import { Pipe, PipeTransform } from '@angular/core';
import { formatTemp } from '../../utils/utils';

@Pipe({
  name: 'formatTemp'
})
export class FormatTempPipe implements PipeTransform {

  transform(value: number) {
    return formatTemp(value);
  }

}
