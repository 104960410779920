import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms'
import { Hotel } from '../../services/hotel/hotelModel'
import { HotelDataService } from '../../services/hotel/hotelData.service'
import { Router } from '@angular/router'
import { FileUploadService } from '../../services/files/fileUpload.service'
import { MunicipiosService } from '../../services/municipios/municipios.service'
import { ToastrService } from 'ngx-toastr'
import { capitalizarPalabra } from '../../../utils/utils'
import { catchError, debounceTime, map, Observable, of, switchMap } from 'rxjs'

interface FormValues {
  nombre: string
  typeobject: string
  direccion: string
}
@Component({
  selector: 'app-data-storage',
  templateUrl: './data-storage.component.html',
  styleUrl: './data-storage.component.scss',
})
export class DataStorageComponent implements OnInit {
  selectedFile: File | null = null
  nombreAlojamiento: string = ''
  address: string = ''
  postal_code: string = ''
  capacity: number | undefined
  rooms: number | undefined
  municipios: any[] = []
  addressOptions: any[] = []
  provincia?: string
  email?: string | undefined
  phone?: string | undefined
  category: string | undefined
  establishmentNameType: boolean = false
  establishmentDataExists: boolean = false
  @ViewChild('miFormulario', { static: false }) miFormulario!: ElementRef
  provinciaValida: boolean = true
  // wifi: boolean = false;
  // mascotas: boolean = false;
  // parking: boolean = false;
  // piscina: boolean = false;
  establecimientoForm: FormGroup
  selectedButton: string = ''
  tipoMinFormat: string = ''
  buttonbuscarAloj: boolean = true
  UIDHotelSubirFich: string = ''
  selectedIntegracion: string | null = ''
  selectExcel: boolean = false
  selectPMS: boolean = false
  pmsNombre: string = ''
  establishmentData: Hotel
  addressMoreOptions: boolean = false // cuando hay un nombre de alojamiento en dos lugares distintos
  contador: number = 0

  resultadosAutocompletar$: Observable<any[]> = new Observable()

  constructor(
    private router: Router,
    private hotelDataService: HotelDataService,
    private fileUploadService: FileUploadService,
    private fb: FormBuilder,
    private municipiosService: MunicipiosService,
    private toastr: ToastrService
  ) {
    this.establecimientoForm = this.fb.group({
      name: ['', Validators.required],
      address: [''],
      province: ['', Validators.required],
      city: ['', Validators.required],
      postal_code: [
        '',
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(5),
          Validators.pattern(/^[0-9]{5}$/),
        ],
      ],
      rooms: [null],
      capacity: [null],
      email: [''],
      category: [''],
      phone: [''],
    })
  }

  ngOnInit(): void {
    this.contador = 0
    this.establecimientoForm.get('postal_code')?.valueChanges.subscribe(() => {
      this.validarCodigoPostal()
    })

    this.establecimientoForm.get('province')?.valueChanges.subscribe(() => {
      this.establecimientoForm.get('postal_code')?.markAsTouched() // Marcar el campo de código postal como tocado
      this.validarCodigoPostal() // Validar cuando cambia la provincia
    })
    this.autocompleteHotelName()
  }

  onProvinciaChange(event: any) {
    this.provincia = event.target.value
    console.log('provincia cambiada', this.provincia)
    const provinciaControl = this.establecimientoForm.get('province')
    const municipioControl = this.establecimientoForm.get('city')

    if (this.provincia == '') {
      provinciaControl?.setErrors({ required: true })
      provinciaControl?.markAsTouched()
      municipioControl?.setErrors({ required: true })
      municipioControl?.markAsTouched()
    } else if (this.provincia) {
      this.municipiosService
        .getMunicipios(this.provincia)
        .subscribe((municipios: any[]) => {
          this.municipios = municipios
          if (
            (!this.establecimientoForm.get('city')?.value &&
              this.municipios.length > 0) ||
            this.municipios.length > 0
          ) {
            console.log('PRIMER MUNICIPIO...', this.municipios[0])
            this.establecimientoForm.get('city')?.setValue(this.municipios[0])
          }
        })
    }
    this.municipios = []
  }
  // Method to handle button clicks
  onButtonClick(buttonName: string) {
    this.selectedButton = buttonName
    // No need to use form.controls['typeobject'].setValue here
  }

  submitForm(form: FormGroup) {
    const formData = this.establecimientoForm.getRawValue()
    const nombreHotel = formData.name
    form.markAllAsTouched()
    if (this.selectedButton != '') {
      this.tipoMinusculaFormato(this.selectedButton)
    }
    if (formData.email == '-') formData.email = ''
    if (form.valid && this.selectedButton != '') {
      // console.log("entra en submit formulario.......", form.value.selectProvincia)

      const nuevoEstablecimiento: Hotel = {
        name: formData.name,
        // type: this.selectedButton,
        type: this.tipoMinFormat,
        address: formData.address,
        city: formData.city,
        province: formData.province,
        postal_code: formData.postal_code,
        rooms: formData.rooms,
        capacity: formData.capacity,
        email: formData.email,
        category: formData.category,
        phone: formData.phone,
      }
      if (formData.postal_code) {
        this.validarCodigoPostal()
        console.log('codigo postal valido')
      }
      // console.log('establecimiento', nuevoEstablecimiento);
      if (this.selectedFile) {
        this.onUpload()
      }
      console.log('Nuevoestablecimiento....', nuevoEstablecimiento)
      //ARREGLAR SUBIDA DE FICHER OY CRAECION DE ALOJAMIENTO ->FATA: SUBO EL FICHERO SOLO SI SE CREA EL ALOJAMIENTO
      this.hotelDataService.addHotel(nuevoEstablecimiento).subscribe({
        next: (respuesta) => {
          console.log('Hotel añadido', respuesta)
          this.toastr.success('El alojamiento se ha creado correctamente')
          this.establishmentNameType = true
          this.establishmentDataExists = true
          this.UIDHotelSubirFich = respuesta.establishment.uid
          // this.toastr.success('Alojamiento creado correctamente', 'Éxito')
        },
        error: (e) => {
          if (e.error) console.log('Error al crear el hote.....', e.error)
          this.toastr.error(
            `El hotel ${formData.name} está creado. Si cree que es un error, por favor póngase en contacto con nosotros.`
          )
          if (e.error) {
            console.error('Detalles del error:', e.error) // Verifica los detalles del error
          }
        },
      }),
        console.log('Establecimiento añadido')
    } else {
      console.log('Formulario invalido', form.errors)
    }
  }

  tipoMinusculaFormato(tipo: string) {
    this.tipoMinFormat = tipo.toLowerCase()
    console.log('dsfasfasdfa', this.tipoMinFormat) // Resultado: hello
  }

  onIntegracionChange() {
    console.log('Valor de selectedIntegracion:', this.selectedIntegracion)

    if (this.selectedIntegracion === 'excel') {
      console.log('Seleccionado Integración Manual (Excel)')
      this.selectExcel = true
      this.selectPMS = false
    } else if (this.selectedIntegracion === 'pms') {
      console.log('Seleccionado Integración PMS')
      this.selectExcel = false
      this.selectPMS = true
    } else {
      console.log('No se seleccionó ninguna opción')
      this.selectExcel = false
      this.selectPMS = false
    }
  }

  onFileSelected(event: Event) {
    const input = event.target as HTMLInputElement
    if (input.files && input.files.length > 0) {
      this.selectedFile = input.files[0]
    }
  }

  onUpload() {
    if (this.selectedFile) {
      if (this.UIDHotelSubirFich != '') {
        this.fileUploadService
          .uploadFile(this.selectedFile, this.UIDHotelSubirFich)
          .subscribe({
            next: (response) => {
              console.log('File uploaded successfully:', response)

              this.toastr.success(
                'El fichero del alojamiento creado se ha subido correctamente.'
              )
              this.hotelDataService.getHoteles()
              this.establecimientoForm.reset()
              localStorage.setItem('buttonClicked', 'true')
              localStorage.setItem('nombreHotel', this.nombreAlojamiento)
              this.router.navigate(['/app'])
            },
            error: (error) => {
              console.error('Error uploading file:', error)
              this.toastr.error(
                'Ha habido un problema al subir el fichero del alojamiento creado. Vaya a la pestaña Carga de Datos dentro de Analíticas y cárgelo ahí'
              )
            },
            complete: () => {
              console.log('Upload complete')
            },
          })
      } else {
        console.log('no hay introducido un nombre de hotel...')
      }
    }
  }

  noFile() {
    this.establecimientoForm.reset()
    localStorage.setItem('buttonClicked', 'true')
    localStorage.setItem('nombreHotel', this.nombreAlojamiento)
    this.router.navigate(['/app'])
    this.hotelDataService.getHoteles()
  }

  noPms() {
    this.establecimientoForm.reset()
    localStorage.setItem('buttonClicked', 'true')
    localStorage.setItem('nombreHotel', this.nombreAlojamiento)
    this.router.navigate(['/app'])
    this.hotelDataService.getHoteles()
  }

  validarCodigoPostal() {
    const codPostal = this.establecimientoForm.get('postal_code')?.value
    const provincia = this.establecimientoForm.get('province')?.value
    if (codPostal === '' || codPostal == null) {
      this.establecimientoForm.get('postal_code')?.setErrors({ required: true })
    } else if (codPostal.length === 5) {
      // Verificar si el código postal coincide con la provincia
      const isValid =
        (provincia === 'Albacete' && codPostal.startsWith('02')) ||
        (provincia === 'Ciudad Real' && codPostal.startsWith('13')) ||
        (provincia === 'Cuenca' && codPostal.startsWith('16')) ||
        (provincia === 'Guadalajara' && codPostal.startsWith('19')) ||
        (provincia === 'Toledo' && codPostal.startsWith('45'))

      // Establecer error si no coincide
      if (!isValid) {
        this.establecimientoForm
          .get('postal_code')
          ?.setErrors({ invalidPostalCode: true })
        this.provinciaValida = false
      } else {
        // Si coincide, elimina cualquier error existente
        this.establecimientoForm.get('postal_code')?.setErrors(null)
        this.provinciaValida = true
      }
    } else {
      // Si el código postal tiene menos de 5 caracteres, no se establece un error
      this.establecimientoForm
        .get('postal_code')
        ?.setErrors({ invalidPostalCode: true }) // O puedes dejarlo como null si prefieres
    }
  }

  // getEstablishmentData(name: string, type: string) {
  //   this.hotelDataService.getEstablishmentData(name, type).subscribe({
  //     next: (r: any) => {
  //       console.log('DATOS RECIBIDOS ESTABLECIMIENTO...', r)
  //       if (r.length > 0) {
  //         if (r.length == 1) {
  //           this.addressMoreOptions = false
  //           this.establishmentData = r[0]
  //           //tiene datos podemos crear el alojamiento (DE MOMENTO LLAMANDO A LO VIEJO)
  //           this.establishmentNameType = true
  //           this.buttonbuscarAloj = false
  //           const provinceFromService = this.establishmentData.province
  //           const formattedProvince =
  //             provinceFromService
  //               ?.toLowerCase()
  //               .replace(/^\w/, (c: string) => c.toUpperCase()) || ''
  //           this.establecimientoForm.patchValue({
  //             address: this.establishmentData.address,
  //             province: formattedProvince,
  //             postal_code: this.establishmentData.postal_code,
  //           })
  //           this.provincia = formattedProvince
  //           this.municipiosService
  //             .getMunicipios(this.provincia)
  //             .subscribe((cities) => {
  //               this.municipios = cities
  //               const cityFromService = this.establishmentData.city
  //               const formattedCity = capitalizarPalabra(cityFromService)

  //               console.log(formattedCity, 'FORMATTED CITY...')
  //               this.establecimientoForm.get('city')?.setValue(formattedCity)
  //             })
  //           this.establecimientoForm.get('address')?.disable()
  //           this.establecimientoForm.get('province')?.disable()
  //           this.establecimientoForm.get('city')?.disable()
  //           this.establecimientoForm.get('postal_code')?.disable()
  //           this.establecimientoForm.get('name')?.valueChanges.subscribe(() => {
  //             this.establishmentNameType = false
  //             this.buttonbuscarAloj = true
  //           })
  //
  //       } else {
  //         this.toastr.error(
  //           'El establecimiento introducido no existe en nuestra base de datos. Por favor, revise los datos introducidos.'
  //         )
  //         this.establishmentNameType = false
  //       }
  //     },
  //     error: (e) => {
  //       this.toastr.error(
  //         'Ha habido un error al cargar los datos del hotel. Intentelo más tarde.'
  //       )
  //     },
  //   })
  // }

  getFormattedData(addressData: any) {
    const formattedProvince =
      addressData.province
        ?.toLowerCase()
        .replace(/^\w/, (c: string) => c.toUpperCase()) || ''
    const formattedCity = capitalizarPalabra(addressData.city)

    return { formattedProvince, formattedCity }
  }

  getEstablishmentData(name: string, type: string) {
    this.hotelDataService.getEstablishmentData(name, type).subscribe({
      next: (r: any) => {
        console.log('DATOS RECIBIDOS ESTABLECIMIENTO...', r)
        if (r.length > 0) {
          this.handleEstablishmentData(r)
        } else {
          this.toastr.error(
            'El establecimiento introducido no existe en nuestra base de datos. Por favor, revise los datos introducidos.'
          )
          this.establishmentNameType = false
        }
      },
      error: (e) => {
        this.toastr.error(
          'Ha habido un error al cargar los datos del hotel. Intentelo más tarde.'
        )
      },
    })
  }

  handleEstablishmentData(r: any) {
    if (r.length === 1) {
      this.contador = 0
      this.setFormValues(r[0])
    } else {
      this.handleMultipleAddresses(r)
    }
  }

  setFormValues(addressData: any) {
    this.addressMoreOptions = false
    this.establishmentData = addressData
    this.establishmentNameType = true
    this.buttonbuscarAloj = false
    const { formattedProvince, formattedCity } =
      this.getFormattedData(addressData)

    this.establecimientoForm.patchValue({
      address: addressData.address,
      province: formattedProvince,
      postal_code: addressData.postal_code,
      rooms: addressData.rooms,
      capacity: addressData.capacity,
      email: addressData.email,
      category: addressData.category,
      phone: addressData.phone,
    })
    this.provincia = formattedProvince

    this.municipiosService
      .getMunicipios(formattedProvince)
      .subscribe((cities) => {
        this.municipios = cities
        this.establecimientoForm.get('city')?.setValue(formattedCity)
      })

    this.disableFormControls()
  }

  handleMultipleAddresses(addresses: any) {
    this.addressMoreOptions = true
    this.establishmentNameType = true
    this.buttonbuscarAloj = false
    this.addressOptions = addresses
    this.onSelectAddress({ target: { value: this.addressOptions[0] } })
  }

  disableFormControls() {
    this.establecimientoForm.get('address')?.enable() // cuando vas de uno que tiene 1 al de +d1 se disable
    if (this.addressMoreOptions) {
      const controls = [
        'province',
        'city',
        'postal_code',
        'rooms',
        'capacity',
        'email',
        'category',
        'phone',
      ]
      controls.forEach((control) => {
        this.establecimientoForm.get(control)?.disable()
      })
    } else {
      const controls = [
        'address',
        'province',
        'city',
        'postal_code',
        'rooms',
        'capacity',
        'email',
        'category',
        'phone',
      ]
      controls.forEach((control) => {
        this.establecimientoForm.get(control)?.disable()
      })
    }
    this.establecimientoForm.get('name')?.valueChanges.subscribe(() => {
      this.establishmentNameType = false
      this.buttonbuscarAloj = true
    })
  }

  onSelectAddress(event: any): void {
    let selectedAddressValue: any
    const selectedIndex = event.target.value
    if (this.contador == 0) {
      //primer acceso por defecto el 0
      selectedAddressValue = this.addressOptions.find((d) => {
        console.log(event.target.value.address, d.address, '.......')
        return d.address == event.target.value.address
      })

      this.contador = this.contador + 1
    } else {
      selectedAddressValue = this.addressOptions.find((d) => {
        console.log(event.target.value, d, '.......')
        return d.address == event.target.value
      })
    }

    if (selectedAddressValue) {
      const { formattedProvince, formattedCity } =
        this.getFormattedData(selectedAddressValue)
      this.establecimientoForm.patchValue({
        address: selectedAddressValue.address,
        province: formattedProvince,
        postal_code: selectedAddressValue.postal_code,
        rooms: selectedAddressValue.rooms,
        capacity: selectedAddressValue.capacity,
        email: selectedAddressValue.email,
        category: selectedAddressValue.category,
        phone: selectedAddressValue.phone,
      })

      this.provincia = formattedProvince
      this.municipiosService
        .getMunicipios(formattedProvince)
        .subscribe((cities) => {
          this.municipios = cities
          this.establecimientoForm.get('city')?.setValue(formattedCity)
        })
    }

    this.disableFormControls()
  }

  onSubmitEstablishmentName() {
    const name = this.establecimientoForm.get('name')?.value
    let type = this.selectedButton
    console.log('name...', name, type, 'type....')
    if (type == 'Casa Rural') {
      type = 'casa_rural'
    }
    this.getEstablishmentData(name, type)
  }

  autocompleteHotelName(): void {
    this.establecimientoForm.get('name')?.valueChanges.subscribe((value) => {
      console.log('Valor del campo name EN AUTOCOMPLETAR....:', value)
    })
    this.resultadosAutocompletar$ = this.establecimientoForm
      .get('name')
      .valueChanges.pipe(
        debounceTime(500), // Espera 500ms después de la última escritura
        switchMap((searchTerm) => {
          let type = this.selectedButton
          console.log('Término de búsqueda:nombre y tipo', searchTerm, type)

          if (searchTerm && searchTerm.trim().length > 0) {
            return this.hotelDataService
              .autocompleteHotelName(searchTerm, type)
              .pipe(
                // Extraemos la propiedad 'resultados' del objeto JSON
                switchMap((response) => {
                  console.log(
                    'Respuesta de autocompletar:',
                    response.resultados
                  ) // Verifica la respuesta del servicio

                  return of(response.resultados || []) // Si 'resultados' no existe, devuelve un array vacío
                })
              )
          } else {
            return of([]) // Si el término de búsqueda está vacío, retorna un array vacío
          }
        }),
        catchError((error) => {
          console.error('Error al obtener los datos:', error)
          return of([]) // En caso de error, también retornamos un array vacío
        })
      )
  }

  seleccionarAlojamiento(item: any): void {
    // Completar el campo de texto con el nombre del alojamiento seleccionado
    this.establecimientoForm.get('name')?.setValue(item)
    // Aquí puedes hacer algo más como cerrar la lista de sugerencias
    this.resultadosAutocompletar$ = new Observable()
  }
}
