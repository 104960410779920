import {
  BehaviorSubject,
  catchError,
  forkJoin,
  map,
  Observable,
  of,
  throwError,
} from 'rxjs'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { error } from 'console'
import * as XLSX from 'xlsx'
import { CSVData } from './iCSV'
import { AuthService } from '../auth/auth.service'
import { environment } from '../../../environments/environment'

@Injectable({
  providedIn: 'root',
})
export class FileUploadService {
  constructor(
    private httpClient: HttpClient,
    private authService: AuthService
  ) {}
  private baseUrl = environment.getTableDatosCLM // Base URL del backend

  //obtener el csv de las tablas de CLM_abierto
  obtenerCsv(infoType: string): Observable<any> {
    const url = environment.getTableDatosCLM.replace('{info_type}', infoType)
    return this.httpClient.get(url, { responseType: 'text' })
  }

  //obtener el json de las tablas de CLM_abierto
  obtenerJson(infoType: string): Observable<any> {
    const url = environment.getTableDatosCLM.replace('{info_type}', infoType) // Genera la URL
    console.log('URL para obtener JSON:', url) // Verifica la URL generada
    return this.httpClient.get<any>(url) // Asumimos que el JSON se devuelve como un objeto o array
  }

  //cargamos excel
  uploadFile(file: File, uid: string): Observable<any> {
    const formData = new FormData()

    // let establecimiento= `/${nameEstablecimiento}/carga`
    const url = environment.uploadFileUrl.replace('{uid}', uid)

    formData.append('file', file)
    console.log('Uploading file:', file)

    // Verifica el contenido del FormData
    formData.forEach((value, key) => {
      console.log(key + ': ' + value)
    })
    console.log('File details:')
    console.log('Name:', file.name)
    console.log('Size:', file.size)
    console.log('Type:', file.type)

    return this.httpClient.post(url, formData)
  }

  //descargamos csv (servicio a url de back)
  downloadJson(uid: string): Observable<any> {
    //si queremos leer desde assets -> nameEstablecimiento por filePath
    let establecimiento = `/${uid}`

    const url = environment.downloadJsonUrl.replace('{uid}', uid)
    // let urlCompleta = environment.getHotelesUrl+establecimiento+'/descarga/csv';
    console.log('entra en descargar....', url, uid)
    return this.httpClient.get(url, { responseType: 'text' })
    // return this.httpClient.get(filePath, { responseType: 'arraybuffer' }); -> para leer desde fichero en assets
  }

  downloadJsonADR(uid: string): Observable<any> {
    //si queremos leer desde assets -> nameEstablecimiento por filePath
    let uidData = `/${uid}`

    const url = environment.downloadJsonADRUrl.replace('{uid}', uidData)
    // let urlCompleta = environment.getHotelesUrl+establecimiento+'/indicadores/adr/csv';
    console.log('entra en adr....', url, uidData)
    return this.httpClient
      .get(url, { responseType: 'text' })
      .pipe(map((d) => this.parseCsv(d)))
    // return this.httpClient.get(filePath, { responseType: 'arraybuffer' }); -> para leer desde fichero en assets
  }

  private parseCsv(data: string): any[] {
    const rows = data.split('\n').map((row) => row.split(','))
    // Aquí puedes transformar los datos según tus necesidades
    return rows
  }

  downloadJsonRevPar(nameEstablecimiento: string): Observable<any> {
    //si queremos leer desde assets -> nameEstablecimiento por filePath
    let establecimiento = `/${nameEstablecimiento}`

    const url = environment.downloadJsonRevParUrl.replace(
      '{establecimiento}',
      nameEstablecimiento
    )
    // let urlCompleta = environment.getHotelesUrl+establecimiento+'/indicadores/revpar/csv';
    console.log('entra en red....', url, nameEstablecimiento)
    return this.httpClient
      .get(url, { responseType: 'text' })
      .pipe(map((d) => this.parseCsv(d)))
    // return this.httpClient.get(filePath, { responseType: 'arraybuffer' }); -> para leer desde fichero en assets
  }

  loadCsvsAdrRevPar(uid: string): Observable<{ data1: any[]; data2: any[] }> {
    let establecimiento = `/${uid}`

    let urlA = environment.downloadJsonADRUrl.replace('{uid}', uid)
    let urlB = environment.downloadJsonRevParUrl.replace('{uid}', uid)
    return forkJoin([
      this.httpClient.get(urlA, { responseType: 'text' }),
      this.httpClient.get(urlB, { responseType: 'text' }),
    ]).pipe(
      map(([data1, data2]) => {
        const parsedData1 = this.parseCsv(data1)
        const parsedData2 = this.parseCsv(data2)
        return { data1: parsedData1, data2: parsedData2 }
      }),
      catchError((error) => {
        console.error('Error cargando CSVs', error)
        return of({ data1: [], data2: [] }) // Devuelve arrays vacíos en caso de error
      })
    )
  }

  parseCSVData(data: string): any[] {
    const rows = data
      // .replace(/\r/g, '')
      .split(/\r?\n/)
      .map((row) => row.split(','))
    const headers = rows[0]
    return rows.slice(1).map((row) => {
      return headers.reduce((acc: CSVData, header: string, index: number) => {
        acc[header] = row[index]
        return acc
      }, {})
    })
  }

  downloadCsv(): Observable<any> {
    //si queremos leer desde assets -> nameEstablecimiento por filePath

    const url = environment.getCSVUrl
    console.log('entra en red de descargar csv....', url)
    return this.httpClient
      .get(url, { responseType: 'text' })
      .pipe(map((d) => this.parseCsv(d)))
  }
}
