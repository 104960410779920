import { Component, Output, EventEmitter, Input } from '@angular/core'
import {
  FormBuilder,
  FormGroup,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms'
import { Router } from '@angular/router'

@Component({
  selector: 'app-verification-code-form',
  standalone: true,
  imports: [ReactiveFormsModule],
  templateUrl: './verification-code-form.component.html',
  styleUrl: './verification-code-form.component.scss',
})
export class VerificationCodeFormComponent {
  loginMFACode: FormGroup
  @Input() waiting: boolean = false
  @Output() mfacode: EventEmitter<any> = new EventEmitter()
  @Output() goBack: EventEmitter<any> = new EventEmitter()

  constructor(private fb: FormBuilder) {
    this.loginMFACode = this.fb.group({
      mfacode: ['', [Validators.required]],
    })
  }

  submitSigninTOTPCode() {
    this.mfacode.emit(this.loginMFACode.value.mfacode)
  }

  close() {
    this.goBack.emit()
  }
}
