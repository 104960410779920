<!-- **************** MAIN CONTENT START **************** -->
<main>
    <!-- =======================
Main Banner START -->

    <section class="hero position-relative overflow-hidden pb-0 pt-xl-9">
        <!-- SVG decoration -->
        <div class="position-absolute top-0 start-0 ms-n7 d-none d-xl-block">
            <img src="assets/images/decoration-pattern2.svg" alt="">
        </div>

        <!-- SVG decoration -->
        <figure class="position-absolute top-0 end-0 me-n4 mt-n7 d-none d-xl-block z-1">
            <svg class="fill-mode opacity-1" width="775px" height="834px" viewBox="0 0 775 834"
                style="enable-background:new 0 0 775 834;" xml:space="preserve">
                <path
                    d="M486.1,564.4c-3.6,2.5-7.4,4.8-11.3,6.4c-12,5.5-25.7,7.9-42.2,7.4c-30.6-1.1-65.6-12.5-102.8-24.4 c-50.7-16.2-103.3-33.4-152.5-27c-56.1,7.2-97.9,44.4-128,114l-0.4-0.2c67.5-156.1,181-119.5,281.1-87.1c37,12,72,23.2,102.5,24.3 c34.3,1.2,58.1-10.7,74.9-37.4C530.1,505,547.1,466,565,425.1C619.4,301,675.6,172.7,892.1,141.3l0.1,0.4 c-216.2,31.4-272.5,159.5-326.8,283.5c-18.1,41.1-35,79.7-57.7,115.6C501.6,550.7,494.5,558.5,486.1,564.4z">
                </path>
                <path
                    d="M500.9,551.4c-43.7,31-103,15.8-165.5-0.2c-49.9-12.7-101.5-25.8-148.7-16.7c-53.3,10.5-93.2,49-121.6,118 l-0.5-0.1c15.3-37.1,33.3-64.7,55.1-84.7c19.5-17.7,41.3-28.6,66.7-33.7c47.4-9.2,99,3.9,148.9,16.6 c70.4,17.9,137.1,34.9,181.3-14.4c35.7-39.9,57.3-91.7,80.2-146.7c23.8-56.7,48.2-115.5,90.2-163.6c22.7-25.9,48.4-46.4,78.4-62.4 c33.9-18.1,72.2-30.3,117.1-37.1l0.1,0.4C695,155.3,645.2,274.5,597.1,389.7c-22.9,55-44.5,106.8-80.4,146.8 C512.3,542.4,506.6,547.3,500.9,551.4z">
                </path>
                <path
                    d="M521.3,536.4c-21.9,15.5-48.4,23.4-80.8,23.8c-31.2,0.5-65.1-5.8-97.9-11.9c-49.3-9.2-100.2-18.7-145.7-6.5 c-51.1,13.7-88.9,53.7-116,122.6l-0.6-0.2c60.5-154.1,163.3-135,262.6-116.5c68.1,12.7,132.6,24.6,183.6-15.8 c48.1-38.2,71.1-100.6,95.6-166.5c20.3-55,41.4-111.6,78.3-158.1c20-25.1,42.7-44.9,69.2-60.5c30.1-17.5,64.2-29.1,104.3-35.4 l0.2,0.6c-167.2,26.3-210,141.9-251.4,253.5C598.3,431.5,575,493.8,527,532.2C525.1,533.8,523.2,535.1,521.3,536.4z">
                </path>
                <path
                    d="M548.9,520.3c-4,2.9-8.2,5.6-12.6,8c-56.6,31.5-120.9,23.8-183,16.6c-51.7-6-100.4-11.8-144.6,3.2 c-49.9,16.9-85.5,57.7-111.3,128.2l-0.6-0.2c13.7-37.3,30.1-66,49.9-87.8c17.8-19.4,37.9-32.8,61.8-40.9 c44.3-15,93.1-9.3,144.9-3.2c62.1,7.2,126.3,14.8,182.8-16.6c59.6-33.2,82-104.7,105.9-180.4c17.1-54.3,34.7-110.5,67.2-156.6 c36.7-52,87.8-82.8,155.7-94l0.2,0.6c-151.9,25-187.8,139.3-222.3,250C620.4,417.6,599.4,484.5,548.9,520.3z">
                </path>
                <path
                    d="M573.5,509.5c-8.2,5.8-17.4,10.7-27.7,14.6c-59.3,22-119.1,18.8-176.8,15.8c-53.2-2.8-103.3-5.3-147.1,12.5 C172.6,572.3,138.1,615.5,113,688l-0.5-0.1c25.1-72.7,59.6-115.9,108.9-136c44-18,94.2-15.3,147.6-12.6 c57.7,3,117.4,6.1,176.6-15.9c70.7-26.2,91.1-106.3,112.8-191.4c13.9-54.5,28.3-111,56.7-156.9C747,123.2,793,92.6,855.6,82l0,0.7 C716.3,106.5,687,221.4,658.9,332.2C640.4,405,622.6,474.4,573.5,509.5z">
                </path>
                <path
                    d="M595.2,502.3c-11.3,8-24.6,14-40,17.4c-56.8,12.7-112,12.7-160.5,12.9c-60.2,0.1-112,0.2-157,21.1 c-49.5,23-84,69.3-108.5,146l-0.6-0.2c24.3-76.7,58.9-123.1,108.6-146.3c45.1-21.1,97.2-21.1,157.4-21.2 c48.6,0,103.6-0.1,160.5-12.9c81.6-18.3,99-106.7,117.4-200.6c10.7-55,22-112,46.6-158.2C747,108,788.6,77.5,846.5,67.2l0.1,0.8 C718,91.2,695.2,206.9,673.2,318.9C658.3,394.9,643.8,467.8,595.2,502.3z">
                </path>
                <path
                    d="M615.3,497.4c-13.7,9.7-30.2,16-50.8,18c-44.4,4.6-86.5,5.8-123.6,6.8c-71.2,2-132.8,3.7-182,27.7 C206,575.6,169.8,627,145,711.3l-0.8-0.1c13-44.6,29-79.3,48.6-106.3c18.1-24.9,39.5-43.1,65.6-55.7 c49.5-24.1,110.9-25.8,182.4-27.7c37.1-1,79.3-2.2,123.5-6.7c92.6-9.4,106.2-106.5,120.5-209.2c7.8-55.9,15.9-113.6,37-160 c23.8-52.7,61.6-83.1,115.3-93.4l0.3,0.7c-53.4,10.1-91,40.4-114.6,92.9c-21.1,46.4-29.2,104.1-36.8,159.9 C674.6,386,663.8,463,615.3,497.4z">
                </path>
                <path
                    d="M634.4,494c-15.5,11-35.2,17.2-60.4,17.3c-12.3,0.1-24.5,0.1-36.1,0.1c-103.7,0-185.5-0.1-246.4,26.4 c-63.5,27.7-103.7,85-130.5,185.5l-0.8-0.1c13.9-52.5,31.3-92.6,53.2-122.9c20.7-28.8,46.2-49.4,77.8-63.2 c61-26.6,142.9-26.4,246.6-26.4c11.7,0.1,23.8,0,36.1-0.1c103.8-0.2,112.9-105.6,122.5-217.2c4.7-56.9,9.9-115.5,27.5-162.4 c20-53.1,54.1-83.7,104.1-93.7l0.1,0.8c-49.5,9.8-83.5,40.3-103.3,93.1c-17.6,46.9-22.7,105.4-27.6,162 C690.1,378.2,682.9,459.6,634.4,494z">
                </path>
                <path
                    d="M652.7,491.8c-17.9,12.7-40.7,17.7-69.2,15.4C328,486.2,228.3,517.5,177.2,735.2l-0.9-0.3 c25.9-110.7,64-171.6,127-204c66.6-34.2,160.2-34.6,280.3-24.7c32.2,2.6,56.9-4.1,75.4-20.5c42.1-37.4,45.1-118.6,48-204.7 c4-116.5,8.1-236.8,112.1-258.6l0.1,0.8C715.9,44.8,711.8,164.8,707.8,280.9c-3.1,86.3-5.8,167.7-48.3,205.2 C657.3,488.3,655,490.1,652.7,491.8z">
                </path>
                <path
                    d="M670.6,490.3c-19.3,13.7-44.8,17.9-77.7,12.7c-138.5-21.4-227.1-13-287.3,27 c-55.4,36.8-89.1,101.7-112.4,216.9l-0.9-0.3C215.8,631,249.6,566,305.1,528.9c60.3-40.1,149.1-48.6,288.1-27.3 c35.9,5.5,63,0,82.6-16.9c43.2-37.5,42.2-124.3,40.9-216.1C714.9,151,713,28.8,809.9,7.7l0.1,0.8c-96,21.1-94.3,142.7-92.7,260.6 c1.3,92.1,2.4,179-41.1,216.7C674.3,487.4,672.6,488.9,670.6,490.3z">
                </path>
            </svg>
        </figure>

        <div class="container pt-4 pt-sm-5 mb-5 pb-5 z-2 position-relative">
            <div class="row g-xl-5">

                <!-- Hero content START -->
                <div class="col-xl-7 mb-5 mb-xl-0">
                    <div class="pe-xxl-4">

                        <!-- Title -->
                        <h1 class="mt-3 fw-bold">Plataforma para anticipar la demanda y optimizar tus <span
                                class="text-decoration-underline text-info">beneficios</span>
                        </h1>
                        <p class="mb-0 mt-4 mt-md-5 h5 text-secondary fw-light">Supera tus desafíos de fijación de
                            precios más exigentes y reduce costos con nuestra plataforma de inteligencia artificial.</p>

                        <!-- Search -->
                        <div class="mt-5 ">
                            <button type="submit"
                                class="btn btn-primary rounded-pill py-2 px-4  align-items-center btn-lg">
                                <i class="fa-solid fa-laptop me-2"></i>Programa una demo</button>
                        </div>
                        <!-- <form class="col-md-10 bg-body border rounded-2 position-relative p-2 mt-4 mt-md-5">
						<div class="input-group">
							<input class="form-control focus-shadow-none border-0 me-1" type="email" placeholder="Enter your email address">
							<button type="button" class="btn btn-dark rounded-2 mb-0">Start trial!</button>
						</div>
					</form> -->

                        <!-- Features -->
                        <!-- Row END -->
                    </div>
                </div>
                <!-- Hero content END -->

                <!-- Hero Image START -->
                <div class="col-md-10 col-xl-5 position-relative mx-auto mt-7 mt-xl-0">
                    <!-- SVG Decoration -->
                    <figure class="position-absolute top-0 start-0 translate-middle mt-5">
                        <svg class="text-light" width="67px" height="52px" viewBox="0 0 67 52" version="1.1"
                            xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"
                                opacity="0.110560826">
                                <g id="Group-2-Copy" fill="#41485A">
                                    <ellipse id="Oval" cx="3.58928571" cy="3.6" rx="3.58928571" ry="3.6"></ellipse>
                                    <ellipse id="Oval" cx="18.7440476" cy="3.6" rx="3.58928571" ry="3.6"></ellipse>
                                    <ellipse id="Oval" cx="33.1011905" cy="3.6" rx="3.58928571" ry="3.6"></ellipse>
                                    <ellipse id="Oval" cx="48.2559524" cy="3.6" rx="3.58928571" ry="3.6"></ellipse>
                                    <ellipse id="Oval" cx="3.58928571" cy="18.8" rx="3.58928571" ry="3.6"></ellipse>
                                    <ellipse id="Oval" cx="18.7440476" cy="18.8" rx="3.58928571" ry="3.6"></ellipse>
                                    <ellipse id="Oval" cx="33.1011905" cy="18.8" rx="3.58928571" ry="3.6"></ellipse>
                                    <ellipse id="Oval" cx="48.2559524" cy="18.8" rx="3.58928571" ry="3.6"></ellipse>
                                    <ellipse id="Oval" cx="3.58928571" cy="33.2" rx="3.58928571" ry="3.6"></ellipse>
                                    <ellipse id="Oval" cx="18.7440476" cy="33.2" rx="3.58928571" ry="3.6"></ellipse>
                                    <ellipse id="Oval" cx="33.1011905" cy="33.2" rx="3.58928571" ry="3.6"></ellipse>
                                    <ellipse id="Oval" cx="48.2559524" cy="33.2" rx="3.58928571" ry="3.6"></ellipse>
                                    <ellipse id="Oval" cx="3.58928571" cy="48.4" rx="3.58928571" ry="3.6"></ellipse>
                                    <ellipse id="Oval" cx="18.7440476" cy="48.4" rx="3.58928571" ry="3.6"></ellipse>
                                    <ellipse id="Oval" cx="33.1011905" cy="48.4" rx="3.58928571" ry="3.6"></ellipse>
                                    <ellipse id="Oval" cx="48.2559524" cy="48.4" rx="3.58928571" ry="3.6"></ellipse>
                                    <ellipse id="Oval" cx="63.4107143" cy="3.6" rx="3.58928571" ry="3.6"></ellipse>
                                    <ellipse id="Oval" cx="63.4107143" cy="18.8" rx="3.58928571" ry="3.6"></ellipse>
                                    <ellipse id="Oval" cx="63.4107143" cy="33.2" rx="3.58928571" ry="3.6"></ellipse>
                                    <ellipse id="Oval" cx="63.4107143" cy="48.4" rx="3.58928571" ry="3.6"></ellipse>
                                </g>
                            </g>
                        </svg>
                    </figure>

                    <!-- Hero image -->
                    <!-- <img src="assets/images/02.jpg" class="rounded img-fluid" alt="hero-img"> -->
                    <div class="bgImage"></div>

                    <!-- Total revenue box START -->
                    <div
                        class="featured d-inline-block bg-dark rounded-4 position-absolute start-0 top-50 mb-md-4 mt-n3 mt-lg-n5 p-2">
                        <div class="d-flex align-items-center flex-column text-white ">
                            <h6 class="mb-2 me-2 smaller">Ingresos totales</h6>
                            <h4 class="mb-2 me-2 fs-4">145.500€</h4>
                            <span class="fw-light text-secondary short">Ingresos próximos 12 meses</span>
                            <div class="d-flex mt-2 align-items-center">
                                <i class="fa-solid fa-arrow-up text-success"></i>
                                <p class="ms-2 mb-0 fs-4">22%</p>
                            </div>
                            <span class="fw-light text-secondary short">vs 2023</span>
                            <div class="bg-white text-center text-body rounded-2 p-2 mt-2"
                                style="--bs-bg-opacity: .99;">
                                <p class="mb-0 short text-primary">
                                    <span class="d-block text-secondary fs-6">114% </span>
                                    frente a ingresos totales de 2023
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- Total revenue box END -->

                    <!-- Average daily rate START -->
                    <div
                        class="featured d-inline-block card card-body shadow rounded-4 position-absolute end-0 top-0 p-3 me-lg-n3 mt-n1 d-sm-block border-0">
                        <div class="d-flex align-items-center flex-column ">
                            <h6 class="mb-2 me-2 smaller">Tarifa media diaria</h6>
                            <h4 class="mb-2 me-2 fs-4">400€</h4>
                            <span class="fw-light text-secondary short">ADR de los próximos 12 meses</span>
                            <div class="d-flex mt-2 align-items-center">
                                <i class="fa-solid fa-arrow-up text-success"></i>
                                <p class="ms-2 mb-0 fs-4">25%</p>
                            </div>
                            <span class="fw-light text-secondary short">vs 2023</span>
                            <div class="bg-primary text-center text-body rounded-2 p-2 mt-2 w-100"
                                style="--bs-bg-opacity: .2;">
                                <p class="mb-0 short text-primary">
                                    <span class="d-block text-body fs-6">66% </span>
                                    <i class="fa-solid fa-arrow-up text-success"></i> frente a ADR total
                                </p>
                            </div>
                        </div>
                    </div>
                    <!--  Average daily rate  END -->

                    <!-- Score START -->
                    <div
                        class="featured d-inline-block card card-body shadow rounded-4 position-absolute top-100 start-50 translate-middle p-3 d-none d-sm-block border-0">
                        <div class="d-flex align-items-center flex-column ">
                            <h6 class="mb-2 me-2 smaller">Puntuación</h6>
                            <div class="text-center text-body rounded-2 p-2 mt-2 w-100">
                                <img src="assets/images/score.png" height="30px" class="" alt="">
                                <h4 class="mb-2 fs-4 mt-n3">82</h4>
                                <p class="mb-0 text-primary">
                                    <span class="d-block text-body short">Tu puntuación es </span>
                                    <span class="badge rounded-pill text-bg-success fw-6 text-success"
                                        style="--bs-bg-opacity: .3;">Excelente</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <!--  Score  END -->

                </div>
                <!-- Hero Image END -->

            </div>
        </div>
    </section>

    <!-- Abbout section -->
    <section class="counter-section">
        <div class="container">
            <div class="d-flex justify-content-center mb-2">
                <h2>
                    <span>Conocemos su negocio.</span> <br> <span>Lo hacemos sencillo. Lo hacemos tuyo.</span>
                    <a href="#" class="fs-6 d-block mt-2">Obtenga más información de los beneficios de esta
                        plataforma</a>
                </h2>
            </div>
            <div class="row justify-content-start pt-5 mb-4">
                <div class="col-lg-3 col-md-6 mb-5 mb-lg-0 text-center icon-box-item">
                    <h3 class="text-primary count">46<span class="fs-1">%</span></h3>
                    <p class="px-lg-5 text-start d-block ">Proporción de ampliación de reservas de habitaciones que
                        nuestro software valora todos los días.</p>
                </div>
                <div class="col-lg-3 col-md-6 mb-5 mb-lg-0 text-center icon-box-item">
                    <h3 class="text-primary interval">3-7<span class="fs-1">%</span></h3>
                    <p class="px-lg-5 text-start d-block ">El aumento de ingresos que nuestros usuarios suelen
                        experimentar.</p>
                </div>
                <div class="col-lg-3 col-md-6 mb-5 mb-lg-0 text-center icon-box-item">
                    <h3 class="text-primary count">42<span class="fs-1">%</span></h3>
                    <p class="px-lg-5 text-start d-block ">Optimización de recursos que permite ahorrar en la gestión de
                        su negocio</p>
                </div>
                <div class="col-lg-3 col-md-6 mb-5 mb-lg-0 text-center icon-box-item">
                    <h3 class="text-primary count">25<span class="fs-1">%</span></h3>
                    <p class="px-lg-5 text-start d-block ">Aumento en la fidelización de los clientes puede contribuir a
                        maximizar los ingresos.</p>
                </div>
            </div>
        </div>
    </section>

    <!-- Blog Start -->
    <!-- <section class="blog">
    <div class="container p-5">
        <div class="d-flex justify-content-start mb-2">
            <h2>
                <span>En Castilla La Mancha los datos son el camino.</span>
                <p class="fs-6 d-block mt-2 text-secondary">Obtenga más información de los beneficios de esta plataforma</p>
            </h2>
        </div>
            <div class="row g-4 justify-content-center pt-5">
                <div class="col-md-6 col-lg-4 col-xl-4">
                    <a href="#" class="blog-item link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover link-secondary">
                        <div class="blog-img">
                            <img src="assets/images/blog-1.jpeg" class="img-fluid w-100" alt="">
                            <div class="blog-info">
                                <span><i class="fa fa-clock"></i> 25 Enero 2024</span>
                                
                            </div>
                        </div>
                        <div class="blog-content">
                            <p class="mb-4">C-LM se posiciona como una alternativa para el sector de los centros de datos a nivel europeo.</p>
                        </div>
                    </a>
                </div>
                <div class="col-md-6 col-lg-4 col-xl-4">
                    <a href="#" class="blog-item link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover link-secondary">
                        <div class="blog-img">
                            <img src="assets/images/blog-2.jpeg" class="img-fluid w-100" alt="">
                            <div class="blog-info">
                                <span><i class="fa fa-clock"></i> 14 Abril 2024</span>
                            </div>
                        </div>
                        <div class="blog-content">
                            <p class="mb-4">La gestión de datos y su importancia como herramienta de toma de decisiones.</p>
                        </div>
                    </a>
                </div>
                <div class="col-md-6 col-lg-4 col-xl-4">
                    <a href="#" class="blog-item link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover link-secondary">
                        <div class="blog-img">
                            <img src="assets/images/blog-3.jpeg" class="img-fluid w-100" alt="">
                            <div class="blog-info">
                                <span><i class="fa fa-clock"></i> 25 Enero 2024</span>
                                
                            </div>
                        </div>
                        <div class="blog-content">
                            <p class="mb-4">Reinvención del turismo en la era de la Inteligencia Artificial.</p>
                        </div>
                    </a>
                </div>
            </div>
            <a class="float-end link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover" href="#">Todas las noticias <i class="fa-solid fa-arrow-right text-body"></i>
            </a>
    </div>
</section> -->

    <!-- FAQs -->
    <section class="section faqs pt-1 pb-5">
        <svg class="position-absolute top-0 end-0 " width="668.84724px" height="706.548386px"
            viewBox="0 0 668.84724 706.548386" version="1.1" xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink">
            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity="0.044875372">
                <path
                    d="M402.466589,600.208696 C521.760095,600.208696 618.466589,503.502202 618.466589,384.208696 C618.466589,264.91519 398.408532,100.771196 279.115026,100.771196 C159.821521,100.771196 50.3806515,399.063627 50.3806515,518.357133 C50.3806515,637.650639 283.173083,600.208696 402.466589,600.208696 Z"
                    id="Oval-Copy" fill="#388AF7"
                    transform="translate(334.4236, 353.2742) rotate(70) translate(-334.4236, -353.2742)"></path>
            </g>
        </svg>
        <div class="container" id="faqs">
            <div class="d-flex justify-content-start mb-2 mt-5">
                <h2><span>Preguntas Frecuentes</span></h2>
            </div>
            <div class="row justify-content-center pt-5">
                <div class="col-lg-9">
                    <div class="accordion accordion-border-bottom" id="accordionFAQ">
                        <div class="accordion-item">
                            <h2 class="accordion-header accordion-button h5 border-0 active" id="111" type="button"
                                data-bs-toggle="collapse" data-bs-target="#111-collapsed" aria-expanded="true"
                                aria-controls="111-collapsed">¿Qué es Senda y para quién está diseñada?
                            </h2>
                            <div id="111-collapsed" class="accordion-collapse collapse border-0 show"
                                aria-labelledby="111" data-bs-parent="#accordionFAQ">
                                <div class="accordion-body py-0 content">
                                    Senda es una plataforma avanzada de <span class="fw-bold">Revenue Management</span>
                                    enfocada en ayudar a pequeños
                                    y medianos establecimientos a maximizar sus ingresos mediante la optimización de
                                    precios y la automatización. Está diseñada específicamente para alojamientos como
                                    <span class="fw-bold">casas rurales, campings, apartamentos turísticos, hostales,
                                        albergues y hoteles</span> en
                                    la región de Castilla-La Mancha.
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header accordion-button h5 border-0 "
                                id="heading-a443e01b4db47b3f4a1267e10594576d52730ec1" type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapse-a443e01b4db47b3f4a1267e10594576d52730ec1"
                                aria-expanded="false" aria-controls="collapse-a443e01b4db47b3f4a1267e10594576d52730ec1">
                                ¿Cómo ayuda Senda a los establecimientos en Castilla-La Mancha?
                            </h2>
                            <div id="collapse-a443e01b4db47b3f4a1267e10594576d52730ec1"
                                class="accordion-collapse collapse border-0 "
                                aria-labelledby="heading-a443e01b4db47b3f4a1267e10594576d52730ec1"
                                data-bs-parent="#accordionFAQ">
                                <div class="accordion-body py-0 content">
                                    Senda utiliza <span class="fw-bold">algoritmos de inteligencia artificial</span>
                                    para prever la demanda local y
                                    ajustar automáticamente los precios en función de la competencia, eventos regionales
                                    y temporadas turísticas, maximizando los ingresos de los establecimientos de la
                                    región.
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header accordion-button h5 border-0 "
                                id="heading-4b82be4be873c8ad699fa97049523ac86b67a8bd" type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapse-4b82be4be873c8ad699fa97049523ac86b67a8bd"
                                aria-expanded="false" aria-controls="collapse-4b82be4be873c8ad699fa97049523ac86b67a8bd">
                                ¿Qué beneficios ofrece Senda a los establecimientos turísticos en Castilla-La Mancha?
                            </h2>
                            <div id="collapse-4b82be4be873c8ad699fa97049523ac86b67a8bd"
                                class="accordion-collapse collapse border-0 "
                                aria-labelledby="heading-4b82be4be873c8ad699fa97049523ac86b67a8bd"
                                data-bs-parent="#accordionFAQ">
                                <div class="accordion-body py-0 content">
                                    <ul>
                                        <li>
                                            <span class="fw-bold">Optimización de precios: </span>Ajustes automáticos
                                            para maximizar los ingresos basados en datos de demanda.
                                        </li>
                                        <li>
                                            <span class="fw-bold">Análisis predictivo: </span>Previsión precisa de
                                            ocupación y demanda.
                                        </li>
                                        <li>
                                            <span class="fw-bold">Automatización avanzada: </span>Reducción del trabajo
                                            manual en la gestión de precios.
                                        </li>
                                        <li>
                                            <span class="fw-bold">Soporte regional: </span>Atención y soporte dedicados
                                            con conocimiento de las particularidades del mercado local.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header accordion-button h5 border-0 "
                                id="heading-3e13e9676a9cd6a6f8bfbe6e1e9fc0881ef247b3" type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapse-3e13e9676a9cd6a6f8bfbe6e1e9fc0881ef247b3"
                                aria-expanded="false" aria-controls="collapse-3e13e9676a9cd6a6f8bfbe6e1e9fc0881ef247b3">
                                ¿Cómo se integra Senda con otros sistemas de gestión?

                            </h2>
                            <div id="collapse-3e13e9676a9cd6a6f8bfbe6e1e9fc0881ef247b3"
                                class="accordion-collapse collapse border-0 "
                                aria-labelledby="heading-3e13e9676a9cd6a6f8bfbe6e1e9fc0881ef247b3"
                                data-bs-parent="#accordionFAQ">
                                <div class="accordion-body py-0 content">
                                    Senda se integra perfectamente con los sistemas de gestión de propiedades (<span
                                        class="fw-bold">PMS</span>) y <span class="fw-bold">Channel Managers</span>,
                                    permitiendo una administración eficiente y centralizada de inventarios, reservas y
                                    tarifas desde una única plataforma. También permite la actualización manual de
                                    datos.
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header accordion-button h5 border-0 " id="heading-5" type="button"
                                data-bs-toggle="collapse" data-bs-target="#collapse-5" aria-expanded="false"
                                aria-controls="collapse-5"> ¿Es fácil de usar Senda para pequeños negocios turísticos?

                            </h2>
                            <div id="collapse-5" class="accordion-collapse collapse border-0 "
                                aria-labelledby="heading-5" data-bs-parent="#accordionFAQ">
                                <div class="accordion-body py-0 content">
                                    Sí, Senda tiene una <span class="fw-bold">interfaz fácil de usar</span> y una
                                    configuración rápida, lo que la
                                    hace ideal para pequeños negocios turísticos que no disponen de grandes equipos
                                    técnicos. Además, ofrecemos <span class="fw-bold">formación continua</span> y
                                    soporte para asegurar una
                                    experiencia fluida desde el primer día.
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header accordion-button h5 border-0 " id="heading-6" type="button"
                                data-bs-toggle="collapse" data-bs-target="#collapse-6" aria-expanded="false"
                                aria-controls="collapse-6"> ¿Qué tipo de soporte ofrece Senda a los usuarios en
                                Castilla-La Mancha?

                            </h2>
                            <div id="collapse-6" class="accordion-collapse collapse border-0 "
                                aria-labelledby="heading-6" data-bs-parent="#accordionFAQ">
                                <div class="accordion-body py-0 content">
                                    Senda ofrece <span class="fw-bold">soporte dedicado</span> con atención
                                    personalizada para resolver dudas y guiar
                                    a los usuarios. También proporcionamos <span class="fw-bold">formación
                                        continua</span> para que los
                                    establecimientos puedan aprovechar al máximo la plataforma.
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header accordion-button h5 border-0 " id="heading-7" type="button"
                                data-bs-toggle="collapse" data-bs-target="#collapse-7" aria-expanded="false"
                                aria-controls="collapse-7"> ¿Qué diferencia a Senda de otras plataformas globales de
                                Revenue Management?

                            </h2>
                            <div id="collapse-7" class="accordion-collapse collapse border-0 "
                                aria-labelledby="heading-7" data-bs-parent="#accordionFAQ">
                                <div class="accordion-body py-0 content">
                                    Senda combina las funcionalidades de las plataformas globales de revenue management
                                    con un enfoque específico para <span class="fw-bold">pequeños establecimientos en
                                        mercados locales</span> como el
                                    de Castilla-La Mancha. Además, Senda es más accesible en términos de <span
                                        class="fw-bold">coste</span> y está
                                    diseñada con las particularidades de los alojamientos rurales y turísticos en mente.
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header accordion-button h5 border-0 " id="heading-8" type="button"
                                data-bs-toggle="collapse" data-bs-target="#collapse-8" aria-expanded="false"
                                aria-controls="collapse-8"> ¿Cómo ayuda Senda a aumentar los ingresos de los
                                alojamientos en la región?
                            </h2>
                            <div id="collapse-8" class="accordion-collapse collapse border-0 "
                                aria-labelledby="heading-8" data-bs-parent="#accordionFAQ">
                                <div class="accordion-body py-0 content">
                                    Senda permite a los propietarios ajustar automáticamente los precios de sus
                                    habitaciones en tiempo real, optimizando tanto en períodos de alta como de baja
                                    demanda. Esto se traduce en un <span class="fw-bold">incremento del RevPAR (Revenue
                                        per Available Room)</span> y
                                    mayores ingresos anuales.
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header accordion-button h5 border-0 " id="heading-9" type="button"
                                data-bs-toggle="collapse" data-bs-target="#collapse-9" aria-expanded="false"
                                aria-controls="collapse-9">¿Qué coste tiene implementar Senda?
                            </h2>
                            <div id="collapse-9" class="accordion-collapse collapse border-0 "
                                aria-labelledby="heading-9" data-bs-parent="#accordionFAQ">
                                <div class="accordion-body py-0 content">
                                    Senda ofrece un <span class="fw-bold">modelo de precios flexible</span> basado en el
                                    tamaño del establecimiento y
                                    el volumen de reservas, asegurando que incluso los pequeños negocios turísticos
                                    puedan acceder a la plataforma de manera asequible.
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header accordion-button h5 border-0 " id="heading-10" type="button"
                                data-bs-toggle="collapse" data-bs-target="#collapse-10" aria-expanded="false"
                                aria-controls="collapse-10">¿Qué hace Senda diferente para los negocios rurales en
                                Castilla-La Mancha?
                            </h2>
                            <div id="collapse-10" class="accordion-collapse collapse border-0 "
                                aria-labelledby="heading-10" data-bs-parent="#accordionFAQ">
                                <div class="accordion-body py-0 content">
                                    Senda está <span class="fw-bold">adaptada a la realidad local</span>, teniendo en
                                    cuenta las particularidades del
                                    mercado turístico rural, las temporadas, y eventos específicos de Castilla-La
                                    Mancha, lo que permite a los propietarios tomar decisiones informadas y oportunas.
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header accordion-button h5 border-0 " id="heading-11" type="button"
                                data-bs-toggle="collapse" data-bs-target="#collapse-11" aria-expanded="false"
                                aria-controls="collapse-11">¿Cómo puedo comenzar a usar Senda en mi establecimiento?
                            </h2>
                            <div id="collapse-11" class="accordion-collapse collapse border-0 "
                                aria-labelledby="heading-11" data-bs-parent="#accordionFAQ">
                                <div class="accordion-body py-0 content">
                                    Para empezar a usar Senda, simplemente debes ponerte en contacto con nuestro equipo
                                    de ventas o registrarte en nuestra página web. Te guiaremos a través de la
                                    <span class="fw-bold">configuración inicial</span> y te proporcionaremos la formación necesaria para empezar a
                                    maximizar tus ingresos rápidamente.
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </section>

</main>