import { Component } from '@angular/core'
import * as pdfMake from 'pdfmake/build/pdfmake.js'
// import * as pdfFonts from 'pdfmake/build/vfs_fonts.js';

// (window as any).pdfMake.vfs = pdfFonts.pdfMake.vfs;

/*
 * There are 50 photos downloaded and placed into generated pdf.
 * Download bandwidth limited to 80Mb.
 * Done on Intel Core i9-8950HK
 * Each photo has 780 × 327 resolution.
 * Photos were produced by:
 * 1. taking a screenshot of an image with size similar to the one visible in the print UI design.
 * 2. optionally converting to jpeg from png (for test with jpeg images)
 * 3. copied 49 times to get 50 images
 * 4. uploaded to imgur so it can be accessed without cors issues
 *
 * Download start and pdf generation start are console logged.
 *
 * Results for 438KB png:
 * - ~2.5s of download
 * - ~6s of report production from data urls stored in memory
 * - ~8.5s in total
 * - whole report weights: 25.3 MB
 *
 * Results for 90KB jpeg:
 * - ~1s of download
 * - ~1s of report production from data urls stored in memory
 * - ~2s in total
 * - whole report weights: 4.2 MB
 */

// By default jpeg is downloaded, to test with png change this to true.
const isPngInsteadOfJpeg = true

const jpegImgUrls = ['']

const pngImgUrls = [
  'assets/images/grafico_imprimir.png',
  // 'https://i.imgur.com/m0j2VWN.png',
  // 'https://i.imgur.com/q3ly31x.png',
  // 'https://i.imgur.com/FCwM9AI.png',
  // 'https://i.imgur.com/JtEFlc3.png',
  // 'https://i.imgur.com/P9G5n8a.png',
  // 'https://i.imgur.com/18WZPGx.png',
  // 'https://i.imgur.com/lU3fAny.png',
  // 'https://i.imgur.com/CfraJq7.png',
  // 'https://i.imgur.com/GKlLqiK.png',
  // 'https://i.imgur.com/uF3t9na.png',
  // 'https://i.imgur.com/rH2xHh4.png',
  // 'https://i.imgur.com/WnDnTdR.png',
  // 'https://i.imgur.com/Gdqasrj.png',
  // 'https://i.imgur.com/aWqZYPf.png',
  // 'https://i.imgur.com/muyD0m4.png',
  // 'https://i.imgur.com/ERqNgwg.png',
  // 'https://i.imgur.com/MGb8Cs2.png',
  // 'https://i.imgur.com/JnelIKr.png',
  // 'https://i.imgur.com/jY2UQdh.png',
  // 'https://i.imgur.com/drRNgY2.png',
  // 'https://i.imgur.com/y11qlUA.png',
  // 'https://i.imgur.com/0fSIHFy.png',
  // 'https://i.imgur.com/jF3arlq.png',
  // 'https://i.imgur.com/HGoYP02.png',
  // 'https://i.imgur.com/ixpiqPL.png',
  // 'https://i.imgur.com/kE45L6M.png',
  // 'https://i.imgur.com/4YcrBc9.png',
  // 'https://i.imgur.com/sUfViKp.png',
  // 'https://i.imgur.com/Trpo8SR.png',
  // 'https://i.imgur.com/z4JCqpJ.png',
  // 'https://i.imgur.com/WlH5i17.png',
  // 'https://i.imgur.com/d6GCq35.png',
  // 'https://i.imgur.com/psGJbq2.png',
  // 'https://i.imgur.com/s89Cgob.png',
  // 'https://i.imgur.com/TWN195F.png',
  // 'https://i.imgur.com/NGTd9me.png',
  // 'https://i.imgur.com/24oVwdk.png',
  // 'https://i.imgur.com/8XLSmFp.png',
  // 'https://i.imgur.com/29tRfv4.png',
  // 'https://i.imgur.com/HpeRsgD.png',
  // 'https://i.imgur.com/cwKqJ66.png',
  // 'https://i.imgur.com/TM9X3hj.png',
  // 'https://i.imgur.com/d5falVs.png',
  // 'https://i.imgur.com/FV8fWJL.png',
  // 'https://i.imgur.com/S3bUq5A.png',
  // 'https://i.imgur.com/STvCKjb.png',
  // 'https://i.imgur.com/p2RO8mB.png',
  // 'https://i.imgur.com/tGpsjVU.png',
  // 'https://i.imgur.com/rwhrUo0.png',
]

const imgUrls = isPngInsteadOfJpeg ? pngImgUrls : jpegImgUrls
const PAGE_WIDTH = 820
const PAGE_HEIGHT = 620
const PHOTO_WIDTH = 800
const PAGE_MARGIN_HORIZONTAL = (PAGE_WIDTH - PHOTO_WIDTH) / 2
const PAGE_MARGIN_VERTICAL = 30

@Component({
  selector: 'convertirGraficaPdf',
  templateUrl: './convertirGraficaPdf.component.html',
  styleUrl: './convertirGraficaPdf.component.scss',
})
export class ConvertirGraficaPdfComponent {
  status = ''

  async downloadPdf() {
    const pdf = pdfMake
      .createPdf((await this.getDocumentDefinition()) as any)
      .download()
  }

  private async getDocumentDefinition() {
    this.updateStatus('Downloading images...')
    const imageDataUrls = await Promise.all(
      imgUrls.map((url) => this.getImageDataUrl(url))
    )
    this.updateStatus('Generating report from those images...')
    const imageDefinitions = imageDataUrls.map((url) => ({
      image: url,
      width: PHOTO_WIDTH,
    }))
    return {
      content: ['Progress report', ...imageDefinitions],
      pageSize: { width: PAGE_WIDTH, height: PAGE_HEIGHT },
      pageMargins: [PAGE_MARGIN_HORIZONTAL, PAGE_MARGIN_VERTICAL],
    }
  }

  private async getImageDataUrl(imgUrl: string | URL | Request) {
    const response = await fetch(imgUrl)
    const blob = await response.blob()
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.onload = () => resolve(reader.result)
      reader.readAsDataURL(blob)
    })
  }

  private updateStatus(text: string) {
    console.log(`${new Date()} ${text}`)
  }
}
