import { HttpClient } from '@angular/common/http'
import { Component, ElementRef, ViewChild } from '@angular/core'
import { Chart, registerables } from 'chart.js'
import { GraphService } from '../../services/graficos/graph.service'
import { FileUploadService } from '../../services/files/fileUpload.service'

@Component({
  selector: 'app-grafico-contribucion',
  templateUrl: './grafico-contribucion.component.html',
  styleUrl: './grafico-contribucion.component.scss',
})
export class GraficoContribucionComponent {
  data: any[] = []
  chart: Chart
  @ViewChild('contribucionProv', { static: true })
  contribucionProv: ElementRef<HTMLCanvasElement>
  constructor(
    private http: HttpClient,
    private graphService: GraphService,
    private fileService: FileUploadService
  ) {
    Chart.register(...registerables)
  }
  ngOnInit() {
    this.loadCSVData()
  }
  loadCSVData() {
    this.http
      .get('../../../assets/data/contribucion_relativa_provincias.csv', {
        responseType: 'text',
      })
      .subscribe((csvData) => {
        this.data = this.fileService.parseCSVData(csvData)
        this.updateChart()
      })
  }
  groupByProvince(data: any[]): { labels: string[]; percentages: number[] } {
    const groupedData: { [key: string]: number } = {}
    data.forEach((item) => {
      const province = this.graphService.getProvince(item.provdest)
      const contribution = parseFloat(item.contribucion_relativa || '0')
      if (province) {
        groupedData[province] = (groupedData[province] || 0) + contribution
      }
    })
    return {
      labels: Object.keys(groupedData),
      percentages: Object.values(groupedData),
    }
  }
  updateChart() {
    const graphElement = this.contribucionProv.nativeElement
    const groupedData = this.groupByProvince(this.data)
    // const labels = this.data.map((item) =>
    //   this.graphService.getProvince(item.provdest)
    // )
    // const percentages = this.data.map((item) =>
    //   parseFloat(item.contribucion_relativa || '0')
    // )
    // const labelsWithoutUndefined = labels.filter((prov) => prov !== undefined)
    // console.log('labeeels..', labels)
    const chartConfig = {
      type: 'pie',
      data: {
        labels: groupedData.labels, // Provincias como etiquetas
        datasets: [
          {
            data: groupedData.percentages, // Porcentajes como datos
            backgroundColor: [
              '#FF6384',
              '#36A2EB',
              '#FFCE56',
              '#4BC0C0',
              '#9966FF',
            ], // Colores para cada sección
          },
        ],
      },
      options: {
        responsive: true,
        plugins: {
          datalabels: false,
          tooltip: {
            callbacks: {
              label: function (context: any) {
                const label = context.label || ''
                const value = context.raw || 0
                return `${label}: ${value}%` // Personalizar tooltip con símbolo %
              },
            },
          },
          legend: {
            position: 'right',
          },
          title: {
            display: true,
            text: 'Contribución Relativa de Provincias al Gasto Total Regional',
            font: {
              size: 24,
            },
            padding: {
              top: 10,
              bottom: 30,
            },
          },
        },
      },
    }
    this.chart = new Chart(graphElement, chartConfig as any)
  }
}
