<div class="mt-5">
  Provincia:
  <select
    class="me-3"
    [(ngModel)]="selectedProvince"
    (change)="onProvinceChange($event)"
  >
    <option *ngFor="let province of provinces" [value]="province">
      {{ province }}
    </option>
  </select>
</div>
<div>
  <canvas #volumenTurista id="volumenTurista"></canvas>
  <div #rangeSlider id="rangeSlider" class="slider mt-5"></div>
</div>
<div class="mt-5">
  <small
    >Estos datos han sido extraidos de la encuesta de ocupación hotelera del
    INE.</small
  >
</div>
