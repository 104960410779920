import { Component } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'

@Component({
  selector: 'modal-cambio-hotel',
  templateUrl: './modal-cambio-hotel.component.html',
  styleUrls: ['./modal-cambio-hotel.component.scss'],
})
export class ConfirmarCambioHotelModalComponent {
  constructor(
    public dialogRef: MatDialogRef<ConfirmarCambioHotelModalComponent>
  ) {}

  cancelar() {
    this.dialogRef.close(false)
  }

  confirmar() {
    this.dialogRef.close(true)
  }
}
