<div class="mt-5">
  Provincia:
  <select
    class="me-3"
    [(ngModel)]="selectedProvince"
    (change)="onProvinceChange($event)"
  >
    <option *ngFor="let province of provinces" [value]="province">
      {{ province }}
    </option>
  </select>
</div>
<div class="row">
  <div class="col-6">
    <canvas #provinciaOcupacionCanvas id="provinciaOcupacionCanvas"></canvas>
  </div>
  <div class="col-6">
    <canvas #provinciaCanvas id="provinciaCanvas"></canvas>
  </div>
  <div #rangeSlider id="rangeSlider" class="slider mt-5"></div>
</div>
