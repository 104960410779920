<!-- <head>
    <script src="https://cdn.plot.ly/plotly-2.35.0.min.js" charset="utf-8"></script>
</head>
<body>
<plotly-plot [data]="graph.data" [layout]="graph.layout"></plotly-plot>
</body> -->

<div class="graph pt-3 mt-5">
  <canvas #rangoEdad id="rangoEdad"></canvas>
</div>
