import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent {
  isChanged:any;
  constructor(private route: ActivatedRoute){
    this.isChanged = this.route.snapshot.paramMap.get('isChanged');
    console.log("isChanged: ", this.isChanged);
    
  }

}

// -----------------------------
  //  Count Up
  // -----------------------------
  // function counter() {
  //   var oTop;
  //   if ($('.count').length !== 0) {
  //     oTop = $('.count').offset().top - window.innerHeight;
  //   }
  //   if ($(window).scrollTop() > oTop) {
  //     $('.count').each(function () {
  //       var $this = $(this),
  //         countTo = $this.attr('data-count');
  //       $({
  //         countNum: $this.text()
  //       }).animate({
  //         countNum: countTo
  //       }, {
  //         duration: 1000,
  //         easing: 'swing',
  //         step: function () {
  //           $this.text(Math.floor(this.countNum));
  //         },
  //         complete: function () {
  //           $this.text(this.countNum);
  //         }
  //       });
  //     });
  //   }
  // }
  // $(window).on('scroll', function () {
  //   counter();
  // });