<form [formGroup]="QRcodeForm" (ngSubmit)="submitSignupTOTPcode()">
  <div id="login-wrapper" class="login-wrapper">
    <p><strong>Vincular tu aplicación de MFA</strong></p>
    <ul>
      <li>Descarga una aplicación de MFA si no tienes una</li>
      <li>Escanea el siguiente QR usando tu aplicación de MFA</li>
      <li>Introduce aquí el código que se te muestre</li>
    </ul>
    <div class="d-flex flex-column gap-2">
      <img width="200" class="align-self-center" [src]="QRUrl" alt="QR Code" />
      <input
        type="text"
        name="code"
        class="form-control mb-3"
        formControlName="code"
        placeholder="Código de verificación"
      />
      <button class="btn login_btn btn-primary text-center w-100 rounded-pill">
        @if (waiting) {
          <img
            width="20"
            src="assets/images/icons/loading-white.svg"
            alt="Loading"
          />
        } @else {
          Vincular cuenta
        }
      </button>
    </div>
  </div>
</form>
