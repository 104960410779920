import { Component, OnInit } from '@angular/core'
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  NgForm,
  ValidatorFn,
  Validators,
} from '@angular/forms'
import { Iuser } from '../../services/users/iuser'
import { UserDataService } from '../../services/users/userData.service'
import { TiempoService } from '../../services/tiempo/tiempo.service'
import { MunicipiosService } from '../../services/municipios/municipios.service'
import { switchMap, tap } from 'rxjs'
import { ToastrService } from 'ngx-toastr'
import { AuthService } from '../../services/auth/auth.service'
import { passwordRequirements } from '../../../utils/validators'

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrl: './account.component.scss',
})
export class AccountComponent implements OnInit {
  // usuario: Iuser = { id: 0, email: '', clave: '' };
  provincias = [
    { value: 'Toledo', label: 'Toledo' },
    { value: 'Cuenca', label: 'Cuenca' },
    { value: 'Guadalajara', label: 'Guadalajara' },
    { value: 'Albacete', label: 'Albacete' },
    { value: 'Ciudad Real', label: 'Ciudad Real' },
  ]
  userForm: FormGroup
  email: string | null = null
  newPassword: string | null = null
  newPassword2: string | null = null
  password: string = ''
  passwordForm: FormGroup
  postal_code: boolean = false
  city: any[] = []
  province?: string
  codigoProvincia: string = ''
  userData?: Iuser
  constructor(
    private userDataService: UserDataService,
    private fb: FormBuilder,
    private municipiosService: MunicipiosService,
    private toastr: ToastrService,
    private authService: AuthService
  ) {
    this.userForm = this.fb.group({
      name: ['', Validators.required],
      email: [''],
      phone: ['', Validators.required],
      province: ['', Validators.required],
      city: ['', Validators.required],
      // fecha: [''],
      address: [''],
      postal_code: [
        '',
        [Validators.required, Validators.minLength(5), Validators.maxLength(5)],
      ],
    })

    this.passwordForm = this.fb.group(
      {
        password: ['', Validators.required],
        newPassword: ['', Validators.required],
        newPassword2: ['', Validators.required],
      },
      { validators: [this.matchValidator('newPassword', 'newPassword2'), passwordRequirements('newPassword')] }
    )
  }

  ngOnInit(): void {
    this.userForm.get('postal_code')?.valueChanges.subscribe(() => {
      this.validarCodigoPostal()
    })

    this.userForm.get('province')?.valueChanges.subscribe(() => {
      this.userForm.get('postal_code')?.markAsTouched() // Marcar el campo de código postal como tocado
      this.validarCodigoPostal() // Validar cuando cambia la provincia
    })

    this.userForm.get('email')?.disable()

    this.userDataService
      .getUserInfo()
      .pipe(
        tap((data) => {
          this.userData = data
          // Primero rellenamos el formulario con los datos del usuario sin el municipio
          this.userForm.patchValue({
            name: this.userData.name,
            email: this.userData.email,
            province: this.userData.province,
            postal_code: this.userData.postal_code,
            phone: this.userData.phone,
            address: this.userData.address,
          })

          // Guardamos la provincia para la próxima llamada
          this.province = this.userData.province
        }),
        // Luego, obtenemos los municipios de esa provincia
        switchMap(() => this.municipiosService.getMunicipios(this.province!))
      )
      .subscribe((municipios) => {
        // Al completarse la llamada a municipios, los asignamos y completamos el formulario
        this.city = municipios
        this.userForm.get('city')?.setValue(this.userData!.city)
      })
  }

  onProvinciaChange(event: any) {
    this.province = event.target.value
    console.log('provincia cambiada', this.province)
    if (this.province) {
      this.municipiosService
        .getMunicipios(this.province)
        .subscribe((municipios) => {
          this.city = municipios
          if (
            (!this.userForm.get('municipio')?.value && this.city.length > 0) ||
            this.city.length > 0
          ) {
            this.userForm.get('municipio')?.setValue(this.city[0])
          }
        })
    }
    this.city = []
  }

  matchValidator(
    controlName: string,
    matchingControlName: string
  ): ValidatorFn {
    return (abstractControl: AbstractControl) => {
      const control = abstractControl.get(controlName)
      const matchingControl = abstractControl.get(matchingControlName)

      if (
        matchingControl!.errors &&
        !matchingControl!.errors?.['confirmedValidator']
      ) {
        return null
      }

      if (control!.value !== matchingControl!.value) {
        const error = { confirmedValidator: 'Passwords do not match.' }
        matchingControl!.setErrors(error)
        return error
      } else {
        matchingControl!.setErrors(null)
        return null
      }
    }
  }

  validarCodigoPostal() {
    const codPostal = this.userForm.get('postal_code')?.value
    const provincia = this.userForm.get('province')?.value

    if (
      codPostal?.length === 5 &&
      ((provincia == 'Albacete' && codPostal.substring(0, 2) == '02') ||
        (provincia == 'Ciudad Real' && codPostal.substring(0, 2) == '13') ||
        (provincia == 'Cuenca' && codPostal.substring(0, 2) == '16') ||
        (provincia == 'Guadalajara' && codPostal.substring(0, 2) == '19') ||
        (provincia == 'Toledo' && codPostal.substring(0, 2) == '45'))
    ) {
      this.postal_code = true
    } else {
      this.postal_code = false
    }
  }
  updateUser() {
    this.validarCodigoPostal()
    console.log('userformValue....', this.userForm.value)
    if (this.postal_code) {
      this.userDataService
        .updateUserInfo(this.userForm.value)
        .subscribe((response) => {
          if (response) {
            console.log('Response.......response', response)
            console.log('Response.......user', this.userForm)
            const updatedUser = {
              name: this.userForm.get('name')?.value,
              email: this.userForm.get('email')?.value,
              postal_code: this.userForm.get('postal_code')?.value,
            }
            this.userDataService.setUser(updatedUser)
            // alert('Perfil actualizado con éxito!');
            this.toastr.success(
              'El usuario se ha actualizado correctamente',
              'Éxito'
            )
            console.log('Usuario actualizado ', response)
          } else {
            console.error('Error al actualizar el perfil: ')
            //  alert('Ha ocurrido un error al crear el usuario. Por favor, inténtelo más tarde.')
            this.toastr.error(
              'Ha ocurrido un error al actualizar el usuario. Por favor, inténtelo más tarde.',
              'Error'
            )
          }
        })
    } else {
      // alert('Código postal inválido para la provincia seleccionada.');
      this.toastr.error(
        'El Código postal es inválido para la provincia seleccionada.',
        'Error'
      )
    }
  }

  async updatePassword() {
    const oldPassword = this.passwordForm.get('password')?.value
    const newPassword = this.passwordForm.get('newPassword')?.value
    const confirmNewPassword = this.passwordForm.get('newPassword2')?.value
    
    if (newPassword === confirmNewPassword) {
      try{
        await this.authService.changePassword(oldPassword, newPassword)
        this.toastr.success(
          'La contraseña ha sido actualizada correctamente',
          'Éxito'
        )
      }catch(err: any){
        if(err.code === 'NotAuthorizedException'){
          this.toastr.error('La contraseña actual no es correcta.', 'Error')
        }else if(err.code === 'InvalidPasswordException'){
          this.toastr.error('La nueva contraseña no cumple con los requisitos mínimos.', 'Error')
        }else if(err.code === 'LimitExceededException'){
          this.toastr.error('Demasiados intentos, prueba otra vez mas tarde.', 'Error')
        }
        else{
          this.toastr.error('Error al actualizar la contraseña', err)
        }
      }
    } else {
      this.toastr.error('Las contraseñas no coinciden.', 'Error')
    }
    this.passwordForm.reset()
  }
}
