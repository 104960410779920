<section class="page-header">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <ul class="list-inline breadcrumbs text-capitalize" style="font-weight:500">
                    <li class="list-inline-item"><a routerLink="/home"><i class="fa-solid fa-house"></i></a>
                    </li>
                    <li class="list-inline-item">| &nbsp; <a href="#">Mis alojamientos</a>
                    </li>
                </ul>
                <h2 class="my-3 text-capitalize fs-3 pt-4 ">Mis alojamientos</h2>

            </div>
        </div>
    </div>
</section>
<section class="section mb-5">
    <div class="container px-5">
        <div class="row justify-content-center mt-5">

            <!-- *ngFor="let hotel of listaHoteles" -->
            <!-- <div class="row my-4">
                    <div class="search-box col-12 col-md-8 col-lg-6 mx-auto position-relative">
                        <i class="fa-solid fa-magnifying-glass position-absolute"></i>
                        <input type="email" class="form-control rounded-pill border-secondary" id="exampleFormControlInput1" placeholder="Buscar tus establecimientos">
                    </div>
            </div> -->
            <div *ngFor="let h of listaHoteles" class="col-lg-4 col-md-6 col-12 mb-4 h-100">
                <app-card-alojamiento [hotel]="h"></app-card-alojamiento>
            </div>
            <!-- <div class="col-lg-4 col-md-6 col-12 mb-4 h-100">
                <app-card-alojamiento [nombreHotel]="nombre" (selectedMove)="prueba($event)" *ngIf="buttonClicked"
                    [hotel]="hotel2"> </app-card-alojamiento>
            </div> -->
            <div class="icon-box-item hotel col-lg-4 col-md-6 mb-4 h-100">
                <div class="rounded py-5 px-4">
                    <div class="icon-box-item new h-100 w-100 col-lg-4 col-md-6 mb-4 rounded">
                        <a routerLink="/data-storage"
                            class="rounded py-4 px-4 d-flex justify-content-center text-decoration-none">
                            <div class="title-box d-flex flex-column">
                                <i class="fa-solid fa-plus text-center my-4 "></i>
                                <h5 class="text-center">Añadir nuevo establecimiento</h5>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>

    </div>
</section>