import { Component } from '@angular/core';

@Component({
  selector: 'app-knowledge-area',
  templateUrl: './knowledge-area.component.html',
  styleUrl: './knowledge-area.component.scss'
})
export class KnowledgeAreaComponent {

}
