<div></div>

<div
  id="carouselGraficas360"
  class="carousel slide carousel-fade"
  data-bs-interval="false"
>
  <div class="carousel-inner">
    <!-- Primer gráfico: gastos por motivo -->
    <div class="carousel-item active graph text-end">
      <canvas #gastosMotiv id="gastosMotiv"></canvas>
    </div>

    <!-- Segundo gráfico: gastos por provincia -->

    <div class="carousel-item graph text-end">
      <app-grafico-gastos-actividades></app-grafico-gastos-actividades>
    </div>
    <div class="carousel-item graph text-center">
      <app-grafico-contribucion></app-grafico-contribucion>
    </div>
  </div>

  <!-- Controles de navegación -->
  <button
    class="carousel-control-prev"
    type="button"
    data-bs-target="#carouselGraficas360"
    data-bs-slide="prev"
  >
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button
    class="carousel-control-next"
    type="button"
    data-bs-target="#carouselGraficas360"
    data-bs-slide="next"
  >
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>
