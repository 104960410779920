import { HttpClient, HttpClientModule } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class TiempoService {
  private apiKey = '58ac79eab98dd04857d7c758e08cc2eb'; //'60915629505bf12c52cb496b05d7096f';
  private apiKeyLoc= '75709e98e23fc27f9b83724d6a9643ac17878e82435522e381320a25160c64ee';
  private apiUrl1 = 'https://api.openweathermap.org/data/2.5/onecall';
  private apiUrl2 = 'https://api.openweathermap.org/geo/1.0/direct';
  private apiUrl3 = 'https://api.openweathermap.org/data/2.5/forecast'
  private apiUrl4 = 'https://apiv1.geoapi.es/';
  constructor(private http: HttpClient) { }

  getLatLon(city: string): Observable<any> {
     const url = `${this.apiUrl2}?q=${city}&limit=1&appid=${this.apiKey}`;
    return this.http.get<any>(url);
  }
  getWeather(city:string): Observable<any> {
    // const url = `${this.apiUrl1}?lat=${lat}&lon=${lon}&exclude=hourly,minutely,current&units=metric&lang=es&appid=${this.apiKey}`; _> de pago
    const url = `${this.apiUrl3}?q=${city}&appid=${this.apiKey}&units=metric`;
    return this.http.get<any>(url); 
  }

  getProvince(code:string): Observable<any> {
    const url = `${this.apiUrl4}municipios?CPRO=${code}&type=JSON&key=${this.apiKeyLoc}`;
    return this.http.get<any>(url);
  }

}
