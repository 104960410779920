import { AbstractControl, FormGroup, ValidatorFn } from '@angular/forms'
import { ValidationErrors } from '@angular/forms'

export function passwordMatchValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const form = control as FormGroup
    return form.get('password')?.value === form.get('confirmPassword')?.value
      ? null
      : { passwordMismatch: true }
  }
}

export function postalCodeValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const form = control as FormGroup
    const postalCode = form.get('postalCode')?.value
    const province = form.get('province')?.value

    if (!postalCode || !province) {
      return null
    }

    const isValid =
      postalCode.length === 5
        ? validatePostalCodeWithProvince(postalCode, province)
        : false

    return isValid ? null : { invalidPostalCode: true }
  }
}

export function municipalityValidator(municipalitiesInProvince: string[]): ValidatorFn {
  return (formGroup: AbstractControl): ValidationErrors | null => {
    const municipality = formGroup.get('municipality').value

    if (!municipality) {
      return null
    }

    return municipalitiesInProvince?.includes(municipality) ? null : { invalidMunicipality: true }
  }
}

function validatePostalCodeWithProvince(postal_code: string, province: string) {
  return (
    (province === 'Albacete' && postal_code.startsWith('02')) ||
    (province === 'Ciudad Real' && postal_code.startsWith('13')) ||
    (province === 'Cuenca' && postal_code.startsWith('16')) ||
    (province === 'Guadalajara' && postal_code.startsWith('19')) ||
    (province === 'Toledo' && postal_code.startsWith('45'))
  )
}

export function passwordRequirements(controlName: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const password = control.get(controlName)?.value

    const hasMinLength = password.length >= 8
    const hasUpperCase = /[A-Z]/.test(password)
    const hasLowerCase = /[a-z]/.test(password)
    const hasNumber = /[0-9]/.test(password)
    const hasSpecialChar = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(
      password
    )

    const passwordValid =
      hasMinLength &&
      hasUpperCase &&
      hasLowerCase &&
      hasNumber &&
      hasSpecialChar

    if (passwordValid) return null

    return {
      invalidPassword: true,
      needsMinLength: !hasMinLength,
      needsUpperCase: !hasUpperCase,
      needsLowerCase: !hasLowerCase,
      needsNumber: !hasNumber,
      needsSpecialChar: !hasSpecialChar,
    }
  }
}
