<form
  class="ng-pristine ng-invalid ng-touched"
  [formGroup]="signinForm"
  (ngSubmit)="signIn()"
>
  <div id="login-wrapper" class="login-wrapper">
    <div class="mb-3">
      <label for="exampleFormControlInput1" class="form-label">Email</label>
      <input
        type="text"
        class="form-control"
        name="email"
        id="email"
        formControlName="email"
        (blur)="signinForm.get('email')?.markAsTouched()"
      />
    </div>
    @if (
      signinForm.get('email')?.hasError('required') &&
      signinForm.get('email')?.touched
    ) {
      <div>
        <small class="text-danger">El email es obligatorio.</small>
      </div>
    }
    @if (
      signinForm.get('email')?.hasError('pattern') &&
      signinForm.get('email')?.dirty
    ) {
      <div>
        <small class="text-danger">El email no es válido.</small>
      </div>
    }

    <div class="mb-3">
      <label for="exampleFormControlInput1" class="form-label"
        >Contraseña</label
      >
      <input
        type="password"
        id="inputPassword5"
        class="form-control"
        name="password"
        aria-describedby="passwordHelpBlock"
        formControlName="password"
        (blur)="signinForm.get('passwordLogin')?.markAsTouched()"
      />
    </div>
    @if (
      signinForm.get('passwordLogin')?.hasError('required') &&
      signinForm.get('passwordLogin')?.touched
    ) {
      <div>
        <small class="text-danger">La contraseña es obligatoria.</small>
      </div>
    }
    @if (loginError == true) {
      <div>
        <small class="text-danger"
          >La contraseña o el usuario no son correctos.</small
        >
      </div>
    }
    <div
      class="d-flex justify-content-between"
      (click)="startResetPasswordFlow()"
    >
      <p role="button">¿Olvidaste tu contraseña?</p>
    </div>
    <div class="d-flex flex-column pb-2 mt-4">
      <button
        type="submit"
        name="button"
        class="btn login_btn btn-primary text-center w-100 rounded-pill"
      >
        @if (waiting) {
          <img
            width="20"
            src="assets/images/icons/loading-white.svg"
            alt="Loading"
          />
        } @else {
          Login
        }
      </button>
    </div>
  </div>
</form>
