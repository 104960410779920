import { Injectable } from '@angular/core'
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http'
import { catchError, Observable, throwError, from } from 'rxjs'
import { AuthService } from '../services/auth/auth.service'
import { Router } from '@angular/router'
import { switchMap } from 'rxjs/operators'

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private auth: AuthService,
    private router: Router
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Skip auth for external APIs
    if (
      request.url.includes('openweathermap') ||
      request.url.includes('geoapi') ||
      request.url.includes('usuarios/registro') ||
      request.url.includes('auth/login')
      // TODO -> Ver la manera de no tener que estar metiendo aquí lo que no
      // necesita token
    ) {
      return next.handle(request)
    }

    return from(this.auth.getToken()).pipe(
      switchMap((token) => {
        if (token) {
          request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${token}`,
            },
          })
        }
        return next.handle(request).pipe(
          catchError((error: HttpErrorResponse) => {
            if (error.status === 401 || error.status === 403) {
              this.auth.logout()
              this.router.navigate(['/home'])
            }
            return throwError(() => error)
          })
        )
      })
    )
  }
}
