import { Injectable } from '@angular/core'
import { BehaviorSubject, catchError, Observable } from 'rxjs'
import { Hotel } from './hotelModel'
import { v4 as uuidv4 } from 'uuid'
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { AuthService } from '../auth/auth.service'
import { environment } from '../../../environments/environment'
import { ToastrService } from 'ngx-toastr'

@Injectable({
  providedIn: 'root',
})
export class HotelDataService {
  // Creamos un BehaviorSubject con un array vacío de usuarios.
  private hotelsState = new BehaviorSubject<Hotel[]>([])

  // Exponemos el observable para que los componentes puedan suscribirse.
  hotelstate$ = this.hotelsState.asObservable()

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
    private toastr: ToastrService
  ) {
    const hotelesGuardados = JSON.parse(localStorage.getItem('hoteles') || '[]')

    this.hotelsState.next(hotelesGuardados)
  }

  addHotel(data: Hotel): Observable<any> {
    console.log('DATOS DEL HOTEL', data)
    console.log('DATOS DEL HOTEL MUNICIPIO', data.city)
    return this.httpClient.post(environment.addHotelUrl, data)
  }

  getHoteles() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    })
    //this.apiUrl
    this.httpClient.get(environment.getHotelesUrl, { headers }).subscribe({
      next: (r: any) => {
        this.hotelsState.next(r)
        console.log(r)
      },
      error: (e) => {
        console.log('Error al recuperar hoteles', e)
      },
    })
  }

  // Método para eliminar el hotel por su índice.
  removeHotel(uid: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    })
    const url = environment.removeHotelUrl.replace('{uid}', uid.toString())
    this.httpClient.delete(url, { headers }).subscribe({
      next: (r: any) => {
        this.getHoteles() //listamos los hoteles creados
      },
      error: (e) => {
        alert(
          'Ha habido algún problema al eliminar el hotel. Intentelo más tarde.'
        )
      },
    })
  }

  getEstablishmentData(name: string, type: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    })
    const url = environment.getEstablishmentDataUrl
      .replace('{establecimiento}', name)
      .replace('{tipoEstablecimiento}', type)
    return this.httpClient.get(url, { headers })
  }

  autocompleteHotelName(name: string, type: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    })
    const params = new HttpParams()
      .set('establishment_name', name)
      .set('establishment_type', type)
    console.log('url autocompletar......', params)
    return this.httpClient
      .get(environment.autocompleteName, { headers, params })
      .pipe(
        // Aquí accedemos a 'resultados' de la respuesta JSON
        catchError((error) => {
          console.error('Error al obtener los datos:', error)
          return [] // En caso de error, retornamos un array vacío
        })
      )
  }
}
