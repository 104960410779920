<form
  class="ng-pristine ng-invalid ng-touched"
  [formGroup]="registerForm"
  (ngSubmit)="register()"
>
  <div id="register-wrapper" class="register-wrapper">
    <div class="mb-3">
      <label for="exampleFormControlInputA" class="form-label"
        >Email<span class="text-danger">*</span></label
      >
      <input
        type="email"
        class="form-control"
        name="email"
        id="exampleFormControlInputA"
        formControlName="email"
      />
      <div class="errors">
        @if (
          registerForm.controls['email'].invalid &&
          registerForm.controls['email'].touched
        ) {
          <small class="text-danger">El email es obligatorio.</small>
        }
        @if (userAlreadyExists) {
          <small class="text-danger">El email ya existe</small>
        }
      </div>
    </div>
    <div class="mb-3">
      <label for="username" class="form-label"
        >Nombre<span class="text-danger">*</span></label
      >
      <input
        type="text"
        class="form-control"
        name="username"
        formControlName="username"
      />
      <div class="errors">
        @if (
          registerForm.controls['username'].invalid &&
          registerForm.controls['username'].touched
        ) {
          <small class="text-danger">El nombre de usuario no es válido</small>
        }
      </div>
    </div>
    <div class="mb-3">
      <label for="province" class="form-label"
        >Provincia<span class="text-danger">*</span></label
      >
      <select
        id="provinces"
        name="province"
        class="form-control"
        formControlName="province"
      >
        <option disabled selected>Provincias</option>
        <option value="Albacete">Albacete</option>
        <option value="Ciudad Real">Ciudad Real</option>
        <option value="Cuenca">Cuenca</option>
        <option value="Guadalajara">Guadalajara</option>
        <option value="Toledo">Toledo</option>
      </select>

      <div class="errors">
        @if (
          registerForm.controls['province'].invalid &&
          registerForm.controls['province'].touched
        ) {
          <small class="text-danger">La provincia no es válida</small>
        }
      </div>
    </div>
    <div class="mb-3">
      <label for="municipality" class="form-label"
        >Municipio<span class="text-danger">*</span></label
      >
      <input
        list="municipalities"
        name="municipality"
        class="form-control"
        formControlName="municipality"
      />
      <datalist id="municipalities">
        @for (municipality of municipalitiesInProvince; track municipality) {
          <option value="{{ municipality }}"></option>
        }
      </datalist>

      <div class="errors">
        @if (
          (registerForm.controls['municipality'].invalid &&
            registerForm.controls['municipality'].touched) ||
          registerForm.errors?.['invalidMunicipality']
        ) {
          <small class="text-danger">El municipio no es válido</small>
        }
      </div>
    </div>
    <div class="mb-3">
      <label for="postalCode" class="form-label"
        >Código postal<span class="text-danger">*</span></label
      >
      <input
        type="text"
        class="form-control"
        name="postalCode"
        formControlName="postalCode"
      />
      <div class="errors">
        @if (
          (registerForm.controls['postalCode'].invalid &&
            registerForm.controls['postalCode'].touched) ||
          registerForm.errors?.['invalidPostalCode']
        ) {
          <small class="text-danger">El código postal no es válido</small>
        }
      </div>
    </div>
    <div class="mb-3">
      <label for="exampleFormControlInputC" class="form-label"
        >Contraseña <span class="text-danger">*</span></label
      >
      <input
        type="password"
        id="inputPasswordR"
        class="form-control"
        name="inputPasswordR"
        aria-describedby="passwordHelpBlock"
        formControlName="password"
      />
      <div class="errors">
        @if (
          registerForm.controls['password'].invalid &&
          registerForm.controls['password'].touched
        ) {
          <small class="text-danger">La contraseña es obligatoria.</small>
        }
        @if (
          registerForm.get('password')?.touched &&
          registerForm.hasError('invalidPassword')
        ) {
          <ul class="mt-1">
            @if (registerForm.hasError('needsMinLength')) {
              <li class="text-danger">
                La contraseña debe tener al menos 8 caracteres.
              </li>
            }
            @if (registerForm.hasError('needsUpperCase')) {
              <li class="text-danger">
                La contraseña debe tener al menos una mayúscula.
              </li>
            }
            @if (registerForm.hasError('needsLowerCase')) {
              <li class="text-danger">
                La contraseña debe tener al menos una minúscula.
              </li>
            }
            @if (registerForm.hasError('needsNumber')) {
              <li class="text-danger">
                La contraseña debe tener al menos un número.
              </li>
            }
            @if (registerForm.hasError('needsSpecialChar')) {
              <li class="text-danger">
                La contraseña debe tener al menos un carácter especial.
              </li>
            }
          </ul>
        }
      </div>
    </div>
    <div class="mb-3">
      <label for="exampleFormControlInputD" class="form-label"
        >Verificar contraseña <span class="text-danger">*</span>
      </label>
      <input
        type="password"
        id="inputPasswordD"
        class="form-control"
        name="verifyPassword"
        aria-describedby="passwordHelpBlock"
        formControlName="confirmPassword"
      />
      <div class="errors">
        @if (
          registerForm.hasError('passwordMismatch') &&
          (registerForm.controls['confirmPassword'].touched ||
            registerForm.controls['password'].touched)
        ) {
          <small class="text-danger">Las contraseñas no coinciden.</small>
        }
      </div>
    </div>
    <div class="d-flex flex-column pb-2 mt-4">
      <button
        type="submit"
        name="button"
        [disabled]="registerForm.invalid"
        class="btn login_btn btn-primary text-center w-100 rounded-pill"
      >
        @if (waiting) {
          <img
            width="20"
            src="assets/images/icons/loading-white.svg"
            alt="Loading"
          />
        } @else {
          Crear nueva cuenta
        }
      </button>
    </div>
  </div>
</form>
