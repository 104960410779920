import { Component, ElementRef, ViewChild } from '@angular/core'
import * as Plotly from 'plotly.js-dist-min'
import { HttpClient } from '@angular/common/http'
import { Chart, ChartConfiguration, ChartType, registerables } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { GraphService } from '../../services/graficos/graph.service'
import { FileUploadService } from '../../services/files/fileUpload.service'
import { tooltip } from 'leaflet'

@Component({
  selector: 'app-grafico-tipo-turista',
  templateUrl: './grafico-TipoTurista.component.html',
  styleUrls: ['./grafico-TipoTurista.component.scss'],
})
export class GraficoTipoTuristaComponent {
  // graph: any = {};
  // resultado: any = {};
  chartViajes: Chart | undefined
  chartMotivos: Chart | undefined
  chartTipoTurista: Chart | undefined
  years: string[] = []
  selectedYearTipoTuristas: string = ''
  selectedYearMotivoTurismo: string = ''
  data: any[] = []
  yearsWithoutUndefined: string[] = []

  @ViewChild('tipoViajes', { static: true })
  canvasRef!: ElementRef<HTMLCanvasElement>
  @ViewChild('motivoTurismo', { static: true })
  canvasElRef!: ElementRef<HTMLCanvasElement>

  constructor(
    private http: HttpClient,
    private graphService: GraphService,
    private fileService: FileUploadService
  ) {
    Chart.register(...registerables)
  }

  ngOnInit(): void {
    this.loadCSVData()
  }

  loadCSVData(): void {
    this.http
      .get('../../../assets/data/output_turista360.csv', {
        responseType: 'text',
      })
      .subscribe((data) => {
        this.data = this.fileService.parseCSVData(data)

        this.years = Array.from(new Set(this.data.map((row) => row.anyo)))

        this.yearsWithoutUndefined = this.years.filter(
          (year) => year !== undefined
        )

        if (this.yearsWithoutUndefined.length > 0) {
          this.selectedYearTipoTuristas = this.yearsWithoutUndefined[0]
          this.selectedYearMotivoTurismo = this.yearsWithoutUndefined[0]
          this.updateChartViajes()
          this.updateChartMotiv()
          // this.updateChartTipo()
        }
      })
  }

  // updateChartTipo(): void {
  //   const canvasElement = this.canvasRefTur.nativeElement

  //   if (this.chartTipoTurista) {
  //     this.chartTipoTurista.destroy()
  //   }

  //   const chartConfig = {
  //     type: 'doughnut',
  //     data: {
  //       labels: [
  //         'Familias en vacaciones',
  //         'Mochileros o viajeros de bajo presupuesto',
  //         'Viajeros de experiencias',
  //         'Turistas de negocios',
  //         'Viajeros de lujo',
  //       ],
  //       datasets: [
  //         {
  //           label: 'Tipos de viajes',
  //           data: [40, 25, 20, 10, 5],

  //           backgroundColor: [
  //             'rgba(255, 99, 132, 1.0)',
  //             'rgba(54, 162, 235, 1.0)',
  //             'rgba(255, 206, 86, 1.0)',
  //             'rgba(75, 192, 192, 1.0)',
  //             'rgba(153, 102, 255, 1.0)',
  //           ],
  //         },
  //       ],
  //     },
  //     options: {
  //       layout: {
  //         padding: {
  //           top: 20,
  //           bottom: 20,
  //         },
  //       },
  //       responsive: true,
  //       maintainAspectRatio: false,
  //       plugins: {
  //         title: {
  //           display: true,
  //           text: 'Distribución de tipos de turistas',
  //           font: {
  //             size: 24,
  //           },
  //           padding: {
  //             top: 10,
  //             bottom: 30,
  //           },
  //         },
  //         legend: {
  //           display: true,
  //           position: 'left',
  //           align: 'end',
  //         },

  //         tooltip: {
  //           callbacks: {
  //             label: function (context: any) {
  //               const value = context.raw || 0
  //               return `${value}%`
  //             },
  //           },
  //         },
  //         datalabels: false,
  //       },

  //       animation: {
  //         animateRotate: true,
  //         animateScale: false,
  //       },
  //     },
  //   }
  //   this.chartTipoTurista = new Chart(canvasElement, chartConfig as any)
  // }
  updateChartViajes(): void {
    const filteredData = this.graphService.filterDataByYear(
      this.data,
      this.selectedYearTipoTuristas
    )
    const percentages = this.graphService.calculatePercentages(filteredData)
    const tiposViajes: any = {
      1: { label: 'Viajes de puente', color: 'rgba(255, 99, 132, 1.0)' },
      2: { label: 'Viajes de fin de semana', color: 'rgba(54, 162, 235, 1.0)' },
      3: { label: 'Trabajo', color: 'rgba(255, 206, 86, 1.0)' },
      4: { label: 'Estudio', color: 'rgba(75, 192, 192, 1.0)' },
      5: {
        label: 'Desplazamiento al centro de estudio',
        color: 'rgba(153, 102, 255, 1.0)',
      },
      6: {
        label: 'Desplazamiento al centro de trabajo',
        color: 'rgba(255, 159, 64, 1.0)',
      },
      7: { label: 'Vacaciones de verano', color: 'rgba(255, 182, 193, 1.0)' },
      8: { label: 'Vacaciones de Navidad', color: 'rgba(0, 255, 127, 1.0)' },
      9: {
        label: 'Vacaciones de Semana Santa',
        color: 'rgba(173, 255, 47, 1.0)',
      },
      10: { label: 'Otros viajes', color: 'rgba(0, 0, 139, 1.0)' },
    }

    if (this.chartViajes) {
      this.chartViajes.destroy()
    }

    const canvasElement = this.canvasRef.nativeElement
    const selectedYearData = percentages[this.selectedYearTipoTuristas as any]
    const labels = []
    const data = []
    const backgroundColors = []

    for (const [type, percentage] of Object.entries(selectedYearData)) {
      if (percentage > 0) {
        const turismo = tiposViajes[+type]
        labels.push(turismo.label)
        data.push(percentage)
        backgroundColors.push(turismo.color)
      }
    }

    const chartConfig = {
      type: 'doughnut',
      data: {
        labels: labels,
        datasets: [
          {
            label: 'Tipos de viajes',
            data: data,

            backgroundColor: backgroundColors,
          },
        ],
      },
      options: {
        layout: {
          padding: {
            top: 20,
            bottom: 20,
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          title: {
            display: true,
            text: 'Distribución de tipos de viajes',
            font: {
              size: 24,
            },
            padding: {
              top: 10,
              bottom: 30,
            },
          },
          legend: {
            display: true,
            position: 'left',
            align: 'end',
          },
          tooltip: {
            callbacks: {
              label: function (context: any) {
                const value = context.raw || 0
                return `${value}%`
              },
            },
          },
          datalabels: false,
        },
        animation: {
          animateRotate: true,
          animateScale: false,
        },
      },
    }
    this.chartViajes = new Chart(canvasElement, chartConfig as any)
  }
  onYearChange(event: any): void {
    this.selectedYearTipoTuristas = event.target.value
    this.updateChartViajes()
    this.updateChartMotiv()
  }

  updateChartMotiv(): void {
    const filteredData = this.graphService.filterDataByYear(
      this.data,
      this.selectedYearMotivoTurismo
    )
    const percentages =
      this.graphService.calculatePercentagesMotiv(filteredData)
    const tiposTurismo: any = {
      1: { label: 'Turismo de sol y playa', color: 'rgba(255, 99, 132, 1.0)' },
      2: { label: 'Turismo cultural', color: 'rgba(54, 162, 235, 1.0)' },
      3: { label: 'Turismo de naturaleza', color: 'rgba(255, 206, 86, 1.0)' },
      4: { label: 'Turismo gastronómico', color: 'rgba(75, 192, 192, 1.0)' },
      5: { label: 'Turismo deportivo', color: 'rgba(153, 102, 255, 1.0)' },
      6: {
        label: 'Turismo termal y de bienestar',
        color: 'rgba(255, 159, 64, 1.0)',
      },
      7: {
        label: 'Otro tipo de turismo de ocio',
        color: 'rgba(255, 182, 193, 1.0)',
      },
      8: {
        label: 'Visitas a familiares o amigos',
        color: 'rgba(0, 255, 127, 1.0)',
      },
      9: { label: 'Turismo de compras', color: 'rgba(173, 255, 47, 1.0)' },
      10: {
        label: 'Desplazamiento al centro de estudios habitual',
        color: 'rgba(0, 0, 139, 1.0)',
      },
      11: {
        label: 'Otros motivos de educación y formación',
        color: 'rgba(60, 179, 113, 1.0)',
      },
      12: { label: 'Tratamiento de salud', color: 'rgba(238, 130, 238, 1.0)' },
      13: {
        label: 'Motivos religiosos o peregrinaciones',
        color: 'rgba(0, 128, 128, 1.0)',
      },
      14: { label: 'Incentivos', color: 'rgba(135, 206, 250, 1.0)' },
      15: {
        label: 'Congresos, ferias y convenciones',
        color: 'rgba(106, 90, 205, 1.0)',
      },
      16: {
        label: 'Desplazamiento al centro de trabajo',
        color: 'rgba(139, 69, 19, 1.0)',
      },
      17: {
        label: 'Otros motivos personales',
        color: 'rgba(220, 20, 60, 1.0)',
      },
      18: {
        label: 'Otros motivos profesionales',
        color: 'rgba(128, 128, 0, 1.0)',
      },
    }

    if (this.chartMotivos) {
      this.chartMotivos.destroy()
    }

    const canvasElement = this.canvasElRef.nativeElement
    const selectedYearData =
      percentages[this.selectedYearMotivoTurismo as any] || {}
    const labels = []
    const data = []
    const backgroundColors = []

    for (const [type, percentage] of Object.entries(selectedYearData)) {
      if (percentage > 0) {
        const turismo = tiposTurismo[+type]
        labels.push(turismo.label)
        data.push(percentage)
        backgroundColors.push(turismo.color)
      }
    }

    const chartConfig = {
      type: 'doughnut',
      data: {
        labels: labels,
        datasets: [
          {
            label: 'Motivos de viajes',
            data: data,
            backgroundColor: backgroundColors,
          },
        ],
      },
      options: {
        layout: {
          padding: {
            top: 20,
            bottom: 50,
          },
        },
        rotation: -0.5 * Math.PI,
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          title: {
            display: true,
            text: 'Distribución de motivos de los viajes',
            font: {
              size: 24,
            },
            padding: {
              top: 0,
              bottom: 30,
            },
          },
          legend: {
            display: true,
            position: 'left',
            align: 'end',
            labels: {
              padding: 10,
              font: {
                size: 12,
              },
            },
            maxHeight: 100,
          },
          tooltip: {
            callbacks: {
              label: function (context: any) {
                const value = context.raw || 0
                return `${value}%`
              },
            },
          },
          datalabels: false,
        },
        animation: {
          animateRotate: true,
          animateScale: false,
        },
      },
    }
    this.chartMotivos = new Chart(canvasElement, chartConfig as any)
  }
  // onYearChangeMotiv(event: any): void {
  //   this.selectedYearMotivoTurismo = event.target.value
  //   this.updateChartMotiv()
  // }
}
