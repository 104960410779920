<div class="container">
  <div class="row">
    <div class="col-12 mb-3">
      <ul
        class="list-inline breadcrumbs text-capitalize"
        style="font-weight: 500"
      >
        <li class="list-inline-item">
          <a routerLink="/app">Área privada</a>
        </li>
        <li class="list-inline-item">» &nbsp; <a>Perfil de usuario</a></li>
      </ul>
    </div>
  </div>
</div>
<section class="divider-section">
  <div class="container">
    <h2 class="divider fs-3">
      <span class="position-absolute">Perfil de usuario</span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        width="413px"
        height="61px"
        viewBox="0 0 413 61"
        version="1.1"
      >
        <title>Group</title>
        <g
          id="Page-1"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
        >
          <g id="separadores" transform="translate(-12, -22)" fill="#FFFFFF">
            <g id="Group" transform="translate(12, 22)">
              <path
                d="M412.045,0 L0,0 L0,60.4823 L351.563,60.4823 C375.552,60.4823 395,41.0348 395,17.045 C395,7.63132 402.631,0 412.045,0 Z"
                id="Path"
              ></path>
            </g>
          </g>
        </g>
      </svg>
    </h2>
    <div class="row form-row mt-5">
      <div class="col-12">
        <div class="vstack gap-4">
          <!-- Personal info START -->
          <div class="card border">
            <!-- Card header -->
            <div class="card-header border-bottom bg-transparent">
              <h4 class="card-header-title mb-0">Información personal</h4>
            </div>

            <!-- Card body START -->
            <div class="card-body">
              <!-- Form START -->
              <form
                [formGroup]="userForm"
                (ngSubmit)="updateUser()"
                class="row g-3"
              >
                <!-- Profile photo -->
                <div class="col-12 form-group">
                  <label class="form-label d-none">
                    Suba su foto de perfil
                    <span class="text-danger"></span
                  ></label>
                  <div class="d-flex align-items-center">
                    <label
                      class="position-relative me-4"
                      for="uploadfile-1"
                      title="Reemplazar esta imagen"
                    >
                      <!-- Avatar place holder -->
                      <span class="avatar avatar-xl">
                        <!-- <img
                          id="uploadfile-1-preview"
                          class="avatar-img rounded-circle border border-white border-3 shadow"
                          src="assets/images/gestur.svg"
                          alt=""
                        /> -->
                      </span>
                    </label>
                    <!-- Upload button -->
                    <label
                      class="bg-primary text-white rounded-pill px-2 d-none"
                      style="cursor: pointer"
                      for="uploadfile-1"
                      >Cambiar</label
                    >
                    <input
                      id="uploadfile-1"
                      class="form-control d-none"
                      type="file"
                    />
                  </div>
                </div>

                <!-- Name -->
                <div class="col-md-6 form-group">
                  <label class="form-label"
                    >Nombre completo <span class="text-danger">*</span></label
                  >
                  <input
                    name="name"
                    id="name"
                    formControlName="name"
                    type="text"
                    class="form-control"
                    value=""
                    placeholder="Indique su nombre completo"
                  />
                </div>

                <!-- Email -->
                <div class="col-md-6 form-group">
                  <label class="form-label"
                    >Dirección de correo electrónico
                    <span class="text-danger">*</span></label
                  >
                  <input
                    name="email"
                    id="email"
                    formControlName="email"
                    type="email"
                    class="form-control"
                    value=""
                    placeholder="Indique su dirección de correo electrónico"
                  />
                </div>

                <!-- Mobile -->
                <div class="col-md-4 form-group">
                  <label class="form-label">Número de teléfono móvil </label>
                  <input
                    name="phone"
                    id="phone"
                    formControlName="phone"
                    type="text"
                    class="form-control"
                    value=""
                    placeholder="Introduzca tu número de móvil"
                  />
                </div>

                <!-- Nationality -->
                <div class="form-group col-md-4">
                  <label for="dropdown"
                    >Provincia <span class="text-danger">*</span></label
                  >
                  <select
                    class="form-select py-2"
                    id="dropdownProvincia"
                    name="province"
                    formControlName="province"
                    (change)="onProvinciaChange($event)"
                  >
                    <option
                      *ngFor="let provincia of provincias"
                      [value]="provincia.value"
                    >
                      {{ provincia.label }}
                    </option>
                  </select>
                </div>
                <div class="form-group col-md-4">
                  <label for="dropdown"
                    >Municipio <span class="text-danger">*</span></label
                  >
                  <select
                    class="form-select py-2"
                    id="dropdownMunicipio"
                    name="city"
                    formControlName="city"
                  >
                    <option *ngFor="let municipio of city" [value]="municipio">
                      {{ municipio }}
                    </option>
                  </select>
                </div>

                <!-- Date of birth -->
                <div class="col-md-6 form-group d-none">
                  <label class="form-label"
                    >Fecha <span class="text-danger">*</span></label
                  >
                  <input
                    name="fecha"
                    id="fecha"
                    formControlName="fecha"
                    type="text"
                    class="form-control flatpickr flatpickr-input"
                    value=""
                    placeholder="Introduzca la fecha"
                    data-date-format="d M Y"
                  />
                </div>

                <!-- type -->
                <div class="col-md-6 form-group d-none">
                  <label class="form-label"
                    >Seleccione tipo <span class="text-danger">*</span></label
                  >
                  <div class="d-flex gap-4">
                    <div class="form-check radio-bg-light">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault1"
                        checked=""
                      />
                      <label class="form-check-label" for="flexRadioDefault1">
                        Hotelero
                      </label>
                    </div>
                    <div class="form-check radio-bg-light">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault2"
                      />
                      <label class="form-check-label" for="flexRadioDefault2">
                        Turístico
                      </label>
                    </div>
                    <div class="form-check radio-bg-light">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault3"
                      />
                      <label class="form-check-label" for="flexRadioDefault3">
                        Otros
                      </label>
                    </div>
                  </div>
                </div>

                <!-- Address -->
                <div class="col-8 form-group">
                  <label class="form-label">Dirección</label>
                  <input
                    name="address"
                    id="address"
                    formControlName="address"
                    class="form-control"
                    placeholder="Introduzca su dirección"
                  />
                </div>
                <div class="col-4 form-group">
                  <label class="form-label"
                    >Código postal <span class="text-danger">*</span></label
                  >
                  <input
                    name="postal_code"
                    id="postal_code"
                    formControlName="postal_code"
                    pattern="^\^[0-9]{5}$"
                    minlength="5"
                    maxlength="5"
                    (input)="validarCodigoPostal()"
                    class="form-control"
                    placeholder="Introduzca el código postal"
                  />
                  <div
                    *ngIf="
                      !postal_code &&
                      (userForm.get('postal_code')?.touched ||
                        userForm.get('postal_code')?.dirty)
                    "
                  >
                    <small class="text-danger"
                      >El código postal no es válido.</small
                    >
                  </div>
                </div>

                <!-- Button -->
                <div class="col-12 text-end">
                  <button
                    type="submit"
                    class="btn btn-sm btn-primary rounded-pill py-2 px-4 align-items-center"
                  >
                    Guardar cambios
                  </button>
                </div>
              </form>
              <!-- Form END -->
            </div>
            <!-- Card body END -->
          </div>
          <!-- Personal info END -->

          <!-- Update email START -->
          <div class="card border d-none">
            <!-- Card header -->
            <div class="card-header border-bottom bg-transparent">
              <h4 class="card-header-title">Actualizar correo electrónico</h4>
              <p class="mb-0">
                Su dirección de correo electrónico actual es
                <span class="text-primary">ejemploAgmail.com</span>
              </p>
            </div>

            <!-- Card body START -->
            <div class="card-body">
              <form class="form-group">
                <!-- Email -->
                <label class="form-label"
                  >Indique su nueva dirección de correo electrónico
                  <span class="text-danger">*</span></label
                >
                <input
                  type="email"
                  class="form-control"
                  placeholder="Indique su dirección de correo electrónico"
                />

                <div class="text-end mt-3">
                  <a
                    class="btn btn-sm btn-primary rounded-pill py-2 px-4 align-items-center"
                    >Guardar correo electrónico</a
                  >
                </div>
              </form>
            </div>
            <!-- Card body END -->
          </div>
          <!-- Update email END -->

          <!-- Update Password START -->
          <div class="card border">
            <!-- Card header -->
            <div class="card-header border-bottom bg-transparent">
              <h4 class="card-header-title">Actualizar contraseña</h4>
              <p class="mb-0">
                Su dirección de correo electrónico actual es
                <span class="text-primary">{{
                  userForm.get('email')?.value
                }}</span>
              </p>
            </div>

            <!-- Card body START -->
            <form
              [formGroup]="passwordForm"
              (ngSubmit)="updatePassword()"
              class="card-body form-group"
            >
              <!-- Current password " -->
              <div class="mb-3">
                <label class="form-label">Contraseña actual</label>
                <input
                  class="form-control"
                  formControlName="password"
                  type="password"
                  placeholder="Introduzca la contraseña actual"
                />
                <div
                  *ngIf="
                    passwordForm.get('password')?.hasError('required') &&
                    passwordForm.get('password')?.touched
                  "
                >
                  <small class="text-danger"
                    >La contraseña es obligatoria.</small
                  >
                </div>
              </div>
              <!-- New password -->
              <div class="mb-3">
                <label class="form-label">Introduzca nueva contraseña</label>
                <div class="input-group">
                  <input
                    class="form-control fakepassword"
                    formControlName="newPassword"
                    placeholder="Introduzca nueva contraseña"
                    type="password"
                    id="psw-input"
                  />
                  <span class="input-group-text p-0 bg-transparent">
                    <!-- <i
                                            class="fakepasswordicon fas fa-eye-slash cursor-pointer p-2 text-secondary"></i> -->
                  </span>
                </div>
                <div
                  *ngIf="
                    passwordForm.get('newPassword')?.hasError('required') &&
                    passwordForm.get('newPassword')?.touched
                  "
                >
                  <small class="text-danger"
                    >La contraseña es obligatoria.</small
                  >
                </div>
                @if (
                  passwordForm.get('newPassword')?.touched &&
                  passwordForm.hasError('invalidPassword')
                ) {
                  <ul class="mt-1">
                    @if (passwordForm.hasError('needsMinLength')) {
                      <li class="text-danger">
                        La contraseña debe tener al menos 8 caracteres.
                      </li>
                    }
                    @if (passwordForm.hasError('needsUpperCase')) {
                      <li class="text-danger">
                        La contraseña debe tener al menos una mayúscula.
                      </li>
                    }
                    @if (passwordForm.hasError('needsLowerCase')) {
                      <li class="text-danger">
                        La contraseña debe tener al menos una minúscula.
                      </li>
                    }
                    @if (passwordForm.hasError('needsNumber')) {
                      <li class="text-danger">
                        La contraseña debe tener al menos un número.
                      </li>
                    }
                    @if (passwordForm.hasError('needsSpecialChar')) {
                      <li class="text-danger">
                        La contraseña debe tener al menos un carácter especial.
                      </li>
                    }
                  </ul>
                }
              </div>
              <!-- Confirm -->
              <div class="mb-3">
                <label class="form-label">Confirme nueva contraseña</label>
                <input
                  class="form-control"
                  formControlName="newPassword2"
                  type="password"
                  placeholder="Confirme nueva contraseña"
                />
                <div
                  *ngIf="
                    passwordForm.get('newPassword2')?.hasError('required') &&
                    passwordForm.get('newPassword2')?.touched
                  "
                >
                  <small class="text-danger"
                    >La contraseña es obligatoria.</small
                  >
                </div>
                <div
                  *ngIf="
                    passwordForm.get('newPassword2')?.errors?.[
                      'confirmedValidator'
                    ] && passwordForm.get('newPassword2')?.dirty
                  "
                >
                  <small class="text-danger"
                    >Las contraseñas no coinciden.</small
                  >
                </div>
              </div>

              <div class="text-end">
                <button
                  type="submit"
                  class="btn btn-sm btn-primary rounded-pill py-2 px-4 align-items-center"
                >
                  Cambiar la contraseña
                </button>
              </div>
            </form>
            <!-- Card body END -->
          </div>
          <!-- Update Password END -->
        </div>
      </div>
    </div>
  </div>
</section>
