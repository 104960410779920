import { Component, EventEmitter, Output  } from '@angular/core';


@Component({
  selector: 'app-change-pass-modal',
  templateUrl: './change-pass-modal.component.html',
  styleUrl: './change-pass-modal.component.scss'
})
export class ChangePassModalComponent {

  isVisible = false;
  newPassword: string = '';
  errorMessage: string = '';

  @Output() passwordChange = new EventEmitter<string>();

  public openModal() {

    console.log("this.isvisble.....", this.isVisible)
    this.isVisible = true;
    console.log("this.isvisble.....", this.isVisible)
  }

  public closeModal() {
    this.isVisible = false;
  }

  public onSubmit() {
    if (this.newPassword) {
      console.log("...new pass...", this.newPassword)
      this.passwordChange.emit(this.newPassword);
      this.closeModal();
    } else {
      this.errorMessage = 'La contraseña no puede estar vacía.';
    }
  }

}
