import { Component } from '@angular/core';

@Component({
  selector: 'app-page-under-construction',
  templateUrl: './page-under-construction.component.html',
  styleUrl: './page-under-construction.component.scss'
})
export class PageUnderConstructionComponent {

}
