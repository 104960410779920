<!-- <div class="modal" *ngIf="isVisible">
    <div class="modal-content">
      <span class="close" (click)="closeModal()">&times;</span>
      <h2>Cambiar contraseña</h2>
      <form (ngSubmit)="onSubmit()">
        <label for="newPassword">Nueva contraseña:</label>
        <input type="password" id="newPassword" [(ngModel)]="newPassword" name="newPassword" required />
        
   
        <button type="submit" name="button"
        class="btn login_btn btn-primary text-center w-100 rounded-pill"> Enviar
    </button>
        <div *ngIf="errorMessage">{{ errorMessage }}</div>
      </form>
    </div>
  </div> -->

  <div class="modal" tabindex="-1" role="dialog" *ngIf="isVisible">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Cambio de contraseña</h5>
        </div>
        <div class="modal-body">
          <p>Por favor, cambie su contraseña actual por una nueva. Introduzca aquí la nueva contraseña.</p>
          <form (ngSubmit)="onSubmit()">
            <label for="newPassword">Nueva contraseña:</label>
            <input class="ms-3" type="password" id="newPassword" [(ngModel)]="newPassword" name="newPassword" required />
           
            <div *ngIf="errorMessage">{{ errorMessage }}</div>
          </form>
        </div>
        <div class="modal-footer">
            <button type="submit" name="button"
            class="btn login_btn btn-primary text-center w-100 rounded-pill"> Enviar
        </button>
          
        </div>
      </div>
    </div>
  </div>