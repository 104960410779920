import { Injectable, signal, computed } from '@angular/core'
import { Router } from '@angular/router'
import { environment } from '../../../environments/environment'
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserPool,
  CognitoUserSession,
} from 'amazon-cognito-identity-js'

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public isLoggedIn = signal<boolean>(false)
  private userPool: CognitoUserPool
  private currentUser: CognitoUser | null = null

  constructor(private router: Router) {
    const poolData = {
      UserPoolId: environment.UserPoolId,
      ClientId: environment.ClientId,
    }
    this.userPool = new CognitoUserPool(poolData)
  }

  // TODO -> get userPool from global
  async isAuth(): Promise<boolean> {
    const poolData = {
      UserPoolId: environment.UserPoolId,
      ClientId: environment.ClientId,
    }

    const userPool = new CognitoUserPool(poolData)
    const currentUser = userPool.getCurrentUser()

    if (!currentUser) {
      return false
    }

    return new Promise((resolve, reject) => {
      currentUser.getSession((err: any, session: CognitoUserSession) => {
        if (err) {
          console.error(err)
          resolve(false)
          return
        }

        this.isLoggedIn.set(session.isValid())
        console.log('valid session:', session.isValid())

        resolve(session.isValid())
      })
    })
  }

  signUp({ email, password }: any) {
    const attributeList = [
      new CognitoUserAttribute({
        Name: 'email',
        Value: email,
      }),
    ]

    return new Promise((resolve, reject) => {
      this.userPool.signUp(
        email,
        password,
        attributeList,
        [],
        (err: any, result: any) => {
          if (err) {
            reject(err)
            return
          }
          resolve(result)
        }
      )
    })
  }

  confirmSignUp(email: string, code: string) {
    const userData = {
      Username: email,
      Pool: this.userPool,
    }

    const cognitoUser = new CognitoUser(userData)

    return new Promise((resolve, reject) => {
      cognitoUser.confirmRegistration(code.trim(), true, (err, result) => {
        if (err) {
          reject(err)
          return
        }
        resolve(result)
      })
    })
  }

  async setupMFA(email: string, password: string): Promise<string> {
    const authDetails = new AuthenticationDetails({
      Username: email,
      Password: password,
    })
    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: this.userPool,
    })

    this.currentUser = cognitoUser

    return new Promise((resolve, reject) => {
      cognitoUser.authenticateUser(authDetails, {
        onSuccess: (session) => {
          this.startMFAAssociation(cognitoUser, resolve, reject)
        },
        mfaSetup: (challengeName, challengeParameters) => {
          this.startMFAAssociation(cognitoUser, resolve, reject)
        },
        onFailure: (err) => {
          console.error('failed setupMFA')
          reject(err)
        },
      })
    })
  }

  private startMFAAssociation(
    cognitoUser: CognitoUser,
    resolve: (value: string) => void,
    reject: (reason: any) => void
  ) {
    cognitoUser.associateSoftwareToken({
      associateSecretCode: (secretCode) => {
        resolve(secretCode)
      },
      onFailure: (err) => {
        console.error('Failed to associate MFA token:', err)
        reject(err)
      },
    })
  }

  async verifyMFA(totpCode: string): Promise<any> {
    if (!this.currentUser) {
      throw new Error('No user is currently authenticated')
    }

    return new Promise((resolve, reject) => {
      this.currentUser.verifySoftwareToken(totpCode, 'Senda', {
        onSuccess: (session) => {
          this.setPreferredMFA(this.currentUser!)
          resolve(session)
        },
        onFailure: (err) => {
          console.error('Failed to verify TOTP:', err)
          reject(err)
        },
      })
    })
  }

  async login(email: string, password: string): Promise<any> {
    const authDetails = new AuthenticationDetails({
      Username: email,
      Password: password,
    })
    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: this.userPool,
    })
    this.currentUser = cognitoUser

    return new Promise((resolve, reject) => {
      cognitoUser.authenticateUser(authDetails, {
        onSuccess: (session) => {
          resolve(session)
        },
        onFailure: (err) => {
          reject(err)
        },
        totpRequired: (challengeName, challengeParameters) => {
          resolve({
            challengeName,
            challengeParameters,
          })
        },
      })
    })
  }

  async verifyLoginTOTP(totpCode: string): Promise<CognitoUserSession> {
    if (!this.currentUser) {
      throw new Error('No user is currently authenticating')
    }

    return new Promise((resolve, reject) => {
      this.currentUser.sendMFACode(
        totpCode,
        {
          onSuccess: (session) => {
            console.log('Successfully logged in with MFA')
            resolve(session)
          },
          onFailure: (err) => {
            console.error('TOTP verification failed:', err)
            reject(err)
          },
        },
        'SOFTWARE_TOKEN_MFA'
      )
    })
  }

  private async setPreferredMFA(cognitoUser: CognitoUser) {
    return new Promise((resolve, reject) => {
      cognitoUser.setUserMfaPreference(
        null,
        {
          Enabled: true,
          PreferredMfa: true,
        },
        (err, result) => {
          if (err) {
            console.error('Failed to set preferred MFA:', err)
            reject(err)
            return
          }
          resolve(result)
        }
      )
    })
  }

  getQRImage(secret: string, email: string) {
    const appName = 'Senda'
    const qrCodeUrl = `otpauth://totp/${appName}:${email}?secret=${secret}&issuer=${appName}`
    const apiUrl = `https://api.qrserver.com/v1/create-qr-code/?data=${encodeURIComponent(
      qrCodeUrl
    )}&size=200x200`

    return apiUrl
  }

  initiatePasswordReset(email: string) {
    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: this.userPool,
    })

    return new Promise((resolve, reject) => {
      cognitoUser.forgotPassword({
        onSuccess: () => {
          resolve({
            status: 'SUCCESS',
            message: 'Password reset code sent successfully',
          })
        },
        onFailure: (err) => {
          reject({
            status: 'ERROR',
            message: err.message || 'Failed to send reset code',
          })
        },
      })
    })
  }

  completePasswordReset(
    email: string,
    verificationCode: string,
    newPassword: string
  ) {
    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: this.userPool,
    })

    return new Promise((resolve, reject) => {
      cognitoUser.confirmPassword(verificationCode, newPassword, {
        onSuccess: () => {
          resolve({
            status: 'SUCCESS',
            message: 'Password reset successful',
          })
        },
        onFailure: (err) => {
          reject({
            status: 'ERROR',
            message: err.message || 'Failed to reset password',
          })
        },
      })
    })
  }

  getToken(): Promise<string | null> {
    const poolData = {
      UserPoolId: environment.UserPoolId,
      ClientId: environment.ClientId,
    }
    const userPool = new CognitoUserPool(poolData)
    const currentUser = userPool.getCurrentUser()

    return new Promise((resolve, reject) => {
      if (!currentUser) {
        console.error('No user is currently authenticated')
        resolve(null)
        return
      }
      currentUser.getSession((err: any, session: CognitoUserSession) => {
        if (err) {
          console.error('Error getting session:', err)
          resolve(null)
          return
        }
        if (!session) {
          resolve(null)
          return
        }
        const token = session.getAccessToken().getJwtToken()
        resolve(token)
      })
    })
  }

  async changePassword(oldPassword: string, newPassword: string){
    const poolData = {
      UserPoolId: environment.UserPoolId,
      ClientId: environment.ClientId,
    }
    const userPool = new CognitoUserPool(poolData)
    const currentUser = userPool.getCurrentUser()

    return new Promise((resolve, reject) => {
      if(!currentUser) reject('No user is currently authenticated')

      currentUser.getSession((err: any, session: CognitoUserSession) => {
        if(err) reject(err)
        if(!session.isValid()) reject('Session is invalid');
          
        currentUser.changePassword(oldPassword, newPassword, function(err, result){
          if(err) reject(err)

          resolve(result)
        })
      })
    })
  }

  async logout() {
    const poolData = {
      UserPoolId: environment.UserPoolId,
      ClientId: environment.ClientId,
    }
    const userPool = new CognitoUserPool(poolData)
    const currentUser = userPool.getCurrentUser()

    if (currentUser) {
      currentUser.signOut()
      this.isLoggedIn.set(false)

      this.router.navigate(['/home'])
    }
  }
}
