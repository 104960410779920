import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  Inject,
} from '@angular/core'
import { CommonModule } from '@angular/common'
import { Hotel } from '../../services/hotel/hotelModel'
import { Router } from '@angular/router'
import { HotelDataService } from '../../services/hotel/hotelData.service'
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component'
import { NotificationModalComponent } from '../notification-modal/notification-modal.component'
import { ToastrService } from 'ngx-toastr'
import { capitalizarPalabra } from '../../../utils/utils'
import { OnInit } from '@angular/core'
import { FormControl } from '@angular/forms'

declare var bootstrap: any // Declaramos bootstrap para usar su funcionalidad

@Component({
  selector: 'app-card-alojamiento',
  templateUrl: './card-alojamiento.component.html',
  styleUrl: './card-alojamiento.component.scss',
})
export class CardAlojamientoComponent implements OnInit {
  @Input() hotel: Hotel = {
    name: '',
    type: '',
    address: '',
    province: '',
    city: '',
    postal_code: '',
    rooms: 0,
    capacity: 0,
  }

  idHotel: number | undefined
  @Output() selectedMove = new EventEmitter<any>()
  @ViewChild(ConfirmationModalComponent)
  confirmationModal!: ConfirmationModalComponent
  @ViewChild(NotificationModalComponent)
  notificationModal!: NotificationModalComponent
  direccion: string = ''
  tooltipMessage: string = ''
  mostrarTooltipActivo: boolean = false // Para controlar si el tooltip está activo

  constructor(
    private router: Router,
    private hotelDataService: HotelDataService,
    private toastr: ToastrService
  ) {}
  ngOnInit(): void {
    this.direccion = capitalizarPalabra(this.hotel.address)
  }

  mostrarTooltip(hotel: Hotel) {
    this.tooltipMessage = `
      <strong>Nombre:</strong> ${hotel.name || 'Desconocido'}
      <strong>Dirección:</strong> ${hotel.address || 'No disponible'}
      <strong>Provincia:</strong> ${hotel.province || 'No disponible'}
      <strong>Municipio:</strong> ${hotel.city || 'No disponible'}
      <strong>Código Postal:</strong> ${hotel.postal_code || 'No disponible'}
      <strong>Teléfono:</strong> ${hotel.phone || 'No disponible'} 
      <strong>Correo:</strong> ${hotel.email || 'No disponible'} 
      <strong>Categoria:</strong> ${hotel.category || 'No disponible'}
      <strong>Total Plazas:</strong> ${hotel.capacity || 'No disponible'}
    `
    this.mostrarTooltipActivo = true
  }
  cerrarTooltip() {
    this.tooltipMessage = ''
    this.mostrarTooltipActivo = false
  }

  deleteHotel(hotel: Hotel) {
    this.confirmationModal.openModal(hotel)
    this.confirmationModal.confirmed.subscribe((result: any) => {
      if (result) {
        this.borrarCard(result.uid)
      }
    })
  }

  borrarCard(uidHotel: string): void {
    // this.selectedMove.emit(event)
    if (uidHotel !== undefined) {
      this.hotelDataService.removeHotel(uidHotel)
      this.hotelDataService.getHoteles()
      this.confirmationModal.dismiss()
      // this.notificationModal.openModal('Hotel eliminado correctamente');
      // alert('Alojamiento eliminado correctamente');
      this.toastr.success(
        'El alojamiento se ha eliminado correctamente',
        'Éxito'
      )
    } else {
      this.toastr.error(
        'Ha ocurrido un error al crear el usuario. Por favor, inténtelo más tarde.',
        'Error'
      )
    }
  }

  verAnaliticas(hotelData: Hotel) {
    this.router.navigate(['/dashboard'], { state: { hotelData } })
  }
  verGestionDatos(hotelData: Hotel) {
    this.router.navigate(['/management-area'], { state: { hotelData } })
  }
}
