<div class="row justify-content-center">
  <!-- items  -->
  <div
    [ngClass]="{
      albergue: hotel.type === 'albergue',
      apartamento: hotel.type === 'apartamento',
      camping: hotel.type === 'camping',
      casarural: hotel.type === 'casa rural',
      hostal: hotel.type === 'hostal',
      hotel: hotel.type === 'hotel',
      pension: hotel.type === 'pension',
      motel: hotel.type === 'motel',
    }"
    class="icon-box-item col-lg-4 col-md-6 mb-4 h-100 w-100"
  >
    <div
      class="icon-box-item col-lg-4 col-md-6 mb-4 h-100 w-100 position-relative rounded-3"
    >
      <button
        (click)="deleteHotel(hotel)"
        class="btn text-danger rounded-2 position-absolute end-0 top-0 me-3 mt-1"
      >
        <i class="fa-regular fa-trash-can fs-5"></i>
      </button>
      <app-confirmation-modal
        [title]="'¿Está seguro de eliminar el establecimiento?'"
        [message]="'Esta acción es irreversible.'"
      >
      </app-confirmation-modal>
      <app-notification-modal></app-notification-modal>

      <button
        mat-raised-button
        class="button-info position-absolute end-0 top-0 me-5 mt-1"
        (click)="mostrarTooltip(hotel)"
        aria-label="Button that displays a tooltip when focused or hovered over"
      >
        <i class="fa-solid fa-info"></i>
      </button>

      <div
        *ngIf="tooltipMessage"
        class="custom-tooltip"
        [innerHTML]="tooltipMessage"
        (click)="cerrarTooltip()"
      ></div>

      <div class="rounded py-5 px-4">
        <div class="title-box d-flex align-items-center">
          <div *ngIf="hotel.type === 'albergue'">
            <img
              class="me-3"
              src="assets/images/icons/albergue.svg"
              alt="albergue"
            />
          </div>
          <div *ngIf="hotel.type === 'apartamento'">
            <img
              class="me-3"
              src="assets/images/icons/apartamento.svg"
              alt="apartamento"
            />
          </div>
          <div *ngIf="hotel.type === 'hotel'">
            <img class="me-3" src="assets/images/icons/hotel.svg" alt="hotel" />
          </div>
          <div *ngIf="hotel.type === 'casa rural'">
            <img
              class="me-3"
              src="assets/images/icons/casaRural.svg"
              alt="casa_rural"
            />
          </div>
          <div *ngIf="hotel.type === 'hostal'">
            <img
              class="me-3"
              src="assets/images/icons/hostal.svg"
              alt="hostal"
            />
          </div>
          <div *ngIf="hotel.type === 'pension'">
            <img
              class="me-3"
              src="assets/images/icons/pension.svg"
              alt="pension"
            />
          </div>

          <div *ngIf="hotel.type === 'camping'">
            <img
              class="me-3"
              src="assets/images/icons/camping.svg"
              alt="camping"
            />
          </div>
          <div *ngIf="hotel.type === 'motel'">
            <img class="me-3" src="assets/images/icons/motel.svg" alt="motel" />
          </div>

          <h5>{{ hotel.name }}</h5>
        </div>
        <p class="mt-3">
          {{ direccion }} <br />
          {{ hotel.postal_code }} {{ hotel.city }}
        </p>

        <button
          class="btn btn-sm btn-outline-primary btn-animate rounded-pill py-2 px-3 mt-3 bg-white border-0 me-3"
          (click)="verAnaliticas(hotel)"
        >
          Analíticas
          <i class="fa-solid fa-chevron-right"></i>
        </button>
      </div>
    </div>
  </div>
</div>
