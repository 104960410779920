<div class="mx-4 row">
  <div class="col-12">
    <canvas #mediaMovilProvinces id="mediaMovilProvinces"></canvas>
  </div>

  <div #rangeSlider id="rangeSlider" class="slider mt-5"></div>
</div>
<p class="mt-5">
  *Datos extraídos de la Encuesta de Turismo de Residentes (ETR/FAMILITUR) del
  INE.
</p>
