import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  output,
  ViewChild,
} from '@angular/core'
import { HotelDataService } from '../../services/hotel/hotelData.service'
import { Router } from '@angular/router'
import { Hotel } from '../../services/hotel/hotelModel'
import { FileUploadService } from '../../services/files/fileUpload.service'
import * as ExcelJS from 'exceljs'
import { DataCardService } from '../../services/cards/dataCard.service'
import { ApiResponse, ResultData } from '../../services/cards/iDataCard'
import { UserDataService } from '../../services/users/userData.service'
import { Iuser } from '../../services/users/iuser'
import { TiempoService } from '../../services/tiempo/tiempo.service'
import { CalendarService } from '../../services/calendar.service'
import { MunicipiosService } from '../../services/municipios/municipios.service'
import { ToastrService } from 'ngx-toastr'
import { GraficoVolumenTuristaComponent } from '../../components/grafico-volumen-turista/grafico-volumen-turista.component'
import { GraficoPuntoProvinciaComponent } from '../../components/grafico-punto-provincia/grafico-punto-provincia.component'
import { GraficoGradoOcupacionComponent } from '../../components/grafico-grado-ocupacion/grafico-grado-ocupacion.component'
import { GraficoOrigenComponent } from '../../components/grafico-origen/grafico-origen.component'
import { GraficoTipoAlojamientoComponent } from '../../components/grafico-tipo-alojamiento/grafico-tipo-alojamiento.component'
import { GraficoVolumenPuntoComponent } from '../../components/grafico-volumen-punto/grafico-volumen-punto.component'

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss',
})
export class DashboardComponent implements OnInit {
  hotelData: Hotel = {} as Hotel

  // ciudad?: string = '';
  municipioTiempo: string = ''
  provinciaTiempo: string = ''
  weatherData: any
  temperatura: number = 0
  localidadSelect?: string
  uid: string = ''
  isScrolled = false

  // Escuchar el evento de scroll en la ventana
  @HostListener('window:scroll', [])
  onWindowScroll() {
    const scrollPosition =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0

    // Si el scroll supera los 200px, activar la variable isScrolled
    if (scrollPosition >= 150) {
      this.isScrolled = true
    } else {
      this.isScrolled = false
    }
  }

  @ViewChild('vPillsPublicoTab', { static: false })
  public tabPublico!: ElementRef

  @ViewChild(GraficoVolumenTuristaComponent)
  graficoVolumenTuristaComp!: GraficoVolumenTuristaComponent

  @ViewChild(GraficoVolumenPuntoComponent)
  graficoVolumenPuntoComp!: GraficoVolumenPuntoComponent

  @ViewChild(GraficoOrigenComponent)
  graficoOrigenComp!: GraficoOrigenComponent

  @ViewChild(GraficoTipoAlojamientoComponent)
  graficoTipoAlojamientoComp!: GraficoTipoAlojamientoComponent

  @ViewChild(GraficoPuntoProvinciaComponent)
  graficoPuntoProvinciaComp!: GraficoPuntoProvinciaComponent

  @ViewChild(GraficoGradoOcupacionComponent)
  graficoOcupacionComp!: GraficoGradoOcupacionComponent

  @Output()
  tabSelected = new EventEmitter<string>() // Declarar el EventEmitter

  ficheroHotelEmpty: boolean = false
  cadena: string = ''
  nombreAlojamiento: string = ''
  localidades: any = []
  selectedFile: File | null = null
  data: any[] = []
  codigoProvincia: string = ''
  columns: string[] = []
  dataCardsView: ResultData

  provincias: any[] = [
    { code: '02', value: 'Albacete' },
    { code: '13', value: 'Ciudad Real' },
    { code: '16', value: 'Cuenca' },
    { code: '19', value: 'Guadalajara' },
    { code: '45', value: 'Toledo' },
  ]
  selectedTab: string = 'v-pills-calendario' // Pestaña inicial

  constructor(
    private router: Router,
    private calendarService: CalendarService,
    private fileUploadService: FileUploadService,
    private dataCards: DataCardService,
    private userDataService: UserDataService,
    private tiemposervice: TiempoService,
    private municipiosService: MunicipiosService,
    private toastr: ToastrService
  ) {
    //cargar datos del hotel referente
    const navigation = this.router.getCurrentNavigation()
    if (navigation?.extras.state) {
      this.hotelData = navigation.extras.state['hotelData'] // Accede a los datos pasados
    }
  }

  onTabSelect() {
    this.calendarService.switchTab() // Emitir evento de cambio de pestaña
    console.log('entra en tabs elected.........')
    this.graficoVolumenTuristaComp.updateChart()
    this.graficoVolumenPuntoComp.updateChart()
    this.graficoOrigenComp.updateChart()
    this.graficoTipoAlojamientoComp.updateChart()
  }
  onTabIndicadoresSelect() {
    this.graficoPuntoProvinciaComp.updateChart()
    this.graficoOcupacionComp.updateChart()
  }

  ngOnInit() {
    //llamamos a los excel para descargarlos y poder pintarlos
    this.getDataCards()
    // this.fetchAndDisplayExcel()
    //this.ciudad = this.options[0].label;
    this.recuperaDatos() //recupera la provincia del usuario para meterla en ciudad
  }

  // onProvinciaChange(event: any) {
  //   this.ciudad = event.target.value;
  //   if(this.ciudad){
  //     this.municipiosService.getMunicipios(this.ciudad).subscribe(municipios => {
  //       this.localidades = municipios;
  //       this.localidadSelect = this.localidades.length > 0 ? this.localidades[0] : '';
  //     })
  //   }
  //   this.localidades = [];

  // }
  onFileSelected(event: Event) {
    const input = event.target as HTMLInputElement
    if (input.files && input.files.length > 0) {
      this.selectedFile = input.files[0]
      this.onUpload()
    }
  }
  onUpload() {
    if (this.selectedFile) {
      this.uid = this.hotelData.uid
      if (this.uid != '') {
        this.fileUploadService
          .uploadFile(this.selectedFile, this.uid)
          .subscribe({
            next: (response) => {
              console.log('File uploaded successfully:', response)
              // alert("Fichero subido correctamente, espere unos segundos antes de darle al botón para visualizarlo.")
              this.toastr.success(
                'Fichero subido correctamente, espere unos segundos antes de darle al botón para visualizarlo.',
                'Éxito'
              )
            },
            error: (error) => {
              console.error('Error uploading file:', error)
              // alert("Ha habido un error al subir el fichero, por favor intentelo más tarde");
              this.toastr.error(
                'Ha habido un error al subir el fichero, por favor intentelo más tarde',
                'Error'
              )
            },
            complete: () => {
              console.log('Upload complete')
            },
          })
      } else {
        console.log('no hay introducido un nombre de hotel...')
      }
    } else {
      console.log('no hay ningun fichero seleccionado.')
      // alert("no hay ningun fichero seleccionado.")
      this.toastr.warning('No hay ningun fichero seleccionado', 'Warning')
    }
  }

  //descargar json
  downloadJson() {
    this.fileUploadService.downloadJson(this.nombreAlojamiento).subscribe({
      next: (response) => {
        console.log('fichero JSON', response)
      },
      error: (error) => {
        console.error('Error al descargar el JSON', error)
      },
    })
  }

  refrescarDatosPublicos() {
    console.log('entra en actualizar.....')
    window.location.reload()
  }

  //para pintar el excel en la tabla y mostrar los datos
  fetchAndDisplayExcel() {
    console.log('nombre alojami....', this.hotelData.name)
    this.fileUploadService.downloadJson(this.hotelData.uid).subscribe({
      //en los decimales nos quedamos solo con dos
      next: (response) => {
        // Procesar los datos CSV y formatear números a dos decimales
        this.data = this.fileUploadService.parseCSVData(response)

        // Formatear los valores numéricos
        this.data = this.data.map((row) => {
          const formattedRow: any = {}
          Object.keys(row).forEach((key) => {
            const value = row[key]

            // Formatear a dos decimales si es un número
            if (typeof value === 'number') {
              formattedRow[key] = value.toFixed(2)
            } else if (typeof value === 'string' && !isNaN(Number(value))) {
              // Si es una cadena que representa un número
              formattedRow[key] = Number(value).toFixed(2)
            } else {
              formattedRow[key] = value // Otros tipos de datos
            }
          })
          return formattedRow
        })

        this.columns = Object.keys(this.data[0] || {})
      },
      error: (error) => {
        console.error('Error fetching Excel file:', error)
      },
      complete: () => {
        console.log('Fetch completed')
      },
    })
  }

  getDataCards() {
    this.dataCards.getDataCard(this.hotelData.uid).subscribe({
      next: (response: ResultData) => {
        this.dataCardsView = response
        this.ficheroHotelEmpty = false
      },
      error: (err) => {
        console.error('Error al obtener datos:', this.ficheroHotelEmpty, err)
        this.ficheroHotelEmpty = true
      },
    })
  }
  recuperaDatos() {
    this.userDataService.getUserInfo().subscribe((userData: Iuser) => {
      console.log('Datos recibidos del servidor:', userData)
      if (userData.city != null && userData.province != null) {
        this.municipioTiempo = userData.city
        this.provinciaTiempo = userData.province!
        this.tiempoTemperatura(this.municipioTiempo)
        // this.municipiosService.getMunicipios(this.ciudad).subscribe(municipios => {
        //   this.localidades = municipios;
        //   this.localidadSelect = this.localidades.length > 0 ? this.localidades[0] : '';
        // })
        // console.log('Provincia...', this.ciudad,'Municipios...',this.localidades,'MunicipioSelect...',this.localidadSelect)
        // this.selectProvince(this.ciudad);
      } else {
        this.municipioTiempo = 'Toledo'
      }
    })
  }
  tiempoTemperatura(city: string) {
    this.tiemposervice.getWeather(city).subscribe((data) => {
      this.weatherData = data
      // this.fecha = this.formatFecha(this.weatherData.list[0].dt_txt);
      //this.guardarinteresan(this.weatherData);
      this.temperatura = this.weatherData.list[0].main.temp
      //this.cadena=this.weatherData.list[0].weather[0].description;
      console.log('datos clima en dashboard ........', this.weatherData)
    })
  }
}
