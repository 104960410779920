<div
  id="carouselGraficasGastos"
  class="carousel slide carousel-fade"
  data-bs-interval="false"
>
  <div class="carousel-inner">
    <!-- Primer gráfico: gastos por motivo -->
    <div class="carousel-item active graph text-end">
      <canvas #gastosMotivos id="gastosMotivos"></canvas>
      <div class="slider">
        <div #rangeSlider id="rangeSlider" class="mt-5 mb-3"></div>
      </div>
    </div>

    <!-- Segundo gráfico: gastos por provincia -->

    <div class="carousel-item graph text-end">
      <app-grafico-gastos-provincias></app-grafico-gastos-provincias>
    </div>
    <div class="carousel-item graph text-end">
      <app-grafico-media-movil></app-grafico-media-movil>
    </div>
  </div>

  <!-- Controles de navegación -->
  <button
    class="carousel-control-prev"
    type="button"
    data-bs-target="#carouselGraficasGastos"
    data-bs-slide="prev"
  >
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button
    class="carousel-control-next"
    type="button"
    data-bs-target="#carouselGraficasGastos"
    data-bs-slide="next"
  >
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>
