<!-- Segundo gráfico: tipo de viajes -->
<!-- <div class="carousel-item graph text-end">
      Año:
      <select name="year" id="year" (change)="onYearChange($event)">
        <option *ngFor="let year of yearsWithoutUndefined" [value]="year">
          {{ year }}
        </option>
      </select>

      <canvas #tipoViajes id="tipoViajes"></canvas>
    </div> -->
<!-- Tercer gráfico: motivo de turismo -->
<!-- <div class="carousel-item graph text-end">
      Año:
      <select name="anyo" id="anyo" (change)="onYearChangeMotiv($event)">
        <option *ngFor="let year of yearsWithoutUndefined" [value]="year">
          {{ year }}
        </option>
      </select>

      <canvas #motivoTurismo id="motivoTurismo"></canvas>
    </div>
  </div> -->

<div class="row">
  <div class="col-6">
    Año:
    <select name="year" id="year" (change)="onYearChange($event)">
      <option *ngFor="let year of yearsWithoutUndefined" [value]="year">
        {{ year }}
      </option>
    </select>
    <div class="card border-0">
      <div class="card-body">
        <canvas #tipoViajes id="tipoViajes"></canvas>
      </div>
    </div>
  </div>
  <div class="col-6 mt-5">
    <div class="card border-0">
      <div class="card-body">
        <canvas #motivoTurismo id="motivoTurismo"></canvas>
      </div>
    </div>
  </div>
</div>
