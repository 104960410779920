import { HttpClient } from '@angular/common/http'
import { Component, ElementRef, ViewChild } from '@angular/core'
import { GraphService } from '../../services/graficos/graph.service'
import { FileUploadService } from '../../services/files/fileUpload.service'
import { Chart, registerables } from 'chart.js'

@Component({
  selector: 'app-grafico-gastos-actividades',
  templateUrl: './grafico-gastos-actividades.component.html',
  styleUrl: './grafico-gastos-actividades.component.scss',
})
export class GraficoGastosActividadesComponent {
  data: any[] = []
  chart: Chart
  provinces = ['Albacete', 'Ciudad Real', 'Cuenca', 'Guadalajara', 'Toledo'] // Provincias disponibles
  selectedProvince: any = 2 // Provincia seleccionada por defecto
  chartData: number[] = [] // Euros por actividad
  chartLabels: string[] = []
  @ViewChild('gastosPorActividad', { static: true })
  gastosPorActividad: ElementRef<HTMLCanvasElement>
  constructor(
    private http: HttpClient,
    private graphService: GraphService,
    private fileService: FileUploadService
  ) {
    Chart.register(...registerables)
  }
  ngOnInit() {
    this.loadCSVData()
  }

  loadCSVData() {
    this.http
      .get('../../../assets/data/actividades_gasto_por_provincia_clm.csv', {
        responseType: 'text',
      })
      .subscribe((csvData) => {
        this.data = this.fileService.parseCSVData(csvData)
        console.log('provincia seleccionada...', this.selectedProvince)
        if (
          this.selectedProvince == '' ||
          this.selectedProvince === undefined ||
          !isNaN(this.selectedProvince)
        ) {
          this.selectedProvince = this.provinces[0]
        }
        this.updateChart()
      })
  }
  filterDataByProvince(provinceNum: any) {
    return this.data.filter((row) => row.provdest === provinceNum)
  }
  getChartData(filteredData: any) {
    this.chartData = []
    this.chartLabels = []
    const activityMap = this.graphService.activityMap
    const activities = Object.keys(activityMap)

    activities.forEach((activity) => {
      const value = filteredData[0]?.[activity]

      if (value && value > 0) {
        this.chartLabels.push(activityMap[activity])
        this.chartData.push(value)
      }
    })
  }
  updateChart() {
    const gastosElement = this.gastosPorActividad.nativeElement
    const provinceNum = this.graphService.getProvinceKeys(this.selectedProvince)
    console.log('numero provincia....', provinceNum)
    const filteredData = this.filterDataByProvince(provinceNum)
    console.log('Datos filtrados num...', filteredData)
    this.getChartData(filteredData)
    if (this.chart) {
      this.chart.destroy()
    }
    const chartConfig = {
      type: 'bar',
      data: {
        labels: this.chartLabels,
        datasets: [
          {
            label: 'Euros',
            data: this.chartData,
            backgroundColor: 'rgba(134, 175, 250, 1)',
            borderColor: 'rgba(134, 175, 250, 1)',
            borderWidth: 1,
          },
        ],
      },
      options: {
        indexAxis: 'y',
        responsive: true,
        scales: {
          x: {
            beginAtZero: true,
            title: {
              display: true,
              text: 'Euros',
            },
          },
          y: {
            title: {
              display: true,
              text: 'Actividades',
            },
          },
        },
        plugins: {
          datalabels: {
            display: false,
          },
          title: {
            display: true,
            text: 'Gastos por actividades',
            font: {
              size: 24,
            },
            padding: {
              top: 30,
              bottom: 30,
            },
          },
        },
      },
    }
    this.chart = new Chart(gastosElement, chartConfig as any)
  }
  onProvinceChange(event: any): void {
    this.selectedProvince = event.target.value
    this.updateChart()
  }
}
