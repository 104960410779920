import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { transformarTexto, capitalizarPalabra } from '../../../utils/utils'
@Injectable({
  providedIn: 'root'
})
export class MunicipiosService {
  private apiUrl = 'https://apiv1.geoapi.es/';
  private apiKey = '75709e98e23fc27f9b83724d6a9643ac17878e82435522e381320a25160c64ee';
  codigoProvincia = '';
  constructor(private http: HttpClient) { }
  getProvince(code: string): Observable<any> {
    const url = `${this.apiUrl}municipios?CPRO=${code}&type=JSON&key=${this.apiKey}`;
    return this.http.get<any>(url);
  }

  getMunicipios(provincia: string): Observable<string[]> {
    if (provincia == 'Toledo') {
      this.codigoProvincia = '45';
    } else if (provincia == 'Albacete') {
      this.codigoProvincia = '02';
    } else if (provincia == 'Guadalajara') {
      this.codigoProvincia = '19';
    } else if (provincia == 'Ciudad Real') {
      this.codigoProvincia = '13';
    } else if (provincia == 'Cuenca') {
      this.codigoProvincia = '16';
    }
    return new Observable<string[]>(observer => {
      this.getProvince(this.codigoProvincia).subscribe(data => {
        let municipios: string[] = [];
        const datos = data.data
        datos.forEach((element: any) => {
          let elemento = element.DMUN50;

          if (element.DMUN50.trim().endsWith('(LAS)') || element.DMUN50.trim().endsWith('(LOS)') || element.DMUN50.trim().endsWith('(LA)') || element.DMUN50.trim().endsWith('(EL)')) {
            elemento = transformarTexto(element.DMUN50);
          }

          const municipiosLower = capitalizarPalabra(elemento);


          municipios.push(municipiosLower);
        });
        municipios.sort((a: string, b: string) => a.localeCompare(b));
        observer.next(municipios);
        observer.complete();
      });
    });
  }
}
