import { Component, ElementRef, ViewChild } from '@angular/core'
import { Chart, registerables } from 'chart.js'
import { FileUploadService } from '../../services/files/fileUpload.service'
import { HttpClient } from '@angular/common/http'
import * as noUiSlider from 'nouislider'
import { GraphService } from '../../services/graficos/graph.service'
@Component({
  selector: 'app-grafico-gastos-motivos',
  templateUrl: './grafico-gastos-motivos.component.html',
  styleUrl: './grafico-gastos-motivos.component.scss',
})
export class GraficoGastosMotivosComponent {
  chartGastosMotivos: Chart
  months: string[] = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ]
  data: any[] = []
  filteredData: any[] = []
  years: any[] = []
  selectedStartMonth = ''
  selectedStartYear = 0
  selectedEndMonth = ''
  selectedEndYear = 0
  dynamicMonthsByYear: Map<string, string[]>

  @ViewChild('gastosMotivos', { static: true })
  gastosMotivos!: ElementRef<HTMLCanvasElement>
  @ViewChild('rangeSlider', { static: true }) rangeSlider!: ElementRef

  constructor(
    private fileService: FileUploadService,
    private http: HttpClient,
    private graphService: GraphService
  ) {
    Chart.register(...registerables)
  }

  ngOnInit() {
    this.loadCSVData()
  }
  loadCSVData() {
    this.http
      .get('../../../assets/data/medias_moviles_motiv_3m.csv', {
        responseType: 'text',
      })
      .subscribe((data) => {
        this.data = this.fileService.parseCSVData(data)
        const yearMonthMap = this.graphService.extractYearsAndMonths(
          this.data,
          this.months
        )
        this.years = Array.from(yearMonthMap.keys()).sort()
        this.dynamicMonthsByYear = yearMonthMap
        this.initializeSlider()
      })
  }
  initializeSlider() {
    const slider = this.rangeSlider.nativeElement
    if (!this.rangeSlider) return

    const sliderValues: string[] = []
    this.years.forEach((year) => {
      const months = this.dynamicMonthsByYear.get(year)!
      months.forEach((month) => {
        sliderValues.push(`${month} ${year}`)
      })
    })

    noUiSlider.create(slider, {
      start: [sliderValues[0], sliderValues[10]],
      step: 1,
      range: {
        min: 0,
        max: sliderValues.length - 1,
      },
      tooltips: [true, true],
      format: {
        to: (value) => {
          return sliderValues[Math.round(value)]
        },
        from: (value) => {
          return sliderValues.indexOf(value)
        },
      },
    })

    slider.noUiSlider!.on('update', (values: any, handle: any) => {
      const start = values[0]
      const end = values[1]

      const [startMonth, startYear] = start.split(' ')
      const [endMonth, endYear] = end.split(' ')

      this.selectedStartYear = parseInt(startYear)
      this.selectedStartMonth = startMonth
      this.selectedEndYear = parseInt(endYear)
      this.selectedEndMonth = endMonth
      this.updateChartByDateRange()
    })
  }
  updateChartByDateRange() {
    this.filteredData = []
    this.filteredData = this.graphService.filterDataByDateRange(
      this.data,
      this.selectedStartYear,
      this.selectedStartMonth,
      this.selectedEndYear,
      this.selectedEndMonth,
      this.months
    )
    this.createChart()
  }
  createChart() {
    const canvasElement = this.gastosMotivos.nativeElement
    if (!canvasElement) return
    const uniqueDates = Array.from(
      new Set(this.filteredData.map((row) => row['periodo']))
    ).sort()
    const motivData: { [province: string]: number[] } = {}
    this.filteredData.forEach((row) => {
      const motivName = this.graphService.getMotive(row.motiv)
      if (!motivData[motivName]) {
        motivData[motivName] = new Array(uniqueDates.length).fill(null)
      }
      const dateIndex = uniqueDates.indexOf(row['periodo'])
      motivData[motivName][dateIndex] = row['gasto_total_medio_movil_3m']
    })
    const filteredMotivData = Object.fromEntries(
      Object.entries(motivData).filter(([_, data]) => {
        return data.some((value) => value !== null)
      })
    )
    const labels = uniqueDates.map((date) => {
      const year = date.slice(0, 4) // Los primeros 4 caracteres son el año
      const month = parseInt(date.slice(5)) - 1
      return `${this.months[month]} ${year}`
    })
    const colors = [
      'rgb(255, 205, 86)', //yellow
      'rgb(255, 99, 132)', //red
      'rgb(54, 162, 235)', //blue
      'rgb(153, 102, 255)', //violet
      'rgb(75, 192, 192)', //teal
      'rgba(255, 159, 64)',
      'rgba(255, 182, 193)',
      'rgba(0, 255, 127)',
      'rgba(173, 255, 47)',
      'rgba(0, 0, 139)',
      'rgba(60, 179, 113)',
      'rgba(0, 128, 128)',
      'rgba(106, 90, 205)',
      'rgba(139, 69, 19)',
      'rgba(220, 20, 60)',
      'rgba(128, 128, 0)',
    ]
    const datasets = Object.keys(filteredMotivData).map((motiv, index) => ({
      label: motiv,
      data: filteredMotivData[motiv],
      borderColor: colors[index % colors.length],
      fill: false,
      spanGaps: true,
    }))
    if (this.chartGastosMotivos) {
      this.chartGastosMotivos.destroy()
    }
    const chartConfig = {
      type: 'line',
      data: {
        labels: labels,
        datasets: datasets,
      },
      options: {
        responsive: true,
        scales: {
          y: {
            beginAtZero: true,
          },
        },
        plugins: {
          datalabels: false,
          callbacks: {
            label: (tooltipItem: any) => {
              return `${tooltipItem.label}: ${tooltipItem.raw} €`
            },
          },
          legend: {
            position: 'right',
          },
          title: {
            display: true,
            text: 'Evolución Temporal del Gasto Promedio por Motivación',
            font: {
              size: 24,
            },
            padding: {
              top: 10,
              bottom: 30,
            },
          },
        },
      },
    }
    this.chartGastosMotivos = new Chart(canvasElement, chartConfig as any)
  }
}
