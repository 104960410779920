<!-- <div class="container">
    <h2>Generador de PDF</h2>
    <button (click)="generarPDF()">Generar PDF</button>
  </div> -->


  <ul class="d-flex download-bar">
    <li class="me-2">Exportar a:</li>
    <!-- <li><a href="" class="text-decoration-none text-body disabled"><i class="fa-solid fa-file-csv me-2 text-info"></i>CSV</a></li>
    <li><a href="" class="text-decoration-none text-body disabled"><i class="fa-solid fa-file-excel me-2 text-success"></i>EXCEL</a></li>
    <li><a href="" class="text-decoration-none text-body disabled"><i class="fa-solid fa-file me-2 text-warning"></i>XML</a></li> -->
    <li><a routerLink="/management-area" (click)="generarPDF()" class="text-decoration-none text-body"><i class="fa-solid fa-file-pdf me-2 text-danger"></i>PDF</a></li>
</ul>