export const mock = [
  {
    stablishment_name: 'Hotel Europa',
    stablishment_id: '1',
    details: [
      {
        date: '2024-07-19',
        festivos: [
          'Festividad local de Villarejo Sobrehuerta',
          'Festividad local de Cabanillas del Campo',
          'Festividad local de Chozas de Canales',
          'Festividad local de Noblejas',
        ],
        pc_ocupacion: 21.0,
        room: {
          double: {
            newPrice: 54.24,
            priceBefore: 50.21,
            priceNow: 49.25,
          },
          single: {
            newPrice: 80.44,
            priceBefore: 76.5,
            priceNow: 83.73,
          },
          suite: {
            newPrice: 76.63,
            priceBefore: 72.48,
            priceNow: 75.62,
          },
        },
      },
      {
        date: '2024-07-20',
        festivos: ['Festividad local de Cendejas de Enmedio'],
        pc_ocupacion: 28.0,
        room: {
          double: {
            newPrice: 55.68,
            priceBefore: 51.95,
            priceNow: 54.61,
          },
          single: {
            newPrice: 98.99,
            priceBefore: 104.0,
            priceNow: 112.2,
          },
          suite: {
            newPrice: 78.67,
            priceBefore: 82.69,
            priceNow: 88.0,
          },
        },
      },
      {
        date: '2024-07-21',
        festivos: ['Festividad local de Cendejas de Padrastro'],
        pc_ocupacion: 19.0,
        room: {
          double: {
            newPrice: 48.36,
            priceBefore: 44.01,
            priceNow: 42.49,
          },
          single: {
            newPrice: 53.94,
            priceBefore: 55.08,
            priceNow: 54.67,
          },
          suite: {
            newPrice: 84.36,
            priceBefore: 82.62,
            priceNow: 81.24,
          },
        },
      },
      {
        date: '2024-07-22',
        festivos: [
          'Festividad local de Villalgordo del Júcar',
          'Festividad local de Poblete',
          'Festividad local de Huerta del Marquesado',
          'Festividad local de Poyatos',
          'Festividad local de Cendejas de Padrastro',
          'Festividad local de Mondéjar',
          'Festividad local de Valdeavellano',
          'Festividad local de Carranque',
          'Festividad local de El Viso de San Juan',
          'Festividad local de Escalonilla',
          'Festividad local de Garciotum',
          'Festividad local de Guadamur',
          'Festividad local de Layos',
          'Festividad local de Mascaraque',
          'Festividad local de Villamuelas',
          'Festividad local de Villasequilla',
        ],
        pc_ocupacion: 21.0,
        room: {
          double: {
            newPrice: 42.47,
            priceBefore: 42.01,
            priceNow: 44.8,
          },
          single: {
            newPrice: 82.78,
            priceBefore: 76.5,
            priceNow: 72.99,
          },
          suite: {
            newPrice: 79.33,
            priceBefore: 81.64,
            priceNow: 86.58,
          },
        },
      },
      {
        date: '2024-07-23',
        festivos: ['Festividad local de Castillejo de Iniesta'],
        pc_ocupacion: 29.0,
        room: {
          double: {
            newPrice: 47.63,
            priceBefore: 43.9,
            priceNow: 48.28,
          },
          single: {
            newPrice: 104.1,
            priceBefore: 98.2,
            priceNow: 107.37,
          },
          suite: {
            newPrice: 108.67,
            priceBefore: 105.0,
            priceNow: 104.87,
          },
        },
      },
      {
        date: '2024-07-24',
        festivos: [],
        pc_ocupacion: 28.0,
        room: {
          double: {
            newPrice: 42.82,
            priceBefore: 41.79,
            priceNow: 41.12,
          },
          single: {
            newPrice: 72.87,
            priceBefore: 76.5,
            priceNow: 78.94,
          },
          suite: {
            newPrice: 132.55,
            priceBefore: 121.64,
            priceNow: 117.94,
          },
        },
      },
      {
        date: '2024-07-25',
        festivos: [
          'Festividad local de Santa Ana',
          'Festividad local de Carrión de Calatrava',
          'Festividad local de Granátula de Calatrava',
          'Festividad local de La Solana',
          'Festividad local de Membrilla',
          'Festividad local de Viso del Marques',
          'Festividad local de Valsalobre',
          'Festividad local de Campisabalos',
          'Festividad local de Mohernando',
          'Festividad local de Valdelcubo',
          'Festividad local de Alcañizo',
          'Festividad local de Burujón',
          'Festividad local de Carpio de Tajo',
          'Festividad local de Espinoso del Rey',
          'Festividad local de Nombela',
          'Festividad local de Quero',
          'Festividad local de Santo Domingo Caudilla',
          'Festividad local de Sartajada',
          'Festividad local de Sotillo de las Palomas',
        ],
        pc_ocupacion: 47.0,
        room: {
          double: {
            newPrice: 126.16,
            priceBefore: 124.25,
            priceNow: 119.34,
          },
          single: {
            newPrice: 97.43,
            priceBefore: 94.5,
            priceNow: 99.78,
          },
          suite: {
            newPrice: 147.47,
            priceBefore: 139.92,
            priceNow: 137.22,
          },
        },
      },
      {
        date: '2024-07-26',
        festivos: [
          'Festividad local de Motilleja',
          'Festividad local de Pozo Lorente',
          'Festividad local de Santa Ana',
          'Festividad local de Almadén',
          'Festividad local de Cabezarados',
          'Festividad local de Fontanarejo',
          'Festividad local de Carrascosa del Campo',
          'Festividad local de El Pedernoso',
          'Festividad local de Gabaldon',
          'Festividad local de Pineda de Gigüela',
          'Festividad local de Reillo',
          'Festividad local de Valsalobre',
          'Festividad local de Vindel',
          'Festividad local de Alarilla',
          'Festividad local de Campisabalos',
          'Festividad local de Muduex',
          'Festividad local de Torremocha del Campo',
          'Festividad local de Añover de Tajo',
          'Festividad local de Burujón',
          'Festividad local de Camarenilla',
          'Festividad local de Mora',
          'Festividad local de Nombela',
          'Festividad local de Santa Ana de Pusa',
          'Festividad local de Santo Domingo Caudilla',
          'Festividad local de Villanueva de Bogas',
        ],
        pc_ocupacion: 32.0,
        room: {
          double: {
            newPrice: 43.05,
            priceBefore: 45.15,
            priceNow: 46.73,
          },
          single: {
            newPrice: 76.0,
            priceBefore: 76.5,
            priceNow: 78.05,
          },
          suite: {
            newPrice: 139.34,
            priceBefore: 142.44,
            priceNow: 136.32,
          },
        },
      },
      {
        date: '2024-07-27',
        festivos: [
          'Festividad local de Ribatajadilla',
          'Festividad local de Ciruelos del Pinar',
        ],
        pc_ocupacion: 27.0,
        room: {
          double: {
            newPrice: 42.71,
            priceBefore: 38.99,
            priceNow: 39.44,
          },
          single: {
            newPrice: 60.23,
            priceBefore: 55.9,
            priceNow: 55.39,
          },
          suite: {
            newPrice: 146.57,
            priceBefore: 137.77,
            priceNow: 142.11,
          },
        },
      },
      {
        date: '2024-07-28',
        festivos: [],
        pc_ocupacion: 34.0,
        room: {
          double: {
            newPrice: 37.99,
            priceBefore: 36.36,
            priceNow: 35.32,
          },
          single: {
            newPrice: 93.86,
            priceBefore: 91.38,
            priceNow: 98.76,
          },
          suite: {
            newPrice: 165.36,
            priceBefore: 150.88,
            priceNow: 156.77,
          },
        },
      },
      {
        date: '2024-07-29',
        festivos: [
          'Festividad local de Cabezarrubias del Puerto',
          'Festividad local de Mestanza',
          'Festividad local de Solana del Pino',
          'Festividad local de Cobisa',
          'Festividad local de Ontígola',
        ],
        pc_ocupacion: 44.0,
        room: {
          double: {
            newPrice: 88.07,
            priceBefore: 85.14,
            priceNow: 82.44,
          },
          single: {
            newPrice: 107.23,
            priceBefore: 106.75,
            priceNow: 105.14,
          },
          suite: {
            newPrice: 147.75,
            priceBefore: 149.18,
            priceNow: 162.58,
          },
        },
      },
      {
        date: '2024-07-30',
        festivos: [],
        pc_ocupacion: 31.0,
        room: {
          double: {
            newPrice: 37.61,
            priceBefore: 38.96,
            priceNow: 42.07,
          },
          single: {
            newPrice: 77.85,
            priceBefore: 76.5,
            priceNow: 76.87,
          },
          suite: {
            newPrice: 140.88,
            priceBefore: 143.7,
            priceNow: 139.71,
          },
        },
      },
      {
        date: '2024-07-31',
        festivos: ['Festividad local de Tribaldos'],
        pc_ocupacion: 25.0,
        room: {
          double: {
            newPrice: 27.62,
            priceBefore: 26.9,
            priceNow: 26.13,
          },
          single: {
            newPrice: 57.87,
            priceBefore: 55.96,
            priceNow: 57.18,
          },
          suite: {
            newPrice: 138.82,
            priceBefore: 140.7,
            priceNow: 150.38,
          },
        },
      },
      {
        date: '2024-08-01',
        festivos: [
          'Festividad local de San Pedro Palmiches',
          'Festividad local de Villalpardo',
          'Festividad local de Las Ventas de Retamosa',
        ],
        pc_ocupacion: 29.0,
        room: {
          double: {
            newPrice: 25.45,
            priceBefore: 25.69,
            priceNow: 24.75,
          },
          single: {
            newPrice: 84.15,
            priceBefore: 76.5,
            priceNow: 77.42,
          },
          suite: {
            newPrice: 146.91,
            priceBefore: 143.66,
            priceNow: 144.71,
          },
        },
      },
      {
        date: '2024-08-02',
        festivos: [
          'Festividad local de Calzada de Calatrava',
          'Festividad local de Pedro Muñoz',
          'Festividad local de Buciegas',
          'Festividad local de La Huerguina',
          'Festividad local de Villar del Águila',
          'Festividad local de Yemeda',
          'Festividad local de Amayas',
          'Festividad local de El Olivar',
          'Festividad local de El Ordial',
          'Festividad local de Escopete',
          'Festividad local de La Bodera',
          'Festividad local de Renera',
          'Festividad local de Robledo de Corpes',
          'Festividad local de Torre del Burgo',
          'Festividad local de Valdesotos',
          'Festividad local de Argés',
          'Festividad local de Los Cerralbos',
          'Festividad local de Montearagón',
        ],
        pc_ocupacion: 38.0,
        room: {
          double: {
            newPrice: 82.54,
            priceBefore: 79.64,
            priceNow: 87.41,
          },
          single: {
            newPrice: 77.0,
            priceBefore: 76.5,
            priceNow: 76.83,
          },
          suite: {
            newPrice: 160.75,
            priceBefore: 147.77,
            priceNow: 145.1,
          },
        },
      },
      {
        date: '2024-08-03',
        festivos: [
          'Festividad local de Amayas',
          'Festividad local de El Olivar',
          'Festividad local de Gualda',
          'Festividad local de La Bodera',
          'Festividad local de Bargas',
        ],
        pc_ocupacion: 26.0,
        room: {
          double: {
            newPrice: 86.39,
            priceBefore: 82.09,
            priceNow: 81.8,
          },
          single: {
            newPrice: 62.14,
            priceBefore: 65.0,
            priceNow: 70.36,
          },
          suite: {
            newPrice: 80.19,
            priceBefore: 82.09,
            priceNow: 78.55,
          },
        },
      },
      {
        date: '2024-08-04',
        festivos: [
          'Festividad local de Algar de Mesa',
          'Festividad local de Bañuelos',
          'Festividad local de Loranca de Tajuña',
          'Festividad local de Olmeda de Cobeta',
          'Festividad local de Somolinos',
          'Festividad local de Valdepinillos',
        ],
        pc_ocupacion: 33.0,
        room: {
          double: {
            newPrice: 68.71,
            priceBefore: 63.3,
            priceNow: 60.17,
          },
          single: {
            newPrice: 64.17,
            priceBefore: 65.0,
            priceNow: 68.05,
          },
          suite: {
            newPrice: 152.29,
            priceBefore: 147.56,
            priceNow: 144.96,
          },
        },
      },
      {
        date: '2024-08-05',
        festivos: [
          'Festividad local de Cenizate',
          'Festividad local de Chinchilla',
          'Festividad local de Almagro',
          'Festividad local de Valdepeñas',
          'Festividad local de Almodóvar del Pinar',
          'Festividad local de Tejadillos',
          'Festividad local de Torralba',
          'Festividad local de Villanueva de la Jara',
          'Festividad local de Anguita',
          'Festividad local de Membrillera',
          'Festividad local de Ocentejo',
          'Festividad local de Renera',
          'Festividad local de Valdesotos',
          'Festividad local de Valtablado de Rio',
          'Festividad local de Lagartera',
          'Festividad local de Malpica de Tajo',
          'Festividad local de Pulgar',
          'Festividad local de Quero',
          'Festividad local de Torralba de Oropesa',
        ],
        pc_ocupacion: 37.0,
        room: {
          double: {
            newPrice: 81.95,
            priceBefore: 85.47,
            priceNow: 90.64,
          },
          single: {
            newPrice: 63.99,
            priceBefore: 65.0,
            priceNow: 64.58,
          },
          suite: {
            newPrice: 151.7,
            priceBefore: 144.77,
            priceNow: 158.0,
          },
        },
      },
      {
        date: '2024-08-06',
        festivos: [
          'Festividad local de La Roda',
          'Festividad local de Socovos',
          'Festividad local de Pozuelo de Calatrava',
          'Festividad local de Olmedilla de Eliz',
          'Festividad local de Huérmeces del Cerro',
          'Festividad local de Málaga del Fresno',
          'Festividad local de Montarrón',
          'Festividad local de Puebla de Valles',
          'Festividad local de Santiuste',
          'Festividad local de Torralba de Oropesa',
        ],
        pc_ocupacion: 35.0,
        room: {
          double: {
            newPrice: 64.19,
            priceBefore: 60.51,
            priceNow: 65.53,
          },
          single: {
            newPrice: 79.04,
            priceBefore: 76.5,
            priceNow: 73.73,
          },
          suite: {
            newPrice: 146.21,
            priceBefore: 145.53,
            priceNow: 156.93,
          },
        },
      },
      {
        date: '2024-08-07',
        festivos: [
          'Festividad local de Tresjuncos',
          'Festividad local de Pradena de Atienza',
          'Festividad local de Somolinos',
          'Festividad local de Tordellego',
        ],
        pc_ocupacion: 37.0,
        room: {
          double: {
            newPrice: 61.36,
            priceBefore: 58.54,
            priceNow: 58.68,
          },
          single: {
            newPrice: 81.54,
            priceBefore: 81.0,
            priceNow: 77.23,
          },
          suite: {
            newPrice: 154.34,
            priceBefore: 162.0,
            priceNow: 165.36,
          },
        },
      },
      {
        date: '2024-08-08',
        festivos: [
          'Festividad local de Terrinches',
          'Festividad local de Moya',
          'Festividad local de Ocentejo',
          'Festividad local de Torremocha del Pinar',
          'Festividad local de Valtablado de Rio',
          'Festividad local de Valmojado',
        ],
        pc_ocupacion: 39.0,
        room: {
          double: {
            newPrice: 62.39,
            priceBefore: 60.14,
            priceNow: 59.2,
          },
          single: {
            newPrice: 96.54,
            priceBefore: 98.0,
            priceNow: 105.83,
          },
          suite: {
            newPrice: 162.68,
            priceBefore: 153.0,
            priceNow: 166.13,
          },
        },
      },
      {
        date: '2024-08-09',
        festivos: [
          'Festividad local de El Ballestero',
          'Festividad local de San Lorenzo de Calatrava',
          'Festividad local de Santa Cruz de Mudela',
          'Festividad local de Casas de Garcimolina',
          'Festividad local de Mota de Altarejos',
          'Festividad local de Naharros',
          'Festividad local de Adobes',
          'Festividad local de Albendiego',
          'Festividad local de Castellar de la Muela',
          'Festividad local de Peñalén',
          'Festividad local de Riofrio del Llano',
          'Festividad local de Tortuero',
          'Festividad local de Viñuelas',
          'Festividad local de Campillo de La Jara',
          'Festividad local de Parrillas',
        ],
        pc_ocupacion: 34.0,
        room: {
          double: {
            newPrice: 63.73,
            priceBefore: 62.38,
            priceNow: 65.51,
          },
          single: {
            newPrice: 63.36,
            priceBefore: 65.0,
            priceNow: 68.19,
          },
          suite: {
            newPrice: 152.08,
            priceBefore: 153.0,
            priceNow: 148.96,
          },
        },
      },
      {
        date: '2024-08-10',
        festivos: [
          'Festividad local de Socuéllamos',
          'Festividad local de Arguisuelas',
          'Festividad local de Bustares',
          'Festividad local de Carrascosa de Henares',
          'Festividad local de Negredo',
          'Festividad local de Riofrio del Llano',
          'Festividad local de Romanillos de Atienza',
          'Festividad local de Torremochuela',
        ],
        pc_ocupacion: 39.0,
        room: {
          double: {
            newPrice: 67.85,
            priceBefore: 63.23,
            priceNow: 69.08,
          },
          single: {
            newPrice: 88.13,
            priceBefore: 81.0,
            priceNow: 88.99,
          },
          suite: {
            newPrice: 162.01,
            priceBefore: 162.0,
            priceNow: 156.85,
          },
        },
      },
      {
        date: '2024-08-11',
        festivos: [
          'Festividad local de Anchuela del Campo',
          'Festividad local de Negredo',
        ],
        pc_ocupacion: 43.0,
        room: {
          double: {
            newPrice: 93.14,
            priceBefore: 96.88,
            priceNow: 102.53,
          },
          single: {
            newPrice: 91.73,
            priceBefore: 89.0,
            priceNow: 89.86,
          },
          suite: {
            newPrice: 168.02,
            priceBefore: 153.0,
            priceNow: 156.62,
          },
        },
      },
      {
        date: '2024-08-12',
        festivos: [
          'Festividad local de Chillón',
          'Festividad local de Beamud',
          'Festividad local de Cervera del Llano',
          'Festividad local de Mota de Altarejos',
          'Festividad local de Mota del Cuervo',
          'Festividad local de San Lorenzo de la Parrilla',
          'Festividad local de Villarrubio',
          'Festividad local de Arbeteta',
          'Festividad local de Campillo de Ranas',
          'Festividad local de Checa',
          'Festividad local de Concha',
          'Festividad local de Gárgoles de Abajo',
          'Festividad local de Hinojosa',
          'Festividad local de Mantiel',
          'Festividad local de Peralveche',
        ],
        pc_ocupacion: 37.0,
        room: {
          double: {
            newPrice: 46.48,
            priceBefore: 48.2,
            priceNow: 52.37,
          },
          single: {
            newPrice: 98.82,
            priceBefore: 98.47,
            priceNow: 104.34,
          },
          suite: {
            newPrice: 164.46,
            priceBefore: 153.0,
            priceNow: 167.39,
          },
        },
      },
      {
        date: '2024-08-13',
        festivos: [
          'Festividad local de Beamud',
          'Festividad local de Castillo de Garcimuñoz',
          'Festividad local de Villarrubio',
          'Festividad local de Estables',
          'Festividad local de Terzaga',
        ],
        pc_ocupacion: 37.0,
        room: {
          double: {
            newPrice: 57.5,
            priceBefore: 57.75,
            priceNow: 55.94,
          },
          single: {
            newPrice: 84.29,
            priceBefore: 85.0,
            priceNow: 92.96,
          },
          suite: {
            newPrice: 154.76,
            priceBefore: 153.0,
            priceNow: 158.74,
          },
        },
      },
      {
        date: '2024-08-14',
        festivos: [
          'Festividad local de Jorquera',
          'Festividad local de Castillo de Garcimuñoz',
          'Festividad local de San Martin de Boniches',
          'Festividad local de Uña',
          'Festividad local de Campillo de Ranas',
          'Festividad local de Congostrina',
          'Festividad local de La Mierla',
          'Festividad local de Las Navas de Jadraque',
          'Festividad local de Prados Redondos',
          'Festividad local de Rebollosa de Jadraque',
          'Festividad local de Retiendas',
          'Festividad local de Sayatón',
          'Festividad local de Terzaga',
          'Festividad local de Uceda',
          'Festividad local de Alameda de la Sagra',
          'Festividad local de Bernuy',
          'Festividad local de Fuentes',
          'Festividad local de La Estrella',
          'Festividad local de Romeral',
        ],
        pc_ocupacion: 41.0,
        room: {
          double: {
            newPrice: 55.45,
            priceBefore: 50.55,
            priceNow: 54.47,
          },
          single: {
            newPrice: 115.29,
            priceBefore: 109.0,
            priceNow: 107.26,
          },
          suite: {
            newPrice: 172.38,
            priceBefore: 162.0,
            priceNow: 173.04,
          },
        },
      },
      {
        date: '2024-08-15',
        festivos: [
          'Asunción de la Virgen',
          'Festividad local de Hontecillas',
          'Festividad local de Anchuela del Pedregal',
          'Festividad local de Arbeteta',
          'Festividad local de Gascueña de Bornova',
          'Festividad local de Peralveche',
          'Festividad local de Tordelpalo',
        ],
        pc_ocupacion: 40.0,
        room: {
          double: {
            newPrice: 52.27,
            priceBefore: 52.1,
            priceNow: 56.09,
          },
          single: {
            newPrice: 101.02,
            priceBefore: 104.0,
            priceNow: 107.33,
          },
          suite: {
            newPrice: 174.6,
            priceBefore: 162.0,
            priceNow: 175.07,
          },
        },
      },
      {
        date: '2024-08-16',
        festivos: [
          'Festividad local de Barrax',
          'Festividad local de Bormate',
          'Festividad local de Fuentealbilla',
          'Festividad local de Letur',
          'Festividad local de Salobre',
          'Festividad local de Tobarra',
          'Festividad local de Villalgordo del Júcar',
          'Festividad local de Villarrobledo',
          'Festividad local de Villatoya',
          'Festividad local de Alamillo',
          'Festividad local de Alcolea de Calatrava',
          'Festividad local de Moral de Calatrava',
          'Festividad local de Torre de Juan Abad',
          'Festividad local de Aliaguilla',
          'Festividad local de Arcos de la Cantera',
          'Festividad local de Barbalimpia',
          'Festividad local de Bascuñana de San Pedro',
          'Festividad local de Bolliga',
          'Festividad local de Boniches',
          'Festividad local de Buenache de la Sierra',
          'Festividad local de Campillos Paravientos',
          'Festividad local de Cañamares',
          'Festividad local de Carboneras de Guadazaon',
          'Festividad local de Castillejo-Sierra',
          'Festividad local de El Provencio',
          'Festividad local de Enguidanos',
          'Festividad local de Fuentesbuenas',
          'Festividad local de Garcinarro',
          'Festividad local de Henarejos',
          'Festividad local de La Pesquera',
          'Festividad local de Lagunaseca',
          'Festividad local de Landete',
          'Festividad local de Ledaña',
          'Festividad local de Monteagudo de las Salinas',
          'Festividad local de Navalon',
          'Festividad local de Olivares del Jucar',
          'Festividad local de Olmedilla de Alarcon',
          'Festividad local de Olmedilla del Campo',
          'Festividad local de Pajaroncillo',
          'Festividad local de Portalrubio de Guadamejud',
          'Festividad local de Portilla',
          'Festividad local de Poyatos',
          'Festividad local de Rada de Haro',
          'Festividad local de Reillo',
          'Festividad local de Salinas del Manzano',
          'Festividad local de Sotoca',
          'Festividad local de Tondos',
          'Festividad local de Torrecilla',
          'Festividad local de Uña',
          'Festividad local de Valdecabras',
          'Festividad local de Valdemorillo de la Sierra',
          'Festividad local de Valeria',
          'Festividad local de Villaescusa de Haro',
          'Festividad local de Villalba de la Sierra',
          'Festividad local de Villanueva de los Escuderos',
          'Festividad local de Villar de la Encina',
          'Festividad local de Villar del Infantado',
          'Festividad local de Villarejo del Espartal',
          'Festividad local de Villarejo Seco',
          'Festividad local de Villarta',
          'Festividad local de Villora',
          'Festividad local de Vindel',
          'Festividad local de Alcocer',
          'Festividad local de Alcolea de las Peñas',
          'Festividad local de Alcolea del Pinar',
          'Festividad local de Aldeanueva de Atienza',
          'Festividad local de Aldeanueva de Guadalajara',
          'Festividad local de Alique',
          'Festividad local de Alocén',
          'Festividad local de Anquela del Pedregal',
          'Festividad local de Baides',
          'Festividad local de Brihuega',
          'Festividad local de Bujarrabal',
          'Festividad local de Bustares',
          'Festividad local de Canales del Ducado',
          'Festividad local de Cincovillas',
          'Festividad local de Cívica',
          'Festividad local de Condemios de Arriba',
          'Festividad local de Copernal',
          'Festividad local de Durón',
          'Festividad local de El Sotillo',
          'Festividad local de Fontanar',
          'Festividad local de Fuentelsaz',
          'Festividad local de Fuentelviejo',
          'Festividad local de Gárgoles de Abajo',
          'Festividad local de Gascueña de Bornova',
          'Festividad local de Illana',
          'Festividad local de Las Navas de Jadraque',
          'Festividad local de Mazarete',
          'Festividad local de Megina',
          'Festividad local de Olmeda de Cobeta',
          'Festividad local de Piqueras',
          'Festividad local de Poveda de la Sierra',
          'Festividad local de Rebollosa de Jadraque',
          'Festividad local de San Andrés del Congosto',
          'Festividad local de San Andrés del Rey',
          'Festividad local de Sayatón',
          'Festividad local de Selas',
          'Festividad local de Semillas',
          'Festividad local de Sienes',
          'Festividad local de Sigüenza',
          'Festividad local de Tierzo',
          'Festividad local de Tordelpalo',
          'Festividad local de Tordelrábano',
          'Festividad local de Torrejón del Rey',
          'Festividad local de Uceda',
          'Festividad local de Valdearenas',
          'Festividad local de Yélamos de Arriba',
          'Festividad local de Zarzuela de Jadraque',
          'Festividad local de Alameda de la Sagra',
          'Festividad local de Camarena',
          'Festividad local de Camuñas',
          'Festividad local de Casar de Escalona',
          'Festividad local de Cervera de los Montes',
          'Festividad local de Erustes',
          'Festividad local de Escalona',
          'Festividad local de Las Navillas',
          'Festividad local de Los Alares',
          'Festividad local de Magán',
          'Festividad local de Mazarambroz',
          'Festividad local de Mohedas de La Jara',
          'Festividad local de Navalcán',
          'Festividad local de Nuño Gómez',
          'Festividad local de Palomeque',
          'Festividad local de Quintanar de la Orden',
          'Festividad local de Quismondo',
          'Festividad local de San Román de los Montes',
          'Festividad local de Seseña',
          'Festividad local de Valdeverdeja',
        ],
        pc_ocupacion: 33.0,
        room: {
          double: {
            newPrice: 51.29,
            priceBefore: 52.06,
            priceNow: 56.68,
          },
          single: {
            newPrice: 57.49,
            priceBefore: 55.96,
            priceNow: 59.92,
          },
          suite: {
            newPrice: 157.5,
            priceBefore: 162.0,
            priceNow: 168.86,
          },
        },
      },
      {
        date: '2024-08-17',
        festivos: [
          'Festividad local de Hijes',
          'Festividad local de Mazarete',
          'Festividad local de Piqueras',
          'Festividad local de Poveda de la Sierra',
          'Festividad local de Taravilla',
        ],
        pc_ocupacion: 53.0,
        room: {
          double: {
            newPrice: 128.78,
            priceBefore: 129.0,
            priceNow: 135.6,
          },
          single: {
            newPrice: 129.47,
            priceBefore: 119.0,
            priceNow: 129.13,
          },
          suite: {
            newPrice: 162.98,
            priceBefore: 153.0,
            priceNow: 161.63,
          },
        },
      },
      {
        date: '2024-08-18',
        festivos: [
          'Festividad local de Castilnuevo',
          'Festividad local de El Ordial',
          'Festividad local de Estriegana',
          'Festividad local de La Toba',
          'Festividad local de Taravilla',
          'Festividad local de Torrecuadrada de Molina',
        ],
        pc_ocupacion: 51.0,
        room: {
          double: {
            newPrice: 138.06,
            priceBefore: 129.0,
            priceNow: 134.83,
          },
          single: {
            newPrice: 95.92,
            priceBefore: 95.0,
            priceNow: 93.16,
          },
          suite: {
            newPrice: 175.05,
            priceBefore: 162.0,
            priceNow: 173.92,
          },
        },
      },
      {
        date: '2024-08-19',
        festivos: [
          'Festividad local de Nerpio',
          'Festividad local de Membrilla',
          'Festividad local de El Provencio',
          'Festividad local de Moya',
          'Festividad local de Tinajas',
          'Festividad local de Torrubia del Castillo',
          'Festividad local de Villar de Domingo Garcia',
          'Festividad local de Alocén',
          'Festividad local de Estriegana',
          'Festividad local de Labros',
          'Festividad local de Tamajón',
          'Festividad local de Esquivias',
          'Festividad local de Retamoso de la Jara',
          'Festividad local de Valdeverdeja',
        ],
        pc_ocupacion: 33.0,
        room: {
          double: {
            newPrice: 79.76,
            priceBefore: 77.2,
            priceNow: 78.87,
          },
          single: {
            newPrice: 107.64,
            priceBefore: 99.06,
            priceNow: 95.78,
          },
          suite: {
            newPrice: 106.44,
            priceBefore: 97.0,
            priceNow: 103.77,
          },
        },
      },
      {
        date: '2024-08-20',
        festivos: [
          'Festividad local de Bogarra',
          'Festividad local de Letur',
          'Festividad local de Nerpio',
          'Festividad local de Hinojosas de Calatrava',
          'Festividad local de Campillos-Sierra',
          'Festividad local de La Hinojosa',
          'Festividad local de Torrubia del Castillo',
          'Festividad local de El Pobo de Dueñas',
          'Festividad local de Fuembellida',
          'Festividad local de Tamajón',
          'Festividad local de Esquivias',
        ],
        pc_ocupacion: 43.0,
        room: {
          double: {
            newPrice: 80.26,
            priceBefore: 82.78,
            priceNow: 81.81,
          },
          single: {
            newPrice: 100.18,
            priceBefore: 96.92,
            priceNow: 96.07,
          },
          suite: {
            newPrice: 163.59,
            priceBefore: 153.0,
            priceNow: 159.49,
          },
        },
      },
      {
        date: '2024-08-21',
        festivos: [
          'Festividad local de Iniesta',
          'Festividad local de Cubillejo de la Sierra',
          'Festividad local de Fuembellida',
        ],
        pc_ocupacion: 45.0,
        room: {
          double: {
            newPrice: 105.73,
            priceBefore: 96.61,
            priceNow: 101.68,
          },
          single: {
            newPrice: 72.74,
            priceBefore: 75.0,
            priceNow: 77.5,
          },
          suite: {
            newPrice: 173.87,
            priceBefore: 174.0,
            priceNow: 174.64,
          },
        },
      },
      {
        date: '2024-08-22',
        festivos: [
          'Festividad local de Ciudad Real',
          'Festividad local de San Clemente',
          'Festividad local de Alcoroches',
          'Festividad local de La Olmeda de Jadraque',
          'Festividad local de Morenilla',
          'Festividad local de Torremocha de Jadraque',
        ],
        pc_ocupacion: 45.0,
        room: {
          double: {
            newPrice: 85.63,
            priceBefore: 80.66,
            priceNow: 80.99,
          },
          single: {
            newPrice: 102.39,
            priceBefore: 94.5,
            priceNow: 101.92,
          },
          suite: {
            newPrice: 182.28,
            priceBefore: 174.0,
            priceNow: 190.47,
          },
        },
      },
      {
        date: '2024-08-23',
        festivos: [
          'Festividad local de Madrigueras',
          'Festividad local de Pozuelo',
          'Festividad local de Reolid',
          'Festividad local de Yeste',
          'Festividad local de Los Pozuelos de Calatrava',
          'Festividad local de Atalaya del Cañavate',
          'Festividad local de Fresneda de Altarejos',
          'Festividad local de La Almarcha',
          'Festividad local de Laguna del Marquesado',
          'Festividad local de Torrejoncillo del Rey',
          'Festividad local de Alcoroches',
          'Festividad local de Arbancón',
          'Festividad local de Armuña de Tajuña',
          'Festividad local de Casa de Uceda',
          'Festividad local de Galápagos',
          'Festividad local de Galve de Sorbe',
          'Festividad local de Henche',
          'Festividad local de Hita',
          'Festividad local de Morenilla',
          'Festividad local de Tartanedo',
          'Festividad local de Torremocha de Jadraque',
          'Festividad local de Valdepeñas de la Sierra',
          'Festividad local de Yebes',
          'Festividad local de Yélamos de Arriba',
          'Festividad local de Aldeanueva de San Bartolomé',
          'Festividad local de Cabañas de Yepes',
          'Festividad local de Gargantilla',
          'Festividad local de Marrupe',
          'Festividad local de Puerto de San Vicente',
          'Festividad local de San Bartolomé de las Abiertas',
        ],
        pc_ocupacion: 41.0,
        room: {
          double: {
            newPrice: 86.13,
            priceBefore: 89.25,
            priceNow: 87.34,
          },
          single: {
            newPrice: 82.52,
            priceBefore: 76.5,
            priceNow: 84.06,
          },
          suite: {
            newPrice: 166.37,
            priceBefore: 153.0,
            priceNow: 154.93,
          },
        },
      },
      {
        date: '2024-08-24',
        festivos: [
          'Festividad local de Tarazona de la Mancha',
          'Festividad local de Almagro',
          'Festividad local de Campo de Criptana',
          'Festividad local de Arcos de la Sierra',
          'Festividad local de Casasimarro',
          'Festividad local de Castillejo del Romeral',
          'Festividad local de Colliga',
          'Festividad local de Villarejo de la Peñuela',
          'Festividad local de Campillo de Dueñas',
          'Festividad local de El Cubillo de Uceda',
          'Festividad local de Jirueque',
          'Festividad local de Lupiana',
          'Festividad local de Matarrubia',
          'Festividad local de Rillo de Gallo',
          'Festividad local de El Viso de San Juan',
          'Festividad local de Navahermosa',
        ],
        pc_ocupacion: 49.0,
        room: {
          double: {
            newPrice: 134.18,
            priceBefore: 123.5,
            priceNow: 117.47,
          },
          single: {
            newPrice: 95.56,
            priceBefore: 90.0,
            priceNow: 95.76,
          },
          suite: {
            newPrice: 148.42,
            priceBefore: 156.0,
            priceNow: 151.28,
          },
        },
      },
      {
        date: '2024-08-25',
        festivos: ['Festividad local de Rillo de Gallo'],
        pc_ocupacion: 43.0,
        room: {
          double: {
            newPrice: 87.26,
            priceBefore: 83.28,
            priceNow: 87.27,
          },
          single: {
            newPrice: 92.7,
            priceBefore: 96.62,
            priceNow: 97.67,
          },
          suite: {
            newPrice: 167.49,
            priceBefore: 156.0,
            priceNow: 153.89,
          },
        },
      },
      {
        date: '2024-08-26',
        festivos: [
          'Festividad local de Carcelén',
          'Festividad local de Ballesteros de Calatrava',
          'Festividad local de Navalpino',
          'Festividad local de Alcohujate',
          'Festividad local de Belmonte',
          'Festividad local de Casas de Fernando Alonso',
          'Festividad local de Casas de Haro',
          'Festividad local de Castillejo del Romeral',
          'Festividad local de Fresneda de Altarejos',
          'Festividad local de Gascueña',
          'Festividad local de Piqueras del Castillo',
          'Festividad local de Pozorrubio de Santiago',
          'Festividad local de Ucles',
          'Festividad local de Vellisca',
          'Festividad local de Checa',
          'Festividad local de Cubillejo del Sitio',
          'Festividad local de Hita',
          'Festividad local de Sacecorbo',
          'Festividad local de Valfermoso de Tajuña',
          'Festividad local de Alcañizo',
          'Festividad local de Aldeanueva de Barbarroya',
          'Festividad local de Aldeanueva de San Bartolomé',
          'Festividad local de Añover de Tajo',
          'Festividad local de Arisgotas',
          'Festividad local de Cabañas de Yepes',
          'Festividad local de Corral de Almaguer',
          'Festividad local de Hormigos',
          'Festividad local de Huerta de Valdecarábanos',
          'Festividad local de Mesegar de Tajo',
          'Festividad local de Orgaz',
          'Festividad local de San Bartolomé de las Abiertas',
          'Festividad local de Santa Cruz de la Zarza',
          'Festividad local de Torrecilla de La Jara',
        ],
        pc_ocupacion: 40.0,
        room: {
          double: {
            newPrice: 99.94,
            priceBefore: 96.24,
            priceNow: 99.63,
          },
          single: {
            newPrice: 62.01,
            priceBefore: 65.0,
            priceNow: 71.3,
          },
          suite: {
            newPrice: 163.37,
            priceBefore: 156.0,
            priceNow: 160.41,
          },
        },
      },
      {
        date: '2024-08-27',
        festivos: [
          'Festividad local de Paredes de Sigüenza',
          'Festividad local de Valfermoso de Tajuña',
          'Festividad local de Albarreal de Tajo',
          'Festividad local de Huerta de Valdecarábanos',
        ],
        pc_ocupacion: 45.0,
        room: {
          double: {
            newPrice: 92.62,
            priceBefore: 85.86,
            priceNow: 93.59,
          },
          single: {
            newPrice: 106.25,
            priceBefore: 105.0,
            priceNow: 109.98,
          },
          suite: {
            newPrice: 153.55,
            priceBefore: 153.0,
            priceNow: 162.38,
          },
        },
      },
      {
        date: '2024-08-28',
        festivos: [
          'Festividad local de Casas Ibáñez',
          'Festividad local de Peñas de San Pedro',
          'Festividad local de Fernán Caballero',
          'Festividad local de Villahermosa',
          'Festividad local de Castillejo de Iniesta',
          'Festividad local de Collados',
          'Festividad local de Mota del Cuervo',
          'Festividad local de Zafrilla',
          'Festividad local de Atanzón',
          'Festividad local de Fuentelencina',
          'Festividad local de Hombrados',
          'Festividad local de Membrillera',
          'Festividad local de Albarreal de Tajo',
          'Festividad local de El Toboso',
        ],
        pc_ocupacion: 41.0,
        room: {
          double: {
            newPrice: 82.59,
            priceBefore: 86.68,
            priceNow: 95.06,
          },
          single: {
            newPrice: 78.7,
            priceBefore: 80.0,
            priceNow: 81.19,
          },
          suite: {
            newPrice: 163.37,
            priceBefore: 153.0,
            priceNow: 159.25,
          },
        },
      },
      {
        date: '2024-08-29',
        festivos: [
          'Festividad local de Puebla de Don Rodrigo',
          'Festividad local de Aranzueque',
          'Festividad local de Espinosa de Henares',
          'Festividad local de La Yunta',
          'Festividad local de Millana',
          'Festividad local de Borox',
        ],
        pc_ocupacion: 39.0,
        room: {
          double: {
            newPrice: 95.32,
            priceBefore: 88.53,
            priceNow: 86.59,
          },
          single: {
            newPrice: 68.48,
            priceBefore: 67.0,
            priceNow: 71.13,
          },
          suite: {
            newPrice: 145.93,
            priceBefore: 153.0,
            priceNow: 154.43,
          },
        },
      },
      {
        date: '2024-08-30',
        festivos: [
          'Festividad local de Huelamo',
          'Festividad local de Pajaron',
          'Festividad local de Espinosa de Henares',
          'Festividad local de Pinilla de Jadraque 5 febrero y',
          'Festividad local de Quer',
          'Festividad local de Saelices de la Sal',
          'Festividad local de Solanillos del Extremo',
          'Festividad local de Torrecuadradilla',
          'Festividad local de Valdeaveruelo',
          'Festividad local de Villares de Jadraque',
          'Festividad local de Viñuelas',
          'Festividad local de Aldea en Cabo',
          'Festividad local de Borox',
          'Festividad local de Las Ventas de San Julian',
          'Festividad local de Torrico',
          'Festividad local de Villaluenga de la Sagra',
        ],
        pc_ocupacion: 43.0,
        room: {
          double: {
            newPrice: 95.37,
            priceBefore: 89.6,
            priceNow: 95.89,
          },
          single: {
            newPrice: 97.64,
            priceBefore: 89.0,
            priceNow: 91.37,
          },
          suite: {
            newPrice: 167.63,
            priceBefore: 158.85,
            priceNow: 171.89,
          },
        },
      },
      {
        date: '2024-08-31',
        festivos: [
          'Festividad local de Tomelloso',
          'Festividad local de Ciruelos del Pinar',
          'Festividad local de Illescas',
          'Festividad local de Villarejo de Montalbán',
        ],
        pc_ocupacion: 45.0,
        room: {
          double: {
            newPrice: 96.67,
            priceBefore: 96.41,
            priceNow: 92.49,
          },
          single: {
            newPrice: 102.03,
            priceBefore: 95.5,
            priceNow: 94.07,
          },
          suite: {
            newPrice: 152.48,
            priceBefore: 155.25,
            priceNow: 149.69,
          },
        },
      },
      {
        date: '2024-09-01',
        festivos: [
          'Festividad local de Cuevas de Velasco',
          'Festividad local de Villar del Maestre',
        ],
        pc_ocupacion: 46.0,
        room: {
          double: {
            newPrice: 98.77,
            priceBefore: 94.72,
            priceNow: 96.56,
          },
          single: {
            newPrice: 102.18,
            priceBefore: 98.5,
            priceNow: 104.77,
          },
          suite: {
            newPrice: 168.84,
            priceBefore: 159.75,
            priceNow: 153.66,
          },
        },
      },
      {
        date: '2024-09-02',
        festivos: [
          'Festividad local de Almansa',
          'Festividad local de Argamasilla de Alba',
          'Festividad local de Daimiel',
          'Festividad local de Arrancacepas',
          'Festividad local de Cueva del Hierro',
          'Festividad local de Las Pedroñeras',
          'Festividad local de Motilla del Palancar',
          'Festividad local de Pajaroncillo',
          'Festividad local de Valhermoso de la Fuente',
          'Festividad local de Villalba del Rey',
          'Festividad local de Caspueñas',
          'Festividad local de El Casar',
          'Festividad local de Molina de Aragón',
          'Festividad local de Solanillos del Extremo',
          'Festividad local de Burguillos de Toledo',
          'Festividad local de Hontanar',
          'Festividad local de La Fresneda de la Jara',
          'Festividad local de La Puebla de Almoradiel',
          'Festividad local de Las Ventas de San Julian',
          'Festividad local de Torrico',
        ],
        pc_ocupacion: 39.0,
        room: {
          double: {
            newPrice: 75.27,
            priceBefore: 74.61,
            priceNow: 73.96,
          },
          single: {
            newPrice: 80.35,
            priceBefore: 80.85,
            priceNow: 79.05,
          },
          suite: {
            newPrice: 168.04,
            priceBefore: 154.18,
            priceNow: 154.32,
          },
        },
      },
      {
        date: '2024-09-03',
        festivos: [
          'Festividad local de El Robledo',
          'Festividad local de Cueva del Hierro',
        ],
        pc_ocupacion: 44.0,
        room: {
          double: {
            newPrice: 94.53,
            priceBefore: 96.0,
            priceNow: 97.43,
          },
          single: {
            newPrice: 89.14,
            priceBefore: 89.25,
            priceNow: 87.85,
          },
          suite: {
            newPrice: 160.67,
            priceBefore: 155.0,
            priceNow: 170.0,
          },
        },
      },
      {
        date: '2024-09-04',
        festivos: ['Festividad local de Ayna'],
        pc_ocupacion: 55.0,
        room: {
          double: {
            newPrice: 75.84,
            priceBefore: 78.54,
            priceNow: 78.92,
          },
          single: {
            newPrice: 163.04,
            priceBefore: 159.0,
            priceNow: 156.71,
          },
          suite: {
            newPrice: 184.68,
            priceBefore: 174.0,
            priceNow: 180.21,
          },
        },
      },
      {
        date: '2024-09-05',
        festivos: [
          'Festividad local de Alcaraz',
          'Festividad local de Daimiel',
          'Festividad local de Villamayor de Santiago',
          'Festividad local de Corduente',
        ],
        pc_ocupacion: 47.0,
        room: {
          double: {
            newPrice: 119.85,
            priceBefore: 110.08,
            priceNow: 107.02,
          },
          single: {
            newPrice: 88.75,
            priceBefore: 89.25,
            priceNow: 85.14,
          },
          suite: {
            newPrice: 180.77,
            priceBefore: 165.0,
            priceNow: 168.9,
          },
        },
      },
      {
        date: '2024-09-06',
        festivos: [
          'Festividad local de Ayna',
          'Festividad local de Bienservida',
          'Festividad local de Puertollano',
          'Festividad local de Almendros',
          'Festividad local de El Cañavate',
          'Festividad local de Garaballa',
          'Festividad local de Salvacañete',
          'Festividad local de Villalba de la Sierra',
          'Festividad local de Villarejo Sobrehuerta',
          'Festividad local de Alovera',
          'Festividad local de Aragosa',
          'Festividad local de Casa de Uceda',
          'Festividad local de Corduente',
          'Festividad local de Malaguilla',
          'Festividad local de Mandayona',
          'Festividad local de Miralrío',
          'Festividad local de Orea',
          'Festividad local de Pareja',
          'Festividad local de Peñalver',
          'Festividad local de Riba de Saelices',
          'Festividad local de Romanones',
          'Festividad local de Trillo',
          'Festividad local de Carranque',
          'Festividad local de El Bercial',
          'Festividad local de Talavera de la Reina',
          'Festividad local de Talavera la Nueva',
          'Festividad local de Velada',
          'Festividad local de Villarrubia de Santiago',
        ],
        pc_ocupacion: 38.0,
        room: {
          double: {
            newPrice: 29.9,
            priceBefore: 29.0,
            priceNow: 29.67,
          },
          single: {
            newPrice: 114.35,
            priceBefore: 105.0,
            priceNow: 114.6,
          },
          suite: {
            newPrice: 184.4,
            priceBefore: 167.65,
            priceNow: 183.66,
          },
        },
      },
      {
        date: '2024-09-07',
        festivos: [
          'Festividad local de Minaya',
          'Festividad local de Alhambra',
          'Festividad local de Cereceda',
          'Festividad local de Majaelrayo',
          'Festividad local de Puebla de Beleña',
          'Festividad local de El Bercial',
        ],
        pc_ocupacion: 40.0,
        room: {
          double: {
            newPrice: 82.7,
            priceBefore: 77.57,
            priceNow: 76.61,
          },
          single: {
            newPrice: 80.77,
            priceBefore: 78.03,
            priceNow: 76.87,
          },
          suite: {
            newPrice: 173.53,
            priceBefore: 159.75,
            priceNow: 170.9,
          },
        },
      },
      {
        date: '2024-09-08',
        festivos: [
          'Festividad local de Las Majadas',
          'Festividad local de Ribatajada',
          'Festividad local de Algar de Mesa',
          'Festividad local de Alhóndiga',
          'Festividad local de Campillo de Dueñas',
          'Festividad local de Casasana',
          'Festividad local de Cereceda',
          'Festividad local de El Recuenco',
          'Festividad local de Miedes de Atienza',
          'Festividad local de Monasterio',
          'Festividad local de Ventosa',
          'Festividad local de Zaorejas',
        ],
        pc_ocupacion: 50.0,
        room: {
          double: {
            newPrice: 123.75,
            priceBefore: 120.0,
            priceNow: 127.74,
          },
          single: {
            newPrice: 65.38,
            priceBefore: 65.0,
            priceNow: 63.1,
          },
          suite: {
            newPrice: 188.53,
            priceBefore: 195.0,
            priceNow: 205.45,
          },
        },
      },
      {
        date: '2024-09-09',
        festivos: [
          'Festividad local de Albacete',
          'Festividad local de Alborea',
          'Festividad local de Caudete',
          'Festividad local de Fuensanta',
          'Festividad local de Aldea del Rey',
          'Festividad local de Argamasilla de Calatrava',
          'Festividad local de Calzada de Calatrava',
          'Festividad local de Fuencaliente',
          'Festividad local de Llanos del Caudillo',
          'Festividad local de Luciana',
          'Festividad local de Miguelturra',
          'Festividad local de Puebla del Príncipe',
          'Festividad local de Valdemanco del Esteras',
          'Festividad local de Villahermosa',
          'Festividad local de Albendea',
          'Festividad local de Alcohujate',
          'Festividad local de Almendros',
          'Festividad local de Altarejos',
          'Festividad local de Barajas de Melo',
          'Festividad local de Belmonte',
          'Festividad local de Buendía',
          'Festividad local de Campillo de Altobuey',
          'Festividad local de Canalejas del Arroyo',
          'Festividad local de Cañada del Hoyo',
          'Festividad local de Cañete',
          'Festividad local de Cañizares',
          'Festividad local de Culebras',
          'Festividad local de El Cañavate',
          'Festividad local de El Herrumblar',
          'Festividad local de Horcajada de la Torre',
          'Festividad local de Huerta de la Obispalía',
          'Festividad local de La Peraleja',
          'Festividad local de Lagunaseca',
          'Festividad local de Loranca del Campo',
          'Festividad local de Puebla de Almenara',
          'Festividad local de Ribagorda',
          'Festividad local de Santa Maria del Campo Rus',
          'Festividad local de Tarancon',
          'Festividad local de Torrubia del Campo',
          'Festividad local de Vega del Codorno',
          'Festividad local de Villanueva de Guadamejud',
          'Festividad local de Villar de Cañas',
          'Festividad local de Villar de Olalla',
          'Festividad local de Villar del Horno',
          'Festividad local de Villarejo de Fuentes',
          'Festividad local de Balbacil',
          'Festividad local de Casasana',
          'Festividad local de Clares',
          'Festividad local de Cobeta',
          'Festividad local de Codes',
          'Festividad local de Condemios de Abajo',
          'Festividad local de Hontoba',
          'Festividad local de Horche',
          'Festividad local de Illana',
          'Festividad local de Maranchón',
          'Festividad local de Monasterio',
          'Festividad local de Pálmaces de Jadraque',
          'Festividad local de Pareja',
          'Festividad local de Peñalver',
          'Festividad local de Tendilla',
          'Festividad local de Torija',
          'Festividad local de Tortuera',
          'Festividad local de Trillo',
          'Festividad local de Turmiel',
          'Festividad local de Ventosa',
          'Festividad local de Yebra',
          'Festividad local de Alcabón',
          'Festividad local de Arisgotas',
          'Festividad local de Carriches',
          'Festividad local de Castillo de Bayuela',
          'Festividad local de Cebolla',
          'Festividad local de Cedillo del Condado',
          'Festividad local de Consuegra',
          'Festividad local de Domingo Pérez',
          'Festividad local de Espinoso del Rey',
          'Festividad local de Gamonal',
          'Festividad local de Las Hunfrías',
          'Festividad local de Los Navalucillos',
          'Festividad local de Lillo',
          'Festividad local de Méntrida',
          'Festividad local de Miguel Esteban',
          'Festividad local de Mocejón',
          'Festividad local de Navamorcuende',
          'Festividad local de Ocaña',
          'Festividad local de Orgaz',
          'Festividad local de Oropesa y Corchuela',
          'Festividad local de Paredes de Escalona',
          'Festividad local de Pepino',
          'Festividad local de Recas',
          'Festividad local de Sonseca',
          'Festividad local de Turleque',
          'Festividad local de Villamiel',
          'Festividad local de Villanueva de Bogas',
          'Festividad local de Villarrubia de Santiago',
          'Festividad local de Villaseca de la Sagra',
        ],
        pc_ocupacion: 54.0,
        room: {
          double: {
            newPrice: 116.31,
            priceBefore: 107.71,
            priceNow: 114.87,
          },
          single: {
            newPrice: 134.26,
            priceBefore: 124.0,
            priceNow: 127.11,
          },
          suite: {
            newPrice: 185.47,
            priceBefore: 174.0,
            priceNow: 171.79,
          },
        },
      },
      {
        date: '2024-09-10',
        festivos: [
          'Festividad local de Barajas de Melo',
          'Festividad local de Cañizares',
          'Festividad local de Vega del Codorno',
          'Festividad local de Almonacid de Zorita',
          'Festividad local de Horche',
          'Festividad local de Pálmaces de Jadraque',
          'Festividad local de Tendilla',
          'Festividad local de Torija',
          'Festividad local de Tortuera',
          'Festividad local de Valdelcubo',
          'Festividad local de Los Navalucillos',
          'Festividad local de Ocaña',
        ],
        pc_ocupacion: 47.0,
        room: {
          double: {
            newPrice: 60.65,
            priceBefore: 63.75,
            priceNow: 62.23,
          },
          single: {
            newPrice: 116.63,
            priceBefore: 116.75,
            priceNow: 126.16,
          },
          suite: {
            newPrice: 168.8,
            priceBefore: 176.65,
            priceNow: 191.11,
          },
        },
      },
      {
        date: '2024-09-11',
        festivos: [
          'Festividad local de Chumillas',
          'Festividad local de Hontanaya',
          'Festividad local de Pinarejo',
          'Festividad local de Almonacid de Zorita',
          'Festividad local de Almendral de La Cañada',
          'Festividad local de Retamoso de la Jara',
          'Festividad local de Villa de Don Fadrique',
        ],
        pc_ocupacion: 47.0,
        room: {
          double: {
            newPrice: 102.3,
            priceBefore: 107.51,
            priceNow: 106.17,
          },
          single: {
            newPrice: 81.22,
            priceBefore: 80.0,
            priceNow: 87.46,
          },
          suite: {
            newPrice: 178.78,
            priceBefore: 172.22,
            priceNow: 171.22,
          },
        },
      },
      {
        date: '2024-09-12',
        festivos: [
          'Festividad local de El Pozuelo',
          'Festividad local de Priego',
          'Festividad local de Guadalajara',
          'Festividad local de Calera y Chozas',
          'Festividad local de Fuensalida',
          'Festividad local de Villatobas',
          'Festividad local de Yuncos',
        ],
        pc_ocupacion: 50.0,
        room: {
          double: {
            newPrice: 103.62,
            priceBefore: 105.9,
            priceNow: 112.2,
          },
          single: {
            newPrice: 99.82,
            priceBefore: 96.9,
            priceNow: 100.58,
          },
          suite: {
            newPrice: 180.04,
            priceBefore: 177.0,
            priceNow: 170.66,
          },
        },
      },
      {
        date: '2024-09-13',
        festivos: [
          'Festividad local de Cañada de Calatrava',
          'Festividad local de Castellar de Santiago',
          'Festividad local de Fuenllana',
          'Festividad local de Montiel',
          'Festividad local de Saceruela',
          'Festividad local de Arcas',
          'Festividad local de Belmontejo',
          'Festividad local de Chillaron de Cuenca',
          'Festividad local de Horcajo De Santiago',
          'Festividad local de Leganiel',
          'Festividad local de Los Valdecolmenas',
          'Festividad local de Mariana',
          'Festividad local de Montalbanejo',
          'Festividad local de Portilla',
          'Festividad local de Priego',
          'Festividad local de Saceda-Trasierra',
          'Festividad local de Santa Maria del Val',
          'Festividad local de Valdeolivas',
          'Festividad local de Chillaron del Rey',
          'Festividad local de Chiloeches',
          'Festividad local de Cifuentes',
          'Festividad local de Guadalajara',
          'Festividad local de Hueva',
          'Festividad local de Luzón',
          'Festividad local de Moratilla de los Meleros',
          'Festividad local de Trijueque',
          'Festividad local de Villanueva de Alcorón',
          'Festividad local de Calera y Chozas',
          'Festividad local de Calzada de Oropesa',
          'Festividad local de Casarrubios del Monte',
          'Festividad local de Dosbarrios',
          'Festividad local de Escalonilla',
          'Festividad local de Fuensalida',
          'Festividad local de Hinojosa de San Vicente',
          'Festividad local de Los Cerralbos',
          'Festividad local de Los Navalmorales',
          'Festividad local de Los Yébenes',
          'Festividad local de Otero',
          'Festividad local de Real de San Vicente',
          'Festividad local de Rielves',
          'Festividad local de Sevilleja de la Jara',
          'Festividad local de Villatobas',
          'Festividad local de Yepes',
          'Festividad local de Yuncos',
        ],
        pc_ocupacion: 60.0,
        room: {
          double: {
            newPrice: 165.27,
            priceBefore: 150.25,
            priceNow: 161.28,
          },
          single: {
            newPrice: 108.41,
            priceBefore: 105.0,
            priceNow: 102.24,
          },
          suite: {
            newPrice: 198.31,
            priceBefore: 186.33,
            priceNow: 177.31,
          },
        },
      },
      {
        date: '2024-09-14',
        festivos: [
          'Festividad local de Alpera',
          'Festividad local de Villa de Vés',
          'Festividad local de Almadén',
          'Festividad local de Bolaños de Calatrava',
          'Festividad local de Cózar',
          'Festividad local de Malagón',
          'Festividad local de Manzanares',
          'Festividad local de Alarcón',
          'Festividad local de Cuevas de Velasco',
          'Festividad local de Fuentelespino de Moya',
          'Festividad local de La Alberca de Zancara',
          'Festividad local de La Parra de las Vegas',
          'Festividad local de La Ventosa',
          'Festividad local de Minglanilla',
          'Festividad local de Nohales',
          'Festividad local de Villar del Maestre',
          'Festividad local de Alaminos',
          'Festividad local de Argecilla',
          'Festividad local de Atienza',
          'Festividad local de Baños de Tajo',
          'Festividad local de Centenera',
          'Festividad local de Ciruelas',
          'Festividad local de Fuentelahiguera de Albatages',
          'Festividad local de Hueva',
          'Festividad local de Irueste',
          'Festividad local de Jadraque',
          'Festividad local de Loranca de Tajuña',
          'Festividad local de Mondéjar',
          'Festividad local de Saelices de la Sal',
          'Festividad local de Salmerón',
          'Festividad local de Val de San Garcia',
          'Festividad local de Valdeconcha',
          'Festividad local de Valdenuño Fernandez',
          'Festividad local de Valhermoso',
          'Festividad local de Carmena',
          'Festividad local de Garciotum',
          'Festividad local de Guadamur',
          'Festividad local de La Pueblanueva',
          'Festividad local de Las Herencias',
          'Festividad local de Lucillos',
          'Festividad local de Madridejos',
          'Festividad local de Pelahustán',
          'Festividad local de Segurilla',
        ],
        pc_ocupacion: 43.0,
        room: {
          double: {
            newPrice: 84.64,
            priceBefore: 78.54,
            priceNow: 82.35,
          },
          single: {
            newPrice: 81.18,
            priceBefore: 80.75,
            priceNow: 88.67,
          },
          suite: {
            newPrice: 180.47,
            priceBefore: 174.0,
            priceNow: 180.25,
          },
        },
      },
      {
        date: '2024-09-15',
        festivos: [
          'Festividad local de Villar de Cañas',
          'Festividad local de Anquela del Ducado',
          'Festividad local de Baños de Tajo',
          'Festividad local de Valhermoso',
          'Festividad local de Zarzuela de Galve',
        ],
        pc_ocupacion: 60.0,
        room: {
          double: {
            newPrice: 159.23,
            priceBefore: 164.5,
            priceNow: 156.98,
          },
          single: {
            newPrice: 70.06,
            priceBefore: 65.0,
            priceNow: 63.16,
          },
          suite: {
            newPrice: 229.85,
            priceBefore: 212.29,
            priceNow: 232.94,
          },
        },
      },
      {
        date: '2024-09-16',
        festivos: [
          'Festividad local de Elche De La Sierra',
          'Festividad local de Villapalacios',
          'Festividad local de Alcoba de los Montes',
          'Festividad local de Brazatortas',
          'Festividad local de Guadalmez',
          'Festividad local de Arandilla del Arroyo',
          'Festividad local de Beteta',
          'Festividad local de Buenache de Alarcon',
          'Festividad local de Castillejo-Sierra',
          'Festividad local de Fuente de Pedro Naharro',
          'Festividad local de Leganiel',
          'Festividad local de Osa de la Vega',
          'Festividad local de Paracuellos de la Vega',
          'Festividad local de Saelices',
          'Festividad local de Santa Maria del Val',
          'Festividad local de Sisante',
          'Festividad local de Villaescusa de Haro',
          'Festividad local de Villares del Saz',
          'Festividad local de Alcocer',
          'Festividad local de Almoguera',
          'Festividad local de Auñón',
          'Festividad local de Azuqueca de Henares',
          'Festividad local de Budia',
          'Festividad local de Chequilla',
          'Festividad local de Cifuentes',
          'Festividad local de Esplegares',
          'Festividad local de Humanes',
          'Festividad local de Jadraque',
          'Festividad local de Milmarcos',
          'Festividad local de Peñalén',
          'Festividad local de Santiuste',
          'Festividad local de Val de San Garcia',
          'Festividad local de Yunquera de Henares',
          'Festividad local de Zarzuela de Galve',
          'Festividad local de Bargas',
          'Festividad local de Calzada de Oropesa',
          'Festividad local de Chueca',
          'Festividad local de Cobeja',
          'Festividad local de Los Navalmorales',
          'Festividad local de Noez',
          'Festividad local de Numancia de la Sagra',
          'Festividad local de Otero',
          'Festividad local de Real de San Vicente',
          'Festividad local de Santa Ana de Pusa',
          'Festividad local de Villafranca de los Caballeros',
          'Festividad local de Yepes',
          'Festividad local de Yunclillos',
        ],
        pc_ocupacion: 45.0,
        room: {
          double: {
            newPrice: 123.87,
            priceBefore: 115.0,
            priceNow: 125.81,
          },
          single: {
            newPrice: 78.06,
            priceBefore: 71.4,
            priceNow: 77.62,
          },
          suite: {
            newPrice: 166.02,
            priceBefore: 161.33,
            priceNow: 164.5,
          },
        },
      },
      {
        date: '2024-09-17',
        festivos: [
          'Festividad local de Beteta',
          'Festividad local de Budia',
          'Festividad local de Yunquera de Henares',
          'Festividad local de Cobeja',
          'Festividad local de Numancia de la Sagra',
        ],
        pc_ocupacion: 49.0,
        room: {
          double: {
            newPrice: 124.83,
            priceBefore: 115.0,
            priceNow: 120.41,
          },
          single: {
            newPrice: 68.34,
            priceBefore: 70.0,
            priceNow: 76.99,
          },
          suite: {
            newPrice: 204.37,
            priceBefore: 191.33,
            priceNow: 194.64,
          },
        },
      },
      {
        date: '2024-09-18',
        festivos: [
          'Festividad local de Villanueva de los Infantes',
          'Festividad local de Honrubia',
          'Festividad local de Escamilla',
          'Festividad local de Luzaga',
        ],
        pc_ocupacion: 56.0,
        room: {
          double: {
            newPrice: 116.59,
            priceBefore: 115.0,
            priceNow: 123.11,
          },
          single: {
            newPrice: 118.57,
            priceBefore: 108.0,
            priceNow: 117.95,
          },
          suite: {
            newPrice: 197.63,
            priceBefore: 194.52,
            priceNow: 201.1,
          },
        },
      },
      {
        date: '2024-09-19',
        festivos: ['Festividad local de Oropesa y Corchuela'],
        pc_ocupacion: 56.0,
        room: {
          double: {
            newPrice: 127.16,
            priceBefore: 118.15,
            priceNow: 128.84,
          },
          single: {
            newPrice: 98.18,
            priceBefore: 98.25,
            priceNow: 97.94,
          },
          suite: {
            newPrice: 217.46,
            priceBefore: 200.75,
            priceNow: 210.78,
          },
        },
      },
      {
        date: '2024-09-20',
        festivos: [
          'Festividad local de Villaverde del Guadalimar',
          'Festividad local de Valverde de Jucar',
          'Festividad local de Ablanque',
          'Festividad local de Peralejos de las Truchas',
          'Festividad local de Pozo de Guadalajara',
          'Festividad local de Gerindote',
        ],
        pc_ocupacion: 49.0,
        room: {
          double: {
            newPrice: 115.6,
            priceBefore: 118.15,
            priceNow: 128.15,
          },
          single: {
            newPrice: 86.76,
            priceBefore: 85.0,
            priceNow: 90.13,
          },
          suite: {
            newPrice: 190.58,
            priceBefore: 174.0,
            priceNow: 166.33,
          },
        },
      },
      {
        date: '2024-09-21',
        festivos: [
          'Festividad local de La Recueja',
          'Festividad local de Cuenca',
          'Festividad local de La Melgosa',
          'Festividad local de Mohorte',
          'Festividad local de Consuegra',
        ],
        pc_ocupacion: 51.0,
        room: {
          double: {
            newPrice: 158.72,
            priceBefore: 155.0,
            priceNow: 159.36,
          },
          single: {
            newPrice: 50.07,
            priceBefore: 49.5,
            priceNow: 50.14,
          },
          suite: {
            newPrice: 195.7,
            priceBefore: 182.98,
            priceNow: 181.09,
          },
        },
      },
      {
        date: '2024-09-22',
        festivos: [],
        pc_ocupacion: 49.0,
        room: {
          double: {
            newPrice: 81.21,
            priceBefore: 75.0,
            priceNow: 71.84,
          },
          single: {
            newPrice: 111.66,
            priceBefore: 106.0,
            priceNow: 106.73,
          },
          suite: {
            newPrice: 181.5,
            priceBefore: 190.68,
            priceNow: 194.52,
          },
        },
      },
      {
        date: '2024-09-23',
        festivos: [
          'Festividad local de Munera',
          'Festividad local de Navas de Estena',
          'Festividad local de Peralejos de las Truchas',
          'Festividad local de Totanés',
          'Festividad local de Villaminaya',
        ],
        pc_ocupacion: 65.0,
        room: {
          double: {
            newPrice: 111.6,
            priceBefore: 114.94,
            priceNow: 123.39,
          },
          single: {
            newPrice: 164.04,
            priceBefore: 155.8,
            priceNow: 155.65,
          },
          suite: {
            newPrice: 218.58,
            priceBefore: 200.37,
            priceNow: 194.33,
          },
        },
      },
      {
        date: '2024-09-24',
        festivos: [
          'Festividad local de Hoya Gonzalo',
          'Festividad local de Herencia',
          'Festividad local de Los Cortijos',
          'Festividad local de Abánades',
          'Festividad local de Mochales',
          'Festividad local de Torremochuela',
          'Festividad local de Villel de Mesa',
          'Festividad local de Las Ventas con Peña Aguilera',
          'Festividad local de Pantoja',
          'Festividad local de Villaluenga de la Sagra',
        ],
        pc_ocupacion: 55.0,
        room: {
          double: {
            newPrice: 106.11,
            priceBefore: 107.03,
            priceNow: 116.28,
          },
          single: {
            newPrice: 93.79,
            priceBefore: 87.5,
            priceNow: 87.22,
          },
          suite: {
            newPrice: 224.82,
            priceBefore: 215.0,
            priceNow: 215.99,
          },
        },
      },
      {
        date: '2024-09-25',
        festivos: [
          'Festividad local de Los Cortijos',
          'Festividad local de La Guardia',
          'Festividad local de Pantoja',
        ],
        pc_ocupacion: 79.0,
        room: {
          double: {
            newPrice: 197.82,
            priceBefore: 180.0,
            priceNow: 195.77,
          },
          single: {
            newPrice: 194.79,
            priceBefore: 185.0,
            priceNow: 176.76,
          },
          suite: {
            newPrice: 188.31,
            priceBefore: 194.99,
            priceNow: 213.98,
          },
        },
      },
      {
        date: '2024-09-26',
        festivos: [
          'Festividad local de La Guardia',
          'Festividad local de Noves',
        ],
        pc_ocupacion: 83.0,
        room: {
          double: {
            newPrice: 164.85,
            priceBefore: 167.22,
            priceNow: 180.66,
          },
          single: {
            newPrice: 182.21,
            priceBefore: 170.0,
            priceNow: 185.05,
          },
          suite: {
            newPrice: 258.71,
            priceBefore: 250.0,
            priceNow: 268.49,
          },
        },
      },
      {
        date: '2024-09-27',
        festivos: [
          'Festividad local de Abengibre',
          'Festividad local de El Peral',
          'Festividad local de Palomares del Campo',
          'Festividad local de Albalate de Zorita',
          'Festividad local de Heras de Ayuso',
          'Festividad local de Villaseca de Uceda',
          'Festividad local de Alberche del Caudillo',
          'Festividad local de Buenaventura',
          'Festividad local de Noves',
          'Festividad local de Torrijos',
        ],
        pc_ocupacion: 49.0,
        room: {
          double: {
            newPrice: 114.46,
            priceBefore: 113.73,
            priceNow: 117.08,
          },
          single: {
            newPrice: 63.72,
            priceBefore: 65.0,
            priceNow: 70.96,
          },
          suite: {
            newPrice: 209.17,
            priceBefore: 195.91,
            priceNow: 202.32,
          },
        },
      },
      {
        date: '2024-09-28',
        festivos: [],
        pc_ocupacion: 52.0,
        room: {
          double: {
            newPrice: 142.59,
            priceBefore: 138.0,
            priceNow: 150.59,
          },
          single: {
            newPrice: 63.3,
            priceBefore: 65.0,
            priceNow: 67.31,
          },
          suite: {
            newPrice: 207.75,
            priceBefore: 189.91,
            priceNow: 184.26,
          },
        },
      },
      {
        date: '2024-09-29',
        festivos: [
          'Festividad local de Fresneda de la Sierra',
          'Festividad local de Huelves',
          'Festividad local de Tondos',
          'Festividad local de Valparaiso de Arriba',
          'Festividad local de Anchuela del Campo',
        ],
        pc_ocupacion: 47.0,
        room: {
          double: {
            newPrice: 96.72,
            priceBefore: 92.33,
            priceNow: 94.32,
          },
          single: {
            newPrice: 90.58,
            priceBefore: 85.0,
            priceNow: 83.78,
          },
          suite: {
            newPrice: 170.86,
            priceBefore: 179.6,
            priceNow: 185.21,
          },
        },
      },
      {
        date: '2024-09-30',
        festivos: [
          'Festividad local de Chinchilla',
          'Festividad local de Corralrubio',
          'Festividad local de El Ballestero',
          'Festividad local de Ossa de Montiel',
          'Festividad local de Paterna del Madera',
          'Festividad local de Peñascosa',
          'Festividad local de Anchuras',
          'Festividad local de Montalbo',
          'Festividad local de Narboneta',
          'Festividad local de Tragacete',
          'Festividad local de Castejón de Henares',
          'Festividad local de Driebes',
          'Festividad local de Huertahernando',
          'Festividad local de Yebes',
          'Festividad local de Robledo del Mazo',
          'Festividad local de Torrecilla de La Jara',
          'Festividad local de Urda',
        ],
        pc_ocupacion: 51.0,
        room: {
          double: {
            newPrice: 115.3,
            priceBefore: 116.03,
            priceNow: 115.46,
          },
          single: {
            newPrice: 72.24,
            priceBefore: 75.53,
            priceNow: 76.77,
          },
          suite: {
            newPrice: 198.81,
            priceBefore: 196.0,
            priceNow: 213.61,
          },
        },
      },
      {
        date: '2024-10-01',
        festivos: ['Festividad local de Montalbo', 'Festividad local de Embid'],
        pc_ocupacion: 59.0,
        room: {
          double: {
            newPrice: 126.25,
            priceBefore: 123.33,
            priceNow: 126.32,
          },
          single: {
            newPrice: 121.67,
            priceBefore: 126.0,
            priceNow: 136.06,
          },
          suite: {
            newPrice: 192.18,
            priceBefore: 186.97,
            priceNow: 205.28,
          },
        },
      },
      {
        date: '2024-10-02',
        festivos: [
          'Festividad local de Arguisuelas',
          'Festividad local de La Cierva',
          'Festividad local de Semillas',
          'Festividad local de Yélamos de Abajo',
        ],
        pc_ocupacion: 61.0,
        room: {
          double: {
            newPrice: 125.31,
            priceBefore: 121.75,
            priceNow: 127.83,
          },
          single: {
            newPrice: 98.93,
            priceBefore: 100.0,
            priceNow: 106.75,
          },
          suite: {
            newPrice: 224.36,
            priceBefore: 224.5,
            priceNow: 236.59,
          },
        },
      },
      {
        date: '2024-10-03',
        festivos: ['Festividad local de Yélamos de Abajo'],
        pc_ocupacion: 55.0,
        room: {
          double: {
            newPrice: 114.64,
            priceBefore: 119.71,
            priceNow: 130.63,
          },
          single: {
            newPrice: 105.43,
            priceBefore: 100.2,
            priceNow: 102.78,
          },
          suite: {
            newPrice: 192.78,
            priceBefore: 190.0,
            priceNow: 193.17,
          },
        },
      },
      {
        date: '2024-10-04',
        festivos: [
          'Festividad local de El Salobral',
          'Festividad local de Vianos',
          'Festividad local de Masegosa',
          'Festividad local de Robledo de Corpes',
          'Festividad local de Sotodosos',
        ],
        pc_ocupacion: 53.0,
        room: {
          double: {
            newPrice: 140.89,
            priceBefore: 136.0,
            priceNow: 142.37,
          },
          single: {
            newPrice: 87.47,
            priceBefore: 80.85,
            priceNow: 88.4,
          },
          suite: {
            newPrice: 171.07,
            priceBefore: 177.96,
            priceNow: 173.51,
          },
        },
      },
      {
        date: '2024-10-05',
        festivos: [
          'Festividad local de Salinas del Manzano',
          'Festividad local de El Pedregal',
        ],
        pc_ocupacion: 41.0,
        room: {
          double: {
            newPrice: 78.0,
            priceBefore: 75.0,
            priceNow: 79.11,
          },
          single: {
            newPrice: 69.84,
            priceBefore: 65.0,
            priceNow: 66.98,
          },
          suite: {
            newPrice: 190.92,
            priceBefore: 185.36,
            priceNow: 193.59,
          },
        },
      },
      {
        date: '2024-10-06',
        festivos: [],
        pc_ocupacion: 56.0,
        room: {
          double: {
            newPrice: 128.45,
            priceBefore: 117.0,
            priceNow: 118.9,
          },
          single: {
            newPrice: 81.44,
            priceBefore: 80.0,
            priceNow: 86.27,
          },
          suite: {
            newPrice: 225.77,
            priceBefore: 221.33,
            priceNow: 239.08,
          },
        },
      },
      {
        date: '2024-10-07',
        festivos: [
          'Festividad local de Bonete',
          'Festividad local de Férez',
          'Festividad local de Masegoso',
          'Festividad local de Povedilla',
          'Festividad local de Tiriez',
          'Festividad local de Valdeganga',
          'Festividad local de Almadenejos',
          'Festividad local de Almedina',
          'Festividad local de Santa Cruz de Cáñamos',
          'Festividad local de Villanueva de San Carlos',
          'Festividad local de Buenache de la Sierra',
          'Festividad local de El Picazo',
          'Festividad local de Fuentes',
          'Festividad local de Fuertescusa',
          'Festividad local de Las Valeras',
          'Festividad local de Masegosa',
          'Festividad local de Pineda de Gigüela',
          'Festividad local de Valdetortola',
          'Festividad local de Valparaiso de Abajo',
          'Festividad local de Zafra de Zancara',
          'Festividad local de Zarzuela',
          'Festividad local de Anchuela del Pedregal',
          'Festividad local de Anguita',
          'Festividad local de Driebes',
          'Festividad local de El Sotillo',
          'Festividad local de Málaga del Fresno',
          'Festividad local de Saúca',
          'Festividad local de Setiles',
          'Festividad local de Cabañas de la Sagra',
          'Festividad local de Cardiel de los Montes',
          'Festividad local de Lagartera',
          'Festividad local de Las Vegas y San Antonio',
          'Festividad local de Montearagón',
          'Festividad local de Olias del Rey',
          'Festividad local de Ontígola',
        ],
        pc_ocupacion: 53.0,
        room: {
          double: {
            newPrice: 103.42,
            priceBefore: 104.72,
            priceNow: 99.57,
          },
          single: {
            newPrice: 101.45,
            priceBefore: 94.33,
            priceNow: 92.93,
          },
          suite: {
            newPrice: 191.37,
            priceBefore: 196.0,
            priceNow: 189.7,
          },
        },
      },
      {
        date: '2024-10-08',
        festivos: [
          'Festividad local de Férez',
          'Festividad local de Valdeganga',
          'Festividad local de Valenzuela de Calatrava',
          'Festividad local de Sotos',
          'Festividad local de Setiles',
          'Festividad local de Olias del Rey',
        ],
        pc_ocupacion: 49.0,
        room: {
          double: {
            newPrice: 123.69,
            priceBefore: 117.0,
            priceNow: 124.28,
          },
          single: {
            newPrice: 85.05,
            priceBefore: 86.0,
            priceNow: 94.04,
          },
          suite: {
            newPrice: 164.88,
            priceBefore: 171.95,
            priceNow: 187.93,
          },
        },
      },
      {
        date: '2024-10-09',
        festivos: [
          'Festividad local de Fuenteálamo',
          'Festividad local de Villar del Humo',
          'Festividad local de Ujados',
        ],
        pc_ocupacion: 47.0,
        room: {
          double: {
            newPrice: 104.19,
            priceBefore: 108.0,
            priceNow: 105.31,
          },
          single: {
            newPrice: 80.41,
            priceBefore: 75.0,
            priceNow: 80.02,
          },
          suite: {
            newPrice: 194.9,
            priceBefore: 177.81,
            priceNow: 184.15,
          },
        },
      },
      {
        date: '2024-10-10',
        festivos: [
          'Festividad local de Cantalojas',
          'Festividad local de Torremocha del Pinar',
          'Festividad local de Portillo de Toledo',
        ],
        pc_ocupacion: 52.0,
        room: {
          double: {
            newPrice: 119.37,
            priceBefore: 118.8,
            priceNow: 116.13,
          },
          single: {
            newPrice: 72.91,
            priceBefore: 70.0,
            priceNow: 66.75,
          },
          suite: {
            newPrice: 203.61,
            priceBefore: 205.0,
            priceNow: 200.08,
          },
        },
      },
      {
        date: '2024-10-11',
        festivos: [
          'Festividad local de Bormate',
          'Festividad local de La Herrera',
          'Festividad local de Cardenete',
          'Festividad local de Carrascosa de la Sierra',
          'Festividad local de Henarejos',
          'Festividad local de Cantalojas',
          'Festividad local de Los Alares',
          'Festividad local de Pulgar',
          'Festividad local de Robledo del Buey',
        ],
        pc_ocupacion: 46.0,
        room: {
          double: {
            newPrice: 88.26,
            priceBefore: 82.94,
            priceNow: 87.59,
          },
          single: {
            newPrice: 100.18,
            priceBefore: 96.3,
            priceNow: 100.84,
          },
          suite: {
            newPrice: 173.99,
            priceBefore: 175.45,
            priceNow: 176.62,
          },
        },
      },
      {
        date: '2024-10-12',
        festivos: ['Fiesta Nacional de España'],
        pc_ocupacion: 33.0,
        room: {
          double: {
            newPrice: 81.39,
            priceBefore: 83.16,
            priceNow: 85.83,
          },
          single: {
            newPrice: 95.34,
            priceBefore: 100.0,
            priceNow: 107.01,
          },
          suite: {
            newPrice: 96.68,
            priceBefore: 92.87,
            priceNow: 89.84,
          },
        },
      },
      {
        date: '2024-10-13',
        festivos: ['Festividad local de Huelamo'],
        pc_ocupacion: 43.0,
        room: {
          double: {
            newPrice: 79.54,
            priceBefore: 75.0,
            priceNow: 76.85,
          },
          single: {
            newPrice: 83.92,
            priceBefore: 82.8,
            priceNow: 87.75,
          },
          suite: {
            newPrice: 190.73,
            priceBefore: 174.54,
            priceNow: 171.83,
          },
        },
      },
      {
        date: '2024-10-14',
        festivos: [
          'Festividad local de Molinicos',
          'Festividad local de Belinchon',
        ],
        pc_ocupacion: 49.0,
        room: {
          double: {
            newPrice: 81.76,
            priceBefore: 78.54,
            priceNow: 77.75,
          },
          single: {
            newPrice: 123.73,
            priceBefore: 129.0,
            priceNow: 139.37,
          },
          suite: {
            newPrice: 166.97,
            priceBefore: 163.58,
            priceNow: 170.9,
          },
        },
      },
      {
        date: '2024-10-15',
        festivos: [
          'Festividad local de Malagón',
          'Festividad local de Jabaga',
          'Festividad local de Villanueva de la Jara',
          'Festividad local de Pastrana',
        ],
        pc_ocupacion: 54.0,
        room: {
          double: {
            newPrice: 90.76,
            priceBefore: 93.6,
            priceNow: 89.13,
          },
          single: {
            newPrice: 130.43,
            priceBefore: 135.0,
            priceNow: 136.92,
          },
          suite: {
            newPrice: 175.2,
            priceBefore: 177.22,
            priceNow: 170.12,
          },
        },
      },
      {
        date: '2024-10-16',
        festivos: [],
        pc_ocupacion: 43.0,
        room: {
          double: {
            newPrice: 85.06,
            priceBefore: 78.54,
            priceNow: 79.29,
          },
          single: {
            newPrice: 103.49,
            priceBefore: 101.5,
            priceNow: 102.76,
          },
          suite: {
            newPrice: 161.66,
            priceBefore: 156.6,
            priceNow: 151.22,
          },
        },
      },
      {
        date: '2024-10-17',
        festivos: ['Festividad local de Cívica'],
        pc_ocupacion: 43.0,
        room: {
          double: {
            newPrice: 124.51,
            priceBefore: 113.85,
            priceNow: 118.89,
          },
          single: {
            newPrice: 62.43,
            priceBefore: 65.0,
            priceNow: 69.48,
          },
          suite: {
            newPrice: 151.99,
            priceBefore: 156.18,
            priceNow: 156.75,
          },
        },
      },
      {
        date: '2024-10-18',
        festivos: [],
        pc_ocupacion: 53.0,
        room: {
          double: {
            newPrice: 140.88,
            priceBefore: 136.0,
            priceNow: 141.8,
          },
          single: {
            newPrice: 101.76,
            priceBefore: 103.5,
            priceNow: 100.81,
          },
          suite: {
            newPrice: 151.44,
            priceBefore: 159.3,
            priceNow: 167.79,
          },
        },
      },
      {
        date: '2024-10-19',
        festivos: [],
        pc_ocupacion: 31.0,
        room: {
          double: {
            newPrice: 93.0,
            priceBefore: 89.0,
            priceNow: 85.97,
          },
          single: {
            newPrice: 82.57,
            priceBefore: 77.0,
            priceNow: 77.11,
          },
          suite: {
            newPrice: 96.4,
            priceBefore: 90.0,
            priceNow: 86.34,
          },
        },
      },
      {
        date: '2024-10-20',
        festivos: [],
        pc_ocupacion: 39.0,
        room: {
          double: {
            newPrice: 80.68,
            priceBefore: 75.0,
            priceNow: 74.53,
          },
          single: {
            newPrice: 55.51,
            priceBefore: 54.75,
            priceNow: 55.74,
          },
          suite: {
            newPrice: 184.0,
            priceBefore: 179.3,
            priceNow: 193.87,
          },
        },
      },
      {
        date: '2024-10-21',
        festivos: ['Festividad local de Brihuega'],
        pc_ocupacion: 43.0,
        room: {
          double: {
            newPrice: 149.84,
            priceBefore: 145.0,
            priceNow: 140.08,
          },
          single: {
            newPrice: 105.22,
            priceBefore: 96.3,
            priceNow: 99.5,
          },
          suite: {
            newPrice: 98.88,
            priceBefore: 96.58,
            priceNow: 101.48,
          },
        },
      },
      {
        date: '2024-10-22',
        festivos: [],
        pc_ocupacion: 56.0,
        room: {
          double: {
            newPrice: 104.57,
            priceBefore: 105.3,
            priceNow: 107.98,
          },
          single: {
            newPrice: 122.17,
            priceBefore: 114.0,
            priceNow: 110.17,
          },
          suite: {
            newPrice: 192.91,
            priceBefore: 199.8,
            priceNow: 195.79,
          },
        },
      },
      {
        date: '2024-10-23',
        festivos: [],
        pc_ocupacion: 44.0,
        room: {
          double: {
            newPrice: 106.65,
            priceBefore: 110.09,
            priceNow: 120.48,
          },
          single: {
            newPrice: 85.54,
            priceBefore: 88.5,
            priceNow: 95.18,
          },
          suite: {
            newPrice: 151.37,
            priceBefore: 141.09,
            priceNow: 134.18,
          },
        },
      },
      {
        date: '2024-10-24',
        festivos: ['Festividad local de Hellín'],
        pc_ocupacion: 61.0,
        room: {
          double: {
            newPrice: 134.49,
            priceBefore: 136.0,
            priceNow: 141.88,
          },
          single: {
            newPrice: 92.3,
            priceBefore: 89.25,
            priceNow: 89.74,
          },
          suite: {
            newPrice: 230.44,
            priceBefore: 224.0,
            priceNow: 216.48,
          },
        },
      },
      {
        date: '2024-10-25',
        festivos: [],
        pc_ocupacion: 52.0,
        room: {
          double: {
            newPrice: 105.23,
            priceBefore: 105.0,
            priceNow: 113.43,
          },
          single: {
            newPrice: 85.48,
            priceBefore: 89.25,
            priceNow: 87.13,
          },
          suite: {
            newPrice: 199.15,
            priceBefore: 198.68,
            priceNow: 213.62,
          },
        },
      },
      {
        date: '2024-10-26',
        festivos: [],
        pc_ocupacion: 43.0,
        room: {
          double: {
            newPrice: 75.29,
            priceBefore: 75.0,
            priceNow: 80.27,
          },
          single: {
            newPrice: 77.52,
            priceBefore: 72.24,
            priceNow: 73.56,
          },
          suite: {
            newPrice: 198.22,
            priceBefore: 189.49,
            priceNow: 196.76,
          },
        },
      },
      {
        date: '2024-10-27',
        festivos: [],
        pc_ocupacion: 47.0,
        room: {
          double: {
            newPrice: 74.7,
            priceBefore: 75.0,
            priceNow: 77.37,
          },
          single: {
            newPrice: 88.67,
            priceBefore: 89.25,
            priceNow: 85.97,
          },
          suite: {
            newPrice: 198.46,
            priceBefore: 196.0,
            priceNow: 210.17,
          },
        },
      },
      {
        date: '2024-10-28',
        festivos: [],
        pc_ocupacion: 39.0,
        room: {
          double: {
            newPrice: 71.4,
            priceBefore: 75.0,
            priceNow: 80.11,
          },
          single: {
            newPrice: 89.34,
            priceBefore: 85.5,
            priceNow: 86.95,
          },
          suite: {
            newPrice: 160.25,
            priceBefore: 151.99,
            priceNow: 155.31,
          },
        },
      },
      {
        date: '2024-10-29',
        festivos: [],
        pc_ocupacion: 35.0,
        room: {
          double: {
            newPrice: 70.1,
            priceBefore: 71.25,
            priceNow: 68.84,
          },
          single: {
            newPrice: 78.44,
            priceBefore: 75.0,
            priceNow: 78.17,
          },
          suite: {
            newPrice: 144.82,
            priceBefore: 139.5,
            priceNow: 145.69,
          },
        },
      },
      {
        date: '2024-10-30',
        festivos: ['Festividad local de Barriopedro'],
        pc_ocupacion: 34.0,
        room: {
          double: {
            newPrice: 64.2,
            priceBefore: 60.0,
            priceNow: 59.75,
          },
          single: {
            newPrice: 66.4,
            priceBefore: 65.0,
            priceNow: 70.35,
          },
          suite: {
            newPrice: 159.47,
            priceBefore: 151.62,
            priceNow: 158.96,
          },
        },
      },
      {
        date: '2024-10-31',
        festivos: ['Festividad local de Menasalbas'],
        pc_ocupacion: 41.0,
        room: {
          double: {
            newPrice: 67.86,
            priceBefore: 63.75,
            priceNow: 68.62,
          },
          single: {
            newPrice: 84.4,
            priceBefore: 78.54,
            priceNow: 79.54,
          },
          suite: {
            newPrice: 175.93,
            priceBefore: 177.0,
            priceNow: 170.86,
          },
        },
      },
      {
        date: '2024-11-01',
        festivos: ['Día de Todos los Santos'],
        pc_ocupacion: 36.0,
        room: {
          double: {
            newPrice: 61.46,
            priceBefore: 60.0,
            priceNow: 65.87,
          },
          single: {
            newPrice: 99.08,
            priceBefore: 90.4,
            priceNow: 89.99,
          },
          suite: {
            newPrice: 145.26,
            priceBefore: 142.35,
            priceNow: 141.99,
          },
        },
      },
      {
        date: '2024-11-02',
        festivos: [],
        pc_ocupacion: 35.0,
        room: {
          double: {
            newPrice: 65.39,
            priceBefore: 60.0,
            priceNow: 57.06,
          },
          single: {
            newPrice: 74.79,
            priceBefore: 72.25,
            priceNow: 74.37,
          },
          suite: {
            newPrice: 161.61,
            priceBefore: 150.45,
            priceNow: 158.08,
          },
        },
      },
      {
        date: '2024-11-03',
        festivos: [],
        pc_ocupacion: 31.0,
        room: {
          double: {
            newPrice: 59.81,
            priceBefore: 60.0,
            priceNow: 59.26,
          },
          single: {
            newPrice: 64.41,
            priceBefore: 60.75,
            priceNow: 62.43,
          },
          suite: {
            newPrice: 136.69,
            priceBefore: 140.31,
            priceNow: 143.87,
          },
        },
      },
      {
        date: '2024-11-04',
        festivos: [
          'Festividad local de Las Labores',
          'Festividad local de Barchin del Hoyo',
        ],
        pc_ocupacion: 45.0,
        room: {
          double: {
            newPrice: 121.43,
            priceBefore: 117.0,
            priceNow: 120.43,
          },
          single: {
            newPrice: 85.98,
            priceBefore: 80.75,
            priceNow: 88.2,
          },
          suite: {
            newPrice: 161.66,
            priceBefore: 150.45,
            priceNow: 145.69,
          },
        },
      },
      {
        date: '2024-11-05',
        festivos: [],
        pc_ocupacion: 29.0,
        room: {
          double: {
            newPrice: 65.35,
            priceBefore: 60.0,
            priceNow: 65.04,
          },
          single: {
            newPrice: 109.47,
            priceBefore: 105.5,
            priceNow: 109.04,
          },
          suite: {
            newPrice: 89.2,
            priceBefore: 81.25,
            priceNow: 86.29,
          },
        },
      },
      {
        date: '2024-11-06',
        festivos: [],
        pc_ocupacion: 37.0,
        room: {
          double: {
            newPrice: 58.8,
            priceBefore: 60.0,
            priceNow: 64.36,
          },
          single: {
            newPrice: 96.1,
            priceBefore: 96.3,
            priceNow: 96.69,
          },
          suite: {
            newPrice: 136.29,
            priceBefore: 139.78,
            priceNow: 147.58,
          },
        },
      },
      {
        date: '2024-11-07',
        festivos: [],
        pc_ocupacion: 32.0,
        room: {
          double: {
            newPrice: 62.21,
            priceBefore: 60.0,
            priceNow: 61.78,
          },
          single: {
            newPrice: 77.49,
            priceBefore: 76.0,
            priceNow: 75.96,
          },
          suite: {
            newPrice: 126.88,
            priceBefore: 130.45,
            priceNow: 132.23,
          },
        },
      },
      {
        date: '2024-11-08',
        festivos: ['Festividad local de Pozo de Almoguera'],
        pc_ocupacion: 35.0,
        room: {
          double: {
            newPrice: 59.89,
            priceBefore: 60.0,
            priceNow: 58.13,
          },
          single: {
            newPrice: 95.65,
            priceBefore: 90.95,
            priceNow: 90.78,
          },
          suite: {
            newPrice: 144.16,
            priceBefore: 137.87,
            priceNow: 139.55,
          },
        },
      },
      {
        date: '2024-11-09',
        festivos: [],
        pc_ocupacion: 35.0,
        room: {
          double: {
            newPrice: 65.09,
            priceBefore: 60.0,
            priceNow: 59.56,
          },
          single: {
            newPrice: 93.08,
            priceBefore: 91.63,
            priceNow: 92.52,
          },
          suite: {
            newPrice: 123.8,
            priceBefore: 130.22,
            priceNow: 129.28,
          },
        },
      },
      {
        date: '2024-11-10',
        festivos: [],
        pc_ocupacion: 32.0,
        room: {
          double: {
            newPrice: 66.84,
            priceBefore: 69.0,
            priceNow: 67.35,
          },
          single: {
            newPrice: 83.96,
            priceBefore: 77.17,
            priceNow: 80.6,
          },
          suite: {
            newPrice: 122.04,
            priceBefore: 118.9,
            priceNow: 119.35,
          },
        },
      },
      {
        date: '2024-11-11',
        festivos: [
          'Festividad local de Cañaveras',
          'Festividad local de Fuertescusa',
          'Festividad local de San Martin de Boniches',
          'Festividad local de Anquela del Ducado',
          'Festividad local de Masegoso de Tajuña',
          'Festividad local de Pozo de Almoguera',
          'Festividad local de San Martín de Pusa',
          'Festividad local de Villanueva de Alcardete',
        ],
        pc_ocupacion: 33.0,
        room: {
          double: {
            newPrice: 107.53,
            priceBefore: 107.0,
            priceNow: 110.83,
          },
          single: {
            newPrice: 74.93,
            priceBefore: 68.85,
            priceNow: 67.77,
          },
          suite: {
            newPrice: 101.08,
            priceBefore: 95.0,
            priceNow: 90.91,
          },
        },
      },
      {
        date: '2024-11-12',
        festivos: [
          'Festividad local de Cañamares',
          'Festividad local de Cogolludo',
          'Festividad local de Muduex',
        ],
        pc_ocupacion: 28.0,
        room: {
          double: {
            newPrice: 61.26,
            priceBefore: 60.0,
            priceNow: 65.53,
          },
          single: {
            newPrice: 88.82,
            priceBefore: 85.0,
            priceNow: 82.37,
          },
          suite: {
            newPrice: 96.24,
            priceBefore: 97.32,
            priceNow: 101.46,
          },
        },
      },
      {
        date: '2024-11-13',
        festivos: [],
        pc_ocupacion: 27.0,
        room: {
          double: {
            newPrice: 64.8,
            priceBefore: 60.0,
            priceNow: 64.34,
          },
          single: {
            newPrice: 74.77,
            priceBefore: 75.0,
            priceNow: 79.17,
          },
          suite: {
            newPrice: 96.64,
            priceBefore: 100.43,
            priceNow: 105.48,
          },
        },
      },
      {
        date: '2024-11-14',
        festivos: ['Festividad local de Tartanedo'],
        pc_ocupacion: 29.0,
        room: {
          double: {
            newPrice: 81.82,
            priceBefore: 81.9,
            priceNow: 78.6,
          },
          single: {
            newPrice: 75.14,
            priceBefore: 79.0,
            priceNow: 86.2,
          },
          suite: {
            newPrice: 85.83,
            priceBefore: 89.98,
            priceNow: 96.56,
          },
        },
      },
      {
        date: '2024-11-15',
        festivos: [],
        pc_ocupacion: 29.0,
        room: {
          double: {
            newPrice: 78.32,
            priceBefore: 81.9,
            priceNow: 79.61,
          },
          single: {
            newPrice: 65.03,
            priceBefore: 65.0,
            priceNow: 70.35,
          },
          suite: {
            newPrice: 95.69,
            priceBefore: 97.89,
            priceNow: 93.23,
          },
        },
      },
      {
        date: '2024-11-16',
        festivos: [],
        pc_ocupacion: 31.0,
        room: {
          double: {
            newPrice: 59.13,
            priceBefore: 60.0,
            priceNow: 58.2,
          },
          single: {
            newPrice: 107.7,
            priceBefore: 104.42,
            priceNow: 114.06,
          },
          suite: {
            newPrice: 88.62,
            priceBefore: 91.19,
            priceNow: 91.73,
          },
        },
      },
      {
        date: '2024-11-17',
        festivos: [],
        pc_ocupacion: 23.0,
        room: {
          double: {
            newPrice: 65.47,
            priceBefore: 60.0,
            priceNow: 59.51,
          },
          single: {
            newPrice: 61.39,
            priceBefore: 63.75,
            priceNow: 66.28,
          },
          suite: {
            newPrice: 83.96,
            priceBefore: 86.67,
            priceNow: 94.33,
          },
        },
      },
      {
        date: '2024-11-18',
        festivos: [
          'Festividad local de Arcos de la Cantera',
          'Festividad local de San Román de los Montes',
        ],
        pc_ocupacion: 30.0,
        room: {
          double: {
            newPrice: 64.9,
            priceBefore: 60.0,
            priceNow: 61.29,
          },
          single: {
            newPrice: 111.05,
            priceBefore: 113.4,
            priceNow: 116.97,
          },
          suite: {
            newPrice: 75.65,
            priceBefore: 78.22,
            priceNow: 75.31,
          },
        },
      },
      {
        date: '2024-11-19',
        festivos: [],
        pc_ocupacion: 31.0,
        room: {
          double: {
            newPrice: 62.0,
            priceBefore: 60.0,
            priceNow: 64.66,
          },
          single: {
            newPrice: 67.26,
            priceBefore: 65.0,
            priceNow: 70.22,
          },
          suite: {
            newPrice: 135.77,
            priceBefore: 135.0,
            priceNow: 132.62,
          },
        },
      },
      {
        date: '2024-11-20',
        festivos: [],
        pc_ocupacion: 31.0,
        room: {
          double: {
            newPrice: 58.24,
            priceBefore: 60.0,
            priceNow: 65.63,
          },
          single: {
            newPrice: 120.87,
            priceBefore: 122.0,
            priceNow: 116.45,
          },
          suite: {
            newPrice: 77.09,
            priceBefore: 74.68,
            priceNow: 75.95,
          },
        },
      },
      {
        date: '2024-11-21',
        festivos: [],
        pc_ocupacion: 35.0,
        room: {
          double: {
            newPrice: 68.51,
            priceBefore: 62.57,
            priceNow: 65.1,
          },
          single: {
            newPrice: 84.35,
            priceBefore: 79.2,
            priceNow: 81.13,
          },
          suite: {
            newPrice: 151.66,
            priceBefore: 144.01,
            priceNow: 151.52,
          },
        },
      },
      {
        date: '2024-11-22',
        festivos: [],
        pc_ocupacion: 39.0,
        room: {
          double: {
            newPrice: 109.73,
            priceBefore: 101.0,
            priceNow: 97.63,
          },
          single: {
            newPrice: 74.3,
            priceBefore: 72.25,
            priceNow: 75.43,
          },
          suite: {
            newPrice: 143.6,
            priceBefore: 135.53,
            priceNow: 141.66,
          },
        },
      },
      {
        date: '2024-11-23',
        festivos: ['Festividad local de Zarzuela de Jadraque'],
        pc_ocupacion: 33.0,
        room: {
          double: {
            newPrice: 58.15,
            priceBefore: 60.0,
            priceNow: 62.26,
          },
          single: {
            newPrice: 73.4,
            priceBefore: 72.25,
            priceNow: 71.99,
          },
          suite: {
            newPrice: 143.15,
            priceBefore: 137.65,
            priceNow: 141.96,
          },
        },
      },
      {
        date: '2024-11-24',
        festivos: [],
        pc_ocupacion: 33.0,
        room: {
          double: {
            newPrice: 68.56,
            priceBefore: 66.91,
            priceNow: 68.16,
          },
          single: {
            newPrice: 66.48,
            priceBefore: 65.0,
            priceNow: 66.82,
          },
          suite: {
            newPrice: 131.93,
            priceBefore: 136.14,
            priceNow: 144.76,
          },
        },
      },
      {
        date: '2024-11-25',
        festivos: [
          'Festividad local de Angón',
          'Festividad local de Tórtola de Henares',
        ],
        pc_ocupacion: 28.0,
        room: {
          double: {
            newPrice: 58.85,
            priceBefore: 60.0,
            priceNow: 57.81,
          },
          single: {
            newPrice: 68.61,
            priceBefore: 65.0,
            priceNow: 64.58,
          },
          suite: {
            newPrice: 124.2,
            priceBefore: 116.14,
            priceNow: 119.76,
          },
        },
      },
      {
        date: '2024-11-26',
        festivos: [],
        pc_ocupacion: 26.0,
        room: {
          double: {
            newPrice: 74.62,
            priceBefore: 72.07,
            priceNow: 73.52,
          },
          single: {
            newPrice: 57.96,
            priceBefore: 55.0,
            priceNow: 57.19,
          },
          suite: {
            newPrice: 102.09,
            priceBefore: 99.01,
            priceNow: 106.06,
          },
        },
      },
      {
        date: '2024-11-27',
        festivos: [],
        pc_ocupacion: 27.0,
        room: {
          double: {
            newPrice: 64.9,
            priceBefore: 59.75,
            priceNow: 61.59,
          },
          single: {
            newPrice: 75.57,
            priceBefore: 79.2,
            priceNow: 84.41,
          },
          suite: {
            newPrice: 100.64,
            priceBefore: 98.93,
            priceNow: 107.39,
          },
        },
      },
      {
        date: '2024-11-28',
        festivos: [],
        pc_ocupacion: 23.0,
        room: {
          double: {
            newPrice: 57.34,
            priceBefore: 60.0,
            priceNow: 65.64,
          },
          single: {
            newPrice: 60.96,
            priceBefore: 55.63,
            priceNow: 54.18,
          },
          suite: {
            newPrice: 101.51,
            priceBefore: 97.57,
            priceNow: 98.5,
          },
        },
      },
      {
        date: '2024-11-29',
        festivos: [
          'Festividad local de Viso del Marques',
          'Festividad local de Castillo de Bayuela',
          'Festividad local de San Martín de Montalbán',
        ],
        pc_ocupacion: 29.0,
        room: {
          double: {
            newPrice: 59.43,
            priceBefore: 60.0,
            priceNow: 63.54,
          },
          single: {
            newPrice: 88.72,
            priceBefore: 85.0,
            priceNow: 87.24,
          },
          suite: {
            newPrice: 98.96,
            priceBefore: 98.08,
            priceNow: 94.97,
          },
        },
      },
      {
        date: '2024-11-30',
        festivos: [
          'Festividad local de Paredes',
          'Festividad local de Fuentelahiguera de Albatages',
          'Festividad local de Romanillos de Atienza',
          'Festividad local de San Andrés del Rey',
          'Festividad local de Carmena',
        ],
        pc_ocupacion: 25.0,
        room: {
          double: {
            newPrice: 59.98,
            priceBefore: 60.0,
            priceNow: 61.26,
          },
          single: {
            newPrice: 52.48,
            priceBefore: 55.0,
            priceNow: 57.76,
          },
          suite: {
            newPrice: 99.99,
            priceBefore: 103.94,
            priceNow: 104.34,
          },
        },
      },
      {
        date: '2024-12-01',
        festivos: [],
        pc_ocupacion: 29.0,
        room: {
          double: {
            newPrice: 68.88,
            priceBefore: 66.72,
            priceNow: 67.15,
          },
          single: {
            newPrice: 88.9,
            priceBefore: 81.0,
            priceNow: 86.7,
          },
          suite: {
            newPrice: 101.82,
            priceBefore: 95.9,
            priceNow: 97.81,
          },
        },
      },
      {
        date: '2024-12-02',
        festivos: [],
        pc_ocupacion: 29.0,
        room: {
          double: {
            newPrice: 77.9,
            priceBefore: 76.11,
            priceNow: 74.24,
          },
          single: {
            newPrice: 81.32,
            priceBefore: 79.0,
            priceNow: 79.84,
          },
          suite: {
            newPrice: 90.83,
            priceBefore: 90.53,
            priceNow: 95.08,
          },
        },
      },
      {
        date: '2024-12-03',
        festivos: [],
        pc_ocupacion: 25.0,
        room: {
          double: {
            newPrice: 57.05,
            priceBefore: 58.41,
            priceNow: 55.86,
          },
          single: {
            newPrice: 80.1,
            priceBefore: 75.0,
            priceNow: 76.07,
          },
          suite: {
            newPrice: 89.77,
            priceBefore: 84.77,
            priceNow: 89.63,
          },
        },
      },
      {
        date: '2024-12-04',
        festivos: [
          'Festividad local de Congostrina',
          'Festividad local de Iniestola',
          'Festividad local de Las Hunfrías',
        ],
        pc_ocupacion: 49.0,
        room: {
          double: {
            newPrice: 120.3,
            priceBefore: 124.44,
            priceNow: 133.75,
          },
          single: {
            newPrice: 64.86,
            priceBefore: 65.0,
            priceNow: 63.79,
          },
          suite: {
            newPrice: 176.67,
            priceBefore: 183.67,
            priceNow: 180.91,
          },
        },
      },
      {
        date: '2024-12-05',
        festivos: [
          'Festividad local de Casas de Lazaro',
          'Festividad local de Matillas',
          'Festividad local de Romanones',
        ],
        pc_ocupacion: 26.0,
        room: {
          double: {
            newPrice: 78.13,
            priceBefore: 73.8,
            priceNow: 70.7,
          },
          single: {
            newPrice: 67.52,
            priceBefore: 69.0,
            priceNow: 73.69,
          },
          suite: {
            newPrice: 81.66,
            priceBefore: 83.59,
            priceNow: 86.71,
          },
        },
      },
      {
        date: '2024-12-06',
        festivos: ['Día de la Constitución Española'],
        pc_ocupacion: 31.0,
        room: {
          double: {
            newPrice: 64.63,
            priceBefore: 60.0,
            priceNow: 59.83,
          },
          single: {
            newPrice: 115.57,
            priceBefore: 107.0,
            priceNow: 106.88,
          },
          suite: {
            newPrice: 89.6,
            priceBefore: 91.17,
            priceNow: 93.83,
          },
        },
      },
      {
        date: '2024-12-07',
        festivos: [],
        pc_ocupacion: 29.0,
        room: {
          double: {
            newPrice: 63.5,
            priceBefore: 60.0,
            priceNow: 59.37,
          },
          single: {
            newPrice: 101.56,
            priceBefore: 96.25,
            priceNow: 91.9,
          },
          suite: {
            newPrice: 91.64,
            priceBefore: 92.0,
            priceNow: 97.38,
          },
        },
      },
      {
        date: '2024-12-08',
        festivos: [],
        pc_ocupacion: 30.0,
        room: {
          double: {
            newPrice: 105.02,
            priceBefore: 97.0,
            priceNow: 92.61,
          },
          single: {
            newPrice: 65.69,
            priceBefore: 65.0,
            priceNow: 65.38,
          },
          suite: {
            newPrice: 91.28,
            priceBefore: 92.96,
            priceNow: 93.97,
          },
        },
      },
      {
        date: '2024-12-09',
        festivos: [
          'Festividad local de Almuradiel',
          'Festividad local de Cañada de Calatrava',
          'Festividad local de Arrancacepas',
          'Festividad local de Barchin del Hoyo',
          'Festividad local de Horcajo De Santiago',
          'Festividad local de Viana de Jadraque',
          'Festividad local de Alcaudete de la Jara',
          'Festividad local de Camarenilla',
          'Festividad local de Casasbuenas',
          'Festividad local de Escalona',
          'Festividad local de Navaltoril',
          'Festividad local de Villaseca de la Sagra',
        ],
        pc_ocupacion: 29.0,
        room: {
          double: {
            newPrice: 66.32,
            priceBefore: 66.72,
            priceNow: 67.2,
          },
          single: {
            newPrice: 69.06,
            priceBefore: 65.0,
            priceNow: 65.58,
          },
          suite: {
            newPrice: 114.63,
            priceBefore: 113.5,
            priceNow: 117.01,
          },
        },
      },
      {
        date: '2024-12-10',
        festivos: [
          'Festividad local de Durón',
          'Festividad local de Sienes',
          'Festividad local de Navaltoril',
        ],
        pc_ocupacion: 22.0,
        room: {
          double: {
            newPrice: 64.82,
            priceBefore: 60.0,
            priceNow: 65.53,
          },
          single: {
            newPrice: 68.68,
            priceBefore: 65.0,
            priceNow: 70.34,
          },
          suite: {
            newPrice: 73.06,
            priceBefore: 76.58,
            priceNow: 80.31,
          },
        },
      },
      {
        date: '2024-12-11',
        festivos: [],
        pc_ocupacion: 37.0,
        room: {
          double: {
            newPrice: 123.4,
            priceBefore: 116.0,
            priceNow: 120.94,
          },
          single: {
            newPrice: 112.53,
            priceBefore: 107.25,
            priceNow: 116.13,
          },
          suite: {
            newPrice: 74.93,
            priceBefore: 75.94,
            priceNow: 74.78,
          },
        },
      },
      {
        date: '2024-12-12',
        festivos: [],
        pc_ocupacion: 29.0,
        room: {
          double: {
            newPrice: 73.45,
            priceBefore: 73.17,
            priceNow: 69.83,
          },
          single: {
            newPrice: 70.58,
            priceBefore: 72.25,
            priceNow: 74.22,
          },
          suite: {
            newPrice: 110.76,
            priceBefore: 102.9,
            priceNow: 105.31,
          },
        },
      },
      {
        date: '2024-12-13',
        festivos: [
          'Festividad local de Fuente el Fresno',
          'Festividad local de Retuerta del Bullaque',
          'Festividad local de Almonacid del Marquesado',
          'Festividad local de Bolliga',
          'Festividad local de Castillo-Albarañez',
          'Festividad local de Olmedilla del Campo',
          'Festividad local de Aldeanueva de Guadalajara',
          'Festividad local de Valdeavellano',
        ],
        pc_ocupacion: 25.0,
        room: {
          double: {
            newPrice: 78.35,
            priceBefore: 72.2,
            priceNow: 78.03,
          },
          single: {
            newPrice: 43.77,
            priceBefore: 40.0,
            priceNow: 42.44,
          },
          suite: {
            newPrice: 116.14,
            priceBefore: 111.98,
            priceNow: 109.12,
          },
        },
      },
      {
        date: '2024-12-14',
        festivos: [],
        pc_ocupacion: 32.0,
        room: {
          double: {
            newPrice: 75.68,
            priceBefore: 77.79,
            priceNow: 77.68,
          },
          single: {
            newPrice: 70.24,
            priceBefore: 69.6,
            priceNow: 69.86,
          },
          suite: {
            newPrice: 121.58,
            priceBefore: 118.68,
            priceNow: 120.8,
          },
        },
      },
      {
        date: '2024-12-15',
        festivos: [],
        pc_ocupacion: 33.0,
        room: {
          double: {
            newPrice: 96.68,
            priceBefore: 90.1,
            priceNow: 98.73,
          },
          single: {
            newPrice: 63.47,
            priceBefore: 58.0,
            priceNow: 59.63,
          },
          suite: {
            newPrice: 127.3,
            priceBefore: 120.15,
            priceNow: 122.16,
          },
        },
      },
      {
        date: '2024-12-16',
        festivos: [],
        pc_ocupacion: 25.0,
        room: {
          double: {
            newPrice: 93.43,
            priceBefore: 89.63,
            priceNow: 95.07,
          },
          single: {
            newPrice: 61.24,
            priceBefore: 58.0,
            priceNow: 55.54,
          },
          suite: {
            newPrice: 80.09,
            priceBefore: 75.85,
            priceNow: 76.92,
          },
        },
      },
      {
        date: '2024-12-17',
        festivos: ['Festividad local de Narboneta'],
        pc_ocupacion: 23.0,
        room: {
          double: {
            newPrice: 79.6,
            priceBefore: 74.69,
            priceNow: 72.66,
          },
          single: {
            newPrice: 62.72,
            priceBefore: 58.0,
            priceNow: 58.68,
          },
          suite: {
            newPrice: 73.43,
            priceBefore: 74.94,
            priceNow: 75.66,
          },
        },
      },
      {
        date: '2024-12-18',
        festivos: ['Festividad local de Valverdejo'],
        pc_ocupacion: 37.0,
        room: {
          double: {
            newPrice: 105.8,
            priceBefore: 97.0,
            priceNow: 95.4,
          },
          single: {
            newPrice: 71.29,
            priceBefore: 65.0,
            priceNow: 61.77,
          },
          suite: {
            newPrice: 151.85,
            priceBefore: 138.43,
            priceNow: 144.73,
          },
        },
      },
      {
        date: '2024-12-19',
        festivos: [],
        pc_ocupacion: 33.0,
        room: {
          double: {
            newPrice: 97.9,
            priceBefore: 97.0,
            priceNow: 102.23,
          },
          single: {
            newPrice: 96.51,
            priceBefore: 89.0,
            priceNow: 90.53,
          },
          suite: {
            newPrice: 82.7,
            priceBefore: 85.0,
            priceNow: 84.03,
          },
        },
      },
      {
        date: '2024-12-20',
        festivos: [
          'Festividad local de Torralba',
          'Festividad local de Villaminaya',
        ],
        pc_ocupacion: 44.0,
        room: {
          double: {
            newPrice: 138.73,
            priceBefore: 140.5,
            priceNow: 150.75,
          },
          single: {
            newPrice: 79.46,
            priceBefore: 75.0,
            priceNow: 78.81,
          },
          suite: {
            newPrice: 121.21,
            priceBefore: 127.4,
            priceNow: 121.28,
          },
        },
      },
      {
        date: '2024-12-21',
        festivos: [],
        pc_ocupacion: 30.0,
        room: {
          double: {
            newPrice: 65.47,
            priceBefore: 60.0,
            priceNow: 64.69,
          },
          single: {
            newPrice: 75.0,
            priceBefore: 72.25,
            priceNow: 77.96,
          },
          suite: {
            newPrice: 117.47,
            priceBefore: 120.34,
            priceNow: 123.46,
          },
        },
      },
      {
        date: '2024-12-22',
        festivos: [],
        pc_ocupacion: 26.0,
        room: {
          double: {
            newPrice: 63.92,
            priceBefore: 60.0,
            priceNow: 58.43,
          },
          single: {
            newPrice: 46.75,
            priceBefore: 48.33,
            priceNow: 49.35,
          },
          suite: {
            newPrice: 126.25,
            priceBefore: 119.43,
            priceNow: 118.34,
          },
        },
      },
      {
        date: '2024-12-23',
        festivos: ['Festividad local de Huecas'],
        pc_ocupacion: 33.0,
        room: {
          double: {
            newPrice: 96.18,
            priceBefore: 88.09,
            priceNow: 86.6,
          },
          single: {
            newPrice: 55.43,
            priceBefore: 56.6,
            priceNow: 53.92,
          },
          suite: {
            newPrice: 132.93,
            priceBefore: 127.11,
            priceNow: 131.09,
          },
        },
      },
      {
        date: '2024-12-24',
        festivos: [],
        pc_ocupacion: 29.0,
        room: {
          double: {
            newPrice: 65.53,
            priceBefore: 60.0,
            priceNow: 58.29,
          },
          single: {
            newPrice: 75.4,
            priceBefore: 77.25,
            priceNow: 75.28,
          },
          suite: {
            newPrice: 111.55,
            priceBefore: 109.0,
            priceNow: 104.76,
          },
        },
      },
      {
        date: '2024-12-25',
        festivos: ['Natividad del Señor'],
        pc_ocupacion: 35.0,
        room: {
          double: {
            newPrice: 101.62,
            priceBefore: 98.64,
            priceNow: 103.24,
          },
          single: {
            newPrice: 62.44,
            priceBefore: 58.0,
            priceNow: 58.69,
          },
          suite: {
            newPrice: 135.63,
            priceBefore: 129.26,
            priceNow: 129.5,
          },
        },
      },
      {
        date: '2024-12-26',
        festivos: [
          'Festividad local de Liétor',
          'Festividad local de Barbalimpia',
          'Festividad local de La Huerguina',
          'Festividad local de Humanes',
        ],
        pc_ocupacion: 33.0,
        room: {
          double: {
            newPrice: 96.04,
            priceBefore: 97.0,
            priceNow: 92.5,
          },
          single: {
            newPrice: 44.55,
            priceBefore: 43.5,
            priceNow: 45.2,
          },
          suite: {
            newPrice: 144.28,
            priceBefore: 134.78,
            priceNow: 145.92,
          },
        },
      },
      {
        date: '2024-12-27',
        festivos: ['Festividad local de Casasimarro'],
        pc_ocupacion: 42.0,
        room: {
          double: {
            newPrice: 55.87,
            priceBefore: 56.0,
            priceNow: 55.44,
          },
          single: {
            newPrice: 90.64,
            priceBefore: 85.5,
            priceNow: 81.31,
          },
          suite: {
            newPrice: 188.86,
            priceBefore: 187.0,
            priceNow: 190.83,
          },
        },
      },
      {
        date: '2024-12-28',
        festivos: ['Festividad local de Alcázar de San Juan'],
        pc_ocupacion: 56.0,
        room: {
          double: {
            newPrice: 144.95,
            priceBefore: 136.0,
            priceNow: 146.5,
          },
          single: {
            newPrice: 76.43,
            priceBefore: 80.0,
            priceNow: 83.06,
          },
          suite: {
            newPrice: 211.27,
            priceBefore: 199.33,
            priceNow: 216.33,
          },
        },
      },
      {
        date: '2024-12-29',
        festivos: [],
        pc_ocupacion: 45.0,
        room: {
          double: {
            newPrice: 100.82,
            priceBefore: 103.62,
            priceNow: 110.84,
          },
          single: {
            newPrice: 78.81,
            priceBefore: 72.25,
            priceNow: 73.31,
          },
          suite: {
            newPrice: 177.74,
            priceBefore: 170.9,
            priceNow: 170.8,
          },
        },
      },
      {
        date: '2024-12-30',
        festivos: ['Festividad local de Alcabón'],
        pc_ocupacion: 52.0,
        room: {
          double: {
            newPrice: 113.28,
            priceBefore: 118.2,
            priceNow: 121.06,
          },
          single: {
            newPrice: 72.2,
            priceBefore: 67.0,
            priceNow: 64.94,
          },
          suite: {
            newPrice: 213.72,
            priceBefore: 205.5,
            priceNow: 209.7,
          },
        },
      },
      {
        date: '2024-12-31',
        festivos: ['Festividad local de El Robledo'],
        pc_ocupacion: 65.0,
        room: {
          double: {
            newPrice: 140.83,
            priceBefore: 130.33,
            priceNow: 129.38,
          },
          single: {
            newPrice: 133.08,
            priceBefore: 135.0,
            priceNow: 143.44,
          },
          suite: {
            newPrice: 221.36,
            priceBefore: 207.0,
            priceNow: 220.88,
          },
        },
      },
      {
        date: '2025-01-01',
        festivos: ['Año Nuevo'],
        pc_ocupacion: 18.0,
        room: {
          double: {
            newPrice: 30.37,
            priceBefore: 31.0,
            priceNow: 33.49,
          },
          single: {
            newPrice: 48.4,
            priceBefore: 48.04,
            priceNow: 46.55,
          },
          suite: {
            newPrice: 96.25,
            priceBefore: 97.34,
            priceNow: 104.39,
          },
        },
      },
      {
        date: '2025-01-02',
        festivos: ['Festividad local de Rozalen Del Monte'],
        pc_ocupacion: 17.0,
        room: {
          double: {
            newPrice: 60.72,
            priceBefore: 61.5,
            priceNow: 59.27,
          },
          single: {
            newPrice: 52.37,
            priceBefore: 50.5,
            priceNow: 55.02,
          },
          suite: {
            newPrice: 59.09,
            priceBefore: 56.0,
            priceNow: 56.42,
          },
        },
      },
      {
        date: '2025-01-03',
        festivos: [],
        pc_ocupacion: 15.0,
        room: {
          double: {
            newPrice: 41.61,
            priceBefore: 39.8,
            priceNow: 42.67,
          },
          single: {
            newPrice: 40.87,
            priceBefore: 40.3,
            priceNow: 43.55,
          },
          suite: {
            newPrice: 75.97,
            priceBefore: 76.9,
            priceNow: 82.76,
          },
        },
      },
      {
        date: '2025-01-04',
        festivos: [],
        pc_ocupacion: 17.0,
        room: {
          double: {
            newPrice: 53.49,
            priceBefore: 54.0,
            priceNow: 56.64,
          },
          single: {
            newPrice: 35.78,
            priceBefore: 35.0,
            priceNow: 37.31,
          },
          suite: {
            newPrice: 77.47,
            priceBefore: 78.69,
            priceNow: 77.01,
          },
        },
      },
      {
        date: '2025-01-05',
        festivos: [
          'Festividad local de Fuentes',
          'Festividad local de La Estrella',
        ],
        pc_ocupacion: 15.0,
        room: {
          double: {
            newPrice: 45.79,
            priceBefore: 44.18,
            priceNow: 45.9,
          },
          single: {
            newPrice: 38.8,
            priceBefore: 37.5,
            priceNow: 39.49,
          },
          suite: {
            newPrice: 77.93,
            priceBefore: 77.27,
            priceNow: 78.09,
          },
        },
      },
      {
        date: '2025-01-06',
        festivos: ['Epifanía del Señor'],
        pc_ocupacion: 15.0,
        room: {
          double: {
            newPrice: 45.77,
            priceBefore: 44.23,
            priceNow: 42.07,
          },
          single: {
            newPrice: 35.81,
            priceBefore: 33.0,
            priceNow: 35.09,
          },
          suite: {
            newPrice: 81.21,
            priceBefore: 78.23,
            priceNow: 74.41,
          },
        },
      },
      {
        date: '2025-01-07',
        festivos: [],
        pc_ocupacion: 15.0,
        room: {
          double: {
            newPrice: 46.07,
            priceBefore: 46.94,
            priceNow: 48.03,
          },
          single: {
            newPrice: 44.31,
            priceBefore: 43.04,
            priceNow: 43.7,
          },
          suite: {
            newPrice: 74.97,
            priceBefore: 72.58,
            priceNow: 71.02,
          },
        },
      },
      {
        date: '2025-01-08',
        festivos: ['Festividad local de Valverde de Jucar'],
        pc_ocupacion: 16.0,
        room: {
          double: {
            newPrice: 42.13,
            priceBefore: 43.5,
            priceNow: 43.72,
          },
          single: {
            newPrice: 52.08,
            priceBefore: 48.5,
            priceNow: 52.32,
          },
          suite: {
            newPrice: 76.8,
            priceBefore: 73.34,
            priceNow: 75.83,
          },
        },
      },
      {
        date: '2025-01-09',
        festivos: [],
        pc_ocupacion: 16.0,
        room: {
          double: {
            newPrice: 62.49,
            priceBefore: 57.4,
            priceNow: 57.4,
          },
          single: {
            newPrice: 32.55,
            priceBefore: 32.88,
            priceNow: 35.22,
          },
          suite: {
            newPrice: 75.46,
            priceBefore: 74.54,
            priceNow: 77.31,
          },
        },
      },
      {
        date: '2025-01-10',
        festivos: [],
        pc_ocupacion: 9.0,
        room: {
          double: {
            newPrice: 35.05,
            priceBefore: 36.48,
            priceNow: 39.57,
          },
          single: {
            newPrice: 41.04,
            priceBefore: 37.56,
            priceNow: 41.06,
          },
          suite: {
            newPrice: 46.72,
            priceBefore: 44.0,
            priceNow: 42.37,
          },
        },
      },
      {
        date: '2025-01-11',
        festivos: [],
        pc_ocupacion: 14.0,
        room: {
          double: {
            newPrice: 51.35,
            priceBefore: 49.5,
            priceNow: 48.46,
          },
          single: {
            newPrice: 31.32,
            priceBefore: 32.84,
            priceNow: 34.78,
          },
          suite: {
            newPrice: 74.83,
            priceBefore: 69.91,
            priceNow: 66.81,
          },
        },
      },
      {
        date: '2025-01-12',
        festivos: [],
        pc_ocupacion: 14.0,
        room: {
          double: {
            newPrice: 43.14,
            priceBefore: 42.22,
            priceNow: 40.14,
          },
          single: {
            newPrice: 43.01,
            priceBefore: 41.45,
            priceNow: 43.35,
          },
          suite: {
            newPrice: 70.2,
            priceBefore: 68.3,
            priceNow: 72.63,
          },
        },
      },
      {
        date: '2025-01-13',
        festivos: [],
        pc_ocupacion: 17.0,
        room: {
          double: {
            newPrice: 48.5,
            priceBefore: 45.55,
            priceNow: 47.79,
          },
          single: {
            newPrice: 35.55,
            priceBefore: 34.75,
            priceNow: 36.96,
          },
          suite: {
            newPrice: 98.5,
            priceBefore: 90.0,
            priceNow: 96.51,
          },
        },
      },
      {
        date: '2025-01-14',
        festivos: [],
        pc_ocupacion: 7.0,
        room: {
          double: {
            newPrice: 45.2,
            priceBefore: 44.41,
            priceNow: 42.39,
          },
          single: {
            newPrice: 3.48,
            priceBefore: 3.34,
            priceNow: 3.5,
          },
          suite: {
            newPrice: 62.49,
            priceBefore: 61.14,
            priceNow: 66.19,
          },
        },
      },
      {
        date: '2025-01-15',
        festivos: [],
        pc_ocupacion: 15.0,
        room: {
          double: {
            newPrice: 48.75,
            priceBefore: 44.65,
            priceNow: 45.08,
          },
          single: {
            newPrice: 34.36,
            priceBefore: 35.8,
            priceNow: 34.02,
          },
          suite: {
            newPrice: 78.15,
            priceBefore: 75.15,
            priceNow: 75.28,
          },
        },
      },
      {
        date: '2025-01-16',
        festivos: [],
        pc_ocupacion: 13.0,
        room: {
          double: {
            newPrice: 49.51,
            priceBefore: 48.5,
            priceNow: 52.73,
          },
          single: {
            newPrice: 42.24,
            priceBefore: 39.15,
            priceNow: 40.81,
          },
          suite: {
            newPrice: 60.35,
            priceBefore: 55.16,
            priceNow: 55.05,
          },
        },
      },
      {
        date: '2025-01-17',
        festivos: [
          'Festividad local de Aguas Nuevas',
          'Festividad local de Balsa de Vés',
          'Festividad local de Casas de Juan Núñez',
          'Festividad local de Mahora',
          'Festividad local de Minaya',
          'Festividad local de Robledo',
          'Festividad local de Tobarra',
          'Festividad local de Villamalea',
          'Festividad local de Alcubillas',
          'Festividad local de Puerto Lápice',
          'Festividad local de Ruidera',
          'Festividad local de Hontecillas',
          'Festividad local de Santa Maria de los Llanos',
          'Festividad local de Santa Maria del Campo Rus',
          'Festividad local de Tebar',
          'Festividad local de Alique',
          'Festividad local de Berninches',
          'Festividad local de Cendejas de Enmedio',
          'Festividad local de Escariche',
          'Festividad local de Almonacid de Toledo',
          'Festividad local de Cuerva',
          'Festividad local de Menasalbas',
          'Festividad local de Navalmoralejo',
        ],
        pc_ocupacion: 14.0,
        room: {
          double: {
            newPrice: 43.48,
            priceBefore: 44.65,
            priceNow: 46.73,
          },
          single: {
            newPrice: 48.2,
            priceBefore: 49.0,
            priceNow: 50.45,
          },
          suite: {
            newPrice: 59.73,
            priceBefore: 57.09,
            priceNow: 61.92,
          },
        },
      },
      {
        date: '2025-01-18',
        festivos: [
          'Festividad local de Cendejas de la Torre',
          'Festividad local de Medranda',
        ],
        pc_ocupacion: 9.0,
        room: {
          double: {
            newPrice: 38.63,
            priceBefore: 38.34,
            priceNow: 36.71,
          },
          single: {
            newPrice: 34.83,
            priceBefore: 32.25,
            priceNow: 32.58,
          },
          suite: {
            newPrice: 54.1,
            priceBefore: 52.88,
            priceNow: 55.07,
          },
        },
      },
      {
        date: '2025-01-19',
        festivos: [
          'Festividad local de Bogarra',
          'Festividad local de Alarcón',
          'Festividad local de Culebras',
          'Festividad local de El Cubillo',
          'Festividad local de Garaballa',
          'Festividad local de Olmeda de la Cuesta',
          'Festividad local de Villalba del Rey',
          'Festividad local de Villar del Águila',
          'Festividad local de Villar del Horno',
          'Festividad local de Cendejas de la Torre',
          'Festividad local de Hortezuela de Océn',
          'Festividad local de El Membrillo',
          'Festividad local de El Toboso',
          'Festividad local de La Mata',
          'Festividad local de Marrupe',
          'Festividad local de Mohedas de La Jara',
          'Festividad local de Sevilleja de la Jara',
          'Festividad local de Sotillo de las Palomas',
          'Festividad local de Villamiel',
        ],
        pc_ocupacion: 10.0,
        room: {
          double: {
            newPrice: 40.16,
            priceBefore: 38.8,
            priceNow: 37.02,
          },
          single: {
            newPrice: 38.89,
            priceBefore: 35.5,
            priceNow: 33.77,
          },
          suite: {
            newPrice: 56.49,
            priceBefore: 54.22,
            priceNow: 51.65,
          },
        },
      },
      {
        date: '2025-01-20',
        festivos: [
          'Festividad local de Fernán Caballero',
          'Festividad local de Valdemanco del Esteras',
          'Festividad local de Campillos Paravientos',
          'Festividad local de Casas de Roldan',
          'Festividad local de El Cubillo',
          'Festividad local de Graja de Campalbo',
          'Festividad local de Torrecilla',
          'Festividad local de Villar del Saz de Navalon',
          'Festividad local de Alustante',
          'Festividad local de La Huerce',
          'Festividad local de Millana',
          'Festividad local de Montarrón',
          'Festividad local de Valdarachas',
          'Festividad local de Belvís de la Jara',
          'Festividad local de El Membrillo',
          'Festividad local de Madridejos',
          'Festividad local de Nuño Gómez',
          'Festividad local de Parrillas',
        ],
        pc_ocupacion: 13.0,
        room: {
          double: {
            newPrice: 60.17,
            priceBefore: 60.48,
            priceNow: 58.65,
          },
          single: {
            newPrice: 44.41,
            priceBefore: 43.5,
            priceNow: 46.89,
          },
          suite: {
            newPrice: 41.73,
            priceBefore: 38.06,
            priceNow: 36.66,
          },
        },
      },
      {
        date: '2025-01-21',
        festivos: [
          'Festividad local de Aldeanueva de Atienza',
          'Festividad local de La Mierla',
        ],
        pc_ocupacion: 8.0,
        room: {
          double: {
            newPrice: 19.8,
            priceBefore: 20.5,
            priceNow: 20.86,
          },
          single: {
            newPrice: 38.69,
            priceBefore: 37.5,
            priceNow: 36.47,
          },
          suite: {
            newPrice: 55.89,
            priceBefore: 56.5,
            priceNow: 62.01,
          },
        },
      },
      {
        date: '2025-01-22',
        festivos: [
          'Festividad local de La Solana',
          'Festividad local de Casas de Guijarro',
          'Festividad local de Las Valeras',
          'Festividad local de Olmedilla de Alarcon',
          'Festividad local de Bujarrabal',
          'Festividad local de Cincovillas',
          'Festividad local de Condemios de Arriba',
          'Festividad local de Pastrana',
          'Festividad local de Quer',
          'Festividad local de Sigüenza',
          'Festividad local de Cazalegas',
          'Festividad local de Gálvez',
          'Festividad local de Malpica de Tajo',
          'Festividad local de Montesclaros',
          'Festividad local de Paredes de Escalona',
          'Festividad local de Puerto de San Vicente',
          'Festividad local de Robledo del Mazo',
        ],
        pc_ocupacion: 11.0,
        room: {
          double: {
            newPrice: 41.44,
            priceBefore: 38.8,
            priceNow: 38.89,
          },
          single: {
            newPrice: 42.98,
            priceBefore: 42.0,
            priceNow: 45.99,
          },
          suite: {
            newPrice: 53.1,
            priceBefore: 54.0,
            priceNow: 53.46,
          },
        },
      },
      {
        date: '2025-01-23',
        festivos: [
          'Festividad local de Albatana',
          'Festividad local de Villavaliente',
          'Festividad local de Villaseca',
          'Festividad local de Valverde de los Arroyos',
          'Festividad local de Herreruela de Oropesa',
          'Festividad local de Toledo',
        ],
        pc_ocupacion: 11.0,
        room: {
          double: {
            newPrice: 38.22,
            priceBefore: 38.22,
            priceNow: 38.29,
          },
          single: {
            newPrice: 43.55,
            priceBefore: 40.5,
            priceNow: 40.65,
          },
          suite: {
            newPrice: 56.08,
            priceBefore: 53.94,
            priceNow: 58.11,
          },
        },
      },
      {
        date: '2025-01-24',
        festivos: [
          'Festividad local de Cabezarrubias del Puerto',
          'Festividad local de Corral de Calatrava',
          'Festividad local de Villarta San Juan',
          'Festividad local de Alovera',
          'Festividad local de Aragosa',
          'Festividad local de Baides',
          'Festividad local de Mandayona',
          'Festividad local de Mazuecos',
          'Festividad local de La Puebla de Montalbán',
          'Festividad local de La Pueblanueva',
          'Festividad local de La Rinconada',
          'Festividad local de Portillo de Toledo',
          'Festividad local de Santa Cruz del Retamar',
          'Festividad local de Villarejo de Montalbán',
        ],
        pc_ocupacion: 10.0,
        room: {
          double: {
            newPrice: 30.38,
            priceBefore: 31.8,
            priceNow: 34.63,
          },
          single: {
            newPrice: 37.97,
            priceBefore: 35.15,
            priceNow: 34.93,
          },
          suite: {
            newPrice: 66.41,
            priceBefore: 61.32,
            priceNow: 62.86,
          },
        },
      },
      {
        date: '2025-01-25',
        festivos: [
          'Festividad local de Fuencemillan',
          'Festividad local de Mazuecos',
          'Festividad local de Navalcán',
          'Festividad local de San Pablo de los Montes',
        ],
        pc_ocupacion: 9.0,
        room: {
          double: {
            newPrice: 40.68,
            priceBefore: 42.0,
            priceNow: 44.11,
          },
          single: {
            newPrice: 37.77,
            priceBefore: 39.5,
            priceNow: 41.51,
          },
          suite: {
            newPrice: 40.52,
            priceBefore: 38.78,
            priceNow: 41.71,
          },
        },
      },
      {
        date: '2025-01-26',
        festivos: ['Festividad local de El Herrumblar'],
        pc_ocupacion: 14.0,
        room: {
          double: {
            newPrice: 59.16,
            priceBefore: 58.5,
            priceNow: 59.67,
          },
          single: {
            newPrice: 31.82,
            priceBefore: 33.35,
            priceNow: 35.34,
          },
          suite: {
            newPrice: 61.46,
            priceBefore: 59.34,
            priceNow: 63.38,
          },
        },
      },
      {
        date: '2025-01-27',
        festivos: [],
        pc_ocupacion: 13.0,
        room: {
          double: {
            newPrice: 45.72,
            priceBefore: 44.32,
            priceNow: 44.24,
          },
          single: {
            newPrice: 40.75,
            priceBefore: 38.81,
            priceNow: 41.76,
          },
          suite: {
            newPrice: 65.19,
            priceBefore: 59.5,
            priceNow: 57.27,
          },
        },
      },
      {
        date: '2025-01-28',
        festivos: [],
        pc_ocupacion: 18.0,
        room: {
          double: {
            newPrice: 52.82,
            priceBefore: 54.0,
            priceNow: 51.54,
          },
          single: {
            newPrice: 34.0,
            priceBefore: 35.2,
            priceNow: 37.44,
          },
          suite: {
            newPrice: 92.88,
            priceBefore: 86.5,
            priceNow: 90.04,
          },
        },
      },
      {
        date: '2025-01-29',
        festivos: [
          'Festividad local de Casas de Haro',
          'Festividad local de Tarancon',
          'Festividad local de Aldeanueva de Barbarroya',
        ],
        pc_ocupacion: 11.0,
        room: {
          double: {
            newPrice: 57.54,
            priceBefore: 53.0,
            priceNow: 54.12,
          },
          single: {
            newPrice: 38.01,
            priceBefore: 38.88,
            priceNow: 38.99,
          },
          suite: {
            newPrice: 42.3,
            priceBefore: 41.0,
            priceNow: 42.29,
          },
        },
      },
      {
        date: '2025-01-30',
        festivos: [],
        pc_ocupacion: 12.0,
        room: {
          double: {
            newPrice: 22.8,
            priceBefore: 21.0,
            priceNow: 21.81,
          },
          single: {
            newPrice: 59.13,
            priceBefore: 55.25,
            priceNow: 52.71,
          },
          suite: {
            newPrice: 61.69,
            priceBefore: 62.26,
            priceNow: 67.9,
          },
        },
      },
      {
        date: '2025-01-31',
        festivos: ['Festividad local de Albendiego'],
        pc_ocupacion: 11.0,
        room: {
          double: {
            newPrice: 42.65,
            priceBefore: 39.3,
            priceNow: 41.56,
          },
          single: {
            newPrice: 35.0,
            priceBefore: 35.76,
            priceNow: 34.33,
          },
          suite: {
            newPrice: 61.29,
            priceBefore: 57.62,
            priceNow: 59.8,
          },
        },
      },
      {
        date: '2025-02-01',
        festivos: [
          'Festividad local de Alcaraz',
          'Festividad local de Pozo de Guadalajara',
          'Festividad local de Villanueva de Argecilla',
          'Festividad local de Cardiel de los Montes',
        ],
        pc_ocupacion: 18.0,
        room: {
          double: {
            newPrice: 50.64,
            priceBefore: 49.5,
            priceNow: 53.22,
          },
          single: {
            newPrice: 47.26,
            priceBefore: 43.0,
            priceNow: 45.57,
          },
          suite: {
            newPrice: 93.13,
            priceBefore: 85.63,
            priceNow: 88.06,
          },
        },
      },
      {
        date: '2025-02-02',
        festivos: [
          'Festividad local de Balazote',
          'Festividad local de Villaverde del Guadalimar',
          'Festividad local de Luciana',
          'Festividad local de Albalate de las Nogueras',
          'Festividad local de Casas de los Pinos',
          'Festividad local de Castejon',
          'Festividad local de Cervera del Llano',
          'Festividad local de Fresneda de la Sierra',
          'Festividad local de Jabalera',
          'Festividad local de La Pesquera',
          'Festividad local de Los Estesos',
          'Festividad local de Pajares',
          'Festividad local de Solera de Gabaldón',
          'Festividad local de Sotoca',
          'Festividad local de Torrejoncillo del Rey',
          'Festividad local de Torrubia del Campo',
          'Festividad local de Villaverde y Pasaconsol',
          'Festividad local de Albalate de Zorita',
          'Festividad local de Arbancón',
          'Festividad local de Malaguilla',
          'Festividad local de Torrejón del Rey',
          'Festividad local de Valdeconcha',
          'Festividad local de Villanueva de Argecilla',
          'Festividad local de Villaseca de Henares',
          'Festividad local de Arcicollar',
          'Festividad local de Buenasbodas',
          'Festividad local de Campillo de La Jara',
          'Festividad local de Cazalegas',
          'Festividad local de Cebolla',
          'Festividad local de Gamonal',
          'Festividad local de Las Navillas',
        ],
        pc_ocupacion: 11.0,
        room: {
          double: {
            newPrice: 46.11,
            priceBefore: 45.8,
            priceNow: 45.44,
          },
          single: {
            newPrice: 34.8,
            priceBefore: 32.0,
            priceNow: 35.18,
          },
          suite: {
            newPrice: 53.4,
            priceBefore: 52.94,
            priceNow: 54.61,
          },
        },
      },
      {
        date: '2025-02-03',
        festivos: [
          'Festividad local de Elche De La Sierra',
          'Festividad local de Tarazona de la Mancha',
          'Festividad local de Hinojosas de Calatrava',
          'Festividad local de Villar del Pozo',
          'Festividad local de Cañaveruelas',
          'Festividad local de Enguidanos',
          'Festividad local de La Peraleja',
          'Festividad local de Pajaron',
          'Festividad local de Tribaldos',
          'Festividad local de Villanueva de Guadamejud',
          'Festividad local de Villar del Saz de Arcas',
          'Festividad local de Villarejo de la Peñuela',
          'Festividad local de Villarejo Seco',
          'Festividad local de Angón',
          'Festividad local de Atanzón',
          'Festividad local de Barriopedro',
          'Festividad local de Castilforte',
          'Festividad local de Copernal',
          'Festividad local de Huérmeces del Cerro',
          'Festividad local de Irueste',
          'Festividad local de La Toba',
          'Festividad local de Saúca',
          'Festividad local de Tobillos',
          'Festividad local de Villaseca de Henares',
          'Festividad local de Cervera de los Montes',
          'Festividad local de Recas',
        ],
        pc_ocupacion: 10.0,
        room: {
          double: {
            newPrice: 39.73,
            priceBefore: 41.57,
            priceNow: 44.05,
          },
          single: {
            newPrice: 34.95,
            priceBefore: 32.79,
            priceNow: 34.96,
          },
          suite: {
            newPrice: 52.46,
            priceBefore: 51.0,
            priceNow: 55.92,
          },
        },
      },
      {
        date: '2025-02-04',
        festivos: [],
        pc_ocupacion: 11.0,
        room: {
          double: {
            newPrice: 45.33,
            priceBefore: 41.22,
            priceNow: 43.21,
          },
          single: {
            newPrice: 34.82,
            priceBefore: 35.5,
            priceNow: 37.24,
          },
          suite: {
            newPrice: 53.9,
            priceBefore: 56.0,
            priceNow: 53.27,
          },
        },
      },
      {
        date: '2025-02-05',
        festivos: [
          'Festividad local de Caracuel de Calatrava',
          'Festividad local de Pinarejo',
          'Festividad local de Pozoamargo',
          'Festividad local de Santa Cruz de Moya',
          'Festividad local de Villalgordo del Marquesado',
          'Festividad local de Zarza de Tajo',
          'Festividad local de Castilforte',
          'Festividad local de Centenera',
          'Festividad local de El Casar',
          'Festividad local de Galápagos',
          'Festividad local de Hontoba',
          'Festividad local de Pioz',
          'Festividad local de Retiendas',
          'Festividad local de Villanueva de la Torre',
          'Festividad local de Buenasbodas',
          'Festividad local de Montesclaros',
          'Festividad local de Pepino',
        ],
        pc_ocupacion: 15.0,
        room: {
          double: {
            newPrice: 66.29,
            priceBefore: 64.5,
            priceNow: 61.38,
          },
          single: {
            newPrice: 45.39,
            priceBefore: 45.5,
            priceNow: 49.34,
          },
          suite: {
            newPrice: 52.4,
            priceBefore: 50.0,
            priceNow: 52.08,
          },
        },
      },
      {
        date: '2025-02-06',
        festivos: [],
        pc_ocupacion: 9.0,
        room: {
          double: {
            newPrice: 45.63,
            priceBefore: 42.22,
            priceNow: 44.61,
          },
          single: {
            newPrice: 29.36,
            priceBefore: 27.62,
            priceNow: 29.17,
          },
          suite: {
            newPrice: 49.0,
            priceBefore: 50.5,
            priceNow: 53.72,
          },
        },
      },
      {
        date: '2025-02-07',
        festivos: [],
        pc_ocupacion: 15.0,
        room: {
          double: {
            newPrice: 61.75,
            priceBefore: 63.5,
            priceNow: 61.05,
          },
          single: {
            newPrice: 32.58,
            priceBefore: 32.32,
            priceNow: 32.29,
          },
          suite: {
            newPrice: 66.22,
            priceBefore: 61.68,
            priceNow: 59.25,
          },
        },
      },
      {
        date: '2025-02-08',
        festivos: [
          'Festividad local de Atalaya del Cañavate',
          'Festividad local de Casas de Benitez',
          'Festividad local de Paracuellos de la Vega',
          'Festividad local de Pozorrubio de Santiago',
          'Festividad local de Sisante',
          'Festividad local de Vara de Rey',
          'Festividad local de Alcaudete de la Jara',
          'Festividad local de San Martín de Montalbán',
        ],
        pc_ocupacion: 13.0,
        room: {
          double: {
            newPrice: 23.46,
            priceBefore: 24.34,
            priceNow: 26.14,
          },
          single: {
            newPrice: 36.45,
            priceBefore: 37.5,
            priceNow: 40.03,
          },
          suite: {
            newPrice: 91.66,
            priceBefore: 86.5,
            priceNow: 84.71,
          },
        },
      },
      {
        date: '2025-02-09',
        festivos: [
          'Festividad local de Albaladejo del Cuende',
          'Festividad local de Tortuero',
        ],
        pc_ocupacion: 14.0,
        room: {
          double: {
            newPrice: 31.29,
            priceBefore: 31.61,
            priceNow: 31.84,
          },
          single: {
            newPrice: 34.05,
            priceBefore: 34.75,
            priceNow: 35.15,
          },
          suite: {
            newPrice: 88.91,
            priceBefore: 86.5,
            priceNow: 89.66,
          },
        },
      },
      {
        date: '2025-02-10',
        festivos: [],
        pc_ocupacion: 9.0,
        room: {
          double: {
            newPrice: 44.25,
            priceBefore: 46.34,
            priceNow: 49.81,
          },
          single: {
            newPrice: 38.76,
            priceBefore: 36.4,
            priceNow: 37.28,
          },
          suite: {
            newPrice: 42.43,
            priceBefore: 42.18,
            priceNow: 43.94,
          },
        },
      },
      {
        date: '2025-02-11',
        festivos: [],
        pc_ocupacion: 18.0,
        room: {
          double: {
            newPrice: 35.17,
            priceBefore: 32.11,
            priceNow: 33.02,
          },
          single: {
            newPrice: 63.44,
            priceBefore: 58.0,
            priceNow: 59.05,
          },
          suite: {
            newPrice: 84.86,
            priceBefore: 85.5,
            priceNow: 84.39,
          },
        },
      },
      {
        date: '2025-02-12',
        festivos: [
          'Festividad local de Moral de Calatrava',
          'Festividad local de Pedro Muñoz',
          'Festividad local de Santa Cruz de Mudela',
          'Festividad local de Las Mesas',
          'Festividad local de Los Yébenes',
        ],
        pc_ocupacion: 12.0,
        room: {
          double: {
            newPrice: 42.38,
            priceBefore: 40.4,
            priceNow: 41.06,
          },
          single: {
            newPrice: 30.35,
            priceBefore: 31.14,
            priceNow: 32.35,
          },
          suite: {
            newPrice: 68.31,
            priceBefore: 69.94,
            priceNow: 74.67,
          },
        },
      },
      {
        date: '2025-02-13',
        festivos: [
          'Festividad local de Herencia',
          'Festividad local de Torrenueva',
          'Festividad local de Almorox',
        ],
        pc_ocupacion: 17.0,
        room: {
          double: {
            newPrice: 53.24,
            priceBefore: 53.0,
            priceNow: 54.0,
          },
          single: {
            newPrice: 50.52,
            priceBefore: 46.5,
            priceNow: 47.94,
          },
          suite: {
            newPrice: 71.08,
            priceBefore: 69.14,
            priceNow: 70.91,
          },
        },
      },
      {
        date: '2025-02-14',
        festivos: [
          'Festividad local de La Roda',
          'Festividad local de Miguelturra',
          'Festividad local de Mejorada',
        ],
        pc_ocupacion: 26.0,
        room: {
          double: {
            newPrice: 74.52,
            priceBefore: 69.5,
            priceNow: 71.12,
          },
          single: {
            newPrice: 46.89,
            priceBefore: 43.5,
            priceNow: 42.43,
          },
          suite: {
            newPrice: 111.78,
            priceBefore: 115.0,
            priceNow: 110.16,
          },
        },
      },
      {
        date: '2025-02-15',
        festivos: [],
        pc_ocupacion: 7.0,
        room: {
          double: {
            newPrice: 23.55,
            priceBefore: 22.5,
            priceNow: 22.47,
          },
          single: {
            newPrice: 42.5,
            priceBefore: 42.5,
            priceNow: 46.54,
          },
          suite: {
            newPrice: 45.13,
            priceBefore: 41.26,
            priceNow: 43.27,
          },
        },
      },
      {
        date: '2025-02-16',
        festivos: ['Festividad local de Villarrobledo'],
        pc_ocupacion: 15.0,
        room: {
          double: {
            newPrice: 54.72,
            priceBefore: 53.0,
            priceNow: 51.24,
          },
          single: {
            newPrice: 34.05,
            priceBefore: 33.11,
            priceNow: 32.66,
          },
          suite: {
            newPrice: 79.38,
            priceBefore: 72.96,
            priceNow: 79.66,
          },
        },
      },
      {
        date: '2025-02-17',
        festivos: [],
        pc_ocupacion: 11.0,
        room: {
          double: {
            newPrice: 54.22,
            priceBefore: 53.0,
            priceNow: 50.59,
          },
          single: {
            newPrice: 41.1,
            priceBefore: 37.5,
            priceNow: 38.55,
          },
          suite: {
            newPrice: 39.44,
            priceBefore: 41.32,
            priceNow: 40.09,
          },
        },
      },
      {
        date: '2025-02-18',
        festivos: [],
        pc_ocupacion: 17.0,
        room: {
          double: {
            newPrice: 69.13,
            priceBefore: 63.5,
            priceNow: 61.73,
          },
          single: {
            newPrice: 38.7,
            priceBefore: 36.0,
            priceNow: 37.79,
          },
          suite: {
            newPrice: 76.29,
            priceBefore: 69.96,
            priceNow: 74.15,
          },
        },
      },
      {
        date: '2025-02-19',
        festivos: [],
        pc_ocupacion: 19.0,
        room: {
          double: {
            newPrice: 54.42,
            priceBefore: 51.7,
            priceNow: 51.03,
          },
          single: {
            newPrice: 39.55,
            priceBefore: 38.4,
            priceNow: 41.74,
          },
          suite: {
            newPrice: 92.2,
            priceBefore: 91.0,
            priceNow: 92.81,
          },
        },
      },
      {
        date: '2025-02-20',
        festivos: ['Festividad local de Valderrebollo'],
        pc_ocupacion: 15.0,
        room: {
          double: {
            newPrice: 47.1,
            priceBefore: 44.65,
            priceNow: 44.39,
          },
          single: {
            newPrice: 40.8,
            priceBefore: 40.5,
            priceNow: 41.69,
          },
          suite: {
            newPrice: 75.21,
            priceBefore: 70.51,
            priceNow: 75.77,
          },
        },
      },
      {
        date: '2025-02-21',
        festivos: ['Festividad local de Chillaron de Cuenca'],
        pc_ocupacion: 15.0,
        room: {
          double: {
            newPrice: 57.62,
            priceBefore: 52.5,
            priceNow: 56.86,
          },
          single: {
            newPrice: 31.08,
            priceBefore: 32.53,
            priceNow: 35.32,
          },
          suite: {
            newPrice: 79.09,
            priceBefore: 75.15,
            priceNow: 81.46,
          },
        },
      },
      {
        date: '2025-02-22',
        festivos: ['Festividad local de Carriches'],
        pc_ocupacion: 19.0,
        room: {
          double: {
            newPrice: 61.79,
            priceBefore: 65.0,
            priceNow: 70.53,
          },
          single: {
            newPrice: 36.5,
            priceBefore: 36.9,
            priceNow: 35.78,
          },
          suite: {
            newPrice: 85.14,
            priceBefore: 85.5,
            priceNow: 82.92,
          },
        },
      },
      {
        date: '2025-02-23',
        festivos: [],
        pc_ocupacion: 27.0,
        room: {
          double: {
            newPrice: 73.36,
            priceBefore: 73.0,
            priceNow: 73.49,
          },
          single: {
            newPrice: 84.07,
            priceBefore: 79.25,
            priceNow: 83.46,
          },
          suite: {
            newPrice: 82.66,
            priceBefore: 85.5,
            priceNow: 90.41,
          },
        },
      },
      {
        date: '2025-02-24',
        festivos: ['Festividad local de Herreruela de Oropesa'],
        pc_ocupacion: 18.0,
        room: {
          double: {
            newPrice: 59.52,
            priceBefore: 54.75,
            priceNow: 59.01,
          },
          single: {
            newPrice: 33.25,
            priceBefore: 33.61,
            priceNow: 34.76,
          },
          suite: {
            newPrice: 93.19,
            priceBefore: 88.0,
            priceNow: 85.94,
          },
        },
      },
      {
        date: '2025-02-25',
        festivos: [],
        pc_ocupacion: 15.0,
        room: {
          double: {
            newPrice: 70.2,
            priceBefore: 65.0,
            priceNow: 67.43,
          },
          single: {
            newPrice: 44.82,
            priceBefore: 41.75,
            priceNow: 45.02,
          },
          suite: {
            newPrice: 50.18,
            priceBefore: 50.0,
            priceNow: 52.61,
          },
        },
      },
      {
        date: '2025-02-26',
        festivos: [],
        pc_ocupacion: 15.0,
        room: {
          double: {
            newPrice: 32.71,
            priceBefore: 32.02,
            priceNow: 31.56,
          },
          single: {
            newPrice: 48.69,
            priceBefore: 46.5,
            priceNow: 49.3,
          },
          suite: {
            newPrice: 72.89,
            priceBefore: 76.05,
            priceNow: 82.77,
          },
        },
      },
      {
        date: '2025-02-27',
        festivos: [],
        pc_ocupacion: 23.0,
        room: {
          double: {
            newPrice: 81.73,
            priceBefore: 75.4,
            priceNow: 76.06,
          },
          single: {
            newPrice: 46.31,
            priceBefore: 46.5,
            priceNow: 45.22,
          },
          suite: {
            newPrice: 85.93,
            priceBefore: 86.5,
            priceNow: 87.71,
          },
        },
      },
      {
        date: '2025-02-28',
        festivos: [],
        pc_ocupacion: 15.0,
        room: {
          double: {
            newPrice: 64.13,
            priceBefore: 65.46,
            priceNow: 62.33,
          },
          single: {
            newPrice: 30.26,
            priceBefore: 30.5,
            priceNow: 30.36,
          },
          suite: {
            newPrice: 66.56,
            priceBefore: 64.75,
            priceNow: 64.7,
          },
        },
      },
      {
        date: '2025-03-01',
        festivos: [],
        pc_ocupacion: 14.0,
        room: {
          double: {
            newPrice: 45.49,
            priceBefore: 44.25,
            priceNow: 46.72,
          },
          single: {
            newPrice: 31.3,
            priceBefore: 29.66,
            priceNow: 28.64,
          },
          suite: {
            newPrice: 73.33,
            priceBefore: 76.95,
            priceNow: 78.45,
          },
        },
      },
      {
        date: '2025-03-02',
        festivos: [],
        pc_ocupacion: 18.0,
        room: {
          double: {
            newPrice: 61.53,
            priceBefore: 58.65,
            priceNow: 59.59,
          },
          single: {
            newPrice: 29.94,
            priceBefore: 28.0,
            priceNow: 28.04,
          },
          suite: {
            newPrice: 100.04,
            priceBefore: 91.5,
            priceNow: 93.37,
          },
        },
      },
      {
        date: '2025-03-03',
        festivos: [],
        pc_ocupacion: 26.0,
        room: {
          double: {
            newPrice: 52.94,
            priceBefore: 55.57,
            priceNow: 57.07,
          },
          single: {
            newPrice: 50.25,
            priceBefore: 51.0,
            priceNow: 49.85,
          },
          suite: {
            newPrice: 117.83,
            priceBefore: 121.16,
            priceNow: 123.3,
          },
        },
      },
      {
        date: '2025-03-04',
        festivos: ['Festividad local de El Bonillo'],
        pc_ocupacion: 15.0,
        room: {
          double: {
            newPrice: 56.44,
            priceBefore: 58.0,
            priceNow: 63.76,
          },
          single: {
            newPrice: 39.0,
            priceBefore: 35.55,
            priceNow: 35.36,
          },
          suite: {
            newPrice: 66.58,
            priceBefore: 69.0,
            priceNow: 66.85,
          },
        },
      },
      {
        date: '2025-03-05',
        festivos: [],
        pc_ocupacion: 17.0,
        room: {
          double: {
            newPrice: 59.15,
            priceBefore: 59.0,
            priceNow: 56.65,
          },
          single: {
            newPrice: 45.37,
            priceBefore: 41.95,
            priceNow: 45.46,
          },
          suite: {
            newPrice: 77.44,
            priceBefore: 73.35,
            priceNow: 80.67,
          },
        },
      },
      {
        date: '2025-03-06',
        festivos: [],
        pc_ocupacion: 15.0,
        room: {
          double: {
            newPrice: 58.7,
            priceBefore: 55.0,
            priceNow: 56.65,
          },
          single: {
            newPrice: 30.38,
            priceBefore: 30.0,
            priceNow: 29.82,
          },
          suite: {
            newPrice: 72.46,
            priceBefore: 72.03,
            priceNow: 68.45,
          },
        },
      },
      {
        date: '2025-03-07',
        festivos: ['Festividad local de Villaseca de Uceda'],
        pc_ocupacion: 18.0,
        room: {
          double: {
            newPrice: 58.8,
            priceBefore: 53.5,
            priceNow: 53.32,
          },
          single: {
            newPrice: 50.86,
            priceBefore: 51.5,
            priceNow: 52.73,
          },
          suite: {
            newPrice: 75.68,
            priceBefore: 74.74,
            priceNow: 74.63,
          },
        },
      },
      {
        date: '2025-03-08',
        festivos: ['Festividad local de Navas de Jorquera'],
        pc_ocupacion: 15.0,
        room: {
          double: {
            newPrice: 62.09,
            priceBefore: 57.15,
            priceNow: 54.48,
          },
          single: {
            newPrice: 34.47,
            priceBefore: 31.88,
            priceNow: 30.66,
          },
          suite: {
            newPrice: 68.65,
            priceBefore: 72.06,
            priceNow: 70.87,
          },
        },
      },
      {
        date: '2025-03-09',
        festivos: [],
        pc_ocupacion: 15.0,
        room: {
          double: {
            newPrice: 65.37,
            priceBefore: 64.75,
            priceNow: 68.66,
          },
          single: {
            newPrice: 53.33,
            priceBefore: 50.0,
            priceNow: 51.7,
          },
          suite: {
            newPrice: 44.04,
            priceBefore: 41.68,
            priceNow: 39.98,
          },
        },
      },
      {
        date: '2025-03-10',
        festivos: [],
        pc_ocupacion: 7.0,
        room: {
          double: {
            newPrice: 34.29,
            priceBefore: 32.74,
            priceNow: 35.36,
          },
          single: {
            newPrice: 35.07,
            priceBefore: 36.0,
            priceNow: 35.18,
          },
          suite: {
            newPrice: 42.7,
            priceBefore: 41.22,
            priceNow: 41.53,
          },
        },
      },
      {
        date: '2025-03-11',
        festivos: ['Festividad local de Illescas'],
        pc_ocupacion: 17.0,
        room: {
          double: {
            newPrice: 61.53,
            priceBefore: 62.0,
            priceNow: 59.16,
          },
          single: {
            newPrice: 37.73,
            priceBefore: 35.55,
            priceNow: 35.56,
          },
          suite: {
            newPrice: 77.36,
            priceBefore: 73.99,
            priceNow: 78.61,
          },
        },
      },
      {
        date: '2025-03-12',
        festivos: [],
        pc_ocupacion: 17.0,
        room: {
          double: {
            newPrice: 61.22,
            priceBefore: 57.15,
            priceNow: 56.23,
          },
          single: {
            newPrice: 42.1,
            priceBefore: 39.13,
            priceNow: 41.09,
          },
          suite: {
            newPrice: 73.6,
            priceBefore: 75.15,
            priceNow: 77.94,
          },
        },
      },
      {
        date: '2025-03-13',
        festivos: [],
        pc_ocupacion: 15.0,
        room: {
          double: {
            newPrice: 50.78,
            priceBefore: 48.08,
            priceNow: 46.96,
          },
          single: {
            newPrice: 34.06,
            priceBefore: 35.16,
            priceNow: 35.79,
          },
          suite: {
            newPrice: 77.72,
            priceBefore: 76.96,
            priceNow: 77.46,
          },
        },
      },
      {
        date: '2025-03-14',
        festivos: [],
        pc_ocupacion: 19.0,
        room: {
          double: {
            newPrice: 60.03,
            priceBefore: 61.65,
            priceNow: 60.09,
          },
          single: {
            newPrice: 43.06,
            priceBefore: 39.5,
            priceNow: 43.25,
          },
          suite: {
            newPrice: 78.66,
            priceBefore: 80.3,
            priceNow: 79.86,
          },
        },
      },
      {
        date: '2025-03-15',
        festivos: [],
        pc_ocupacion: 19.0,
        room: {
          double: {
            newPrice: 37.92,
            priceBefore: 36.9,
            priceNow: 38.76,
          },
          single: {
            newPrice: 37.86,
            priceBefore: 35.55,
            priceNow: 36.51,
          },
          suite: {
            newPrice: 107.58,
            priceBefore: 108.88,
            priceNow: 107.11,
          },
        },
      },
      {
        date: '2025-03-16',
        festivos: [],
        pc_ocupacion: 13.0,
        room: {
          double: {
            newPrice: 50.53,
            priceBefore: 53.0,
            priceNow: 54.16,
          },
          single: {
            newPrice: 45.84,
            priceBefore: 46.41,
            priceNow: 49.86,
          },
          suite: {
            newPrice: 42.21,
            priceBefore: 44.06,
            priceNow: 45.21,
          },
        },
      },
      {
        date: '2025-03-17',
        festivos: [],
        pc_ocupacion: 17.0,
        room: {
          double: {
            newPrice: 47.66,
            priceBefore: 44.42,
            priceNow: 46.38,
          },
          single: {
            newPrice: 30.99,
            priceBefore: 29.0,
            priceNow: 31.14,
          },
          suite: {
            newPrice: 106.37,
            priceBefore: 97.7,
            priceNow: 99.72,
          },
        },
      },
      {
        date: '2025-03-18',
        festivos: [
          'Festividad local de Casas de Lazaro',
          'Festividad local de Ontur',
          'Festividad local de Abenójar',
          'Festividad local de Zorita de los Canes',
        ],
        pc_ocupacion: 27.0,
        room: {
          double: {
            newPrice: 57.37,
            priceBefore: 56.25,
            priceNow: 58.0,
          },
          single: {
            newPrice: 75.86,
            priceBefore: 73.5,
            priceNow: 71.4,
          },
          suite: {
            newPrice: 105.68,
            priceBefore: 107.3,
            priceNow: 113.32,
          },
        },
      },
      {
        date: '2025-03-19',
        festivos: [
          'Festividad local de Molinicos',
          'Festividad local de Ontur',
          'Festividad local de Las Mesas',
          'Festividad local de Puebla de Valles',
          'Festividad local de Zorita de los Canes',
          'Festividad local de Gerindote',
          'Festividad local de Sartajada',
          'Festividad local de Villacañas',
        ],
        pc_ocupacion: 19.0,
        room: {
          double: {
            newPrice: 64.6,
            priceBefore: 65.7,
            priceNow: 70.11,
          },
          single: {
            newPrice: 39.04,
            priceBefore: 37.34,
            priceNow: 37.51,
          },
          suite: {
            newPrice: 81.33,
            priceBefore: 77.68,
            priceNow: 77.04,
          },
        },
      },
      {
        date: '2025-03-20',
        festivos: ['Festividad local de Monreal del Llano'],
        pc_ocupacion: 16.0,
        room: {
          double: {
            newPrice: 39.11,
            priceBefore: 36.9,
            priceNow: 35.42,
          },
          single: {
            newPrice: 40.49,
            priceBefore: 42.5,
            priceNow: 42.2,
          },
          suite: {
            newPrice: 82.82,
            priceBefore: 84.32,
            priceNow: 86.83,
          },
        },
      },
      {
        date: '2025-03-21',
        festivos: [],
        pc_ocupacion: 23.0,
        room: {
          double: {
            newPrice: 54.5,
            priceBefore: 51.35,
            priceNow: 53.87,
          },
          single: {
            newPrice: 65.19,
            priceBefore: 64.5,
            priceNow: 70.37,
          },
          suite: {
            newPrice: 93.61,
            priceBefore: 90.5,
            priceNow: 97.73,
          },
        },
      },
      {
        date: '2025-03-22',
        festivos: ['Festividad local de Atienza'],
        pc_ocupacion: 31.0,
        room: {
          double: {
            newPrice: 82.48,
            priceBefore: 79.11,
            priceNow: 78.89,
          },
          single: {
            newPrice: 64.72,
            priceBefore: 64.5,
            priceNow: 65.37,
          },
          suite: {
            newPrice: 112.22,
            priceBefore: 115.0,
            priceNow: 111.92,
          },
        },
      },
      {
        date: '2025-03-23',
        festivos: [],
        pc_ocupacion: 26.0,
        room: {
          double: {
            newPrice: 61.2,
            priceBefore: 62.55,
            priceNow: 64.36,
          },
          single: {
            newPrice: 65.91,
            priceBefore: 63.45,
            priceNow: 61.81,
          },
          suite: {
            newPrice: 110.17,
            priceBefore: 100.5,
            priceNow: 109.51,
          },
        },
      },
      {
        date: '2025-03-24',
        festivos: [],
        pc_ocupacion: 19.0,
        room: {
          double: {
            newPrice: 51.95,
            priceBefore: 49.72,
            priceNow: 49.68,
          },
          single: {
            newPrice: 37.23,
            priceBefore: 37.5,
            priceNow: 39.51,
          },
          suite: {
            newPrice: 96.09,
            priceBefore: 93.2,
            priceNow: 101.91,
          },
        },
      },
      {
        date: '2025-03-25',
        festivos: [],
        pc_ocupacion: 27.0,
        room: {
          double: {
            newPrice: 52.29,
            priceBefore: 52.65,
            priceNow: 56.08,
          },
          single: {
            newPrice: 64.56,
            priceBefore: 65.0,
            priceNow: 67.92,
          },
          suite: {
            newPrice: 127.55,
            priceBefore: 120.0,
            priceNow: 131.54,
          },
        },
      },
      {
        date: '2025-03-26',
        festivos: [],
        pc_ocupacion: 30.0,
        room: {
          double: {
            newPrice: 98.13,
            priceBefore: 91.0,
            priceNow: 97.65,
          },
          single: {
            newPrice: 53.44,
            priceBefore: 50.0,
            priceNow: 49.58,
          },
          suite: {
            newPrice: 119.48,
            priceBefore: 112.16,
            priceNow: 120.49,
          },
        },
      },
      {
        date: '2025-03-27',
        festivos: [],
        pc_ocupacion: 31.0,
        room: {
          double: {
            newPrice: 81.89,
            priceBefore: 80.5,
            priceNow: 85.49,
          },
          single: {
            newPrice: 49.28,
            priceBefore: 51.75,
            priceNow: 56.06,
          },
          suite: {
            newPrice: 131.53,
            priceBefore: 127.0,
            priceNow: 134.08,
          },
        },
      },
      {
        date: '2025-03-28',
        festivos: [],
        pc_ocupacion: 16.0,
        room: {
          double: {
            newPrice: 52.2,
            priceBefore: 51.64,
            priceNow: 52.28,
          },
          single: {
            newPrice: 40.17,
            priceBefore: 38.25,
            priceNow: 37.78,
          },
          suite: {
            newPrice: 73.97,
            priceBefore: 74.18,
            priceNow: 72.36,
          },
        },
      },
      {
        date: '2025-03-29',
        festivos: [],
        pc_ocupacion: 23.0,
        room: {
          double: {
            newPrice: 67.76,
            priceBefore: 61.65,
            priceNow: 64.11,
          },
          single: {
            newPrice: 37.9,
            priceBefore: 37.5,
            priceNow: 37.72,
          },
          suite: {
            newPrice: 109.99,
            priceBefore: 108.0,
            priceNow: 112.1,
          },
        },
      },
      {
        date: '2025-03-30',
        festivos: [],
        pc_ocupacion: 27.0,
        room: {
          double: {
            newPrice: 69.95,
            priceBefore: 65.45,
            priceNow: 67.55,
          },
          single: {
            newPrice: 41.36,
            priceBefore: 41.22,
            priceNow: 41.8,
          },
          suite: {
            newPrice: 132.8,
            priceBefore: 126.84,
            priceNow: 133.42,
          },
        },
      },
      {
        date: '2025-03-31',
        festivos: [],
        pc_ocupacion: 21.0,
        room: {
          double: {
            newPrice: 80.98,
            priceBefore: 75.15,
            priceNow: 71.69,
          },
          single: {
            newPrice: 40.78,
            priceBefore: 39.5,
            priceNow: 40.88,
          },
          suite: {
            newPrice: 90.99,
            priceBefore: 84.18,
            priceNow: 88.44,
          },
        },
      },
      {
        date: '2025-04-01',
        festivos: [
          'Festividad local de Bonete',
          'Festividad local de Almadenejos',
          'Festividad local de Campo de Criptana',
          'Festividad local de Carrión de Calatrava',
          'Festividad local de Carrizosa',
          'Festividad local de Chillón',
          'Festividad local de Socuéllamos',
          'Festividad local de Villarrubia de los Ojos',
          'Festividad local de Los Hinojosos',
          'Festividad local de Mira',
          'Festividad local de Villora',
          'Festividad local de Argés',
          'Festividad local de Cobisa',
          'Festividad local de Las Ventas con Peña Aguilera',
          'Festividad local de Navamorcuende',
          'Festividad local de Puente del Arzobispo',
          'Festividad local de Tembleque',
          'Festividad local de Toledo',
        ],
        pc_ocupacion: 20.0,
        room: {
          double: {
            newPrice: 68.28,
            priceBefore: 70.5,
            priceNow: 71.05,
          },
          single: {
            newPrice: 40.23,
            priceBefore: 40.81,
            priceNow: 39.21,
          },
          suite: {
            newPrice: 78.18,
            priceBefore: 79.65,
            priceNow: 84.02,
          },
        },
      },
      {
        date: '2025-04-02',
        festivos: ['Festividad local de Ajofrín'],
        pc_ocupacion: 22.0,
        room: {
          double: {
            newPrice: 49.3,
            priceBefore: 48.15,
            priceNow: 48.77,
          },
          single: {
            newPrice: 46.49,
            priceBefore: 44.55,
            priceNow: 45.39,
          },
          suite: {
            newPrice: 105.92,
            priceBefore: 110.5,
            priceNow: 117.11,
          },
        },
      },
      {
        date: '2025-04-03',
        festivos: ['Festividad local de La Nava de Ricomalillo'],
        pc_ocupacion: 13.0,
        room: {
          double: {
            newPrice: 24.14,
            priceBefore: 22.3,
            priceNow: 23.11,
          },
          single: {
            newPrice: 44.98,
            priceBefore: 47.0,
            priceNow: 49.64,
          },
          suite: {
            newPrice: 81.89,
            priceBefore: 79.65,
            priceNow: 75.78,
          },
        },
      },
      {
        date: '2025-04-04',
        festivos: ['Festividad local de La Nava de Ricomalillo'],
        pc_ocupacion: 20.0,
        room: {
          double: {
            newPrice: 54.53,
            priceBefore: 56.1,
            priceNow: 61.26,
          },
          single: {
            newPrice: 55.02,
            priceBefore: 52.65,
            priceNow: 52.49,
          },
          suite: {
            newPrice: 89.08,
            priceBefore: 81.15,
            priceNow: 85.06,
          },
        },
      },
      {
        date: '2025-04-05',
        festivos: [],
        pc_ocupacion: 21.0,
        room: {
          double: {
            newPrice: 59.85,
            priceBefore: 59.88,
            priceNow: 63.57,
          },
          single: {
            newPrice: 45.4,
            priceBefore: 44.42,
            priceNow: 48.5,
          },
          suite: {
            newPrice: 87.86,
            priceBefore: 88.16,
            priceNow: 85.65,
          },
        },
      },
      {
        date: '2025-04-06',
        festivos: [],
        pc_ocupacion: 23.0,
        room: {
          double: {
            newPrice: 70.16,
            priceBefore: 66.6,
            priceNow: 71.72,
          },
          single: {
            newPrice: 48.69,
            priceBefore: 48.62,
            priceNow: 52.45,
          },
          suite: {
            newPrice: 92.72,
            priceBefore: 94.95,
            priceNow: 95.14,
          },
        },
      },
      {
        date: '2025-04-07',
        festivos: [],
        pc_ocupacion: 21.0,
        room: {
          double: {
            newPrice: 73.12,
            priceBefore: 74.25,
            priceNow: 72.5,
          },
          single: {
            newPrice: 34.65,
            priceBefore: 36.36,
            priceNow: 37.2,
          },
          suite: {
            newPrice: 94.1,
            priceBefore: 85.65,
            priceNow: 90.81,
          },
        },
      },
      {
        date: '2025-04-08',
        festivos: [
          'Festividad local de Caudete',
          'Festividad local de El Hito',
          'Festividad local de Carpio de Tajo',
        ],
        pc_ocupacion: 22.0,
        room: {
          double: {
            newPrice: 48.85,
            priceBefore: 45.48,
            priceNow: 48.68,
          },
          single: {
            newPrice: 58.43,
            priceBefore: 58.16,
            priceNow: 55.78,
          },
          suite: {
            newPrice: 110.41,
            priceBefore: 100.38,
            priceNow: 101.25,
          },
        },
      },
      {
        date: '2025-04-09',
        festivos: [],
        pc_ocupacion: 24.0,
        room: {
          double: {
            newPrice: 68.35,
            priceBefore: 69.5,
            priceNow: 69.46,
          },
          single: {
            newPrice: 41.92,
            priceBefore: 39.75,
            priceNow: 42.86,
          },
          suite: {
            newPrice: 104.2,
            priceBefore: 105.5,
            priceNow: 111.46,
          },
        },
      },
      {
        date: '2025-04-10',
        festivos: [
          'Festividad local de San Lorenzo de Calatrava',
          'Festividad local de Hinojosa de San Vicente',
        ],
        pc_ocupacion: 17.0,
        room: {
          double: {
            newPrice: 43.29,
            priceBefore: 45.0,
            priceNow: 49.05,
          },
          single: {
            newPrice: 39.88,
            priceBefore: 40.38,
            priceNow: 40.2,
          },
          suite: {
            newPrice: 87.09,
            priceBefore: 83.72,
            priceNow: 82.89,
          },
        },
      },
      {
        date: '2025-04-11',
        festivos: [],
        pc_ocupacion: 19.0,
        room: {
          double: {
            newPrice: 48.64,
            priceBefore: 49.72,
            priceNow: 52.45,
          },
          single: {
            newPrice: 41.15,
            priceBefore: 42.75,
            priceNow: 43.97,
          },
          suite: {
            newPrice: 94.12,
            priceBefore: 88.65,
            priceNow: 87.63,
          },
        },
      },
      {
        date: '2025-04-12',
        festivos: [],
        pc_ocupacion: 20.0,
        room: {
          double: {
            newPrice: 78.25,
            priceBefore: 72.33,
            priceNow: 73.91,
          },
          single: {
            newPrice: 34.12,
            priceBefore: 31.09,
            priceNow: 30.18,
          },
          suite: {
            newPrice: 93.52,
            priceBefore: 88.65,
            priceNow: 96.02,
          },
        },
      },
      {
        date: '2025-04-13',
        festivos: [],
        pc_ocupacion: 28.0,
        room: {
          double: {
            newPrice: 97.78,
            priceBefore: 91.0,
            priceNow: 98.65,
          },
          single: {
            newPrice: 52.74,
            priceBefore: 48.15,
            priceNow: 50.67,
          },
          suite: {
            newPrice: 106.17,
            priceBefore: 101.66,
            priceNow: 104.73,
          },
        },
      },
      {
        date: '2025-04-14',
        festivos: [],
        pc_ocupacion: 24.0,
        room: {
          double: {
            newPrice: 44.48,
            priceBefore: 45.48,
            priceNow: 44.51,
          },
          single: {
            newPrice: 72.32,
            priceBefore: 66.0,
            priceNow: 70.95,
          },
          suite: {
            newPrice: 100.89,
            priceBefore: 103.5,
            priceNow: 108.01,
          },
        },
      },
      {
        date: '2025-04-15',
        festivos: [],
        pc_ocupacion: 21.0,
        room: {
          double: {
            newPrice: 55.2,
            priceBefore: 54.4,
            priceNow: 57.46,
          },
          single: {
            newPrice: 57.08,
            priceBefore: 53.16,
            priceNow: 54.42,
          },
          suite: {
            newPrice: 92.26,
            priceBefore: 93.15,
            priceNow: 97.9,
          },
        },
      },
      {
        date: '2025-04-16',
        festivos: [],
        pc_ocupacion: 19.0,
        room: {
          double: {
            newPrice: 56.15,
            priceBefore: 51.15,
            priceNow: 50.47,
          },
          single: {
            newPrice: 38.94,
            priceBefore: 40.65,
            priceNow: 44.19,
          },
          suite: {
            newPrice: 85.61,
            priceBefore: 88.65,
            priceNow: 86.13,
          },
        },
      },
      {
        date: '2025-04-17',
        festivos: ['Jueves Santo', 'Festividad local de Nohales'],
        pc_ocupacion: 23.0,
        room: {
          double: {
            newPrice: 67.17,
            priceBefore: 68.5,
            priceNow: 68.48,
          },
          single: {
            newPrice: 39.44,
            priceBefore: 36.38,
            priceNow: 37.73,
          },
          suite: {
            newPrice: 113.29,
            priceBefore: 107.1,
            priceNow: 104.95,
          },
        },
      },
      {
        date: '2025-04-18',
        festivos: ['Viernes Santo'],
        pc_ocupacion: 21.0,
        room: {
          double: {
            newPrice: 56.79,
            priceBefore: 57.9,
            priceNow: 62.91,
          },
          single: {
            newPrice: 45.76,
            priceBefore: 42.75,
            priceNow: 43.0,
          },
          suite: {
            newPrice: 96.83,
            priceBefore: 95.75,
            priceNow: 98.09,
          },
        },
      },
      {
        date: '2025-04-19',
        festivos: [],
        pc_ocupacion: 23.0,
        room: {
          double: {
            newPrice: 64.46,
            priceBefore: 64.5,
            priceNow: 62.58,
          },
          single: {
            newPrice: 54.77,
            priceBefore: 57.5,
            priceNow: 55.76,
          },
          suite: {
            newPrice: 91.63,
            priceBefore: 83.72,
            priceNow: 88.81,
          },
        },
      },
      {
        date: '2025-04-20',
        festivos: [],
        pc_ocupacion: 13.0,
        room: {
          double: {
            newPrice: 52.8,
            priceBefore: 49.72,
            priceNow: 51.1,
          },
          single: {
            newPrice: 45.68,
            priceBefore: 47.5,
            priceNow: 46.51,
          },
          suite: {
            newPrice: 50.95,
            priceBefore: 51.9,
            priceNow: 54.2,
          },
        },
      },
      {
        date: '2025-04-21',
        festivos: ['Festividad local de Castilnuevo'],
        pc_ocupacion: 23.0,
        room: {
          double: {
            newPrice: 77.37,
            priceBefore: 73.35,
            priceNow: 70.66,
          },
          single: {
            newPrice: 48.55,
            priceBefore: 47.5,
            priceNow: 51.68,
          },
          suite: {
            newPrice: 95.29,
            priceBefore: 88.65,
            priceNow: 97.06,
          },
        },
      },
      {
        date: '2025-04-22',
        festivos: ['Festividad local de Escariche'],
        pc_ocupacion: 25.0,
        room: {
          double: {
            newPrice: 79.55,
            priceBefore: 83.5,
            priceNow: 80.03,
          },
          single: {
            newPrice: 38.91,
            priceBefore: 40.0,
            priceNow: 39.63,
          },
          suite: {
            newPrice: 106.63,
            priceBefore: 100.5,
            priceNow: 95.98,
          },
        },
      },
      {
        date: '2025-04-23',
        festivos: [
          'Festividad local de Golosalvo',
          'Festividad local de Madrigueras',
          'Festividad local de Graja de Iniesta',
          'Festividad local de Pozoseco',
          'Festividad local de Rubielos Altos',
          'Festividad local de Rubielos Bajos',
          'Festividad local de Miralrío',
          'Festividad local de Erustes',
          'Festividad local de Villanueva de Alcardete',
        ],
        pc_ocupacion: 21.0,
        room: {
          double: {
            newPrice: 64.92,
            priceBefore: 59.4,
            priceNow: 60.05,
          },
          single: {
            newPrice: 61.2,
            priceBefore: 57.0,
            priceNow: 60.45,
          },
          suite: {
            newPrice: 79.86,
            priceBefore: 83.72,
            priceNow: 81.8,
          },
        },
      },
      {
        date: '2025-04-24',
        festivos: ['Festividad local de Segurilla'],
        pc_ocupacion: 26.0,
        room: {
          double: {
            newPrice: 66.69,
            priceBefore: 69.03,
            priceNow: 72.17,
          },
          single: {
            newPrice: 72.32,
            priceBefore: 74.5,
            priceNow: 72.38,
          },
          suite: {
            newPrice: 82.92,
            priceBefore: 83.9,
            priceNow: 89.69,
          },
        },
      },
      {
        date: '2025-04-25',
        festivos: [
          'Festividad local de Bienservida',
          'Festividad local de El Salobral',
          'Festividad local de Fuenteálamo',
          'Festividad local de Montalvos',
          'Festividad local de Reolid',
          'Festividad local de Salobre',
          'Festividad local de Viveros',
          'Festividad local de Albaladejo',
          'Festividad local de Arroba de los Montes',
          'Festividad local de Castellar de Santiago',
          'Festividad local de Ruidera',
          'Festividad local de San Carlos del Valle',
          'Festividad local de Santa Cruz de Cáñamos',
          'Festividad local de Villamanrique',
          'Festividad local de Villanueva de la Fuente',
          'Festividad local de Algarra',
          'Festividad local de Carrascosa del Campo',
          'Festividad local de Castillo-Albarañez',
          'Festividad local de Osa de la Vega',
          'Festividad local de Quintanar del Rey',
          'Festividad local de Villalpardo',
          'Festividad local de Villaverde y Pasaconsol',
          'Festividad local de Yemeda',
          'Festividad local de Chiloeches',
          'Festividad local de Tordesilos',
          'Festividad local de Villares de Jadraque',
          'Festividad local de Méntrida',
        ],
        pc_ocupacion: 28.0,
        room: {
          double: {
            newPrice: 65.17,
            priceBefore: 59.55,
            priceNow: 60.91,
          },
          single: {
            newPrice: 51.65,
            priceBefore: 52.7,
            priceNow: 51.25,
          },
          suite: {
            newPrice: 124.72,
            priceBefore: 130.25,
            priceNow: 136.76,
          },
        },
      },
      {
        date: '2025-04-26',
        festivos: [
          'Festividad local de Montealegre del Castillo',
          'Festividad local de Povedilla',
          'Festividad local de Aldea del Rey',
          'Festividad local de Maqueda',
        ],
        pc_ocupacion: 20.0,
        room: {
          double: {
            newPrice: 57.35,
            priceBefore: 58.86,
            priceNow: 56.37,
          },
          single: {
            newPrice: 34.99,
            priceBefore: 36.5,
            priceNow: 39.79,
          },
          suite: {
            newPrice: 94.83,
            priceBefore: 94.72,
            priceNow: 100.61,
          },
        },
      },
      {
        date: '2025-04-27',
        festivos: ['Festividad local de Adobes', 'Festividad local de Azután'],
        pc_ocupacion: 21.0,
        room: {
          double: {
            newPrice: 62.57,
            priceBefore: 59.08,
            priceNow: 56.25,
          },
          single: {
            newPrice: 48.79,
            priceBefore: 47.6,
            priceNow: 50.8,
          },
          suite: {
            newPrice: 91.05,
            priceBefore: 92.55,
            priceNow: 99.69,
          },
        },
      },
      {
        date: '2025-04-28',
        festivos: [],
        pc_ocupacion: 23.0,
        room: {
          double: {
            newPrice: 62.96,
            priceBefore: 62.69,
            priceNow: 66.54,
          },
          single: {
            newPrice: 53.56,
            priceBefore: 52.38,
            priceNow: 50.18,
          },
          suite: {
            newPrice: 90.41,
            priceBefore: 90.15,
            priceNow: 88.68,
          },
        },
      },
      {
        date: '2025-04-29',
        festivos: [
          'Festividad local de Casas Ibáñez',
          'Festividad local de Hoya Gonzalo',
          'Festividad local de Ossa de Montiel',
          'Festividad local de Bolaños de Calatrava',
          'Festividad local de Tomelloso',
          'Festividad local de Casas de Santa Cruz',
          'Festividad local de Graja de Campalbo',
          'Festividad local de Pozoamargo',
          'Festividad local de Tórtola de Henares',
          'Festividad local de Maqueda',
          'Festividad local de Mora',
          'Festividad local de Pelahustán',
          'Festividad local de Villamuelas',
        ],
        pc_ocupacion: 27.0,
        room: {
          double: {
            newPrice: 78.29,
            priceBefore: 75.5,
            priceNow: 77.25,
          },
          single: {
            newPrice: 58.75,
            priceBefore: 61.16,
            priceNow: 60.34,
          },
          suite: {
            newPrice: 95.63,
            priceBefore: 99.45,
            priceNow: 104.52,
          },
        },
      },
      {
        date: '2025-04-30',
        festivos: [
          'Festividad local de Fontanarejo',
          'Festividad local de Palomares del Campo',
          'Festividad local de Henche',
        ],
        pc_ocupacion: 27.0,
        room: {
          double: {
            newPrice: 71.35,
            priceBefore: 70.2,
            priceNow: 69.58,
          },
          single: {
            newPrice: 58.33,
            priceBefore: 60.75,
            priceNow: 63.96,
          },
          suite: {
            newPrice: 98.07,
            priceBefore: 99.45,
            priceNow: 97.23,
          },
        },
      },
      {
        date: '2025-05-01',
        festivos: ['Fiesta del Trabajo'],
        pc_ocupacion: 23.0,
        room: {
          double: {
            newPrice: 70.56,
            priceBefore: 66.3,
            priceNow: 68.02,
          },
          single: {
            newPrice: 57.51,
            priceBefore: 59.92,
            priceNow: 64.56,
          },
          suite: {
            newPrice: 85.35,
            priceBefore: 83.72,
            priceNow: 85.34,
          },
        },
      },
      {
        date: '2025-05-02',
        festivos: [
          'Festividad local de La Gineta',
          'Festividad local de Lezuza',
          'Festividad local de Tinajeros',
          'Festividad local de Villamayor de Calatrava',
          'Festividad local de Alcázar del Rey',
          'Festividad local de Escopete',
          'Festividad local de Aldea en Cabo',
          'Festividad local de Belvís de la Jara',
          'Festividad local de La Iglesuela del Tietar',
          'Festividad local de Lominchar',
          'Festividad local de Piedraescrita',
        ],
        pc_ocupacion: 18.0,
        room: {
          double: {
            newPrice: 45.42,
            priceBefore: 44.0,
            priceNow: 44.05,
          },
          single: {
            newPrice: 43.48,
            priceBefore: 40.38,
            priceNow: 39.66,
          },
          suite: {
            newPrice: 91.75,
            priceBefore: 93.92,
            priceNow: 94.35,
          },
        },
      },
      {
        date: '2025-05-03',
        festivos: [
          'Festividad local de Alpera',
          'Festividad local de Balazote',
          'Festividad local de Balsa de Vés',
          'Festividad local de Cotillas',
          'Festividad local de Hellín',
          'Festividad local de La Gineta',
          'Festividad local de Lezuza',
          'Festividad local de Pozo Lorente',
          'Festividad local de Riópar',
          'Festividad local de Socovos',
          'Festividad local de Tinajeros',
          'Festividad local de Yeste',
          'Festividad local de Arroba de los Montes',
          'Festividad local de Carrizosa',
          'Festividad local de Granátula de Calatrava',
          'Festividad local de Picón',
          'Festividad local de Piedrabuena',
          'Festividad local de Porzuna',
          'Festividad local de Saceruela',
          'Festividad local de Villanueva de los Infantes',
          'Festividad local de Graja de Iniesta',
          'Festividad local de Huerta de la Obispalía',
          'Festividad local de La Cierva',
          'Festividad local de Las Majadas',
          'Festividad local de San Lorenzo de la Parrilla',
          'Festividad local de Valdetortola',
          'Festividad local de Vellisca',
          'Festividad local de Villalgordo del Marquesado',
          'Festividad local de Villar de Olalla',
          'Festividad local de Villares del Saz',
          'Festividad local de Almoguera',
          'Festividad local de Cabanillas del Campo',
          'Festividad local de Fuencemillan',
          'Festividad local de Fuentelviejo',
          'Festividad local de La Yunta',
          'Festividad local de Marchamalo',
          'Festividad local de Milmarcos',
          'Festividad local de Mohernando',
          'Festividad local de Paredes de Sigüenza',
          'Festividad local de Almorox',
          'Festividad local de Buenaventura',
          'Festividad local de Chozas de Canales',
          'Festividad local de Ciruelos',
          'Festividad local de La Iglesuela del Tietar',
          'Festividad local de Lillo',
          'Festividad local de Lominchar',
          'Festividad local de Navalmoralejo',
          'Festividad local de Noblejas',
          'Festividad local de Noez',
          'Festividad local de Piedraescrita',
          'Festividad local de San Martín de Pusa',
          'Festividad local de Santa Cruz del Retamar',
          'Festividad local de Torre de Esteban Hambrán',
          'Festividad local de Valmojado',
        ],
        pc_ocupacion: 26.0,
        room: {
          double: {
            newPrice: 61.85,
            priceBefore: 60.06,
            priceNow: 57.49,
          },
          single: {
            newPrice: 61.68,
            priceBefore: 58.5,
            priceNow: 63.64,
          },
          suite: {
            newPrice: 121.21,
            priceBefore: 110.5,
            priceNow: 113.54,
          },
        },
      },
      {
        date: '2025-05-04',
        festivos: ['Festividad local de Prados Redondos'],
        pc_ocupacion: 15.0,
        room: {
          double: {
            newPrice: 63.62,
            priceBefore: 58.48,
            priceNow: 62.25,
          },
          single: {
            newPrice: 45.17,
            priceBefore: 45.0,
            priceNow: 47.0,
          },
          suite: {
            newPrice: 59.69,
            priceBefore: 56.5,
            priceNow: 57.1,
          },
        },
      },
      {
        date: '2025-05-05',
        festivos: [],
        pc_ocupacion: 19.0,
        room: {
          double: {
            newPrice: 48.86,
            priceBefore: 47.94,
            priceNow: 51.38,
          },
          single: {
            newPrice: 50.85,
            priceBefore: 49.95,
            priceNow: 51.23,
          },
          suite: {
            newPrice: 89.52,
            priceBefore: 88.65,
            priceNow: 94.89,
          },
        },
      },
      {
        date: '2025-05-06',
        festivos: [
          'Festividad local de Almansa',
          'Festividad local de Casas de Guijarro',
          'Festividad local de Mazarulleque',
          'Festividad local de Rada de Haro',
          'Festividad local de Solera de Gabaldón',
          'Festividad local de Tinajas',
          'Festividad local de Ucles',
          'Festividad local de Villarejo Periesteban',
          'Festividad local de Lupiana',
          'Festividad local de Caleruela',
          'Festividad local de Ciruelos',
          'Festividad local de Mazarambroz',
          'Festividad local de Santa Olalla',
          'Festividad local de Sonseca',
        ],
        pc_ocupacion: 23.0,
        room: {
          double: {
            newPrice: 51.55,
            priceBefore: 51.64,
            priceNow: 53.71,
          },
          single: {
            newPrice: 59.69,
            priceBefore: 58.05,
            priceNow: 63.17,
          },
          suite: {
            newPrice: 98.55,
            priceBefore: 99.45,
            priceNow: 104.92,
          },
        },
      },
      {
        date: '2025-05-07',
        festivos: [
          'Festividad local de Villel de Mesa',
          'Festividad local de Ajofrín',
          'Festividad local de Caleruela',
        ],
        pc_ocupacion: 24.0,
        room: {
          double: {
            newPrice: 66.56,
            priceBefore: 69.0,
            priceNow: 75.44,
          },
          single: {
            newPrice: 67.23,
            priceBefore: 62.32,
            priceNow: 65.75,
          },
          suite: {
            newPrice: 91.83,
            priceBefore: 85.18,
            priceNow: 84.77,
          },
        },
      },
      {
        date: '2025-05-08',
        festivos: [
          'Festividad local de Abengibre',
          'Festividad local de Villa de Vés',
          'Festividad local de Arenales de San Gregorio',
          'Festividad local de Alconchel de la Estrella',
          'Festividad local de Huelves',
          'Festividad local de Olmeda del Rey',
          'Festividad local de Palomera',
          'Festividad local de Villagarcía del Llano',
          'Festividad local de Argecilla',
          'Festividad local de Matarrubia',
          'Festividad local de Taragudo',
        ],
        pc_ocupacion: 27.0,
        room: {
          double: {
            newPrice: 76.41,
            priceBefore: 79.5,
            priceNow: 83.36,
          },
          single: {
            newPrice: 62.83,
            priceBefore: 63.45,
            priceNow: 69.54,
          },
          suite: {
            newPrice: 98.07,
            priceBefore: 94.33,
            priceNow: 102.89,
          },
        },
      },
      {
        date: '2025-05-09',
        festivos: [
          'Festividad local de Almedina',
          'Festividad local de Arenales de San Gregorio',
          'Festividad local de Caracuel de Calatrava',
          'Festividad local de Alconchel de la Estrella',
          'Festividad local de Cubillejo del Sitio',
          'Festividad local de Valdegrudas',
        ],
        pc_ocupacion: 22.0,
        room: {
          double: {
            newPrice: 72.79,
            priceBefore: 71.76,
            priceNow: 78.51,
          },
          single: {
            newPrice: 38.69,
            priceBefore: 38.29,
            priceNow: 38.0,
          },
          suite: {
            newPrice: 93.31,
            priceBefore: 91.5,
            priceNow: 88.9,
          },
        },
      },
      {
        date: '2025-05-10',
        festivos: [
          'Festividad local de Almodóvar del Campo',
          'Festividad local de Montiel',
          'Festividad local de Alcantud',
          'Festividad local de Huete',
          'Festividad local de Alustante',
          'Festividad local de Mirabueno',
          'Festividad local de Riba de Saelices',
        ],
        pc_ocupacion: 20.0,
        room: {
          double: {
            newPrice: 66.91,
            priceBefore: 66.3,
            priceNow: 71.28,
          },
          single: {
            newPrice: 50.9,
            priceBefore: 49.0,
            priceNow: 49.17,
          },
          suite: {
            newPrice: 77.46,
            priceBefore: 76.58,
            priceNow: 77.83,
          },
        },
      },
      {
        date: '2025-05-11',
        festivos: [
          'Festividad local de Piqueras del Castillo',
          'Festividad local de Tordellego',
        ],
        pc_ocupacion: 25.0,
        room: {
          double: {
            newPrice: 54.59,
            priceBefore: 51.05,
            priceNow: 51.05,
          },
          single: {
            newPrice: 63.9,
            priceBefore: 59.92,
            priceNow: 62.78,
          },
          suite: {
            newPrice: 120.47,
            priceBefore: 110.5,
            priceNow: 121.43,
          },
        },
      },
      {
        date: '2025-05-12',
        festivos: [],
        pc_ocupacion: 29.0,
        room: {
          double: {
            newPrice: 88.54,
            priceBefore: 93.0,
            priceNow: 98.62,
          },
          single: {
            newPrice: 58.2,
            priceBefore: 57.15,
            priceNow: 62.51,
          },
          suite: {
            newPrice: 100.26,
            priceBefore: 93.8,
            priceNow: 97.16,
          },
        },
      },
      {
        date: '2025-05-13',
        festivos: [
          'Festividad local de Fuensanta',
          'Festividad local de Masegoso',
          'Festividad local de Peñascosa',
          'Festividad local de Solana del Pino',
          'Festividad local de Torrenueva',
          'Festividad local de Villanueva de la Fuente',
          'Festividad local de Villanueva de San Carlos',
          'Festividad local de Alcantud',
          'Festividad local de Buendía',
          'Festividad local de Carboneras de Guadazaon',
          'Festividad local de San Pedro Palmiches',
          'Festividad local de Fontanar',
          'Festividad local de Labros',
          'Festividad local de Mirabueno',
          'Festividad local de Alcolea de Tajo',
          'Festividad local de Casar de Escalona',
          'Festividad local de Mesegar de Tajo',
        ],
        pc_ocupacion: 27.0,
        room: {
          double: {
            newPrice: 71.99,
            priceBefore: 70.2,
            priceNow: 69.42,
          },
          single: {
            newPrice: 62.72,
            priceBefore: 58.05,
            priceNow: 57.02,
          },
          suite: {
            newPrice: 103.66,
            priceBefore: 107.5,
            priceNow: 115.05,
          },
        },
      },
      {
        date: '2025-05-14',
        festivos: [
          'Festividad local de Pozuelo',
          'Festividad local de Fuentelencina',
          'Festividad local de Salmerón',
          'Festividad local de Valdepeñas de la Sierra',
          'Festividad local de Valdeazores',
        ],
        pc_ocupacion: 28.0,
        room: {
          double: {
            newPrice: 79.16,
            priceBefore: 79.65,
            priceNow: 76.47,
          },
          single: {
            newPrice: 62.89,
            priceBefore: 63.45,
            priceNow: 65.26,
          },
          suite: {
            newPrice: 94.89,
            priceBefore: 99.45,
            priceNow: 96.69,
          },
        },
      },
      {
        date: '2025-05-15',
        festivos: [
          'Festividad local de Aguas Nuevas',
          'Festividad local de Alatoz',
          'Festividad local de Albatana',
          'Festividad local de Alborea',
          'Festividad local de Alcadozo',
          'Festividad local de Alcalá del Júcar',
          'Festividad local de Carcelén',
          'Festividad local de Casas de Juan Núñez',
          'Festividad local de Corralrubio',
          'Festividad local de Fuentealbilla',
          'Festividad local de Golosalvo',
          'Festividad local de La Herrera',
          'Festividad local de La Recueja',
          'Festividad local de Mahora',
          'Festividad local de Montalvos',
          'Festividad local de Montealegre del Castillo',
          'Festividad local de Motilleja',
          'Festividad local de Munera',
          'Festividad local de Navas de Jorquera',
          'Festividad local de Pozo Cañada',
          'Festividad local de Vianos',
          'Festividad local de Villamalea',
          'Festividad local de Villapalacios',
          'Festividad local de Villatoya',
          'Festividad local de Villavaliente',
          'Festividad local de Viveros',
          'Festividad local de Agudo',
          'Festividad local de Albaladejo',
          'Festividad local de Alcázar de San Juan',
          'Festividad local de Alcolea de Calatrava',
          'Festividad local de Alcubillas',
          'Festividad local de Alhambra',
          'Festividad local de Almuradiel',
          'Festividad local de Argamasilla de Alba',
          'Festividad local de Argamasilla de Calatrava',
          'Festividad local de Brazatortas',
          'Festividad local de Cabezarados',
          'Festividad local de Corral de Calatrava',
          'Festividad local de Cózar',
          'Festividad local de Fuencaliente',
          'Festividad local de Horcajo de los Montes',
          'Festividad local de Las Labores',
          'Festividad local de Llanos del Caudillo',
          'Festividad local de Navalpino',
          'Festividad local de Picón',
          'Festividad local de Piedrabuena',
          'Festividad local de Poblete',
          'Festividad local de Porzuna',
          'Festividad local de Puerto Lápice',
          'Festividad local de Retuerta del Bullaque',
          'Festividad local de San Carlos del Valle',
          'Festividad local de Terrinches',
          'Festividad local de Torralba de Calatrava',
          'Festividad local de Torre de Juan Abad',
          'Festividad local de Valenzuela de Calatrava',
          'Festividad local de Villamanrique',
          'Festividad local de Villamayor de Calatrava',
          'Festividad local de Villarrubia de los Ojos',
          'Festividad local de Albaladejo del Cuende',
          'Festividad local de Albalate de las Nogueras',
          'Festividad local de Alcázar del Rey',
          'Festividad local de Almonacid del Marquesado',
          'Festividad local de Altarejos',
          'Festividad local de Arandilla del Arroyo',
          'Festividad local de Arcas',
          'Festividad local de Belmontejo',
          'Festividad local de Buenache de Alarcon',
          'Festividad local de Campillo de Altobuey',
          'Festividad local de Canalejas del Arroyo',
          'Festividad local de Cañada Juncosa',
          'Festividad local de Cañaveras',
          'Festividad local de Cañaveruelas',
          'Festividad local de Carrascosa de Haro',
          'Festividad local de Casas de Benitez',
          'Festividad local de Casas de Fernando Alonso',
          'Festividad local de Casas de los Pinos',
          'Festividad local de Casas de Roldan',
          'Festividad local de Casas de Santa Cruz',
          'Festividad local de Chumillas',
          'Festividad local de El Acebron',
          'Festividad local de El Pedernoso',
          'Festividad local de El Peral',
          'Festividad local de El Picazo',
          'Festividad local de Fuente de Pedro Naharro',
          'Festividad local de Fuentesclaras Chillaron',
          'Festividad local de Fuentesbuenas',
          'Festividad local de Garcinarro',
          'Festividad local de Honrubia',
          'Festividad local de Hontanaya',
          'Festividad local de Horcajada de la Torre',
          'Festividad local de Iniesta',
          'Festividad local de Jabaga',
          'Festividad local de Jabalera',
          'Festividad local de La Alberca de Zancara',
          'Festividad local de La Almarcha',
          'Festividad local de La Frontera',
          'Festividad local de La Parra de las Vegas',
          'Festividad local de La Ventosa',
          'Festividad local de Las Pedroñeras',
          'Festividad local de Ledaña',
          'Festividad local de Los Estesos',
          'Festividad local de Los Hinojosos',
          'Festividad local de Mazarulleque',
          'Festividad local de Minglanilla',
          'Festividad local de Mira',
          'Festividad local de Montalbanejo',
          'Festividad local de Motilla del Palancar',
          'Festividad local de Navalon',
          'Festividad local de Noheda',
          'Festividad local de Olivares del Jucar',
          'Festividad local de Olmeda de la Cuesta',
          'Festividad local de Olmeda del Rey',
          'Festividad local de Olmedilla de Eliz',
          'Festividad local de Paredes',
          'Festividad local de Poveda de la Obispalía',
          'Festividad local de Pozoseco',
          'Festividad local de Puebla de Almenara',
          'Festividad local de Puebla del Salvador',
          'Festividad local de Quintanar del Rey',
          'Festividad local de Ribagorda',
          'Festividad local de Rubielos Altos',
          'Festividad local de Rubielos Bajos',
          'Festividad local de Salmeroncillos',
          'Festividad local de Santa Maria de los Llanos',
          'Festividad local de Tresjuncos',
          'Festividad local de Valdecabras',
          'Festividad local de Valdecañas',
          'Festividad local de Valdemorillo de la Sierra',
          'Festividad local de Valdemoro Sierra',
          'Festividad local de Valeria',
          'Festividad local de Valparaiso de Arriba',
          'Festividad local de Valverdejo',
          'Festividad local de Villaconejos de Trabaque',
          'Festividad local de Villagarcía del Llano',
          'Festividad local de Villamayor de Santiago',
          'Festividad local de Villar de Domingo Garcia',
          'Festividad local de Villar de la Encina',
          'Festividad local de Villar del Humo',
          'Festividad local de Villar del Infantado',
          'Festividad local de Villar del Saz de Navalon',
          'Festividad local de Villarejo del Espartal',
          'Festividad local de Villarejo Periesteban',
          'Festividad local de Villarta',
          'Festividad local de Zafra de Zancara',
          'Festividad local de Abánades',
          'Festividad local de Alaminos',
          'Festividad local de Alarilla',
          'Festividad local de Alcolea de las Peñas',
          'Festividad local de Armuña de Tajuña',
          'Festividad local de Azuqueca de Henares',
          'Festividad local de Bujalaro',
          'Festividad local de Canredondo',
          'Festividad local de Cañizar',
          'Festividad local de Castejón de Henares',
          'Festividad local de Ciruelas',
          'Festividad local de Cogolludo',
          'Festividad local de El Cubillo de Uceda',
          'Festividad local de El Pobo de Dueñas',
          'Festividad local de Esplegares',
          'Festividad local de Fuentenovilla',
          'Festividad local de Gajanejos',
          'Festividad local de Las Inviernas',
          'Festividad local de Ledanca',
          'Festividad local de Marchamalo',
          'Festividad local de Pioz',
          'Festividad local de Sacecorbo',
          'Festividad local de Torre del Burgo',
          'Festividad local de Utande',
          'Festividad local de Valdearenas',
          'Festividad local de Valderrebollo',
          'Festividad local de Alberche del Caudillo',
          'Festividad local de Alcolea de Tajo',
          'Festividad local de Almendral de La Cañada',
          'Festividad local de Almonacid de Toledo',
          'Festividad local de Arcicollar',
          'Festividad local de Bernuy',
          'Festividad local de Burguillos de Toledo',
          'Festividad local de Cabañas de la Sagra',
          'Festividad local de Camuñas',
          'Festividad local de Casarrubios del Monte',
          'Festividad local de Casasbuenas',
          'Festividad local de Chueca',
          'Festividad local de Cuerva',
          'Festividad local de Domingo Pérez',
          'Festividad local de Dosbarrios',
          'Festividad local de Gálvez',
          'Festividad local de Gargantilla',
          'Festividad local de La Puebla de Almoradiel',
          'Festividad local de La Rinconada',
          'Festividad local de Las Herencias',
          'Festividad local de Las Vegas y San Antonio',
          'Festividad local de Layos',
          'Festividad local de Manzaneque',
          'Festividad local de Mascaraque',
          'Festividad local de Mejorada',
          'Festividad local de Miguel Esteban',
          'Festividad local de Quintanar de la Orden',
          'Festividad local de Quismondo',
          'Festividad local de Romeral',
          'Festividad local de San Pablo de los Montes',
          'Festividad local de Santa Cruz de la Zarza',
          'Festividad local de Seseña',
          'Festividad local de Talavera de la Reina',
          'Festividad local de Talavera la Nueva',
          'Festividad local de Tembleque',
          'Festividad local de Torre de Esteban Hambrán',
          'Festividad local de Totanés',
          'Festividad local de Turleque',
          'Festividad local de Ugena',
          'Festividad local de Urda',
          'Festividad local de Valdeazores',
          'Festividad local de Villa de Don Fadrique',
          'Festividad local de Villacañas',
          'Festividad local de Villafranca de los Caballeros',
          'Festividad local de Villasequilla',
        ],
        pc_ocupacion: 25.0,
        room: {
          double: {
            newPrice: 66.95,
            priceBefore: 66.3,
            priceNow: 72.75,
          },
          single: {
            newPrice: 57.06,
            priceBefore: 52.28,
            priceNow: 55.29,
          },
          suite: {
            newPrice: 112.19,
            priceBefore: 105.5,
            priceNow: 105.73,
          },
        },
      },
      {
        date: '2025-05-16',
        festivos: [
          'Festividad local de Alcadozo',
          'Festividad local de Cenizate',
          'Festividad local de Puertollano',
          'Festividad local de Cañada Juncosa',
          'Festividad local de Monreal del Llano',
          'Festividad local de Valhermoso de la Fuente',
          'Festividad local de La Olmeda de Jadraque',
          'Festividad local de Valdarachas',
        ],
        pc_ocupacion: 21.0,
        room: {
          double: {
            newPrice: 59.87,
            priceBefore: 61.65,
            priceNow: 59.81,
          },
          single: {
            newPrice: 42.93,
            priceBefore: 44.5,
            priceNow: 46.0,
          },
          suite: {
            newPrice: 96.38,
            priceBefore: 89.5,
            priceNow: 89.62,
          },
        },
      },
      {
        date: '2025-05-17',
        festivos: [
          'Festividad local de Paterna del Madera',
          'Festividad local de Fuenllana',
          'Festividad local de Balbacil',
          'Festividad local de Castellar de la Muela',
          'Festividad local de Clares',
          'Festividad local de Codes',
          'Festividad local de Fuentelsaz',
          'Festividad local de Maranchón',
          'Festividad local de Mochales',
          'Festividad local de Moratilla de los Meleros',
          'Festividad local de Tierzo',
          'Festividad local de Torremocha del Campo',
          'Festividad local de Turmiel',
        ],
        pc_ocupacion: 29.0,
        room: {
          double: {
            newPrice: 70.81,
            priceBefore: 71.7,
            priceNow: 77.0,
          },
          single: {
            newPrice: 62.77,
            priceBefore: 65.0,
            priceNow: 62.86,
          },
          suite: {
            newPrice: 109.92,
            priceBefore: 106.82,
            priceNow: 114.13,
          },
        },
      },
      {
        date: '2025-05-18',
        festivos: [],
        pc_ocupacion: 22.0,
        room: {
          double: {
            newPrice: 65.79,
            priceBefore: 60.06,
            priceNow: 62.15,
          },
          single: {
            newPrice: 40.54,
            priceBefore: 42.12,
            priceNow: 45.05,
          },
          suite: {
            newPrice: 98.64,
            priceBefore: 99.74,
            priceNow: 96.64,
          },
        },
      },
      {
        date: '2025-05-19',
        festivos: [],
        pc_ocupacion: 27.0,
        room: {
          double: {
            newPrice: 81.29,
            priceBefore: 79.5,
            priceNow: 82.94,
          },
          single: {
            newPrice: 60.4,
            priceBefore: 58.05,
            priceNow: 58.61,
          },
          suite: {
            newPrice: 107.97,
            priceBefore: 99.45,
            priceNow: 104.83,
          },
        },
      },
      {
        date: '2025-05-20',
        festivos: [
          'Festividad local de Peñas de San Pedro',
          'Festividad local de Tiriez',
          'Festividad local de Ciudad Real',
          'Festividad local de Guadalmez',
          'Festividad local de Algarra',
          'Festividad local de Boniches',
          'Festividad local de Cañada del Hoyo',
          'Festividad local de Castejon',
          'Festividad local de El Acebron',
          'Festividad local de La Hinojosa',
          'Festividad local de Landete',
          'Festividad local de Poveda de la Obispalía',
          'Festividad local de San Clemente',
          'Festividad local de Santa Cruz de Moya',
          'Festividad local de Vara de Rey',
          'Festividad local de Villarejo de Fuentes',
          'Festividad local de Auñón',
          'Festividad local de Corral de Almaguer',
          'Festividad local de Hontanar',
          'Festividad local de Nambroca',
          'Festividad local de Navahermosa',
          'Festividad local de Torrijos',
          'Festividad local de Velada',
        ],
        pc_ocupacion: 26.0,
        room: {
          double: {
            newPrice: 69.25,
            priceBefore: 67.65,
            priceNow: 66.09,
          },
          single: {
            newPrice: 31.47,
            priceBefore: 32.5,
            priceNow: 34.37,
          },
          suite: {
            newPrice: 127.41,
            priceBefore: 129.0,
            priceNow: 141.13,
          },
        },
      },
      {
        date: '2025-05-21',
        festivos: ['Festividad local de Hombrados'],
        pc_ocupacion: 19.0,
        room: {
          double: {
            newPrice: 64.55,
            priceBefore: 66.3,
            priceNow: 71.34,
          },
          single: {
            newPrice: 48.14,
            priceBefore: 45.5,
            priceNow: 48.17,
          },
          suite: {
            newPrice: 72.16,
            priceBefore: 68.36,
            priceNow: 71.84,
          },
        },
      },
      {
        date: '2025-05-22',
        festivos: [
          'Festividad local de Casas de Vés',
          'Festividad local de Higueruela',
          'Festividad local de Jorquera',
          'Festividad local de Pétrola',
          'Festividad local de Alcoba de los Montes',
          'Festividad local de Fuente el Fresno',
          'Festividad local de Los Pozuelos de Calatrava',
          'Festividad local de Puebla de Don Rodrigo',
          'Festividad local de Belinchon',
          'Festividad local de Fuentelespino de Moya',
          'Festividad local de Rozalen Del Monte',
          'Festividad local de Tebar',
          'Festividad local de Ablanque',
          'Festividad local de Carrascosa de Henares',
          'Festividad local de Megina',
          'Festividad local de Torrecuadrada de Molina',
          'Festividad local de Marjaliza',
          'Festividad local de Minas de Santa Quiteria',
        ],
        pc_ocupacion: 22.0,
        room: {
          double: {
            newPrice: 64.1,
            priceBefore: 59.5,
            priceNow: 62.17,
          },
          single: {
            newPrice: 51.76,
            priceBefore: 52.65,
            priceNow: 50.88,
          },
          suite: {
            newPrice: 89.79,
            priceBefore: 88.68,
            priceNow: 86.33,
          },
        },
      },
      {
        date: '2025-05-23',
        festivos: [
          'Festividad local de Higueruela',
          'Festividad local de Puebla del Príncipe',
          'Festividad local de Minas de Santa Quiteria',
        ],
        pc_ocupacion: 22.0,
        room: {
          double: {
            newPrice: 60.99,
            priceBefore: 56.52,
            priceNow: 54.43,
          },
          single: {
            newPrice: 52.58,
            priceBefore: 52.56,
            priceNow: 52.71,
          },
          suite: {
            newPrice: 95.86,
            priceBefore: 92.26,
            priceNow: 97.1,
          },
        },
      },
      {
        date: '2025-05-24',
        festivos: [
          'Festividad local de Barrax',
          'Festividad local de Abia de la Obispalia',
          'Festividad local de Almodóvar del Pinar',
          'Festividad local de Huete',
          'Festividad local de Naharros',
          'Festividad local de Saceda-Trasierra',
          'Festividad local de Robledillo',
        ],
        pc_ocupacion: 24.0,
        room: {
          double: {
            newPrice: 67.24,
            priceBefore: 66.3,
            priceNow: 69.2,
          },
          single: {
            newPrice: 57.44,
            priceBefore: 55.0,
            priceNow: 58.54,
          },
          suite: {
            newPrice: 98.94,
            priceBefore: 93.92,
            priceNow: 98.66,
          },
        },
      },
      {
        date: '2025-05-25',
        festivos: [],
        pc_ocupacion: 22.0,
        room: {
          double: {
            newPrice: 47.29,
            priceBefore: 46.28,
            priceNow: 49.89,
          },
          single: {
            newPrice: 61.23,
            priceBefore: 57.38,
            priceNow: 55.2,
          },
          suite: {
            newPrice: 104.98,
            priceBefore: 99.45,
            priceNow: 103.3,
          },
        },
      },
      {
        date: '2025-05-26',
        festivos: [],
        pc_ocupacion: 31.0,
        room: {
          double: {
            newPrice: 89.67,
            priceBefore: 88.0,
            priceNow: 96.67,
          },
          single: {
            newPrice: 78.04,
            priceBefore: 74.5,
            priceNow: 73.63,
          },
          suite: {
            newPrice: 102.31,
            priceBefore: 99.45,
            priceNow: 107.42,
          },
        },
      },
      {
        date: '2025-05-27',
        festivos: [
          'Festividad local de Mestanza',
          'Festividad local de Navas de Estena',
          'Festividad local de Saelices',
          'Festividad local de Cañizar',
          'Festividad local de Córcoles',
          'Festividad local de Hortezuela de Océn',
          'Festividad local de Hormigos',
          'Festividad local de Robledillo',
        ],
        pc_ocupacion: 21.0,
        room: {
          double: {
            newPrice: 55.7,
            priceBefore: 54.06,
            priceNow: 54.77,
          },
          single: {
            newPrice: 54.86,
            priceBefore: 57.15,
            priceNow: 55.2,
          },
          suite: {
            newPrice: 91.95,
            priceBefore: 88.82,
            priceNow: 88.11,
          },
        },
      },
      {
        date: '2025-05-28',
        festivos: [
          'Festividad local de El Hito',
          'Festividad local de Puebla del Salvador',
          'Festividad local de Córcoles',
          'Festividad local de Tordelrábano',
        ],
        pc_ocupacion: 23.0,
        room: {
          double: {
            newPrice: 76.99,
            priceBefore: 70.2,
            priceNow: 67.84,
          },
          single: {
            newPrice: 40.63,
            priceBefore: 37.72,
            priceNow: 38.37,
          },
          suite: {
            newPrice: 111.57,
            priceBefore: 103.95,
            priceNow: 112.79,
          },
        },
      },
      {
        date: '2025-05-29',
        festivos: [
          'Festividad local de Gabaldon',
          'Festividad local de Sacedón',
        ],
        pc_ocupacion: 19.0,
        room: {
          double: {
            newPrice: 56.61,
            priceBefore: 58.64,
            priceNow: 61.31,
          },
          single: {
            newPrice: 40.6,
            priceBefore: 40.38,
            priceNow: 40.07,
          },
          suite: {
            newPrice: 90.09,
            priceBefore: 83.3,
            priceNow: 86.89,
          },
        },
      },
      {
        date: '2025-05-30',
        festivos: ['Festividad local de Sacedón', 'Festividad local de Selas'],
        pc_ocupacion: 21.0,
        room: {
          double: {
            newPrice: 51.13,
            priceBefore: 51.05,
            priceNow: 53.77,
          },
          single: {
            newPrice: 54.41,
            priceBefore: 51.22,
            priceNow: 55.08,
          },
          suite: {
            newPrice: 96.58,
            priceBefore: 93.92,
            priceNow: 93.5,
          },
        },
      },
      {
        date: '2025-05-31',
        festivos: ['Día de Castilla-La Mancha'],
        pc_ocupacion: 23.0,
        room: {
          double: {
            newPrice: 70.06,
            priceBefore: 63.74,
            priceNow: 66.03,
          },
          single: {
            newPrice: 50.83,
            priceBefore: 50.0,
            priceNow: 49.7,
          },
          suite: {
            newPrice: 100.45,
            priceBefore: 99.45,
            priceNow: 108.88,
          },
        },
      },
      {
        date: '2025-06-01',
        festivos: [
          'Festividad local de Cuenca',
          'Festividad local de La Melgosa',
          'Festividad local de Mohorte',
          'Festividad local de Gualda',
        ],
        pc_ocupacion: 52.0,
        room: {
          double: {
            newPrice: 126.18,
            priceBefore: 115.6,
            priceNow: 114.86,
          },
          single: {
            newPrice: 87.93,
            priceBefore: 89.1,
            priceNow: 87.78,
          },
          suite: {
            newPrice: 203.86,
            priceBefore: 187.85,
            priceNow: 193.26,
          },
        },
      },
      {
        date: '2025-06-02',
        festivos: [],
        pc_ocupacion: 59.0,
        room: {
          double: {
            newPrice: 171.93,
            priceBefore: 157.14,
            priceNow: 171.57,
          },
          single: {
            newPrice: 77.23,
            priceBefore: 80.75,
            priceNow: 87.75,
          },
          suite: {
            newPrice: 196.95,
            priceBefore: 198.9,
            priceNow: 202.26,
          },
        },
      },
      {
        date: '2025-06-03',
        festivos: [
          'Festividad local de Zafrilla',
          'Festividad local de Casas de San Galindo',
        ],
        pc_ocupacion: 56.0,
        room: {
          double: {
            newPrice: 142.59,
            priceBefore: 137.4,
            priceNow: 143.6,
          },
          single: {
            newPrice: 93.43,
            priceBefore: 90.0,
            priceNow: 93.15,
          },
          suite: {
            newPrice: 188.45,
            priceBefore: 187.85,
            priceNow: 187.33,
          },
        },
      },
      {
        date: '2025-06-04',
        festivos: ['Festividad local de Casas de San Galindo'],
        pc_ocupacion: 54.0,
        room: {
          double: {
            newPrice: 144.08,
            priceBefore: 145.8,
            priceNow: 153.95,
          },
          single: {
            newPrice: 60.26,
            priceBefore: 62.8,
            priceNow: 59.89,
          },
          suite: {
            newPrice: 205.54,
            priceBefore: 194.4,
            priceNow: 205.94,
          },
        },
      },
      {
        date: '2025-06-05',
        festivos: [],
        pc_ocupacion: 55.0,
        room: {
          double: {
            newPrice: 114.68,
            priceBefore: 115.2,
            priceNow: 126.45,
          },
          single: {
            newPrice: 98.69,
            priceBefore: 100.0,
            priceNow: 109.9,
          },
          suite: {
            newPrice: 199.17,
            priceBefore: 197.25,
            priceNow: 202.87,
          },
        },
      },
      {
        date: '2025-06-06',
        festivos: ['Festividad local de Valdepeñas'],
        pc_ocupacion: 44.0,
        room: {
          double: {
            newPrice: 112.04,
            priceBefore: 115.32,
            priceNow: 119.04,
          },
          single: {
            newPrice: 107.49,
            priceBefore: 110.0,
            priceNow: 106.68,
          },
          suite: {
            newPrice: 126.98,
            priceBefore: 117.35,
            priceNow: 124.71,
          },
        },
      },
      {
        date: '2025-06-07',
        festivos: ['Festividad local de Hinojosa'],
        pc_ocupacion: 66.0,
        room: {
          double: {
            newPrice: 133.83,
            priceBefore: 139.0,
            priceNow: 140.71,
          },
          single: {
            newPrice: 135.48,
            priceBefore: 130.0,
            priceNow: 134.65,
          },
          suite: {
            newPrice: 204.31,
            priceBefore: 213.0,
            priceNow: 213.76,
          },
        },
      },
      {
        date: '2025-06-08',
        festivos: [],
        pc_ocupacion: 64.0,
        room: {
          double: {
            newPrice: 163.44,
            priceBefore: 157.0,
            priceNow: 161.11,
          },
          single: {
            newPrice: 125.96,
            priceBefore: 120.0,
            priceNow: 123.13,
          },
          suite: {
            newPrice: 190.14,
            priceBefore: 191.7,
            priceNow: 199.94,
          },
        },
      },
      {
        date: '2025-06-09',
        festivos: ['Festividad local de La Huerce'],
        pc_ocupacion: 68.0,
        room: {
          double: {
            newPrice: 153.06,
            priceBefore: 159.3,
            priceNow: 159.18,
          },
          single: {
            newPrice: 118.48,
            priceBefore: 117.9,
            priceNow: 126.78,
          },
          suite: {
            newPrice: 220.12,
            priceBefore: 217.1,
            priceNow: 210.57,
          },
        },
      },
      {
        date: '2025-06-10',
        festivos: [
          'Festividad local de Fuentes',
          'Festividad local de Cobeta',
          'Festividad local de Mantiel',
        ],
        pc_ocupacion: 59.0,
        room: {
          double: {
            newPrice: 142.12,
            priceBefore: 140.4,
            priceNow: 134.08,
          },
          single: {
            newPrice: 91.31,
            priceBefore: 90.67,
            priceNow: 94.65,
          },
          suite: {
            newPrice: 213.63,
            priceBefore: 206.0,
            priceNow: 198.47,
          },
        },
      },
      {
        date: '2025-06-11',
        festivos: [
          'Festividad local de Pétrola',
          'Festividad local de Arenas de San Juan',
          'Festividad local de Cogollor',
          'Festividad local de Hijes',
          'Festividad local de Masegoso de Tajuña',
          'Festividad local de Trijueque',
          'Festividad local de Valdenuño Fernandez',
        ],
        pc_ocupacion: 49.0,
        room: {
          double: {
            newPrice: 143.18,
            priceBefore: 131.0,
            priceNow: 143.93,
          },
          single: {
            newPrice: 80.83,
            priceBefore: 80.24,
            priceNow: 84.11,
          },
          suite: {
            newPrice: 154.16,
            priceBefore: 160.8,
            priceNow: 156.76,
          },
        },
      },
      {
        date: '2025-06-12',
        festivos: [],
        pc_ocupacion: 60.0,
        room: {
          double: {
            newPrice: 145.62,
            priceBefore: 133.95,
            priceNow: 145.36,
          },
          single: {
            newPrice: 117.33,
            priceBefore: 120.0,
            priceNow: 127.81,
          },
          suite: {
            newPrice: 185.16,
            priceBefore: 187.04,
            priceNow: 198.77,
          },
        },
      },
      {
        date: '2025-06-13',
        festivos: [
          'Festividad local de Casas de Vés',
          'Festividad local de Robledo',
          'Festividad local de Alamillo',
          'Festividad local de Anchuras',
          'Festividad local de Torralba de Calatrava',
          'Festividad local de Abia de la Obispalia',
          'Festividad local de Albendea',
          'Festividad local de Arcos de la Sierra',
          'Festividad local de Buciegas',
          'Festividad local de Campillos-Sierra',
          'Festividad local de Cañete',
          'Festividad local de Cardenete',
          'Festividad local de Carrascosa de Haro',
          'Festividad local de Collados',
          'Festividad local de Colliga',
          'Festividad local de El Pozuelo',
          'Festividad local de Fuentelespino de Haro',
          'Festividad local de Huerta del Marquesado',
          'Festividad local de La Frontera',
          'Festividad local de Laguna del Marquesado',
          'Festividad local de Los Valdecolmenas',
          'Festividad local de Mariana',
          'Festividad local de Noheda',
          'Festividad local de Palomera',
          'Festividad local de Portalrubio de Guadamejud',
          'Festividad local de Ribatajadilla',
          'Festividad local de Salmeroncillos',
          'Festividad local de Salvacañete',
          'Festividad local de Sotos',
          'Festividad local de Talayuelas',
          'Festividad local de Valdecañas',
          'Festividad local de Valdemoro Sierra',
          'Festividad local de Valparaiso de Abajo',
          'Festividad local de Villanueva de los Escuderos',
          'Festividad local de Villar del Saz de Arcas',
          'Festividad local de Villaseca',
          'Festividad local de Zarzuela',
          'Festividad local de Albares',
          'Festividad local de Alcolea del Pinar',
          'Festividad local de Anquela del Pedregal',
          'Festividad local de Aranzueque',
          'Festividad local de Armallones',
          'Festividad local de Bañuelos',
          'Festividad local de Caspueñas',
          'Festividad local de Chillaron del Rey',
          'Festividad local de Condemios de Abajo',
          'Festividad local de Cubillejo de la Sierra',
          'Festividad local de El Recuenco',
          'Festividad local de Estables',
          'Festividad local de Gajanejos',
          'Festividad local de Huertahernando',
          'Festividad local de Iniestola',
          'Festividad local de Jirueque',
          'Festividad local de La Miñosa',
          'Festividad local de Miedes de Atienza',
          'Festividad local de Pradena de Atienza',
          'Festividad local de Tordesilos',
          'Festividad local de Torrecuadradilla',
          'Festividad local de Ujados',
          'Festividad local de Viana de Jadraque',
          'Festividad local de Villanueva de Alcorón',
          'Festividad local de Zaorejas',
          'Festividad local de Barcience',
          'Festividad local de Cabezamesada',
          'Festividad local de Cedillo del Condado',
          'Festividad local de Lucillos',
          'Festividad local de Yeles',
        ],
        pc_ocupacion: 59.0,
        room: {
          double: {
            newPrice: 160.97,
            priceBefore: 150.45,
            priceNow: 159.7,
          },
          single: {
            newPrice: 78.43,
            priceBefore: 79.0,
            priceNow: 85.96,
          },
          suite: {
            newPrice: 201.52,
            priceBefore: 206.0,
            priceNow: 208.03,
          },
        },
      },
      {
        date: '2025-06-14',
        festivos: [
          'Festividad local de Agudo',
          'Festividad local de Alcalá de la Vega',
          'Festividad local de Aliaguilla',
          'Festividad local de Fuentelespino de Haro',
          'Festividad local de Monteagudo de las Salinas',
          'Festividad local de Talayuelas',
          'Festividad local de Valdeolivas',
          'Festividad local de Albares',
          'Festividad local de Almadrones',
          'Festividad local de Canales del Ducado',
          'Festividad local de Cogollor',
          'Festividad local de La Miñosa',
          'Festividad local de Luzón',
          'Festividad local de Sotodosos',
          'Festividad local de Barcience',
          'Festividad local de Cabezamesada',
          'Festividad local de Yeles',
        ],
        pc_ocupacion: 56.0,
        room: {
          double: {
            newPrice: 103.85,
            priceBefore: 108.42,
            priceNow: 111.71,
          },
          single: {
            newPrice: 119.4,
            priceBefore: 120.0,
            priceNow: 127.62,
          },
          suite: {
            newPrice: 200.97,
            priceBefore: 187.28,
            priceNow: 197.91,
          },
        },
      },
      {
        date: '2025-06-15',
        festivos: [
          'Festividad local de Alcalá de la Vega',
          'Festividad local de Algora',
          'Festividad local de San Andrés del Congosto',
        ],
        pc_ocupacion: 61.0,
        room: {
          double: {
            newPrice: 167.64,
            priceBefore: 156.0,
            priceNow: 155.31,
          },
          single: {
            newPrice: 112.78,
            priceBefore: 109.0,
            priceNow: 116.82,
          },
          suite: {
            newPrice: 195.04,
            priceBefore: 180.03,
            priceNow: 196.08,
          },
        },
      },
      {
        date: '2025-06-16',
        festivos: [
          'Festividad local de Algora',
          'Festividad local de Valdepinillos',
        ],
        pc_ocupacion: 60.0,
        room: {
          double: {
            newPrice: 134.72,
            priceBefore: 132.6,
            priceNow: 144.48,
          },
          single: {
            newPrice: 87.32,
            priceBefore: 90.0,
            priceNow: 92.36,
          },
          suite: {
            newPrice: 213.56,
            priceBefore: 221.0,
            priceNow: 214.76,
          },
        },
      },
      {
        date: '2025-06-17',
        festivos: [
          'Festividad local de Zarza de Tajo',
          'Festividad local de Almadrones',
          'Festividad local de Valdegrudas',
        ],
        pc_ocupacion: 53.0,
        room: {
          double: {
            newPrice: 131.17,
            priceBefore: 134.1,
            priceNow: 130.98,
          },
          single: {
            newPrice: 75.49,
            priceBefore: 75.0,
            priceNow: 82.35,
          },
          suite: {
            newPrice: 178.07,
            priceBefore: 185.26,
            priceNow: 188.97,
          },
        },
      },
      {
        date: '2025-06-18',
        festivos: [],
        pc_ocupacion: 46.0,
        room: {
          double: {
            newPrice: 94.1,
            priceBefore: 96.3,
            priceNow: 101.84,
          },
          single: {
            newPrice: 81.45,
            priceBefore: 80.75,
            priceNow: 77.78,
          },
          suite: {
            newPrice: 195.5,
            priceBefore: 178.2,
            priceNow: 179.02,
          },
        },
      },
      {
        date: '2025-06-19',
        festivos: ['Corpus Christi'],
        pc_ocupacion: 69.0,
        room: {
          double: {
            newPrice: 118.99,
            priceBefore: 114.0,
            priceNow: 115.52,
          },
          single: {
            newPrice: 106.08,
            priceBefore: 105.4,
            priceNow: 113.02,
          },
          suite: {
            newPrice: 286.55,
            priceBefore: 278.0,
            priceNow: 295.31,
          },
        },
      },
      {
        date: '2025-06-20',
        festivos: ['Festividad local de Concha'],
        pc_ocupacion: 57.0,
        room: {
          double: {
            newPrice: 185.15,
            priceBefore: 177.0,
            priceNow: 168.92,
          },
          single: {
            newPrice: 86.98,
            priceBefore: 80.19,
            priceNow: 77.07,
          },
          suite: {
            newPrice: 158.53,
            priceBefore: 162.35,
            priceNow: 174.88,
          },
        },
      },
      {
        date: '2025-06-21',
        festivos: [
          'Festividad local de Galve de Sorbe',
          'Festividad local de Heras de Ayuso',
          'Festividad local de Villanueva de la Torre',
          'Festividad local de Rielves',
        ],
        pc_ocupacion: 41.0,
        room: {
          double: {
            newPrice: 121.87,
            priceBefore: 113.4,
            priceNow: 109.84,
          },
          single: {
            newPrice: 107.85,
            priceBefore: 99.9,
            priceNow: 99.85,
          },
          suite: {
            newPrice: 114.19,
            priceBefore: 106.78,
            priceNow: 104.82,
          },
        },
      },
      {
        date: '2025-06-22',
        festivos: [
          'Festividad local de Pajares',
          'Festividad local de Las Inviernas',
          'Festividad local de Utande',
        ],
        pc_ocupacion: 44.0,
        room: {
          double: {
            newPrice: 121.23,
            priceBefore: 126.0,
            priceNow: 122.49,
          },
          single: {
            newPrice: 101.53,
            priceBefore: 106.52,
            priceNow: 108.92,
          },
          suite: {
            newPrice: 119.45,
            priceBefore: 110.0,
            priceNow: 105.92,
          },
        },
      },
      {
        date: '2025-06-23',
        festivos: [],
        pc_ocupacion: 53.0,
        room: {
          double: {
            newPrice: 135.25,
            priceBefore: 128.0,
            priceNow: 131.54,
          },
          single: {
            newPrice: 104.9,
            priceBefore: 97.0,
            priceNow: 100.14,
          },
          suite: {
            newPrice: 171.23,
            priceBefore: 171.9,
            priceNow: 172.25,
          },
        },
      },
      {
        date: '2025-06-24',
        festivos: [
          'Festividad local de Alatoz',
          'Festividad local de Albacete',
          'Festividad local de Alcalá del Júcar',
          'Festividad local de Cotillas',
          'Festividad local de El Bonillo',
          'Festividad local de Pozo Cañada',
          'Festividad local de Pozohondo',
          'Festividad local de Riópar',
          'Festividad local de Abenójar',
          'Festividad local de Horcajo de los Montes',
          'Festividad local de Villar del Pozo',
          'Festividad local de Villarta San Juan',
          'Festividad local de Casas de Garcimolina',
          'Festividad local de Colliguilla',
          'Festividad local de Fuentesclaras Chillaron',
          'Festividad local de Ribatajada',
          'Festividad local de Tragacete',
          'Festividad local de Villaconejos de Trabaque',
          'Festividad local de Alhóndiga',
          'Festividad local de Ledanca',
          'Festividad local de Majaelrayo',
          'Festividad local de Matillas',
          'Festividad local de Medranda',
          'Festividad local de Pinilla de Molina',
          'Festividad local de Puebla de Beleña',
          'Festividad local de Taragudo',
          'Festividad local de Tobillos',
          'Festividad local de Valdeaveruelo',
          'Festividad local de Yebra',
          'Festividad local de Camarena',
          'Festividad local de Huecas',
          'Festividad local de La Mata',
          'Festividad local de Marjaliza',
          'Festividad local de Palomeque',
          'Festividad local de Puente del Arzobispo',
          'Festividad local de Ugena',
        ],
        pc_ocupacion: 51.0,
        room: {
          double: {
            newPrice: 118.76,
            priceBefore: 108.49,
            priceNow: 114.32,
          },
          single: {
            newPrice: 96.32,
            priceBefore: 95.0,
            priceNow: 102.01,
          },
          suite: {
            newPrice: 175.95,
            priceBefore: 182.83,
            priceNow: 186.15,
          },
        },
      },
      {
        date: '2025-06-25',
        festivos: ['Festividad local de Pozohondo'],
        pc_ocupacion: 57.0,
        room: {
          double: {
            newPrice: 146.6,
            priceBefore: 138.93,
            priceNow: 139.96,
          },
          single: {
            newPrice: 79.5,
            priceBefore: 79.29,
            priceNow: 77.65,
          },
          suite: {
            newPrice: 212.54,
            priceBefore: 208.13,
            priceNow: 226.64,
          },
        },
      },
      {
        date: '2025-06-26',
        festivos: [],
        pc_ocupacion: 61.0,
        room: {
          double: {
            newPrice: 147.41,
            priceBefore: 152.67,
            priceNow: 147.01,
          },
          single: {
            newPrice: 111.89,
            priceBefore: 102.3,
            priceNow: 100.76,
          },
          suite: {
            newPrice: 192.48,
            priceBefore: 193.73,
            priceNow: 204.05,
          },
        },
      },
      {
        date: '2025-06-27',
        festivos: ['Festividad local de Fuentenovilla'],
        pc_ocupacion: 49.0,
        room: {
          double: {
            newPrice: 117.03,
            priceBefore: 121.55,
            priceNow: 133.21,
          },
          single: {
            newPrice: 76.48,
            priceBefore: 70.44,
            priceNow: 72.16,
          },
          suite: {
            newPrice: 172.93,
            priceBefore: 180.9,
            priceNow: 196.64,
          },
        },
      },
      {
        date: '2025-06-28',
        festivos: [
          'Festividad local de San Pedro',
          'Festividad local de Bujalaro',
          'Festividad local de Polán',
        ],
        pc_ocupacion: 49.0,
        room: {
          double: {
            newPrice: 131.68,
            priceBefore: 121.55,
            priceNow: 116.96,
          },
          single: {
            newPrice: 79.82,
            priceBefore: 76.5,
            priceNow: 74.63,
          },
          suite: {
            newPrice: 187.23,
            priceBefore: 177.3,
            priceNow: 194.67,
          },
        },
      },
      {
        date: '2025-06-29',
        festivos: [
          'Festividad local de Bascuñana de San Pedro',
          'Festividad local de Loranca del Campo',
          'Festividad local de El Pedregal',
          'Festividad local de Polán',
        ],
        pc_ocupacion: 61.0,
        room: {
          double: {
            newPrice: 120.66,
            priceBefore: 110.8,
            priceNow: 110.68,
          },
          single: {
            newPrice: 122.68,
            priceBefore: 127.0,
            priceNow: 136.71,
          },
          suite: {
            newPrice: 209.83,
            priceBefore: 214.0,
            priceNow: 218.53,
          },
        },
      },
      {
        date: '2025-06-30',
        festivos: [],
        pc_ocupacion: 53.0,
        room: {
          double: {
            newPrice: 130.06,
            priceBefore: 126.86,
            priceNow: 138.32,
          },
          single: {
            newPrice: 85.98,
            priceBefore: 85.5,
            priceNow: 85.92,
          },
          suite: {
            newPrice: 186.56,
            priceBefore: 186.3,
            priceNow: 200.99,
          },
        },
      },
      {
        date: '2025-07-01',
        festivos: [
          'Festividad local de San Pedro',
          'Festividad local de Chequilla',
        ],
        pc_ocupacion: 60.0,
        room: {
          double: {
            newPrice: 177.22,
            priceBefore: 165.0,
            priceNow: 172.88,
          },
          single: {
            newPrice: 115.21,
            priceBefore: 107.1,
            priceNow: 108.04,
          },
          suite: {
            newPrice: 167.55,
            priceBefore: 168.3,
            priceNow: 174.46,
          },
        },
      },
      {
        date: '2025-07-02',
        festivos: ['Festividad local de Valdemeca'],
        pc_ocupacion: 58.0,
        room: {
          double: {
            newPrice: 134.82,
            priceBefore: 126.65,
            priceNow: 131.11,
          },
          single: {
            newPrice: 119.87,
            priceBefore: 119.7,
            priceNow: 124.61,
          },
          suite: {
            newPrice: 187.17,
            priceBefore: 185.03,
            priceNow: 191.72,
          },
        },
      },
      {
        date: '2025-07-03',
        festivos: [
          'Festividad local de Valdemeca',
          'Festividad local de Escamilla',
        ],
        pc_ocupacion: 62.0,
        room: {
          double: {
            newPrice: 154.07,
            priceBefore: 156.5,
            priceNow: 154.04,
          },
          single: {
            newPrice: 113.86,
            priceBefore: 111.68,
            priceNow: 114.44,
          },
          suite: {
            newPrice: 181.95,
            priceBefore: 186.3,
            priceNow: 200.94,
          },
        },
      },
      {
        date: '2025-07-04',
        festivos: [],
        pc_ocupacion: 57.0,
        room: {
          double: {
            newPrice: 133.83,
            priceBefore: 137.7,
            priceNow: 137.47,
          },
          single: {
            newPrice: 121.31,
            priceBefore: 116.92,
            priceNow: 111.65,
          },
          suite: {
            newPrice: 175.34,
            priceBefore: 168.3,
            priceNow: 176.36,
          },
        },
      },
      {
        date: '2025-07-05',
        festivos: ['Festividad local de Puerto Rey'],
        pc_ocupacion: 61.0,
        room: {
          double: {
            newPrice: 182.55,
            priceBefore: 175.0,
            priceNow: 191.36,
          },
          single: {
            newPrice: 105.66,
            priceBefore: 104.72,
            priceNow: 104.0,
          },
          suite: {
            newPrice: 174.51,
            priceBefore: 167.45,
            priceNow: 174.19,
          },
        },
      },
      {
        date: '2025-07-06',
        festivos: [],
        pc_ocupacion: 64.0,
        room: {
          double: {
            newPrice: 112.82,
            priceBefore: 118.46,
            priceNow: 126.79,
          },
          single: {
            newPrice: 128.4,
            priceBefore: 116.95,
            priceNow: 117.47,
          },
          suite: {
            newPrice: 247.53,
            priceBefore: 231.0,
            priceNow: 250.13,
          },
        },
      },
      {
        date: '2025-07-07',
        festivos: [],
        pc_ocupacion: 53.0,
        room: {
          double: {
            newPrice: 140.94,
            priceBefore: 146.7,
            priceNow: 160.28,
          },
          single: {
            newPrice: 92.41,
            priceBefore: 86.75,
            priceNow: 82.66,
          },
          suite: {
            newPrice: 160.54,
            priceBefore: 168.3,
            priceNow: 180.32,
          },
        },
      },
      {
        date: '2025-07-08',
        festivos: [
          'Festividad local de Ballesteros de Calatrava',
          'Festividad local de Canredondo',
          'Festividad local de Puerto Rey',
        ],
        pc_ocupacion: 74.0,
        room: {
          double: {
            newPrice: 123.53,
            priceBefore: 127.8,
            priceNow: 124.32,
          },
          single: {
            newPrice: 141.12,
            priceBefore: 139.0,
            priceNow: 141.04,
          },
          suite: {
            newPrice: 276.24,
            priceBefore: 265.0,
            priceNow: 271.52,
          },
        },
      },
      {
        date: '2025-07-09',
        festivos: ['Festividad local de Luzaga'],
        pc_ocupacion: 60.0,
        room: {
          double: {
            newPrice: 159.84,
            priceBefore: 146.7,
            priceNow: 139.4,
          },
          single: {
            newPrice: 102.71,
            priceBefore: 105.83,
            priceNow: 114.94,
          },
          suite: {
            newPrice: 187.48,
            priceBefore: 189.9,
            priceNow: 184.43,
          },
        },
      },
      {
        date: '2025-07-10',
        festivos: ['Festividad local de Gascueña', 'Festividad local de Orea'],
        pc_ocupacion: 72.0,
        room: {
          double: {
            newPrice: 138.91,
            priceBefore: 142.2,
            priceNow: 146.58,
          },
          single: {
            newPrice: 158.42,
            priceBefore: 144.9,
            priceNow: 144.31,
          },
          suite: {
            newPrice: 236.29,
            priceBefore: 229.0,
            priceNow: 226.94,
          },
        },
      },
      {
        date: '2025-07-11',
        festivos: [],
        pc_ocupacion: 71.0,
        room: {
          double: {
            newPrice: 136.53,
            priceBefore: 127.82,
            priceNow: 128.77,
          },
          single: {
            newPrice: 178.83,
            priceBefore: 170.1,
            priceNow: 166.44,
          },
          suite: {
            newPrice: 228.38,
            priceBefore: 217.11,
            priceNow: 208.99,
          },
        },
      },
      {
        date: '2025-07-12',
        festivos: [
          'Festividad local de Manzanares',
          'Festividad local de Robledo del Buey',
        ],
        pc_ocupacion: 59.0,
        room: {
          double: {
            newPrice: 144.54,
            priceBefore: 137.7,
            priceNow: 141.65,
          },
          single: {
            newPrice: 128.76,
            priceBefore: 121.5,
            priceNow: 117.08,
          },
          suite: {
            newPrice: 178.6,
            priceBefore: 177.3,
            priceNow: 170.24,
          },
        },
      },
      {
        date: '2025-07-13',
        festivos: [],
        pc_ocupacion: 59.0,
        room: {
          double: {
            newPrice: 91.84,
            priceBefore: 91.48,
            priceNow: 98.94,
          },
          single: {
            newPrice: 81.18,
            priceBefore: 81.08,
            priceNow: 78.19,
          },
          suite: {
            newPrice: 262.13,
            priceBefore: 265.44,
            priceNow: 264.78,
          },
        },
      },
      {
        date: '2025-07-14',
        festivos: [],
        pc_ocupacion: 61.0,
        room: {
          double: {
            newPrice: 155.26,
            priceBefore: 149.4,
            priceNow: 144.83,
          },
          single: {
            newPrice: 119.9,
            priceBefore: 121.5,
            priceNow: 125.71,
          },
          suite: {
            newPrice: 179.05,
            priceBefore: 177.3,
            priceNow: 177.1,
          },
        },
      },
      {
        date: '2025-07-15',
        festivos: [
          'Festividad local de Pinilla de Molina',
          'Festividad local de Azután',
          'Festividad local de Yuncler de la Sagra',
        ],
        pc_ocupacion: 66.0,
        room: {
          double: {
            newPrice: 134.55,
            priceBefore: 137.7,
            priceNow: 145.76,
          },
          single: {
            newPrice: 114.7,
            priceBefore: 112.32,
            priceNow: 111.88,
          },
          suite: {
            newPrice: 250.72,
            priceBefore: 231.0,
            priceNow: 228.95,
          },
        },
      },
      {
        date: '2025-07-16',
        festivos: [
          'Festividad local de Liétor',
          'Festividad local de Almodóvar del Campo',
          'Festividad local de Arenas de San Juan',
          'Festividad local de Pozuelo de Calatrava',
          'Festividad local de Carrascosa de la Sierra',
          'Festividad local de Colliguilla',
          'Festividad local de Tejadillos',
          'Festividad local de Berninches',
          'Festividad local de Molina de Aragón',
          'Festividad local de La Fresneda de la Jara',
          'Festividad local de La Puebla de Montalbán',
          'Festividad local de Las Ventas de Retamosa',
          'Festividad local de Manzaneque',
          'Festividad local de Mocejón',
          'Festividad local de Nambroca',
          'Festividad local de Santa Olalla',
          'Festividad local de Yuncler de la Sagra',
          'Festividad local de Yunclillos',
        ],
        pc_ocupacion: 54.0,
        room: {
          double: {
            newPrice: 116.07,
            priceBefore: 118.8,
            priceNow: 128.99,
          },
          single: {
            newPrice: 98.78,
            priceBefore: 99.95,
            priceNow: 99.15,
          },
          suite: {
            newPrice: 180.89,
            priceBefore: 186.3,
            priceNow: 197.26,
          },
        },
      },
      {
        date: '2025-07-17',
        festivos: [],
        pc_ocupacion: 76.0,
        room: {
          double: {
            newPrice: 146.22,
            priceBefore: 139.7,
            priceNow: 146.98,
          },
          single: {
            newPrice: 110.27,
            priceBefore: 105.3,
            priceNow: 104.52,
          },
          suite: {
            newPrice: 312.09,
            priceBefore: 299.33,
            priceNow: 306.04,
          },
        },
      },
      {
        date: '2025-07-18',
        festivos: ['Festividad local de Magán'],
        pc_ocupacion: 44.0,
        room: {
          double: {
            newPrice: 91.41,
            priceBefore: 91.45,
            priceNow: 88.81,
          },
          single: {
            newPrice: 93.21,
            priceBefore: 90.95,
            priceNow: 95.34,
          },
          suite: {
            newPrice: 151.43,
            priceBefore: 158.95,
            priceNow: 154.72,
          },
        },
      },
      {
        date: '2025-07-19',
        festivos: [
          'Festividad local de Villarejo Sobrehuerta',
          'Festividad local de Cabanillas del Campo',
          'Festividad local de Chozas de Canales',
          'Festividad local de Noblejas',
        ],
        pc_ocupacion: 51.0,
        room: {
          double: {
            newPrice: 126.98,
            priceBefore: 126.0,
            priceNow: 120.2,
          },
          single: {
            newPrice: 83.4,
            priceBefore: 85.0,
            priceNow: 88.65,
          },
          suite: {
            newPrice: 186.51,
            priceBefore: 177.3,
            priceNow: 177.07,
          },
        },
      },
      {
        date: '2025-07-20',
        festivos: ['Festividad local de Cendejas de Enmedio'],
        pc_ocupacion: 66.0,
        room: {
          double: {
            newPrice: 174.32,
            priceBefore: 162.0,
            priceNow: 167.65,
          },
          single: {
            newPrice: 103.03,
            priceBefore: 99.0,
            priceNow: 107.23,
          },
          suite: {
            newPrice: 230.71,
            priceBefore: 221.0,
            priceNow: 231.02,
          },
        },
      },
      {
        date: '2025-07-21',
        festivos: ['Festividad local de Cendejas de Padrastro'],
        pc_ocupacion: 57.0,
        room: {
          double: {
            newPrice: 123.99,
            priceBefore: 123.3,
            priceNow: 121.53,
          },
          single: {
            newPrice: 134.65,
            priceBefore: 131.08,
            priceNow: 127.65,
          },
          suite: {
            newPrice: 172.34,
            priceBefore: 167.95,
            priceNow: 175.71,
          },
        },
      },
      {
        date: '2025-07-22',
        festivos: [
          'Festividad local de Villalgordo del Júcar',
          'Festividad local de Poblete',
          'Festividad local de Huerta del Marquesado',
          'Festividad local de Poyatos',
          'Festividad local de Cendejas de Padrastro',
          'Festividad local de Mondéjar',
          'Festividad local de Valdeavellano',
          'Festividad local de Carranque',
          'Festividad local de El Viso de San Juan',
          'Festividad local de Escalonilla',
          'Festividad local de Garciotum',
          'Festividad local de Guadamur',
          'Festividad local de Layos',
          'Festividad local de Mascaraque',
          'Festividad local de Villamuelas',
          'Festividad local de Villasequilla',
        ],
        pc_ocupacion: 59.0,
        room: {
          double: {
            newPrice: 119.0,
            priceBefore: 123.3,
            priceNow: 134.39,
          },
          single: {
            newPrice: 100.68,
            priceBefore: 94.5,
            priceNow: 96.24,
          },
          suite: {
            newPrice: 206.78,
            priceBefore: 216.9,
            priceNow: 236.2,
          },
        },
      },
      {
        date: '2025-07-23',
        festivos: ['Festividad local de Castillejo de Iniesta'],
        pc_ocupacion: 50.0,
        room: {
          double: {
            newPrice: 99.36,
            priceBefore: 94.51,
            priceNow: 90.84,
          },
          single: {
            newPrice: 136.64,
            priceBefore: 125.1,
            priceNow: 132.77,
          },
          suite: {
            newPrice: 172.61,
            priceBefore: 158.4,
            priceNow: 163.46,
          },
        },
      },
      {
        date: '2025-07-24',
        festivos: [],
        pc_ocupacion: 51.0,
        room: {
          double: {
            newPrice: 142.0,
            priceBefore: 137.7,
            priceNow: 146.82,
          },
          single: {
            newPrice: 87.13,
            priceBefore: 90.51,
            priceNow: 89.54,
          },
          suite: {
            newPrice: 153.88,
            priceBefore: 158.4,
            priceNow: 166.2,
          },
        },
      },
      {
        date: '2025-07-25',
        festivos: [
          'Festividad local de Santa Ana',
          'Festividad local de Carrión de Calatrava',
          'Festividad local de Granátula de Calatrava',
          'Festividad local de La Solana',
          'Festividad local de Membrilla',
          'Festividad local de Viso del Marques',
          'Festividad local de Valsalobre',
          'Festividad local de Campisabalos',
          'Festividad local de Mohernando',
          'Festividad local de Valdelcubo',
          'Festividad local de Alcañizo',
          'Festividad local de Burujón',
          'Festividad local de Carpio de Tajo',
          'Festividad local de Espinoso del Rey',
          'Festividad local de Nombela',
          'Festividad local de Quero',
          'Festividad local de Santo Domingo Caudilla',
          'Festividad local de Sartajada',
          'Festividad local de Sotillo de las Palomas',
        ],
        pc_ocupacion: 44.0,
        room: {
          double: {
            newPrice: 93.71,
            priceBefore: 93.84,
            priceNow: 102.7,
          },
          single: {
            newPrice: 75.55,
            priceBefore: 72.25,
            priceNow: 76.08,
          },
          suite: {
            newPrice: 190.94,
            priceBefore: 177.3,
            priceNow: 186.25,
          },
        },
      },
      {
        date: '2025-07-26',
        festivos: [
          'Festividad local de Motilleja',
          'Festividad local de Pozo Lorente',
          'Festividad local de Santa Ana',
          'Festividad local de Almadén',
          'Festividad local de Cabezarados',
          'Festividad local de Fontanarejo',
          'Festividad local de Carrascosa del Campo',
          'Festividad local de El Pedernoso',
          'Festividad local de Gabaldon',
          'Festividad local de Pineda de Gigüela',
          'Festividad local de Reillo',
          'Festividad local de Valsalobre',
          'Festividad local de Vindel',
          'Festividad local de Alarilla',
          'Festividad local de Campisabalos',
          'Festividad local de Muduex',
          'Festividad local de Torremocha del Campo',
          'Festividad local de Añover de Tajo',
          'Festividad local de Burujón',
          'Festividad local de Camarenilla',
          'Festividad local de Mora',
          'Festividad local de Nombela',
          'Festividad local de Santa Ana de Pusa',
          'Festividad local de Santo Domingo Caudilla',
          'Festividad local de Villanueva de Bogas',
        ],
        pc_ocupacion: 70.0,
        room: {
          double: {
            newPrice: 108.25,
            priceBefore: 103.7,
            priceNow: 102.7,
          },
          single: {
            newPrice: 187.76,
            priceBefore: 178.0,
            priceNow: 188.12,
          },
          suite: {
            newPrice: 230.71,
            priceBefore: 225.2,
            priceNow: 232.62,
          },
        },
      },
      {
        date: '2025-07-27',
        festivos: [
          'Festividad local de Ribatajadilla',
          'Festividad local de Ciruelos del Pinar',
        ],
        pc_ocupacion: 69.0,
        room: {
          double: {
            newPrice: 159.56,
            priceBefore: 149.4,
            priceNow: 163.23,
          },
          single: {
            newPrice: 167.5,
            priceBefore: 169.0,
            priceNow: 168.91,
          },
          suite: {
            newPrice: 182.01,
            priceBefore: 177.3,
            priceNow: 189.8,
          },
        },
      },
      {
        date: '2025-07-28',
        festivos: [],
        pc_ocupacion: 65.0,
        room: {
          double: {
            newPrice: 115.78,
            priceBefore: 111.76,
            priceNow: 107.65,
          },
          single: {
            newPrice: 131.04,
            priceBefore: 122.6,
            priceNow: 118.35,
          },
          suite: {
            newPrice: 254.73,
            priceBefore: 235.63,
            priceNow: 234.21,
          },
        },
      },
      {
        date: '2025-07-29',
        festivos: [
          'Festividad local de Cabezarrubias del Puerto',
          'Festividad local de Mestanza',
          'Festividad local de Solana del Pino',
          'Festividad local de Cobisa',
          'Festividad local de Ontígola',
        ],
        pc_ocupacion: 64.0,
        room: {
          double: {
            newPrice: 125.08,
            priceBefore: 130.05,
            priceNow: 136.0,
          },
          single: {
            newPrice: 122.9,
            priceBefore: 121.0,
            priceNow: 128.6,
          },
          suite: {
            newPrice: 219.91,
            priceBefore: 216.9,
            priceNow: 215.25,
          },
        },
      },
      {
        date: '2025-07-30',
        festivos: [],
        pc_ocupacion: 50.0,
        room: {
          double: {
            newPrice: 105.74,
            priceBefore: 103.7,
            priceNow: 108.54,
          },
          single: {
            newPrice: 97.87,
            priceBefore: 90.0,
            priceNow: 97.02,
          },
          suite: {
            newPrice: 201.97,
            priceBefore: 186.3,
            priceNow: 188.75,
          },
        },
      },
      {
        date: '2025-07-31',
        festivos: ['Festividad local de Tribaldos'],
        pc_ocupacion: 59.0,
        room: {
          double: {
            newPrice: 136.7,
            priceBefore: 141.18,
            priceNow: 146.07,
          },
          single: {
            newPrice: 95.05,
            priceBefore: 96.5,
            priceNow: 104.85,
          },
          suite: {
            newPrice: 213.13,
            priceBefore: 201.57,
            priceNow: 202.09,
          },
        },
      },
      {
        date: '2025-08-01',
        festivos: [
          'Festividad local de San Pedro Palmiches',
          'Festividad local de Villalpardo',
          'Festividad local de Las Ventas de Retamosa',
        ],
        pc_ocupacion: 57.0,
        room: {
          double: {
            newPrice: 119.12,
            priceBefore: 120.3,
            priceNow: 128.9,
          },
          single: {
            newPrice: 139.48,
            priceBefore: 130.5,
            priceNow: 130.3,
          },
          suite: {
            newPrice: 168.57,
            priceBefore: 169.88,
            priceNow: 185.37,
          },
        },
      },
      {
        date: '2025-08-02',
        festivos: [
          'Festividad local de Calzada de Calatrava',
          'Festividad local de Pedro Muñoz',
          'Festividad local de Buciegas',
          'Festividad local de La Huerguina',
          'Festividad local de Villar del Águila',
          'Festividad local de Yemeda',
          'Festividad local de Amayas',
          'Festividad local de El Olivar',
          'Festividad local de El Ordial',
          'Festividad local de Escopete',
          'Festividad local de La Bodera',
          'Festividad local de Renera',
          'Festividad local de Robledo de Corpes',
          'Festividad local de Torre del Burgo',
          'Festividad local de Valdesotos',
          'Festividad local de Argés',
          'Festividad local de Los Cerralbos',
          'Festividad local de Montearagón',
        ],
        pc_ocupacion: 69.0,
        room: {
          double: {
            newPrice: 170.77,
            priceBefore: 166.7,
            priceNow: 176.63,
          },
          single: {
            newPrice: 130.93,
            priceBefore: 130.5,
            priceNow: 139.94,
          },
          suite: {
            newPrice: 218.46,
            priceBefore: 198.98,
            priceNow: 211.48,
          },
        },
      },
      {
        date: '2025-08-03',
        festivos: [
          'Festividad local de Amayas',
          'Festividad local de El Olivar',
          'Festividad local de Gualda',
          'Festividad local de La Bodera',
          'Festividad local de Bargas',
        ],
        pc_ocupacion: 66.0,
        room: {
          double: {
            newPrice: 145.16,
            priceBefore: 139.95,
            priceNow: 146.84,
          },
          single: {
            newPrice: 153.21,
            priceBefore: 152.1,
            priceNow: 145.25,
          },
          suite: {
            newPrice: 200.68,
            priceBefore: 186.3,
            priceNow: 192.62,
          },
        },
      },
      {
        date: '2025-08-04',
        festivos: [
          'Festividad local de Algar de Mesa',
          'Festividad local de Bañuelos',
          'Festividad local de Loranca de Tajuña',
          'Festividad local de Olmeda de Cobeta',
          'Festividad local de Somolinos',
          'Festividad local de Valdepinillos',
        ],
        pc_ocupacion: 49.0,
        room: {
          double: {
            newPrice: 133.51,
            priceBefore: 129.7,
            priceNow: 130.31,
          },
          single: {
            newPrice: 72.83,
            priceBefore: 72.25,
            priceNow: 72.02,
          },
          suite: {
            newPrice: 183.64,
            priceBefore: 168.3,
            priceNow: 168.1,
          },
        },
      },
      {
        date: '2025-08-05',
        festivos: [
          'Festividad local de Cenizate',
          'Festividad local de Chinchilla',
          'Festividad local de Almagro',
          'Festividad local de Valdepeñas',
          'Festividad local de Almodóvar del Pinar',
          'Festividad local de Tejadillos',
          'Festividad local de Torralba',
          'Festividad local de Villanueva de la Jara',
          'Festividad local de Anguita',
          'Festividad local de Membrillera',
          'Festividad local de Ocentejo',
          'Festividad local de Renera',
          'Festividad local de Valdesotos',
          'Festividad local de Valtablado de Rio',
          'Festividad local de Lagartera',
          'Festividad local de Malpica de Tajo',
          'Festividad local de Pulgar',
          'Festividad local de Quero',
          'Festividad local de Torralba de Oropesa',
        ],
        pc_ocupacion: 61.0,
        room: {
          double: {
            newPrice: 191.38,
            priceBefore: 186.0,
            priceNow: 201.38,
          },
          single: {
            newPrice: 105.56,
            priceBefore: 96.3,
            priceNow: 97.33,
          },
          suite: {
            newPrice: 178.56,
            priceBefore: 168.3,
            priceNow: 161.25,
          },
        },
      },
      {
        date: '2025-08-06',
        festivos: [
          'Festividad local de La Roda',
          'Festividad local de Socovos',
          'Festividad local de Pozuelo de Calatrava',
          'Festividad local de Olmedilla de Eliz',
          'Festividad local de Huérmeces del Cerro',
          'Festividad local de Málaga del Fresno',
          'Festividad local de Montarrón',
          'Festividad local de Puebla de Valles',
          'Festividad local de Santiuste',
          'Festividad local de Torralba de Oropesa',
        ],
        pc_ocupacion: 64.0,
        room: {
          double: {
            newPrice: 132.35,
            priceBefore: 121.21,
            priceNow: 133.08,
          },
          single: {
            newPrice: 175.87,
            priceBefore: 171.0,
            priceNow: 170.05,
          },
          suite: {
            newPrice: 192.13,
            priceBefore: 177.3,
            priceNow: 170.92,
          },
        },
      },
      {
        date: '2025-08-07',
        festivos: [
          'Festividad local de Tresjuncos',
          'Festividad local de Pradena de Atienza',
          'Festividad local de Somolinos',
          'Festividad local de Tordellego',
        ],
        pc_ocupacion: 49.0,
        room: {
          double: {
            newPrice: 92.73,
            priceBefore: 96.56,
            priceNow: 104.03,
          },
          single: {
            newPrice: 96.54,
            priceBefore: 98.0,
            priceNow: 100.39,
          },
          suite: {
            newPrice: 177.62,
            priceBefore: 177.3,
            priceNow: 176.67,
          },
        },
      },
      {
        date: '2025-08-08',
        festivos: [
          'Festividad local de Terrinches',
          'Festividad local de Moya',
          'Festividad local de Ocentejo',
          'Festividad local de Torremocha del Pinar',
          'Festividad local de Valtablado de Rio',
          'Festividad local de Valmojado',
        ],
        pc_ocupacion: 69.0,
        room: {
          double: {
            newPrice: 150.17,
            priceBefore: 137.7,
            priceNow: 149.24,
          },
          single: {
            newPrice: 168.51,
            priceBefore: 171.0,
            priceNow: 183.24,
          },
          suite: {
            newPrice: 189.79,
            priceBefore: 186.3,
            priceNow: 181.59,
          },
        },
      },
      {
        date: '2025-08-09',
        festivos: [
          'Festividad local de El Ballestero',
          'Festividad local de San Lorenzo de Calatrava',
          'Festividad local de Santa Cruz de Mudela',
          'Festividad local de Casas de Garcimolina',
          'Festividad local de Mota de Altarejos',
          'Festividad local de Naharros',
          'Festividad local de Adobes',
          'Festividad local de Albendiego',
          'Festividad local de Castellar de la Muela',
          'Festividad local de Peñalén',
          'Festividad local de Riofrio del Llano',
          'Festividad local de Tortuero',
          'Festividad local de Viñuelas',
          'Festividad local de Campillo de La Jara',
          'Festividad local de Parrillas',
        ],
        pc_ocupacion: 57.0,
        room: {
          double: {
            newPrice: 82.25,
            priceBefore: 82.45,
            priceNow: 86.36,
          },
          single: {
            newPrice: 151.84,
            priceBefore: 144.9,
            priceNow: 142.22,
          },
          suite: {
            newPrice: 198.67,
            priceBefore: 198.9,
            priceNow: 191.72,
          },
        },
      },
      {
        date: '2025-08-10',
        festivos: [
          'Festividad local de Socuéllamos',
          'Festividad local de Arguisuelas',
          'Festividad local de Bustares',
          'Festividad local de Carrascosa de Henares',
          'Festividad local de Negredo',
          'Festividad local de Riofrio del Llano',
          'Festividad local de Romanillos de Atienza',
          'Festividad local de Torremochuela',
        ],
        pc_ocupacion: 63.0,
        room: {
          double: {
            newPrice: 109.52,
            priceBefore: 107.57,
            priceNow: 108.29,
          },
          single: {
            newPrice: 86.46,
            priceBefore: 90.9,
            priceNow: 87.38,
          },
          suite: {
            newPrice: 253.62,
            priceBefore: 259.2,
            priceNow: 276.31,
          },
        },
      },
      {
        date: '2025-08-11',
        festivos: [
          'Festividad local de Anchuela del Campo',
          'Festividad local de Negredo',
        ],
        pc_ocupacion: 62.0,
        room: {
          double: {
            newPrice: 179.23,
            priceBefore: 185.0,
            priceNow: 200.09,
          },
          single: {
            newPrice: 92.0,
            priceBefore: 85.0,
            priceNow: 88.53,
          },
          suite: {
            newPrice: 177.06,
            priceBefore: 186.3,
            priceNow: 193.83,
          },
        },
      },
      {
        date: '2025-08-12',
        festivos: [
          'Festividad local de Chillón',
          'Festividad local de Beamud',
          'Festividad local de Cervera del Llano',
          'Festividad local de Mota de Altarejos',
          'Festividad local de Mota del Cuervo',
          'Festividad local de San Lorenzo de la Parrilla',
          'Festividad local de Villarrubio',
          'Festividad local de Arbeteta',
          'Festividad local de Campillo de Ranas',
          'Festividad local de Checa',
          'Festividad local de Concha',
          'Festividad local de Gárgoles de Abajo',
          'Festividad local de Hinojosa',
          'Festividad local de Mantiel',
          'Festividad local de Peralveche',
        ],
        pc_ocupacion: 62.0,
        room: {
          double: {
            newPrice: 75.46,
            priceBefore: 72.75,
            priceNow: 75.55,
          },
          single: {
            newPrice: 181.16,
            priceBefore: 178.65,
            priceNow: 179.07,
          },
          suite: {
            newPrice: 203.67,
            priceBefore: 202.74,
            priceNow: 204.24,
          },
        },
      },
      {
        date: '2025-08-13',
        festivos: [
          'Festividad local de Beamud',
          'Festividad local de Castillo de Garcimuñoz',
          'Festividad local de Villarrubio',
          'Festividad local de Estables',
          'Festividad local de Terzaga',
        ],
        pc_ocupacion: 62.0,
        room: {
          double: {
            newPrice: 140.65,
            priceBefore: 145.2,
            priceNow: 152.61,
          },
          single: {
            newPrice: 100.4,
            priceBefore: 105.3,
            priceNow: 103.86,
          },
          suite: {
            newPrice: 209.18,
            priceBefore: 205.38,
            priceNow: 205.81,
          },
        },
      },
      {
        date: '2025-08-14',
        festivos: [
          'Festividad local de Jorquera',
          'Festividad local de Castillo de Garcimuñoz',
          'Festividad local de San Martin de Boniches',
          'Festividad local de Uña',
          'Festividad local de Campillo de Ranas',
          'Festividad local de Congostrina',
          'Festividad local de La Mierla',
          'Festividad local de Las Navas de Jadraque',
          'Festividad local de Prados Redondos',
          'Festividad local de Rebollosa de Jadraque',
          'Festividad local de Retiendas',
          'Festividad local de Sayatón',
          'Festividad local de Terzaga',
          'Festividad local de Uceda',
          'Festividad local de Alameda de la Sagra',
          'Festividad local de Bernuy',
          'Festividad local de Fuentes',
          'Festividad local de La Estrella',
          'Festividad local de Romeral',
        ],
        pc_ocupacion: 69.0,
        room: {
          double: {
            newPrice: 175.97,
            priceBefore: 168.3,
            priceNow: 166.0,
          },
          single: {
            newPrice: 128.4,
            priceBefore: 132.05,
            priceNow: 134.87,
          },
          suite: {
            newPrice: 216.54,
            priceBefore: 198.9,
            priceNow: 201.02,
          },
        },
      },
      {
        date: '2025-08-15',
        festivos: [
          'Asunción de la Virgen',
          'Festividad local de Hontecillas',
          'Festividad local de Anchuela del Pedregal',
          'Festividad local de Arbeteta',
          'Festividad local de Gascueña de Bornova',
          'Festividad local de Peralveche',
          'Festividad local de Tordelpalo',
        ],
        pc_ocupacion: 68.0,
        room: {
          double: {
            newPrice: 172.6,
            priceBefore: 168.3,
            priceNow: 181.09,
          },
          single: {
            newPrice: 114.47,
            priceBefore: 115.32,
            priceNow: 111.35,
          },
          suite: {
            newPrice: 209.25,
            priceBefore: 207.9,
            priceNow: 221.58,
          },
        },
      },
      {
        date: '2025-08-16',
        festivos: [
          'Festividad local de Barrax',
          'Festividad local de Bormate',
          'Festividad local de Fuentealbilla',
          'Festividad local de Letur',
          'Festividad local de Salobre',
          'Festividad local de Tobarra',
          'Festividad local de Villalgordo del Júcar',
          'Festividad local de Villarrobledo',
          'Festividad local de Villatoya',
          'Festividad local de Alamillo',
          'Festividad local de Alcolea de Calatrava',
          'Festividad local de Moral de Calatrava',
          'Festividad local de Torre de Juan Abad',
          'Festividad local de Aliaguilla',
          'Festividad local de Arcos de la Cantera',
          'Festividad local de Barbalimpia',
          'Festividad local de Bascuñana de San Pedro',
          'Festividad local de Bolliga',
          'Festividad local de Boniches',
          'Festividad local de Buenache de la Sierra',
          'Festividad local de Campillos Paravientos',
          'Festividad local de Cañamares',
          'Festividad local de Carboneras de Guadazaon',
          'Festividad local de Castillejo-Sierra',
          'Festividad local de El Provencio',
          'Festividad local de Enguidanos',
          'Festividad local de Fuentesbuenas',
          'Festividad local de Garcinarro',
          'Festividad local de Henarejos',
          'Festividad local de La Pesquera',
          'Festividad local de Lagunaseca',
          'Festividad local de Landete',
          'Festividad local de Ledaña',
          'Festividad local de Monteagudo de las Salinas',
          'Festividad local de Navalon',
          'Festividad local de Olivares del Jucar',
          'Festividad local de Olmedilla de Alarcon',
          'Festividad local de Olmedilla del Campo',
          'Festividad local de Pajaroncillo',
          'Festividad local de Portalrubio de Guadamejud',
          'Festividad local de Portilla',
          'Festividad local de Poyatos',
          'Festividad local de Rada de Haro',
          'Festividad local de Reillo',
          'Festividad local de Salinas del Manzano',
          'Festividad local de Sotoca',
          'Festividad local de Tondos',
          'Festividad local de Torrecilla',
          'Festividad local de Uña',
          'Festividad local de Valdecabras',
          'Festividad local de Valdemorillo de la Sierra',
          'Festividad local de Valeria',
          'Festividad local de Villaescusa de Haro',
          'Festividad local de Villalba de la Sierra',
          'Festividad local de Villanueva de los Escuderos',
          'Festividad local de Villar de la Encina',
          'Festividad local de Villar del Infantado',
          'Festividad local de Villarejo del Espartal',
          'Festividad local de Villarejo Seco',
          'Festividad local de Villarta',
          'Festividad local de Villora',
          'Festividad local de Vindel',
          'Festividad local de Alcocer',
          'Festividad local de Alcolea de las Peñas',
          'Festividad local de Alcolea del Pinar',
          'Festividad local de Aldeanueva de Atienza',
          'Festividad local de Aldeanueva de Guadalajara',
          'Festividad local de Alique',
          'Festividad local de Alocén',
          'Festividad local de Anquela del Pedregal',
          'Festividad local de Baides',
          'Festividad local de Brihuega',
          'Festividad local de Bujarrabal',
          'Festividad local de Bustares',
          'Festividad local de Canales del Ducado',
          'Festividad local de Cincovillas',
          'Festividad local de Cívica',
          'Festividad local de Condemios de Arriba',
          'Festividad local de Copernal',
          'Festividad local de Durón',
          'Festividad local de El Sotillo',
          'Festividad local de Fontanar',
          'Festividad local de Fuentelsaz',
          'Festividad local de Fuentelviejo',
          'Festividad local de Gárgoles de Abajo',
          'Festividad local de Gascueña de Bornova',
          'Festividad local de Illana',
          'Festividad local de Las Navas de Jadraque',
          'Festividad local de Mazarete',
          'Festividad local de Megina',
          'Festividad local de Olmeda de Cobeta',
          'Festividad local de Piqueras',
          'Festividad local de Poveda de la Sierra',
          'Festividad local de Rebollosa de Jadraque',
          'Festividad local de San Andrés del Congosto',
          'Festividad local de San Andrés del Rey',
          'Festividad local de Sayatón',
          'Festividad local de Selas',
          'Festividad local de Semillas',
          'Festividad local de Sienes',
          'Festividad local de Sigüenza',
          'Festividad local de Tierzo',
          'Festividad local de Tordelpalo',
          'Festividad local de Tordelrábano',
          'Festividad local de Torrejón del Rey',
          'Festividad local de Uceda',
          'Festividad local de Valdearenas',
          'Festividad local de Yélamos de Arriba',
          'Festividad local de Zarzuela de Jadraque',
          'Festividad local de Alameda de la Sagra',
          'Festividad local de Camarena',
          'Festividad local de Camuñas',
          'Festividad local de Casar de Escalona',
          'Festividad local de Cervera de los Montes',
          'Festividad local de Erustes',
          'Festividad local de Escalona',
          'Festividad local de Las Navillas',
          'Festividad local de Los Alares',
          'Festividad local de Magán',
          'Festividad local de Mazarambroz',
          'Festividad local de Mohedas de La Jara',
          'Festividad local de Navalcán',
          'Festividad local de Nuño Gómez',
          'Festividad local de Palomeque',
          'Festividad local de Quintanar de la Orden',
          'Festividad local de Quismondo',
          'Festividad local de San Román de los Montes',
          'Festividad local de Seseña',
          'Festividad local de Valdeverdeja',
        ],
        pc_ocupacion: 63.0,
        room: {
          double: {
            newPrice: 126.22,
            priceBefore: 124.2,
            priceNow: 129.65,
          },
          single: {
            newPrice: 153.23,
            priceBefore: 150.0,
            priceNow: 159.85,
          },
          suite: {
            newPrice: 189.05,
            priceBefore: 186.3,
            priceNow: 186.14,
          },
        },
      },
      {
        date: '2025-08-17',
        festivos: [
          'Festividad local de Hijes',
          'Festividad local de Mazarete',
          'Festividad local de Piqueras',
          'Festividad local de Poveda de la Sierra',
          'Festividad local de Taravilla',
        ],
        pc_ocupacion: 53.0,
        room: {
          double: {
            newPrice: 148.68,
            priceBefore: 149.0,
            priceNow: 153.15,
          },
          single: {
            newPrice: 81.0,
            priceBefore: 82.13,
            priceNow: 86.33,
          },
          suite: {
            newPrice: 175.33,
            priceBefore: 170.28,
            priceNow: 166.74,
          },
        },
      },
      {
        date: '2025-08-18',
        festivos: [
          'Festividad local de Castilnuevo',
          'Festividad local de El Ordial',
          'Festividad local de Estriegana',
          'Festividad local de La Toba',
          'Festividad local de Taravilla',
          'Festividad local de Torrecuadrada de Molina',
        ],
        pc_ocupacion: 54.0,
        room: {
          double: {
            newPrice: 151.53,
            priceBefore: 140.7,
            priceNow: 136.43,
          },
          single: {
            newPrice: 107.17,
            priceBefore: 97.54,
            priceNow: 98.58,
          },
          suite: {
            newPrice: 165.16,
            priceBefore: 168.3,
            priceNow: 164.76,
          },
        },
      },
      {
        date: '2025-08-19',
        festivos: [
          'Festividad local de Nerpio',
          'Festividad local de Membrilla',
          'Festividad local de El Provencio',
          'Festividad local de Moya',
          'Festividad local de Tinajas',
          'Festividad local de Torrubia del Castillo',
          'Festividad local de Villar de Domingo Garcia',
          'Festividad local de Alocén',
          'Festividad local de Estriegana',
          'Festividad local de Labros',
          'Festividad local de Tamajón',
          'Festividad local de Esquivias',
          'Festividad local de Retamoso de la Jara',
          'Festividad local de Valdeverdeja',
        ],
        pc_ocupacion: 83.0,
        room: {
          double: {
            newPrice: 197.46,
            priceBefore: 180.2,
            priceNow: 175.8,
          },
          single: {
            newPrice: 200.31,
            priceBefore: 187.5,
            priceNow: 184.11,
          },
          suite: {
            newPrice: 226.86,
            priceBefore: 217.6,
            priceNow: 218.2,
          },
        },
      },
      {
        date: '2025-08-20',
        festivos: [
          'Festividad local de Bogarra',
          'Festividad local de Letur',
          'Festividad local de Nerpio',
          'Festividad local de Hinojosas de Calatrava',
          'Festividad local de Campillos-Sierra',
          'Festividad local de La Hinojosa',
          'Festividad local de Torrubia del Castillo',
          'Festividad local de El Pobo de Dueñas',
          'Festividad local de Fuembellida',
          'Festividad local de Tamajón',
          'Festividad local de Esquivias',
        ],
        pc_ocupacion: 65.0,
        room: {
          double: {
            newPrice: 115.77,
            priceBefore: 109.77,
            priceNow: 118.15,
          },
          single: {
            newPrice: 164.89,
            priceBefore: 152.1,
            priceNow: 151.05,
          },
          suite: {
            newPrice: 209.94,
            priceBefore: 207.9,
            priceNow: 213.92,
          },
        },
      },
      {
        date: '2025-08-21',
        festivos: [
          'Festividad local de Iniesta',
          'Festividad local de Cubillejo de la Sierra',
          'Festividad local de Fuembellida',
        ],
        pc_ocupacion: 53.0,
        room: {
          double: {
            newPrice: 85.88,
            priceBefore: 82.45,
            priceNow: 84.26,
          },
          single: {
            newPrice: 114.73,
            priceBefore: 112.5,
            priceNow: 115.11,
          },
          suite: {
            newPrice: 214.66,
            priceBefore: 205.38,
            priceNow: 220.42,
          },
        },
      },
      {
        date: '2025-08-22',
        festivos: [
          'Festividad local de Ciudad Real',
          'Festividad local de San Clemente',
          'Festividad local de Alcoroches',
          'Festividad local de La Olmeda de Jadraque',
          'Festividad local de Morenilla',
          'Festividad local de Torremocha de Jadraque',
        ],
        pc_ocupacion: 64.0,
        room: {
          double: {
            newPrice: 148.68,
            priceBefore: 137.7,
            priceNow: 142.23,
          },
          single: {
            newPrice: 105.81,
            priceBefore: 100.0,
            priceNow: 109.72,
          },
          suite: {
            newPrice: 219.69,
            priceBefore: 231.0,
            priceNow: 247.91,
          },
        },
      },
      {
        date: '2025-08-23',
        festivos: [
          'Festividad local de Madrigueras',
          'Festividad local de Pozuelo',
          'Festividad local de Reolid',
          'Festividad local de Yeste',
          'Festividad local de Los Pozuelos de Calatrava',
          'Festividad local de Atalaya del Cañavate',
          'Festividad local de Fresneda de Altarejos',
          'Festividad local de La Almarcha',
          'Festividad local de Laguna del Marquesado',
          'Festividad local de Torrejoncillo del Rey',
          'Festividad local de Alcoroches',
          'Festividad local de Arbancón',
          'Festividad local de Armuña de Tajuña',
          'Festividad local de Casa de Uceda',
          'Festividad local de Galápagos',
          'Festividad local de Galve de Sorbe',
          'Festividad local de Henche',
          'Festividad local de Hita',
          'Festividad local de Morenilla',
          'Festividad local de Tartanedo',
          'Festividad local de Torremocha de Jadraque',
          'Festividad local de Valdepeñas de la Sierra',
          'Festividad local de Yebes',
          'Festividad local de Yélamos de Arriba',
          'Festividad local de Aldeanueva de San Bartolomé',
          'Festividad local de Cabañas de Yepes',
          'Festividad local de Gargantilla',
          'Festividad local de Marrupe',
          'Festividad local de Puerto de San Vicente',
          'Festividad local de San Bartolomé de las Abiertas',
        ],
        pc_ocupacion: 73.0,
        room: {
          double: {
            newPrice: 240.48,
            priceBefore: 225.0,
            priceNow: 247.0,
          },
          single: {
            newPrice: 104.62,
            priceBefore: 96.9,
            priceNow: 94.49,
          },
          suite: {
            newPrice: 204.16,
            priceBefore: 198.9,
            priceNow: 209.26,
          },
        },
      },
      {
        date: '2025-08-24',
        festivos: [
          'Festividad local de Tarazona de la Mancha',
          'Festividad local de Almagro',
          'Festividad local de Campo de Criptana',
          'Festividad local de Arcos de la Sierra',
          'Festividad local de Casasimarro',
          'Festividad local de Castillejo del Romeral',
          'Festividad local de Colliga',
          'Festividad local de Villarejo de la Peñuela',
          'Festividad local de Campillo de Dueñas',
          'Festividad local de El Cubillo de Uceda',
          'Festividad local de Jirueque',
          'Festividad local de Lupiana',
          'Festividad local de Matarrubia',
          'Festividad local de Rillo de Gallo',
          'Festividad local de El Viso de San Juan',
          'Festividad local de Navahermosa',
        ],
        pc_ocupacion: 54.0,
        room: {
          double: {
            newPrice: 153.81,
            priceBefore: 150.3,
            priceNow: 145.87,
          },
          single: {
            newPrice: 108.85,
            priceBefore: 114.3,
            priceNow: 117.36,
          },
          suite: {
            newPrice: 141.33,
            priceBefore: 137.91,
            priceNow: 142.29,
          },
        },
      },
      {
        date: '2025-08-25',
        festivos: ['Festividad local de Rillo de Gallo'],
        pc_ocupacion: 53.0,
        room: {
          double: {
            newPrice: 141.65,
            priceBefore: 140.58,
            priceNow: 146.86,
          },
          single: {
            newPrice: 95.65,
            priceBefore: 100.0,
            priceNow: 106.35,
          },
          suite: {
            newPrice: 153.68,
            priceBefore: 158.95,
            priceNow: 165.68,
          },
        },
      },
      {
        date: '2025-08-26',
        festivos: [
          'Festividad local de Carcelén',
          'Festividad local de Ballesteros de Calatrava',
          'Festividad local de Navalpino',
          'Festividad local de Alcohujate',
          'Festividad local de Belmonte',
          'Festividad local de Casas de Fernando Alonso',
          'Festividad local de Casas de Haro',
          'Festividad local de Castillejo del Romeral',
          'Festividad local de Fresneda de Altarejos',
          'Festividad local de Gascueña',
          'Festividad local de Piqueras del Castillo',
          'Festividad local de Pozorrubio de Santiago',
          'Festividad local de Ucles',
          'Festividad local de Vellisca',
          'Festividad local de Checa',
          'Festividad local de Cubillejo del Sitio',
          'Festividad local de Hita',
          'Festividad local de Sacecorbo',
          'Festividad local de Valfermoso de Tajuña',
          'Festividad local de Alcañizo',
          'Festividad local de Aldeanueva de Barbarroya',
          'Festividad local de Aldeanueva de San Bartolomé',
          'Festividad local de Añover de Tajo',
          'Festividad local de Arisgotas',
          'Festividad local de Cabañas de Yepes',
          'Festividad local de Corral de Almaguer',
          'Festividad local de Hormigos',
          'Festividad local de Huerta de Valdecarábanos',
          'Festividad local de Mesegar de Tajo',
          'Festividad local de Orgaz',
          'Festividad local de San Bartolomé de las Abiertas',
          'Festividad local de Santa Cruz de la Zarza',
          'Festividad local de Torrecilla de La Jara',
        ],
        pc_ocupacion: 68.0,
        room: {
          double: {
            newPrice: 170.45,
            priceBefore: 174.0,
            priceNow: 166.32,
          },
          single: {
            newPrice: 149.54,
            priceBefore: 143.0,
            priceNow: 141.49,
          },
          suite: {
            newPrice: 189.45,
            priceBefore: 174.3,
            priceNow: 171.63,
          },
        },
      },
      {
        date: '2025-08-27',
        festivos: [
          'Festividad local de Paredes de Sigüenza',
          'Festividad local de Valfermoso de Tajuña',
          'Festividad local de Albarreal de Tajo',
          'Festividad local de Huerta de Valdecarábanos',
        ],
        pc_ocupacion: 45.0,
        room: {
          double: {
            newPrice: 99.61,
            priceBefore: 90.95,
            priceNow: 96.89,
          },
          single: {
            newPrice: 89.71,
            priceBefore: 88.77,
            priceNow: 93.49,
          },
          suite: {
            newPrice: 165.95,
            priceBefore: 168.3,
            priceNow: 164.82,
          },
        },
      },
      {
        date: '2025-08-28',
        festivos: [
          'Festividad local de Casas Ibáñez',
          'Festividad local de Peñas de San Pedro',
          'Festividad local de Fernán Caballero',
          'Festividad local de Villahermosa',
          'Festividad local de Castillejo de Iniesta',
          'Festividad local de Collados',
          'Festividad local de Mota del Cuervo',
          'Festividad local de Zafrilla',
          'Festividad local de Atanzón',
          'Festividad local de Fuentelencina',
          'Festividad local de Hombrados',
          'Festividad local de Membrillera',
          'Festividad local de Albarreal de Tajo',
          'Festividad local de El Toboso',
        ],
        pc_ocupacion: 61.0,
        room: {
          double: {
            newPrice: 115.81,
            priceBefore: 107.0,
            priceNow: 106.2,
          },
          single: {
            newPrice: 105.56,
            priceBefore: 109.8,
            priceNow: 111.61,
          },
          suite: {
            newPrice: 231.02,
            priceBefore: 228.0,
            priceNow: 225.03,
          },
        },
      },
      {
        date: '2025-08-29',
        festivos: [
          'Festividad local de Puebla de Don Rodrigo',
          'Festividad local de Aranzueque',
          'Festividad local de Espinosa de Henares',
          'Festividad local de La Yunta',
          'Festividad local de Millana',
          'Festividad local de Borox',
        ],
        pc_ocupacion: 67.0,
        room: {
          double: {
            newPrice: 139.92,
            priceBefore: 128.7,
            priceNow: 122.49,
          },
          single: {
            newPrice: 150.39,
            priceBefore: 148.5,
            priceNow: 156.99,
          },
          suite: {
            newPrice: 209.47,
            priceBefore: 206.5,
            priceNow: 222.33,
          },
        },
      },
      {
        date: '2025-08-30',
        festivos: [
          'Festividad local de Huelamo',
          'Festividad local de Pajaron',
          'Festividad local de Espinosa de Henares',
          'Festividad local de Pinilla de Jadraque 5 febrero y',
          'Festividad local de Quer',
          'Festividad local de Saelices de la Sal',
          'Festividad local de Solanillos del Extremo',
          'Festividad local de Torrecuadradilla',
          'Festividad local de Valdeaveruelo',
          'Festividad local de Villares de Jadraque',
          'Festividad local de Viñuelas',
          'Festividad local de Aldea en Cabo',
          'Festividad local de Borox',
          'Festividad local de Las Ventas de San Julian',
          'Festividad local de Torrico',
          'Festividad local de Villaluenga de la Sagra',
        ],
        pc_ocupacion: 53.0,
        room: {
          double: {
            newPrice: 145.39,
            priceBefore: 142.2,
            priceNow: 140.62,
          },
          single: {
            newPrice: 113.6,
            priceBefore: 104.5,
            priceNow: 112.81,
          },
          suite: {
            newPrice: 167.23,
            priceBefore: 152.99,
            priceNow: 158.43,
          },
        },
      },
      {
        date: '2025-08-31',
        festivos: [
          'Festividad local de Tomelloso',
          'Festividad local de Ciruelos del Pinar',
          'Festividad local de Illescas',
          'Festividad local de Villarejo de Montalbán',
        ],
        pc_ocupacion: 45.0,
        room: {
          double: {
            newPrice: 86.09,
            priceBefore: 90.2,
            priceNow: 97.97,
          },
          single: {
            newPrice: 138.19,
            priceBefore: 139.0,
            priceNow: 152.48,
          },
          suite: {
            newPrice: 120.63,
            priceBefore: 116.4,
            priceNow: 126.92,
          },
        },
      },
      {
        date: '2025-09-01',
        festivos: [
          'Festividad local de Cuevas de Velasco',
          'Festividad local de Villar del Maestre',
        ],
        pc_ocupacion: 57.0,
        room: {
          double: {
            newPrice: 150.77,
            priceBefore: 143.94,
            priceNow: 140.34,
          },
          single: {
            newPrice: 101.59,
            priceBefore: 99.0,
            priceNow: 102.92,
          },
          suite: {
            newPrice: 192.34,
            priceBefore: 181.8,
            priceNow: 197.82,
          },
        },
      },
      {
        date: '2025-09-02',
        festivos: [
          'Festividad local de Almansa',
          'Festividad local de Argamasilla de Alba',
          'Festividad local de Daimiel',
          'Festividad local de Arrancacepas',
          'Festividad local de Cueva del Hierro',
          'Festividad local de Las Pedroñeras',
          'Festividad local de Motilla del Palancar',
          'Festividad local de Pajaroncillo',
          'Festividad local de Valhermoso de la Fuente',
          'Festividad local de Villalba del Rey',
          'Festividad local de Caspueñas',
          'Festividad local de El Casar',
          'Festividad local de Molina de Aragón',
          'Festividad local de Solanillos del Extremo',
          'Festividad local de Burguillos de Toledo',
          'Festividad local de Hontanar',
          'Festividad local de La Fresneda de la Jara',
          'Festividad local de La Puebla de Almoradiel',
          'Festividad local de Las Ventas de San Julian',
          'Festividad local de Torrico',
        ],
        pc_ocupacion: 71.0,
        room: {
          double: {
            newPrice: 148.32,
            priceBefore: 142.2,
            priceNow: 152.9,
          },
          single: {
            newPrice: 122.28,
            priceBefore: 119.0,
            priceNow: 115.15,
          },
          suite: {
            newPrice: 252.56,
            priceBefore: 249.0,
            priceNow: 238.42,
          },
        },
      },
      {
        date: '2025-09-03',
        festivos: [
          'Festividad local de El Robledo',
          'Festividad local de Cueva del Hierro',
        ],
        pc_ocupacion: 51.0,
        room: {
          double: {
            newPrice: 133.6,
            priceBefore: 123.3,
            priceNow: 119.44,
          },
          single: {
            newPrice: 126.61,
            priceBefore: 125.1,
            priceNow: 131.35,
          },
          suite: {
            newPrice: 142.07,
            priceBefore: 135.99,
            priceNow: 131.39,
          },
        },
      },
      {
        date: '2025-09-04',
        festivos: ['Festividad local de Ayna'],
        pc_ocupacion: 59.0,
        room: {
          double: {
            newPrice: 131.5,
            priceBefore: 131.14,
            priceNow: 125.83,
          },
          single: {
            newPrice: 137.01,
            priceBefore: 126.0,
            priceNow: 131.39,
          },
          suite: {
            newPrice: 194.25,
            priceBefore: 177.66,
            priceNow: 182.08,
          },
        },
      },
      {
        date: '2025-09-05',
        festivos: [
          'Festividad local de Alcaraz',
          'Festividad local de Daimiel',
          'Festividad local de Villamayor de Santiago',
          'Festividad local de Corduente',
        ],
        pc_ocupacion: 57.0,
        room: {
          double: {
            newPrice: 122.09,
            priceBefore: 123.98,
            priceNow: 128.39,
          },
          single: {
            newPrice: 136.16,
            priceBefore: 127.44,
            priceNow: 136.19,
          },
          suite: {
            newPrice: 186.58,
            priceBefore: 174.0,
            priceNow: 177.93,
          },
        },
      },
      {
        date: '2025-09-06',
        festivos: [
          'Festividad local de Ayna',
          'Festividad local de Bienservida',
          'Festividad local de Puertollano',
          'Festividad local de Almendros',
          'Festividad local de El Cañavate',
          'Festividad local de Garaballa',
          'Festividad local de Salvacañete',
          'Festividad local de Villalba de la Sierra',
          'Festividad local de Villarejo Sobrehuerta',
          'Festividad local de Alovera',
          'Festividad local de Aragosa',
          'Festividad local de Casa de Uceda',
          'Festividad local de Corduente',
          'Festividad local de Malaguilla',
          'Festividad local de Mandayona',
          'Festividad local de Miralrío',
          'Festividad local de Orea',
          'Festividad local de Pareja',
          'Festividad local de Peñalver',
          'Festividad local de Riba de Saelices',
          'Festividad local de Romanones',
          'Festividad local de Trillo',
          'Festividad local de Carranque',
          'Festividad local de El Bercial',
          'Festividad local de Talavera de la Reina',
          'Festividad local de Talavera la Nueva',
          'Festividad local de Velada',
          'Festividad local de Villarrubia de Santiago',
        ],
        pc_ocupacion: 65.0,
        room: {
          double: {
            newPrice: 148.52,
            priceBefore: 138.6,
            priceNow: 137.94,
          },
          single: {
            newPrice: 149.14,
            priceBefore: 137.0,
            priceNow: 141.71,
          },
          suite: {
            newPrice: 197.35,
            priceBefore: 195.3,
            priceNow: 198.89,
          },
        },
      },
      {
        date: '2025-09-07',
        festivos: [
          'Festividad local de Minaya',
          'Festividad local de Alhambra',
          'Festividad local de Cereceda',
          'Festividad local de Majaelrayo',
          'Festividad local de Puebla de Beleña',
          'Festividad local de El Bercial',
        ],
        pc_ocupacion: 67.0,
        room: {
          double: {
            newPrice: 148.82,
            priceBefore: 139.95,
            priceNow: 138.4,
          },
          single: {
            newPrice: 148.84,
            priceBefore: 136.0,
            priceNow: 138.87,
          },
          suite: {
            newPrice: 199.98,
            priceBefore: 207.64,
            priceNow: 208.99,
          },
        },
      },
      {
        date: '2025-09-08',
        festivos: [
          'Festividad local de Las Majadas',
          'Festividad local de Ribatajada',
          'Festividad local de Algar de Mesa',
          'Festividad local de Alhóndiga',
          'Festividad local de Campillo de Dueñas',
          'Festividad local de Casasana',
          'Festividad local de Cereceda',
          'Festividad local de El Recuenco',
          'Festividad local de Miedes de Atienza',
          'Festividad local de Monasterio',
          'Festividad local de Ventosa',
          'Festividad local de Zaorejas',
        ],
        pc_ocupacion: 65.0,
        room: {
          double: {
            newPrice: 153.9,
            priceBefore: 149.4,
            priceNow: 149.27,
          },
          single: {
            newPrice: 112.27,
            priceBefore: 112.59,
            priceNow: 118.31,
          },
          suite: {
            newPrice: 227.32,
            priceBefore: 207.9,
            priceNow: 221.6,
          },
        },
      },
      {
        date: '2025-09-09',
        festivos: [
          'Festividad local de Albacete',
          'Festividad local de Alborea',
          'Festividad local de Caudete',
          'Festividad local de Fuensanta',
          'Festividad local de Aldea del Rey',
          'Festividad local de Argamasilla de Calatrava',
          'Festividad local de Calzada de Calatrava',
          'Festividad local de Fuencaliente',
          'Festividad local de Llanos del Caudillo',
          'Festividad local de Luciana',
          'Festividad local de Miguelturra',
          'Festividad local de Puebla del Príncipe',
          'Festividad local de Valdemanco del Esteras',
          'Festividad local de Villahermosa',
          'Festividad local de Albendea',
          'Festividad local de Alcohujate',
          'Festividad local de Almendros',
          'Festividad local de Altarejos',
          'Festividad local de Barajas de Melo',
          'Festividad local de Belmonte',
          'Festividad local de Buendía',
          'Festividad local de Campillo de Altobuey',
          'Festividad local de Canalejas del Arroyo',
          'Festividad local de Cañada del Hoyo',
          'Festividad local de Cañete',
          'Festividad local de Cañizares',
          'Festividad local de Culebras',
          'Festividad local de El Cañavate',
          'Festividad local de El Herrumblar',
          'Festividad local de Horcajada de la Torre',
          'Festividad local de Huerta de la Obispalía',
          'Festividad local de La Peraleja',
          'Festividad local de Lagunaseca',
          'Festividad local de Loranca del Campo',
          'Festividad local de Puebla de Almenara',
          'Festividad local de Ribagorda',
          'Festividad local de Santa Maria del Campo Rus',
          'Festividad local de Tarancon',
          'Festividad local de Torrubia del Campo',
          'Festividad local de Vega del Codorno',
          'Festividad local de Villanueva de Guadamejud',
          'Festividad local de Villar de Cañas',
          'Festividad local de Villar de Olalla',
          'Festividad local de Villar del Horno',
          'Festividad local de Villarejo de Fuentes',
          'Festividad local de Balbacil',
          'Festividad local de Casasana',
          'Festividad local de Clares',
          'Festividad local de Cobeta',
          'Festividad local de Codes',
          'Festividad local de Condemios de Abajo',
          'Festividad local de Hontoba',
          'Festividad local de Horche',
          'Festividad local de Illana',
          'Festividad local de Maranchón',
          'Festividad local de Monasterio',
          'Festividad local de Pálmaces de Jadraque',
          'Festividad local de Pareja',
          'Festividad local de Peñalver',
          'Festividad local de Tendilla',
          'Festividad local de Torija',
          'Festividad local de Tortuera',
          'Festividad local de Trillo',
          'Festividad local de Turmiel',
          'Festividad local de Ventosa',
          'Festividad local de Yebra',
          'Festividad local de Alcabón',
          'Festividad local de Arisgotas',
          'Festividad local de Carriches',
          'Festividad local de Castillo de Bayuela',
          'Festividad local de Cebolla',
          'Festividad local de Cedillo del Condado',
          'Festividad local de Consuegra',
          'Festividad local de Domingo Pérez',
          'Festividad local de Espinoso del Rey',
          'Festividad local de Gamonal',
          'Festividad local de Las Hunfrías',
          'Festividad local de Los Navalucillos',
          'Festividad local de Lillo',
          'Festividad local de Méntrida',
          'Festividad local de Miguel Esteban',
          'Festividad local de Mocejón',
          'Festividad local de Navamorcuende',
          'Festividad local de Ocaña',
          'Festividad local de Orgaz',
          'Festividad local de Oropesa y Corchuela',
          'Festividad local de Paredes de Escalona',
          'Festividad local de Pepino',
          'Festividad local de Recas',
          'Festividad local de Sonseca',
          'Festividad local de Turleque',
          'Festividad local de Villamiel',
          'Festividad local de Villanueva de Bogas',
          'Festividad local de Villarrubia de Santiago',
          'Festividad local de Villaseca de la Sagra',
        ],
        pc_ocupacion: 50.0,
        room: {
          double: {
            newPrice: 163.07,
            priceBefore: 167.2,
            priceNow: 170.05,
          },
          single: {
            newPrice: 94.12,
            priceBefore: 90.0,
            priceNow: 95.48,
          },
          suite: {
            newPrice: 121.74,
            priceBefore: 120.32,
            priceNow: 123.44,
          },
        },
      },
      {
        date: '2025-09-10',
        festivos: [
          'Festividad local de Barajas de Melo',
          'Festividad local de Cañizares',
          'Festividad local de Vega del Codorno',
          'Festividad local de Almonacid de Zorita',
          'Festividad local de Horche',
          'Festividad local de Pálmaces de Jadraque',
          'Festividad local de Tendilla',
          'Festividad local de Torija',
          'Festividad local de Tortuera',
          'Festividad local de Valdelcubo',
          'Festividad local de Los Navalucillos',
          'Festividad local de Ocaña',
        ],
        pc_ocupacion: 67.0,
        room: {
          double: {
            newPrice: 131.54,
            priceBefore: 134.46,
            priceNow: 130.73,
          },
          single: {
            newPrice: 142.77,
            priceBefore: 144.9,
            priceNow: 141.73,
          },
          suite: {
            newPrice: 204.14,
            priceBefore: 207.9,
            priceNow: 201.8,
          },
        },
      },
      {
        date: '2025-09-11',
        festivos: [
          'Festividad local de Chumillas',
          'Festividad local de Hontanaya',
          'Festividad local de Pinarejo',
          'Festividad local de Almonacid de Zorita',
          'Festividad local de Almendral de La Cañada',
          'Festividad local de Retamoso de la Jara',
          'Festividad local de Villa de Don Fadrique',
        ],
        pc_ocupacion: 63.0,
        room: {
          double: {
            newPrice: 167.21,
            priceBefore: 164.0,
            priceNow: 155.98,
          },
          single: {
            newPrice: 84.96,
            priceBefore: 87.2,
            priceNow: 94.93,
          },
          suite: {
            newPrice: 218.15,
            priceBefore: 207.9,
            priceNow: 228.01,
          },
        },
      },
      {
        date: '2025-09-12',
        festivos: [
          'Festividad local de El Pozuelo',
          'Festividad local de Priego',
          'Festividad local de Guadalajara',
          'Festividad local de Calera y Chozas',
          'Festividad local de Fuensalida',
          'Festividad local de Villatobas',
          'Festividad local de Yuncos',
        ],
        pc_ocupacion: 63.0,
        room: {
          double: {
            newPrice: 186.17,
            priceBefore: 170.0,
            priceNow: 171.7,
          },
          single: {
            newPrice: 90.24,
            priceBefore: 89.0,
            priceNow: 97.15,
          },
          suite: {
            newPrice: 216.7,
            priceBefore: 204.9,
            priceNow: 218.17,
          },
        },
      },
      {
        date: '2025-09-13',
        festivos: [
          'Festividad local de Cañada de Calatrava',
          'Festividad local de Castellar de Santiago',
          'Festividad local de Fuenllana',
          'Festividad local de Montiel',
          'Festividad local de Saceruela',
          'Festividad local de Arcas',
          'Festividad local de Belmontejo',
          'Festividad local de Chillaron de Cuenca',
          'Festividad local de Horcajo De Santiago',
          'Festividad local de Leganiel',
          'Festividad local de Los Valdecolmenas',
          'Festividad local de Mariana',
          'Festividad local de Montalbanejo',
          'Festividad local de Portilla',
          'Festividad local de Priego',
          'Festividad local de Saceda-Trasierra',
          'Festividad local de Santa Maria del Val',
          'Festividad local de Valdeolivas',
          'Festividad local de Chillaron del Rey',
          'Festividad local de Chiloeches',
          'Festividad local de Cifuentes',
          'Festividad local de Guadalajara',
          'Festividad local de Hueva',
          'Festividad local de Luzón',
          'Festividad local de Moratilla de los Meleros',
          'Festividad local de Trijueque',
          'Festividad local de Villanueva de Alcorón',
          'Festividad local de Calera y Chozas',
          'Festividad local de Calzada de Oropesa',
          'Festividad local de Casarrubios del Monte',
          'Festividad local de Dosbarrios',
          'Festividad local de Escalonilla',
          'Festividad local de Fuensalida',
          'Festividad local de Hinojosa de San Vicente',
          'Festividad local de Los Cerralbos',
          'Festividad local de Los Navalmorales',
          'Festividad local de Los Yébenes',
          'Festividad local de Otero',
          'Festividad local de Real de San Vicente',
          'Festividad local de Rielves',
          'Festividad local de Sevilleja de la Jara',
          'Festividad local de Villatobas',
          'Festividad local de Yepes',
          'Festividad local de Yuncos',
        ],
        pc_ocupacion: 60.0,
        room: {
          double: {
            newPrice: 124.2,
            priceBefore: 116.62,
            priceNow: 119.43,
          },
          single: {
            newPrice: 94.06,
            priceBefore: 90.0,
            priceNow: 95.48,
          },
          suite: {
            newPrice: 239.74,
            priceBefore: 235.5,
            priceNow: 253.94,
          },
        },
      },
      {
        date: '2025-09-14',
        festivos: [
          'Festividad local de Alpera',
          'Festividad local de Villa de Vés',
          'Festividad local de Almadén',
          'Festividad local de Bolaños de Calatrava',
          'Festividad local de Cózar',
          'Festividad local de Malagón',
          'Festividad local de Manzanares',
          'Festividad local de Alarcón',
          'Festividad local de Cuevas de Velasco',
          'Festividad local de Fuentelespino de Moya',
          'Festividad local de La Alberca de Zancara',
          'Festividad local de La Parra de las Vegas',
          'Festividad local de La Ventosa',
          'Festividad local de Minglanilla',
          'Festividad local de Nohales',
          'Festividad local de Villar del Maestre',
          'Festividad local de Alaminos',
          'Festividad local de Argecilla',
          'Festividad local de Atienza',
          'Festividad local de Baños de Tajo',
          'Festividad local de Centenera',
          'Festividad local de Ciruelas',
          'Festividad local de Fuentelahiguera de Albatages',
          'Festividad local de Hueva',
          'Festividad local de Irueste',
          'Festividad local de Jadraque',
          'Festividad local de Loranca de Tajuña',
          'Festividad local de Mondéjar',
          'Festividad local de Saelices de la Sal',
          'Festividad local de Salmerón',
          'Festividad local de Val de San Garcia',
          'Festividad local de Valdeconcha',
          'Festividad local de Valdenuño Fernandez',
          'Festividad local de Valhermoso',
          'Festividad local de Carmena',
          'Festividad local de Garciotum',
          'Festividad local de Guadamur',
          'Festividad local de La Pueblanueva',
          'Festividad local de Las Herencias',
          'Festividad local de Lucillos',
          'Festividad local de Madridejos',
          'Festividad local de Pelahustán',
          'Festividad local de Segurilla',
        ],
        pc_ocupacion: 63.0,
        room: {
          double: {
            newPrice: 129.01,
            priceBefore: 124.04,
            priceNow: 131.7,
          },
          single: {
            newPrice: 128.44,
            priceBefore: 130.0,
            priceNow: 125.49,
          },
          suite: {
            newPrice: 212.68,
            priceBefore: 207.9,
            priceNow: 209.35,
          },
        },
      },
      {
        date: '2025-09-15',
        festivos: [
          'Festividad local de Villar de Cañas',
          'Festividad local de Anquela del Ducado',
          'Festividad local de Baños de Tajo',
          'Festividad local de Valhermoso',
          'Festividad local de Zarzuela de Galve',
        ],
        pc_ocupacion: 67.0,
        room: {
          double: {
            newPrice: 161.8,
            priceBefore: 162.0,
            priceNow: 177.34,
          },
          single: {
            newPrice: 120.37,
            priceBefore: 116.1,
            priceNow: 119.36,
          },
          suite: {
            newPrice: 226.2,
            priceBefore: 207.9,
            priceNow: 205.21,
          },
        },
      },
      {
        date: '2025-09-16',
        festivos: [
          'Festividad local de Elche De La Sierra',
          'Festividad local de Villapalacios',
          'Festividad local de Alcoba de los Montes',
          'Festividad local de Brazatortas',
          'Festividad local de Guadalmez',
          'Festividad local de Arandilla del Arroyo',
          'Festividad local de Beteta',
          'Festividad local de Buenache de Alarcon',
          'Festividad local de Castillejo-Sierra',
          'Festividad local de Fuente de Pedro Naharro',
          'Festividad local de Leganiel',
          'Festividad local de Osa de la Vega',
          'Festividad local de Paracuellos de la Vega',
          'Festividad local de Saelices',
          'Festividad local de Santa Maria del Val',
          'Festividad local de Sisante',
          'Festividad local de Villaescusa de Haro',
          'Festividad local de Villares del Saz',
          'Festividad local de Alcocer',
          'Festividad local de Almoguera',
          'Festividad local de Auñón',
          'Festividad local de Azuqueca de Henares',
          'Festividad local de Budia',
          'Festividad local de Chequilla',
          'Festividad local de Cifuentes',
          'Festividad local de Esplegares',
          'Festividad local de Humanes',
          'Festividad local de Jadraque',
          'Festividad local de Milmarcos',
          'Festividad local de Peñalén',
          'Festividad local de Santiuste',
          'Festividad local de Val de San Garcia',
          'Festividad local de Yunquera de Henares',
          'Festividad local de Zarzuela de Galve',
          'Festividad local de Bargas',
          'Festividad local de Calzada de Oropesa',
          'Festividad local de Chueca',
          'Festividad local de Cobeja',
          'Festividad local de Los Navalmorales',
          'Festividad local de Noez',
          'Festividad local de Numancia de la Sagra',
          'Festividad local de Otero',
          'Festividad local de Real de San Vicente',
          'Festividad local de Santa Ana de Pusa',
          'Festividad local de Villafranca de los Caballeros',
          'Festividad local de Yepes',
          'Festividad local de Yunclillos',
        ],
        pc_ocupacion: 55.0,
        room: {
          double: {
            newPrice: 161.42,
            priceBefore: 147.59,
            priceNow: 157.28,
          },
          single: {
            newPrice: 81.97,
            priceBefore: 75.0,
            priceNow: 75.75,
          },
          suite: {
            newPrice: 202.76,
            priceBefore: 191.73,
            priceNow: 183.95,
          },
        },
      },
      {
        date: '2025-09-17',
        festivos: [
          'Festividad local de Beteta',
          'Festividad local de Budia',
          'Festividad local de Yunquera de Henares',
          'Festividad local de Cobeja',
          'Festividad local de Numancia de la Sagra',
        ],
        pc_ocupacion: 51.0,
        room: {
          double: {
            newPrice: 94.53,
            priceBefore: 96.3,
            priceNow: 98.02,
          },
          single: {
            newPrice: 89.57,
            priceBefore: 85.0,
            priceNow: 83.85,
          },
          suite: {
            newPrice: 199.53,
            priceBefore: 207.9,
            priceNow: 206.5,
          },
        },
      },
      {
        date: '2025-09-18',
        festivos: [
          'Festividad local de Villanueva de los Infantes',
          'Festividad local de Honrubia',
          'Festividad local de Escamilla',
          'Festividad local de Luzaga',
        ],
        pc_ocupacion: 71.0,
        room: {
          double: {
            newPrice: 125.89,
            priceBefore: 114.69,
            priceNow: 120.37,
          },
          single: {
            newPrice: 177.21,
            priceBefore: 162.33,
            priceNow: 165.01,
          },
          suite: {
            newPrice: 241.48,
            priceBefore: 231.5,
            priceNow: 245.01,
          },
        },
      },
      {
        date: '2025-09-19',
        festivos: ['Festividad local de Oropesa y Corchuela'],
        pc_ocupacion: 63.0,
        room: {
          double: {
            newPrice: 105.67,
            priceBefore: 106.0,
            priceNow: 106.43,
          },
          single: {
            newPrice: 122.57,
            priceBefore: 118.15,
            priceNow: 124.41,
          },
          suite: {
            newPrice: 246.12,
            priceBefore: 234.0,
            priceNow: 243.97,
          },
        },
      },
      {
        date: '2025-09-20',
        festivos: [
          'Festividad local de Villaverde del Guadalimar',
          'Festividad local de Valverde de Jucar',
          'Festividad local de Ablanque',
          'Festividad local de Peralejos de las Truchas',
          'Festividad local de Pozo de Guadalajara',
          'Festividad local de Gerindote',
        ],
        pc_ocupacion: 77.0,
        room: {
          double: {
            newPrice: 162.96,
            priceBefore: 168.3,
            priceNow: 184.68,
          },
          single: {
            newPrice: 133.24,
            priceBefore: 129.6,
            priceNow: 126.11,
          },
          suite: {
            newPrice: 246.29,
            priceBefore: 248.0,
            priceNow: 243.05,
          },
        },
      },
      {
        date: '2025-09-21',
        festivos: [
          'Festividad local de La Recueja',
          'Festividad local de Cuenca',
          'Festividad local de La Melgosa',
          'Festividad local de Mohorte',
          'Festividad local de Consuegra',
        ],
        pc_ocupacion: 46.0,
        room: {
          double: {
            newPrice: 128.86,
            priceBefore: 125.78,
            priceNow: 128.0,
          },
          single: {
            newPrice: 105.93,
            priceBefore: 101.52,
            priceNow: 109.13,
          },
          suite: {
            newPrice: 135.81,
            priceBefore: 125.78,
            priceNow: 132.79,
          },
        },
      },
      {
        date: '2025-09-22',
        festivos: [],
        pc_ocupacion: 67.0,
        room: {
          double: {
            newPrice: 152.97,
            priceBefore: 150.15,
            priceNow: 162.73,
          },
          single: {
            newPrice: 123.35,
            priceBefore: 113.4,
            priceNow: 111.22,
          },
          suite: {
            newPrice: 213.28,
            priceBefore: 218.72,
            priceNow: 229.9,
          },
        },
      },
      {
        date: '2025-09-23',
        festivos: [
          'Festividad local de Munera',
          'Festividad local de Navas de Estena',
          'Festividad local de Peralejos de las Truchas',
          'Festividad local de Totanés',
          'Festividad local de Villaminaya',
        ],
        pc_ocupacion: 78.0,
        room: {
          double: {
            newPrice: 177.1,
            priceBefore: 163.8,
            priceNow: 168.98,
          },
          single: {
            newPrice: 158.95,
            priceBefore: 159.0,
            priceNow: 156.03,
          },
          suite: {
            newPrice: 237.86,
            priceBefore: 234.35,
            priceNow: 246.54,
          },
        },
      },
      {
        date: '2025-09-24',
        festivos: [
          'Festividad local de Hoya Gonzalo',
          'Festividad local de Herencia',
          'Festividad local de Los Cortijos',
          'Festividad local de Abánades',
          'Festividad local de Mochales',
          'Festividad local de Torremochuela',
          'Festividad local de Villel de Mesa',
          'Festividad local de Las Ventas con Peña Aguilera',
          'Festividad local de Pantoja',
          'Festividad local de Villaluenga de la Sagra',
        ],
        pc_ocupacion: 71.0,
        room: {
          double: {
            newPrice: 182.34,
            priceBefore: 169.0,
            priceNow: 164.69,
          },
          single: {
            newPrice: 148.82,
            priceBefore: 146.2,
            priceNow: 144.83,
          },
          suite: {
            newPrice: 216.28,
            priceBefore: 197.7,
            priceNow: 192.38,
          },
        },
      },
      {
        date: '2025-09-25',
        festivos: [
          'Festividad local de Los Cortijos',
          'Festividad local de La Guardia',
          'Festividad local de Pantoja',
        ],
        pc_ocupacion: 52.0,
        room: {
          double: {
            newPrice: 99.93,
            priceBefore: 94.0,
            priceNow: 91.88,
          },
          single: {
            newPrice: 95.0,
            priceBefore: 95.0,
            priceNow: 90.29,
          },
          suite: {
            newPrice: 208.38,
            priceBefore: 201.6,
            priceNow: 205.48,
          },
        },
      },
      {
        date: '2025-09-26',
        festivos: [
          'Festividad local de La Guardia',
          'Festividad local de Noves',
        ],
        pc_ocupacion: 57.0,
        room: {
          double: {
            newPrice: 176.8,
            priceBefore: 164.0,
            priceNow: 174.64,
          },
          single: {
            newPrice: 115.49,
            priceBefore: 107.1,
            priceNow: 105.06,
          },
          suite: {
            newPrice: 152.8,
            priceBefore: 148.69,
            priceNow: 155.34,
          },
        },
      },
      {
        date: '2025-09-27',
        festivos: [
          'Festividad local de Abengibre',
          'Festividad local de El Peral',
          'Festividad local de Palomares del Campo',
          'Festividad local de Albalate de Zorita',
          'Festividad local de Heras de Ayuso',
          'Festividad local de Villaseca de Uceda',
          'Festividad local de Alberche del Caudillo',
          'Festividad local de Buenaventura',
          'Festividad local de Noves',
          'Festividad local de Torrijos',
        ],
        pc_ocupacion: 65.0,
        room: {
          double: {
            newPrice: 134.61,
            priceBefore: 128.99,
            priceNow: 129.04,
          },
          single: {
            newPrice: 119.59,
            priceBefore: 118.65,
            priceNow: 115.28,
          },
          suite: {
            newPrice: 220.1,
            priceBefore: 226.5,
            priceNow: 238.83,
          },
        },
      },
      {
        date: '2025-09-28',
        festivos: [],
        pc_ocupacion: 53.0,
        room: {
          double: {
            newPrice: 120.14,
            priceBefore: 121.53,
            priceNow: 128.65,
          },
          single: {
            newPrice: 104.99,
            priceBefore: 110.0,
            priceNow: 108.44,
          },
          suite: {
            newPrice: 176.23,
            priceBefore: 169.52,
            priceNow: 179.49,
          },
        },
      },
      {
        date: '2025-09-29',
        festivos: [
          'Festividad local de Fresneda de la Sierra',
          'Festividad local de Huelves',
          'Festividad local de Tondos',
          'Festividad local de Valparaiso de Arriba',
          'Festividad local de Anchuela del Campo',
        ],
        pc_ocupacion: 70.0,
        room: {
          double: {
            newPrice: 136.59,
            priceBefore: 139.5,
            priceNow: 136.41,
          },
          single: {
            newPrice: 123.28,
            priceBefore: 129.73,
            priceNow: 137.2,
          },
          suite: {
            newPrice: 248.74,
            priceBefore: 234.0,
            priceNow: 242.16,
          },
        },
      },
      {
        date: '2025-09-30',
        festivos: [
          'Festividad local de Chinchilla',
          'Festividad local de Corralrubio',
          'Festividad local de El Ballestero',
          'Festividad local de Ossa de Montiel',
          'Festividad local de Paterna del Madera',
          'Festividad local de Peñascosa',
          'Festividad local de Anchuras',
          'Festividad local de Montalbo',
          'Festividad local de Narboneta',
          'Festividad local de Tragacete',
          'Festividad local de Castejón de Henares',
          'Festividad local de Driebes',
          'Festividad local de Huertahernando',
          'Festividad local de Yebes',
          'Festividad local de Robledo del Mazo',
          'Festividad local de Torrecilla de La Jara',
          'Festividad local de Urda',
        ],
        pc_ocupacion: 71.0,
        room: {
          double: {
            newPrice: 127.54,
            priceBefore: 126.9,
            priceNow: 121.05,
          },
          single: {
            newPrice: 156.51,
            priceBefore: 160.0,
            priceNow: 170.99,
          },
          suite: {
            newPrice: 218.94,
            priceBefore: 224.1,
            priceNow: 226.72,
          },
        },
      },
      {
        date: '2025-10-01',
        festivos: ['Festividad local de Montalbo', 'Festividad local de Embid'],
        pc_ocupacion: 52.0,
        room: {
          double: {
            newPrice: 109.5,
            priceBefore: 115.2,
            priceNow: 112.39,
          },
          single: {
            newPrice: 91.85,
            priceBefore: 95.4,
            priceNow: 91.63,
          },
          suite: {
            newPrice: 191.94,
            priceBefore: 182.14,
            priceNow: 185.05,
          },
        },
      },
      {
        date: '2025-10-02',
        festivos: [
          'Festividad local de Arguisuelas',
          'Festividad local de La Cierva',
          'Festividad local de Semillas',
          'Festividad local de Yélamos de Abajo',
        ],
        pc_ocupacion: 56.0,
        room: {
          double: {
            newPrice: 142.81,
            priceBefore: 139.5,
            priceNow: 144.89,
          },
          single: {
            newPrice: 137.34,
            priceBefore: 129.0,
            priceNow: 135.81,
          },
          suite: {
            newPrice: 139.81,
            priceBefore: 146.92,
            priceNow: 140.98,
          },
        },
      },
      {
        date: '2025-10-03',
        festivos: ['Festividad local de Yélamos de Abajo'],
        pc_ocupacion: 61.0,
        room: {
          double: {
            newPrice: 145.88,
            priceBefore: 137.2,
            priceNow: 148.97,
          },
          single: {
            newPrice: 96.31,
            priceBefore: 100.0,
            priceNow: 107.85,
          },
          suite: {
            newPrice: 216.35,
            priceBefore: 215.55,
            priceNow: 229.39,
          },
        },
      },
      {
        date: '2025-10-04',
        festivos: [
          'Festividad local de El Salobral',
          'Festividad local de Vianos',
          'Festividad local de Masegosa',
          'Festividad local de Robledo de Corpes',
          'Festividad local de Sotodosos',
        ],
        pc_ocupacion: 60.0,
        room: {
          double: {
            newPrice: 139.31,
            priceBefore: 139.5,
            priceNow: 150.63,
          },
          single: {
            newPrice: 129.66,
            priceBefore: 123.33,
            priceNow: 132.13,
          },
          suite: {
            newPrice: 190.56,
            priceBefore: 180.9,
            priceNow: 198.2,
          },
        },
      },
      {
        date: '2025-10-05',
        festivos: [
          'Festividad local de Salinas del Manzano',
          'Festividad local de El Pedregal',
        ],
        pc_ocupacion: 57.0,
        room: {
          double: {
            newPrice: 102.39,
            priceBefore: 107.41,
            priceNow: 104.82,
          },
          single: {
            newPrice: 98.1,
            priceBefore: 100.8,
            priceNow: 98.72,
          },
          suite: {
            newPrice: 210.38,
            priceBefore: 216.0,
            priceNow: 211.86,
          },
        },
      },
      {
        date: '2025-10-06',
        festivos: [],
        pc_ocupacion: 56.0,
        room: {
          double: {
            newPrice: 134.7,
            priceBefore: 132.3,
            priceNow: 134.47,
          },
          single: {
            newPrice: 79.99,
            priceBefore: 79.66,
            priceNow: 78.22,
          },
          suite: {
            newPrice: 223.15,
            priceBefore: 204.0,
            priceNow: 203.81,
          },
        },
      },
      {
        date: '2025-10-07',
        festivos: [
          'Festividad local de Bonete',
          'Festividad local de Férez',
          'Festividad local de Masegoso',
          'Festividad local de Povedilla',
          'Festividad local de Tiriez',
          'Festividad local de Valdeganga',
          'Festividad local de Almadenejos',
          'Festividad local de Almedina',
          'Festividad local de Santa Cruz de Cáñamos',
          'Festividad local de Villanueva de San Carlos',
          'Festividad local de Buenache de la Sierra',
          'Festividad local de El Picazo',
          'Festividad local de Fuentes',
          'Festividad local de Fuertescusa',
          'Festividad local de Las Valeras',
          'Festividad local de Masegosa',
          'Festividad local de Pineda de Gigüela',
          'Festividad local de Valdetortola',
          'Festividad local de Valparaiso de Abajo',
          'Festividad local de Zafra de Zancara',
          'Festividad local de Zarzuela',
          'Festividad local de Anchuela del Pedregal',
          'Festividad local de Anguita',
          'Festividad local de Driebes',
          'Festividad local de El Sotillo',
          'Festividad local de Málaga del Fresno',
          'Festividad local de Saúca',
          'Festividad local de Setiles',
          'Festividad local de Cabañas de la Sagra',
          'Festividad local de Cardiel de los Montes',
          'Festividad local de Lagartera',
          'Festividad local de Las Vegas y San Antonio',
          'Festividad local de Montearagón',
          'Festividad local de Olias del Rey',
          'Festividad local de Ontígola',
        ],
        pc_ocupacion: 57.0,
        room: {
          double: {
            newPrice: 118.43,
            priceBefore: 110.0,
            priceNow: 118.28,
          },
          single: {
            newPrice: 106.82,
            priceBefore: 106.1,
            priceNow: 108.19,
          },
          suite: {
            newPrice: 218.58,
            priceBefore: 204.82,
            priceNow: 225.09,
          },
        },
      },
      {
        date: '2025-10-08',
        festivos: [
          'Festividad local de Férez',
          'Festividad local de Valdeganga',
          'Festividad local de Valenzuela de Calatrava',
          'Festividad local de Sotos',
          'Festividad local de Setiles',
          'Festividad local de Olias del Rey',
        ],
        pc_ocupacion: 64.0,
        room: {
          double: {
            newPrice: 149.23,
            priceBefore: 151.5,
            priceNow: 146.18,
          },
          single: {
            newPrice: 119.32,
            priceBefore: 118.8,
            priceNow: 126.92,
          },
          suite: {
            newPrice: 217.02,
            priceBefore: 198.9,
            priceNow: 199.61,
          },
        },
      },
      {
        date: '2025-10-09',
        festivos: [
          'Festividad local de Fuenteálamo',
          'Festividad local de Villar del Humo',
          'Festividad local de Ujados',
        ],
        pc_ocupacion: 51.0,
        room: {
          double: {
            newPrice: 152.42,
            priceBefore: 153.0,
            priceNow: 154.46,
          },
          single: {
            newPrice: 83.29,
            priceBefore: 85.0,
            priceNow: 85.08,
          },
          suite: {
            newPrice: 152.67,
            priceBefore: 146.92,
            priceNow: 156.22,
          },
        },
      },
      {
        date: '2025-10-10',
        festivos: [
          'Festividad local de Cantalojas',
          'Festividad local de Torremocha del Pinar',
          'Festividad local de Portillo de Toledo',
        ],
        pc_ocupacion: 60.0,
        room: {
          double: {
            newPrice: 148.83,
            priceBefore: 151.2,
            priceNow: 157.93,
          },
          single: {
            newPrice: 123.11,
            priceBefore: 113.4,
            priceNow: 113.73,
          },
          suite: {
            newPrice: 168.27,
            priceBefore: 176.33,
            priceNow: 180.11,
          },
        },
      },
      {
        date: '2025-10-11',
        festivos: [
          'Festividad local de Bormate',
          'Festividad local de La Herrera',
          'Festividad local de Cardenete',
          'Festividad local de Carrascosa de la Sierra',
          'Festividad local de Henarejos',
          'Festividad local de Cantalojas',
          'Festividad local de Los Alares',
          'Festividad local de Pulgar',
          'Festividad local de Robledo del Buey',
        ],
        pc_ocupacion: 65.0,
        room: {
          double: {
            newPrice: 146.34,
            priceBefore: 151.5,
            priceNow: 157.96,
          },
          single: {
            newPrice: 122.5,
            priceBefore: 120.0,
            priceNow: 131.57,
          },
          suite: {
            newPrice: 198.65,
            priceBefore: 199.8,
            priceNow: 210.59,
          },
        },
      },
      {
        date: '2025-10-12',
        festivos: [],
        pc_ocupacion: 63.0,
        room: {
          double: {
            newPrice: 149.36,
            priceBefore: 151.2,
            priceNow: 158.22,
          },
          single: {
            newPrice: 107.19,
            priceBefore: 110.0,
            priceNow: 111.12,
          },
          suite: {
            newPrice: 205.91,
            priceBefore: 197.95,
            priceNow: 195.28,
          },
        },
      },
      {
        date: '2025-10-13',
        festivos: ['Festividad local de Huelamo'],
        pc_ocupacion: 71.0,
        room: {
          double: {
            newPrice: 192.86,
            priceBefore: 200.7,
            priceNow: 195.76,
          },
          single: {
            newPrice: 76.86,
            priceBefore: 80.0,
            priceNow: 84.97,
          },
          suite: {
            newPrice: 235.09,
            priceBefore: 231.78,
            priceNow: 246.94,
          },
        },
      },
      {
        date: '2025-10-14',
        festivos: [
          'Festividad local de Molinicos',
          'Festividad local de Belinchon',
        ],
        pc_ocupacion: 59.0,
        room: {
          double: {
            newPrice: 128.26,
            priceBefore: 132.3,
            priceNow: 133.35,
          },
          single: {
            newPrice: 126.33,
            priceBefore: 115.0,
            priceNow: 117.97,
          },
          suite: {
            newPrice: 198.9,
            priceBefore: 190.8,
            priceNow: 202.9,
          },
        },
      },
      {
        date: '2025-10-15',
        festivos: [
          'Festividad local de Malagón',
          'Festividad local de Jabaga',
          'Festividad local de Villanueva de la Jara',
          'Festividad local de Pastrana',
        ],
        pc_ocupacion: 51.0,
        room: {
          double: {
            newPrice: 151.81,
            priceBefore: 159.0,
            priceNow: 161.37,
          },
          single: {
            newPrice: 126.14,
            priceBefore: 115.0,
            priceNow: 119.58,
          },
          suite: {
            newPrice: 114.14,
            priceBefore: 112.29,
            priceNow: 109.04,
          },
        },
      },
      {
        date: '2025-10-16',
        festivos: [],
        pc_ocupacion: 41.0,
        room: {
          double: {
            newPrice: 93.88,
            priceBefore: 90.95,
            priceNow: 91.57,
          },
          single: {
            newPrice: 96.13,
            priceBefore: 90.0,
            priceNow: 98.4,
          },
          suite: {
            newPrice: 140.3,
            priceBefore: 139.57,
            priceNow: 136.47,
          },
        },
      },
      {
        date: '2025-10-17',
        festivos: ['Festividad local de Cívica'],
        pc_ocupacion: 63.0,
        room: {
          double: {
            newPrice: 125.5,
            priceBefore: 128.7,
            priceNow: 141.43,
          },
          single: {
            newPrice: 152.82,
            priceBefore: 140.0,
            priceNow: 138.0,
          },
          suite: {
            newPrice: 202.69,
            priceBefore: 190.8,
            priceNow: 202.05,
          },
        },
      },
      {
        date: '2025-10-18',
        festivos: [],
        pc_ocupacion: 48.0,
        room: {
          double: {
            newPrice: 99.5,
            priceBefore: 95.48,
            priceNow: 102.08,
          },
          single: {
            newPrice: 122.62,
            priceBefore: 112.0,
            priceNow: 114.69,
          },
          suite: {
            newPrice: 168.78,
            priceBefore: 160.2,
            priceNow: 157.74,
          },
        },
      },
      {
        date: '2025-10-19',
        festivos: [],
        pc_ocupacion: 54.0,
        room: {
          double: {
            newPrice: 128.49,
            priceBefore: 117.6,
            priceNow: 126.23,
          },
          single: {
            newPrice: 114.68,
            priceBefore: 119.0,
            priceNow: 129.7,
          },
          suite: {
            newPrice: 160.93,
            priceBefore: 168.63,
            priceNow: 184.62,
          },
        },
      },
      {
        date: '2025-10-20',
        festivos: [],
        pc_ocupacion: 59.0,
        room: {
          double: {
            newPrice: 147.79,
            priceBefore: 139.5,
            priceNow: 151.76,
          },
          single: {
            newPrice: 103.93,
            priceBefore: 106.25,
            priceNow: 101.17,
          },
          suite: {
            newPrice: 193.91,
            priceBefore: 190.8,
            priceNow: 203.87,
          },
        },
      },
      {
        date: '2025-10-21',
        festivos: ['Festividad local de Brihuega'],
        pc_ocupacion: 59.0,
        room: {
          double: {
            newPrice: 133.87,
            priceBefore: 126.72,
            priceNow: 129.93,
          },
          single: {
            newPrice: 70.47,
            priceBefore: 74.15,
            priceNow: 73.53,
          },
          suite: {
            newPrice: 230.92,
            priceBefore: 236.52,
            priceNow: 235.3,
          },
        },
      },
      {
        date: '2025-10-22',
        festivos: [],
        pc_ocupacion: 49.0,
        room: {
          double: {
            newPrice: 105.94,
            priceBefore: 109.8,
            priceNow: 106.54,
          },
          single: {
            newPrice: 101.85,
            priceBefore: 96.3,
            priceNow: 100.55,
          },
          suite: {
            newPrice: 165.03,
            priceBefore: 168.25,
            priceNow: 170.21,
          },
        },
      },
      {
        date: '2025-10-23',
        festivos: [],
        pc_ocupacion: 59.0,
        room: {
          double: {
            newPrice: 113.4,
            priceBefore: 111.3,
            priceNow: 113.69,
          },
          single: {
            newPrice: 110.01,
            priceBefore: 115.0,
            priceNow: 112.49,
          },
          suite: {
            newPrice: 225.07,
            priceBefore: 213.1,
            priceNow: 220.25,
          },
        },
      },
      {
        date: '2025-10-24',
        festivos: ['Festividad local de Hellín'],
        pc_ocupacion: 67.0,
        room: {
          double: {
            newPrice: 181.39,
            priceBefore: 178.59,
            priceNow: 195.9,
          },
          single: {
            newPrice: 90.89,
            priceBefore: 87.08,
            priceNow: 93.94,
          },
          suite: {
            newPrice: 215.1,
            priceBefore: 221.18,
            priceNow: 240.88,
          },
        },
      },
      {
        date: '2025-10-25',
        festivos: [],
        pc_ocupacion: 47.0,
        room: {
          double: {
            newPrice: 104.25,
            priceBefore: 104.4,
            priceNow: 109.05,
          },
          single: {
            newPrice: 98.48,
            priceBefore: 90.9,
            priceNow: 98.88,
          },
          suite: {
            newPrice: 159.13,
            priceBefore: 162.9,
            priceNow: 167.59,
          },
        },
      },
      {
        date: '2025-10-26',
        festivos: [],
        pc_ocupacion: 49.0,
        room: {
          double: {
            newPrice: 102.74,
            priceBefore: 104.4,
            priceNow: 106.04,
          },
          single: {
            newPrice: 76.68,
            priceBefore: 80.1,
            priceNow: 85.25,
          },
          suite: {
            newPrice: 191.11,
            priceBefore: 189.07,
            priceNow: 188.06,
          },
        },
      },
      {
        date: '2025-10-27',
        festivos: [],
        pc_ocupacion: 39.0,
        room: {
          double: {
            newPrice: 113.86,
            priceBefore: 110.0,
            priceNow: 110.09,
          },
          single: {
            newPrice: 94.73,
            priceBefore: 90.95,
            priceNow: 93.83,
          },
          suite: {
            newPrice: 115.09,
            priceBefore: 108.0,
            priceNow: 115.49,
          },
        },
      },
      {
        date: '2025-10-28',
        festivos: [],
        pc_ocupacion: 53.0,
        room: {
          double: {
            newPrice: 115.96,
            priceBefore: 109.8,
            priceNow: 110.57,
          },
          single: {
            newPrice: 104.99,
            priceBefore: 96.3,
            priceNow: 96.2,
          },
          suite: {
            newPrice: 204.56,
            priceBefore: 191.2,
            priceNow: 187.79,
          },
        },
      },
      {
        date: '2025-10-29',
        festivos: [],
        pc_ocupacion: 56.0,
        room: {
          double: {
            newPrice: 111.4,
            priceBefore: 101.58,
            priceNow: 105.67,
          },
          single: {
            newPrice: 116.16,
            priceBefore: 111.25,
            priceNow: 115.58,
          },
          suite: {
            newPrice: 200.03,
            priceBefore: 202.88,
            priceNow: 193.54,
          },
        },
      },
      {
        date: '2025-10-30',
        festivos: ['Festividad local de Barriopedro'],
        pc_ocupacion: 43.0,
        room: {
          double: {
            newPrice: 95.8,
            priceBefore: 94.0,
            priceNow: 97.55,
          },
          single: {
            newPrice: 77.21,
            priceBefore: 80.75,
            priceNow: 78.12,
          },
          suite: {
            newPrice: 165.22,
            priceBefore: 156.6,
            priceNow: 152.08,
          },
        },
      },
      {
        date: '2025-10-31',
        festivos: ['Festividad local de Menasalbas'],
        pc_ocupacion: 48.0,
        room: {
          double: {
            newPrice: 121.78,
            priceBefore: 122.63,
            priceNow: 130.81,
          },
          single: {
            newPrice: 91.75,
            priceBefore: 90.18,
            priceNow: 90.48,
          },
          suite: {
            newPrice: 163.34,
            priceBefore: 153.3,
            priceNow: 150.88,
          },
        },
      },
      {
        date: '2025-11-01',
        festivos: ['Día de Todos los Santos'],
        pc_ocupacion: 53.0,
        room: {
          double: {
            newPrice: 128.5,
            priceBefore: 126.0,
            priceNow: 133.78,
          },
          single: {
            newPrice: 118.98,
            priceBefore: 110.0,
            priceNow: 115.29,
          },
          suite: {
            newPrice: 171.71,
            priceBefore: 161.5,
            priceNow: 172.37,
          },
        },
      },
      {
        date: '2025-11-02',
        festivos: [],
        pc_ocupacion: 55.0,
        room: {
          double: {
            newPrice: 146.24,
            priceBefore: 137.7,
            priceNow: 140.94,
          },
          single: {
            newPrice: 90.08,
            priceBefore: 90.0,
            priceNow: 86.55,
          },
          suite: {
            newPrice: 202.48,
            priceBefore: 186.22,
            priceNow: 203.33,
          },
        },
      },
      {
        date: '2025-11-03',
        festivos: [],
        pc_ocupacion: 45.0,
        room: {
          double: {
            newPrice: 92.61,
            priceBefore: 92.0,
            priceNow: 98.36,
          },
          single: {
            newPrice: 76.04,
            priceBefore: 79.2,
            priceNow: 75.93,
          },
          suite: {
            newPrice: 169.12,
            priceBefore: 175.74,
            priceNow: 172.66,
          },
        },
      },
      {
        date: '2025-11-04',
        festivos: [
          'Festividad local de Las Labores',
          'Festividad local de Barchin del Hoyo',
        ],
        pc_ocupacion: 51.0,
        room: {
          double: {
            newPrice: 108.75,
            priceBefore: 110.13,
            priceNow: 110.45,
          },
          single: {
            newPrice: 89.48,
            priceBefore: 89.0,
            priceNow: 93.26,
          },
          suite: {
            newPrice: 178.77,
            priceBefore: 187.0,
            priceNow: 194.28,
          },
        },
      },
      {
        date: '2025-11-05',
        festivos: [],
        pc_ocupacion: 66.0,
        room: {
          double: {
            newPrice: 145.44,
            priceBefore: 148.91,
            priceNow: 144.51,
          },
          single: {
            newPrice: 151.3,
            priceBefore: 150.0,
            priceNow: 145.58,
          },
          suite: {
            newPrice: 176.35,
            priceBefore: 180.12,
            priceNow: 177.71,
          },
        },
      },
      {
        date: '2025-11-06',
        festivos: [],
        pc_ocupacion: 55.0,
        room: {
          double: {
            newPrice: 79.33,
            priceBefore: 78.2,
            priceNow: 84.27,
          },
          single: {
            newPrice: 154.69,
            priceBefore: 155.52,
            priceNow: 158.82,
          },
          suite: {
            newPrice: 188.44,
            priceBefore: 180.22,
            priceNow: 183.08,
          },
        },
      },
      {
        date: '2025-11-07',
        festivos: [],
        pc_ocupacion: 81.0,
        room: {
          double: {
            newPrice: 217.76,
            priceBefore: 210.0,
            priceNow: 207.18,
          },
          single: {
            newPrice: 177.47,
            priceBefore: 174.15,
            priceNow: 177.51,
          },
          suite: {
            newPrice: 192.84,
            priceBefore: 193.68,
            priceNow: 207.19,
          },
        },
      },
      {
        date: '2025-11-08',
        festivos: ['Festividad local de Pozo de Almoguera'],
        pc_ocupacion: 71.0,
        room: {
          double: {
            newPrice: 233.85,
            priceBefore: 216.0,
            priceNow: 234.72,
          },
          single: {
            newPrice: 142.72,
            priceBefore: 142.45,
            priceNow: 154.56,
          },
          suite: {
            newPrice: 170.79,
            priceBefore: 155.49,
            priceNow: 168.49,
          },
        },
      },
      {
        date: '2025-11-09',
        festivos: [],
        pc_ocupacion: 65.0,
        room: {
          double: {
            newPrice: 151.91,
            priceBefore: 138.24,
            priceNow: 135.81,
          },
          single: {
            newPrice: 90.25,
            priceBefore: 88.0,
            priceNow: 93.36,
          },
          suite: {
            newPrice: 243.93,
            priceBefore: 246.25,
            priceNow: 239.75,
          },
        },
      },
      {
        date: '2025-11-10',
        festivos: [],
        pc_ocupacion: 67.0,
        room: {
          double: {
            newPrice: 129.65,
            priceBefore: 118.07,
            priceNow: 129.5,
          },
          single: {
            newPrice: 146.67,
            priceBefore: 135.0,
            priceNow: 129.2,
          },
          suite: {
            newPrice: 246.91,
            priceBefore: 230.0,
            priceNow: 235.81,
          },
        },
      },
      {
        date: '2025-11-11',
        festivos: [
          'Festividad local de Cañaveras',
          'Festividad local de Fuertescusa',
          'Festividad local de San Martin de Boniches',
          'Festividad local de Anquela del Ducado',
          'Festividad local de Masegoso de Tajuña',
          'Festividad local de Pozo de Almoguera',
          'Festividad local de San Martín de Pusa',
          'Festividad local de Villanueva de Alcardete',
        ],
        pc_ocupacion: 59.0,
        room: {
          double: {
            newPrice: 158.7,
            priceBefore: 166.67,
            priceNow: 170.73,
          },
          single: {
            newPrice: 82.27,
            priceBefore: 75.0,
            priceNow: 73.6,
          },
          suite: {
            newPrice: 200.87,
            priceBefore: 196.67,
            priceNow: 188.25,
          },
        },
      },
      {
        date: '2025-11-12',
        festivos: [
          'Festividad local de Cañamares',
          'Festividad local de Cogolludo',
          'Festividad local de Muduex',
        ],
        pc_ocupacion: 54.0,
        room: {
          double: {
            newPrice: 129.31,
            priceBefore: 128.33,
            priceNow: 124.29,
          },
          single: {
            newPrice: 95.05,
            priceBefore: 92.4,
            priceNow: 94.21,
          },
          suite: {
            newPrice: 195.94,
            priceBefore: 184.13,
            priceNow: 186.52,
          },
        },
      },
      {
        date: '2025-11-13',
        festivos: [],
        pc_ocupacion: 27.0,
        room: {
          double: {
            newPrice: 67.02,
            priceBefore: 68.0,
            priceNow: 70.66,
          },
          single: {
            newPrice: 74.75,
            priceBefore: 68.0,
            priceNow: 66.13,
          },
          suite: {
            newPrice: 93.93,
            priceBefore: 94.54,
            priceNow: 90.84,
          },
        },
      },
      {
        date: '2025-11-14',
        festivos: ['Festividad local de Tartanedo'],
        pc_ocupacion: 45.0,
        room: {
          double: {
            newPrice: 105.39,
            priceBefore: 96.9,
            priceNow: 101.9,
          },
          single: {
            newPrice: 93.21,
            priceBefore: 89.7,
            priceNow: 87.68,
          },
          suite: {
            newPrice: 168.86,
            priceBefore: 164.34,
            priceNow: 169.04,
          },
        },
      },
      {
        date: '2025-11-15',
        festivos: [],
        pc_ocupacion: 44.0,
        room: {
          double: {
            newPrice: 105.3,
            priceBefore: 99.48,
            priceNow: 101.78,
          },
          single: {
            newPrice: 81.81,
            priceBefore: 75.0,
            priceNow: 71.7,
          },
          suite: {
            newPrice: 177.03,
            priceBefore: 168.55,
            priceNow: 176.81,
          },
        },
      },
      {
        date: '2025-11-16',
        festivos: [],
        pc_ocupacion: 45.0,
        room: {
          double: {
            newPrice: 116.56,
            priceBefore: 122.4,
            priceNow: 120.55,
          },
          single: {
            newPrice: 61.91,
            priceBefore: 62.5,
            priceNow: 68.43,
          },
          suite: {
            newPrice: 174.75,
            priceBefore: 162.32,
            priceNow: 168.14,
          },
        },
      },
      {
        date: '2025-11-17',
        festivos: [],
        pc_ocupacion: 59.0,
        room: {
          double: {
            newPrice: 119.77,
            priceBefore: 118.58,
            priceNow: 113.45,
          },
          single: {
            newPrice: 84.67,
            priceBefore: 88.0,
            priceNow: 92.58,
          },
          suite: {
            newPrice: 225.22,
            priceBefore: 231.0,
            priceNow: 220.26,
          },
        },
      },
      {
        date: '2025-11-18',
        festivos: [
          'Festividad local de Arcos de la Cantera',
          'Festividad local de San Román de los Montes',
        ],
        pc_ocupacion: 43.0,
        room: {
          double: {
            newPrice: 137.43,
            priceBefore: 126.0,
            priceNow: 128.32,
          },
          single: {
            newPrice: 53.69,
            priceBefore: 52.0,
            priceNow: 52.66,
          },
          suite: {
            newPrice: 167.01,
            priceBefore: 156.6,
            priceNow: 163.31,
          },
        },
      },
      {
        date: '2025-11-19',
        festivos: [],
        pc_ocupacion: 39.0,
        room: {
          double: {
            newPrice: 95.29,
            priceBefore: 91.2,
            priceNow: 91.05,
          },
          single: {
            newPrice: 71.69,
            priceBefore: 68.0,
            priceNow: 66.5,
          },
          suite: {
            newPrice: 150.77,
            priceBefore: 147.9,
            priceNow: 153.87,
          },
        },
      },
      {
        date: '2025-11-20',
        festivos: [],
        pc_ocupacion: 37.0,
        room: {
          double: {
            newPrice: 93.06,
            priceBefore: 87.67,
            priceNow: 91.44,
          },
          single: {
            newPrice: 77.95,
            priceBefore: 77.14,
            priceNow: 74.49,
          },
          suite: {
            newPrice: 137.01,
            priceBefore: 133.98,
            priceNow: 140.34,
          },
        },
      },
      {
        date: '2025-11-21',
        festivos: [],
        pc_ocupacion: 47.0,
        room: {
          double: {
            newPrice: 121.51,
            priceBefore: 115.6,
            priceNow: 111.83,
          },
          single: {
            newPrice: 80.93,
            priceBefore: 75.0,
            priceNow: 72.39,
          },
          suite: {
            newPrice: 179.43,
            priceBefore: 169.76,
            priceNow: 168.11,
          },
        },
      },
      {
        date: '2025-11-22',
        festivos: [],
        pc_ocupacion: 48.0,
        room: {
          double: {
            newPrice: 103.53,
            priceBefore: 102.6,
            priceNow: 101.16,
          },
          single: {
            newPrice: 113.29,
            priceBefore: 116.0,
            priceNow: 113.05,
          },
          suite: {
            newPrice: 140.8,
            priceBefore: 147.9,
            priceNow: 140.82,
          },
        },
      },
      {
        date: '2025-11-23',
        festivos: ['Festividad local de Zarzuela de Jadraque'],
        pc_ocupacion: 49.0,
        room: {
          double: {
            newPrice: 102.83,
            priceBefore: 106.84,
            priceNow: 102.29,
          },
          single: {
            newPrice: 82.51,
            priceBefore: 83.6,
            priceNow: 89.54,
          },
          suite: {
            newPrice: 177.06,
            priceBefore: 181.0,
            priceNow: 183.59,
          },
        },
      },
      {
        date: '2025-11-24',
        festivos: [],
        pc_ocupacion: 44.0,
        room: {
          double: {
            newPrice: 98.35,
            priceBefore: 101.36,
            priceNow: 102.05,
          },
          single: {
            newPrice: 67.54,
            priceBefore: 69.9,
            priceNow: 74.64,
          },
          suite: {
            newPrice: 177.81,
            priceBefore: 169.72,
            priceNow: 164.97,
          },
        },
      },
      {
        date: '2025-11-25',
        festivos: [
          'Festividad local de Angón',
          'Festividad local de Tórtola de Henares',
        ],
        pc_ocupacion: 55.0,
        room: {
          double: {
            newPrice: 156.38,
            priceBefore: 161.0,
            priceNow: 174.45,
          },
          single: {
            newPrice: 107.99,
            priceBefore: 104.0,
            priceNow: 109.56,
          },
          suite: {
            newPrice: 143.71,
            priceBefore: 144.8,
            priceNow: 140.0,
          },
        },
      },
      {
        date: '2025-11-26',
        festivos: [],
        pc_ocupacion: 49.0,
        room: {
          double: {
            newPrice: 133.48,
            priceBefore: 126.0,
            priceNow: 132.25,
          },
          single: {
            newPrice: 99.12,
            priceBefore: 93.6,
            priceNow: 101.98,
          },
          suite: {
            newPrice: 150.82,
            priceBefore: 150.07,
            priceNow: 153.5,
          },
        },
      },
      {
        date: '2025-11-27',
        festivos: [],
        pc_ocupacion: 51.0,
        room: {
          double: {
            newPrice: 165.63,
            priceBefore: 154.0,
            priceNow: 162.05,
          },
          single: {
            newPrice: 82.91,
            priceBefore: 76.12,
            priceNow: 77.57,
          },
          suite: {
            newPrice: 159.12,
            priceBefore: 153.17,
            priceNow: 149.97,
          },
        },
      },
      {
        date: '2025-11-28',
        festivos: [],
        pc_ocupacion: 49.0,
        room: {
          double: {
            newPrice: 107.14,
            priceBefore: 106.0,
            priceNow: 106.19,
          },
          single: {
            newPrice: 98.23,
            priceBefore: 94.0,
            priceNow: 100.26,
          },
          suite: {
            newPrice: 187.19,
            priceBefore: 174.0,
            priceNow: 186.25,
          },
        },
      },
      {
        date: '2025-11-29',
        festivos: [
          'Festividad local de Viso del Marques',
          'Festividad local de Castillo de Bayuela',
          'Festividad local de San Martín de Montalbán',
        ],
        pc_ocupacion: 42.0,
        room: {
          double: {
            newPrice: 118.41,
            priceBefore: 111.31,
            priceNow: 115.41,
          },
          single: {
            newPrice: 78.3,
            priceBefore: 75.0,
            priceNow: 80.41,
          },
          suite: {
            newPrice: 139.57,
            priceBefore: 142.0,
            priceNow: 139.0,
          },
        },
      },
      {
        date: '2025-11-30',
        festivos: [
          'Festividad local de Paredes',
          'Festividad local de Fuentelahiguera de Albatages',
          'Festividad local de Romanillos de Atienza',
          'Festividad local de San Andrés del Rey',
          'Festividad local de Carmena',
        ],
        pc_ocupacion: 45.0,
        room: {
          double: {
            newPrice: 110.8,
            priceBefore: 106.89,
            priceNow: 114.17,
          },
          single: {
            newPrice: 95.65,
            priceBefore: 88.0,
            priceNow: 84.66,
          },
          suite: {
            newPrice: 156.93,
            priceBefore: 156.52,
            priceNow: 165.26,
          },
        },
      },
      {
        date: '2025-12-01',
        festivos: [],
        pc_ocupacion: 39.0,
        room: {
          double: {
            newPrice: 98.83,
            priceBefore: 91.8,
            priceNow: 94.13,
          },
          single: {
            newPrice: 69.21,
            priceBefore: 68.0,
            priceNow: 71.36,
          },
          suite: {
            newPrice: 155.57,
            priceBefore: 147.9,
            priceNow: 151.57,
          },
        },
      },
      {
        date: '2025-12-02',
        festivos: [],
        pc_ocupacion: 45.0,
        room: {
          double: {
            newPrice: 111.87,
            priceBefore: 117.5,
            priceNow: 121.86,
          },
          single: {
            newPrice: 77.96,
            priceBefore: 74.8,
            priceNow: 71.14,
          },
          suite: {
            newPrice: 153.07,
            priceBefore: 156.6,
            priceNow: 162.81,
          },
        },
      },
      {
        date: '2025-12-03',
        festivos: [],
        pc_ocupacion: 53.0,
        room: {
          double: {
            newPrice: 88.53,
            priceBefore: 87.2,
            priceNow: 95.54,
          },
          single: {
            newPrice: 121.18,
            priceBefore: 120.7,
            priceNow: 119.75,
          },
          suite: {
            newPrice: 197.11,
            priceBefore: 190.0,
            priceNow: 182.81,
          },
        },
      },
      {
        date: '2025-12-04',
        festivos: [
          'Festividad local de Congostrina',
          'Festividad local de Iniestola',
          'Festividad local de Las Hunfrías',
        ],
        pc_ocupacion: 50.0,
        room: {
          double: {
            newPrice: 121.47,
            priceBefore: 119.0,
            priceNow: 115.3,
          },
          single: {
            newPrice: 113.93,
            priceBefore: 103.7,
            priceNow: 107.65,
          },
          suite: {
            newPrice: 153.75,
            priceBefore: 156.6,
            priceNow: 163.18,
          },
        },
      },
      {
        date: '2025-12-05',
        festivos: [
          'Festividad local de Casas de Lazaro',
          'Festividad local de Matillas',
          'Festividad local de Romanones',
        ],
        pc_ocupacion: 45.0,
        room: {
          double: {
            newPrice: 106.41,
            priceBefore: 96.9,
            priceNow: 96.62,
          },
          single: {
            newPrice: 89.39,
            priceBefore: 88.0,
            priceNow: 88.29,
          },
          suite: {
            newPrice: 159.83,
            priceBefore: 165.6,
            priceNow: 171.74,
          },
        },
      },
      {
        date: '2025-12-06',
        festivos: ['Día de la Constitución Española'],
        pc_ocupacion: 59.0,
        room: {
          double: {
            newPrice: 163.87,
            priceBefore: 154.0,
            priceNow: 166.37,
          },
          single: {
            newPrice: 117.09,
            priceBefore: 109.8,
            priceNow: 108.41,
          },
          suite: {
            newPrice: 186.01,
            priceBefore: 174.0,
            priceNow: 171.34,
          },
        },
      },
      {
        date: '2025-12-07',
        festivos: [],
        pc_ocupacion: 50.0,
        room: {
          double: {
            newPrice: 137.46,
            priceBefore: 128.0,
            priceNow: 139.39,
          },
          single: {
            newPrice: 106.81,
            priceBefore: 104.0,
            priceNow: 111.41,
          },
          suite: {
            newPrice: 148.62,
            priceBefore: 148.12,
            priceNow: 157.49,
          },
        },
      },
      {
        date: '2025-12-08',
        festivos: ['Inmaculada Concepción'],
        pc_ocupacion: 52.0,
        room: {
          double: {
            newPrice: 145.52,
            priceBefore: 140.0,
            priceNow: 151.88,
          },
          single: {
            newPrice: 91.37,
            priceBefore: 86.4,
            priceNow: 88.92,
          },
          suite: {
            newPrice: 175.12,
            priceBefore: 165.6,
            priceNow: 168.82,
          },
        },
      },
      {
        date: '2025-12-09',
        festivos: [
          'Festividad local de Almuradiel',
          'Festividad local de Cañada de Calatrava',
          'Festividad local de Arrancacepas',
          'Festividad local de Barchin del Hoyo',
          'Festividad local de Horcajo De Santiago',
          'Festividad local de Viana de Jadraque',
          'Festividad local de Alcaudete de la Jara',
          'Festividad local de Camarenilla',
          'Festividad local de Casasbuenas',
          'Festividad local de Escalona',
          'Festividad local de Navaltoril',
          'Festividad local de Villaseca de la Sagra',
        ],
        pc_ocupacion: 47.0,
        room: {
          double: {
            newPrice: 130.78,
            priceBefore: 119.0,
            priceNow: 126.49,
          },
          single: {
            newPrice: 100.82,
            priceBefore: 95.0,
            priceNow: 94.74,
          },
          suite: {
            newPrice: 145.45,
            priceBefore: 149.06,
            priceNow: 145.98,
          },
        },
      },
      {
        date: '2025-12-10',
        festivos: [
          'Festividad local de Durón',
          'Festividad local de Sienes',
          'Festividad local de Navaltoril',
        ],
        pc_ocupacion: 36.0,
        room: {
          double: {
            newPrice: 137.29,
            priceBefore: 132.39,
            priceNow: 144.36,
          },
          single: {
            newPrice: 74.05,
            priceBefore: 67.5,
            priceNow: 64.51,
          },
          suite: {
            newPrice: 97.3,
            priceBefore: 90.72,
            priceNow: 96.73,
          },
        },
      },
      {
        date: '2025-12-11',
        festivos: [],
        pc_ocupacion: 49.0,
        room: {
          double: {
            newPrice: 126.47,
            priceBefore: 119.0,
            priceNow: 121.43,
          },
          single: {
            newPrice: 77.69,
            priceBefore: 80.0,
            priceNow: 81.88,
          },
          suite: {
            newPrice: 167.12,
            priceBefore: 174.0,
            priceNow: 183.42,
          },
        },
      },
      {
        date: '2025-12-12',
        festivos: [],
        pc_ocupacion: 40.0,
        room: {
          double: {
            newPrice: 102.18,
            priceBefore: 96.9,
            priceNow: 101.03,
          },
          single: {
            newPrice: 77.54,
            priceBefore: 74.8,
            priceNow: 79.57,
          },
          suite: {
            newPrice: 146.12,
            priceBefore: 144.92,
            priceNow: 147.45,
          },
        },
      },
      {
        date: '2025-12-13',
        festivos: [
          'Festividad local de Fuente el Fresno',
          'Festividad local de Retuerta del Bullaque',
          'Festividad local de Almonacid del Marquesado',
          'Festividad local de Bolliga',
          'Festividad local de Castillo-Albarañez',
          'Festividad local de Olmedilla del Campo',
          'Festividad local de Aldeanueva de Guadalajara',
          'Festividad local de Valdeavellano',
        ],
        pc_ocupacion: 54.0,
        room: {
          double: {
            newPrice: 137.01,
            priceBefore: 129.2,
            priceNow: 138.14,
          },
          single: {
            newPrice: 77.33,
            priceBefore: 79.0,
            priceNow: 81.05,
          },
          suite: {
            newPrice: 208.44,
            priceBefore: 196.35,
            priceNow: 187.69,
          },
        },
      },
      {
        date: '2025-12-14',
        festivos: [],
        pc_ocupacion: 50.0,
        room: {
          double: {
            newPrice: 115.76,
            priceBefore: 106.0,
            priceNow: 112.07,
          },
          single: {
            newPrice: 125.38,
            priceBefore: 124.2,
            priceNow: 131.82,
          },
          suite: {
            newPrice: 145.22,
            priceBefore: 148.15,
            priceNow: 151.15,
          },
        },
      },
      {
        date: '2025-12-15',
        festivos: [],
        pc_ocupacion: 30.0,
        room: {
          double: {
            newPrice: 89.62,
            priceBefore: 87.3,
            priceNow: 89.68,
          },
          single: {
            newPrice: 82.47,
            priceBefore: 78.0,
            priceNow: 82.44,
          },
          suite: {
            newPrice: 91.87,
            priceBefore: 89.51,
            priceNow: 91.69,
          },
        },
      },
      {
        date: '2025-12-16',
        festivos: [],
        pc_ocupacion: 41.0,
        room: {
          double: {
            newPrice: 110.76,
            priceBefore: 103.5,
            priceNow: 102.83,
          },
          single: {
            newPrice: 67.48,
            priceBefore: 70.0,
            priceNow: 71.36,
          },
          suite: {
            newPrice: 153.16,
            priceBefore: 147.9,
            priceNow: 144.52,
          },
        },
      },
      {
        date: '2025-12-17',
        festivos: ['Festividad local de Narboneta'],
        pc_ocupacion: 31.0,
        room: {
          double: {
            newPrice: 120.23,
            priceBefore: 110.7,
            priceNow: 112.83,
          },
          single: {
            newPrice: 57.32,
            priceBefore: 56.16,
            priceNow: 60.96,
          },
          suite: {
            newPrice: 87.26,
            priceBefore: 89.44,
            priceNow: 86.33,
          },
        },
      },
      {
        date: '2025-12-18',
        festivos: ['Festividad local de Valverdejo'],
        pc_ocupacion: 42.0,
        room: {
          double: {
            newPrice: 86.04,
            priceBefore: 90.1,
            priceNow: 93.65,
          },
          single: {
            newPrice: 89.71,
            priceBefore: 88.4,
            priceNow: 86.54,
          },
          suite: {
            newPrice: 153.98,
            priceBefore: 148.39,
            priceNow: 149.3,
          },
        },
      },
      {
        date: '2025-12-19',
        festivos: [],
        pc_ocupacion: 36.0,
        room: {
          double: {
            newPrice: 82.41,
            priceBefore: 78.2,
            priceNow: 83.44,
          },
          single: {
            newPrice: 86.27,
            priceBefore: 81.6,
            priceNow: 77.75,
          },
          suite: {
            newPrice: 137.37,
            priceBefore: 133.11,
            priceNow: 141.13,
          },
        },
      },
      {
        date: '2025-12-20',
        festivos: [
          'Festividad local de Torralba',
          'Festividad local de Villaminaya',
        ],
        pc_ocupacion: 49.0,
        room: {
          double: {
            newPrice: 114.33,
            priceBefore: 119.0,
            priceNow: 116.1,
          },
          single: {
            newPrice: 88.22,
            priceBefore: 89.55,
            priceNow: 96.46,
          },
          suite: {
            newPrice: 161.3,
            priceBefore: 160.88,
            priceNow: 175.59,
          },
        },
      },
      {
        date: '2025-12-21',
        festivos: [],
        pc_ocupacion: 41.0,
        room: {
          double: {
            newPrice: 102.41,
            priceBefore: 96.9,
            priceNow: 104.23,
          },
          single: {
            newPrice: 79.97,
            priceBefore: 75.0,
            priceNow: 75.61,
          },
          suite: {
            newPrice: 152.2,
            priceBefore: 147.9,
            priceNow: 148.23,
          },
        },
      },
      {
        date: '2025-12-22',
        festivos: [],
        pc_ocupacion: 39.0,
        room: {
          double: {
            newPrice: 76.8,
            priceBefore: 70.88,
            priceNow: 70.36,
          },
          single: {
            newPrice: 94.41,
            priceBefore: 90.0,
            priceNow: 97.22,
          },
          suite: {
            newPrice: 145.76,
            priceBefore: 146.2,
            priceNow: 153.66,
          },
        },
      },
      {
        date: '2025-12-23',
        festivos: ['Festividad local de Huecas'],
        pc_ocupacion: 52.0,
        room: {
          double: {
            newPrice: 107.81,
            priceBefore: 110.7,
            priceNow: 108.97,
          },
          single: {
            newPrice: 85.8,
            priceBefore: 88.4,
            priceNow: 90.18,
          },
          suite: {
            newPrice: 187.88,
            priceBefore: 192.95,
            priceNow: 204.04,
          },
        },
      },
      {
        date: '2025-12-24',
        festivos: [],
        pc_ocupacion: 63.0,
        room: {
          double: {
            newPrice: 168.14,
            priceBefore: 172.85,
            priceNow: 188.25,
          },
          single: {
            newPrice: 143.51,
            priceBefore: 139.6,
            priceNow: 147.59,
          },
          suite: {
            newPrice: 153.33,
            priceBefore: 146.2,
            priceNow: 147.7,
          },
        },
      },
      {
        date: '2025-12-25',
        festivos: ['Natividad del Señor'],
        pc_ocupacion: 35.0,
        room: {
          double: {
            newPrice: 73.83,
            priceBefore: 74.61,
            priceNow: 73.84,
          },
          single: {
            newPrice: 91.18,
            priceBefore: 91.6,
            priceNow: 88.21,
          },
          suite: {
            newPrice: 120.17,
            priceBefore: 120.0,
            priceNow: 118.0,
          },
        },
      },
      {
        date: '2025-12-26',
        festivos: [
          'Festividad local de Liétor',
          'Festividad local de Barbalimpia',
          'Festividad local de La Huerguina',
          'Festividad local de Humanes',
        ],
        pc_ocupacion: 47.0,
        room: {
          double: {
            newPrice: 90.95,
            priceBefore: 83.16,
            priceNow: 90.08,
          },
          single: {
            newPrice: 113.81,
            priceBefore: 110.34,
            priceNow: 118.03,
          },
          suite: {
            newPrice: 168.61,
            priceBefore: 163.34,
            priceNow: 171.65,
          },
        },
      },
      {
        date: '2025-12-27',
        festivos: ['Festividad local de Casasimarro'],
        pc_ocupacion: 44.0,
        room: {
          double: {
            newPrice: 113.56,
            priceBefore: 114.9,
            priceNow: 122.17,
          },
          single: {
            newPrice: 60.67,
            priceBefore: 58.36,
            priceNow: 62.74,
          },
          suite: {
            newPrice: 161.31,
            priceBefore: 168.9,
            priceNow: 162.96,
          },
        },
      },
      {
        date: '2025-12-28',
        festivos: ['Festividad local de Alcázar de San Juan'],
        pc_ocupacion: 69.0,
        room: {
          double: {
            newPrice: 143.37,
            priceBefore: 131.35,
            priceNow: 141.6,
          },
          single: {
            newPrice: 198.1,
            priceBefore: 192.95,
            priceNow: 193.13,
          },
          suite: {
            newPrice: 195.93,
            priceBefore: 178.67,
            priceNow: 174.38,
          },
        },
      },
      {
        date: '2025-12-29',
        festivos: [],
        pc_ocupacion: 100.0,
        room: {
          double: {
            newPrice: 199.64,
            priceBefore: 198.88,
            priceNow: 203.24,
          },
          single: {
            newPrice: 141.18,
            priceBefore: 145.8,
            priceNow: 139.43,
          },
          suite: {
            newPrice: 356.44,
            priceBefore: 349.63,
            priceNow: 346.28,
          },
        },
      },
      {
        date: '2025-12-30',
        festivos: ['Festividad local de Alcabón'],
        pc_ocupacion: 69.0,
        room: {
          double: {
            newPrice: 203.11,
            priceBefore: 188.0,
            priceNow: 199.01,
          },
          single: {
            newPrice: 130.84,
            priceBefore: 122.48,
            priceNow: 133.91,
          },
          suite: {
            newPrice: 190.01,
            priceBefore: 188.88,
            priceNow: 195.54,
          },
        },
      },
      {
        date: '2025-12-31',
        festivos: ['Festividad local de El Robledo'],
        pc_ocupacion: 55.0,
        room: {
          double: {
            newPrice: 119.3,
            priceBefore: 118.2,
            priceNow: 122.79,
          },
          single: {
            newPrice: 84.43,
            priceBefore: 86.7,
            priceNow: 92.15,
          },
          suite: {
            newPrice: 203.93,
            priceBefore: 208.0,
            priceNow: 220.46,
          },
        },
      },
    ],
  },
  {
    stablishment_name: 'Hotel Oceania',
    stablishment_id: '2',
    details: [
      {
        date: '2024-07-19',
        festivos: [
          'Festividad local de Villarejo Sobrehuerta',
          'Festividad local de Cabanillas del Campo',
          'Festividad local de Chozas de Canales',
          'Festividad local de Noblejas',
        ],
        pc_ocupacion: 21.0,
        room: {
          double: {
            newPrice: 64.94,
            priceBefore: 66.73,
            priceNow: 70.36,
          },
          single: {
            newPrice: 109.77,
            priceBefore: 106.39,
            priceNow: 107.47,
          },
          suite: {
            newPrice: 94.11,
            priceBefore: 98.17,
            priceNow: 93.84,
          },
        },
      },
      {
        date: '2024-07-20',
        festivos: ['Festividad local de Cendejas de Enmedio'],
        pc_ocupacion: 31.0,
        room: {
          double: {
            newPrice: 62.95,
            priceBefore: 65.81,
            priceNow: 69.69,
          },
          single: {
            newPrice: 138.35,
            priceBefore: 135.96,
            priceNow: 142.47,
          },
          suite: {
            newPrice: 113.97,
            priceBefore: 109.68,
            priceNow: 118.51,
          },
        },
      },
      {
        date: '2024-07-21',
        festivos: ['Festividad local de Cendejas de Padrastro'],
        pc_ocupacion: 19.0,
        room: {
          double: {
            newPrice: 54.52,
            priceBefore: 56.53,
            priceNow: 62.16,
          },
          single: {
            newPrice: 75.36,
            priceBefore: 70.64,
            priceNow: 70.91,
          },
          suite: {
            newPrice: 114.73,
            priceBefore: 113.33,
            priceNow: 114.59,
          },
        },
      },
      {
        date: '2024-07-22',
        festivos: [
          'Festividad local de Villalgordo del Júcar',
          'Festividad local de Poblete',
          'Festividad local de Huerta del Marquesado',
          'Festividad local de Poyatos',
          'Festividad local de Cendejas de Padrastro',
          'Festividad local de Mondéjar',
          'Festividad local de Valdeavellano',
          'Festividad local de Carranque',
          'Festividad local de El Viso de San Juan',
          'Festividad local de Escalonilla',
          'Festividad local de Garciotum',
          'Festividad local de Guadamur',
          'Festividad local de Layos',
          'Festividad local de Mascaraque',
          'Festividad local de Villamuelas',
          'Festividad local de Villasequilla',
        ],
        pc_ocupacion: 20.0,
        room: {
          double: {
            newPrice: 55.1,
            priceBefore: 56.14,
            priceNow: 58.95,
          },
          single: {
            newPrice: 110.13,
            priceBefore: 109.67,
            priceNow: 108.84,
          },
          suite: {
            newPrice: 101.76,
            priceBefore: 93.82,
            priceNow: 90.59,
          },
        },
      },
      {
        date: '2024-07-23',
        festivos: ['Festividad local de Castillejo de Iniesta'],
        pc_ocupacion: 32.0,
        room: {
          double: {
            newPrice: 59.22,
            priceBefore: 59.85,
            priceNow: 57.29,
          },
          single: {
            newPrice: 127.21,
            priceBefore: 129.27,
            priceNow: 125.63,
          },
          suite: {
            newPrice: 154.24,
            priceBefore: 143.01,
            priceNow: 145.93,
          },
        },
      },
      {
        date: '2024-07-24',
        festivos: [],
        pc_ocupacion: 30.0,
        room: {
          double: {
            newPrice: 52.75,
            priceBefore: 55.09,
            priceNow: 55.23,
          },
          single: {
            newPrice: 92.71,
            priceBefore: 92.7,
            priceNow: 91.64,
          },
          suite: {
            newPrice: 175.56,
            priceBefore: 159.74,
            priceNow: 163.02,
          },
        },
      },
      {
        date: '2024-07-25',
        festivos: [
          'Festividad local de Santa Ana',
          'Festividad local de Carrión de Calatrava',
          'Festividad local de Granátula de Calatrava',
          'Festividad local de La Solana',
          'Festividad local de Membrilla',
          'Festividad local de Viso del Marques',
          'Festividad local de Valsalobre',
          'Festividad local de Campisabalos',
          'Festividad local de Mohernando',
          'Festividad local de Valdelcubo',
          'Festividad local de Alcañizo',
          'Festividad local de Burujón',
          'Festividad local de Carpio de Tajo',
          'Festividad local de Espinoso del Rey',
          'Festividad local de Nombela',
          'Festividad local de Quero',
          'Festividad local de Santo Domingo Caudilla',
          'Festividad local de Sartajada',
          'Festividad local de Sotillo de las Palomas',
        ],
        pc_ocupacion: 51.0,
        room: {
          double: {
            newPrice: 150.64,
            priceBefore: 153.68,
            priceNow: 165.33,
          },
          single: {
            newPrice: 120.88,
            priceBefore: 115.81,
            priceNow: 115.61,
          },
          suite: {
            newPrice: 181.98,
            priceBefore: 182.75,
            priceNow: 183.76,
          },
        },
      },
      {
        date: '2024-07-26',
        festivos: [
          'Festividad local de Motilleja',
          'Festividad local de Pozo Lorente',
          'Festividad local de Santa Ana',
          'Festividad local de Almadén',
          'Festividad local de Cabezarados',
          'Festividad local de Fontanarejo',
          'Festividad local de Carrascosa del Campo',
          'Festividad local de El Pedernoso',
          'Festividad local de Gabaldon',
          'Festividad local de Pineda de Gigüela',
          'Festividad local de Reillo',
          'Festividad local de Valsalobre',
          'Festividad local de Vindel',
          'Festividad local de Alarilla',
          'Festividad local de Campisabalos',
          'Festividad local de Muduex',
          'Festividad local de Torremocha del Campo',
          'Festividad local de Añover de Tajo',
          'Festividad local de Burujón',
          'Festividad local de Camarenilla',
          'Festividad local de Mora',
          'Festividad local de Nombela',
          'Festividad local de Santa Ana de Pusa',
          'Festividad local de Santo Domingo Caudilla',
          'Festividad local de Villanueva de Bogas',
        ],
        pc_ocupacion: 34.0,
        room: {
          double: {
            newPrice: 65.48,
            priceBefore: 60.18,
            priceNow: 64.93,
          },
          single: {
            newPrice: 95.41,
            priceBefore: 97.66,
            priceNow: 104.41,
          },
          suite: {
            newPrice: 180.52,
            priceBefore: 180.08,
            priceNow: 177.69,
          },
        },
      },
      {
        date: '2024-07-27',
        festivos: [
          'Festividad local de Ribatajadilla',
          'Festividad local de Ciruelos del Pinar',
        ],
        pc_ocupacion: 29.0,
        room: {
          double: {
            newPrice: 51.53,
            priceBefore: 50.28,
            priceNow: 48.22,
          },
          single: {
            newPrice: 66.95,
            priceBefore: 68.64,
            priceNow: 71.91,
          },
          suite: {
            newPrice: 172.39,
            priceBefore: 174.9,
            priceNow: 176.07,
          },
        },
      },
      {
        date: '2024-07-28',
        festivos: [],
        pc_ocupacion: 35.0,
        room: {
          double: {
            newPrice: 43.36,
            priceBefore: 41.87,
            priceNow: 43.59,
          },
          single: {
            newPrice: 119.95,
            priceBefore: 113.72,
            priceNow: 111.82,
          },
          suite: {
            newPrice: 207.34,
            priceBefore: 194.57,
            priceNow: 204.97,
          },
        },
      },
      {
        date: '2024-07-29',
        festivos: [
          'Festividad local de Cabezarrubias del Puerto',
          'Festividad local de Mestanza',
          'Festividad local de Solana del Pino',
          'Festividad local de Cobisa',
          'Festividad local de Ontígola',
        ],
        pc_ocupacion: 43.0,
        room: {
          double: {
            newPrice: 108.79,
            priceBefore: 112.42,
            priceNow: 115.21,
          },
          single: {
            newPrice: 135.72,
            priceBefore: 132.8,
            priceNow: 135.72,
          },
          suite: {
            newPrice: 205.33,
            priceBefore: 197.02,
            priceNow: 190.82,
          },
        },
      },
      {
        date: '2024-07-30',
        festivos: [],
        pc_ocupacion: 33.0,
        room: {
          double: {
            newPrice: 51.68,
            priceBefore: 48.71,
            priceNow: 49.86,
          },
          single: {
            newPrice: 104.26,
            priceBefore: 102.47,
            priceNow: 101.1,
          },
          suite: {
            newPrice: 187.1,
            priceBefore: 176.76,
            priceNow: 188.56,
          },
        },
      },
      {
        date: '2024-07-31',
        festivos: ['Festividad local de Tribaldos'],
        pc_ocupacion: 27.0,
        room: {
          double: {
            newPrice: 40.33,
            priceBefore: 37.08,
            priceNow: 37.66,
          },
          single: {
            newPrice: 65.55,
            priceBefore: 64.7,
            priceNow: 62.36,
          },
          suite: {
            newPrice: 184.46,
            priceBefore: 188.74,
            priceNow: 184.4,
          },
        },
      },
      {
        date: '2024-08-01',
        festivos: [
          'Festividad local de San Pedro Palmiches',
          'Festividad local de Villalpardo',
          'Festividad local de Las Ventas de Retamosa',
        ],
        pc_ocupacion: 30.0,
        room: {
          double: {
            newPrice: 31.38,
            priceBefore: 32.5,
            priceNow: 33.46,
          },
          single: {
            newPrice: 105.48,
            priceBefore: 98.45,
            priceNow: 96.18,
          },
          suite: {
            newPrice: 189.53,
            priceBefore: 188.6,
            priceNow: 189.93,
          },
        },
      },
      {
        date: '2024-08-02',
        festivos: [
          'Festividad local de Calzada de Calatrava',
          'Festividad local de Pedro Muñoz',
          'Festividad local de Buciegas',
          'Festividad local de La Huerguina',
          'Festividad local de Villar del Águila',
          'Festividad local de Yemeda',
          'Festividad local de Amayas',
          'Festividad local de El Olivar',
          'Festividad local de El Ordial',
          'Festividad local de Escopete',
          'Festividad local de La Bodera',
          'Festividad local de Renera',
          'Festividad local de Robledo de Corpes',
          'Festividad local de Torre del Burgo',
          'Festividad local de Valdesotos',
          'Festividad local de Argés',
          'Festividad local de Los Cerralbos',
          'Festividad local de Montearagón',
        ],
        pc_ocupacion: 35.0,
        room: {
          double: {
            newPrice: 97.89,
            priceBefore: 98.7,
            priceNow: 101.59,
          },
          single: {
            newPrice: 106.75,
            priceBefore: 103.61,
            priceNow: 111.77,
          },
          suite: {
            newPrice: 187.23,
            priceBefore: 189.66,
            priceNow: 193.93,
          },
        },
      },
      {
        date: '2024-08-03',
        festivos: [
          'Festividad local de Amayas',
          'Festividad local de El Olivar',
          'Festividad local de Gualda',
          'Festividad local de La Bodera',
          'Festividad local de Bargas',
        ],
        pc_ocupacion: 24.0,
        room: {
          double: {
            newPrice: 111.18,
            priceBefore: 104.52,
            priceNow: 101.48,
          },
          single: {
            newPrice: 88.85,
            priceBefore: 83.98,
            priceNow: 84.2,
          },
          suite: {
            newPrice: 104.69,
            priceBefore: 104.31,
            priceNow: 113.16,
          },
        },
      },
      {
        date: '2024-08-04',
        festivos: [
          'Festividad local de Algar de Mesa',
          'Festividad local de Bañuelos',
          'Festividad local de Loranca de Tajuña',
          'Festividad local de Olmeda de Cobeta',
          'Festividad local de Somolinos',
          'Festividad local de Valdepinillos',
        ],
        pc_ocupacion: 32.0,
        room: {
          double: {
            newPrice: 78.9,
            priceBefore: 82.26,
            priceNow: 88.74,
          },
          single: {
            newPrice: 92.8,
            priceBefore: 85.01,
            priceNow: 87.78,
          },
          suite: {
            newPrice: 196.62,
            priceBefore: 197.67,
            priceNow: 216.11,
          },
        },
      },
      {
        date: '2024-08-05',
        festivos: [
          'Festividad local de Cenizate',
          'Festividad local de Chinchilla',
          'Festividad local de Almagro',
          'Festividad local de Valdepeñas',
          'Festividad local de Almodóvar del Pinar',
          'Festividad local de Tejadillos',
          'Festividad local de Torralba',
          'Festividad local de Villanueva de la Jara',
          'Festividad local de Anguita',
          'Festividad local de Membrillera',
          'Festividad local de Ocentejo',
          'Festividad local de Renera',
          'Festividad local de Valdesotos',
          'Festividad local de Valtablado de Rio',
          'Festividad local de Lagartera',
          'Festividad local de Malpica de Tajo',
          'Festividad local de Pulgar',
          'Festividad local de Quero',
          'Festividad local de Torralba de Oropesa',
        ],
        pc_ocupacion: 37.0,
        room: {
          double: {
            newPrice: 111.07,
            priceBefore: 110.58,
            priceNow: 106.84,
          },
          single: {
            newPrice: 84.93,
            priceBefore: 86.82,
            priceNow: 82.89,
          },
          suite: {
            newPrice: 203.26,
            priceBefore: 193.33,
            priceNow: 203.85,
          },
        },
      },
      {
        date: '2024-08-06',
        festivos: [
          'Festividad local de La Roda',
          'Festividad local de Socovos',
          'Festividad local de Pozuelo de Calatrava',
          'Festividad local de Olmedilla de Eliz',
          'Festividad local de Huérmeces del Cerro',
          'Festividad local de Málaga del Fresno',
          'Festividad local de Montarrón',
          'Festividad local de Puebla de Valles',
          'Festividad local de Santiuste',
          'Festividad local de Torralba de Oropesa',
        ],
        pc_ocupacion: 34.0,
        room: {
          double: {
            newPrice: 81.93,
            priceBefore: 79.16,
            priceNow: 75.96,
          },
          single: {
            newPrice: 95.71,
            priceBefore: 90.44,
            priceNow: 87.19,
          },
          suite: {
            newPrice: 199.36,
            priceBefore: 181.53,
            priceNow: 174.09,
          },
        },
      },
      {
        date: '2024-08-07',
        festivos: [
          'Festividad local de Tresjuncos',
          'Festividad local de Pradena de Atienza',
          'Festividad local de Somolinos',
          'Festividad local de Tordellego',
        ],
        pc_ocupacion: 38.0,
        room: {
          double: {
            newPrice: 76.6,
            priceBefore: 74.63,
            priceNow: 74.36,
          },
          single: {
            newPrice: 115.09,
            priceBefore: 106.01,
            priceNow: 114.61,
          },
          suite: {
            newPrice: 220.2,
            priceBefore: 214.94,
            priceNow: 225.63,
          },
        },
      },
      {
        date: '2024-08-08',
        festivos: [
          'Festividad local de Terrinches',
          'Festividad local de Moya',
          'Festividad local de Ocentejo',
          'Festividad local de Torremocha del Pinar',
          'Festividad local de Valtablado de Rio',
          'Festividad local de Valmojado',
        ],
        pc_ocupacion: 39.0,
        room: {
          double: {
            newPrice: 67.25,
            priceBefore: 65.23,
            priceNow: 71.6,
          },
          single: {
            newPrice: 120.12,
            priceBefore: 125.51,
            priceNow: 121.67,
          },
          suite: {
            newPrice: 210.91,
            priceBefore: 194.6,
            priceNow: 205.55,
          },
        },
      },
      {
        date: '2024-08-09',
        festivos: [
          'Festividad local de El Ballestero',
          'Festividad local de San Lorenzo de Calatrava',
          'Festividad local de Santa Cruz de Mudela',
          'Festividad local de Casas de Garcimolina',
          'Festividad local de Mota de Altarejos',
          'Festividad local de Naharros',
          'Festividad local de Adobes',
          'Festividad local de Albendiego',
          'Festividad local de Castellar de la Muela',
          'Festividad local de Peñalén',
          'Festividad local de Riofrio del Llano',
          'Festividad local de Tortuero',
          'Festividad local de Viñuelas',
          'Festividad local de Campillo de La Jara',
          'Festividad local de Parrillas',
        ],
        pc_ocupacion: 35.0,
        room: {
          double: {
            newPrice: 85.98,
            priceBefore: 80.94,
            priceNow: 82.34,
          },
          single: {
            newPrice: 91.4,
            priceBefore: 91.75,
            priceNow: 88.64,
          },
          suite: {
            newPrice: 215.96,
            priceBefore: 201.91,
            priceNow: 198.61,
          },
        },
      },
      {
        date: '2024-08-10',
        festivos: [
          'Festividad local de Socuéllamos',
          'Festividad local de Arguisuelas',
          'Festividad local de Bustares',
          'Festividad local de Carrascosa de Henares',
          'Festividad local de Negredo',
          'Festividad local de Riofrio del Llano',
          'Festividad local de Romanillos de Atienza',
          'Festividad local de Torremochuela',
        ],
        pc_ocupacion: 37.0,
        room: {
          double: {
            newPrice: 87.38,
            priceBefore: 87.65,
            priceNow: 94.8,
          },
          single: {
            newPrice: 111.14,
            priceBefore: 113.92,
            priceNow: 116.14,
          },
          suite: {
            newPrice: 203.72,
            priceBefore: 206.5,
            priceNow: 217.93,
          },
        },
      },
      {
        date: '2024-08-11',
        festivos: [
          'Festividad local de Anchuela del Campo',
          'Festividad local de Negredo',
        ],
        pc_ocupacion: 44.0,
        room: {
          double: {
            newPrice: 140.61,
            priceBefore: 131.5,
            priceNow: 129.67,
          },
          single: {
            newPrice: 119.02,
            priceBefore: 114.13,
            priceNow: 112.04,
          },
          suite: {
            newPrice: 198.69,
            priceBefore: 205.27,
            priceNow: 198.86,
          },
        },
      },
      {
        date: '2024-08-12',
        festivos: [
          'Festividad local de Chillón',
          'Festividad local de Beamud',
          'Festividad local de Cervera del Llano',
          'Festividad local de Mota de Altarejos',
          'Festividad local de Mota del Cuervo',
          'Festividad local de San Lorenzo de la Parrilla',
          'Festividad local de Villarrubio',
          'Festividad local de Arbeteta',
          'Festividad local de Campillo de Ranas',
          'Festividad local de Checa',
          'Festividad local de Concha',
          'Festividad local de Gárgoles de Abajo',
          'Festividad local de Hinojosa',
          'Festividad local de Mantiel',
          'Festividad local de Peralveche',
        ],
        pc_ocupacion: 36.0,
        room: {
          double: {
            newPrice: 68.41,
            priceBefore: 63.59,
            priceNow: 67.77,
          },
          single: {
            newPrice: 121.73,
            priceBefore: 119.3,
            priceNow: 128.51,
          },
          suite: {
            newPrice: 188.29,
            priceBefore: 193.77,
            priceNow: 199.92,
          },
        },
      },
      {
        date: '2024-08-13',
        festivos: [
          'Festividad local de Beamud',
          'Festividad local de Castillo de Garcimuñoz',
          'Festividad local de Villarrubio',
          'Festividad local de Estables',
          'Festividad local de Terzaga',
        ],
        pc_ocupacion: 36.0,
        room: {
          double: {
            newPrice: 67.18,
            priceBefore: 68.47,
            priceNow: 73.43,
          },
          single: {
            newPrice: 114.54,
            priceBefore: 111.1,
            priceNow: 120.04,
          },
          suite: {
            newPrice: 219.76,
            priceBefore: 200.86,
            priceNow: 199.43,
          },
        },
      },
      {
        date: '2024-08-14',
        festivos: [
          'Festividad local de Jorquera',
          'Festividad local de Castillo de Garcimuñoz',
          'Festividad local de San Martin de Boniches',
          'Festividad local de Uña',
          'Festividad local de Campillo de Ranas',
          'Festividad local de Congostrina',
          'Festividad local de La Mierla',
          'Festividad local de Las Navas de Jadraque',
          'Festividad local de Prados Redondos',
          'Festividad local de Rebollosa de Jadraque',
          'Festividad local de Retiendas',
          'Festividad local de Sayatón',
          'Festividad local de Terzaga',
          'Festividad local de Uceda',
          'Festividad local de Alameda de la Sagra',
          'Festividad local de Bernuy',
          'Festividad local de Fuentes',
          'Festividad local de La Estrella',
          'Festividad local de Romeral',
        ],
        pc_ocupacion: 39.0,
        room: {
          double: {
            newPrice: 70.21,
            priceBefore: 67.41,
            priceNow: 65.23,
          },
          single: {
            newPrice: 158.66,
            priceBefore: 144.85,
            priceNow: 145.64,
          },
          suite: {
            newPrice: 219.97,
            priceBefore: 208.33,
            priceNow: 219.04,
          },
        },
      },
      {
        date: '2024-08-15',
        festivos: [
          'Asunción de la Virgen',
          'Festividad local de Hontecillas',
          'Festividad local de Anchuela del Pedregal',
          'Festividad local de Arbeteta',
          'Festividad local de Gascueña de Bornova',
          'Festividad local de Peralveche',
          'Festividad local de Tordelpalo',
        ],
        pc_ocupacion: 39.0,
        room: {
          double: {
            newPrice: 73.23,
            priceBefore: 66.92,
            priceNow: 65.0,
          },
          single: {
            newPrice: 127.7,
            priceBefore: 131.34,
            priceNow: 141.18,
          },
          suite: {
            newPrice: 218.21,
            priceBefore: 209.74,
            priceNow: 221.76,
          },
        },
      },
      {
        date: '2024-08-16',
        festivos: [
          'Festividad local de Barrax',
          'Festividad local de Bormate',
          'Festividad local de Fuentealbilla',
          'Festividad local de Letur',
          'Festividad local de Salobre',
          'Festividad local de Tobarra',
          'Festividad local de Villalgordo del Júcar',
          'Festividad local de Villarrobledo',
          'Festividad local de Villatoya',
          'Festividad local de Alamillo',
          'Festividad local de Alcolea de Calatrava',
          'Festividad local de Moral de Calatrava',
          'Festividad local de Torre de Juan Abad',
          'Festividad local de Aliaguilla',
          'Festividad local de Arcos de la Cantera',
          'Festividad local de Barbalimpia',
          'Festividad local de Bascuñana de San Pedro',
          'Festividad local de Bolliga',
          'Festividad local de Boniches',
          'Festividad local de Buenache de la Sierra',
          'Festividad local de Campillos Paravientos',
          'Festividad local de Cañamares',
          'Festividad local de Carboneras de Guadazaon',
          'Festividad local de Castillejo-Sierra',
          'Festividad local de El Provencio',
          'Festividad local de Enguidanos',
          'Festividad local de Fuentesbuenas',
          'Festividad local de Garcinarro',
          'Festividad local de Henarejos',
          'Festividad local de La Pesquera',
          'Festividad local de Lagunaseca',
          'Festividad local de Landete',
          'Festividad local de Ledaña',
          'Festividad local de Monteagudo de las Salinas',
          'Festividad local de Navalon',
          'Festividad local de Olivares del Jucar',
          'Festividad local de Olmedilla de Alarcon',
          'Festividad local de Olmedilla del Campo',
          'Festividad local de Pajaroncillo',
          'Festividad local de Portalrubio de Guadamejud',
          'Festividad local de Portilla',
          'Festividad local de Poyatos',
          'Festividad local de Rada de Haro',
          'Festividad local de Reillo',
          'Festividad local de Salinas del Manzano',
          'Festividad local de Sotoca',
          'Festividad local de Tondos',
          'Festividad local de Torrecilla',
          'Festividad local de Uña',
          'Festividad local de Valdecabras',
          'Festividad local de Valdemorillo de la Sierra',
          'Festividad local de Valeria',
          'Festividad local de Villaescusa de Haro',
          'Festividad local de Villalba de la Sierra',
          'Festividad local de Villanueva de los Escuderos',
          'Festividad local de Villar de la Encina',
          'Festividad local de Villar del Infantado',
          'Festividad local de Villarejo del Espartal',
          'Festividad local de Villarejo Seco',
          'Festividad local de Villarta',
          'Festividad local de Villora',
          'Festividad local de Vindel',
          'Festividad local de Alcocer',
          'Festividad local de Alcolea de las Peñas',
          'Festividad local de Alcolea del Pinar',
          'Festividad local de Aldeanueva de Atienza',
          'Festividad local de Aldeanueva de Guadalajara',
          'Festividad local de Alique',
          'Festividad local de Alocén',
          'Festividad local de Anquela del Pedregal',
          'Festividad local de Baides',
          'Festividad local de Brihuega',
          'Festividad local de Bujarrabal',
          'Festividad local de Bustares',
          'Festividad local de Canales del Ducado',
          'Festividad local de Cincovillas',
          'Festividad local de Cívica',
          'Festividad local de Condemios de Arriba',
          'Festividad local de Copernal',
          'Festividad local de Durón',
          'Festividad local de El Sotillo',
          'Festividad local de Fontanar',
          'Festividad local de Fuentelsaz',
          'Festividad local de Fuentelviejo',
          'Festividad local de Gárgoles de Abajo',
          'Festividad local de Gascueña de Bornova',
          'Festividad local de Illana',
          'Festividad local de Las Navas de Jadraque',
          'Festividad local de Mazarete',
          'Festividad local de Megina',
          'Festividad local de Olmeda de Cobeta',
          'Festividad local de Piqueras',
          'Festividad local de Poveda de la Sierra',
          'Festividad local de Rebollosa de Jadraque',
          'Festividad local de San Andrés del Congosto',
          'Festividad local de San Andrés del Rey',
          'Festividad local de Sayatón',
          'Festividad local de Selas',
          'Festividad local de Semillas',
          'Festividad local de Sienes',
          'Festividad local de Sigüenza',
          'Festividad local de Tierzo',
          'Festividad local de Tordelpalo',
          'Festividad local de Tordelrábano',
          'Festividad local de Torrejón del Rey',
          'Festividad local de Uceda',
          'Festividad local de Valdearenas',
          'Festividad local de Yélamos de Arriba',
          'Festividad local de Zarzuela de Jadraque',
          'Festividad local de Alameda de la Sagra',
          'Festividad local de Camarena',
          'Festividad local de Camuñas',
          'Festividad local de Casar de Escalona',
          'Festividad local de Cervera de los Montes',
          'Festividad local de Erustes',
          'Festividad local de Escalona',
          'Festividad local de Las Navillas',
          'Festividad local de Los Alares',
          'Festividad local de Magán',
          'Festividad local de Mazarambroz',
          'Festividad local de Mohedas de La Jara',
          'Festividad local de Navalcán',
          'Festividad local de Nuño Gómez',
          'Festividad local de Palomeque',
          'Festividad local de Quintanar de la Orden',
          'Festividad local de Quismondo',
          'Festividad local de San Román de los Montes',
          'Festividad local de Seseña',
          'Festividad local de Valdeverdeja',
        ],
        pc_ocupacion: 29.0,
        room: {
          double: {
            newPrice: 73.07,
            priceBefore: 70.68,
            priceNow: 76.72,
          },
          single: {
            newPrice: 82.17,
            priceBefore: 75.58,
            priceNow: 72.13,
          },
          suite: {
            newPrice: 210.4,
            priceBefore: 202.48,
            priceNow: 205.83,
          },
        },
      },
      {
        date: '2024-08-17',
        festivos: [
          'Festividad local de Hijes',
          'Festividad local de Mazarete',
          'Festividad local de Piqueras',
          'Festividad local de Poveda de la Sierra',
          'Festividad local de Taravilla',
        ],
        pc_ocupacion: 57.0,
        room: {
          double: {
            newPrice: 181.81,
            priceBefore: 171.89,
            priceNow: 178.47,
          },
          single: {
            newPrice: 165.98,
            priceBefore: 155.84,
            priceNow: 157.72,
          },
          suite: {
            newPrice: 194.3,
            priceBefore: 201.76,
            priceNow: 198.08,
          },
        },
      },
      {
        date: '2024-08-18',
        festivos: [
          'Festividad local de Castilnuevo',
          'Festividad local de El Ordial',
          'Festividad local de Estriegana',
          'Festividad local de La Toba',
          'Festividad local de Taravilla',
          'Festividad local de Torrecuadrada de Molina',
        ],
        pc_ocupacion: 50.0,
        room: {
          double: {
            newPrice: 183.94,
            priceBefore: 167.46,
            priceNow: 172.63,
          },
          single: {
            newPrice: 112.55,
            priceBefore: 116.72,
            priceNow: 128.04,
          },
          suite: {
            newPrice: 197.11,
            priceBefore: 201.24,
            priceNow: 211.01,
          },
        },
      },
      {
        date: '2024-08-19',
        festivos: [
          'Festividad local de Nerpio',
          'Festividad local de Membrilla',
          'Festividad local de El Provencio',
          'Festividad local de Moya',
          'Festividad local de Tinajas',
          'Festividad local de Torrubia del Castillo',
          'Festividad local de Villar de Domingo Garcia',
          'Festividad local de Alocén',
          'Festividad local de Estriegana',
          'Festividad local de Labros',
          'Festividad local de Tamajón',
          'Festividad local de Esquivias',
          'Festividad local de Retamoso de la Jara',
          'Festividad local de Valdeverdeja',
        ],
        pc_ocupacion: 31.0,
        room: {
          double: {
            newPrice: 86.63,
            priceBefore: 85.62,
            priceNow: 87.99,
          },
          single: {
            newPrice: 127.98,
            priceBefore: 128.08,
            priceNow: 128.65,
          },
          suite: {
            newPrice: 127.24,
            priceBefore: 122.36,
            priceNow: 125.27,
          },
        },
      },
      {
        date: '2024-08-20',
        festivos: [
          'Festividad local de Bogarra',
          'Festividad local de Letur',
          'Festividad local de Nerpio',
          'Festividad local de Hinojosas de Calatrava',
          'Festividad local de Campillos-Sierra',
          'Festividad local de La Hinojosa',
          'Festividad local de Torrubia del Castillo',
          'Festividad local de El Pobo de Dueñas',
          'Festividad local de Fuembellida',
          'Festividad local de Tamajón',
          'Festividad local de Esquivias',
        ],
        pc_ocupacion: 41.0,
        room: {
          double: {
            newPrice: 101.62,
            priceBefore: 101.39,
            priceNow: 110.55,
          },
          single: {
            newPrice: 121.63,
            priceBefore: 122.76,
            priceNow: 122.21,
          },
          suite: {
            newPrice: 204.53,
            priceBefore: 200.09,
            priceNow: 192.35,
          },
        },
      },
      {
        date: '2024-08-21',
        festivos: [
          'Festividad local de Iniesta',
          'Festividad local de Cubillejo de la Sierra',
          'Festividad local de Fuembellida',
        ],
        pc_ocupacion: 44.0,
        room: {
          double: {
            newPrice: 127.13,
            priceBefore: 128.46,
            priceNow: 137.36,
          },
          single: {
            newPrice: 91.2,
            priceBefore: 92.0,
            priceNow: 99.71,
          },
          suite: {
            newPrice: 244.26,
            priceBefore: 230.15,
            priceNow: 218.69,
          },
        },
      },
      {
        date: '2024-08-22',
        festivos: [
          'Festividad local de Ciudad Real',
          'Festividad local de San Clemente',
          'Festividad local de Alcoroches',
          'Festividad local de La Olmeda de Jadraque',
          'Festividad local de Morenilla',
          'Festividad local de Torremocha de Jadraque',
        ],
        pc_ocupacion: 42.0,
        room: {
          double: {
            newPrice: 108.5,
            priceBefore: 101.76,
            priceNow: 110.19,
          },
          single: {
            newPrice: 141.81,
            priceBefore: 131.49,
            priceNow: 139.47,
          },
          suite: {
            newPrice: 232.85,
            priceBefore: 227.55,
            priceNow: 219.08,
          },
        },
      },
      {
        date: '2024-08-23',
        festivos: [
          'Festividad local de Madrigueras',
          'Festividad local de Pozuelo',
          'Festividad local de Reolid',
          'Festividad local de Yeste',
          'Festividad local de Los Pozuelos de Calatrava',
          'Festividad local de Atalaya del Cañavate',
          'Festividad local de Fresneda de Altarejos',
          'Festividad local de La Almarcha',
          'Festividad local de Laguna del Marquesado',
          'Festividad local de Torrejoncillo del Rey',
          'Festividad local de Alcoroches',
          'Festividad local de Arbancón',
          'Festividad local de Armuña de Tajuña',
          'Festividad local de Casa de Uceda',
          'Festividad local de Galápagos',
          'Festividad local de Galve de Sorbe',
          'Festividad local de Henche',
          'Festividad local de Hita',
          'Festividad local de Morenilla',
          'Festividad local de Tartanedo',
          'Festividad local de Torremocha de Jadraque',
          'Festividad local de Valdepeñas de la Sierra',
          'Festividad local de Yebes',
          'Festividad local de Yélamos de Arriba',
          'Festividad local de Aldeanueva de San Bartolomé',
          'Festividad local de Cabañas de Yepes',
          'Festividad local de Gargantilla',
          'Festividad local de Marrupe',
          'Festividad local de Puerto de San Vicente',
          'Festividad local de San Bartolomé de las Abiertas',
        ],
        pc_ocupacion: 40.0,
        room: {
          double: {
            newPrice: 124.69,
            priceBefore: 117.25,
            priceNow: 120.04,
          },
          single: {
            newPrice: 105.66,
            priceBefore: 96.74,
            priceNow: 97.9,
          },
          suite: {
            newPrice: 225.95,
            priceBefore: 207.33,
            priceNow: 219.73,
          },
        },
      },
      {
        date: '2024-08-24',
        festivos: [
          'Festividad local de Tarazona de la Mancha',
          'Festividad local de Almagro',
          'Festividad local de Campo de Criptana',
          'Festividad local de Arcos de la Sierra',
          'Festividad local de Casasimarro',
          'Festividad local de Castillejo del Romeral',
          'Festividad local de Colliga',
          'Festividad local de Villarejo de la Peñuela',
          'Festividad local de Campillo de Dueñas',
          'Festividad local de El Cubillo de Uceda',
          'Festividad local de Jirueque',
          'Festividad local de Lupiana',
          'Festividad local de Matarrubia',
          'Festividad local de Rillo de Gallo',
          'Festividad local de El Viso de San Juan',
          'Festividad local de Navahermosa',
        ],
        pc_ocupacion: 51.0,
        room: {
          double: {
            newPrice: 168.52,
            priceBefore: 161.17,
            priceNow: 166.84,
          },
          single: {
            newPrice: 126.63,
            priceBefore: 121.38,
            priceNow: 116.66,
          },
          suite: {
            newPrice: 218.67,
            priceBefore: 209.87,
            priceNow: 213.41,
          },
        },
      },
      {
        date: '2024-08-25',
        festivos: ['Festividad local de Rillo de Gallo'],
        pc_ocupacion: 45.0,
        room: {
          double: {
            newPrice: 105.52,
            priceBefore: 107.97,
            priceNow: 105.84,
          },
          single: {
            newPrice: 127.28,
            priceBefore: 119.57,
            priceNow: 130.25,
          },
          suite: {
            newPrice: 211.35,
            priceBefore: 193.39,
            priceNow: 197.35,
          },
        },
      },
      {
        date: '2024-08-26',
        festivos: [
          'Festividad local de Carcelén',
          'Festividad local de Ballesteros de Calatrava',
          'Festividad local de Navalpino',
          'Festividad local de Alcohujate',
          'Festividad local de Belmonte',
          'Festividad local de Casas de Fernando Alonso',
          'Festividad local de Casas de Haro',
          'Festividad local de Castillejo del Romeral',
          'Festividad local de Fresneda de Altarejos',
          'Festividad local de Gascueña',
          'Festividad local de Piqueras del Castillo',
          'Festividad local de Pozorrubio de Santiago',
          'Festividad local de Ucles',
          'Festividad local de Vellisca',
          'Festividad local de Checa',
          'Festividad local de Cubillejo del Sitio',
          'Festividad local de Hita',
          'Festividad local de Sacecorbo',
          'Festividad local de Valfermoso de Tajuña',
          'Festividad local de Alcañizo',
          'Festividad local de Aldeanueva de Barbarroya',
          'Festividad local de Aldeanueva de San Bartolomé',
          'Festividad local de Añover de Tajo',
          'Festividad local de Arisgotas',
          'Festividad local de Cabañas de Yepes',
          'Festividad local de Corral de Almaguer',
          'Festividad local de Hormigos',
          'Festividad local de Huerta de Valdecarábanos',
          'Festividad local de Mesegar de Tajo',
          'Festividad local de Orgaz',
          'Festividad local de San Bartolomé de las Abiertas',
          'Festividad local de Santa Cruz de la Zarza',
          'Festividad local de Torrecilla de La Jara',
        ],
        pc_ocupacion: 37.0,
        room: {
          double: {
            newPrice: 125.65,
            priceBefore: 130.08,
            priceNow: 138.56,
          },
          single: {
            newPrice: 92.66,
            priceBefore: 85.28,
            priceNow: 91.18,
          },
          suite: {
            newPrice: 209.73,
            priceBefore: 204.18,
            priceNow: 200.47,
          },
        },
      },
      {
        date: '2024-08-27',
        festivos: [
          'Festividad local de Paredes de Sigüenza',
          'Festividad local de Valfermoso de Tajuña',
          'Festividad local de Albarreal de Tajo',
          'Festividad local de Huerta de Valdecarábanos',
        ],
        pc_ocupacion: 43.0,
        room: {
          double: {
            newPrice: 105.3,
            priceBefore: 102.15,
            priceNow: 105.1,
          },
          single: {
            newPrice: 149.57,
            priceBefore: 136.56,
            priceNow: 130.85,
          },
          suite: {
            newPrice: 190.61,
            priceBefore: 195.54,
            priceNow: 198.8,
          },
        },
      },
      {
        date: '2024-08-28',
        festivos: [
          'Festividad local de Casas Ibáñez',
          'Festividad local de Peñas de San Pedro',
          'Festividad local de Fernán Caballero',
          'Festividad local de Villahermosa',
          'Festividad local de Castillejo de Iniesta',
          'Festividad local de Collados',
          'Festividad local de Mota del Cuervo',
          'Festividad local de Zafrilla',
          'Festividad local de Atanzón',
          'Festividad local de Fuentelencina',
          'Festividad local de Hombrados',
          'Festividad local de Membrillera',
          'Festividad local de Albarreal de Tajo',
          'Festividad local de El Toboso',
        ],
        pc_ocupacion: 38.0,
        room: {
          double: {
            newPrice: 122.17,
            priceBefore: 113.96,
            priceNow: 110.51,
          },
          single: {
            newPrice: 121.75,
            priceBefore: 111.04,
            priceNow: 111.32,
          },
          suite: {
            newPrice: 192.08,
            priceBefore: 199.45,
            priceNow: 192.39,
          },
        },
      },
      {
        date: '2024-08-29',
        festivos: [
          'Festividad local de Puebla de Don Rodrigo',
          'Festividad local de Aranzueque',
          'Festividad local de Espinosa de Henares',
          'Festividad local de La Yunta',
          'Festividad local de Millana',
          'Festividad local de Borox',
        ],
        pc_ocupacion: 37.0,
        room: {
          double: {
            newPrice: 106.26,
            priceBefore: 109.86,
            priceNow: 108.59,
          },
          single: {
            newPrice: 82.76,
            priceBefore: 86.85,
            priceNow: 84.68,
          },
          suite: {
            newPrice: 204.47,
            priceBefore: 197.52,
            priceNow: 202.6,
          },
        },
      },
      {
        date: '2024-08-30',
        festivos: [
          'Festividad local de Huelamo',
          'Festividad local de Pajaron',
          'Festividad local de Espinosa de Henares',
          'Festividad local de Pinilla de Jadraque 5 febrero y',
          'Festividad local de Quer',
          'Festividad local de Saelices de la Sal',
          'Festividad local de Solanillos del Extremo',
          'Festividad local de Torrecuadradilla',
          'Festividad local de Valdeaveruelo',
          'Festividad local de Villares de Jadraque',
          'Festividad local de Viñuelas',
          'Festividad local de Aldea en Cabo',
          'Festividad local de Borox',
          'Festividad local de Las Ventas de San Julian',
          'Festividad local de Torrico',
          'Festividad local de Villaluenga de la Sagra',
        ],
        pc_ocupacion: 46.0,
        room: {
          double: {
            newPrice: 113.82,
            priceBefore: 112.76,
            priceNow: 108.3,
          },
          single: {
            newPrice: 117.37,
            priceBefore: 115.59,
            priceNow: 127.06,
          },
          suite: {
            newPrice: 198.66,
            priceBefore: 202.87,
            priceNow: 202.31,
          },
        },
      },
      {
        date: '2024-08-31',
        festivos: [
          'Festividad local de Tomelloso',
          'Festividad local de Ciruelos del Pinar',
          'Festividad local de Illescas',
          'Festividad local de Villarejo de Montalbán',
        ],
        pc_ocupacion: 46.0,
        room: {
          double: {
            newPrice: 128.83,
            priceBefore: 118.4,
            priceNow: 126.72,
          },
          single: {
            newPrice: 142.43,
            priceBefore: 135.06,
            priceNow: 139.65,
          },
          suite: {
            newPrice: 212.94,
            priceBefore: 209.21,
            priceNow: 220.99,
          },
        },
      },
      {
        date: '2024-09-01',
        festivos: [
          'Festividad local de Cuevas de Velasco',
          'Festividad local de Villar del Maestre',
        ],
        pc_ocupacion: 44.0,
        room: {
          double: {
            newPrice: 119.67,
            priceBefore: 123.98,
            priceNow: 122.97,
          },
          single: {
            newPrice: 133.62,
            priceBefore: 130.53,
            priceNow: 140.88,
          },
          suite: {
            newPrice: 219.03,
            priceBefore: 209.86,
            priceNow: 206.97,
          },
        },
      },
      {
        date: '2024-09-02',
        festivos: [
          'Festividad local de Almansa',
          'Festividad local de Argamasilla de Alba',
          'Festividad local de Daimiel',
          'Festividad local de Arrancacepas',
          'Festividad local de Cueva del Hierro',
          'Festividad local de Las Pedroñeras',
          'Festividad local de Motilla del Palancar',
          'Festividad local de Pajaroncillo',
          'Festividad local de Valhermoso de la Fuente',
          'Festividad local de Villalba del Rey',
          'Festividad local de Caspueñas',
          'Festividad local de El Casar',
          'Festividad local de Molina de Aragón',
          'Festividad local de Solanillos del Extremo',
          'Festividad local de Burguillos de Toledo',
          'Festividad local de Hontanar',
          'Festividad local de La Fresneda de la Jara',
          'Festividad local de La Puebla de Almoradiel',
          'Festividad local de Las Ventas de San Julian',
          'Festividad local de Torrico',
        ],
        pc_ocupacion: 38.0,
        room: {
          double: {
            newPrice: 98.35,
            priceBefore: 99.5,
            priceNow: 101.75,
          },
          single: {
            newPrice: 109.97,
            priceBefore: 101.46,
            priceNow: 106.81,
          },
          suite: {
            newPrice: 201.03,
            priceBefore: 191.53,
            priceNow: 188.74,
          },
        },
      },
      {
        date: '2024-09-03',
        festivos: [
          'Festividad local de El Robledo',
          'Festividad local de Cueva del Hierro',
        ],
        pc_ocupacion: 47.0,
        room: {
          double: {
            newPrice: 127.83,
            priceBefore: 118.92,
            priceNow: 129.93,
          },
          single: {
            newPrice: 135.09,
            priceBefore: 122.93,
            priceNow: 126.83,
          },
          suite: {
            newPrice: 204.67,
            priceBefore: 199.07,
            priceNow: 218.36,
          },
        },
      },
      {
        date: '2024-09-04',
        festivos: ['Festividad local de Ayna'],
        pc_ocupacion: 52.0,
        room: {
          double: {
            newPrice: 112.03,
            priceBefore: 108.62,
            priceNow: 116.16,
          },
          single: {
            newPrice: 198.59,
            priceBefore: 205.28,
            priceNow: 197.09,
          },
          suite: {
            newPrice: 221.17,
            priceBefore: 220.22,
            priceNow: 241.92,
          },
        },
      },
      {
        date: '2024-09-05',
        festivos: [
          'Festividad local de Alcaraz',
          'Festividad local de Daimiel',
          'Festividad local de Villamayor de Santiago',
          'Festividad local de Corduente',
        ],
        pc_ocupacion: 52.0,
        room: {
          double: {
            newPrice: 149.81,
            priceBefore: 145.03,
            priceNow: 151.58,
          },
          single: {
            newPrice: 124.57,
            priceBefore: 114.21,
            priceNow: 123.46,
          },
          suite: {
            newPrice: 227.12,
            priceBefore: 215.1,
            priceNow: 206.14,
          },
        },
      },
      {
        date: '2024-09-06',
        festivos: [
          'Festividad local de Ayna',
          'Festividad local de Bienservida',
          'Festividad local de Puertollano',
          'Festividad local de Almendros',
          'Festividad local de El Cañavate',
          'Festividad local de Garaballa',
          'Festividad local de Salvacañete',
          'Festividad local de Villalba de la Sierra',
          'Festividad local de Villarejo Sobrehuerta',
          'Festividad local de Alovera',
          'Festividad local de Aragosa',
          'Festividad local de Casa de Uceda',
          'Festividad local de Corduente',
          'Festividad local de Malaguilla',
          'Festividad local de Mandayona',
          'Festividad local de Miralrío',
          'Festividad local de Orea',
          'Festividad local de Pareja',
          'Festividad local de Peñalver',
          'Festividad local de Riba de Saelices',
          'Festividad local de Romanones',
          'Festividad local de Trillo',
          'Festividad local de Carranque',
          'Festividad local de El Bercial',
          'Festividad local de Talavera de la Reina',
          'Festividad local de Talavera la Nueva',
          'Festividad local de Velada',
          'Festividad local de Villarrubia de Santiago',
        ],
        pc_ocupacion: 35.0,
        room: {
          double: {
            newPrice: 37.54,
            priceBefore: 36.31,
            priceNow: 38.86,
          },
          single: {
            newPrice: 139.47,
            priceBefore: 140.39,
            priceNow: 146.28,
          },
          suite: {
            newPrice: 224.62,
            priceBefore: 216.38,
            priceNow: 210.25,
          },
        },
      },
      {
        date: '2024-09-07',
        festivos: [
          'Festividad local de Minaya',
          'Festividad local de Alhambra',
          'Festividad local de Cereceda',
          'Festividad local de Majaelrayo',
          'Festividad local de Puebla de Beleña',
          'Festividad local de El Bercial',
        ],
        pc_ocupacion: 39.0,
        room: {
          double: {
            newPrice: 99.09,
            priceBefore: 92.34,
            priceNow: 101.11,
          },
          single: {
            newPrice: 109.81,
            priceBefore: 102.39,
            priceNow: 104.95,
          },
          suite: {
            newPrice: 208.49,
            priceBefore: 207.67,
            priceNow: 216.94,
          },
        },
      },
      {
        date: '2024-09-08',
        festivos: [
          'Festividad local de Las Majadas',
          'Festividad local de Ribatajada',
          'Festividad local de Algar de Mesa',
          'Festividad local de Alhóndiga',
          'Festividad local de Campillo de Dueñas',
          'Festividad local de Casasana',
          'Festividad local de Cereceda',
          'Festividad local de El Recuenco',
          'Festividad local de Miedes de Atienza',
          'Festividad local de Monasterio',
          'Festividad local de Ventosa',
          'Festividad local de Zaorejas',
        ],
        pc_ocupacion: 53.0,
        room: {
          double: {
            newPrice: 174.9,
            priceBefore: 160.54,
            priceNow: 154.31,
          },
          single: {
            newPrice: 79.34,
            priceBefore: 78.78,
            priceNow: 76.6,
          },
          suite: {
            newPrice: 263.11,
            priceBefore: 250.68,
            priceNow: 275.5,
          },
        },
      },
      {
        date: '2024-09-09',
        festivos: [
          'Festividad local de Albacete',
          'Festividad local de Alborea',
          'Festividad local de Caudete',
          'Festividad local de Fuensanta',
          'Festividad local de Aldea del Rey',
          'Festividad local de Argamasilla de Calatrava',
          'Festividad local de Calzada de Calatrava',
          'Festividad local de Fuencaliente',
          'Festividad local de Llanos del Caudillo',
          'Festividad local de Luciana',
          'Festividad local de Miguelturra',
          'Festividad local de Puebla del Príncipe',
          'Festividad local de Valdemanco del Esteras',
          'Festividad local de Villahermosa',
          'Festividad local de Albendea',
          'Festividad local de Alcohujate',
          'Festividad local de Almendros',
          'Festividad local de Altarejos',
          'Festividad local de Barajas de Melo',
          'Festividad local de Belmonte',
          'Festividad local de Buendía',
          'Festividad local de Campillo de Altobuey',
          'Festividad local de Canalejas del Arroyo',
          'Festividad local de Cañada del Hoyo',
          'Festividad local de Cañete',
          'Festividad local de Cañizares',
          'Festividad local de Culebras',
          'Festividad local de El Cañavate',
          'Festividad local de El Herrumblar',
          'Festividad local de Horcajada de la Torre',
          'Festividad local de Huerta de la Obispalía',
          'Festividad local de La Peraleja',
          'Festividad local de Lagunaseca',
          'Festividad local de Loranca del Campo',
          'Festividad local de Puebla de Almenara',
          'Festividad local de Ribagorda',
          'Festividad local de Santa Maria del Campo Rus',
          'Festividad local de Tarancon',
          'Festividad local de Torrubia del Campo',
          'Festividad local de Vega del Codorno',
          'Festividad local de Villanueva de Guadamejud',
          'Festividad local de Villar de Cañas',
          'Festividad local de Villar de Olalla',
          'Festividad local de Villar del Horno',
          'Festividad local de Villarejo de Fuentes',
          'Festividad local de Balbacil',
          'Festividad local de Casasana',
          'Festividad local de Clares',
          'Festividad local de Cobeta',
          'Festividad local de Codes',
          'Festividad local de Condemios de Abajo',
          'Festividad local de Hontoba',
          'Festividad local de Horche',
          'Festividad local de Illana',
          'Festividad local de Maranchón',
          'Festividad local de Monasterio',
          'Festividad local de Pálmaces de Jadraque',
          'Festividad local de Pareja',
          'Festividad local de Peñalver',
          'Festividad local de Tendilla',
          'Festividad local de Torija',
          'Festividad local de Tortuera',
          'Festividad local de Trillo',
          'Festividad local de Turmiel',
          'Festividad local de Ventosa',
          'Festividad local de Yebra',
          'Festividad local de Alcabón',
          'Festividad local de Arisgotas',
          'Festividad local de Carriches',
          'Festividad local de Castillo de Bayuela',
          'Festividad local de Cebolla',
          'Festividad local de Cedillo del Condado',
          'Festividad local de Consuegra',
          'Festividad local de Domingo Pérez',
          'Festividad local de Espinoso del Rey',
          'Festividad local de Gamonal',
          'Festividad local de Las Hunfrías',
          'Festividad local de Los Navalucillos',
          'Festividad local de Lillo',
          'Festividad local de Méntrida',
          'Festividad local de Miguel Esteban',
          'Festividad local de Mocejón',
          'Festividad local de Navamorcuende',
          'Festividad local de Ocaña',
          'Festividad local de Orgaz',
          'Festividad local de Oropesa y Corchuela',
          'Festividad local de Paredes de Escalona',
          'Festividad local de Pepino',
          'Festividad local de Recas',
          'Festividad local de Sonseca',
          'Festividad local de Turleque',
          'Festividad local de Villamiel',
          'Festividad local de Villanueva de Bogas',
          'Festividad local de Villarrubia de Santiago',
          'Festividad local de Villaseca de la Sagra',
        ],
        pc_ocupacion: 58.0,
        room: {
          double: {
            newPrice: 143.02,
            priceBefore: 137.34,
            priceNow: 144.37,
          },
          single: {
            newPrice: 171.58,
            priceBefore: 170.44,
            priceNow: 169.53,
          },
          suite: {
            newPrice: 233.36,
            priceBefore: 219.45,
            priceNow: 238.64,
          },
        },
      },
      {
        date: '2024-09-10',
        festivos: [
          'Festividad local de Barajas de Melo',
          'Festividad local de Cañizares',
          'Festividad local de Vega del Codorno',
          'Festividad local de Almonacid de Zorita',
          'Festividad local de Horche',
          'Festividad local de Pálmaces de Jadraque',
          'Festividad local de Tendilla',
          'Festividad local de Torija',
          'Festividad local de Tortuera',
          'Festividad local de Valdelcubo',
          'Festividad local de Los Navalucillos',
          'Festividad local de Ocaña',
        ],
        pc_ocupacion: 48.0,
        room: {
          double: {
            newPrice: 81.0,
            priceBefore: 80.81,
            priceNow: 85.21,
          },
          single: {
            newPrice: 147.38,
            priceBefore: 151.34,
            priceNow: 155.42,
          },
          suite: {
            newPrice: 242.72,
            priceBefore: 227.14,
            priceNow: 226.71,
          },
        },
      },
      {
        date: '2024-09-11',
        festivos: [
          'Festividad local de Chumillas',
          'Festividad local de Hontanaya',
          'Festividad local de Pinarejo',
          'Festividad local de Almonacid de Zorita',
          'Festividad local de Almendral de La Cañada',
          'Festividad local de Retamoso de la Jara',
          'Festividad local de Villa de Don Fadrique',
        ],
        pc_ocupacion: 46.0,
        room: {
          double: {
            newPrice: 145.5,
            priceBefore: 138.62,
            priceNow: 147.22,
          },
          single: {
            newPrice: 95.83,
            priceBefore: 97.99,
            priceNow: 96.95,
          },
          suite: {
            newPrice: 240.51,
            priceBefore: 223.97,
            priceNow: 215.75,
          },
        },
      },
      {
        date: '2024-09-12',
        festivos: [
          'Festividad local de El Pozuelo',
          'Festividad local de Priego',
          'Festividad local de Guadalajara',
          'Festividad local de Calera y Chozas',
          'Festividad local de Fuensalida',
          'Festividad local de Villatobas',
          'Festividad local de Yuncos',
        ],
        pc_ocupacion: 55.0,
        room: {
          double: {
            newPrice: 139.71,
            priceBefore: 141.48,
            priceNow: 152.02,
          },
          single: {
            newPrice: 128.75,
            priceBefore: 127.75,
            priceNow: 136.23,
          },
          suite: {
            newPrice: 228.62,
            priceBefore: 226.58,
            priceNow: 216.84,
          },
        },
      },
      {
        date: '2024-09-13',
        festivos: [
          'Festividad local de Cañada de Calatrava',
          'Festividad local de Castellar de Santiago',
          'Festividad local de Fuenllana',
          'Festividad local de Montiel',
          'Festividad local de Saceruela',
          'Festividad local de Arcas',
          'Festividad local de Belmontejo',
          'Festividad local de Chillaron de Cuenca',
          'Festividad local de Horcajo De Santiago',
          'Festividad local de Leganiel',
          'Festividad local de Los Valdecolmenas',
          'Festividad local de Mariana',
          'Festividad local de Montalbanejo',
          'Festividad local de Portilla',
          'Festividad local de Priego',
          'Festividad local de Saceda-Trasierra',
          'Festividad local de Santa Maria del Val',
          'Festividad local de Valdeolivas',
          'Festividad local de Chillaron del Rey',
          'Festividad local de Chiloeches',
          'Festividad local de Cifuentes',
          'Festividad local de Guadalajara',
          'Festividad local de Hueva',
          'Festividad local de Luzón',
          'Festividad local de Moratilla de los Meleros',
          'Festividad local de Trijueque',
          'Festividad local de Villanueva de Alcorón',
          'Festividad local de Calera y Chozas',
          'Festividad local de Calzada de Oropesa',
          'Festividad local de Casarrubios del Monte',
          'Festividad local de Dosbarrios',
          'Festividad local de Escalonilla',
          'Festividad local de Fuensalida',
          'Festividad local de Hinojosa de San Vicente',
          'Festividad local de Los Cerralbos',
          'Festividad local de Los Navalmorales',
          'Festividad local de Los Yébenes',
          'Festividad local de Otero',
          'Festividad local de Real de San Vicente',
          'Festividad local de Rielves',
          'Festividad local de Sevilleja de la Jara',
          'Festividad local de Villatobas',
          'Festividad local de Yepes',
          'Festividad local de Yuncos',
        ],
        pc_ocupacion: 59.0,
        room: {
          double: {
            newPrice: 199.38,
            priceBefore: 198.11,
            priceNow: 210.92,
          },
          single: {
            newPrice: 143.14,
            priceBefore: 140.53,
            priceNow: 141.01,
          },
          suite: {
            newPrice: 243.1,
            priceBefore: 246.54,
            priceNow: 255.05,
          },
        },
      },
      {
        date: '2024-09-14',
        festivos: [
          'Festividad local de Alpera',
          'Festividad local de Villa de Vés',
          'Festividad local de Almadén',
          'Festividad local de Bolaños de Calatrava',
          'Festividad local de Cózar',
          'Festividad local de Malagón',
          'Festividad local de Manzanares',
          'Festividad local de Alarcón',
          'Festividad local de Cuevas de Velasco',
          'Festividad local de Fuentelespino de Moya',
          'Festividad local de La Alberca de Zancara',
          'Festividad local de La Parra de las Vegas',
          'Festividad local de La Ventosa',
          'Festividad local de Minglanilla',
          'Festividad local de Nohales',
          'Festividad local de Villar del Maestre',
          'Festividad local de Alaminos',
          'Festividad local de Argecilla',
          'Festividad local de Atienza',
          'Festividad local de Baños de Tajo',
          'Festividad local de Centenera',
          'Festividad local de Ciruelas',
          'Festividad local de Fuentelahiguera de Albatages',
          'Festividad local de Hueva',
          'Festividad local de Irueste',
          'Festividad local de Jadraque',
          'Festividad local de Loranca de Tajuña',
          'Festividad local de Mondéjar',
          'Festividad local de Saelices de la Sal',
          'Festividad local de Salmerón',
          'Festividad local de Val de San Garcia',
          'Festividad local de Valdeconcha',
          'Festividad local de Valdenuño Fernandez',
          'Festividad local de Valhermoso',
          'Festividad local de Carmena',
          'Festividad local de Garciotum',
          'Festividad local de Guadamur',
          'Festividad local de La Pueblanueva',
          'Festividad local de Las Herencias',
          'Festividad local de Lucillos',
          'Festividad local de Madridejos',
          'Festividad local de Pelahustán',
          'Festividad local de Segurilla',
        ],
        pc_ocupacion: 39.0,
        room: {
          double: {
            newPrice: 109.81,
            priceBefore: 100.21,
            priceNow: 106.38,
          },
          single: {
            newPrice: 103.57,
            priceBefore: 102.18,
            priceNow: 111.31,
          },
          suite: {
            newPrice: 219.47,
            priceBefore: 222.18,
            priceNow: 243.52,
          },
        },
      },
      {
        date: '2024-09-15',
        festivos: [
          'Festividad local de Villar de Cañas',
          'Festividad local de Anquela del Ducado',
          'Festividad local de Baños de Tajo',
          'Festividad local de Valhermoso',
          'Festividad local de Zarzuela de Galve',
        ],
        pc_ocupacion: 59.0,
        room: {
          double: {
            newPrice: 219.32,
            priceBefore: 208.67,
            priceNow: 228.7,
          },
          single: {
            newPrice: 86.45,
            priceBefore: 81.0,
            priceNow: 85.35,
          },
          suite: {
            newPrice: 274.62,
            priceBefore: 279.4,
            priceNow: 267.16,
          },
        },
      },
      {
        date: '2024-09-16',
        festivos: [
          'Festividad local de Elche De La Sierra',
          'Festividad local de Villapalacios',
          'Festividad local de Alcoba de los Montes',
          'Festividad local de Brazatortas',
          'Festividad local de Guadalmez',
          'Festividad local de Arandilla del Arroyo',
          'Festividad local de Beteta',
          'Festividad local de Buenache de Alarcon',
          'Festividad local de Castillejo-Sierra',
          'Festividad local de Fuente de Pedro Naharro',
          'Festividad local de Leganiel',
          'Festividad local de Osa de la Vega',
          'Festividad local de Paracuellos de la Vega',
          'Festividad local de Saelices',
          'Festividad local de Santa Maria del Val',
          'Festividad local de Sisante',
          'Festividad local de Villaescusa de Haro',
          'Festividad local de Villares del Saz',
          'Festividad local de Alcocer',
          'Festividad local de Almoguera',
          'Festividad local de Auñón',
          'Festividad local de Azuqueca de Henares',
          'Festividad local de Budia',
          'Festividad local de Chequilla',
          'Festividad local de Cifuentes',
          'Festividad local de Esplegares',
          'Festividad local de Humanes',
          'Festividad local de Jadraque',
          'Festividad local de Milmarcos',
          'Festividad local de Peñalén',
          'Festividad local de Santiuste',
          'Festividad local de Val de San Garcia',
          'Festividad local de Yunquera de Henares',
          'Festividad local de Zarzuela de Galve',
          'Festividad local de Bargas',
          'Festividad local de Calzada de Oropesa',
          'Festividad local de Chueca',
          'Festividad local de Cobeja',
          'Festividad local de Los Navalmorales',
          'Festividad local de Noez',
          'Festividad local de Numancia de la Sagra',
          'Festividad local de Otero',
          'Festividad local de Real de San Vicente',
          'Festividad local de Santa Ana de Pusa',
          'Festividad local de Villafranca de los Caballeros',
          'Festividad local de Yepes',
          'Festividad local de Yunclillos',
        ],
        pc_ocupacion: 46.0,
        room: {
          double: {
            newPrice: 152.09,
            priceBefore: 148.71,
            priceNow: 161.77,
          },
          single: {
            newPrice: 99.96,
            priceBefore: 97.71,
            priceNow: 100.93,
          },
          suite: {
            newPrice: 225.79,
            priceBefore: 214.12,
            priceNow: 210.77,
          },
        },
      },
      {
        date: '2024-09-17',
        festivos: [
          'Festividad local de Beteta',
          'Festividad local de Budia',
          'Festividad local de Yunquera de Henares',
          'Festividad local de Cobeja',
          'Festividad local de Numancia de la Sagra',
        ],
        pc_ocupacion: 49.0,
        room: {
          double: {
            newPrice: 159.7,
            priceBefore: 149.18,
            priceNow: 150.25,
          },
          single: {
            newPrice: 93.24,
            priceBefore: 93.1,
            priceNow: 95.37,
          },
          suite: {
            newPrice: 265.64,
            priceBefore: 247.87,
            priceNow: 268.71,
          },
        },
      },
      {
        date: '2024-09-18',
        festivos: [
          'Festividad local de Villanueva de los Infantes',
          'Festividad local de Honrubia',
          'Festividad local de Escamilla',
          'Festividad local de Luzaga',
        ],
        pc_ocupacion: 56.0,
        room: {
          double: {
            newPrice: 152.87,
            priceBefore: 152.44,
            priceNow: 150.22,
          },
          single: {
            newPrice: 145.96,
            priceBefore: 146.06,
            priceNow: 138.8,
          },
          suite: {
            newPrice: 237.98,
            priceBefore: 247.67,
            priceNow: 261.15,
          },
        },
      },
      {
        date: '2024-09-19',
        festivos: ['Festividad local de Oropesa y Corchuela'],
        pc_ocupacion: 58.0,
        room: {
          double: {
            newPrice: 155.7,
            priceBefore: 148.29,
            priceNow: 157.87,
          },
          single: {
            newPrice: 129.37,
            priceBefore: 120.25,
            priceNow: 130.72,
          },
          suite: {
            newPrice: 265.81,
            priceBefore: 257.66,
            priceNow: 274.06,
          },
        },
      },
      {
        date: '2024-09-20',
        festivos: [
          'Festividad local de Villaverde del Guadalimar',
          'Festividad local de Valverde de Jucar',
          'Festividad local de Ablanque',
          'Festividad local de Peralejos de las Truchas',
          'Festividad local de Pozo de Guadalajara',
          'Festividad local de Gerindote',
        ],
        pc_ocupacion: 49.0,
        room: {
          double: {
            newPrice: 163.37,
            priceBefore: 151.34,
            priceNow: 155.02,
          },
          single: {
            newPrice: 119.77,
            priceBefore: 114.97,
            priceNow: 117.61,
          },
          suite: {
            newPrice: 230.29,
            priceBefore: 220.99,
            priceNow: 230.83,
          },
        },
      },
      {
        date: '2024-09-21',
        festivos: [
          'Festividad local de La Recueja',
          'Festividad local de Cuenca',
          'Festividad local de La Melgosa',
          'Festividad local de Mohorte',
          'Festividad local de Consuegra',
        ],
        pc_ocupacion: 56.0,
        room: {
          double: {
            newPrice: 200.6,
            priceBefore: 209.51,
            priceNow: 208.91,
          },
          single: {
            newPrice: 62.39,
            priceBefore: 65.19,
            priceNow: 66.63,
          },
          suite: {
            newPrice: 242.19,
            priceBefore: 240.13,
            priceNow: 250.13,
          },
        },
      },
      {
        date: '2024-09-22',
        festivos: [],
        pc_ocupacion: 53.0,
        room: {
          double: {
            newPrice: 106.8,
            priceBefore: 98.31,
            priceNow: 101.49,
          },
          single: {
            newPrice: 137.13,
            priceBefore: 135.44,
            priceNow: 131.4,
          },
          suite: {
            newPrice: 232.68,
            priceBefore: 244.35,
            priceNow: 247.39,
          },
        },
      },
      {
        date: '2024-09-23',
        festivos: [
          'Festividad local de Munera',
          'Festividad local de Navas de Estena',
          'Festividad local de Peralejos de las Truchas',
          'Festividad local de Totanés',
          'Festividad local de Villaminaya',
        ],
        pc_ocupacion: 64.0,
        room: {
          double: {
            newPrice: 160.82,
            priceBefore: 156.66,
            priceNow: 158.36,
          },
          single: {
            newPrice: 203.83,
            priceBefore: 210.0,
            priceNow: 200.74,
          },
          suite: {
            newPrice: 268.82,
            priceBefore: 252.98,
            priceNow: 245.66,
          },
        },
      },
      {
        date: '2024-09-24',
        festivos: [
          'Festividad local de Hoya Gonzalo',
          'Festividad local de Herencia',
          'Festividad local de Los Cortijos',
          'Festividad local de Abánades',
          'Festividad local de Mochales',
          'Festividad local de Torremochuela',
          'Festividad local de Villel de Mesa',
          'Festividad local de Las Ventas con Peña Aguilera',
          'Festividad local de Pantoja',
          'Festividad local de Villaluenga de la Sagra',
        ],
        pc_ocupacion: 53.0,
        room: {
          double: {
            newPrice: 136.07,
            priceBefore: 139.39,
            priceNow: 135.15,
          },
          single: {
            newPrice: 110.15,
            priceBefore: 107.97,
            priceNow: 113.54,
          },
          suite: {
            newPrice: 280.63,
            priceBefore: 285.4,
            priceNow: 313.89,
          },
        },
      },
      {
        date: '2024-09-25',
        festivos: [
          'Festividad local de Los Cortijos',
          'Festividad local de La Guardia',
          'Festividad local de Pantoja',
        ],
        pc_ocupacion: 75.0,
        room: {
          double: {
            newPrice: 249.82,
            priceBefore: 229.08,
            priceNow: 230.53,
          },
          single: {
            newPrice: 259.51,
            priceBefore: 241.05,
            priceNow: 248.63,
          },
          suite: {
            newPrice: 247.09,
            priceBefore: 259.59,
            priceNow: 269.93,
          },
        },
      },
      {
        date: '2024-09-26',
        festivos: [
          'Festividad local de La Guardia',
          'Festividad local de Noves',
        ],
        pc_ocupacion: 87.0,
        room: {
          double: {
            newPrice: 238.35,
            priceBefore: 219.12,
            priceNow: 216.42,
          },
          single: {
            newPrice: 233.44,
            priceBefore: 223.84,
            priceNow: 218.82,
          },
          suite: {
            newPrice: 328.34,
            priceBefore: 317.01,
            priceNow: 344.78,
          },
        },
      },
      {
        date: '2024-09-27',
        festivos: [
          'Festividad local de Abengibre',
          'Festividad local de El Peral',
          'Festividad local de Palomares del Campo',
          'Festividad local de Albalate de Zorita',
          'Festividad local de Heras de Ayuso',
          'Festividad local de Villaseca de Uceda',
          'Festividad local de Alberche del Caudillo',
          'Festividad local de Buenaventura',
          'Festividad local de Noves',
          'Festividad local de Torrijos',
        ],
        pc_ocupacion: 53.0,
        room: {
          double: {
            newPrice: 165.04,
            priceBefore: 153.17,
            priceNow: 157.52,
          },
          single: {
            newPrice: 94.06,
            priceBefore: 85.96,
            priceNow: 82.33,
          },
          suite: {
            newPrice: 273.22,
            priceBefore: 251.36,
            priceNow: 251.09,
          },
        },
      },
      {
        date: '2024-09-28',
        festivos: [],
        pc_ocupacion: 57.0,
        room: {
          double: {
            newPrice: 195.24,
            priceBefore: 178.75,
            priceNow: 177.44,
          },
          single: {
            newPrice: 85.49,
            priceBefore: 85.36,
            priceNow: 87.47,
          },
          suite: {
            newPrice: 262.49,
            priceBefore: 243.48,
            priceNow: 258.58,
          },
        },
      },
      {
        date: '2024-09-29',
        festivos: [
          'Festividad local de Fresneda de la Sierra',
          'Festividad local de Huelves',
          'Festividad local de Tondos',
          'Festividad local de Valparaiso de Arriba',
          'Festividad local de Anchuela del Campo',
        ],
        pc_ocupacion: 46.0,
        room: {
          double: {
            newPrice: 127.0,
            priceBefore: 120.95,
            priceNow: 119.77,
          },
          single: {
            newPrice: 116.91,
            priceBefore: 108.16,
            priceNow: 115.5,
          },
          suite: {
            newPrice: 243.96,
            priceBefore: 227.59,
            priceNow: 226.91,
          },
        },
      },
      {
        date: '2024-09-30',
        festivos: [
          'Festividad local de Chinchilla',
          'Festividad local de Corralrubio',
          'Festividad local de El Ballestero',
          'Festividad local de Ossa de Montiel',
          'Festividad local de Paterna del Madera',
          'Festividad local de Peñascosa',
          'Festividad local de Anchuras',
          'Festividad local de Montalbo',
          'Festividad local de Narboneta',
          'Festividad local de Tragacete',
          'Festividad local de Castejón de Henares',
          'Festividad local de Driebes',
          'Festividad local de Huertahernando',
          'Festividad local de Yebes',
          'Festividad local de Robledo del Mazo',
          'Festividad local de Torrecilla de La Jara',
          'Festividad local de Urda',
        ],
        pc_ocupacion: 56.0,
        room: {
          double: {
            newPrice: 162.85,
            priceBefore: 156.49,
            priceNow: 155.38,
          },
          single: {
            newPrice: 99.49,
            priceBefore: 93.34,
            priceNow: 96.48,
          },
          suite: {
            newPrice: 266.66,
            priceBefore: 257.65,
            priceNow: 271.76,
          },
        },
      },
      {
        date: '2024-10-01',
        festivos: ['Festividad local de Montalbo', 'Festividad local de Embid'],
        pc_ocupacion: 55.0,
        room: {
          double: {
            newPrice: 153.5,
            priceBefore: 155.78,
            priceNow: 149.57,
          },
          single: {
            newPrice: 171.47,
            priceBefore: 162.11,
            priceNow: 171.16,
          },
          suite: {
            newPrice: 268.7,
            priceBefore: 244.47,
            priceNow: 260.81,
          },
        },
      },
      {
        date: '2024-10-02',
        festivos: [
          'Festividad local de Arguisuelas',
          'Festividad local de La Cierva',
          'Festividad local de Semillas',
          'Festividad local de Yélamos de Abajo',
        ],
        pc_ocupacion: 55.0,
        room: {
          double: {
            newPrice: 168.21,
            priceBefore: 168.62,
            priceNow: 177.28,
          },
          single: {
            newPrice: 142.36,
            priceBefore: 133.36,
            priceNow: 136.54,
          },
          suite: {
            newPrice: 308.67,
            priceBefore: 286.52,
            priceNow: 289.63,
          },
        },
      },
      {
        date: '2024-10-03',
        festivos: ['Festividad local de Yélamos de Abajo'],
        pc_ocupacion: 57.0,
        room: {
          double: {
            newPrice: 153.6,
            priceBefore: 153.04,
            priceNow: 150.76,
          },
          single: {
            newPrice: 128.84,
            priceBefore: 131.83,
            priceNow: 129.3,
          },
          suite: {
            newPrice: 259.0,
            priceBefore: 246.32,
            priceNow: 257.6,
          },
        },
      },
      {
        date: '2024-10-04',
        festivos: [
          'Festividad local de El Salobral',
          'Festividad local de Vianos',
          'Festividad local de Masegosa',
          'Festividad local de Robledo de Corpes',
          'Festividad local de Sotodosos',
        ],
        pc_ocupacion: 52.0,
        room: {
          double: {
            newPrice: 189.48,
            priceBefore: 180.16,
            priceNow: 175.38,
          },
          single: {
            newPrice: 97.92,
            priceBefore: 101.65,
            priceNow: 109.45,
          },
          suite: {
            newPrice: 220.14,
            priceBefore: 225.75,
            priceNow: 245.72,
          },
        },
      },
      {
        date: '2024-10-05',
        festivos: [
          'Festividad local de Salinas del Manzano',
          'Festividad local de El Pedregal',
        ],
        pc_ocupacion: 40.0,
        room: {
          double: {
            newPrice: 101.24,
            priceBefore: 98.51,
            priceNow: 97.82,
          },
          single: {
            newPrice: 86.55,
            priceBefore: 80.91,
            priceNow: 78.82,
          },
          suite: {
            newPrice: 261.06,
            priceBefore: 237.64,
            priceNow: 252.88,
          },
        },
      },
      {
        date: '2024-10-06',
        festivos: [],
        pc_ocupacion: 55.0,
        room: {
          double: {
            newPrice: 160.52,
            priceBefore: 157.92,
            priceNow: 166.32,
          },
          single: {
            newPrice: 110.64,
            priceBefore: 106.22,
            priceNow: 116.27,
          },
          suite: {
            newPrice: 283.88,
            priceBefore: 288.45,
            priceNow: 287.92,
          },
        },
      },
      {
        date: '2024-10-07',
        festivos: [
          'Festividad local de Bonete',
          'Festividad local de Férez',
          'Festividad local de Masegoso',
          'Festividad local de Povedilla',
          'Festividad local de Tiriez',
          'Festividad local de Valdeganga',
          'Festividad local de Almadenejos',
          'Festividad local de Almedina',
          'Festividad local de Santa Cruz de Cáñamos',
          'Festividad local de Villanueva de San Carlos',
          'Festividad local de Buenache de la Sierra',
          'Festividad local de El Picazo',
          'Festividad local de Fuentes',
          'Festividad local de Fuertescusa',
          'Festividad local de Las Valeras',
          'Festividad local de Masegosa',
          'Festividad local de Pineda de Gigüela',
          'Festividad local de Valdetortola',
          'Festividad local de Valparaiso de Abajo',
          'Festividad local de Zafra de Zancara',
          'Festividad local de Zarzuela',
          'Festividad local de Anchuela del Pedregal',
          'Festividad local de Anguita',
          'Festividad local de Driebes',
          'Festividad local de El Sotillo',
          'Festividad local de Málaga del Fresno',
          'Festividad local de Saúca',
          'Festividad local de Setiles',
          'Festividad local de Cabañas de la Sagra',
          'Festividad local de Cardiel de los Montes',
          'Festividad local de Lagartera',
          'Festividad local de Las Vegas y San Antonio',
          'Festividad local de Montearagón',
          'Festividad local de Olias del Rey',
          'Festividad local de Ontígola',
        ],
        pc_ocupacion: 54.0,
        room: {
          double: {
            newPrice: 138.91,
            priceBefore: 133.12,
            priceNow: 139.43,
          },
          single: {
            newPrice: 122.99,
            priceBefore: 125.01,
            priceNow: 127.8,
          },
          suite: {
            newPrice: 261.9,
            priceBefore: 256.91,
            priceNow: 247.41,
          },
        },
      },
      {
        date: '2024-10-08',
        festivos: [
          'Festividad local de Férez',
          'Festividad local de Valdeganga',
          'Festividad local de Valenzuela de Calatrava',
          'Festividad local de Sotos',
          'Festividad local de Setiles',
          'Festividad local de Olias del Rey',
        ],
        pc_ocupacion: 53.0,
        room: {
          double: {
            newPrice: 157.45,
            priceBefore: 160.32,
            priceNow: 163.48,
          },
          single: {
            newPrice: 120.4,
            priceBefore: 115.5,
            priceNow: 113.86,
          },
          suite: {
            newPrice: 235.68,
            priceBefore: 218.24,
            priceNow: 211.19,
          },
        },
      },
      {
        date: '2024-10-09',
        festivos: [
          'Festividad local de Fuenteálamo',
          'Festividad local de Villar del Humo',
          'Festividad local de Ujados',
        ],
        pc_ocupacion: 45.0,
        room: {
          double: {
            newPrice: 130.52,
            priceBefore: 132.49,
            priceNow: 129.54,
          },
          single: {
            newPrice: 97.2,
            priceBefore: 98.89,
            priceNow: 99.28,
          },
          suite: {
            newPrice: 253.08,
            priceBefore: 237.71,
            priceNow: 251.13,
          },
        },
      },
      {
        date: '2024-10-10',
        festivos: [
          'Festividad local de Cantalojas',
          'Festividad local de Torremocha del Pinar',
          'Festividad local de Portillo de Toledo',
        ],
        pc_ocupacion: 57.0,
        room: {
          double: {
            newPrice: 144.41,
            priceBefore: 150.86,
            priceNow: 146.05,
          },
          single: {
            newPrice: 72.29,
            priceBefore: 73.84,
            priceNow: 72.8,
          },
          suite: {
            newPrice: 291.16,
            priceBefore: 273.12,
            priceNow: 287.9,
          },
        },
      },
      {
        date: '2024-10-11',
        festivos: [
          'Festividad local de Bormate',
          'Festividad local de La Herrera',
          'Festividad local de Cardenete',
          'Festividad local de Carrascosa de la Sierra',
          'Festividad local de Henarejos',
          'Festividad local de Cantalojas',
          'Festividad local de Los Alares',
          'Festividad local de Pulgar',
          'Festividad local de Robledo del Buey',
        ],
        pc_ocupacion: 49.0,
        room: {
          double: {
            newPrice: 88.99,
            priceBefore: 91.69,
            priceNow: 93.28,
          },
          single: {
            newPrice: 132.17,
            priceBefore: 120.26,
            priceNow: 119.73,
          },
          suite: {
            newPrice: 240.43,
            priceBefore: 231.88,
            priceNow: 250.66,
          },
        },
      },
      {
        date: '2024-10-12',
        festivos: ['Fiesta Nacional de España'],
        pc_ocupacion: 33.0,
        room: {
          double: {
            newPrice: 109.78,
            priceBefore: 111.4,
            priceNow: 113.38,
          },
          single: {
            newPrice: 132.07,
            priceBefore: 130.15,
            priceNow: 136.39,
          },
          suite: {
            newPrice: 115.03,
            priceBefore: 117.84,
            priceNow: 114.6,
          },
        },
      },
      {
        date: '2024-10-13',
        festivos: ['Festividad local de Huelamo'],
        pc_ocupacion: 43.0,
        room: {
          double: {
            newPrice: 97.17,
            priceBefore: 98.53,
            priceNow: 102.62,
          },
          single: {
            newPrice: 111.09,
            priceBefore: 105.56,
            priceNow: 114.6,
          },
          suite: {
            newPrice: 241.28,
            priceBefore: 228.91,
            priceNow: 228.84,
          },
        },
      },
      {
        date: '2024-10-14',
        festivos: [
          'Festividad local de Molinicos',
          'Festividad local de Belinchon',
        ],
        pc_ocupacion: 47.0,
        room: {
          double: {
            newPrice: 94.41,
            priceBefore: 97.25,
            priceNow: 106.85,
          },
          single: {
            newPrice: 163.96,
            priceBefore: 169.37,
            priceNow: 171.83,
          },
          suite: {
            newPrice: 208.25,
            priceBefore: 207.8,
            priceNow: 204.19,
          },
        },
      },
      {
        date: '2024-10-15',
        festivos: [
          'Festividad local de Malagón',
          'Festividad local de Jabaga',
          'Festividad local de Villanueva de la Jara',
          'Festividad local de Pastrana',
        ],
        pc_ocupacion: 57.0,
        room: {
          double: {
            newPrice: 129.78,
            priceBefore: 121.26,
            priceNow: 127.47,
          },
          single: {
            newPrice: 191.81,
            priceBefore: 177.51,
            priceNow: 174.29,
          },
          suite: {
            newPrice: 236.51,
            priceBefore: 226.89,
            priceNow: 233.8,
          },
        },
      },
      {
        date: '2024-10-16',
        festivos: [],
        pc_ocupacion: 44.0,
        room: {
          double: {
            newPrice: 108.76,
            priceBefore: 104.46,
            priceNow: 110.28,
          },
          single: {
            newPrice: 117.66,
            priceBefore: 123.33,
            priceNow: 133.42,
          },
          suite: {
            newPrice: 202.89,
            priceBefore: 209.15,
            priceNow: 206.23,
          },
        },
      },
      {
        date: '2024-10-17',
        festivos: ['Festividad local de Cívica'],
        pc_ocupacion: 41.0,
        room: {
          double: {
            newPrice: 146.78,
            priceBefore: 150.13,
            priceNow: 157.66,
          },
          single: {
            newPrice: 92.99,
            priceBefore: 88.97,
            priceNow: 91.42,
          },
          suite: {
            newPrice: 215.67,
            priceBefore: 202.59,
            priceNow: 195.82,
          },
        },
      },
      {
        date: '2024-10-18',
        festivos: [],
        pc_ocupacion: 53.0,
        room: {
          double: {
            newPrice: 163.46,
            priceBefore: 169.28,
            priceNow: 182.37,
          },
          single: {
            newPrice: 135.59,
            priceBefore: 132.46,
            priceNow: 133.77,
          },
          suite: {
            newPrice: 226.58,
            priceBefore: 207.0,
            priceNow: 205.68,
          },
        },
      },
      {
        date: '2024-10-19',
        festivos: [],
        pc_ocupacion: 33.0,
        room: {
          double: {
            newPrice: 125.78,
            priceBefore: 115.23,
            priceNow: 119.24,
          },
          single: {
            newPrice: 102.14,
            priceBefore: 95.46,
            priceNow: 95.25,
          },
          suite: {
            newPrice: 125.36,
            priceBefore: 117.06,
            priceNow: 111.38,
          },
        },
      },
      {
        date: '2024-10-20',
        festivos: [],
        pc_ocupacion: 37.0,
        room: {
          double: {
            newPrice: 101.02,
            priceBefore: 100.93,
            priceNow: 100.51,
          },
          single: {
            newPrice: 79.1,
            priceBefore: 78.11,
            priceNow: 85.39,
          },
          suite: {
            newPrice: 244.47,
            priceBefore: 231.01,
            priceNow: 220.44,
          },
        },
      },
      {
        date: '2024-10-21',
        festivos: ['Festividad local de Brihuega'],
        pc_ocupacion: 39.0,
        room: {
          double: {
            newPrice: 206.13,
            priceBefore: 187.92,
            priceNow: 203.98,
          },
          single: {
            newPrice: 131.61,
            priceBefore: 126.71,
            priceNow: 131.61,
          },
          suite: {
            newPrice: 126.68,
            priceBefore: 131.26,
            priceNow: 128.5,
          },
        },
      },
      {
        date: '2024-10-22',
        festivos: [],
        pc_ocupacion: 56.0,
        room: {
          double: {
            newPrice: 134.05,
            priceBefore: 126.29,
            priceNow: 122.48,
          },
          single: {
            newPrice: 143.2,
            priceBefore: 141.83,
            priceNow: 138.09,
          },
          suite: {
            newPrice: 262.56,
            priceBefore: 264.93,
            priceNow: 269.16,
          },
        },
      },
      {
        date: '2024-10-23',
        festivos: [],
        pc_ocupacion: 43.0,
        room: {
          double: {
            newPrice: 151.48,
            priceBefore: 147.71,
            priceNow: 161.59,
          },
          single: {
            newPrice: 111.96,
            priceBefore: 115.59,
            priceNow: 117.7,
          },
          suite: {
            newPrice: 202.98,
            priceBefore: 186.51,
            priceNow: 185.32,
          },
        },
      },
      {
        date: '2024-10-24',
        festivos: ['Festividad local de Hellín'],
        pc_ocupacion: 67.0,
        room: {
          double: {
            newPrice: 166.95,
            priceBefore: 173.36,
            priceNow: 167.56,
          },
          single: {
            newPrice: 124.05,
            priceBefore: 118.42,
            priceNow: 122.75,
          },
          suite: {
            newPrice: 318.34,
            priceBefore: 292.85,
            priceNow: 289.02,
          },
        },
      },
      {
        date: '2024-10-25',
        festivos: [],
        pc_ocupacion: 50.0,
        room: {
          double: {
            newPrice: 138.21,
            priceBefore: 132.98,
            priceNow: 133.55,
          },
          single: {
            newPrice: 100.43,
            priceBefore: 103.05,
            priceNow: 111.61,
          },
          suite: {
            newPrice: 258.66,
            priceBefore: 247.22,
            priceNow: 262.27,
          },
        },
      },
      {
        date: '2024-10-26',
        festivos: [],
        pc_ocupacion: 47.0,
        room: {
          double: {
            newPrice: 96.73,
            priceBefore: 99.31,
            priceNow: 108.18,
          },
          single: {
            newPrice: 100.65,
            priceBefore: 94.9,
            priceNow: 92.2,
          },
          suite: {
            newPrice: 241.19,
            priceBefore: 251.71,
            priceNow: 263.09,
          },
        },
      },
      {
        date: '2024-10-27',
        festivos: [],
        pc_ocupacion: 43.0,
        room: {
          double: {
            newPrice: 101.38,
            priceBefore: 96.97,
            priceNow: 101.87,
          },
          single: {
            newPrice: 112.0,
            priceBefore: 114.93,
            priceNow: 113.11,
          },
          suite: {
            newPrice: 268.84,
            priceBefore: 258.45,
            priceNow: 269.67,
          },
        },
      },
      {
        date: '2024-10-28',
        festivos: [],
        pc_ocupacion: 36.0,
        room: {
          double: {
            newPrice: 98.08,
            priceBefore: 100.54,
            priceNow: 100.64,
          },
          single: {
            newPrice: 125.6,
            priceBefore: 120.6,
            priceNow: 125.93,
          },
          suite: {
            newPrice: 194.31,
            priceBefore: 194.86,
            priceNow: 200.24,
          },
        },
      },
      {
        date: '2024-10-29',
        festivos: [],
        pc_ocupacion: 37.0,
        room: {
          double: {
            newPrice: 98.64,
            priceBefore: 90.81,
            priceNow: 93.8,
          },
          single: {
            newPrice: 99.81,
            priceBefore: 98.36,
            priceNow: 103.25,
          },
          suite: {
            newPrice: 188.83,
            priceBefore: 182.4,
            priceNow: 174.98,
          },
        },
      },
      {
        date: '2024-10-30',
        festivos: ['Festividad local de Barriopedro'],
        pc_ocupacion: 36.0,
        room: {
          double: {
            newPrice: 77.4,
            priceBefore: 81.09,
            priceNow: 80.55,
          },
          single: {
            newPrice: 87.51,
            priceBefore: 83.94,
            priceNow: 82.92,
          },
          suite: {
            newPrice: 200.7,
            priceBefore: 197.19,
            priceNow: 204.74,
          },
        },
      },
      {
        date: '2024-10-31',
        festivos: ['Festividad local de Menasalbas'],
        pc_ocupacion: 41.0,
        room: {
          double: {
            newPrice: 90.19,
            priceBefore: 84.35,
            priceNow: 85.37,
          },
          single: {
            newPrice: 106.39,
            priceBefore: 106.56,
            priceNow: 110.55,
          },
          suite: {
            newPrice: 240.88,
            priceBefore: 233.34,
            priceNow: 248.28,
          },
        },
      },
      {
        date: '2024-11-01',
        festivos: ['Día de Todos los Santos'],
        pc_ocupacion: 35.0,
        room: {
          double: {
            newPrice: 79.61,
            priceBefore: 79.54,
            priceNow: 84.65,
          },
          single: {
            newPrice: 111.81,
            priceBefore: 109.87,
            priceNow: 116.36,
          },
          suite: {
            newPrice: 217.53,
            priceBefore: 198.72,
            priceNow: 203.34,
          },
        },
      },
      {
        date: '2024-11-02',
        festivos: [],
        pc_ocupacion: 38.0,
        room: {
          double: {
            newPrice: 86.74,
            priceBefore: 83.06,
            priceNow: 81.39,
          },
          single: {
            newPrice: 109.84,
            priceBefore: 102.17,
            priceNow: 106.35,
          },
          suite: {
            newPrice: 208.79,
            priceBefore: 192.21,
            priceNow: 190.41,
          },
        },
      },
      {
        date: '2024-11-03',
        festivos: [],
        pc_ocupacion: 28.0,
        room: {
          double: {
            newPrice: 69.54,
            priceBefore: 71.47,
            priceNow: 73.81,
          },
          single: {
            newPrice: 77.62,
            priceBefore: 80.78,
            priceNow: 87.32,
          },
          suite: {
            newPrice: 185.36,
            priceBefore: 182.53,
            priceNow: 175.62,
          },
        },
      },
      {
        date: '2024-11-04',
        festivos: [
          'Festividad local de Las Labores',
          'Festividad local de Barchin del Hoyo',
        ],
        pc_ocupacion: 45.0,
        room: {
          double: {
            newPrice: 156.1,
            priceBefore: 157.3,
            priceNow: 153.97,
          },
          single: {
            newPrice: 108.66,
            priceBefore: 110.43,
            priceNow: 110.74,
          },
          suite: {
            newPrice: 220.59,
            priceBefore: 202.43,
            priceNow: 196.97,
          },
        },
      },
      {
        date: '2024-11-05',
        festivos: [],
        pc_ocupacion: 27.0,
        room: {
          double: {
            newPrice: 82.32,
            priceBefore: 84.5,
            priceNow: 88.12,
          },
          single: {
            newPrice: 127.96,
            priceBefore: 129.1,
            priceNow: 138.85,
          },
          suite: {
            newPrice: 111.67,
            priceBefore: 101.65,
            priceNow: 110.77,
          },
        },
      },
      {
        date: '2024-11-06',
        festivos: [],
        pc_ocupacion: 33.0,
        room: {
          double: {
            newPrice: 82.89,
            priceBefore: 85.43,
            priceNow: 92.83,
          },
          single: {
            newPrice: 122.19,
            priceBefore: 123.38,
            priceNow: 133.83,
          },
          suite: {
            newPrice: 186.45,
            priceBefore: 178.49,
            priceNow: 181.15,
          },
        },
      },
      {
        date: '2024-11-07',
        festivos: [],
        pc_ocupacion: 29.0,
        room: {
          double: {
            newPrice: 69.44,
            priceBefore: 71.52,
            priceNow: 70.25,
          },
          single: {
            newPrice: 113.05,
            priceBefore: 104.73,
            priceNow: 107.76,
          },
          suite: {
            newPrice: 176.05,
            priceBefore: 174.11,
            priceNow: 169.4,
          },
        },
      },
      {
        date: '2024-11-08',
        festivos: ['Festividad local de Pozo de Almoguera'],
        pc_ocupacion: 35.0,
        room: {
          double: {
            newPrice: 73.57,
            priceBefore: 72.96,
            priceNow: 69.35,
          },
          single: {
            newPrice: 114.48,
            priceBefore: 118.75,
            priceNow: 125.69,
          },
          suite: {
            newPrice: 177.25,
            priceBefore: 175.68,
            priceNow: 192.93,
          },
        },
      },
      {
        date: '2024-11-09',
        festivos: [],
        pc_ocupacion: 33.0,
        room: {
          double: {
            newPrice: 74.87,
            priceBefore: 68.36,
            priceNow: 66.33,
          },
          single: {
            newPrice: 131.04,
            priceBefore: 119.57,
            priceNow: 130.11,
          },
          suite: {
            newPrice: 188.37,
            priceBefore: 174.0,
            priceNow: 171.2,
          },
        },
      },
      {
        date: '2024-11-10',
        festivos: [],
        pc_ocupacion: 33.0,
        room: {
          double: {
            newPrice: 89.01,
            priceBefore: 85.41,
            priceNow: 81.59,
          },
          single: {
            newPrice: 104.7,
            priceBefore: 98.47,
            priceNow: 100.11,
          },
          suite: {
            newPrice: 164.51,
            priceBefore: 158.92,
            priceNow: 163.66,
          },
        },
      },
      {
        date: '2024-11-11',
        festivos: [
          'Festividad local de Cañaveras',
          'Festividad local de Fuertescusa',
          'Festividad local de San Martin de Boniches',
          'Festividad local de Anquela del Ducado',
          'Festividad local de Masegoso de Tajuña',
          'Festividad local de Pozo de Almoguera',
          'Festividad local de San Martín de Pusa',
          'Festividad local de Villanueva de Alcardete',
        ],
        pc_ocupacion: 32.0,
        room: {
          double: {
            newPrice: 135.71,
            priceBefore: 142.43,
            priceNow: 146.59,
          },
          single: {
            newPrice: 87.44,
            priceBefore: 85.22,
            priceNow: 90.99,
          },
          suite: {
            newPrice: 135.24,
            priceBefore: 131.72,
            priceNow: 143.78,
          },
        },
      },
      {
        date: '2024-11-12',
        festivos: [
          'Festividad local de Cañamares',
          'Festividad local de Cogolludo',
          'Festividad local de Muduex',
        ],
        pc_ocupacion: 29.0,
        room: {
          double: {
            newPrice: 81.98,
            priceBefore: 80.97,
            priceNow: 78.97,
          },
          single: {
            newPrice: 125.94,
            priceBefore: 115.64,
            priceNow: 116.03,
          },
          suite: {
            newPrice: 142.82,
            priceBefore: 129.86,
            priceNow: 124.52,
          },
        },
      },
      {
        date: '2024-11-13',
        festivos: [],
        pc_ocupacion: 26.0,
        room: {
          double: {
            newPrice: 65.62,
            priceBefore: 68.8,
            priceNow: 73.4,
          },
          single: {
            newPrice: 96.33,
            priceBefore: 99.33,
            priceNow: 100.47,
          },
          suite: {
            newPrice: 133.63,
            priceBefore: 128.6,
            priceNow: 127.58,
          },
        },
      },
      {
        date: '2024-11-14',
        festivos: ['Festividad local de Tartanedo'],
        pc_ocupacion: 31.0,
        room: {
          double: {
            newPrice: 106.73,
            priceBefore: 109.17,
            priceNow: 105.48,
          },
          single: {
            newPrice: 103.84,
            priceBefore: 99.51,
            priceNow: 100.36,
          },
          suite: {
            newPrice: 115.82,
            priceBefore: 115.17,
            priceNow: 112.7,
          },
        },
      },
      {
        date: '2024-11-15',
        festivos: [],
        pc_ocupacion: 26.0,
        room: {
          double: {
            newPrice: 109.32,
            priceBefore: 103.87,
            priceNow: 110.32,
          },
          single: {
            newPrice: 81.79,
            priceBefore: 80.66,
            priceNow: 82.78,
          },
          suite: {
            newPrice: 123.14,
            priceBefore: 126.09,
            priceNow: 125.87,
          },
        },
      },
      {
        date: '2024-11-16',
        festivos: [],
        pc_ocupacion: 31.0,
        room: {
          double: {
            newPrice: 82.64,
            priceBefore: 80.07,
            priceNow: 77.49,
          },
          single: {
            newPrice: 129.57,
            priceBefore: 120.65,
            priceNow: 121.33,
          },
          suite: {
            newPrice: 126.68,
            priceBefore: 118.29,
            priceNow: 120.78,
          },
        },
      },
      {
        date: '2024-11-17',
        festivos: [],
        pc_ocupacion: 21.0,
        room: {
          double: {
            newPrice: 79.29,
            priceBefore: 81.69,
            priceNow: 85.32,
          },
          single: {
            newPrice: 85.03,
            priceBefore: 83.34,
            priceNow: 90.78,
          },
          suite: {
            newPrice: 118.42,
            priceBefore: 113.81,
            priceNow: 118.18,
          },
        },
      },
      {
        date: '2024-11-18',
        festivos: [
          'Festividad local de Arcos de la Cantera',
          'Festividad local de San Román de los Montes',
        ],
        pc_ocupacion: 30.0,
        room: {
          double: {
            newPrice: 74.75,
            priceBefore: 72.26,
            priceNow: 76.45,
          },
          single: {
            newPrice: 155.62,
            priceBefore: 153.62,
            priceNow: 164.3,
          },
          suite: {
            newPrice: 94.67,
            priceBefore: 96.07,
            priceNow: 103.0,
          },
        },
      },
      {
        date: '2024-11-19',
        festivos: [],
        pc_ocupacion: 34.0,
        room: {
          double: {
            newPrice: 87.13,
            priceBefore: 83.13,
            priceNow: 81.86,
          },
          single: {
            newPrice: 76.17,
            priceBefore: 79.4,
            priceNow: 77.37,
          },
          suite: {
            newPrice: 176.47,
            priceBefore: 170.1,
            priceNow: 187.09,
          },
        },
      },
      {
        date: '2024-11-20',
        festivos: [],
        pc_ocupacion: 31.0,
        room: {
          double: {
            newPrice: 75.94,
            priceBefore: 78.3,
            priceNow: 85.4,
          },
          single: {
            newPrice: 158.32,
            priceBefore: 157.47,
            priceNow: 154.72,
          },
          suite: {
            newPrice: 103.86,
            priceBefore: 94.57,
            priceNow: 102.12,
          },
        },
      },
      {
        date: '2024-11-21',
        festivos: [],
        pc_ocupacion: 38.0,
        room: {
          double: {
            newPrice: 84.73,
            priceBefore: 81.9,
            priceNow: 81.86,
          },
          single: {
            newPrice: 96.25,
            priceBefore: 100.51,
            priceNow: 110.02,
          },
          suite: {
            newPrice: 179.26,
            priceBefore: 184.28,
            priceNow: 202.39,
          },
        },
      },
      {
        date: '2024-11-22',
        festivos: [],
        pc_ocupacion: 42.0,
        room: {
          double: {
            newPrice: 137.86,
            priceBefore: 133.19,
            priceNow: 132.32,
          },
          single: {
            newPrice: 87.55,
            priceBefore: 91.0,
            priceNow: 86.96,
          },
          suite: {
            newPrice: 172.48,
            priceBefore: 178.57,
            priceNow: 195.28,
          },
        },
      },
      {
        date: '2024-11-23',
        festivos: ['Festividad local de Zarzuela de Jadraque'],
        pc_ocupacion: 33.0,
        room: {
          double: {
            newPrice: 69.49,
            priceBefore: 72.71,
            priceNow: 78.37,
          },
          single: {
            newPrice: 97.29,
            priceBefore: 94.22,
            priceNow: 93.5,
          },
          suite: {
            newPrice: 189.48,
            priceBefore: 180.08,
            priceNow: 195.06,
          },
        },
      },
      {
        date: '2024-11-24',
        festivos: [],
        pc_ocupacion: 33.0,
        room: {
          double: {
            newPrice: 91.86,
            priceBefore: 87.26,
            priceNow: 92.7,
          },
          single: {
            newPrice: 81.21,
            priceBefore: 74.82,
            priceNow: 78.54,
          },
          suite: {
            newPrice: 179.19,
            priceBefore: 183.43,
            priceNow: 193.79,
          },
        },
      },
      {
        date: '2024-11-25',
        festivos: [
          'Festividad local de Angón',
          'Festividad local de Tórtola de Henares',
        ],
        pc_ocupacion: 30.0,
        room: {
          double: {
            newPrice: 79.99,
            priceBefore: 73.76,
            priceNow: 71.99,
          },
          single: {
            newPrice: 90.39,
            priceBefore: 94.08,
            priceNow: 94.21,
          },
          suite: {
            newPrice: 167.13,
            priceBefore: 158.48,
            priceNow: 161.36,
          },
        },
      },
      {
        date: '2024-11-26',
        festivos: [],
        pc_ocupacion: 26.0,
        room: {
          double: {
            newPrice: 97.18,
            priceBefore: 94.94,
            priceNow: 97.83,
          },
          single: {
            newPrice: 77.78,
            priceBefore: 80.8,
            priceNow: 77.39,
          },
          suite: {
            newPrice: 132.8,
            priceBefore: 134.72,
            priceNow: 148.16,
          },
        },
      },
      {
        date: '2024-11-27',
        festivos: [],
        pc_ocupacion: 29.0,
        room: {
          double: {
            newPrice: 68.45,
            priceBefore: 67.92,
            priceNow: 68.3,
          },
          single: {
            newPrice: 101.9,
            priceBefore: 100.89,
            priceNow: 110.89,
          },
          suite: {
            newPrice: 134.42,
            priceBefore: 125.58,
            priceNow: 137.91,
          },
        },
      },
      {
        date: '2024-11-28',
        festivos: [],
        pc_ocupacion: 21.0,
        room: {
          double: {
            newPrice: 75.65,
            priceBefore: 72.76,
            priceNow: 77.15,
          },
          single: {
            newPrice: 74.38,
            priceBefore: 68.41,
            priceNow: 71.86,
          },
          suite: {
            newPrice: 125.21,
            priceBefore: 119.88,
            priceNow: 120.1,
          },
        },
      },
      {
        date: '2024-11-29',
        festivos: [
          'Festividad local de Viso del Marques',
          'Festividad local de Castillo de Bayuela',
          'Festividad local de San Martín de Montalbán',
        ],
        pc_ocupacion: 29.0,
        room: {
          double: {
            newPrice: 79.0,
            priceBefore: 81.15,
            priceNow: 84.56,
          },
          single: {
            newPrice: 120.53,
            priceBefore: 112.63,
            priceNow: 117.36,
          },
          suite: {
            newPrice: 129.09,
            priceBefore: 126.03,
            priceNow: 129.35,
          },
        },
      },
      {
        date: '2024-11-30',
        festivos: [
          'Festividad local de Paredes',
          'Festividad local de Fuentelahiguera de Albatages',
          'Festividad local de Romanillos de Atienza',
          'Festividad local de San Andrés del Rey',
          'Festividad local de Carmena',
        ],
        pc_ocupacion: 26.0,
        room: {
          double: {
            newPrice: 84.44,
            priceBefore: 79.97,
            priceNow: 77.96,
          },
          single: {
            newPrice: 75.46,
            priceBefore: 74.43,
            priceNow: 74.02,
          },
          suite: {
            newPrice: 140.96,
            priceBefore: 140.22,
            priceNow: 134.27,
          },
        },
      },
      {
        date: '2024-12-01',
        festivos: [],
        pc_ocupacion: 30.0,
        room: {
          double: {
            newPrice: 96.01,
            priceBefore: 90.39,
            priceNow: 94.77,
          },
          single: {
            newPrice: 115.31,
            priceBefore: 111.18,
            priceNow: 107.39,
          },
          suite: {
            newPrice: 122.06,
            priceBefore: 125.49,
            priceNow: 136.5,
          },
        },
      },
      {
        date: '2024-12-02',
        festivos: [],
        pc_ocupacion: 29.0,
        room: {
          double: {
            newPrice: 103.54,
            priceBefore: 98.24,
            priceNow: 97.44,
          },
          single: {
            newPrice: 112.11,
            priceBefore: 108.55,
            priceNow: 112.86,
          },
          suite: {
            newPrice: 108.33,
            priceBefore: 112.06,
            priceNow: 112.62,
          },
        },
      },
      {
        date: '2024-12-03',
        festivos: [],
        pc_ocupacion: 27.0,
        room: {
          double: {
            newPrice: 78.27,
            priceBefore: 77.24,
            priceNow: 81.79,
          },
          single: {
            newPrice: 97.17,
            priceBefore: 96.91,
            priceNow: 93.04,
          },
          suite: {
            newPrice: 103.6,
            priceBefore: 108.21,
            priceNow: 106.53,
          },
        },
      },
      {
        date: '2024-12-04',
        festivos: [
          'Festividad local de Congostrina',
          'Festividad local de Iniestola',
          'Festividad local de Las Hunfrías',
        ],
        pc_ocupacion: 48.0,
        room: {
          double: {
            newPrice: 170.14,
            priceBefore: 170.23,
            priceNow: 180.0,
          },
          single: {
            newPrice: 95.81,
            priceBefore: 88.03,
            priceNow: 87.02,
          },
          suite: {
            newPrice: 261.49,
            priceBefore: 237.96,
            priceNow: 249.91,
          },
        },
      },
      {
        date: '2024-12-05',
        festivos: [
          'Festividad local de Casas de Lazaro',
          'Festividad local de Matillas',
          'Festividad local de Romanones',
        ],
        pc_ocupacion: 24.0,
        room: {
          double: {
            newPrice: 89.93,
            priceBefore: 93.09,
            priceNow: 94.46,
          },
          single: {
            newPrice: 94.84,
            priceBefore: 95.93,
            priceNow: 95.58,
          },
          suite: {
            newPrice: 114.52,
            priceBefore: 116.57,
            priceNow: 123.39,
          },
        },
      },
      {
        date: '2024-12-06',
        festivos: ['Día de la Constitución Española'],
        pc_ocupacion: 33.0,
        room: {
          double: {
            newPrice: 81.03,
            priceBefore: 76.09,
            priceNow: 73.03,
          },
          single: {
            newPrice: 146.42,
            priceBefore: 139.21,
            priceNow: 151.04,
          },
          suite: {
            newPrice: 119.66,
            priceBefore: 122.73,
            priceNow: 126.46,
          },
        },
      },
      {
        date: '2024-12-07',
        festivos: [],
        pc_ocupacion: 32.0,
        room: {
          double: {
            newPrice: 84.66,
            priceBefore: 78.44,
            priceNow: 82.51,
          },
          single: {
            newPrice: 129.09,
            priceBefore: 119.91,
            priceNow: 124.82,
          },
          suite: {
            newPrice: 113.45,
            priceBefore: 115.1,
            priceNow: 119.98,
          },
        },
      },
      {
        date: '2024-12-08',
        festivos: [],
        pc_ocupacion: 32.0,
        room: {
          double: {
            newPrice: 140.88,
            priceBefore: 133.44,
            priceNow: 128.14,
          },
          single: {
            newPrice: 82.82,
            priceBefore: 81.53,
            priceNow: 87.97,
          },
          suite: {
            newPrice: 118.79,
            priceBefore: 121.6,
            priceNow: 118.99,
          },
        },
      },
      {
        date: '2024-12-09',
        festivos: [
          'Festividad local de Almuradiel',
          'Festividad local de Cañada de Calatrava',
          'Festividad local de Arrancacepas',
          'Festividad local de Barchin del Hoyo',
          'Festividad local de Horcajo De Santiago',
          'Festividad local de Viana de Jadraque',
          'Festividad local de Alcaudete de la Jara',
          'Festividad local de Camarenilla',
          'Festividad local de Casasbuenas',
          'Festividad local de Escalona',
          'Festividad local de Navaltoril',
          'Festividad local de Villaseca de la Sagra',
        ],
        pc_ocupacion: 27.0,
        room: {
          double: {
            newPrice: 93.88,
            priceBefore: 90.11,
            priceNow: 89.11,
          },
          single: {
            newPrice: 84.34,
            priceBefore: 86.23,
            priceNow: 84.13,
          },
          suite: {
            newPrice: 151.18,
            priceBefore: 147.92,
            priceNow: 158.15,
          },
        },
      },
      {
        date: '2024-12-10',
        festivos: [
          'Festividad local de Durón',
          'Festividad local de Sienes',
          'Festividad local de Navaltoril',
        ],
        pc_ocupacion: 22.0,
        room: {
          double: {
            newPrice: 71.07,
            priceBefore: 70.23,
            priceNow: 75.55,
          },
          single: {
            newPrice: 78.57,
            priceBefore: 76.07,
            priceNow: 78.34,
          },
          suite: {
            newPrice: 91.23,
            priceBefore: 93.11,
            priceNow: 100.28,
          },
        },
      },
      {
        date: '2024-12-11',
        festivos: [],
        pc_ocupacion: 38.0,
        room: {
          double: {
            newPrice: 174.77,
            priceBefore: 159.33,
            priceNow: 155.72,
          },
          single: {
            newPrice: 130.08,
            priceBefore: 129.86,
            priceNow: 140.89,
          },
          suite: {
            newPrice: 108.06,
            priceBefore: 101.79,
            priceNow: 102.28,
          },
        },
      },
      {
        date: '2024-12-12',
        festivos: [],
        pc_ocupacion: 29.0,
        room: {
          double: {
            newPrice: 90.74,
            priceBefore: 91.44,
            priceNow: 87.0,
          },
          single: {
            newPrice: 94.96,
            priceBefore: 89.63,
            priceNow: 91.39,
          },
          suite: {
            newPrice: 126.59,
            priceBefore: 133.15,
            priceNow: 134.43,
          },
        },
      },
      {
        date: '2024-12-13',
        festivos: [
          'Festividad local de Fuente el Fresno',
          'Festividad local de Retuerta del Bullaque',
          'Festividad local de Almonacid del Marquesado',
          'Festividad local de Bolliga',
          'Festividad local de Castillo-Albarañez',
          'Festividad local de Olmedilla del Campo',
          'Festividad local de Aldeanueva de Guadalajara',
          'Festividad local de Valdeavellano',
        ],
        pc_ocupacion: 25.0,
        room: {
          double: {
            newPrice: 88.39,
            priceBefore: 86.62,
            priceNow: 91.33,
          },
          single: {
            newPrice: 51.86,
            priceBefore: 54.58,
            priceNow: 57.43,
          },
          suite: {
            newPrice: 163.02,
            priceBefore: 148.93,
            priceNow: 160.14,
          },
        },
      },
      {
        date: '2024-12-14',
        festivos: [],
        pc_ocupacion: 33.0,
        room: {
          double: {
            newPrice: 102.83,
            priceBefore: 97.78,
            priceNow: 100.23,
          },
          single: {
            newPrice: 84.59,
            priceBefore: 84.83,
            priceNow: 84.41,
          },
          suite: {
            newPrice: 158.95,
            priceBefore: 161.81,
            priceNow: 177.18,
          },
        },
      },
      {
        date: '2024-12-15',
        festivos: [],
        pc_ocupacion: 32.0,
        room: {
          double: {
            newPrice: 110.83,
            priceBefore: 112.61,
            priceNow: 114.98,
          },
          single: {
            newPrice: 80.01,
            priceBefore: 72.85,
            priceNow: 75.76,
          },
          suite: {
            newPrice: 162.19,
            priceBefore: 153.79,
            priceNow: 150.78,
          },
        },
      },
      {
        date: '2024-12-16',
        festivos: [],
        pc_ocupacion: 25.0,
        room: {
          double: {
            newPrice: 129.34,
            priceBefore: 119.69,
            priceNow: 129.04,
          },
          single: {
            newPrice: 72.31,
            priceBefore: 75.23,
            priceNow: 71.84,
          },
          suite: {
            newPrice: 94.29,
            priceBefore: 88.44,
            priceNow: 94.64,
          },
        },
      },
      {
        date: '2024-12-17',
        festivos: ['Festividad local de Narboneta'],
        pc_ocupacion: 23.0,
        room: {
          double: {
            newPrice: 103.66,
            priceBefore: 103.37,
            priceNow: 112.41,
          },
          single: {
            newPrice: 76.7,
            priceBefore: 77.36,
            priceNow: 75.15,
          },
          suite: {
            newPrice: 101.13,
            priceBefore: 92.69,
            priceNow: 89.6,
          },
        },
      },
      {
        date: '2024-12-18',
        festivos: ['Festividad local de Valverdejo'],
        pc_ocupacion: 40.0,
        room: {
          double: {
            newPrice: 143.16,
            priceBefore: 138.63,
            priceNow: 147.02,
          },
          single: {
            newPrice: 84.95,
            priceBefore: 82.93,
            priceNow: 86.72,
          },
          suite: {
            newPrice: 193.43,
            priceBefore: 184.98,
            priceNow: 194.25,
          },
        },
      },
      {
        date: '2024-12-19',
        festivos: [],
        pc_ocupacion: 31.0,
        room: {
          double: {
            newPrice: 126.01,
            priceBefore: 127.15,
            priceNow: 137.9,
          },
          single: {
            newPrice: 111.51,
            priceBefore: 112.75,
            priceNow: 123.01,
          },
          suite: {
            newPrice: 121.64,
            priceBefore: 117.06,
            priceNow: 112.71,
          },
        },
      },
      {
        date: '2024-12-20',
        festivos: [
          'Festividad local de Torralba',
          'Festividad local de Villaminaya',
        ],
        pc_ocupacion: 43.0,
        room: {
          double: {
            newPrice: 190.52,
            priceBefore: 178.63,
            priceNow: 180.67,
          },
          single: {
            newPrice: 106.76,
            priceBefore: 97.95,
            priceNow: 97.56,
          },
          suite: {
            newPrice: 169.27,
            priceBefore: 168.65,
            priceNow: 160.28,
          },
        },
      },
      {
        date: '2024-12-21',
        festivos: [],
        pc_ocupacion: 27.0,
        room: {
          double: {
            newPrice: 75.1,
            priceBefore: 68.81,
            priceNow: 73.06,
          },
          single: {
            newPrice: 95.44,
            priceBefore: 92.84,
            priceNow: 99.23,
          },
          suite: {
            newPrice: 171.58,
            priceBefore: 157.51,
            priceNow: 149.74,
          },
        },
      },
      {
        date: '2024-12-22',
        festivos: [],
        pc_ocupacion: 28.0,
        room: {
          double: {
            newPrice: 74.59,
            priceBefore: 70.08,
            priceNow: 70.7,
          },
          single: {
            newPrice: 50.89,
            priceBefore: 52.25,
            priceNow: 50.76,
          },
          suite: {
            newPrice: 157.66,
            priceBefore: 152.25,
            priceNow: 145.39,
          },
        },
      },
      {
        date: '2024-12-23',
        festivos: ['Festividad local de Huecas'],
        pc_ocupacion: 33.0,
        room: {
          double: {
            newPrice: 125.21,
            priceBefore: 123.02,
            priceNow: 123.09,
          },
          single: {
            newPrice: 65.32,
            priceBefore: 68.28,
            priceNow: 68.15,
          },
          suite: {
            newPrice: 168.57,
            priceBefore: 169.56,
            priceNow: 164.38,
          },
        },
      },
      {
        date: '2024-12-24',
        festivos: [],
        pc_ocupacion: 28.0,
        room: {
          double: {
            newPrice: 79.16,
            priceBefore: 81.18,
            priceNow: 88.04,
          },
          single: {
            newPrice: 105.51,
            priceBefore: 104.47,
            priceNow: 102.78,
          },
          suite: {
            newPrice: 155.38,
            priceBefore: 142.6,
            priceNow: 155.52,
          },
        },
      },
      {
        date: '2024-12-25',
        festivos: ['Natividad del Señor'],
        pc_ocupacion: 39.0,
        room: {
          double: {
            newPrice: 132.05,
            priceBefore: 132.31,
            priceNow: 133.72,
          },
          single: {
            newPrice: 82.4,
            priceBefore: 77.53,
            priceNow: 80.19,
          },
          suite: {
            newPrice: 170.2,
            priceBefore: 173.9,
            priceNow: 185.23,
          },
        },
      },
      {
        date: '2024-12-26',
        festivos: [
          'Festividad local de Liétor',
          'Festividad local de Barbalimpia',
          'Festividad local de La Huerguina',
          'Festividad local de Humanes',
        ],
        pc_ocupacion: 32.0,
        room: {
          double: {
            newPrice: 119.67,
            priceBefore: 117.21,
            priceNow: 126.9,
          },
          single: {
            newPrice: 65.46,
            priceBefore: 65.23,
            priceNow: 65.03,
          },
          suite: {
            newPrice: 167.25,
            priceBefore: 168.88,
            priceNow: 179.86,
          },
        },
      },
      {
        date: '2024-12-27',
        festivos: ['Festividad local de Casasimarro'],
        pc_ocupacion: 43.0,
        room: {
          double: {
            newPrice: 77.5,
            priceBefore: 75.16,
            priceNow: 72.51,
          },
          single: {
            newPrice: 110.73,
            priceBefore: 107.02,
            priceNow: 112.74,
          },
          suite: {
            newPrice: 232.74,
            priceBefore: 242.21,
            priceNow: 232.59,
          },
        },
      },
      {
        date: '2024-12-28',
        festivos: ['Festividad local de Alcázar de San Juan'],
        pc_ocupacion: 59.0,
        room: {
          double: {
            newPrice: 178.02,
            priceBefore: 186.39,
            priceNow: 186.35,
          },
          single: {
            newPrice: 103.9,
            priceBefore: 101.63,
            priceNow: 98.4,
          },
          suite: {
            newPrice: 280.23,
            priceBefore: 256.83,
            priceNow: 254.92,
          },
        },
      },
      {
        date: '2024-12-29',
        festivos: [],
        pc_ocupacion: 41.0,
        room: {
          double: {
            newPrice: 132.04,
            priceBefore: 129.4,
            priceNow: 141.15,
          },
          single: {
            newPrice: 103.65,
            priceBefore: 99.05,
            priceNow: 104.8,
          },
          suite: {
            newPrice: 224.93,
            priceBefore: 218.86,
            priceNow: 215.53,
          },
        },
      },
      {
        date: '2024-12-30',
        festivos: ['Festividad local de Alcabón'],
        pc_ocupacion: 47.0,
        room: {
          double: {
            newPrice: 154.47,
            priceBefore: 155.54,
            priceNow: 156.69,
          },
          single: {
            newPrice: 91.86,
            priceBefore: 84.11,
            priceNow: 92.42,
          },
          suite: {
            newPrice: 291.58,
            priceBefore: 267.89,
            priceNow: 267.35,
          },
        },
      },
      {
        date: '2024-12-31',
        festivos: ['Festividad local de El Robledo'],
        pc_ocupacion: 59.0,
        room: {
          double: {
            newPrice: 179.97,
            priceBefore: 174.39,
            priceNow: 168.55,
          },
          single: {
            newPrice: 171.54,
            priceBefore: 175.97,
            priceNow: 174.97,
          },
          suite: {
            newPrice: 267.07,
            priceBefore: 271.56,
            priceNow: 274.38,
          },
        },
      },
      {
        date: '2025-01-01',
        festivos: ['Año Nuevo'],
        pc_ocupacion: 17.0,
        room: {
          double: {
            newPrice: 50.38,
            priceBefore: 46.75,
            priceNow: 44.62,
          },
          single: {
            newPrice: 74.63,
            priceBefore: 70.3,
            priceNow: 67.83,
          },
          suite: {
            newPrice: 118.18,
            priceBefore: 113.16,
            priceNow: 113.34,
          },
        },
      },
      {
        date: '2025-01-02',
        festivos: ['Festividad local de Rozalen Del Monte'],
        pc_ocupacion: 17.0,
        room: {
          double: {
            newPrice: 83.19,
            priceBefore: 78.83,
            priceNow: 79.4,
          },
          single: {
            newPrice: 74.96,
            priceBefore: 74.42,
            priceNow: 78.71,
          },
          suite: {
            newPrice: 86.7,
            priceBefore: 79.51,
            priceNow: 82.01,
          },
        },
      },
      {
        date: '2025-01-03',
        festivos: [],
        pc_ocupacion: 16.0,
        room: {
          double: {
            newPrice: 53.99,
            priceBefore: 53.53,
            priceNow: 53.31,
          },
          single: {
            newPrice: 45.42,
            priceBefore: 42.83,
            priceNow: 45.4,
          },
          suite: {
            newPrice: 114.91,
            priceBefore: 108.69,
            priceNow: 103.85,
          },
        },
      },
      {
        date: '2025-01-04',
        festivos: [],
        pc_ocupacion: 16.0,
        room: {
          double: {
            newPrice: 80.06,
            priceBefore: 74.98,
            priceNow: 77.37,
          },
          single: {
            newPrice: 45.82,
            priceBefore: 46.51,
            priceNow: 48.91,
          },
          suite: {
            newPrice: 99.6,
            priceBefore: 102.92,
            priceNow: 112.72,
          },
        },
      },
      {
        date: '2025-01-05',
        festivos: [
          'Festividad local de Fuentes',
          'Festividad local de La Estrella',
        ],
        pc_ocupacion: 16.0,
        room: {
          double: {
            newPrice: 49.35,
            priceBefore: 50.36,
            priceNow: 51.69,
          },
          single: {
            newPrice: 55.54,
            priceBefore: 51.3,
            priceNow: 54.82,
          },
          suite: {
            newPrice: 94.61,
            priceBefore: 99.56,
            priceNow: 108.79,
          },
        },
      },
      {
        date: '2025-01-06',
        festivos: ['Epifanía del Señor'],
        pc_ocupacion: 15.0,
        room: {
          double: {
            newPrice: 59.46,
            priceBefore: 56.5,
            priceNow: 54.71,
          },
          single: {
            newPrice: 59.88,
            priceBefore: 56.23,
            priceNow: 59.7,
          },
          suite: {
            newPrice: 94.62,
            priceBefore: 96.27,
            priceNow: 102.13,
          },
        },
      },
      {
        date: '2025-01-07',
        festivos: [],
        pc_ocupacion: 15.0,
        room: {
          double: {
            newPrice: 61.39,
            priceBefore: 63.68,
            priceNow: 67.44,
          },
          single: {
            newPrice: 48.68,
            priceBefore: 46.52,
            priceNow: 50.33,
          },
          suite: {
            newPrice: 95.1,
            priceBefore: 94.11,
            priceNow: 90.14,
          },
        },
      },
      {
        date: '2025-01-08',
        festivos: ['Festividad local de Valverde de Jucar'],
        pc_ocupacion: 17.0,
        room: {
          double: {
            newPrice: 51.22,
            priceBefore: 52.91,
            priceNow: 53.46,
          },
          single: {
            newPrice: 72.68,
            priceBefore: 66.21,
            priceNow: 65.94,
          },
          suite: {
            newPrice: 103.1,
            priceBefore: 98.01,
            priceNow: 99.2,
          },
        },
      },
      {
        date: '2025-01-09',
        festivos: [],
        pc_ocupacion: 16.0,
        room: {
          double: {
            newPrice: 77.22,
            priceBefore: 79.72,
            priceNow: 84.48,
          },
          single: {
            newPrice: 49.84,
            priceBefore: 47.64,
            priceNow: 47.13,
          },
          suite: {
            newPrice: 104.42,
            priceBefore: 100.82,
            priceNow: 101.91,
          },
        },
      },
      {
        date: '2025-01-10',
        festivos: [],
        pc_ocupacion: 9.0,
        room: {
          double: {
            newPrice: 45.62,
            priceBefore: 45.25,
            priceNow: 44.82,
          },
          single: {
            newPrice: 52.29,
            priceBefore: 53.11,
            priceNow: 56.78,
          },
          suite: {
            newPrice: 69.69,
            priceBefore: 63.44,
            priceNow: 67.99,
          },
        },
      },
      {
        date: '2025-01-11',
        festivos: [],
        pc_ocupacion: 13.0,
        room: {
          double: {
            newPrice: 74.19,
            priceBefore: 70.49,
            priceNow: 70.83,
          },
          single: {
            newPrice: 45.68,
            priceBefore: 43.5,
            priceNow: 44.71,
          },
          suite: {
            newPrice: 105.73,
            priceBefore: 97.27,
            priceNow: 92.87,
          },
        },
      },
      {
        date: '2025-01-12',
        festivos: [],
        pc_ocupacion: 14.0,
        room: {
          double: {
            newPrice: 64.14,
            priceBefore: 59.27,
            priceNow: 61.22,
          },
          single: {
            newPrice: 59.99,
            priceBefore: 55.92,
            priceNow: 54.28,
          },
          suite: {
            newPrice: 84.48,
            priceBefore: 84.64,
            priceNow: 88.41,
          },
        },
      },
      {
        date: '2025-01-13',
        festivos: [],
        pc_ocupacion: 17.0,
        room: {
          double: {
            newPrice: 53.76,
            priceBefore: 55.37,
            priceNow: 60.89,
          },
          single: {
            newPrice: 42.34,
            priceBefore: 43.92,
            priceNow: 45.19,
          },
          suite: {
            newPrice: 115.08,
            priceBefore: 119.05,
            priceNow: 123.42,
          },
        },
      },
      {
        date: '2025-01-14',
        festivos: [],
        pc_ocupacion: 8.0,
        room: {
          double: {
            newPrice: 57.61,
            priceBefore: 58.35,
            priceNow: 62.67,
          },
          single: {
            newPrice: 11.68,
            priceBefore: 12.22,
            priceNow: 12.59,
          },
          suite: {
            newPrice: 78.99,
            priceBefore: 81.82,
            priceNow: 87.61,
          },
        },
      },
      {
        date: '2025-01-15',
        festivos: [],
        pc_ocupacion: 15.0,
        room: {
          double: {
            newPrice: 65.4,
            priceBefore: 65.8,
            priceNow: 64.98,
          },
          single: {
            newPrice: 39.78,
            priceBefore: 41.08,
            priceNow: 40.42,
          },
          suite: {
            newPrice: 96.41,
            priceBefore: 88.41,
            priceNow: 96.44,
          },
        },
      },
      {
        date: '2025-01-16',
        festivos: [],
        pc_ocupacion: 14.0,
        room: {
          double: {
            newPrice: 54.15,
            priceBefore: 56.88,
            priceNow: 60.21,
          },
          single: {
            newPrice: 45.16,
            priceBefore: 43.71,
            priceNow: 41.96,
          },
          suite: {
            newPrice: 75.9,
            priceBefore: 74.56,
            priceNow: 76.52,
          },
        },
      },
      {
        date: '2025-01-17',
        festivos: [
          'Festividad local de Aguas Nuevas',
          'Festividad local de Balsa de Vés',
          'Festividad local de Casas de Juan Núñez',
          'Festividad local de Mahora',
          'Festividad local de Minaya',
          'Festividad local de Robledo',
          'Festividad local de Tobarra',
          'Festividad local de Villamalea',
          'Festividad local de Alcubillas',
          'Festividad local de Puerto Lápice',
          'Festividad local de Ruidera',
          'Festividad local de Hontecillas',
          'Festividad local de Santa Maria de los Llanos',
          'Festividad local de Santa Maria del Campo Rus',
          'Festividad local de Tebar',
          'Festividad local de Alique',
          'Festividad local de Berninches',
          'Festividad local de Cendejas de Enmedio',
          'Festividad local de Escariche',
          'Festividad local de Almonacid de Toledo',
          'Festividad local de Cuerva',
          'Festividad local de Menasalbas',
          'Festividad local de Navalmoralejo',
        ],
        pc_ocupacion: 15.0,
        room: {
          double: {
            newPrice: 62.0,
            priceBefore: 56.47,
            priceNow: 58.96,
          },
          single: {
            newPrice: 61.99,
            priceBefore: 60.63,
            priceNow: 64.16,
          },
          suite: {
            newPrice: 74.97,
            priceBefore: 75.37,
            priceNow: 78.83,
          },
        },
      },
      {
        date: '2025-01-18',
        festivos: [
          'Festividad local de Cendejas de la Torre',
          'Festividad local de Medranda',
        ],
        pc_ocupacion: 10.0,
        room: {
          double: {
            newPrice: 45.79,
            priceBefore: 46.75,
            priceNow: 46.55,
          },
          single: {
            newPrice: 47.5,
            priceBefore: 46.13,
            priceNow: 44.44,
          },
          suite: {
            newPrice: 63.19,
            priceBefore: 65.24,
            priceNow: 68.62,
          },
        },
      },
      {
        date: '2025-01-19',
        festivos: [
          'Festividad local de Bogarra',
          'Festividad local de Alarcón',
          'Festividad local de Culebras',
          'Festividad local de El Cubillo',
          'Festividad local de Garaballa',
          'Festividad local de Olmeda de la Cuesta',
          'Festividad local de Villalba del Rey',
          'Festividad local de Villar del Águila',
          'Festividad local de Villar del Horno',
          'Festividad local de Cendejas de la Torre',
          'Festividad local de Hortezuela de Océn',
          'Festividad local de El Membrillo',
          'Festividad local de El Toboso',
          'Festividad local de La Mata',
          'Festividad local de Marrupe',
          'Festividad local de Mohedas de La Jara',
          'Festividad local de Sevilleja de la Jara',
          'Festividad local de Sotillo de las Palomas',
          'Festividad local de Villamiel',
        ],
        pc_ocupacion: 10.0,
        room: {
          double: {
            newPrice: 50.37,
            priceBefore: 47.06,
            priceNow: 45.11,
          },
          single: {
            newPrice: 48.14,
            priceBefore: 49.03,
            priceNow: 52.49,
          },
          suite: {
            newPrice: 83.99,
            priceBefore: 77.0,
            priceNow: 76.42,
          },
        },
      },
      {
        date: '2025-01-20',
        festivos: [
          'Festividad local de Fernán Caballero',
          'Festividad local de Valdemanco del Esteras',
          'Festividad local de Campillos Paravientos',
          'Festividad local de Casas de Roldan',
          'Festividad local de El Cubillo',
          'Festividad local de Graja de Campalbo',
          'Festividad local de Torrecilla',
          'Festividad local de Villar del Saz de Navalon',
          'Festividad local de Alustante',
          'Festividad local de La Huerce',
          'Festividad local de Millana',
          'Festividad local de Montarrón',
          'Festividad local de Valdarachas',
          'Festividad local de Belvís de la Jara',
          'Festividad local de El Membrillo',
          'Festividad local de Madridejos',
          'Festividad local de Nuño Gómez',
          'Festividad local de Parrillas',
        ],
        pc_ocupacion: 13.0,
        room: {
          double: {
            newPrice: 76.29,
            priceBefore: 73.58,
            priceNow: 74.02,
          },
          single: {
            newPrice: 56.24,
            priceBefore: 58.26,
            priceNow: 56.87,
          },
          suite: {
            newPrice: 46.09,
            priceBefore: 45.69,
            priceNow: 44.64,
          },
        },
      },
      {
        date: '2025-01-21',
        festivos: [
          'Festividad local de Aldeanueva de Atienza',
          'Festividad local de La Mierla',
        ],
        pc_ocupacion: 9.0,
        room: {
          double: {
            newPrice: 21.21,
            priceBefore: 22.26,
            priceNow: 22.86,
          },
          single: {
            newPrice: 64.83,
            priceBefore: 59.69,
            priceNow: 61.86,
          },
          suite: {
            newPrice: 80.33,
            priceBefore: 75.53,
            priceNow: 77.78,
          },
        },
      },
      {
        date: '2025-01-22',
        festivos: [
          'Festividad local de La Solana',
          'Festividad local de Casas de Guijarro',
          'Festividad local de Las Valeras',
          'Festividad local de Olmedilla de Alarcon',
          'Festividad local de Bujarrabal',
          'Festividad local de Cincovillas',
          'Festividad local de Condemios de Arriba',
          'Festividad local de Pastrana',
          'Festividad local de Quer',
          'Festividad local de Sigüenza',
          'Festividad local de Cazalegas',
          'Festividad local de Gálvez',
          'Festividad local de Malpica de Tajo',
          'Festividad local de Montesclaros',
          'Festividad local de Paredes de Escalona',
          'Festividad local de Puerto de San Vicente',
          'Festividad local de Robledo del Mazo',
        ],
        pc_ocupacion: 11.0,
        room: {
          double: {
            newPrice: 58.4,
            priceBefore: 53.85,
            priceNow: 54.86,
          },
          single: {
            newPrice: 51.73,
            priceBefore: 51.5,
            priceNow: 54.11,
          },
          suite: {
            newPrice: 78.72,
            priceBefore: 73.26,
            priceNow: 78.72,
          },
        },
      },
      {
        date: '2025-01-23',
        festivos: [
          'Festividad local de Albatana',
          'Festividad local de Villavaliente',
          'Festividad local de Villaseca',
          'Festividad local de Valverde de los Arroyos',
          'Festividad local de Herreruela de Oropesa',
          'Festividad local de Toledo',
        ],
        pc_ocupacion: 10.0,
        room: {
          double: {
            newPrice: 54.56,
            priceBefore: 51.08,
            priceNow: 50.59,
          },
          single: {
            newPrice: 45.94,
            priceBefore: 46.45,
            priceNow: 44.78,
          },
          suite: {
            newPrice: 70.01,
            priceBefore: 70.78,
            priceNow: 69.58,
          },
        },
      },
      {
        date: '2025-01-24',
        festivos: [
          'Festividad local de Cabezarrubias del Puerto',
          'Festividad local de Corral de Calatrava',
          'Festividad local de Villarta San Juan',
          'Festividad local de Alovera',
          'Festividad local de Aragosa',
          'Festividad local de Baides',
          'Festividad local de Mandayona',
          'Festividad local de Mazuecos',
          'Festividad local de La Puebla de Montalbán',
          'Festividad local de La Pueblanueva',
          'Festividad local de La Rinconada',
          'Festividad local de Portillo de Toledo',
          'Festividad local de Santa Cruz del Retamar',
          'Festividad local de Villarejo de Montalbán',
        ],
        pc_ocupacion: 10.0,
        room: {
          double: {
            newPrice: 39.98,
            priceBefore: 41.28,
            priceNow: 45.17,
          },
          single: {
            newPrice: 53.18,
            priceBefore: 50.84,
            priceNow: 51.87,
          },
          suite: {
            newPrice: 79.98,
            priceBefore: 76.54,
            priceNow: 74.01,
          },
        },
      },
      {
        date: '2025-01-25',
        festivos: [
          'Festividad local de Fuencemillan',
          'Festividad local de Mazuecos',
          'Festividad local de Navalcán',
          'Festividad local de San Pablo de los Montes',
        ],
        pc_ocupacion: 9.0,
        room: {
          double: {
            newPrice: 52.08,
            priceBefore: 53.71,
            priceNow: 54.54,
          },
          single: {
            newPrice: 53.4,
            priceBefore: 50.26,
            priceNow: 49.42,
          },
          suite: {
            newPrice: 52.69,
            priceBefore: 48.35,
            priceNow: 51.51,
          },
        },
      },
      {
        date: '2025-01-26',
        festivos: ['Festividad local de El Herrumblar'],
        pc_ocupacion: 14.0,
        room: {
          double: {
            newPrice: 76.35,
            priceBefore: 79.96,
            priceNow: 76.83,
          },
          single: {
            newPrice: 42.92,
            priceBefore: 44.32,
            priceNow: 45.12,
          },
          suite: {
            newPrice: 77.83,
            priceBefore: 78.32,
            priceNow: 78.34,
          },
        },
      },
      {
        date: '2025-01-27',
        festivos: [],
        pc_ocupacion: 13.0,
        room: {
          double: {
            newPrice: 65.73,
            priceBefore: 60.34,
            priceNow: 65.45,
          },
          single: {
            newPrice: 49.51,
            priceBefore: 49.94,
            priceNow: 53.35,
          },
          suite: {
            newPrice: 69.25,
            priceBefore: 72.85,
            priceNow: 76.68,
          },
        },
      },
      {
        date: '2025-01-28',
        festivos: [],
        pc_ocupacion: 18.0,
        room: {
          double: {
            newPrice: 79.03,
            priceBefore: 75.7,
            priceNow: 77.1,
          },
          single: {
            newPrice: 49.07,
            priceBefore: 49.54,
            priceNow: 50.28,
          },
          suite: {
            newPrice: 103.85,
            priceBefore: 107.51,
            priceNow: 117.36,
          },
        },
      },
      {
        date: '2025-01-29',
        festivos: [
          'Festividad local de Casas de Haro',
          'Festividad local de Tarancon',
          'Festividad local de Aldeanueva de Barbarroya',
        ],
        pc_ocupacion: 10.0,
        room: {
          double: {
            newPrice: 73.36,
            priceBefore: 68.07,
            priceNow: 71.68,
          },
          single: {
            newPrice: 45.03,
            priceBefore: 46.89,
            priceNow: 45.95,
          },
          suite: {
            newPrice: 56.17,
            priceBefore: 52.85,
            priceNow: 50.21,
          },
        },
      },
      {
        date: '2025-01-30',
        festivos: [],
        pc_ocupacion: 11.0,
        room: {
          double: {
            newPrice: 25.64,
            priceBefore: 25.0,
            priceNow: 25.34,
          },
          single: {
            newPrice: 70.44,
            priceBefore: 68.33,
            priceNow: 65.91,
          },
          suite: {
            newPrice: 90.8,
            priceBefore: 87.63,
            priceNow: 87.76,
          },
        },
      },
      {
        date: '2025-01-31',
        festivos: ['Festividad local de Albendiego'],
        pc_ocupacion: 11.0,
        room: {
          double: {
            newPrice: 53.15,
            priceBefore: 54.27,
            priceNow: 57.66,
          },
          single: {
            newPrice: 40.47,
            priceBefore: 38.96,
            priceNow: 41.07,
          },
          suite: {
            newPrice: 81.58,
            priceBefore: 80.82,
            priceNow: 86.46,
          },
        },
      },
      {
        date: '2025-02-01',
        festivos: [
          'Festividad local de Alcaraz',
          'Festividad local de Pozo de Guadalajara',
          'Festividad local de Villanueva de Argecilla',
          'Festividad local de Cardiel de los Montes',
        ],
        pc_ocupacion: 19.0,
        room: {
          double: {
            newPrice: 62.95,
            priceBefore: 65.25,
            priceNow: 63.2,
          },
          single: {
            newPrice: 55.89,
            priceBefore: 52.32,
            priceNow: 54.39,
          },
          suite: {
            newPrice: 106.56,
            priceBefore: 109.19,
            priceNow: 114.55,
          },
        },
      },
      {
        date: '2025-02-02',
        festivos: [
          'Festividad local de Balazote',
          'Festividad local de Villaverde del Guadalimar',
          'Festividad local de Luciana',
          'Festividad local de Albalate de las Nogueras',
          'Festividad local de Casas de los Pinos',
          'Festividad local de Castejon',
          'Festividad local de Cervera del Llano',
          'Festividad local de Fresneda de la Sierra',
          'Festividad local de Jabalera',
          'Festividad local de La Pesquera',
          'Festividad local de Los Estesos',
          'Festividad local de Pajares',
          'Festividad local de Solera de Gabaldón',
          'Festividad local de Sotoca',
          'Festividad local de Torrejoncillo del Rey',
          'Festividad local de Torrubia del Campo',
          'Festividad local de Villaverde y Pasaconsol',
          'Festividad local de Albalate de Zorita',
          'Festividad local de Arbancón',
          'Festividad local de Malaguilla',
          'Festividad local de Torrejón del Rey',
          'Festividad local de Valdeconcha',
          'Festividad local de Villanueva de Argecilla',
          'Festividad local de Villaseca de Henares',
          'Festividad local de Arcicollar',
          'Festividad local de Buenasbodas',
          'Festividad local de Campillo de La Jara',
          'Festividad local de Cazalegas',
          'Festividad local de Cebolla',
          'Festividad local de Gamonal',
          'Festividad local de Las Navillas',
        ],
        pc_ocupacion: 11.0,
        room: {
          double: {
            newPrice: 62.21,
            priceBefore: 63.36,
            priceNow: 67.08,
          },
          single: {
            newPrice: 48.89,
            priceBefore: 44.97,
            priceNow: 46.92,
          },
          suite: {
            newPrice: 71.08,
            priceBefore: 65.09,
            priceNow: 67.46,
          },
        },
      },
      {
        date: '2025-02-03',
        festivos: [
          'Festividad local de Elche De La Sierra',
          'Festividad local de Tarazona de la Mancha',
          'Festividad local de Hinojosas de Calatrava',
          'Festividad local de Villar del Pozo',
          'Festividad local de Cañaveruelas',
          'Festividad local de Enguidanos',
          'Festividad local de La Peraleja',
          'Festividad local de Pajaron',
          'Festividad local de Tribaldos',
          'Festividad local de Villanueva de Guadamejud',
          'Festividad local de Villar del Saz de Arcas',
          'Festividad local de Villarejo de la Peñuela',
          'Festividad local de Villarejo Seco',
          'Festividad local de Angón',
          'Festividad local de Atanzón',
          'Festividad local de Barriopedro',
          'Festividad local de Castilforte',
          'Festividad local de Copernal',
          'Festividad local de Huérmeces del Cerro',
          'Festividad local de Irueste',
          'Festividad local de La Toba',
          'Festividad local de Saúca',
          'Festividad local de Tobillos',
          'Festividad local de Villaseca de Henares',
          'Festividad local de Cervera de los Montes',
          'Festividad local de Recas',
        ],
        pc_ocupacion: 10.0,
        room: {
          double: {
            newPrice: 58.12,
            priceBefore: 53.3,
            priceNow: 56.18,
          },
          single: {
            newPrice: 50.81,
            priceBefore: 49.14,
            priceNow: 52.64,
          },
          suite: {
            newPrice: 59.67,
            priceBefore: 61.66,
            priceNow: 67.45,
          },
        },
      },
      {
        date: '2025-02-04',
        festivos: [],
        pc_ocupacion: 12.0,
        room: {
          double: {
            newPrice: 59.82,
            priceBefore: 58.28,
            priceNow: 61.74,
          },
          single: {
            newPrice: 52.59,
            priceBefore: 49.46,
            priceNow: 51.56,
          },
          suite: {
            newPrice: 84.66,
            priceBefore: 77.99,
            priceNow: 85.51,
          },
        },
      },
      {
        date: '2025-02-05',
        festivos: [
          'Festividad local de Caracuel de Calatrava',
          'Festividad local de Pinarejo',
          'Festividad local de Pozoamargo',
          'Festividad local de Santa Cruz de Moya',
          'Festividad local de Villalgordo del Marquesado',
          'Festividad local de Zarza de Tajo',
          'Festividad local de Castilforte',
          'Festividad local de Centenera',
          'Festividad local de El Casar',
          'Festividad local de Galápagos',
          'Festividad local de Hontoba',
          'Festividad local de Pioz',
          'Festividad local de Retiendas',
          'Festividad local de Villanueva de la Torre',
          'Festividad local de Buenasbodas',
          'Festividad local de Montesclaros',
          'Festividad local de Pepino',
        ],
        pc_ocupacion: 15.0,
        room: {
          double: {
            newPrice: 88.1,
            priceBefore: 85.91,
            priceNow: 83.87,
          },
          single: {
            newPrice: 55.14,
            priceBefore: 54.17,
            priceNow: 57.56,
          },
          suite: {
            newPrice: 63.85,
            priceBefore: 64.42,
            priceNow: 64.64,
          },
        },
      },
      {
        date: '2025-02-06',
        festivos: [],
        pc_ocupacion: 8.0,
        room: {
          double: {
            newPrice: 60.79,
            priceBefore: 58.43,
            priceNow: 59.93,
          },
          single: {
            newPrice: 39.44,
            priceBefore: 38.47,
            priceNow: 36.96,
          },
          suite: {
            newPrice: 57.89,
            priceBefore: 60.91,
            priceNow: 58.7,
          },
        },
      },
      {
        date: '2025-02-07',
        festivos: [],
        pc_ocupacion: 15.0,
        room: {
          double: {
            newPrice: 81.49,
            priceBefore: 84.37,
            priceNow: 87.87,
          },
          single: {
            newPrice: 46.3,
            priceBefore: 42.13,
            priceNow: 44.9,
          },
          suite: {
            newPrice: 70.78,
            priceBefore: 71.8,
            priceNow: 76.99,
          },
        },
      },
      {
        date: '2025-02-08',
        festivos: [
          'Festividad local de Atalaya del Cañavate',
          'Festividad local de Casas de Benitez',
          'Festividad local de Paracuellos de la Vega',
          'Festividad local de Pozorrubio de Santiago',
          'Festividad local de Sisante',
          'Festividad local de Vara de Rey',
          'Festividad local de Alcaudete de la Jara',
          'Festividad local de San Martín de Montalbán',
        ],
        pc_ocupacion: 13.0,
        room: {
          double: {
            newPrice: 33.37,
            priceBefore: 30.71,
            priceNow: 32.57,
          },
          single: {
            newPrice: 50.48,
            priceBefore: 49.57,
            priceNow: 51.49,
          },
          suite: {
            newPrice: 101.9,
            priceBefore: 101.87,
            priceNow: 107.09,
          },
        },
      },
      {
        date: '2025-02-09',
        festivos: [
          'Festividad local de Albaladejo del Cuende',
          'Festividad local de Tortuero',
        ],
        pc_ocupacion: 15.0,
        room: {
          double: {
            newPrice: 38.02,
            priceBefore: 35.97,
            priceNow: 38.68,
          },
          single: {
            newPrice: 36.22,
            priceBefore: 33.05,
            priceNow: 32.67,
          },
          suite: {
            newPrice: 98.64,
            priceBefore: 102.36,
            priceNow: 101.48,
          },
        },
      },
      {
        date: '2025-02-10',
        festivos: [],
        pc_ocupacion: 9.0,
        room: {
          double: {
            newPrice: 63.48,
            priceBefore: 60.91,
            priceNow: 60.97,
          },
          single: {
            newPrice: 52.09,
            priceBefore: 51.61,
            priceNow: 53.34,
          },
          suite: {
            newPrice: 53.98,
            priceBefore: 53.93,
            priceNow: 57.3,
          },
        },
      },
      {
        date: '2025-02-11',
        festivos: [],
        pc_ocupacion: 18.0,
        room: {
          double: {
            newPrice: 42.14,
            priceBefore: 43.48,
            priceNow: 43.65,
          },
          single: {
            newPrice: 76.21,
            priceBefore: 76.39,
            priceNow: 75.31,
          },
          suite: {
            newPrice: 126.26,
            priceBefore: 121.2,
            priceNow: 123.83,
          },
        },
      },
      {
        date: '2025-02-12',
        festivos: [
          'Festividad local de Moral de Calatrava',
          'Festividad local de Pedro Muñoz',
          'Festividad local de Santa Cruz de Mudela',
          'Festividad local de Las Mesas',
          'Festividad local de Los Yébenes',
        ],
        pc_ocupacion: 13.0,
        room: {
          double: {
            newPrice: 49.01,
            priceBefore: 50.26,
            priceNow: 54.05,
          },
          single: {
            newPrice: 48.8,
            priceBefore: 45.22,
            priceNow: 45.27,
          },
          suite: {
            newPrice: 103.02,
            priceBefore: 96.11,
            priceNow: 94.59,
          },
        },
      },
      {
        date: '2025-02-13',
        festivos: [
          'Festividad local de Herencia',
          'Festividad local de Torrenueva',
          'Festividad local de Almorox',
        ],
        pc_ocupacion: 16.0,
        room: {
          double: {
            newPrice: 77.13,
            priceBefore: 71.95,
            priceNow: 77.99,
          },
          single: {
            newPrice: 62.92,
            priceBefore: 57.32,
            priceNow: 60.43,
          },
          suite: {
            newPrice: 91.75,
            priceBefore: 96.4,
            priceNow: 101.04,
          },
        },
      },
      {
        date: '2025-02-14',
        festivos: [
          'Festividad local de La Roda',
          'Festividad local de Miguelturra',
          'Festividad local de Mejorada',
        ],
        pc_ocupacion: 28.0,
        room: {
          double: {
            newPrice: 78.77,
            priceBefore: 82.59,
            priceNow: 83.26,
          },
          single: {
            newPrice: 52.71,
            priceBefore: 52.98,
            priceNow: 55.93,
          },
          suite: {
            newPrice: 159.49,
            priceBefore: 154.33,
            priceNow: 150.52,
          },
        },
      },
      {
        date: '2025-02-15',
        festivos: [],
        pc_ocupacion: 7.0,
        room: {
          double: {
            newPrice: 22.27,
            priceBefore: 21.88,
            priceNow: 23.65,
          },
          single: {
            newPrice: 62.35,
            priceBefore: 59.11,
            priceNow: 62.5,
          },
          suite: {
            newPrice: 65.61,
            priceBefore: 60.25,
            priceNow: 60.88,
          },
        },
      },
      {
        date: '2025-02-16',
        festivos: ['Festividad local de Villarrobledo'],
        pc_ocupacion: 15.0,
        room: {
          double: {
            newPrice: 64.07,
            priceBefore: 66.59,
            priceNow: 65.19,
          },
          single: {
            newPrice: 41.98,
            priceBefore: 43.75,
            priceNow: 43.49,
          },
          suite: {
            newPrice: 95.55,
            priceBefore: 95.71,
            priceNow: 94.14,
          },
        },
      },
      {
        date: '2025-02-17',
        festivos: [],
        pc_ocupacion: 10.0,
        room: {
          double: {
            newPrice: 68.37,
            priceBefore: 66.21,
            priceNow: 72.1,
          },
          single: {
            newPrice: 48.42,
            priceBefore: 49.04,
            priceNow: 48.11,
          },
          suite: {
            newPrice: 61.83,
            priceBefore: 56.55,
            priceNow: 60.51,
          },
        },
      },
      {
        date: '2025-02-18',
        festivos: [],
        pc_ocupacion: 16.0,
        room: {
          double: {
            newPrice: 85.22,
            priceBefore: 84.87,
            priceNow: 88.54,
          },
          single: {
            newPrice: 56.02,
            priceBefore: 53.44,
            priceNow: 52.31,
          },
          suite: {
            newPrice: 105.39,
            priceBefore: 98.96,
            priceNow: 106.88,
          },
        },
      },
      {
        date: '2025-02-19',
        festivos: [],
        pc_ocupacion: 19.0,
        room: {
          double: {
            newPrice: 68.52,
            priceBefore: 65.58,
            priceNow: 68.7,
          },
          single: {
            newPrice: 57.15,
            priceBefore: 52.35,
            priceNow: 52.94,
          },
          suite: {
            newPrice: 109.99,
            priceBefore: 109.88,
            priceNow: 113.95,
          },
        },
      },
      {
        date: '2025-02-20',
        festivos: ['Festividad local de Valderrebollo'],
        pc_ocupacion: 14.0,
        room: {
          double: {
            newPrice: 56.31,
            priceBefore: 58.02,
            priceNow: 56.97,
          },
          single: {
            newPrice: 55.9,
            priceBefore: 50.91,
            priceNow: 55.68,
          },
          suite: {
            newPrice: 89.54,
            priceBefore: 88.4,
            priceNow: 91.16,
          },
        },
      },
      {
        date: '2025-02-21',
        festivos: ['Festividad local de Chillaron de Cuenca'],
        pc_ocupacion: 15.0,
        room: {
          double: {
            newPrice: 66.12,
            priceBefore: 64.46,
            priceNow: 62.31,
          },
          single: {
            newPrice: 46.54,
            priceBefore: 42.44,
            priceNow: 45.68,
          },
          suite: {
            newPrice: 91.36,
            priceBefore: 93.5,
            priceNow: 102.22,
          },
        },
      },
      {
        date: '2025-02-22',
        festivos: ['Festividad local de Carriches'],
        pc_ocupacion: 21.0,
        room: {
          double: {
            newPrice: 81.25,
            priceBefore: 80.33,
            priceNow: 86.69,
          },
          single: {
            newPrice: 55.13,
            priceBefore: 50.62,
            priceNow: 53.59,
          },
          suite: {
            newPrice: 105.95,
            priceBefore: 103.91,
            priceNow: 112.11,
          },
        },
      },
      {
        date: '2025-02-23',
        festivos: [],
        pc_ocupacion: 30.0,
        room: {
          double: {
            newPrice: 97.66,
            priceBefore: 91.04,
            priceNow: 89.82,
          },
          single: {
            newPrice: 93.01,
            priceBefore: 95.92,
            priceNow: 98.3,
          },
          suite: {
            newPrice: 123.22,
            priceBefore: 112.47,
            priceNow: 110.81,
          },
        },
      },
      {
        date: '2025-02-24',
        festivos: ['Festividad local de Herreruela de Oropesa'],
        pc_ocupacion: 19.0,
        room: {
          double: {
            newPrice: 76.13,
            priceBefore: 78.41,
            priceNow: 77.78,
          },
          single: {
            newPrice: 38.31,
            priceBefore: 38.28,
            priceNow: 39.12,
          },
          suite: {
            newPrice: 121.79,
            priceBefore: 111.79,
            priceNow: 112.1,
          },
        },
      },
      {
        date: '2025-02-25',
        festivos: [],
        pc_ocupacion: 14.0,
        room: {
          double: {
            newPrice: 93.42,
            priceBefore: 85.55,
            priceNow: 88.44,
          },
          single: {
            newPrice: 61.26,
            priceBefore: 59.56,
            priceNow: 64.84,
          },
          suite: {
            newPrice: 64.05,
            priceBefore: 63.19,
            priceNow: 65.11,
          },
        },
      },
      {
        date: '2025-02-26',
        festivos: [],
        pc_ocupacion: 14.0,
        room: {
          double: {
            newPrice: 42.13,
            priceBefore: 43.9,
            priceNow: 45.38,
          },
          single: {
            newPrice: 74.7,
            priceBefore: 70.15,
            priceNow: 72.27,
          },
          suite: {
            newPrice: 92.45,
            priceBefore: 96.43,
            priceNow: 102.35,
          },
        },
      },
      {
        date: '2025-02-27',
        festivos: [],
        pc_ocupacion: 25.0,
        room: {
          double: {
            newPrice: 103.76,
            priceBefore: 95.92,
            priceNow: 93.97,
          },
          single: {
            newPrice: 59.88,
            priceBefore: 58.64,
            priceNow: 62.31,
          },
          suite: {
            newPrice: 107.54,
            priceBefore: 107.41,
            priceNow: 109.05,
          },
        },
      },
      {
        date: '2025-02-28',
        festivos: [],
        pc_ocupacion: 15.0,
        room: {
          double: {
            newPrice: 89.08,
            priceBefore: 88.54,
            priceNow: 84.71,
          },
          single: {
            newPrice: 41.94,
            priceBefore: 42.96,
            priceNow: 46.68,
          },
          suite: {
            newPrice: 82.87,
            priceBefore: 83.08,
            priceNow: 90.08,
          },
        },
      },
      {
        date: '2025-03-01',
        festivos: [],
        pc_ocupacion: 13.0,
        room: {
          double: {
            newPrice: 69.34,
            priceBefore: 64.0,
            priceNow: 67.14,
          },
          single: {
            newPrice: 32.98,
            priceBefore: 34.65,
            priceNow: 35.86,
          },
          suite: {
            newPrice: 102.98,
            priceBefore: 98.54,
            priceNow: 94.3,
          },
        },
      },
      {
        date: '2025-03-02',
        festivos: [],
        pc_ocupacion: 17.0,
        room: {
          double: {
            newPrice: 82.09,
            priceBefore: 77.11,
            priceNow: 81.64,
          },
          single: {
            newPrice: 33.04,
            priceBefore: 31.7,
            priceNow: 31.12,
          },
          suite: {
            newPrice: 111.39,
            priceBefore: 116.4,
            priceNow: 114.94,
          },
        },
      },
      {
        date: '2025-03-03',
        festivos: [],
        pc_ocupacion: 27.0,
        room: {
          double: {
            newPrice: 67.78,
            priceBefore: 69.8,
            priceNow: 67.67,
          },
          single: {
            newPrice: 61.0,
            priceBefore: 63.2,
            priceNow: 60.11,
          },
          suite: {
            newPrice: 162.17,
            priceBefore: 157.94,
            priceNow: 151.36,
          },
        },
      },
      {
        date: '2025-03-04',
        festivos: ['Festividad local de El Bonillo'],
        pc_ocupacion: 16.0,
        room: {
          double: {
            newPrice: 76.98,
            priceBefore: 77.2,
            priceNow: 83.89,
          },
          single: {
            newPrice: 41.83,
            priceBefore: 41.6,
            priceNow: 44.5,
          },
          suite: {
            newPrice: 86.1,
            priceBefore: 87.74,
            priceNow: 89.6,
          },
        },
      },
      {
        date: '2025-03-05',
        festivos: [],
        pc_ocupacion: 18.0,
        room: {
          double: {
            newPrice: 84.86,
            priceBefore: 79.28,
            priceNow: 84.84,
          },
          single: {
            newPrice: 57.6,
            priceBefore: 55.29,
            priceNow: 53.84,
          },
          suite: {
            newPrice: 91.53,
            priceBefore: 90.82,
            priceNow: 86.66,
          },
        },
      },
      {
        date: '2025-03-06',
        festivos: [],
        pc_ocupacion: 16.0,
        room: {
          double: {
            newPrice: 66.22,
            priceBefore: 63.14,
            priceNow: 62.74,
          },
          single: {
            newPrice: 36.78,
            priceBefore: 36.01,
            priceNow: 38.53,
          },
          suite: {
            newPrice: 93.19,
            priceBefore: 87.04,
            priceNow: 86.52,
          },
        },
      },
      {
        date: '2025-03-07',
        festivos: ['Festividad local de Villaseca de Uceda'],
        pc_ocupacion: 17.0,
        room: {
          double: {
            newPrice: 68.33,
            priceBefore: 70.21,
            priceNow: 74.12,
          },
          single: {
            newPrice: 88.26,
            priceBefore: 81.45,
            priceNow: 79.0,
          },
          suite: {
            newPrice: 109.41,
            priceBefore: 100.39,
            priceNow: 104.39,
          },
        },
      },
      {
        date: '2025-03-08',
        festivos: ['Festividad local de Navas de Jorquera'],
        pc_ocupacion: 16.0,
        room: {
          double: {
            newPrice: 75.32,
            priceBefore: 70.87,
            priceNow: 74.55,
          },
          single: {
            newPrice: 36.46,
            priceBefore: 37.16,
            priceNow: 38.04,
          },
          suite: {
            newPrice: 96.25,
            priceBefore: 95.42,
            priceNow: 90.71,
          },
        },
      },
      {
        date: '2025-03-09',
        festivos: [],
        pc_ocupacion: 15.0,
        room: {
          double: {
            newPrice: 90.68,
            priceBefore: 86.88,
            priceNow: 84.45,
          },
          single: {
            newPrice: 54.7,
            priceBefore: 54.07,
            priceNow: 52.06,
          },
          suite: {
            newPrice: 60.54,
            priceBefore: 59.53,
            priceNow: 59.14,
          },
        },
      },
      {
        date: '2025-03-10',
        festivos: [],
        pc_ocupacion: 7.0,
        room: {
          double: {
            newPrice: 54.67,
            priceBefore: 50.96,
            priceNow: 48.74,
          },
          single: {
            newPrice: 50.1,
            priceBefore: 46.57,
            priceNow: 50.02,
          },
          suite: {
            newPrice: 57.12,
            priceBefore: 57.18,
            priceNow: 62.69,
          },
        },
      },
      {
        date: '2025-03-11',
        festivos: ['Festividad local de Illescas'],
        pc_ocupacion: 17.0,
        room: {
          double: {
            newPrice: 80.13,
            priceBefore: 78.68,
            priceNow: 81.5,
          },
          single: {
            newPrice: 62.57,
            priceBefore: 59.57,
            priceNow: 62.69,
          },
          suite: {
            newPrice: 87.17,
            priceBefore: 90.96,
            priceNow: 90.22,
          },
        },
      },
      {
        date: '2025-03-12',
        festivos: [],
        pc_ocupacion: 17.0,
        room: {
          double: {
            newPrice: 78.39,
            priceBefore: 74.49,
            priceNow: 81.5,
          },
          single: {
            newPrice: 55.92,
            priceBefore: 55.8,
            priceNow: 54.27,
          },
          suite: {
            newPrice: 100.59,
            priceBefore: 93.72,
            priceNow: 100.95,
          },
        },
      },
      {
        date: '2025-03-13',
        festivos: [],
        pc_ocupacion: 15.0,
        room: {
          double: {
            newPrice: 58.75,
            priceBefore: 58.19,
            priceNow: 62.15,
          },
          single: {
            newPrice: 50.26,
            priceBefore: 46.35,
            priceNow: 45.87,
          },
          suite: {
            newPrice: 97.38,
            priceBefore: 101.6,
            priceNow: 106.13,
          },
        },
      },
      {
        date: '2025-03-14',
        festivos: [],
        pc_ocupacion: 18.0,
        room: {
          double: {
            newPrice: 95.1,
            priceBefore: 86.83,
            priceNow: 84.22,
          },
          single: {
            newPrice: 49.54,
            priceBefore: 51.01,
            priceNow: 48.95,
          },
          suite: {
            newPrice: 113.59,
            priceBefore: 107.27,
            priceNow: 117.67,
          },
        },
      },
      {
        date: '2025-03-15',
        festivos: [],
        pc_ocupacion: 21.0,
        room: {
          double: {
            newPrice: 50.15,
            priceBefore: 52.78,
            priceNow: 50.69,
          },
          single: {
            newPrice: 46.12,
            priceBefore: 48.46,
            priceNow: 46.82,
          },
          suite: {
            newPrice: 146.73,
            priceBefore: 144.15,
            priceNow: 151.76,
          },
        },
      },
      {
        date: '2025-03-16',
        festivos: [],
        pc_ocupacion: 14.0,
        room: {
          double: {
            newPrice: 68.25,
            priceBefore: 67.22,
            priceNow: 72.92,
          },
          single: {
            newPrice: 55.34,
            priceBefore: 50.65,
            priceNow: 49.83,
          },
          suite: {
            newPrice: 59.93,
            priceBefore: 60.18,
            priceNow: 59.16,
          },
        },
      },
      {
        date: '2025-03-17',
        festivos: [],
        pc_ocupacion: 16.0,
        room: {
          double: {
            newPrice: 52.85,
            priceBefore: 49.72,
            priceNow: 48.04,
          },
          single: {
            newPrice: 37.3,
            priceBefore: 37.48,
            priceNow: 38.29,
          },
          suite: {
            newPrice: 128.63,
            priceBefore: 120.57,
            priceNow: 131.05,
          },
        },
      },
      {
        date: '2025-03-18',
        festivos: [
          'Festividad local de Casas de Lazaro',
          'Festividad local de Ontur',
          'Festividad local de Abenójar',
          'Festividad local de Zorita de los Canes',
        ],
        pc_ocupacion: 27.0,
        room: {
          double: {
            newPrice: 70.33,
            priceBefore: 69.75,
            priceNow: 66.32,
          },
          single: {
            newPrice: 92.02,
            priceBefore: 95.11,
            priceNow: 102.5,
          },
          suite: {
            newPrice: 143.62,
            priceBefore: 135.3,
            priceNow: 147.75,
          },
        },
      },
      {
        date: '2025-03-19',
        festivos: [
          'Festividad local de Molinicos',
          'Festividad local de Ontur',
          'Festividad local de Las Mesas',
          'Festividad local de Puebla de Valles',
          'Festividad local de Zorita de los Canes',
          'Festividad local de Gerindote',
          'Festividad local de Sartajada',
          'Festividad local de Villacañas',
        ],
        pc_ocupacion: 18.0,
        room: {
          double: {
            newPrice: 94.98,
            priceBefore: 90.04,
            priceNow: 88.8,
          },
          single: {
            newPrice: 36.89,
            priceBefore: 37.29,
            priceNow: 37.05,
          },
          suite: {
            newPrice: 109.8,
            priceBefore: 101.06,
            priceNow: 99.56,
          },
        },
      },
      {
        date: '2025-03-20',
        festivos: ['Festividad local de Monreal del Llano'],
        pc_ocupacion: 15.0,
        room: {
          double: {
            newPrice: 45.64,
            priceBefore: 44.14,
            priceNow: 47.31,
          },
          single: {
            newPrice: 55.27,
            priceBefore: 53.74,
            priceNow: 53.9,
          },
          suite: {
            newPrice: 103.89,
            priceBefore: 101.51,
            priceNow: 97.83,
          },
        },
      },
      {
        date: '2025-03-21',
        festivos: [],
        pc_ocupacion: 24.0,
        room: {
          double: {
            newPrice: 68.3,
            priceBefore: 63.86,
            priceNow: 66.98,
          },
          single: {
            newPrice: 75.29,
            priceBefore: 77.05,
            priceNow: 83.64,
          },
          suite: {
            newPrice: 124.21,
            priceBefore: 114.33,
            priceNow: 116.33,
          },
        },
      },
      {
        date: '2025-03-22',
        festivos: ['Festividad local de Atienza'],
        pc_ocupacion: 31.0,
        room: {
          double: {
            newPrice: 100.13,
            priceBefore: 104.54,
            priceNow: 104.34,
          },
          single: {
            newPrice: 85.37,
            priceBefore: 79.4,
            priceNow: 78.52,
          },
          suite: {
            newPrice: 161.21,
            priceBefore: 147.85,
            priceNow: 141.38,
          },
        },
      },
      {
        date: '2025-03-23',
        festivos: [],
        pc_ocupacion: 24.0,
        room: {
          double: {
            newPrice: 81.67,
            priceBefore: 74.76,
            priceNow: 77.45,
          },
          single: {
            newPrice: 77.38,
            priceBefore: 79.06,
            priceNow: 83.81,
          },
          suite: {
            newPrice: 151.6,
            priceBefore: 138.71,
            priceNow: 134.1,
          },
        },
      },
      {
        date: '2025-03-24',
        festivos: [],
        pc_ocupacion: 19.0,
        room: {
          double: {
            newPrice: 75.79,
            priceBefore: 74.68,
            priceNow: 78.92,
          },
          single: {
            newPrice: 59.77,
            priceBefore: 57.27,
            priceNow: 56.39,
          },
          suite: {
            newPrice: 128.39,
            priceBefore: 123.19,
            priceNow: 134.76,
          },
        },
      },
      {
        date: '2025-03-25',
        festivos: [],
        pc_ocupacion: 25.0,
        room: {
          double: {
            newPrice: 66.81,
            priceBefore: 63.3,
            priceNow: 64.37,
          },
          single: {
            newPrice: 75.56,
            priceBefore: 77.94,
            priceNow: 81.45,
          },
          suite: {
            newPrice: 166.71,
            priceBefore: 158.66,
            priceNow: 168.29,
          },
        },
      },
      {
        date: '2025-03-26',
        festivos: [],
        pc_ocupacion: 29.0,
        room: {
          double: {
            newPrice: 120.02,
            priceBefore: 111.31,
            priceNow: 119.86,
          },
          single: {
            newPrice: 71.43,
            priceBefore: 69.57,
            priceNow: 74.1,
          },
          suite: {
            newPrice: 144.46,
            priceBefore: 140.88,
            priceNow: 141.53,
          },
        },
      },
      {
        date: '2025-03-27',
        festivos: [],
        pc_ocupacion: 33.0,
        room: {
          double: {
            newPrice: 113.8,
            priceBefore: 106.54,
            priceNow: 110.4,
          },
          single: {
            newPrice: 65.58,
            priceBefore: 63.2,
            priceNow: 66.28,
          },
          suite: {
            newPrice: 173.72,
            priceBefore: 169.38,
            priceNow: 177.49,
          },
        },
      },
      {
        date: '2025-03-28',
        festivos: [],
        pc_ocupacion: 15.0,
        room: {
          double: {
            newPrice: 69.91,
            priceBefore: 68.81,
            priceNow: 73.1,
          },
          single: {
            newPrice: 52.97,
            priceBefore: 50.79,
            priceNow: 54.7,
          },
          suite: {
            newPrice: 97.84,
            priceBefore: 94.13,
            priceNow: 90.71,
          },
        },
      },
      {
        date: '2025-03-29',
        festivos: [],
        pc_ocupacion: 22.0,
        room: {
          double: {
            newPrice: 92.35,
            priceBefore: 85.44,
            priceNow: 90.54,
          },
          single: {
            newPrice: 49.72,
            priceBefore: 47.35,
            priceNow: 49.3,
          },
          suite: {
            newPrice: 149.22,
            priceBefore: 139.68,
            priceNow: 146.53,
          },
        },
      },
      {
        date: '2025-03-30',
        festivos: [],
        pc_ocupacion: 27.0,
        room: {
          double: {
            newPrice: 85.68,
            priceBefore: 89.22,
            priceNow: 93.82,
          },
          single: {
            newPrice: 48.47,
            priceBefore: 48.54,
            priceNow: 49.09,
          },
          suite: {
            newPrice: 164.3,
            priceBefore: 160.65,
            priceNow: 158.68,
          },
        },
      },
      {
        date: '2025-03-31',
        festivos: [],
        pc_ocupacion: 20.0,
        room: {
          double: {
            newPrice: 112.73,
            priceBefore: 106.3,
            priceNow: 108.4,
          },
          single: {
            newPrice: 63.14,
            priceBefore: 61.25,
            priceNow: 63.19,
          },
          suite: {
            newPrice: 116.9,
            priceBefore: 107.48,
            priceNow: 103.86,
          },
        },
      },
      {
        date: '2025-04-01',
        festivos: [
          'Festividad local de Bonete',
          'Festividad local de Almadenejos',
          'Festividad local de Campo de Criptana',
          'Festividad local de Carrión de Calatrava',
          'Festividad local de Carrizosa',
          'Festividad local de Chillón',
          'Festividad local de Socuéllamos',
          'Festividad local de Villarrubia de los Ojos',
          'Festividad local de Los Hinojosos',
          'Festividad local de Mira',
          'Festividad local de Villora',
          'Festividad local de Argés',
          'Festividad local de Cobisa',
          'Festividad local de Las Ventas con Peña Aguilera',
          'Festividad local de Navamorcuende',
          'Festividad local de Puente del Arzobispo',
          'Festividad local de Tembleque',
          'Festividad local de Toledo',
        ],
        pc_ocupacion: 19.0,
        room: {
          double: {
            newPrice: 89.42,
            priceBefore: 93.26,
            priceNow: 92.36,
          },
          single: {
            newPrice: 52.1,
            priceBefore: 54.66,
            priceNow: 56.77,
          },
          suite: {
            newPrice: 99.21,
            priceBefore: 102.33,
            priceNow: 111.44,
          },
        },
      },
      {
        date: '2025-04-02',
        festivos: ['Festividad local de Ajofrín'],
        pc_ocupacion: 21.0,
        room: {
          double: {
            newPrice: 64.29,
            priceBefore: 60.31,
            priceNow: 60.69,
          },
          single: {
            newPrice: 57.25,
            priceBefore: 56.87,
            priceNow: 60.03,
          },
          suite: {
            newPrice: 143.68,
            priceBefore: 141.33,
            priceNow: 145.62,
          },
        },
      },
      {
        date: '2025-04-03',
        festivos: ['Festividad local de La Nava de Ricomalillo'],
        pc_ocupacion: 14.0,
        room: {
          double: {
            newPrice: 24.29,
            priceBefore: 24.55,
            priceNow: 24.89,
          },
          single: {
            newPrice: 55.96,
            priceBefore: 58.48,
            priceNow: 58.87,
          },
          suite: {
            newPrice: 103.26,
            priceBefore: 99.88,
            priceNow: 99.03,
          },
        },
      },
      {
        date: '2025-04-04',
        festivos: ['Festividad local de La Nava de Ricomalillo'],
        pc_ocupacion: 21.0,
        room: {
          double: {
            newPrice: 70.84,
            priceBefore: 70.66,
            priceNow: 70.51,
          },
          single: {
            newPrice: 73.73,
            priceBefore: 74.73,
            priceNow: 74.06,
          },
          suite: {
            newPrice: 117.35,
            priceBefore: 108.08,
            priceNow: 112.12,
          },
        },
      },
      {
        date: '2025-04-05',
        festivos: [],
        pc_ocupacion: 19.0,
        room: {
          double: {
            newPrice: 71.06,
            priceBefore: 74.15,
            priceNow: 73.41,
          },
          single: {
            newPrice: 52.44,
            priceBefore: 53.54,
            priceNow: 54.86,
          },
          suite: {
            newPrice: 118.75,
            priceBefore: 111.69,
            priceNow: 107.41,
          },
        },
      },
      {
        date: '2025-04-06',
        festivos: [],
        pc_ocupacion: 25.0,
        room: {
          double: {
            newPrice: 86.4,
            priceBefore: 79.71,
            priceNow: 77.0,
          },
          single: {
            newPrice: 62.8,
            priceBefore: 60.33,
            priceNow: 57.55,
          },
          suite: {
            newPrice: 128.53,
            priceBefore: 129.54,
            priceNow: 141.63,
          },
        },
      },
      {
        date: '2025-04-07',
        festivos: [],
        pc_ocupacion: 23.0,
        room: {
          double: {
            newPrice: 94.66,
            priceBefore: 95.65,
            priceNow: 95.85,
          },
          single: {
            newPrice: 45.75,
            priceBefore: 42.78,
            priceNow: 43.93,
          },
          suite: {
            newPrice: 114.24,
            priceBefore: 109.93,
            priceNow: 112.79,
          },
        },
      },
      {
        date: '2025-04-08',
        festivos: [
          'Festividad local de Caudete',
          'Festividad local de El Hito',
          'Festividad local de Carpio de Tajo',
        ],
        pc_ocupacion: 21.0,
        room: {
          double: {
            newPrice: 70.14,
            priceBefore: 65.25,
            priceNow: 67.51,
          },
          single: {
            newPrice: 87.14,
            priceBefore: 79.47,
            priceNow: 76.35,
          },
          suite: {
            newPrice: 138.28,
            priceBefore: 128.73,
            priceNow: 126.88,
          },
        },
      },
      {
        date: '2025-04-09',
        festivos: [],
        pc_ocupacion: 22.0,
        room: {
          double: {
            newPrice: 93.33,
            priceBefore: 92.31,
            priceNow: 99.7,
          },
          single: {
            newPrice: 54.71,
            priceBefore: 53.72,
            priceNow: 55.2,
          },
          suite: {
            newPrice: 138.75,
            priceBefore: 132.8,
            priceNow: 127.96,
          },
        },
      },
      {
        date: '2025-04-10',
        festivos: [
          'Festividad local de San Lorenzo de Calatrava',
          'Festividad local de Hinojosa de San Vicente',
        ],
        pc_ocupacion: 18.0,
        room: {
          double: {
            newPrice: 68.38,
            priceBefore: 62.21,
            priceNow: 63.5,
          },
          single: {
            newPrice: 49.79,
            priceBefore: 46.39,
            priceNow: 49.3,
          },
          suite: {
            newPrice: 121.52,
            priceBefore: 111.8,
            priceNow: 116.31,
          },
        },
      },
      {
        date: '2025-04-11',
        festivos: [],
        pc_ocupacion: 19.0,
        room: {
          double: {
            newPrice: 56.14,
            priceBefore: 58.48,
            priceNow: 55.58,
          },
          single: {
            newPrice: 63.85,
            priceBefore: 60.36,
            priceNow: 57.87,
          },
          suite: {
            newPrice: 109.13,
            priceBefore: 106.22,
            priceNow: 116.77,
          },
        },
      },
      {
        date: '2025-04-12',
        festivos: [],
        pc_ocupacion: 19.0,
        room: {
          double: {
            newPrice: 91.57,
            priceBefore: 91.22,
            priceNow: 95.17,
          },
          single: {
            newPrice: 45.47,
            priceBefore: 41.86,
            priceNow: 44.31,
          },
          suite: {
            newPrice: 126.36,
            priceBefore: 117.77,
            priceNow: 119.48,
          },
        },
      },
      {
        date: '2025-04-13',
        festivos: [],
        pc_ocupacion: 29.0,
        room: {
          double: {
            newPrice: 127.34,
            priceBefore: 125.79,
            priceNow: 131.43,
          },
          single: {
            newPrice: 70.01,
            priceBefore: 71.23,
            priceNow: 69.9,
          },
          suite: {
            newPrice: 130.89,
            priceBefore: 130.2,
            priceNow: 136.99,
          },
        },
      },
      {
        date: '2025-04-14',
        festivos: [],
        pc_ocupacion: 26.0,
        room: {
          double: {
            newPrice: 58.69,
            priceBefore: 58.17,
            priceNow: 56.28,
          },
          single: {
            newPrice: 82.17,
            priceBefore: 80.04,
            priceNow: 80.55,
          },
          suite: {
            newPrice: 140.97,
            priceBefore: 135.05,
            priceNow: 132.74,
          },
        },
      },
      {
        date: '2025-04-15',
        festivos: [],
        pc_ocupacion: 19.0,
        room: {
          double: {
            newPrice: 71.29,
            priceBefore: 68.59,
            priceNow: 68.43,
          },
          single: {
            newPrice: 85.08,
            priceBefore: 78.16,
            priceNow: 80.93,
          },
          suite: {
            newPrice: 132.8,
            priceBefore: 122.98,
            priceNow: 126.18,
          },
        },
      },
      {
        date: '2025-04-16',
        festivos: [],
        pc_ocupacion: 18.0,
        room: {
          double: {
            newPrice: 58.57,
            priceBefore: 61.45,
            priceNow: 61.84,
          },
          single: {
            newPrice: 57.95,
            priceBefore: 54.28,
            priceNow: 55.81,
          },
          suite: {
            newPrice: 130.24,
            priceBefore: 120.96,
            priceNow: 125.15,
          },
        },
      },
      {
        date: '2025-04-17',
        festivos: ['Jueves Santo', 'Festividad local de Nohales'],
        pc_ocupacion: 21.0,
        room: {
          double: {
            newPrice: 91.39,
            priceBefore: 95.97,
            priceNow: 94.33,
          },
          single: {
            newPrice: 43.32,
            priceBefore: 43.7,
            priceNow: 43.61,
          },
          suite: {
            newPrice: 142.32,
            priceBefore: 145.14,
            priceNow: 141.66,
          },
        },
      },
      {
        date: '2025-04-18',
        festivos: ['Viernes Santo'],
        pc_ocupacion: 20.0,
        room: {
          double: {
            newPrice: 79.32,
            priceBefore: 76.16,
            priceNow: 73.51,
          },
          single: {
            newPrice: 64.8,
            priceBefore: 59.57,
            priceNow: 58.58,
          },
          suite: {
            newPrice: 122.86,
            priceBefore: 117.36,
            priceNow: 126.96,
          },
        },
      },
      {
        date: '2025-04-19',
        festivos: [],
        pc_ocupacion: 21.0,
        room: {
          double: {
            newPrice: 78.72,
            priceBefore: 81.24,
            priceNow: 81.96,
          },
          single: {
            newPrice: 86.66,
            priceBefore: 82.48,
            priceNow: 89.45,
          },
          suite: {
            newPrice: 106.7,
            priceBefore: 111.26,
            priceNow: 110.86,
          },
        },
      },
      {
        date: '2025-04-20',
        festivos: [],
        pc_ocupacion: 13.0,
        room: {
          double: {
            newPrice: 68.02,
            priceBefore: 68.18,
            priceNow: 65.21,
          },
          single: {
            newPrice: 54.68,
            priceBefore: 54.67,
            priceNow: 56.53,
          },
          suite: {
            newPrice: 60.58,
            priceBefore: 58.77,
            priceNow: 58.72,
          },
        },
      },
      {
        date: '2025-04-21',
        festivos: ['Festividad local de Castilnuevo'],
        pc_ocupacion: 23.0,
        room: {
          double: {
            newPrice: 96.41,
            priceBefore: 91.98,
            priceNow: 93.07,
          },
          single: {
            newPrice: 63.01,
            priceBefore: 65.05,
            priceNow: 68.8,
          },
          suite: {
            newPrice: 113.41,
            priceBefore: 112.68,
            priceNow: 119.67,
          },
        },
      },
      {
        date: '2025-04-22',
        festivos: ['Festividad local de Escariche'],
        pc_ocupacion: 27.0,
        room: {
          double: {
            newPrice: 104.97,
            priceBefore: 98.42,
            priceNow: 96.47,
          },
          single: {
            newPrice: 50.38,
            priceBefore: 46.92,
            priceNow: 50.26,
          },
          suite: {
            newPrice: 137.14,
            priceBefore: 129.0,
            priceNow: 138.34,
          },
        },
      },
      {
        date: '2025-04-23',
        festivos: [
          'Festividad local de Golosalvo',
          'Festividad local de Madrigueras',
          'Festividad local de Graja de Iniesta',
          'Festividad local de Pozoseco',
          'Festividad local de Rubielos Altos',
          'Festividad local de Rubielos Bajos',
          'Festividad local de Miralrío',
          'Festividad local de Erustes',
          'Festividad local de Villanueva de Alcardete',
        ],
        pc_ocupacion: 19.0,
        room: {
          double: {
            newPrice: 76.08,
            priceBefore: 73.67,
            priceNow: 75.79,
          },
          single: {
            newPrice: 66.66,
            priceBefore: 69.58,
            priceNow: 75.97,
          },
          suite: {
            newPrice: 104.85,
            priceBefore: 104.04,
            priceNow: 107.65,
          },
        },
      },
      {
        date: '2025-04-24',
        festivos: ['Festividad local de Segurilla'],
        pc_ocupacion: 25.0,
        room: {
          double: {
            newPrice: 84.35,
            priceBefore: 86.37,
            priceNow: 83.71,
          },
          single: {
            newPrice: 106.77,
            priceBefore: 99.74,
            priceNow: 106.41,
          },
          suite: {
            newPrice: 115.6,
            priceBefore: 109.8,
            priceNow: 115.87,
          },
        },
      },
      {
        date: '2025-04-25',
        festivos: [
          'Festividad local de Bienservida',
          'Festividad local de El Salobral',
          'Festividad local de Fuenteálamo',
          'Festividad local de Montalvos',
          'Festividad local de Reolid',
          'Festividad local de Salobre',
          'Festividad local de Viveros',
          'Festividad local de Albaladejo',
          'Festividad local de Arroba de los Montes',
          'Festividad local de Castellar de Santiago',
          'Festividad local de Ruidera',
          'Festividad local de San Carlos del Valle',
          'Festividad local de Santa Cruz de Cáñamos',
          'Festividad local de Villamanrique',
          'Festividad local de Villanueva de la Fuente',
          'Festividad local de Algarra',
          'Festividad local de Carrascosa del Campo',
          'Festividad local de Castillo-Albarañez',
          'Festividad local de Osa de la Vega',
          'Festividad local de Quintanar del Rey',
          'Festividad local de Villalpardo',
          'Festividad local de Villaverde y Pasaconsol',
          'Festividad local de Yemeda',
          'Festividad local de Chiloeches',
          'Festividad local de Tordesilos',
          'Festividad local de Villares de Jadraque',
          'Festividad local de Méntrida',
        ],
        pc_ocupacion: 26.0,
        room: {
          double: {
            newPrice: 91.01,
            priceBefore: 87.09,
            priceNow: 93.61,
          },
          single: {
            newPrice: 62.37,
            priceBefore: 63.43,
            priceNow: 60.71,
          },
          suite: {
            newPrice: 161.03,
            priceBefore: 168.55,
            priceNow: 172.38,
          },
        },
      },
      {
        date: '2025-04-26',
        festivos: [
          'Festividad local de Montealegre del Castillo',
          'Festividad local de Povedilla',
          'Festividad local de Aldea del Rey',
          'Festividad local de Maqueda',
        ],
        pc_ocupacion: 21.0,
        room: {
          double: {
            newPrice: 69.09,
            priceBefore: 69.89,
            priceNow: 71.6,
          },
          single: {
            newPrice: 47.23,
            priceBefore: 47.14,
            priceNow: 47.62,
          },
          suite: {
            newPrice: 121.28,
            priceBefore: 123.77,
            priceNow: 132.49,
          },
        },
      },
      {
        date: '2025-04-27',
        festivos: ['Festividad local de Adobes', 'Festividad local de Azután'],
        pc_ocupacion: 23.0,
        room: {
          double: {
            newPrice: 75.48,
            priceBefore: 78.62,
            priceNow: 84.71,
          },
          single: {
            newPrice: 65.53,
            priceBefore: 64.39,
            priceNow: 68.34,
          },
          suite: {
            newPrice: 126.02,
            priceBefore: 116.46,
            priceNow: 127.47,
          },
        },
      },
      {
        date: '2025-04-28',
        festivos: [],
        pc_ocupacion: 22.0,
        room: {
          double: {
            newPrice: 68.8,
            priceBefore: 71.49,
            priceNow: 69.54,
          },
          single: {
            newPrice: 75.74,
            priceBefore: 70.31,
            priceNow: 74.39,
          },
          suite: {
            newPrice: 124.04,
            priceBefore: 116.14,
            priceNow: 112.86,
          },
        },
      },
      {
        date: '2025-04-29',
        festivos: [
          'Festividad local de Casas Ibáñez',
          'Festividad local de Hoya Gonzalo',
          'Festividad local de Ossa de Montiel',
          'Festividad local de Bolaños de Calatrava',
          'Festividad local de Tomelloso',
          'Festividad local de Casas de Santa Cruz',
          'Festividad local de Graja de Campalbo',
          'Festividad local de Pozoamargo',
          'Festividad local de Tórtola de Henares',
          'Festividad local de Maqueda',
          'Festividad local de Mora',
          'Festividad local de Pelahustán',
          'Festividad local de Villamuelas',
        ],
        pc_ocupacion: 29.0,
        room: {
          double: {
            newPrice: 104.05,
            priceBefore: 96.65,
            priceNow: 104.34,
          },
          single: {
            newPrice: 89.05,
            priceBefore: 83.33,
            priceNow: 80.58,
          },
          suite: {
            newPrice: 141.67,
            priceBefore: 131.97,
            priceNow: 137.67,
          },
        },
      },
      {
        date: '2025-04-30',
        festivos: [
          'Festividad local de Fontanarejo',
          'Festividad local de Palomares del Campo',
          'Festividad local de Henche',
        ],
        pc_ocupacion: 25.0,
        room: {
          double: {
            newPrice: 90.5,
            priceBefore: 94.13,
            priceNow: 96.19,
          },
          single: {
            newPrice: 86.27,
            priceBefore: 81.43,
            priceNow: 85.88,
          },
          suite: {
            newPrice: 139.62,
            priceBefore: 133.41,
            priceNow: 130.85,
          },
        },
      },
      {
        date: '2025-05-01',
        festivos: ['Fiesta del Trabajo'],
        pc_ocupacion: 25.0,
        room: {
          double: {
            newPrice: 88.75,
            priceBefore: 81.24,
            priceNow: 82.43,
          },
          single: {
            newPrice: 87.53,
            priceBefore: 80.53,
            priceNow: 86.67,
          },
          suite: {
            newPrice: 110.96,
            priceBefore: 108.31,
            priceNow: 105.86,
          },
        },
      },
      {
        date: '2025-05-02',
        festivos: [
          'Festividad local de La Gineta',
          'Festividad local de Lezuza',
          'Festividad local de Tinajeros',
          'Festividad local de Villamayor de Calatrava',
          'Festividad local de Alcázar del Rey',
          'Festividad local de Escopete',
          'Festividad local de Aldea en Cabo',
          'Festividad local de Belvís de la Jara',
          'Festividad local de La Iglesuela del Tietar',
          'Festividad local de Lominchar',
          'Festividad local de Piedraescrita',
        ],
        pc_ocupacion: 17.0,
        room: {
          double: {
            newPrice: 71.04,
            priceBefore: 66.47,
            priceNow: 64.09,
          },
          single: {
            newPrice: 53.46,
            priceBefore: 50.01,
            priceNow: 54.03,
          },
          suite: {
            newPrice: 113.6,
            priceBefore: 115.51,
            priceNow: 116.23,
          },
        },
      },
      {
        date: '2025-05-03',
        festivos: [
          'Festividad local de Alpera',
          'Festividad local de Balazote',
          'Festividad local de Balsa de Vés',
          'Festividad local de Cotillas',
          'Festividad local de Hellín',
          'Festividad local de La Gineta',
          'Festividad local de Lezuza',
          'Festividad local de Pozo Lorente',
          'Festividad local de Riópar',
          'Festividad local de Socovos',
          'Festividad local de Tinajeros',
          'Festividad local de Yeste',
          'Festividad local de Arroba de los Montes',
          'Festividad local de Carrizosa',
          'Festividad local de Granátula de Calatrava',
          'Festividad local de Picón',
          'Festividad local de Piedrabuena',
          'Festividad local de Porzuna',
          'Festividad local de Saceruela',
          'Festividad local de Villanueva de los Infantes',
          'Festividad local de Graja de Iniesta',
          'Festividad local de Huerta de la Obispalía',
          'Festividad local de La Cierva',
          'Festividad local de Las Majadas',
          'Festividad local de San Lorenzo de la Parrilla',
          'Festividad local de Valdetortola',
          'Festividad local de Vellisca',
          'Festividad local de Villalgordo del Marquesado',
          'Festividad local de Villar de Olalla',
          'Festividad local de Villares del Saz',
          'Festividad local de Almoguera',
          'Festividad local de Cabanillas del Campo',
          'Festividad local de Fuencemillan',
          'Festividad local de Fuentelviejo',
          'Festividad local de La Yunta',
          'Festividad local de Marchamalo',
          'Festividad local de Milmarcos',
          'Festividad local de Mohernando',
          'Festividad local de Paredes de Sigüenza',
          'Festividad local de Almorox',
          'Festividad local de Buenaventura',
          'Festividad local de Chozas de Canales',
          'Festividad local de Ciruelos',
          'Festividad local de La Iglesuela del Tietar',
          'Festividad local de Lillo',
          'Festividad local de Lominchar',
          'Festividad local de Navalmoralejo',
          'Festividad local de Noblejas',
          'Festividad local de Noez',
          'Festividad local de Piedraescrita',
          'Festividad local de San Martín de Pusa',
          'Festividad local de Santa Cruz del Retamar',
          'Festividad local de Torre de Esteban Hambrán',
          'Festividad local de Valmojado',
        ],
        pc_ocupacion: 29.0,
        room: {
          double: {
            newPrice: 87.74,
            priceBefore: 82.22,
            priceNow: 82.13,
          },
          single: {
            newPrice: 68.87,
            priceBefore: 71.56,
            priceNow: 72.61,
          },
          suite: {
            newPrice: 131.09,
            priceBefore: 136.49,
            priceNow: 150.1,
          },
        },
      },
      {
        date: '2025-05-04',
        festivos: ['Festividad local de Prados Redondos'],
        pc_ocupacion: 14.0,
        room: {
          double: {
            newPrice: 79.38,
            priceBefore: 74.93,
            priceNow: 77.8,
          },
          single: {
            newPrice: 56.13,
            priceBefore: 58.97,
            priceNow: 57.97,
          },
          suite: {
            newPrice: 73.8,
            priceBefore: 76.54,
            priceNow: 82.71,
          },
        },
      },
      {
        date: '2025-05-05',
        festivos: [],
        pc_ocupacion: 20.0,
        room: {
          double: {
            newPrice: 64.81,
            priceBefore: 67.2,
            priceNow: 70.59,
          },
          single: {
            newPrice: 74.84,
            priceBefore: 68.11,
            priceNow: 70.8,
          },
          suite: {
            newPrice: 130.93,
            priceBefore: 122.52,
            priceNow: 119.76,
          },
        },
      },
      {
        date: '2025-05-06',
        festivos: [
          'Festividad local de Almansa',
          'Festividad local de Casas de Guijarro',
          'Festividad local de Mazarulleque',
          'Festividad local de Rada de Haro',
          'Festividad local de Solera de Gabaldón',
          'Festividad local de Tinajas',
          'Festividad local de Ucles',
          'Festividad local de Villarejo Periesteban',
          'Festividad local de Lupiana',
          'Festividad local de Caleruela',
          'Festividad local de Ciruelos',
          'Festividad local de Mazarambroz',
          'Festividad local de Santa Olalla',
          'Festividad local de Sonseca',
        ],
        pc_ocupacion: 21.0,
        room: {
          double: {
            newPrice: 65.71,
            priceBefore: 64.34,
            priceNow: 63.91,
          },
          single: {
            newPrice: 85.1,
            priceBefore: 82.65,
            priceNow: 79.71,
          },
          suite: {
            newPrice: 141.79,
            priceBefore: 131.03,
            priceNow: 132.94,
          },
        },
      },
      {
        date: '2025-05-07',
        festivos: [
          'Festividad local de Villel de Mesa',
          'Festividad local de Ajofrín',
          'Festividad local de Caleruela',
        ],
        pc_ocupacion: 25.0,
        room: {
          double: {
            newPrice: 91.76,
            priceBefore: 86.57,
            priceNow: 91.03,
          },
          single: {
            newPrice: 79.32,
            priceBefore: 76.93,
            priceNow: 82.69,
          },
          suite: {
            newPrice: 116.52,
            priceBefore: 110.37,
            priceNow: 110.9,
          },
        },
      },
      {
        date: '2025-05-08',
        festivos: [
          'Festividad local de Abengibre',
          'Festividad local de Villa de Vés',
          'Festividad local de Arenales de San Gregorio',
          'Festividad local de Alconchel de la Estrella',
          'Festividad local de Huelves',
          'Festividad local de Olmeda del Rey',
          'Festividad local de Palomera',
          'Festividad local de Villagarcía del Llano',
          'Festividad local de Argecilla',
          'Festividad local de Matarrubia',
          'Festividad local de Taragudo',
        ],
        pc_ocupacion: 30.0,
        room: {
          double: {
            newPrice: 108.76,
            priceBefore: 107.0,
            priceNow: 110.03,
          },
          single: {
            newPrice: 84.94,
            priceBefore: 80.24,
            priceNow: 76.65,
          },
          suite: {
            newPrice: 120.88,
            priceBefore: 124.42,
            priceNow: 122.0,
          },
        },
      },
      {
        date: '2025-05-09',
        festivos: [
          'Festividad local de Almedina',
          'Festividad local de Arenales de San Gregorio',
          'Festividad local de Caracuel de Calatrava',
          'Festividad local de Alconchel de la Estrella',
          'Festividad local de Cubillejo del Sitio',
          'Festividad local de Valdegrudas',
        ],
        pc_ocupacion: 21.0,
        room: {
          double: {
            newPrice: 105.1,
            priceBefore: 100.05,
            priceNow: 107.04,
          },
          single: {
            newPrice: 53.15,
            priceBefore: 51.21,
            priceNow: 51.33,
          },
          suite: {
            newPrice: 118.14,
            priceBefore: 120.62,
            priceNow: 123.95,
          },
        },
      },
      {
        date: '2025-05-10',
        festivos: [
          'Festividad local de Almodóvar del Campo',
          'Festividad local de Montiel',
          'Festividad local de Alcantud',
          'Festividad local de Huete',
          'Festividad local de Alustante',
          'Festividad local de Mirabueno',
          'Festividad local de Riba de Saelices',
        ],
        pc_ocupacion: 19.0,
        room: {
          double: {
            newPrice: 87.58,
            priceBefore: 88.68,
            priceNow: 84.97,
          },
          single: {
            newPrice: 72.71,
            priceBefore: 70.02,
            priceNow: 70.04,
          },
          suite: {
            newPrice: 109.69,
            priceBefore: 111.37,
            priceNow: 118.39,
          },
        },
      },
      {
        date: '2025-05-11',
        festivos: [
          'Festividad local de Piqueras del Castillo',
          'Festividad local de Tordellego',
        ],
        pc_ocupacion: 25.0,
        room: {
          double: {
            newPrice: 61.6,
            priceBefore: 60.76,
            priceNow: 58.1,
          },
          single: {
            newPrice: 89.15,
            priceBefore: 82.72,
            priceNow: 83.12,
          },
          suite: {
            newPrice: 163.05,
            priceBefore: 148.65,
            priceNow: 154.15,
          },
        },
      },
      {
        date: '2025-05-12',
        festivos: [],
        pc_ocupacion: 27.0,
        room: {
          double: {
            newPrice: 124.22,
            priceBefore: 125.99,
            priceNow: 129.7,
          },
          single: {
            newPrice: 73.02,
            priceBefore: 72.11,
            priceNow: 75.72,
          },
          suite: {
            newPrice: 140.29,
            priceBefore: 128.46,
            priceNow: 139.99,
          },
        },
      },
      {
        date: '2025-05-13',
        festivos: [
          'Festividad local de Fuensanta',
          'Festividad local de Masegoso',
          'Festividad local de Peñascosa',
          'Festividad local de Solana del Pino',
          'Festividad local de Torrenueva',
          'Festividad local de Villanueva de la Fuente',
          'Festividad local de Villanueva de San Carlos',
          'Festividad local de Alcantud',
          'Festividad local de Buendía',
          'Festividad local de Carboneras de Guadazaon',
          'Festividad local de San Pedro Palmiches',
          'Festividad local de Fontanar',
          'Festividad local de Labros',
          'Festividad local de Mirabueno',
          'Festividad local de Alcolea de Tajo',
          'Festividad local de Casar de Escalona',
          'Festividad local de Mesegar de Tajo',
        ],
        pc_ocupacion: 28.0,
        room: {
          double: {
            newPrice: 85.59,
            priceBefore: 86.94,
            priceNow: 94.09,
          },
          single: {
            newPrice: 76.49,
            priceBefore: 80.04,
            priceNow: 85.89,
          },
          suite: {
            newPrice: 143.96,
            priceBefore: 132.78,
            priceNow: 143.92,
          },
        },
      },
      {
        date: '2025-05-14',
        festivos: [
          'Festividad local de Pozuelo',
          'Festividad local de Fuentelencina',
          'Festividad local de Salmerón',
          'Festividad local de Valdepeñas de la Sierra',
          'Festividad local de Valdeazores',
        ],
        pc_ocupacion: 28.0,
        room: {
          double: {
            newPrice: 104.99,
            priceBefore: 98.48,
            priceNow: 97.42,
          },
          single: {
            newPrice: 88.73,
            priceBefore: 87.87,
            priceNow: 94.38,
          },
          suite: {
            newPrice: 114.2,
            priceBefore: 116.43,
            priceNow: 124.05,
          },
        },
      },
      {
        date: '2025-05-15',
        festivos: [
          'Festividad local de Aguas Nuevas',
          'Festividad local de Alatoz',
          'Festividad local de Albatana',
          'Festividad local de Alborea',
          'Festividad local de Alcadozo',
          'Festividad local de Alcalá del Júcar',
          'Festividad local de Carcelén',
          'Festividad local de Casas de Juan Núñez',
          'Festividad local de Corralrubio',
          'Festividad local de Fuentealbilla',
          'Festividad local de Golosalvo',
          'Festividad local de La Herrera',
          'Festividad local de La Recueja',
          'Festividad local de Mahora',
          'Festividad local de Montalvos',
          'Festividad local de Montealegre del Castillo',
          'Festividad local de Motilleja',
          'Festividad local de Munera',
          'Festividad local de Navas de Jorquera',
          'Festividad local de Pozo Cañada',
          'Festividad local de Vianos',
          'Festividad local de Villamalea',
          'Festividad local de Villapalacios',
          'Festividad local de Villatoya',
          'Festividad local de Villavaliente',
          'Festividad local de Viveros',
          'Festividad local de Agudo',
          'Festividad local de Albaladejo',
          'Festividad local de Alcázar de San Juan',
          'Festividad local de Alcolea de Calatrava',
          'Festividad local de Alcubillas',
          'Festividad local de Alhambra',
          'Festividad local de Almuradiel',
          'Festividad local de Argamasilla de Alba',
          'Festividad local de Argamasilla de Calatrava',
          'Festividad local de Brazatortas',
          'Festividad local de Cabezarados',
          'Festividad local de Corral de Calatrava',
          'Festividad local de Cózar',
          'Festividad local de Fuencaliente',
          'Festividad local de Horcajo de los Montes',
          'Festividad local de Las Labores',
          'Festividad local de Llanos del Caudillo',
          'Festividad local de Navalpino',
          'Festividad local de Picón',
          'Festividad local de Piedrabuena',
          'Festividad local de Poblete',
          'Festividad local de Porzuna',
          'Festividad local de Puerto Lápice',
          'Festividad local de Retuerta del Bullaque',
          'Festividad local de San Carlos del Valle',
          'Festividad local de Terrinches',
          'Festividad local de Torralba de Calatrava',
          'Festividad local de Torre de Juan Abad',
          'Festividad local de Valenzuela de Calatrava',
          'Festividad local de Villamanrique',
          'Festividad local de Villamayor de Calatrava',
          'Festividad local de Villarrubia de los Ojos',
          'Festividad local de Albaladejo del Cuende',
          'Festividad local de Albalate de las Nogueras',
          'Festividad local de Alcázar del Rey',
          'Festividad local de Almonacid del Marquesado',
          'Festividad local de Altarejos',
          'Festividad local de Arandilla del Arroyo',
          'Festividad local de Arcas',
          'Festividad local de Belmontejo',
          'Festividad local de Buenache de Alarcon',
          'Festividad local de Campillo de Altobuey',
          'Festividad local de Canalejas del Arroyo',
          'Festividad local de Cañada Juncosa',
          'Festividad local de Cañaveras',
          'Festividad local de Cañaveruelas',
          'Festividad local de Carrascosa de Haro',
          'Festividad local de Casas de Benitez',
          'Festividad local de Casas de Fernando Alonso',
          'Festividad local de Casas de los Pinos',
          'Festividad local de Casas de Roldan',
          'Festividad local de Casas de Santa Cruz',
          'Festividad local de Chumillas',
          'Festividad local de El Acebron',
          'Festividad local de El Pedernoso',
          'Festividad local de El Peral',
          'Festividad local de El Picazo',
          'Festividad local de Fuente de Pedro Naharro',
          'Festividad local de Fuentesclaras Chillaron',
          'Festividad local de Fuentesbuenas',
          'Festividad local de Garcinarro',
          'Festividad local de Honrubia',
          'Festividad local de Hontanaya',
          'Festividad local de Horcajada de la Torre',
          'Festividad local de Iniesta',
          'Festividad local de Jabaga',
          'Festividad local de Jabalera',
          'Festividad local de La Alberca de Zancara',
          'Festividad local de La Almarcha',
          'Festividad local de La Frontera',
          'Festividad local de La Parra de las Vegas',
          'Festividad local de La Ventosa',
          'Festividad local de Las Pedroñeras',
          'Festividad local de Ledaña',
          'Festividad local de Los Estesos',
          'Festividad local de Los Hinojosos',
          'Festividad local de Mazarulleque',
          'Festividad local de Minglanilla',
          'Festividad local de Mira',
          'Festividad local de Montalbanejo',
          'Festividad local de Motilla del Palancar',
          'Festividad local de Navalon',
          'Festividad local de Noheda',
          'Festividad local de Olivares del Jucar',
          'Festividad local de Olmeda de la Cuesta',
          'Festividad local de Olmeda del Rey',
          'Festividad local de Olmedilla de Eliz',
          'Festividad local de Paredes',
          'Festividad local de Poveda de la Obispalía',
          'Festividad local de Pozoseco',
          'Festividad local de Puebla de Almenara',
          'Festividad local de Puebla del Salvador',
          'Festividad local de Quintanar del Rey',
          'Festividad local de Ribagorda',
          'Festividad local de Rubielos Altos',
          'Festividad local de Rubielos Bajos',
          'Festividad local de Salmeroncillos',
          'Festividad local de Santa Maria de los Llanos',
          'Festividad local de Tresjuncos',
          'Festividad local de Valdecabras',
          'Festividad local de Valdecañas',
          'Festividad local de Valdemorillo de la Sierra',
          'Festividad local de Valdemoro Sierra',
          'Festividad local de Valeria',
          'Festividad local de Valparaiso de Arriba',
          'Festividad local de Valverdejo',
          'Festividad local de Villaconejos de Trabaque',
          'Festividad local de Villagarcía del Llano',
          'Festividad local de Villamayor de Santiago',
          'Festividad local de Villar de Domingo Garcia',
          'Festividad local de Villar de la Encina',
          'Festividad local de Villar del Humo',
          'Festividad local de Villar del Infantado',
          'Festividad local de Villar del Saz de Navalon',
          'Festividad local de Villarejo del Espartal',
          'Festividad local de Villarejo Periesteban',
          'Festividad local de Villarta',
          'Festividad local de Zafra de Zancara',
          'Festividad local de Abánades',
          'Festividad local de Alaminos',
          'Festividad local de Alarilla',
          'Festividad local de Alcolea de las Peñas',
          'Festividad local de Armuña de Tajuña',
          'Festividad local de Azuqueca de Henares',
          'Festividad local de Bujalaro',
          'Festividad local de Canredondo',
          'Festividad local de Cañizar',
          'Festividad local de Castejón de Henares',
          'Festividad local de Ciruelas',
          'Festividad local de Cogolludo',
          'Festividad local de El Cubillo de Uceda',
          'Festividad local de El Pobo de Dueñas',
          'Festividad local de Esplegares',
          'Festividad local de Fuentenovilla',
          'Festividad local de Gajanejos',
          'Festividad local de Las Inviernas',
          'Festividad local de Ledanca',
          'Festividad local de Marchamalo',
          'Festividad local de Pioz',
          'Festividad local de Sacecorbo',
          'Festividad local de Torre del Burgo',
          'Festividad local de Utande',
          'Festividad local de Valdearenas',
          'Festividad local de Valderrebollo',
          'Festividad local de Alberche del Caudillo',
          'Festividad local de Alcolea de Tajo',
          'Festividad local de Almendral de La Cañada',
          'Festividad local de Almonacid de Toledo',
          'Festividad local de Arcicollar',
          'Festividad local de Bernuy',
          'Festividad local de Burguillos de Toledo',
          'Festividad local de Cabañas de la Sagra',
          'Festividad local de Camuñas',
          'Festividad local de Casarrubios del Monte',
          'Festividad local de Casasbuenas',
          'Festividad local de Chueca',
          'Festividad local de Cuerva',
          'Festividad local de Domingo Pérez',
          'Festividad local de Dosbarrios',
          'Festividad local de Gálvez',
          'Festividad local de Gargantilla',
          'Festividad local de La Puebla de Almoradiel',
          'Festividad local de La Rinconada',
          'Festividad local de Las Herencias',
          'Festividad local de Las Vegas y San Antonio',
          'Festividad local de Layos',
          'Festividad local de Manzaneque',
          'Festividad local de Mascaraque',
          'Festividad local de Mejorada',
          'Festividad local de Miguel Esteban',
          'Festividad local de Quintanar de la Orden',
          'Festividad local de Quismondo',
          'Festividad local de Romeral',
          'Festividad local de San Pablo de los Montes',
          'Festividad local de Santa Cruz de la Zarza',
          'Festividad local de Seseña',
          'Festividad local de Talavera de la Reina',
          'Festividad local de Talavera la Nueva',
          'Festividad local de Tembleque',
          'Festividad local de Torre de Esteban Hambrán',
          'Festividad local de Totanés',
          'Festividad local de Turleque',
          'Festividad local de Ugena',
          'Festividad local de Urda',
          'Festividad local de Valdeazores',
          'Festividad local de Villa de Don Fadrique',
          'Festividad local de Villacañas',
          'Festividad local de Villafranca de los Caballeros',
          'Festividad local de Villasequilla',
        ],
        pc_ocupacion: 27.0,
        room: {
          double: {
            newPrice: 85.37,
            priceBefore: 83.96,
            priceNow: 81.98,
          },
          single: {
            newPrice: 71.83,
            priceBefore: 68.52,
            priceNow: 66.31,
          },
          suite: {
            newPrice: 137.75,
            priceBefore: 130.16,
            priceNow: 127.6,
          },
        },
      },
      {
        date: '2025-05-16',
        festivos: [
          'Festividad local de Alcadozo',
          'Festividad local de Cenizate',
          'Festividad local de Puertollano',
          'Festividad local de Cañada Juncosa',
          'Festividad local de Monreal del Llano',
          'Festividad local de Valhermoso de la Fuente',
          'Festividad local de La Olmeda de Jadraque',
          'Festividad local de Valdarachas',
        ],
        pc_ocupacion: 21.0,
        room: {
          double: {
            newPrice: 74.61,
            priceBefore: 69.86,
            priceNow: 66.42,
          },
          single: {
            newPrice: 55.31,
            priceBefore: 54.64,
            priceNow: 54.0,
          },
          suite: {
            newPrice: 110.46,
            priceBefore: 115.85,
            priceNow: 114.89,
          },
        },
      },
      {
        date: '2025-05-17',
        festivos: [
          'Festividad local de Paterna del Madera',
          'Festividad local de Fuenllana',
          'Festividad local de Balbacil',
          'Festividad local de Castellar de la Muela',
          'Festividad local de Clares',
          'Festividad local de Codes',
          'Festividad local de Fuentelsaz',
          'Festividad local de Maranchón',
          'Festividad local de Mochales',
          'Festividad local de Moratilla de los Meleros',
          'Festividad local de Tierzo',
          'Festividad local de Torremocha del Campo',
          'Festividad local de Turmiel',
        ],
        pc_ocupacion: 30.0,
        room: {
          double: {
            newPrice: 92.09,
            priceBefore: 90.71,
            priceNow: 86.68,
          },
          single: {
            newPrice: 87.72,
            priceBefore: 84.66,
            priceNow: 83.52,
          },
          suite: {
            newPrice: 150.01,
            priceBefore: 138.1,
            priceNow: 138.89,
          },
        },
      },
      {
        date: '2025-05-18',
        festivos: [],
        pc_ocupacion: 21.0,
        room: {
          double: {
            newPrice: 81.29,
            priceBefore: 75.85,
            priceNow: 74.41,
          },
          single: {
            newPrice: 57.81,
            priceBefore: 59.04,
            priceNow: 60.69,
          },
          suite: {
            newPrice: 141.89,
            priceBefore: 130.79,
            priceNow: 129.48,
          },
        },
      },
      {
        date: '2025-05-19',
        festivos: [],
        pc_ocupacion: 26.0,
        room: {
          double: {
            newPrice: 111.04,
            priceBefore: 104.16,
            priceNow: 101.4,
          },
          single: {
            newPrice: 86.77,
            priceBefore: 80.78,
            priceNow: 78.56,
          },
          suite: {
            newPrice: 135.66,
            priceBefore: 126.25,
            priceNow: 137.69,
          },
        },
      },
      {
        date: '2025-05-20',
        festivos: [
          'Festividad local de Peñas de San Pedro',
          'Festividad local de Tiriez',
          'Festividad local de Ciudad Real',
          'Festividad local de Guadalmez',
          'Festividad local de Algarra',
          'Festividad local de Boniches',
          'Festividad local de Cañada del Hoyo',
          'Festividad local de Castejon',
          'Festividad local de El Acebron',
          'Festividad local de La Hinojosa',
          'Festividad local de Landete',
          'Festividad local de Poveda de la Obispalía',
          'Festividad local de San Clemente',
          'Festividad local de Santa Cruz de Moya',
          'Festividad local de Vara de Rey',
          'Festividad local de Villarejo de Fuentes',
          'Festividad local de Auñón',
          'Festividad local de Corral de Almaguer',
          'Festividad local de Hontanar',
          'Festividad local de Nambroca',
          'Festividad local de Navahermosa',
          'Festividad local de Torrijos',
          'Festividad local de Velada',
        ],
        pc_ocupacion: 27.0,
        room: {
          double: {
            newPrice: 104.68,
            priceBefore: 101.09,
            priceNow: 97.13,
          },
          single: {
            newPrice: 42.31,
            priceBefore: 40.65,
            priceNow: 43.56,
          },
          suite: {
            newPrice: 179.31,
            priceBefore: 173.09,
            priceNow: 185.27,
          },
        },
      },
      {
        date: '2025-05-21',
        festivos: ['Festividad local de Hombrados'],
        pc_ocupacion: 17.0,
        room: {
          double: {
            newPrice: 100.91,
            priceBefore: 93.29,
            priceNow: 94.97,
          },
          single: {
            newPrice: 59.93,
            priceBefore: 55.96,
            priceNow: 54.77,
          },
          suite: {
            newPrice: 96.22,
            priceBefore: 93.4,
            priceNow: 96.0,
          },
        },
      },
      {
        date: '2025-05-22',
        festivos: [
          'Festividad local de Casas de Vés',
          'Festividad local de Higueruela',
          'Festividad local de Jorquera',
          'Festividad local de Pétrola',
          'Festividad local de Alcoba de los Montes',
          'Festividad local de Fuente el Fresno',
          'Festividad local de Los Pozuelos de Calatrava',
          'Festividad local de Puebla de Don Rodrigo',
          'Festividad local de Belinchon',
          'Festividad local de Fuentelespino de Moya',
          'Festividad local de Rozalen Del Monte',
          'Festividad local de Tebar',
          'Festividad local de Ablanque',
          'Festividad local de Carrascosa de Henares',
          'Festividad local de Megina',
          'Festividad local de Torrecuadrada de Molina',
          'Festividad local de Marjaliza',
          'Festividad local de Minas de Santa Quiteria',
        ],
        pc_ocupacion: 23.0,
        room: {
          double: {
            newPrice: 79.85,
            priceBefore: 75.54,
            priceNow: 82.27,
          },
          single: {
            newPrice: 66.86,
            priceBefore: 67.4,
            priceNow: 71.82,
          },
          suite: {
            newPrice: 124.84,
            priceBefore: 128.76,
            priceNow: 124.59,
          },
        },
      },
      {
        date: '2025-05-23',
        festivos: [
          'Festividad local de Higueruela',
          'Festividad local de Puebla del Príncipe',
          'Festividad local de Minas de Santa Quiteria',
        ],
        pc_ocupacion: 23.0,
        room: {
          double: {
            newPrice: 72.95,
            priceBefore: 67.45,
            priceNow: 64.59,
          },
          single: {
            newPrice: 69.34,
            priceBefore: 65.98,
            priceNow: 68.27,
          },
          suite: {
            newPrice: 128.14,
            priceBefore: 121.89,
            priceNow: 125.43,
          },
        },
      },
      {
        date: '2025-05-24',
        festivos: [
          'Festividad local de Barrax',
          'Festividad local de Abia de la Obispalia',
          'Festividad local de Almodóvar del Pinar',
          'Festividad local de Huete',
          'Festividad local de Naharros',
          'Festividad local de Saceda-Trasierra',
          'Festividad local de Robledillo',
        ],
        pc_ocupacion: 23.0,
        room: {
          double: {
            newPrice: 81.24,
            priceBefore: 81.41,
            priceNow: 79.01,
          },
          single: {
            newPrice: 69.83,
            priceBefore: 69.79,
            priceNow: 74.77,
          },
          suite: {
            newPrice: 121.55,
            priceBefore: 122.08,
            priceNow: 129.59,
          },
        },
      },
      {
        date: '2025-05-25',
        festivos: [],
        pc_ocupacion: 23.0,
        room: {
          double: {
            newPrice: 52.44,
            priceBefore: 53.8,
            priceNow: 57.62,
          },
          single: {
            newPrice: 75.36,
            priceBefore: 75.64,
            priceNow: 82.72,
          },
          suite: {
            newPrice: 129.44,
            priceBefore: 124.96,
            priceNow: 125.34,
          },
        },
      },
      {
        date: '2025-05-26',
        festivos: [],
        pc_ocupacion: 30.0,
        room: {
          double: {
            newPrice: 115.67,
            priceBefore: 114.57,
            priceNow: 125.1,
          },
          single: {
            newPrice: 103.39,
            priceBefore: 94.7,
            priceNow: 98.4,
          },
          suite: {
            newPrice: 132.39,
            priceBefore: 136.72,
            priceNow: 143.39,
          },
        },
      },
      {
        date: '2025-05-27',
        festivos: [
          'Festividad local de Mestanza',
          'Festividad local de Navas de Estena',
          'Festividad local de Saelices',
          'Festividad local de Cañizar',
          'Festividad local de Córcoles',
          'Festividad local de Hortezuela de Océn',
          'Festividad local de Hormigos',
          'Festividad local de Robledillo',
        ],
        pc_ocupacion: 22.0,
        room: {
          double: {
            newPrice: 62.3,
            priceBefore: 64.64,
            priceNow: 65.51,
          },
          single: {
            newPrice: 75.16,
            priceBefore: 73.34,
            priceNow: 72.38,
          },
          suite: {
            newPrice: 110.05,
            priceBefore: 111.45,
            priceNow: 109.71,
          },
        },
      },
      {
        date: '2025-05-28',
        festivos: [
          'Festividad local de El Hito',
          'Festividad local de Puebla del Salvador',
          'Festividad local de Córcoles',
          'Festividad local de Tordelrábano',
        ],
        pc_ocupacion: 21.0,
        room: {
          double: {
            newPrice: 91.55,
            priceBefore: 85.42,
            priceNow: 87.93,
          },
          single: {
            newPrice: 54.33,
            priceBefore: 51.01,
            priceNow: 52.38,
          },
          suite: {
            newPrice: 147.27,
            priceBefore: 136.22,
            priceNow: 140.33,
          },
        },
      },
      {
        date: '2025-05-29',
        festivos: [
          'Festividad local de Gabaldon',
          'Festividad local de Sacedón',
        ],
        pc_ocupacion: 20.0,
        room: {
          double: {
            newPrice: 78.95,
            priceBefore: 77.4,
            priceNow: 73.93,
          },
          single: {
            newPrice: 54.91,
            priceBefore: 49.95,
            priceNow: 48.48,
          },
          suite: {
            newPrice: 112.35,
            priceBefore: 108.69,
            priceNow: 117.27,
          },
        },
      },
      {
        date: '2025-05-30',
        festivos: ['Festividad local de Sacedón', 'Festividad local de Selas'],
        pc_ocupacion: 22.0,
        room: {
          double: {
            newPrice: 77.35,
            priceBefore: 72.24,
            priceNow: 70.24,
          },
          single: {
            newPrice: 68.65,
            priceBefore: 71.7,
            priceNow: 77.55,
          },
          suite: {
            newPrice: 123.19,
            priceBefore: 128.53,
            priceNow: 134.44,
          },
        },
      },
      {
        date: '2025-05-31',
        festivos: ['Día de Castilla-La Mancha'],
        pc_ocupacion: 25.0,
        room: {
          double: {
            newPrice: 90.55,
            priceBefore: 84.18,
            priceNow: 91.16,
          },
          single: {
            newPrice: 54.8,
            priceBefore: 55.96,
            priceNow: 60.99,
          },
          suite: {
            newPrice: 140.19,
            priceBefore: 127.46,
            priceNow: 126.28,
          },
        },
      },
      {
        date: '2025-06-01',
        festivos: [
          'Festividad local de Cuenca',
          'Festividad local de La Melgosa',
          'Festividad local de Mohorte',
          'Festividad local de Gualda',
        ],
        pc_ocupacion: 57.0,
        room: {
          double: {
            newPrice: 153.35,
            priceBefore: 156.87,
            priceNow: 158.24,
          },
          single: {
            newPrice: 118.0,
            priceBefore: 113.61,
            priceNow: 110.59,
          },
          suite: {
            newPrice: 275.63,
            priceBefore: 250.83,
            priceNow: 243.29,
          },
        },
      },
      {
        date: '2025-06-02',
        festivos: [],
        pc_ocupacion: 65.0,
        room: {
          double: {
            newPrice: 191.11,
            priceBefore: 200.73,
            priceNow: 203.95,
          },
          single: {
            newPrice: 115.38,
            priceBefore: 107.96,
            priceNow: 104.17,
          },
          suite: {
            newPrice: 279.0,
            priceBefore: 266.4,
            priceNow: 271.37,
          },
        },
      },
      {
        date: '2025-06-03',
        festivos: [
          'Festividad local de Zafrilla',
          'Festividad local de Casas de San Galindo',
        ],
        pc_ocupacion: 53.0,
        room: {
          double: {
            newPrice: 169.12,
            priceBefore: 177.62,
            priceNow: 180.06,
          },
          single: {
            newPrice: 129.31,
            priceBefore: 120.71,
            priceNow: 124.28,
          },
          suite: {
            newPrice: 249.64,
            priceBefore: 247.85,
            priceNow: 238.78,
          },
        },
      },
      {
        date: '2025-06-04',
        festivos: ['Festividad local de Casas de San Galindo'],
        pc_ocupacion: 49.0,
        room: {
          double: {
            newPrice: 197.58,
            priceBefore: 182.93,
            priceNow: 174.11,
          },
          single: {
            newPrice: 84.54,
            priceBefore: 82.23,
            priceNow: 86.87,
          },
          suite: {
            newPrice: 252.56,
            priceBefore: 256.4,
            priceNow: 271.96,
          },
        },
      },
      {
        date: '2025-06-05',
        festivos: [],
        pc_ocupacion: 53.0,
        room: {
          double: {
            newPrice: 156.16,
            priceBefore: 146.53,
            priceNow: 158.91,
          },
          single: {
            newPrice: 133.57,
            priceBefore: 123.17,
            priceNow: 127.54,
          },
          suite: {
            newPrice: 262.67,
            priceBefore: 250.14,
            priceNow: 251.41,
          },
        },
      },
      {
        date: '2025-06-06',
        festivos: ['Festividad local de Valdepeñas'],
        pc_ocupacion: 41.0,
        room: {
          double: {
            newPrice: 158.89,
            priceBefore: 150.34,
            priceNow: 156.2,
          },
          single: {
            newPrice: 146.38,
            priceBefore: 148.06,
            priceNow: 142.56,
          },
          suite: {
            newPrice: 151.96,
            priceBefore: 148.89,
            priceNow: 161.95,
          },
        },
      },
      {
        date: '2025-06-07',
        festivos: ['Festividad local de Hinojosa'],
        pc_ocupacion: 69.0,
        room: {
          double: {
            newPrice: 177.24,
            priceBefore: 186.03,
            priceNow: 200.42,
          },
          single: {
            newPrice: 170.97,
            priceBefore: 166.89,
            priceNow: 172.79,
          },
          suite: {
            newPrice: 270.88,
            priceBefore: 274.04,
            priceNow: 284.51,
          },
        },
      },
      {
        date: '2025-06-08',
        festivos: [],
        pc_ocupacion: 64.0,
        room: {
          double: {
            newPrice: 227.86,
            priceBefore: 210.28,
            priceNow: 223.5,
          },
          single: {
            newPrice: 167.82,
            priceBefore: 159.95,
            priceNow: 153.38,
          },
          suite: {
            newPrice: 270.58,
            priceBefore: 254.06,
            priceNow: 262.85,
          },
        },
      },
      {
        date: '2025-06-09',
        festivos: ['Festividad local de La Huerce'],
        pc_ocupacion: 66.0,
        room: {
          double: {
            newPrice: 223.46,
            priceBefore: 213.35,
            priceNow: 229.69,
          },
          single: {
            newPrice: 149.92,
            priceBefore: 157.17,
            priceNow: 151.83,
          },
          suite: {
            newPrice: 283.57,
            priceBefore: 283.7,
            priceNow: 306.0,
          },
        },
      },
      {
        date: '2025-06-10',
        festivos: [
          'Festividad local de Fuentes',
          'Festividad local de Cobeta',
          'Festividad local de Mantiel',
        ],
        pc_ocupacion: 55.0,
        room: {
          double: {
            newPrice: 199.29,
            priceBefore: 189.68,
            priceNow: 187.73,
          },
          single: {
            newPrice: 113.13,
            priceBefore: 117.47,
            priceNow: 121.32,
          },
          suite: {
            newPrice: 294.16,
            priceBefore: 272.07,
            priceNow: 272.44,
          },
        },
      },
      {
        date: '2025-06-11',
        festivos: [
          'Festividad local de Pétrola',
          'Festividad local de Arenas de San Juan',
          'Festividad local de Cogollor',
          'Festividad local de Hijes',
          'Festividad local de Masegoso de Tajuña',
          'Festividad local de Trijueque',
          'Festividad local de Valdenuño Fernandez',
        ],
        pc_ocupacion: 46.0,
        room: {
          double: {
            newPrice: 195.26,
            priceBefore: 178.62,
            priceNow: 187.94,
          },
          single: {
            newPrice: 103.04,
            priceBefore: 101.73,
            priceNow: 109.81,
          },
          suite: {
            newPrice: 212.13,
            priceBefore: 207.11,
            priceNow: 210.56,
          },
        },
      },
      {
        date: '2025-06-12',
        festivos: [],
        pc_ocupacion: 57.0,
        room: {
          double: {
            newPrice: 184.53,
            priceBefore: 175.1,
            priceNow: 175.64,
          },
          single: {
            newPrice: 156.39,
            priceBefore: 161.25,
            priceNow: 169.01,
          },
          suite: {
            newPrice: 233.94,
            priceBefore: 242.66,
            priceNow: 230.75,
          },
        },
      },
      {
        date: '2025-06-13',
        festivos: [
          'Festividad local de Casas de Vés',
          'Festividad local de Robledo',
          'Festividad local de Alamillo',
          'Festividad local de Anchuras',
          'Festividad local de Torralba de Calatrava',
          'Festividad local de Abia de la Obispalia',
          'Festividad local de Albendea',
          'Festividad local de Arcos de la Sierra',
          'Festividad local de Buciegas',
          'Festividad local de Campillos-Sierra',
          'Festividad local de Cañete',
          'Festividad local de Cardenete',
          'Festividad local de Carrascosa de Haro',
          'Festividad local de Collados',
          'Festividad local de Colliga',
          'Festividad local de El Pozuelo',
          'Festividad local de Fuentelespino de Haro',
          'Festividad local de Huerta del Marquesado',
          'Festividad local de La Frontera',
          'Festividad local de Laguna del Marquesado',
          'Festividad local de Los Valdecolmenas',
          'Festividad local de Mariana',
          'Festividad local de Noheda',
          'Festividad local de Palomera',
          'Festividad local de Portalrubio de Guadamejud',
          'Festividad local de Ribatajadilla',
          'Festividad local de Salmeroncillos',
          'Festividad local de Salvacañete',
          'Festividad local de Sotos',
          'Festividad local de Talayuelas',
          'Festividad local de Valdecañas',
          'Festividad local de Valdemoro Sierra',
          'Festividad local de Valparaiso de Abajo',
          'Festividad local de Villanueva de los Escuderos',
          'Festividad local de Villar del Saz de Arcas',
          'Festividad local de Villaseca',
          'Festividad local de Zarzuela',
          'Festividad local de Albares',
          'Festividad local de Alcolea del Pinar',
          'Festividad local de Anquela del Pedregal',
          'Festividad local de Aranzueque',
          'Festividad local de Armallones',
          'Festividad local de Bañuelos',
          'Festividad local de Caspueñas',
          'Festividad local de Chillaron del Rey',
          'Festividad local de Condemios de Abajo',
          'Festividad local de Cubillejo de la Sierra',
          'Festividad local de El Recuenco',
          'Festividad local de Estables',
          'Festividad local de Gajanejos',
          'Festividad local de Huertahernando',
          'Festividad local de Iniestola',
          'Festividad local de Jirueque',
          'Festividad local de La Miñosa',
          'Festividad local de Miedes de Atienza',
          'Festividad local de Pradena de Atienza',
          'Festividad local de Tordesilos',
          'Festividad local de Torrecuadradilla',
          'Festividad local de Ujados',
          'Festividad local de Viana de Jadraque',
          'Festividad local de Villanueva de Alcorón',
          'Festividad local de Zaorejas',
          'Festividad local de Barcience',
          'Festividad local de Cabezamesada',
          'Festividad local de Cedillo del Condado',
          'Festividad local de Lucillos',
          'Festividad local de Yeles',
        ],
        pc_ocupacion: 53.0,
        room: {
          double: {
            newPrice: 190.41,
            priceBefore: 198.56,
            priceNow: 209.48,
          },
          single: {
            newPrice: 105.2,
            priceBefore: 103.96,
            priceNow: 105.56,
          },
          suite: {
            newPrice: 256.09,
            priceBefore: 262.85,
            priceNow: 256.45,
          },
        },
      },
      {
        date: '2025-06-14',
        festivos: [
          'Festividad local de Agudo',
          'Festividad local de Alcalá de la Vega',
          'Festividad local de Aliaguilla',
          'Festividad local de Fuentelespino de Haro',
          'Festividad local de Monteagudo de las Salinas',
          'Festividad local de Talayuelas',
          'Festividad local de Valdeolivas',
          'Festividad local de Albares',
          'Festividad local de Almadrones',
          'Festividad local de Canales del Ducado',
          'Festividad local de Cogollor',
          'Festividad local de La Miñosa',
          'Festividad local de Luzón',
          'Festividad local de Sotodosos',
          'Festividad local de Barcience',
          'Festividad local de Cabezamesada',
          'Festividad local de Yeles',
        ],
        pc_ocupacion: 55.0,
        room: {
          double: {
            newPrice: 138.68,
            priceBefore: 141.11,
            priceNow: 138.0,
          },
          single: {
            newPrice: 158.88,
            priceBefore: 154.86,
            priceNow: 149.0,
          },
          suite: {
            newPrice: 271.63,
            priceBefore: 251.39,
            priceNow: 249.82,
          },
        },
      },
      {
        date: '2025-06-15',
        festivos: [
          'Festividad local de Alcalá de la Vega',
          'Festividad local de Algora',
          'Festividad local de San Andrés del Congosto',
        ],
        pc_ocupacion: 60.0,
        room: {
          double: {
            newPrice: 217.73,
            priceBefore: 203.71,
            priceNow: 212.06,
          },
          single: {
            newPrice: 145.63,
            priceBefore: 138.77,
            priceNow: 138.99,
          },
          suite: {
            newPrice: 228.28,
            priceBefore: 231.73,
            priceNow: 229.97,
          },
        },
      },
      {
        date: '2025-06-16',
        festivos: [
          'Festividad local de Algora',
          'Festividad local de Valdepinillos',
        ],
        pc_ocupacion: 57.0,
        room: {
          double: {
            newPrice: 177.97,
            priceBefore: 178.26,
            priceNow: 195.52,
          },
          single: {
            newPrice: 126.39,
            priceBefore: 115.25,
            priceNow: 112.58,
          },
          suite: {
            newPrice: 311.1,
            priceBefore: 288.01,
            priceNow: 284.37,
          },
        },
      },
      {
        date: '2025-06-17',
        festivos: [
          'Festividad local de Zarza de Tajo',
          'Festividad local de Almadrones',
          'Festividad local de Valdegrudas',
        ],
        pc_ocupacion: 51.0,
        room: {
          double: {
            newPrice: 175.34,
            priceBefore: 175.06,
            priceNow: 171.45,
          },
          single: {
            newPrice: 94.64,
            priceBefore: 91.16,
            priceNow: 87.33,
          },
          suite: {
            newPrice: 244.59,
            priceBefore: 242.71,
            priceNow: 236.78,
          },
        },
      },
      {
        date: '2025-06-18',
        festivos: [],
        pc_ocupacion: 48.0,
        room: {
          double: {
            newPrice: 134.57,
            priceBefore: 122.38,
            priceNow: 126.03,
          },
          single: {
            newPrice: 104.48,
            priceBefore: 103.51,
            priceNow: 99.42,
          },
          suite: {
            newPrice: 247.5,
            priceBefore: 235.97,
            priceNow: 250.66,
          },
        },
      },
      {
        date: '2025-06-19',
        festivos: ['Corpus Christi'],
        pc_ocupacion: 66.0,
        room: {
          double: {
            newPrice: 142.38,
            priceBefore: 147.01,
            priceNow: 153.58,
          },
          single: {
            newPrice: 142.01,
            priceBefore: 132.32,
            priceNow: 131.73,
          },
          suite: {
            newPrice: 369.99,
            priceBefore: 355.6,
            priceNow: 369.16,
          },
        },
      },
      {
        date: '2025-06-20',
        festivos: ['Festividad local de Concha'],
        pc_ocupacion: 53.0,
        room: {
          double: {
            newPrice: 224.05,
            priceBefore: 235.8,
            priceNow: 247.98,
          },
          single: {
            newPrice: 105.51,
            priceBefore: 96.05,
            priceNow: 95.78,
          },
          suite: {
            newPrice: 223.46,
            priceBefore: 214.67,
            priceNow: 224.61,
          },
        },
      },
      {
        date: '2025-06-21',
        festivos: [
          'Festividad local de Galve de Sorbe',
          'Festividad local de Heras de Ayuso',
          'Festividad local de Villanueva de la Torre',
          'Festividad local de Rielves',
        ],
        pc_ocupacion: 39.0,
        room: {
          double: {
            newPrice: 151.26,
            priceBefore: 146.55,
            priceNow: 155.65,
          },
          single: {
            newPrice: 134.08,
            priceBefore: 131.67,
            priceNow: 140.37,
          },
          suite: {
            newPrice: 139.36,
            priceBefore: 135.04,
            priceNow: 130.88,
          },
        },
      },
      {
        date: '2025-06-22',
        festivos: [
          'Festividad local de Pajares',
          'Festividad local de Las Inviernas',
          'Festividad local de Utande',
        ],
        pc_ocupacion: 48.0,
        room: {
          double: {
            newPrice: 175.52,
            priceBefore: 172.07,
            priceNow: 175.97,
          },
          single: {
            newPrice: 135.85,
            priceBefore: 136.43,
            priceNow: 138.84,
          },
          suite: {
            newPrice: 152.6,
            priceBefore: 147.46,
            priceNow: 158.08,
          },
        },
      },
      {
        date: '2025-06-23',
        festivos: [],
        pc_ocupacion: 50.0,
        room: {
          double: {
            newPrice: 173.59,
            priceBefore: 166.13,
            priceNow: 174.58,
          },
          single: {
            newPrice: 126.24,
            priceBefore: 119.86,
            priceNow: 117.66,
          },
          suite: {
            newPrice: 229.31,
            priceBefore: 218.49,
            priceNow: 219.22,
          },
        },
      },
      {
        date: '2025-06-24',
        festivos: [
          'Festividad local de Alatoz',
          'Festividad local de Albacete',
          'Festividad local de Alcalá del Júcar',
          'Festividad local de Cotillas',
          'Festividad local de El Bonillo',
          'Festividad local de Pozo Cañada',
          'Festividad local de Pozohondo',
          'Festividad local de Riópar',
          'Festividad local de Abenójar',
          'Festividad local de Horcajo de los Montes',
          'Festividad local de Villar del Pozo',
          'Festividad local de Villarta San Juan',
          'Festividad local de Casas de Garcimolina',
          'Festividad local de Colliguilla',
          'Festividad local de Fuentesclaras Chillaron',
          'Festividad local de Ribatajada',
          'Festividad local de Tragacete',
          'Festividad local de Villaconejos de Trabaque',
          'Festividad local de Alhóndiga',
          'Festividad local de Ledanca',
          'Festividad local de Majaelrayo',
          'Festividad local de Matillas',
          'Festividad local de Medranda',
          'Festividad local de Pinilla de Molina',
          'Festividad local de Puebla de Beleña',
          'Festividad local de Taragudo',
          'Festividad local de Tobillos',
          'Festividad local de Valdeaveruelo',
          'Festividad local de Yebra',
          'Festividad local de Camarena',
          'Festividad local de Huecas',
          'Festividad local de La Mata',
          'Festividad local de Marjaliza',
          'Festividad local de Palomeque',
          'Festividad local de Puente del Arzobispo',
          'Festividad local de Ugena',
        ],
        pc_ocupacion: 47.0,
        room: {
          double: {
            newPrice: 145.34,
            priceBefore: 136.38,
            priceNow: 133.87,
          },
          single: {
            newPrice: 123.94,
            priceBefore: 120.25,
            priceNow: 116.44,
          },
          suite: {
            newPrice: 235.78,
            priceBefore: 222.81,
            priceNow: 215.91,
          },
        },
      },
      {
        date: '2025-06-25',
        festivos: ['Festividad local de Pozohondo'],
        pc_ocupacion: 54.0,
        room: {
          double: {
            newPrice: 199.85,
            priceBefore: 183.58,
            priceNow: 181.78,
          },
          single: {
            newPrice: 113.48,
            priceBefore: 112.02,
            priceNow: 111.76,
          },
          suite: {
            newPrice: 274.11,
            priceBefore: 263.21,
            priceNow: 288.04,
          },
        },
      },
      {
        date: '2025-06-26',
        festivos: [],
        pc_ocupacion: 59.0,
        room: {
          double: {
            newPrice: 211.84,
            priceBefore: 193.76,
            priceNow: 189.14,
          },
          single: {
            newPrice: 128.56,
            priceBefore: 124.86,
            priceNow: 124.35,
          },
          suite: {
            newPrice: 239.9,
            priceBefore: 251.78,
            priceNow: 275.75,
          },
        },
      },
      {
        date: '2025-06-27',
        festivos: ['Festividad local de Fuentenovilla'],
        pc_ocupacion: 49.0,
        room: {
          double: {
            newPrice: 147.67,
            priceBefore: 155.03,
            priceNow: 160.25,
          },
          single: {
            newPrice: 91.68,
            priceBefore: 86.66,
            priceNow: 93.99,
          },
          suite: {
            newPrice: 261.94,
            priceBefore: 241.46,
            priceNow: 265.3,
          },
        },
      },
      {
        date: '2025-06-28',
        festivos: [
          'Festividad local de San Pedro',
          'Festividad local de Bujalaro',
          'Festividad local de Polán',
        ],
        pc_ocupacion: 47.0,
        room: {
          double: {
            newPrice: 155.7,
            priceBefore: 154.8,
            priceNow: 152.72,
          },
          single: {
            newPrice: 101.33,
            priceBefore: 105.53,
            priceNow: 106.27,
          },
          suite: {
            newPrice: 252.81,
            priceBefore: 233.52,
            priceNow: 256.86,
          },
        },
      },
      {
        date: '2025-06-29',
        festivos: [
          'Festividad local de Bascuñana de San Pedro',
          'Festividad local de Loranca del Campo',
          'Festividad local de El Pedregal',
          'Festividad local de Polán',
        ],
        pc_ocupacion: 65.0,
        room: {
          double: {
            newPrice: 138.26,
            priceBefore: 143.48,
            priceNow: 154.42,
          },
          single: {
            newPrice: 171.85,
            priceBefore: 169.03,
            priceNow: 162.4,
          },
          suite: {
            newPrice: 266.79,
            priceBefore: 274.55,
            priceNow: 282.66,
          },
        },
      },
      {
        date: '2025-06-30',
        festivos: [],
        pc_ocupacion: 58.0,
        room: {
          double: {
            newPrice: 165.23,
            priceBefore: 172.26,
            priceNow: 167.43,
          },
          single: {
            newPrice: 107.87,
            priceBefore: 111.3,
            priceNow: 119.21,
          },
          suite: {
            newPrice: 248.12,
            priceBefore: 249.91,
            priceNow: 271.73,
          },
        },
      },
      {
        date: '2025-07-01',
        festivos: [
          'Festividad local de San Pedro',
          'Festividad local de Chequilla',
        ],
        pc_ocupacion: 65.0,
        room: {
          double: {
            newPrice: 225.64,
            priceBefore: 220.08,
            priceNow: 237.19,
          },
          single: {
            newPrice: 136.72,
            priceBefore: 139.54,
            priceNow: 150.35,
          },
          suite: {
            newPrice: 214.37,
            priceBefore: 213.49,
            priceNow: 228.12,
          },
        },
      },
      {
        date: '2025-07-02',
        festivos: ['Festividad local de Valdemeca'],
        pc_ocupacion: 57.0,
        room: {
          double: {
            newPrice: 170.91,
            priceBefore: 165.75,
            priceNow: 170.88,
          },
          single: {
            newPrice: 148.95,
            priceBefore: 154.21,
            priceNow: 151.69,
          },
          suite: {
            newPrice: 242.16,
            priceBefore: 232.8,
            priceNow: 228.28,
          },
        },
      },
      {
        date: '2025-07-03',
        festivos: [
          'Festividad local de Valdemeca',
          'Festividad local de Escamilla',
        ],
        pc_ocupacion: 65.0,
        room: {
          double: {
            newPrice: 198.83,
            priceBefore: 192.89,
            priceNow: 190.95,
          },
          single: {
            newPrice: 140.49,
            priceBefore: 146.15,
            priceNow: 150.31,
          },
          suite: {
            newPrice: 232.46,
            priceBefore: 237.18,
            priceNow: 225.9,
          },
        },
      },
      {
        date: '2025-07-04',
        festivos: [],
        pc_ocupacion: 53.0,
        room: {
          double: {
            newPrice: 190.13,
            priceBefore: 174.49,
            priceNow: 169.01,
          },
          single: {
            newPrice: 150.35,
            priceBefore: 146.41,
            priceNow: 149.28,
          },
          suite: {
            newPrice: 216.89,
            priceBefore: 225.97,
            priceNow: 233.48,
          },
        },
      },
      {
        date: '2025-07-05',
        festivos: ['Festividad local de Puerto Rey'],
        pc_ocupacion: 57.0,
        room: {
          double: {
            newPrice: 216.22,
            priceBefore: 223.1,
            priceNow: 216.9,
          },
          single: {
            newPrice: 144.03,
            priceBefore: 143.22,
            priceNow: 142.5,
          },
          suite: {
            newPrice: 219.22,
            priceBefore: 219.15,
            priceNow: 213.07,
          },
        },
      },
      {
        date: '2025-07-06',
        festivos: [],
        pc_ocupacion: 61.0,
        room: {
          double: {
            newPrice: 174.48,
            priceBefore: 162.75,
            priceNow: 161.94,
          },
          single: {
            newPrice: 154.56,
            priceBefore: 151.89,
            priceNow: 147.54,
          },
          suite: {
            newPrice: 303.21,
            priceBefore: 303.0,
            priceNow: 302.7,
          },
        },
      },
      {
        date: '2025-07-07',
        festivos: [],
        pc_ocupacion: 59.0,
        room: {
          double: {
            newPrice: 193.72,
            priceBefore: 194.89,
            priceNow: 185.98,
          },
          single: {
            newPrice: 107.55,
            priceBefore: 111.96,
            priceNow: 121.72,
          },
          suite: {
            newPrice: 214.69,
            priceBefore: 223.12,
            priceNow: 222.92,
          },
        },
      },
      {
        date: '2025-07-08',
        festivos: [
          'Festividad local de Ballesteros de Calatrava',
          'Festividad local de Canredondo',
          'Festividad local de Puerto Rey',
        ],
        pc_ocupacion: 72.0,
        room: {
          double: {
            newPrice: 162.89,
            priceBefore: 157.95,
            priceNow: 151.03,
          },
          single: {
            newPrice: 172.78,
            priceBefore: 179.95,
            priceNow: 177.91,
          },
          suite: {
            newPrice: 375.14,
            priceBefore: 345.12,
            priceNow: 329.21,
          },
        },
      },
      {
        date: '2025-07-09',
        festivos: ['Festividad local de Luzaga'],
        pc_ocupacion: 55.0,
        room: {
          double: {
            newPrice: 201.55,
            priceBefore: 188.65,
            priceNow: 205.84,
          },
          single: {
            newPrice: 136.38,
            priceBefore: 139.41,
            priceNow: 146.5,
          },
          suite: {
            newPrice: 244.54,
            priceBefore: 238.57,
            priceNow: 254.04,
          },
        },
      },
      {
        date: '2025-07-10',
        festivos: ['Festividad local de Gascueña', 'Festividad local de Orea'],
        pc_ocupacion: 74.0,
        room: {
          double: {
            newPrice: 175.7,
            priceBefore: 177.36,
            priceNow: 168.72,
          },
          single: {
            newPrice: 189.03,
            priceBefore: 187.52,
            priceNow: 189.74,
          },
          suite: {
            newPrice: 284.21,
            priceBefore: 284.84,
            priceNow: 303.95,
          },
        },
      },
      {
        date: '2025-07-11',
        festivos: [],
        pc_ocupacion: 69.0,
        room: {
          double: {
            newPrice: 181.93,
            priceBefore: 174.57,
            priceNow: 189.3,
          },
          single: {
            newPrice: 228.75,
            priceBefore: 216.08,
            priceNow: 235.72,
          },
          suite: {
            newPrice: 300.38,
            priceBefore: 281.28,
            priceNow: 278.44,
          },
        },
      },
      {
        date: '2025-07-12',
        festivos: [
          'Festividad local de Manzanares',
          'Festividad local de Robledo del Buey',
        ],
        pc_ocupacion: 63.0,
        room: {
          double: {
            newPrice: 176.86,
            priceBefore: 175.08,
            priceNow: 182.92,
          },
          single: {
            newPrice: 154.67,
            priceBefore: 161.12,
            priceNow: 170.52,
          },
          suite: {
            newPrice: 234.22,
            priceBefore: 229.99,
            priceNow: 236.61,
          },
        },
      },
      {
        date: '2025-07-13',
        festivos: [],
        pc_ocupacion: 60.0,
        room: {
          double: {
            newPrice: 131.34,
            priceBefore: 123.96,
            priceNow: 131.77,
          },
          single: {
            newPrice: 102.91,
            priceBefore: 101.11,
            priceNow: 101.98,
          },
          suite: {
            newPrice: 344.63,
            priceBefore: 340.25,
            priceNow: 331.21,
          },
        },
      },
      {
        date: '2025-07-14',
        festivos: [],
        pc_ocupacion: 59.0,
        room: {
          double: {
            newPrice: 191.02,
            priceBefore: 181.75,
            priceNow: 191.74,
          },
          single: {
            newPrice: 164.82,
            priceBefore: 154.18,
            priceNow: 155.93,
          },
          suite: {
            newPrice: 241.69,
            priceBefore: 223.51,
            priceNow: 215.4,
          },
        },
      },
      {
        date: '2025-07-15',
        festivos: [
          'Festividad local de Pinilla de Molina',
          'Festividad local de Azután',
          'Festividad local de Yuncler de la Sagra',
        ],
        pc_ocupacion: 71.0,
        room: {
          double: {
            newPrice: 175.49,
            priceBefore: 182.43,
            priceNow: 183.37,
          },
          single: {
            newPrice: 156.69,
            priceBefore: 147.8,
            priceNow: 141.2,
          },
          suite: {
            newPrice: 283.01,
            priceBefore: 288.78,
            priceNow: 291.55,
          },
        },
      },
      {
        date: '2025-07-16',
        festivos: [
          'Festividad local de Liétor',
          'Festividad local de Almodóvar del Campo',
          'Festividad local de Arenas de San Juan',
          'Festividad local de Pozuelo de Calatrava',
          'Festividad local de Carrascosa de la Sierra',
          'Festividad local de Colliguilla',
          'Festividad local de Tejadillos',
          'Festividad local de Berninches',
          'Festividad local de Molina de Aragón',
          'Festividad local de La Fresneda de la Jara',
          'Festividad local de La Puebla de Montalbán',
          'Festividad local de Las Ventas de Retamosa',
          'Festividad local de Manzaneque',
          'Festividad local de Mocejón',
          'Festividad local de Nambroca',
          'Festividad local de Santa Olalla',
          'Festividad local de Yuncler de la Sagra',
          'Festividad local de Yunclillos',
        ],
        pc_ocupacion: 55.0,
        room: {
          double: {
            newPrice: 164.17,
            priceBefore: 155.96,
            priceNow: 160.09,
          },
          single: {
            newPrice: 140.68,
            priceBefore: 131.38,
            priceNow: 134.68,
          },
          suite: {
            newPrice: 244.9,
            priceBefore: 227.34,
            priceNow: 225.96,
          },
        },
      },
      {
        date: '2025-07-17',
        festivos: [],
        pc_ocupacion: 78.0,
        room: {
          double: {
            newPrice: 203.93,
            priceBefore: 188.37,
            priceNow: 180.53,
          },
          single: {
            newPrice: 142.65,
            priceBefore: 136.05,
            priceNow: 146.71,
          },
          suite: {
            newPrice: 430.14,
            priceBefore: 394.42,
            priceNow: 395.94,
          },
        },
      },
      {
        date: '2025-07-18',
        festivos: ['Festividad local de Magán'],
        pc_ocupacion: 47.0,
        room: {
          double: {
            newPrice: 108.93,
            priceBefore: 111.53,
            priceNow: 113.48,
          },
          single: {
            newPrice: 120.72,
            priceBefore: 118.95,
            priceNow: 115.01,
          },
          suite: {
            newPrice: 205.87,
            priceBefore: 207.66,
            priceNow: 223.43,
          },
        },
      },
      {
        date: '2025-07-19',
        festivos: [
          'Festividad local de Villarejo Sobrehuerta',
          'Festividad local de Cabanillas del Campo',
          'Festividad local de Chozas de Canales',
          'Festividad local de Noblejas',
        ],
        pc_ocupacion: 52.0,
        room: {
          double: {
            newPrice: 162.36,
            priceBefore: 158.52,
            priceNow: 152.03,
          },
          single: {
            newPrice: 123.1,
            priceBefore: 115.57,
            priceNow: 122.31,
          },
          suite: {
            newPrice: 254.79,
            priceBefore: 238.29,
            priceNow: 246.43,
          },
        },
      },
      {
        date: '2025-07-20',
        festivos: ['Festividad local de Cendejas de Enmedio'],
        pc_ocupacion: 61.0,
        room: {
          double: {
            newPrice: 202.92,
            priceBefore: 200.4,
            priceNow: 210.62,
          },
          single: {
            newPrice: 130.31,
            priceBefore: 127.04,
            priceNow: 137.05,
          },
          suite: {
            newPrice: 312.82,
            priceBefore: 286.06,
            priceNow: 285.73,
          },
        },
      },
      {
        date: '2025-07-21',
        festivos: ['Festividad local de Cendejas de Padrastro'],
        pc_ocupacion: 59.0,
        room: {
          double: {
            newPrice: 160.67,
            priceBefore: 159.39,
            priceNow: 153.16,
          },
          single: {
            newPrice: 172.65,
            priceBefore: 166.35,
            priceNow: 175.55,
          },
          suite: {
            newPrice: 229.91,
            priceBefore: 215.94,
            priceNow: 208.4,
          },
        },
      },
      {
        date: '2025-07-22',
        festivos: [
          'Festividad local de Villalgordo del Júcar',
          'Festividad local de Poblete',
          'Festividad local de Huerta del Marquesado',
          'Festividad local de Poyatos',
          'Festividad local de Cendejas de Padrastro',
          'Festividad local de Mondéjar',
          'Festividad local de Valdeavellano',
          'Festividad local de Carranque',
          'Festividad local de El Viso de San Juan',
          'Festividad local de Escalonilla',
          'Festividad local de Garciotum',
          'Festividad local de Guadamur',
          'Festividad local de Layos',
          'Festividad local de Mascaraque',
          'Festividad local de Villamuelas',
          'Festividad local de Villasequilla',
        ],
        pc_ocupacion: 57.0,
        room: {
          double: {
            newPrice: 149.66,
            priceBefore: 148.29,
            priceNow: 146.07,
          },
          single: {
            newPrice: 121.13,
            priceBefore: 126.29,
            priceNow: 126.9,
          },
          suite: {
            newPrice: 282.34,
            priceBefore: 282.27,
            priceNow: 276.92,
          },
        },
      },
      {
        date: '2025-07-23',
        festivos: ['Festividad local de Castillejo de Iniesta'],
        pc_ocupacion: 49.0,
        room: {
          double: {
            newPrice: 115.15,
            priceBefore: 120.28,
            priceNow: 124.02,
          },
          single: {
            newPrice: 169.32,
            priceBefore: 166.77,
            priceNow: 160.81,
          },
          suite: {
            newPrice: 193.28,
            priceBefore: 201.56,
            priceNow: 203.82,
          },
        },
      },
      {
        date: '2025-07-24',
        festivos: [],
        pc_ocupacion: 51.0,
        room: {
          double: {
            newPrice: 173.07,
            priceBefore: 178.15,
            priceNow: 176.38,
          },
          single: {
            newPrice: 115.29,
            priceBefore: 118.62,
            priceNow: 124.86,
          },
          suite: {
            newPrice: 199.29,
            priceBefore: 205.84,
            priceNow: 220.41,
          },
        },
      },
      {
        date: '2025-07-25',
        festivos: [
          'Festividad local de Santa Ana',
          'Festividad local de Carrión de Calatrava',
          'Festividad local de Granátula de Calatrava',
          'Festividad local de La Solana',
          'Festividad local de Membrilla',
          'Festividad local de Viso del Marques',
          'Festividad local de Valsalobre',
          'Festividad local de Campisabalos',
          'Festividad local de Mohernando',
          'Festividad local de Valdelcubo',
          'Festividad local de Alcañizo',
          'Festividad local de Burujón',
          'Festividad local de Carpio de Tajo',
          'Festividad local de Espinoso del Rey',
          'Festividad local de Nombela',
          'Festividad local de Quero',
          'Festividad local de Santo Domingo Caudilla',
          'Festividad local de Sartajada',
          'Festividad local de Sotillo de las Palomas',
        ],
        pc_ocupacion: 45.0,
        room: {
          double: {
            newPrice: 120.88,
            priceBefore: 122.1,
            priceNow: 133.79,
          },
          single: {
            newPrice: 88.82,
            priceBefore: 91.2,
            priceNow: 95.71,
          },
          suite: {
            newPrice: 241.28,
            priceBefore: 237.06,
            priceNow: 254.48,
          },
        },
      },
      {
        date: '2025-07-26',
        festivos: [
          'Festividad local de Motilleja',
          'Festividad local de Pozo Lorente',
          'Festividad local de Santa Ana',
          'Festividad local de Almadén',
          'Festividad local de Cabezarados',
          'Festividad local de Fontanarejo',
          'Festividad local de Carrascosa del Campo',
          'Festividad local de El Pedernoso',
          'Festividad local de Gabaldon',
          'Festividad local de Pineda de Gigüela',
          'Festividad local de Reillo',
          'Festividad local de Valsalobre',
          'Festividad local de Vindel',
          'Festividad local de Alarilla',
          'Festividad local de Campisabalos',
          'Festividad local de Muduex',
          'Festividad local de Torremocha del Campo',
          'Festividad local de Añover de Tajo',
          'Festividad local de Burujón',
          'Festividad local de Camarenilla',
          'Festividad local de Mora',
          'Festividad local de Nombela',
          'Festividad local de Santa Ana de Pusa',
          'Festividad local de Santo Domingo Caudilla',
          'Festividad local de Villanueva de Bogas',
        ],
        pc_ocupacion: 77.0,
        room: {
          double: {
            newPrice: 149.61,
            priceBefore: 137.8,
            priceNow: 133.19,
          },
          single: {
            newPrice: 227.67,
            priceBefore: 221.22,
            priceNow: 225.32,
          },
          suite: {
            newPrice: 286.28,
            priceBefore: 290.78,
            priceNow: 283.87,
          },
        },
      },
      {
        date: '2025-07-27',
        festivos: [
          'Festividad local de Ribatajadilla',
          'Festividad local de Ciruelos del Pinar',
        ],
        pc_ocupacion: 69.0,
        room: {
          double: {
            newPrice: 211.99,
            priceBefore: 199.83,
            priceNow: 206.12,
          },
          single: {
            newPrice: 225.11,
            priceBefore: 218.76,
            priceNow: 234.17,
          },
          suite: {
            newPrice: 216.29,
            priceBefore: 223.2,
            priceNow: 223.14,
          },
        },
      },
      {
        date: '2025-07-28',
        festivos: [],
        pc_ocupacion: 64.0,
        room: {
          double: {
            newPrice: 155.46,
            priceBefore: 141.59,
            priceNow: 152.58,
          },
          single: {
            newPrice: 151.86,
            priceBefore: 157.68,
            priceNow: 168.93,
          },
          suite: {
            newPrice: 311.35,
            priceBefore: 315.63,
            priceNow: 331.72,
          },
        },
      },
      {
        date: '2025-07-29',
        festivos: [
          'Festividad local de Cabezarrubias del Puerto',
          'Festividad local de Mestanza',
          'Festividad local de Solana del Pino',
          'Festividad local de Cobisa',
          'Festividad local de Ontígola',
        ],
        pc_ocupacion: 69.0,
        room: {
          double: {
            newPrice: 179.59,
            priceBefore: 166.94,
            priceNow: 166.02,
          },
          single: {
            newPrice: 160.25,
            priceBefore: 153.13,
            priceNow: 155.87,
          },
          suite: {
            newPrice: 288.77,
            priceBefore: 267.49,
            priceNow: 286.18,
          },
        },
      },
      {
        date: '2025-07-30',
        festivos: [],
        pc_ocupacion: 53.0,
        room: {
          double: {
            newPrice: 155.59,
            priceBefore: 146.31,
            priceNow: 146.53,
          },
          single: {
            newPrice: 124.97,
            priceBefore: 119.21,
            priceNow: 130.85,
          },
          suite: {
            newPrice: 242.05,
            priceBefore: 246.71,
            priceNow: 269.47,
          },
        },
      },
      {
        date: '2025-07-31',
        festivos: ['Festividad local de Tribaldos'],
        pc_ocupacion: 57.0,
        room: {
          double: {
            newPrice: 190.93,
            priceBefore: 179.14,
            priceNow: 196.02,
          },
          single: {
            newPrice: 130.42,
            priceBefore: 119.81,
            priceNow: 122.96,
          },
          suite: {
            newPrice: 252.42,
            priceBefore: 262.8,
            priceNow: 259.16,
          },
        },
      },
      {
        date: '2025-08-01',
        festivos: [
          'Festividad local de San Pedro Palmiches',
          'Festividad local de Villalpardo',
          'Festividad local de Las Ventas de Retamosa',
        ],
        pc_ocupacion: 61.0,
        room: {
          double: {
            newPrice: 155.22,
            priceBefore: 158.19,
            priceNow: 169.91,
          },
          single: {
            newPrice: 183.7,
            priceBefore: 168.42,
            priceNow: 160.85,
          },
          suite: {
            newPrice: 234.43,
            priceBefore: 213.92,
            priceNow: 209.82,
          },
        },
      },
      {
        date: '2025-08-02',
        festivos: [
          'Festividad local de Calzada de Calatrava',
          'Festividad local de Pedro Muñoz',
          'Festividad local de Buciegas',
          'Festividad local de La Huerguina',
          'Festividad local de Villar del Águila',
          'Festividad local de Yemeda',
          'Festividad local de Amayas',
          'Festividad local de El Olivar',
          'Festividad local de El Ordial',
          'Festividad local de Escopete',
          'Festividad local de La Bodera',
          'Festividad local de Renera',
          'Festividad local de Robledo de Corpes',
          'Festividad local de Torre del Burgo',
          'Festividad local de Valdesotos',
          'Festividad local de Argés',
          'Festividad local de Los Cerralbos',
          'Festividad local de Montearagón',
        ],
        pc_ocupacion: 69.0,
        room: {
          double: {
            newPrice: 221.21,
            priceBefore: 216.8,
            priceNow: 217.95,
          },
          single: {
            newPrice: 168.3,
            priceBefore: 172.27,
            priceNow: 178.66,
          },
          suite: {
            newPrice: 258.48,
            priceBefore: 257.48,
            priceNow: 249.23,
          },
        },
      },
      {
        date: '2025-08-03',
        festivos: [
          'Festividad local de Amayas',
          'Festividad local de El Olivar',
          'Festividad local de Gualda',
          'Festividad local de La Bodera',
          'Festividad local de Bargas',
        ],
        pc_ocupacion: 66.0,
        room: {
          double: {
            newPrice: 198.43,
            priceBefore: 180.82,
            priceNow: 177.98,
          },
          single: {
            newPrice: 203.6,
            priceBefore: 196.66,
            priceNow: 215.97,
          },
          suite: {
            newPrice: 248.62,
            priceBefore: 240.93,
            priceNow: 246.28,
          },
        },
      },
      {
        date: '2025-08-04',
        festivos: [
          'Festividad local de Algar de Mesa',
          'Festividad local de Bañuelos',
          'Festividad local de Loranca de Tajuña',
          'Festividad local de Olmeda de Cobeta',
          'Festividad local de Somolinos',
          'Festividad local de Valdepinillos',
        ],
        pc_ocupacion: 47.0,
        room: {
          double: {
            newPrice: 182.68,
            priceBefore: 171.13,
            priceNow: 165.51,
          },
          single: {
            newPrice: 99.0,
            priceBefore: 94.57,
            priceNow: 96.75,
          },
          suite: {
            newPrice: 227.31,
            priceBefore: 214.82,
            priceNow: 219.33,
          },
        },
      },
      {
        date: '2025-08-05',
        festivos: [
          'Festividad local de Cenizate',
          'Festividad local de Chinchilla',
          'Festividad local de Almagro',
          'Festividad local de Valdepeñas',
          'Festividad local de Almodóvar del Pinar',
          'Festividad local de Tejadillos',
          'Festividad local de Torralba',
          'Festividad local de Villanueva de la Jara',
          'Festividad local de Anguita',
          'Festividad local de Membrillera',
          'Festividad local de Ocentejo',
          'Festividad local de Renera',
          'Festividad local de Valdesotos',
          'Festividad local de Valtablado de Rio',
          'Festividad local de Lagartera',
          'Festividad local de Malpica de Tajo',
          'Festividad local de Pulgar',
          'Festividad local de Quero',
          'Festividad local de Torralba de Oropesa',
        ],
        pc_ocupacion: 57.0,
        room: {
          double: {
            newPrice: 262.64,
            priceBefore: 240.65,
            priceNow: 236.89,
          },
          single: {
            newPrice: 133.46,
            priceBefore: 125.86,
            priceNow: 128.12,
          },
          suite: {
            newPrice: 209.94,
            priceBefore: 216.94,
            priceNow: 231.62,
          },
        },
      },
      {
        date: '2025-08-06',
        festivos: [
          'Festividad local de La Roda',
          'Festividad local de Socovos',
          'Festividad local de Pozuelo de Calatrava',
          'Festividad local de Olmedilla de Eliz',
          'Festividad local de Huérmeces del Cerro',
          'Festividad local de Málaga del Fresno',
          'Festividad local de Montarrón',
          'Festividad local de Puebla de Valles',
          'Festividad local de Santiuste',
          'Festividad local de Torralba de Oropesa',
        ],
        pc_ocupacion: 64.0,
        room: {
          double: {
            newPrice: 182.59,
            priceBefore: 166.25,
            priceNow: 173.18,
          },
          single: {
            newPrice: 238.95,
            priceBefore: 217.83,
            priceNow: 214.78,
          },
          suite: {
            newPrice: 222.56,
            priceBefore: 221.85,
            priceNow: 218.91,
          },
        },
      },
      {
        date: '2025-08-07',
        festivos: [
          'Festividad local de Tresjuncos',
          'Festividad local de Pradena de Atienza',
          'Festividad local de Somolinos',
          'Festividad local de Tordellego',
        ],
        pc_ocupacion: 46.0,
        room: {
          double: {
            newPrice: 135.5,
            priceBefore: 130.95,
            priceNow: 133.62,
          },
          single: {
            newPrice: 132.34,
            priceBefore: 124.77,
            priceNow: 120.77,
          },
          suite: {
            newPrice: 244.08,
            priceBefore: 231.31,
            priceNow: 231.56,
          },
        },
      },
      {
        date: '2025-08-08',
        festivos: [
          'Festividad local de Terrinches',
          'Festividad local de Moya',
          'Festividad local de Ocentejo',
          'Festividad local de Torremocha del Pinar',
          'Festividad local de Valtablado de Rio',
          'Festividad local de Valmojado',
        ],
        pc_ocupacion: 68.0,
        room: {
          double: {
            newPrice: 184.96,
            priceBefore: 182.98,
            priceNow: 192.13,
          },
          single: {
            newPrice: 237.5,
            priceBefore: 226.54,
            priceNow: 221.12,
          },
          suite: {
            newPrice: 260.04,
            priceBefore: 241.27,
            priceNow: 246.16,
          },
        },
      },
      {
        date: '2025-08-09',
        festivos: [
          'Festividad local de El Ballestero',
          'Festividad local de San Lorenzo de Calatrava',
          'Festividad local de Santa Cruz de Mudela',
          'Festividad local de Casas de Garcimolina',
          'Festividad local de Mota de Altarejos',
          'Festividad local de Naharros',
          'Festividad local de Adobes',
          'Festividad local de Albendiego',
          'Festividad local de Castellar de la Muela',
          'Festividad local de Peñalén',
          'Festividad local de Riofrio del Llano',
          'Festividad local de Tortuero',
          'Festividad local de Viñuelas',
          'Festividad local de Campillo de La Jara',
          'Festividad local de Parrillas',
        ],
        pc_ocupacion: 63.0,
        room: {
          double: {
            newPrice: 104.46,
            priceBefore: 107.03,
            priceNow: 115.36,
          },
          single: {
            newPrice: 187.56,
            priceBefore: 187.92,
            priceNow: 187.63,
          },
          suite: {
            newPrice: 275.5,
            priceBefore: 256.91,
            priceNow: 247.01,
          },
        },
      },
      {
        date: '2025-08-10',
        festivos: [
          'Festividad local de Socuéllamos',
          'Festividad local de Arguisuelas',
          'Festividad local de Bustares',
          'Festividad local de Carrascosa de Henares',
          'Festividad local de Negredo',
          'Festividad local de Riofrio del Llano',
          'Festividad local de Romanillos de Atienza',
          'Festividad local de Torremochuela',
        ],
        pc_ocupacion: 59.0,
        room: {
          double: {
            newPrice: 129.41,
            priceBefore: 133.71,
            priceNow: 140.52,
          },
          single: {
            newPrice: 124.07,
            priceBefore: 120.94,
            priceNow: 123.61,
          },
          suite: {
            newPrice: 363.8,
            priceBefore: 343.23,
            priceNow: 347.03,
          },
        },
      },
      {
        date: '2025-08-11',
        festivos: [
          'Festividad local de Anchuela del Campo',
          'Festividad local de Negredo',
        ],
        pc_ocupacion: 67.0,
        room: {
          double: {
            newPrice: 228.33,
            priceBefore: 237.13,
            priceNow: 254.9,
          },
          single: {
            newPrice: 113.28,
            priceBefore: 105.4,
            priceNow: 107.6,
          },
          suite: {
            newPrice: 258.92,
            priceBefore: 246.55,
            priceNow: 248.08,
          },
        },
      },
      {
        date: '2025-08-12',
        festivos: [
          'Festividad local de Chillón',
          'Festividad local de Beamud',
          'Festividad local de Cervera del Llano',
          'Festividad local de Mota de Altarejos',
          'Festividad local de Mota del Cuervo',
          'Festividad local de San Lorenzo de la Parrilla',
          'Festividad local de Villarrubio',
          'Festividad local de Arbeteta',
          'Festividad local de Campillo de Ranas',
          'Festividad local de Checa',
          'Festividad local de Concha',
          'Festividad local de Gárgoles de Abajo',
          'Festividad local de Hinojosa',
          'Festividad local de Mantiel',
          'Festividad local de Peralveche',
        ],
        pc_ocupacion: 61.0,
        room: {
          double: {
            newPrice: 92.02,
            priceBefore: 94.52,
            priceNow: 102.04,
          },
          single: {
            newPrice: 243.07,
            priceBefore: 240.5,
            priceNow: 233.67,
          },
          suite: {
            newPrice: 276.42,
            priceBefore: 268.85,
            priceNow: 275.38,
          },
        },
      },
      {
        date: '2025-08-13',
        festivos: [
          'Festividad local de Beamud',
          'Festividad local de Castillo de Garcimuñoz',
          'Festividad local de Villarrubio',
          'Festividad local de Estables',
          'Festividad local de Terzaga',
        ],
        pc_ocupacion: 61.0,
        room: {
          double: {
            newPrice: 187.46,
            priceBefore: 177.23,
            priceNow: 179.8,
          },
          single: {
            newPrice: 136.82,
            priceBefore: 127.15,
            priceNow: 136.92,
          },
          suite: {
            newPrice: 263.0,
            priceBefore: 263.26,
            priceNow: 272.05,
          },
        },
      },
      {
        date: '2025-08-14',
        festivos: [
          'Festividad local de Jorquera',
          'Festividad local de Castillo de Garcimuñoz',
          'Festividad local de San Martin de Boniches',
          'Festividad local de Uña',
          'Festividad local de Campillo de Ranas',
          'Festividad local de Congostrina',
          'Festividad local de La Mierla',
          'Festividad local de Las Navas de Jadraque',
          'Festividad local de Prados Redondos',
          'Festividad local de Rebollosa de Jadraque',
          'Festividad local de Retiendas',
          'Festividad local de Sayatón',
          'Festividad local de Terzaga',
          'Festividad local de Uceda',
          'Festividad local de Alameda de la Sagra',
          'Festividad local de Bernuy',
          'Festividad local de Fuentes',
          'Festividad local de La Estrella',
          'Festividad local de Romeral',
        ],
        pc_ocupacion: 66.0,
        room: {
          double: {
            newPrice: 222.17,
            priceBefore: 213.98,
            priceNow: 228.66,
          },
          single: {
            newPrice: 195.29,
            priceBefore: 178.87,
            priceNow: 193.28,
          },
          suite: {
            newPrice: 261.75,
            priceBefore: 256.9,
            priceNow: 245.84,
          },
        },
      },
      {
        date: '2025-08-15',
        festivos: [
          'Asunción de la Virgen',
          'Festividad local de Hontecillas',
          'Festividad local de Anchuela del Pedregal',
          'Festividad local de Arbeteta',
          'Festividad local de Gascueña de Bornova',
          'Festividad local de Peralveche',
          'Festividad local de Tordelpalo',
        ],
        pc_ocupacion: 69.0,
        room: {
          double: {
            newPrice: 235.19,
            priceBefore: 221.21,
            priceNow: 232.12,
          },
          single: {
            newPrice: 163.44,
            priceBefore: 149.29,
            priceNow: 147.51,
          },
          suite: {
            newPrice: 263.69,
            priceBefore: 269.23,
            priceNow: 283.48,
          },
        },
      },
      {
        date: '2025-08-16',
        festivos: [
          'Festividad local de Barrax',
          'Festividad local de Bormate',
          'Festividad local de Fuentealbilla',
          'Festividad local de Letur',
          'Festividad local de Salobre',
          'Festividad local de Tobarra',
          'Festividad local de Villalgordo del Júcar',
          'Festividad local de Villarrobledo',
          'Festividad local de Villatoya',
          'Festividad local de Alamillo',
          'Festividad local de Alcolea de Calatrava',
          'Festividad local de Moral de Calatrava',
          'Festividad local de Torre de Juan Abad',
          'Festividad local de Aliaguilla',
          'Festividad local de Arcos de la Cantera',
          'Festividad local de Barbalimpia',
          'Festividad local de Bascuñana de San Pedro',
          'Festividad local de Bolliga',
          'Festividad local de Boniches',
          'Festividad local de Buenache de la Sierra',
          'Festividad local de Campillos Paravientos',
          'Festividad local de Cañamares',
          'Festividad local de Carboneras de Guadazaon',
          'Festividad local de Castillejo-Sierra',
          'Festividad local de El Provencio',
          'Festividad local de Enguidanos',
          'Festividad local de Fuentesbuenas',
          'Festividad local de Garcinarro',
          'Festividad local de Henarejos',
          'Festividad local de La Pesquera',
          'Festividad local de Lagunaseca',
          'Festividad local de Landete',
          'Festividad local de Ledaña',
          'Festividad local de Monteagudo de las Salinas',
          'Festividad local de Navalon',
          'Festividad local de Olivares del Jucar',
          'Festividad local de Olmedilla de Alarcon',
          'Festividad local de Olmedilla del Campo',
          'Festividad local de Pajaroncillo',
          'Festividad local de Portalrubio de Guadamejud',
          'Festividad local de Portilla',
          'Festividad local de Poyatos',
          'Festividad local de Rada de Haro',
          'Festividad local de Reillo',
          'Festividad local de Salinas del Manzano',
          'Festividad local de Sotoca',
          'Festividad local de Tondos',
          'Festividad local de Torrecilla',
          'Festividad local de Uña',
          'Festividad local de Valdecabras',
          'Festividad local de Valdemorillo de la Sierra',
          'Festividad local de Valeria',
          'Festividad local de Villaescusa de Haro',
          'Festividad local de Villalba de la Sierra',
          'Festividad local de Villanueva de los Escuderos',
          'Festividad local de Villar de la Encina',
          'Festividad local de Villar del Infantado',
          'Festividad local de Villarejo del Espartal',
          'Festividad local de Villarejo Seco',
          'Festividad local de Villarta',
          'Festividad local de Villora',
          'Festividad local de Vindel',
          'Festividad local de Alcocer',
          'Festividad local de Alcolea de las Peñas',
          'Festividad local de Alcolea del Pinar',
          'Festividad local de Aldeanueva de Atienza',
          'Festividad local de Aldeanueva de Guadalajara',
          'Festividad local de Alique',
          'Festividad local de Alocén',
          'Festividad local de Anquela del Pedregal',
          'Festividad local de Baides',
          'Festividad local de Brihuega',
          'Festividad local de Bujarrabal',
          'Festividad local de Bustares',
          'Festividad local de Canales del Ducado',
          'Festividad local de Cincovillas',
          'Festividad local de Cívica',
          'Festividad local de Condemios de Arriba',
          'Festividad local de Copernal',
          'Festividad local de Durón',
          'Festividad local de El Sotillo',
          'Festividad local de Fontanar',
          'Festividad local de Fuentelsaz',
          'Festividad local de Fuentelviejo',
          'Festividad local de Gárgoles de Abajo',
          'Festividad local de Gascueña de Bornova',
          'Festividad local de Illana',
          'Festividad local de Las Navas de Jadraque',
          'Festividad local de Mazarete',
          'Festividad local de Megina',
          'Festividad local de Olmeda de Cobeta',
          'Festividad local de Piqueras',
          'Festividad local de Poveda de la Sierra',
          'Festividad local de Rebollosa de Jadraque',
          'Festividad local de San Andrés del Congosto',
          'Festividad local de San Andrés del Rey',
          'Festividad local de Sayatón',
          'Festividad local de Selas',
          'Festividad local de Semillas',
          'Festividad local de Sienes',
          'Festividad local de Sigüenza',
          'Festividad local de Tierzo',
          'Festividad local de Tordelpalo',
          'Festividad local de Tordelrábano',
          'Festividad local de Torrejón del Rey',
          'Festividad local de Uceda',
          'Festividad local de Valdearenas',
          'Festividad local de Yélamos de Arriba',
          'Festividad local de Zarzuela de Jadraque',
          'Festividad local de Alameda de la Sagra',
          'Festividad local de Camarena',
          'Festividad local de Camuñas',
          'Festividad local de Casar de Escalona',
          'Festividad local de Cervera de los Montes',
          'Festividad local de Erustes',
          'Festividad local de Escalona',
          'Festividad local de Las Navillas',
          'Festividad local de Los Alares',
          'Festividad local de Magán',
          'Festividad local de Mazarambroz',
          'Festividad local de Mohedas de La Jara',
          'Festividad local de Navalcán',
          'Festividad local de Nuño Gómez',
          'Festividad local de Palomeque',
          'Festividad local de Quintanar de la Orden',
          'Festividad local de Quismondo',
          'Festividad local de San Román de los Montes',
          'Festividad local de Seseña',
          'Festividad local de Valdeverdeja',
        ],
        pc_ocupacion: 63.0,
        room: {
          double: {
            newPrice: 165.32,
            priceBefore: 163.03,
            priceNow: 175.25,
          },
          single: {
            newPrice: 209.38,
            priceBefore: 192.42,
            priceNow: 201.33,
          },
          suite: {
            newPrice: 254.63,
            priceBefore: 237.26,
            priceNow: 235.85,
          },
        },
      },
      {
        date: '2025-08-17',
        festivos: [
          'Festividad local de Hijes',
          'Festividad local de Mazarete',
          'Festividad local de Piqueras',
          'Festividad local de Poveda de la Sierra',
          'Festividad local de Taravilla',
        ],
        pc_ocupacion: 53.0,
        room: {
          double: {
            newPrice: 197.68,
            priceBefore: 194.17,
            priceNow: 191.17,
          },
          single: {
            newPrice: 99.85,
            priceBefore: 99.89,
            priceNow: 102.73,
          },
          suite: {
            newPrice: 218.48,
            priceBefore: 221.88,
            priceNow: 226.52,
          },
        },
      },
      {
        date: '2025-08-18',
        festivos: [
          'Festividad local de Castilnuevo',
          'Festividad local de El Ordial',
          'Festividad local de Estriegana',
          'Festividad local de La Toba',
          'Festividad local de Taravilla',
          'Festividad local de Torrecuadrada de Molina',
        ],
        pc_ocupacion: 52.0,
        room: {
          double: {
            newPrice: 189.13,
            priceBefore: 178.22,
            priceNow: 176.63,
          },
          single: {
            newPrice: 132.37,
            priceBefore: 128.39,
            priceNow: 135.41,
          },
          suite: {
            newPrice: 198.09,
            priceBefore: 207.33,
            priceNow: 201.5,
          },
        },
      },
      {
        date: '2025-08-19',
        festivos: [
          'Festividad local de Nerpio',
          'Festividad local de Membrilla',
          'Festividad local de El Provencio',
          'Festividad local de Moya',
          'Festividad local de Tinajas',
          'Festividad local de Torrubia del Castillo',
          'Festividad local de Villar de Domingo Garcia',
          'Festividad local de Alocén',
          'Festividad local de Estriegana',
          'Festividad local de Labros',
          'Festividad local de Tamajón',
          'Festividad local de Esquivias',
          'Festividad local de Retamoso de la Jara',
          'Festividad local de Valdeverdeja',
        ],
        pc_ocupacion: 90.0,
        room: {
          double: {
            newPrice: 245.94,
            priceBefore: 232.99,
            priceNow: 231.36,
          },
          single: {
            newPrice: 247.9,
            priceBefore: 240.46,
            priceNow: 229.0,
          },
          suite: {
            newPrice: 304.59,
            priceBefore: 289.63,
            priceNow: 307.15,
          },
        },
      },
      {
        date: '2025-08-20',
        festivos: [
          'Festividad local de Bogarra',
          'Festividad local de Letur',
          'Festividad local de Nerpio',
          'Festividad local de Hinojosas de Calatrava',
          'Festividad local de Campillos-Sierra',
          'Festividad local de La Hinojosa',
          'Festividad local de Torrubia del Castillo',
          'Festividad local de El Pobo de Dueñas',
          'Festividad local de Fuembellida',
          'Festividad local de Tamajón',
          'Festividad local de Esquivias',
        ],
        pc_ocupacion: 67.0,
        room: {
          double: {
            newPrice: 162.9,
            priceBefore: 151.33,
            priceNow: 145.05,
          },
          single: {
            newPrice: 196.74,
            priceBefore: 193.7,
            priceNow: 195.79,
          },
          suite: {
            newPrice: 264.44,
            priceBefore: 277.22,
            priceNow: 280.35,
          },
        },
      },
      {
        date: '2025-08-21',
        festivos: [
          'Festividad local de Iniesta',
          'Festividad local de Cubillejo de la Sierra',
          'Festividad local de Fuembellida',
        ],
        pc_ocupacion: 54.0,
        room: {
          double: {
            newPrice: 104.51,
            priceBefore: 109.03,
            priceNow: 106.66,
          },
          single: {
            newPrice: 160.16,
            priceBefore: 148.69,
            priceNow: 141.45,
          },
          suite: {
            newPrice: 287.26,
            priceBefore: 264.45,
            priceNow: 269.64,
          },
        },
      },
      {
        date: '2025-08-22',
        festivos: [
          'Festividad local de Ciudad Real',
          'Festividad local de San Clemente',
          'Festividad local de Alcoroches',
          'Festividad local de La Olmeda de Jadraque',
          'Festividad local de Morenilla',
          'Festividad local de Torremocha de Jadraque',
        ],
        pc_ocupacion: 67.0,
        room: {
          double: {
            newPrice: 188.71,
            priceBefore: 179.84,
            priceNow: 174.83,
          },
          single: {
            newPrice: 128.52,
            priceBefore: 121.73,
            priceNow: 130.9,
          },
          suite: {
            newPrice: 296.58,
            priceBefore: 299.66,
            priceNow: 317.05,
          },
        },
      },
      {
        date: '2025-08-23',
        festivos: [
          'Festividad local de Madrigueras',
          'Festividad local de Pozuelo',
          'Festividad local de Reolid',
          'Festividad local de Yeste',
          'Festividad local de Los Pozuelos de Calatrava',
          'Festividad local de Atalaya del Cañavate',
          'Festividad local de Fresneda de Altarejos',
          'Festividad local de La Almarcha',
          'Festividad local de Laguna del Marquesado',
          'Festividad local de Torrejoncillo del Rey',
          'Festividad local de Alcoroches',
          'Festividad local de Arbancón',
          'Festividad local de Armuña de Tajuña',
          'Festividad local de Casa de Uceda',
          'Festividad local de Galápagos',
          'Festividad local de Galve de Sorbe',
          'Festividad local de Henche',
          'Festividad local de Hita',
          'Festividad local de Morenilla',
          'Festividad local de Tartanedo',
          'Festividad local de Torremocha de Jadraque',
          'Festividad local de Valdepeñas de la Sierra',
          'Festividad local de Yebes',
          'Festividad local de Yélamos de Arriba',
          'Festividad local de Aldeanueva de San Bartolomé',
          'Festividad local de Cabañas de Yepes',
          'Festividad local de Gargantilla',
          'Festividad local de Marrupe',
          'Festividad local de Puerto de San Vicente',
          'Festividad local de San Bartolomé de las Abiertas',
        ],
        pc_ocupacion: 77.0,
        room: {
          double: {
            newPrice: 299.43,
            priceBefore: 289.36,
            priceNow: 290.07,
          },
          single: {
            newPrice: 124.87,
            priceBefore: 121.81,
            priceNow: 115.89,
          },
          suite: {
            newPrice: 267.04,
            priceBefore: 256.58,
            priceNow: 253.09,
          },
        },
      },
      {
        date: '2025-08-24',
        festivos: [
          'Festividad local de Tarazona de la Mancha',
          'Festividad local de Almagro',
          'Festividad local de Campo de Criptana',
          'Festividad local de Arcos de la Sierra',
          'Festividad local de Casasimarro',
          'Festividad local de Castillejo del Romeral',
          'Festividad local de Colliga',
          'Festividad local de Villarejo de la Peñuela',
          'Festividad local de Campillo de Dueñas',
          'Festividad local de El Cubillo de Uceda',
          'Festividad local de Jirueque',
          'Festividad local de Lupiana',
          'Festividad local de Matarrubia',
          'Festividad local de Rillo de Gallo',
          'Festividad local de El Viso de San Juan',
          'Festividad local de Navahermosa',
        ],
        pc_ocupacion: 52.0,
        room: {
          double: {
            newPrice: 203.25,
            priceBefore: 192.61,
            priceNow: 189.11,
          },
          single: {
            newPrice: 146.07,
            priceBefore: 153.39,
            priceNow: 163.56,
          },
          suite: {
            newPrice: 181.04,
            priceBefore: 173.6,
            priceNow: 180.32,
          },
        },
      },
      {
        date: '2025-08-25',
        festivos: ['Festividad local de Rillo de Gallo'],
        pc_ocupacion: 49.0,
        room: {
          double: {
            newPrice: 176.53,
            priceBefore: 178.4,
            priceNow: 194.95,
          },
          single: {
            newPrice: 135.65,
            priceBefore: 130.03,
            priceNow: 130.29,
          },
          suite: {
            newPrice: 191.9,
            priceBefore: 201.9,
            priceNow: 219.56,
          },
        },
      },
      {
        date: '2025-08-26',
        festivos: [
          'Festividad local de Carcelén',
          'Festividad local de Ballesteros de Calatrava',
          'Festividad local de Navalpino',
          'Festividad local de Alcohujate',
          'Festividad local de Belmonte',
          'Festividad local de Casas de Fernando Alonso',
          'Festividad local de Casas de Haro',
          'Festividad local de Castillejo del Romeral',
          'Festividad local de Fresneda de Altarejos',
          'Festividad local de Gascueña',
          'Festividad local de Piqueras del Castillo',
          'Festividad local de Pozorrubio de Santiago',
          'Festividad local de Ucles',
          'Festividad local de Vellisca',
          'Festividad local de Checa',
          'Festividad local de Cubillejo del Sitio',
          'Festividad local de Hita',
          'Festividad local de Sacecorbo',
          'Festividad local de Valfermoso de Tajuña',
          'Festividad local de Alcañizo',
          'Festividad local de Aldeanueva de Barbarroya',
          'Festividad local de Aldeanueva de San Bartolomé',
          'Festividad local de Añover de Tajo',
          'Festividad local de Arisgotas',
          'Festividad local de Cabañas de Yepes',
          'Festividad local de Corral de Almaguer',
          'Festividad local de Hormigos',
          'Festividad local de Huerta de Valdecarábanos',
          'Festividad local de Mesegar de Tajo',
          'Festividad local de Orgaz',
          'Festividad local de San Bartolomé de las Abiertas',
          'Festividad local de Santa Cruz de la Zarza',
          'Festividad local de Torrecilla de La Jara',
        ],
        pc_ocupacion: 68.0,
        room: {
          double: {
            newPrice: 236.39,
            priceBefore: 231.47,
            priceNow: 244.45,
          },
          single: {
            newPrice: 188.99,
            priceBefore: 189.87,
            priceNow: 199.45,
          },
          suite: {
            newPrice: 230.3,
            priceBefore: 229.42,
            priceNow: 220.53,
          },
        },
      },
      {
        date: '2025-08-27',
        festivos: [
          'Festividad local de Paredes de Sigüenza',
          'Festividad local de Valfermoso de Tajuña',
          'Festividad local de Albarreal de Tajo',
          'Festividad local de Huerta de Valdecarábanos',
        ],
        pc_ocupacion: 43.0,
        room: {
          double: {
            newPrice: 130.63,
            priceBefore: 122.26,
            priceNow: 125.82,
          },
          single: {
            newPrice: 121.31,
            priceBefore: 118.69,
            priceNow: 124.36,
          },
          suite: {
            newPrice: 219.8,
            priceBefore: 223.61,
            priceNow: 241.26,
          },
        },
      },
      {
        date: '2025-08-28',
        festivos: [
          'Festividad local de Casas Ibáñez',
          'Festividad local de Peñas de San Pedro',
          'Festividad local de Fernán Caballero',
          'Festividad local de Villahermosa',
          'Festividad local de Castillejo de Iniesta',
          'Festividad local de Collados',
          'Festividad local de Mota del Cuervo',
          'Festividad local de Zafrilla',
          'Festividad local de Atanzón',
          'Festividad local de Fuentelencina',
          'Festividad local de Hombrados',
          'Festividad local de Membrillera',
          'Festividad local de Albarreal de Tajo',
          'Festividad local de El Toboso',
        ],
        pc_ocupacion: 62.0,
        room: {
          double: {
            newPrice: 131.84,
            priceBefore: 137.2,
            priceNow: 146.4,
          },
          single: {
            newPrice: 148.37,
            priceBefore: 140.89,
            priceNow: 154.08,
          },
          suite: {
            newPrice: 330.76,
            priceBefore: 302.98,
            priceNow: 293.81,
          },
        },
      },
      {
        date: '2025-08-29',
        festivos: [
          'Festividad local de Puebla de Don Rodrigo',
          'Festividad local de Aranzueque',
          'Festividad local de Espinosa de Henares',
          'Festividad local de La Yunta',
          'Festividad local de Millana',
          'Festividad local de Borox',
        ],
        pc_ocupacion: 67.0,
        room: {
          double: {
            newPrice: 151.38,
            priceBefore: 159.18,
            priceNow: 172.27,
          },
          single: {
            newPrice: 218.26,
            priceBefore: 201.45,
            priceNow: 217.75,
          },
          suite: {
            newPrice: 288.91,
            priceBefore: 268.41,
            priceNow: 258.03,
          },
        },
      },
      {
        date: '2025-08-30',
        festivos: [
          'Festividad local de Huelamo',
          'Festividad local de Pajaron',
          'Festividad local de Espinosa de Henares',
          'Festividad local de Pinilla de Jadraque 5 febrero y',
          'Festividad local de Quer',
          'Festividad local de Saelices de la Sal',
          'Festividad local de Solanillos del Extremo',
          'Festividad local de Torrecuadradilla',
          'Festividad local de Valdeaveruelo',
          'Festividad local de Villares de Jadraque',
          'Festividad local de Viñuelas',
          'Festividad local de Aldea en Cabo',
          'Festividad local de Borox',
          'Festividad local de Las Ventas de San Julian',
          'Festividad local de Torrico',
          'Festividad local de Villaluenga de la Sagra',
        ],
        pc_ocupacion: 54.0,
        room: {
          double: {
            newPrice: 187.57,
            priceBefore: 185.79,
            priceNow: 180.4,
          },
          single: {
            newPrice: 130.75,
            priceBefore: 133.41,
            priceNow: 130.65,
          },
          suite: {
            newPrice: 220.88,
            priceBefore: 201.11,
            priceNow: 213.43,
          },
        },
      },
      {
        date: '2025-08-31',
        festivos: [
          'Festividad local de Tomelloso',
          'Festividad local de Ciruelos del Pinar',
          'Festividad local de Illescas',
          'Festividad local de Villarejo de Montalbán',
        ],
        pc_ocupacion: 48.0,
        room: {
          double: {
            newPrice: 112.94,
            priceBefore: 107.47,
            priceNow: 107.1,
          },
          single: {
            newPrice: 180.59,
            priceBefore: 184.91,
            priceNow: 181.16,
          },
          suite: {
            newPrice: 163.81,
            priceBefore: 158.37,
            priceNow: 151.25,
          },
        },
      },
      {
        date: '2025-09-01',
        festivos: [
          'Festividad local de Cuevas de Velasco',
          'Festividad local de Villar del Maestre',
        ],
        pc_ocupacion: 57.0,
        room: {
          double: {
            newPrice: 173.39,
            priceBefore: 178.81,
            priceNow: 182.1,
          },
          single: {
            newPrice: 125.62,
            priceBefore: 131.49,
            priceNow: 133.38,
          },
          suite: {
            newPrice: 242.95,
            priceBefore: 241.49,
            priceNow: 238.63,
          },
        },
      },
      {
        date: '2025-09-02',
        festivos: [
          'Festividad local de Almansa',
          'Festividad local de Argamasilla de Alba',
          'Festividad local de Daimiel',
          'Festividad local de Arrancacepas',
          'Festividad local de Cueva del Hierro',
          'Festividad local de Las Pedroñeras',
          'Festividad local de Motilla del Palancar',
          'Festividad local de Pajaroncillo',
          'Festividad local de Valhermoso de la Fuente',
          'Festividad local de Villalba del Rey',
          'Festividad local de Caspueñas',
          'Festividad local de El Casar',
          'Festividad local de Molina de Aragón',
          'Festividad local de Solanillos del Extremo',
          'Festividad local de Burguillos de Toledo',
          'Festividad local de Hontanar',
          'Festividad local de La Fresneda de la Jara',
          'Festividad local de La Puebla de Almoradiel',
          'Festividad local de Las Ventas de San Julian',
          'Festividad local de Torrico',
        ],
        pc_ocupacion: 73.0,
        room: {
          double: {
            newPrice: 187.69,
            priceBefore: 183.6,
            priceNow: 195.88,
          },
          single: {
            newPrice: 156.95,
            priceBefore: 156.34,
            priceNow: 162.57,
          },
          suite: {
            newPrice: 316.01,
            priceBefore: 326.98,
            priceNow: 315.22,
          },
        },
      },
      {
        date: '2025-09-03',
        festivos: [
          'Festividad local de El Robledo',
          'Festividad local de Cueva del Hierro',
        ],
        pc_ocupacion: 49.0,
        room: {
          double: {
            newPrice: 168.64,
            priceBefore: 163.36,
            priceNow: 170.99,
          },
          single: {
            newPrice: 169.0,
            priceBefore: 160.82,
            priceNow: 156.43,
          },
          suite: {
            newPrice: 186.62,
            priceBefore: 185.99,
            priceNow: 183.48,
          },
        },
      },
      {
        date: '2025-09-04',
        festivos: ['Festividad local de Ayna'],
        pc_ocupacion: 53.0,
        room: {
          double: {
            newPrice: 169.84,
            priceBefore: 174.55,
            priceNow: 186.1,
          },
          single: {
            newPrice: 171.21,
            priceBefore: 163.33,
            priceNow: 159.11,
          },
          suite: {
            newPrice: 242.18,
            priceBefore: 222.94,
            priceNow: 220.47,
          },
        },
      },
      {
        date: '2025-09-05',
        festivos: [
          'Festividad local de Alcaraz',
          'Festividad local de Daimiel',
          'Festividad local de Villamayor de Santiago',
          'Festividad local de Corduente',
        ],
        pc_ocupacion: 61.0,
        room: {
          double: {
            newPrice: 153.62,
            priceBefore: 159.79,
            priceNow: 154.51,
          },
          single: {
            newPrice: 174.81,
            priceBefore: 171.73,
            priceNow: 183.5,
          },
          suite: {
            newPrice: 230.64,
            priceBefore: 221.21,
            priceNow: 219.62,
          },
        },
      },
      {
        date: '2025-09-06',
        festivos: [
          'Festividad local de Ayna',
          'Festividad local de Bienservida',
          'Festividad local de Puertollano',
          'Festividad local de Almendros',
          'Festividad local de El Cañavate',
          'Festividad local de Garaballa',
          'Festividad local de Salvacañete',
          'Festividad local de Villalba de la Sierra',
          'Festividad local de Villarejo Sobrehuerta',
          'Festividad local de Alovera',
          'Festividad local de Aragosa',
          'Festividad local de Casa de Uceda',
          'Festividad local de Corduente',
          'Festividad local de Malaguilla',
          'Festividad local de Mandayona',
          'Festividad local de Miralrío',
          'Festividad local de Orea',
          'Festividad local de Pareja',
          'Festividad local de Peñalver',
          'Festividad local de Riba de Saelices',
          'Festividad local de Romanones',
          'Festividad local de Trillo',
          'Festividad local de Carranque',
          'Festividad local de El Bercial',
          'Festividad local de Talavera de la Reina',
          'Festividad local de Talavera la Nueva',
          'Festividad local de Velada',
          'Festividad local de Villarrubia de Santiago',
        ],
        pc_ocupacion: 65.0,
        room: {
          double: {
            newPrice: 172.52,
            priceBefore: 177.18,
            priceNow: 175.64,
          },
          single: {
            newPrice: 186.15,
            priceBefore: 179.13,
            priceNow: 184.04,
          },
          suite: {
            newPrice: 286.57,
            priceBefore: 265.16,
            priceNow: 269.1,
          },
        },
      },
      {
        date: '2025-09-07',
        festivos: [
          'Festividad local de Minaya',
          'Festividad local de Alhambra',
          'Festividad local de Cereceda',
          'Festividad local de Majaelrayo',
          'Festividad local de Puebla de Beleña',
          'Festividad local de El Bercial',
        ],
        pc_ocupacion: 73.0,
        room: {
          double: {
            newPrice: 189.18,
            priceBefore: 179.9,
            priceNow: 180.15,
          },
          single: {
            newPrice: 200.21,
            priceBefore: 187.31,
            priceNow: 190.89,
          },
          suite: {
            newPrice: 267.69,
            priceBefore: 267.06,
            priceNow: 283.59,
          },
        },
      },
      {
        date: '2025-09-08',
        festivos: [
          'Festividad local de Las Majadas',
          'Festividad local de Ribatajada',
          'Festividad local de Algar de Mesa',
          'Festividad local de Alhóndiga',
          'Festividad local de Campillo de Dueñas',
          'Festividad local de Casasana',
          'Festividad local de Cereceda',
          'Festividad local de El Recuenco',
          'Festividad local de Miedes de Atienza',
          'Festividad local de Monasterio',
          'Festividad local de Ventosa',
          'Festividad local de Zaorejas',
        ],
        pc_ocupacion: 68.0,
        room: {
          double: {
            newPrice: 189.35,
            priceBefore: 184.87,
            priceNow: 179.46,
          },
          single: {
            newPrice: 153.91,
            priceBefore: 148.14,
            priceNow: 143.4,
          },
          suite: {
            newPrice: 298.6,
            priceBefore: 278.96,
            priceNow: 280.49,
          },
        },
      },
      {
        date: '2025-09-09',
        festivos: [
          'Festividad local de Albacete',
          'Festividad local de Alborea',
          'Festividad local de Caudete',
          'Festividad local de Fuensanta',
          'Festividad local de Aldea del Rey',
          'Festividad local de Argamasilla de Calatrava',
          'Festividad local de Calzada de Calatrava',
          'Festividad local de Fuencaliente',
          'Festividad local de Llanos del Caudillo',
          'Festividad local de Luciana',
          'Festividad local de Miguelturra',
          'Festividad local de Puebla del Príncipe',
          'Festividad local de Valdemanco del Esteras',
          'Festividad local de Villahermosa',
          'Festividad local de Albendea',
          'Festividad local de Alcohujate',
          'Festividad local de Almendros',
          'Festividad local de Altarejos',
          'Festividad local de Barajas de Melo',
          'Festividad local de Belmonte',
          'Festividad local de Buendía',
          'Festividad local de Campillo de Altobuey',
          'Festividad local de Canalejas del Arroyo',
          'Festividad local de Cañada del Hoyo',
          'Festividad local de Cañete',
          'Festividad local de Cañizares',
          'Festividad local de Culebras',
          'Festividad local de El Cañavate',
          'Festividad local de El Herrumblar',
          'Festividad local de Horcajada de la Torre',
          'Festividad local de Huerta de la Obispalía',
          'Festividad local de La Peraleja',
          'Festividad local de Lagunaseca',
          'Festividad local de Loranca del Campo',
          'Festividad local de Puebla de Almenara',
          'Festividad local de Ribagorda',
          'Festividad local de Santa Maria del Campo Rus',
          'Festividad local de Tarancon',
          'Festividad local de Torrubia del Campo',
          'Festividad local de Vega del Codorno',
          'Festividad local de Villanueva de Guadamejud',
          'Festividad local de Villar de Cañas',
          'Festividad local de Villar de Olalla',
          'Festividad local de Villar del Horno',
          'Festividad local de Villarejo de Fuentes',
          'Festividad local de Balbacil',
          'Festividad local de Casasana',
          'Festividad local de Clares',
          'Festividad local de Cobeta',
          'Festividad local de Codes',
          'Festividad local de Condemios de Abajo',
          'Festividad local de Hontoba',
          'Festividad local de Horche',
          'Festividad local de Illana',
          'Festividad local de Maranchón',
          'Festividad local de Monasterio',
          'Festividad local de Pálmaces de Jadraque',
          'Festividad local de Pareja',
          'Festividad local de Peñalver',
          'Festividad local de Tendilla',
          'Festividad local de Torija',
          'Festividad local de Tortuera',
          'Festividad local de Trillo',
          'Festividad local de Turmiel',
          'Festividad local de Ventosa',
          'Festividad local de Yebra',
          'Festividad local de Alcabón',
          'Festividad local de Arisgotas',
          'Festividad local de Carriches',
          'Festividad local de Castillo de Bayuela',
          'Festividad local de Cebolla',
          'Festividad local de Cedillo del Condado',
          'Festividad local de Consuegra',
          'Festividad local de Domingo Pérez',
          'Festividad local de Espinoso del Rey',
          'Festividad local de Gamonal',
          'Festividad local de Las Hunfrías',
          'Festividad local de Los Navalucillos',
          'Festividad local de Lillo',
          'Festividad local de Méntrida',
          'Festividad local de Miguel Esteban',
          'Festividad local de Mocejón',
          'Festividad local de Navamorcuende',
          'Festividad local de Ocaña',
          'Festividad local de Orgaz',
          'Festividad local de Oropesa y Corchuela',
          'Festividad local de Paredes de Escalona',
          'Festividad local de Pepino',
          'Festividad local de Recas',
          'Festividad local de Sonseca',
          'Festividad local de Turleque',
          'Festividad local de Villamiel',
          'Festividad local de Villanueva de Bogas',
          'Festividad local de Villarrubia de Santiago',
          'Festividad local de Villaseca de la Sagra',
        ],
        pc_ocupacion: 48.0,
        room: {
          double: {
            newPrice: 218.41,
            priceBefore: 215.51,
            priceNow: 232.67,
          },
          single: {
            newPrice: 130.53,
            priceBefore: 119.37,
            priceNow: 115.85,
          },
          suite: {
            newPrice: 160.64,
            priceBefore: 158.9,
            priceNow: 152.95,
          },
        },
      },
      {
        date: '2025-09-10',
        festivos: [
          'Festividad local de Barajas de Melo',
          'Festividad local de Cañizares',
          'Festividad local de Vega del Codorno',
          'Festividad local de Almonacid de Zorita',
          'Festividad local de Horche',
          'Festividad local de Pálmaces de Jadraque',
          'Festividad local de Tendilla',
          'Festividad local de Torija',
          'Festividad local de Tortuera',
          'Festividad local de Valdelcubo',
          'Festividad local de Los Navalucillos',
          'Festividad local de Ocaña',
        ],
        pc_ocupacion: 70.0,
        room: {
          double: {
            newPrice: 175.85,
            priceBefore: 175.55,
            priceNow: 187.97,
          },
          single: {
            newPrice: 207.72,
            priceBefore: 192.66,
            priceNow: 209.84,
          },
          suite: {
            newPrice: 276.08,
            priceBefore: 268.81,
            priceNow: 280.87,
          },
        },
      },
      {
        date: '2025-09-11',
        festivos: [
          'Festividad local de Chumillas',
          'Festividad local de Hontanaya',
          'Festividad local de Pinarejo',
          'Festividad local de Almonacid de Zorita',
          'Festividad local de Almendral de La Cañada',
          'Festividad local de Retamoso de la Jara',
          'Festividad local de Villa de Don Fadrique',
        ],
        pc_ocupacion: 62.0,
        room: {
          double: {
            newPrice: 198.7,
            priceBefore: 208.05,
            priceNow: 205.49,
          },
          single: {
            newPrice: 117.72,
            priceBefore: 120.3,
            priceNow: 118.17,
          },
          suite: {
            newPrice: 282.42,
            priceBefore: 262.43,
            priceNow: 269.61,
          },
        },
      },
      {
        date: '2025-09-12',
        festivos: [
          'Festividad local de El Pozuelo',
          'Festividad local de Priego',
          'Festividad local de Guadalajara',
          'Festividad local de Calera y Chozas',
          'Festividad local de Fuensalida',
          'Festividad local de Villatobas',
          'Festividad local de Yuncos',
        ],
        pc_ocupacion: 66.0,
        room: {
          double: {
            newPrice: 217.26,
            priceBefore: 222.09,
            priceNow: 244.0,
          },
          single: {
            newPrice: 111.78,
            priceBefore: 114.27,
            priceNow: 123.74,
          },
          suite: {
            newPrice: 271.33,
            priceBefore: 265.42,
            priceNow: 274.77,
          },
        },
      },
      {
        date: '2025-09-13',
        festivos: [
          'Festividad local de Cañada de Calatrava',
          'Festividad local de Castellar de Santiago',
          'Festividad local de Fuenllana',
          'Festividad local de Montiel',
          'Festividad local de Saceruela',
          'Festividad local de Arcas',
          'Festividad local de Belmontejo',
          'Festividad local de Chillaron de Cuenca',
          'Festividad local de Horcajo De Santiago',
          'Festividad local de Leganiel',
          'Festividad local de Los Valdecolmenas',
          'Festividad local de Mariana',
          'Festividad local de Montalbanejo',
          'Festividad local de Portilla',
          'Festividad local de Priego',
          'Festividad local de Saceda-Trasierra',
          'Festividad local de Santa Maria del Val',
          'Festividad local de Valdeolivas',
          'Festividad local de Chillaron del Rey',
          'Festividad local de Chiloeches',
          'Festividad local de Cifuentes',
          'Festividad local de Guadalajara',
          'Festividad local de Hueva',
          'Festividad local de Luzón',
          'Festividad local de Moratilla de los Meleros',
          'Festividad local de Trijueque',
          'Festividad local de Villanueva de Alcorón',
          'Festividad local de Calera y Chozas',
          'Festividad local de Calzada de Oropesa',
          'Festividad local de Casarrubios del Monte',
          'Festividad local de Dosbarrios',
          'Festividad local de Escalonilla',
          'Festividad local de Fuensalida',
          'Festividad local de Hinojosa de San Vicente',
          'Festividad local de Los Cerralbos',
          'Festividad local de Los Navalmorales',
          'Festividad local de Los Yébenes',
          'Festividad local de Otero',
          'Festividad local de Real de San Vicente',
          'Festividad local de Rielves',
          'Festividad local de Sevilleja de la Jara',
          'Festividad local de Villatobas',
          'Festividad local de Yepes',
          'Festividad local de Yuncos',
        ],
        pc_ocupacion: 61.0,
        room: {
          double: {
            newPrice: 143.62,
            priceBefore: 148.6,
            priceNow: 147.9,
          },
          single: {
            newPrice: 126.28,
            priceBefore: 117.66,
            priceNow: 128.42,
          },
          suite: {
            newPrice: 328.73,
            priceBefore: 313.53,
            priceNow: 320.32,
          },
        },
      },
      {
        date: '2025-09-14',
        festivos: [
          'Festividad local de Alpera',
          'Festividad local de Villa de Vés',
          'Festividad local de Almadén',
          'Festividad local de Bolaños de Calatrava',
          'Festividad local de Cózar',
          'Festividad local de Malagón',
          'Festividad local de Manzanares',
          'Festividad local de Alarcón',
          'Festividad local de Cuevas de Velasco',
          'Festividad local de Fuentelespino de Moya',
          'Festividad local de La Alberca de Zancara',
          'Festividad local de La Parra de las Vegas',
          'Festividad local de La Ventosa',
          'Festividad local de Minglanilla',
          'Festividad local de Nohales',
          'Festividad local de Villar del Maestre',
          'Festividad local de Alaminos',
          'Festividad local de Argecilla',
          'Festividad local de Atienza',
          'Festividad local de Baños de Tajo',
          'Festividad local de Centenera',
          'Festividad local de Ciruelas',
          'Festividad local de Fuentelahiguera de Albatages',
          'Festividad local de Hueva',
          'Festividad local de Irueste',
          'Festividad local de Jadraque',
          'Festividad local de Loranca de Tajuña',
          'Festividad local de Mondéjar',
          'Festividad local de Saelices de la Sal',
          'Festividad local de Salmerón',
          'Festividad local de Val de San Garcia',
          'Festividad local de Valdeconcha',
          'Festividad local de Valdenuño Fernandez',
          'Festividad local de Valhermoso',
          'Festividad local de Carmena',
          'Festividad local de Garciotum',
          'Festividad local de Guadamur',
          'Festividad local de La Pueblanueva',
          'Festividad local de Las Herencias',
          'Festividad local de Lucillos',
          'Festividad local de Madridejos',
          'Festividad local de Pelahustán',
          'Festividad local de Segurilla',
        ],
        pc_ocupacion: 69.0,
        room: {
          double: {
            newPrice: 168.01,
            priceBefore: 161.6,
            priceNow: 174.93,
          },
          single: {
            newPrice: 160.02,
            priceBefore: 165.96,
            priceNow: 181.81,
          },
          suite: {
            newPrice: 267.14,
            priceBefore: 269.89,
            priceNow: 279.79,
          },
        },
      },
      {
        date: '2025-09-15',
        festivos: [
          'Festividad local de Villar de Cañas',
          'Festividad local de Anquela del Ducado',
          'Festividad local de Baños de Tajo',
          'Festividad local de Valhermoso',
          'Festividad local de Zarzuela de Galve',
        ],
        pc_ocupacion: 62.0,
        room: {
          double: {
            newPrice: 221.78,
            priceBefore: 205.15,
            priceNow: 224.97,
          },
          single: {
            newPrice: 146.0,
            priceBefore: 144.78,
            priceNow: 147.18,
          },
          suite: {
            newPrice: 261.14,
            priceBefore: 271.84,
            priceNow: 288.36,
          },
        },
      },
      {
        date: '2025-09-16',
        festivos: [
          'Festividad local de Elche De La Sierra',
          'Festividad local de Villapalacios',
          'Festividad local de Alcoba de los Montes',
          'Festividad local de Brazatortas',
          'Festividad local de Guadalmez',
          'Festividad local de Arandilla del Arroyo',
          'Festividad local de Beteta',
          'Festividad local de Buenache de Alarcon',
          'Festividad local de Castillejo-Sierra',
          'Festividad local de Fuente de Pedro Naharro',
          'Festividad local de Leganiel',
          'Festividad local de Osa de la Vega',
          'Festividad local de Paracuellos de la Vega',
          'Festividad local de Saelices',
          'Festividad local de Santa Maria del Val',
          'Festividad local de Sisante',
          'Festividad local de Villaescusa de Haro',
          'Festividad local de Villares del Saz',
          'Festividad local de Alcocer',
          'Festividad local de Almoguera',
          'Festividad local de Auñón',
          'Festividad local de Azuqueca de Henares',
          'Festividad local de Budia',
          'Festividad local de Chequilla',
          'Festividad local de Cifuentes',
          'Festividad local de Esplegares',
          'Festividad local de Humanes',
          'Festividad local de Jadraque',
          'Festividad local de Milmarcos',
          'Festividad local de Peñalén',
          'Festividad local de Santiuste',
          'Festividad local de Val de San Garcia',
          'Festividad local de Yunquera de Henares',
          'Festividad local de Zarzuela de Galve',
          'Festividad local de Bargas',
          'Festividad local de Calzada de Oropesa',
          'Festividad local de Chueca',
          'Festividad local de Cobeja',
          'Festividad local de Los Navalmorales',
          'Festividad local de Noez',
          'Festividad local de Numancia de la Sagra',
          'Festividad local de Otero',
          'Festividad local de Real de San Vicente',
          'Festividad local de Santa Ana de Pusa',
          'Festividad local de Villafranca de los Caballeros',
          'Festividad local de Yepes',
          'Festividad local de Yunclillos',
        ],
        pc_ocupacion: 54.0,
        room: {
          double: {
            newPrice: 208.62,
            priceBefore: 191.21,
            priceNow: 203.54,
          },
          single: {
            newPrice: 86.56,
            priceBefore: 89.41,
            priceNow: 96.44,
          },
          suite: {
            newPrice: 233.13,
            priceBefore: 243.63,
            priceNow: 239.07,
          },
        },
      },
      {
        date: '2025-09-17',
        festivos: [
          'Festividad local de Beteta',
          'Festividad local de Budia',
          'Festividad local de Yunquera de Henares',
          'Festividad local de Cobeja',
          'Festividad local de Numancia de la Sagra',
        ],
        pc_ocupacion: 51.0,
        room: {
          double: {
            newPrice: 128.66,
            priceBefore: 123.81,
            priceNow: 118.57,
          },
          single: {
            newPrice: 102.67,
            priceBefore: 108.07,
            priceNow: 114.62,
          },
          suite: {
            newPrice: 275.85,
            priceBefore: 279.22,
            priceNow: 285.32,
          },
        },
      },
      {
        date: '2025-09-18',
        festivos: [
          'Festividad local de Villanueva de los Infantes',
          'Festividad local de Honrubia',
          'Festividad local de Escamilla',
          'Festividad local de Luzaga',
        ],
        pc_ocupacion: 65.0,
        room: {
          double: {
            newPrice: 153.64,
            priceBefore: 145.32,
            priceNow: 141.52,
          },
          single: {
            newPrice: 219.04,
            priceBefore: 204.14,
            priceNow: 204.59,
          },
          suite: {
            newPrice: 321.01,
            priceBefore: 309.37,
            priceNow: 335.72,
          },
        },
      },
      {
        date: '2025-09-19',
        festivos: ['Festividad local de Oropesa y Corchuela'],
        pc_ocupacion: 69.0,
        room: {
          double: {
            newPrice: 140.02,
            priceBefore: 132.62,
            priceNow: 140.24,
          },
          single: {
            newPrice: 163.28,
            priceBefore: 150.53,
            priceNow: 160.28,
          },
          suite: {
            newPrice: 333.22,
            priceBefore: 306.92,
            priceNow: 313.91,
          },
        },
      },
      {
        date: '2025-09-20',
        festivos: [
          'Festividad local de Villaverde del Guadalimar',
          'Festividad local de Valverde de Jucar',
          'Festividad local de Ablanque',
          'Festividad local de Peralejos de las Truchas',
          'Festividad local de Pozo de Guadalajara',
          'Festividad local de Gerindote',
        ],
        pc_ocupacion: 77.0,
        room: {
          double: {
            newPrice: 217.43,
            priceBefore: 228.17,
            priceNow: 244.08,
          },
          single: {
            newPrice: 159.42,
            priceBefore: 165.28,
            priceNow: 161.47,
          },
          suite: {
            newPrice: 319.93,
            priceBefore: 328.18,
            priceNow: 326.25,
          },
        },
      },
      {
        date: '2025-09-21',
        festivos: [
          'Festividad local de La Recueja',
          'Festividad local de Cuenca',
          'Festividad local de La Melgosa',
          'Festividad local de Mohorte',
          'Festividad local de Consuegra',
        ],
        pc_ocupacion: 46.0,
        room: {
          double: {
            newPrice: 168.59,
            priceBefore: 168.73,
            priceNow: 171.31,
          },
          single: {
            newPrice: 144.62,
            priceBefore: 139.45,
            priceNow: 147.94,
          },
          suite: {
            newPrice: 180.25,
            priceBefore: 171.29,
            priceNow: 176.81,
          },
        },
      },
      {
        date: '2025-09-22',
        festivos: [],
        pc_ocupacion: 69.0,
        room: {
          double: {
            newPrice: 202.14,
            priceBefore: 191.76,
            priceNow: 184.57,
          },
          single: {
            newPrice: 156.47,
            priceBefore: 151.02,
            priceNow: 156.95,
          },
          suite: {
            newPrice: 294.25,
            priceBefore: 279.02,
            priceNow: 293.52,
          },
        },
      },
      {
        date: '2025-09-23',
        festivos: [
          'Festividad local de Munera',
          'Festividad local de Navas de Estena',
          'Festividad local de Peralejos de las Truchas',
          'Festividad local de Totanés',
          'Festividad local de Villaminaya',
        ],
        pc_ocupacion: 76.0,
        room: {
          double: {
            newPrice: 212.49,
            priceBefore: 212.23,
            priceNow: 231.66,
          },
          single: {
            newPrice: 208.93,
            priceBefore: 208.04,
            priceNow: 228.13,
          },
          suite: {
            newPrice: 311.28,
            priceBefore: 306.36,
            priceNow: 307.94,
          },
        },
      },
      {
        date: '2025-09-24',
        festivos: [
          'Festividad local de Hoya Gonzalo',
          'Festividad local de Herencia',
          'Festividad local de Los Cortijos',
          'Festividad local de Abánades',
          'Festividad local de Mochales',
          'Festividad local de Torremochuela',
          'Festividad local de Villel de Mesa',
          'Festividad local de Las Ventas con Peña Aguilera',
          'Festividad local de Pantoja',
          'Festividad local de Villaluenga de la Sagra',
        ],
        pc_ocupacion: 74.0,
        room: {
          double: {
            newPrice: 222.63,
            priceBefore: 211.88,
            priceNow: 206.01,
          },
          single: {
            newPrice: 194.9,
            priceBefore: 194.16,
            priceNow: 209.15,
          },
          suite: {
            newPrice: 250.12,
            priceBefore: 262.27,
            priceNow: 287.71,
          },
        },
      },
      {
        date: '2025-09-25',
        festivos: [
          'Festividad local de Los Cortijos',
          'Festividad local de La Guardia',
          'Festividad local de Pantoja',
        ],
        pc_ocupacion: 57.0,
        room: {
          double: {
            newPrice: 113.78,
            priceBefore: 115.31,
            priceNow: 111.95,
          },
          single: {
            newPrice: 123.36,
            priceBefore: 120.1,
            priceNow: 126.7,
          },
          suite: {
            newPrice: 252.58,
            priceBefore: 258.22,
            priceNow: 263.25,
          },
        },
      },
      {
        date: '2025-09-26',
        festivos: [
          'Festividad local de La Guardia',
          'Festividad local de Noves',
        ],
        pc_ocupacion: 51.0,
        room: {
          double: {
            newPrice: 214.56,
            priceBefore: 209.13,
            priceNow: 205.6,
          },
          single: {
            newPrice: 145.49,
            priceBefore: 145.86,
            priceNow: 152.22,
          },
          suite: {
            newPrice: 192.7,
            priceBefore: 194.67,
            priceNow: 211.84,
          },
        },
      },
      {
        date: '2025-09-27',
        festivos: [
          'Festividad local de Abengibre',
          'Festividad local de El Peral',
          'Festividad local de Palomares del Campo',
          'Festividad local de Albalate de Zorita',
          'Festividad local de Heras de Ayuso',
          'Festividad local de Villaseca de Uceda',
          'Festividad local de Alberche del Caudillo',
          'Festividad local de Buenaventura',
          'Festividad local de Noves',
          'Festividad local de Torrijos',
        ],
        pc_ocupacion: 61.0,
        room: {
          double: {
            newPrice: 170.98,
            priceBefore: 170.8,
            priceNow: 174.16,
          },
          single: {
            newPrice: 167.0,
            priceBefore: 162.54,
            priceNow: 158.22,
          },
          suite: {
            newPrice: 289.03,
            priceBefore: 296.91,
            priceNow: 301.1,
          },
        },
      },
      {
        date: '2025-09-28',
        festivos: [],
        pc_ocupacion: 53.0,
        room: {
          double: {
            newPrice: 161.77,
            priceBefore: 169.58,
            priceNow: 185.94,
          },
          single: {
            newPrice: 139.63,
            priceBefore: 135.97,
            priceNow: 140.75,
          },
          suite: {
            newPrice: 213.72,
            priceBefore: 213.24,
            priceNow: 224.55,
          },
        },
      },
      {
        date: '2025-09-29',
        festivos: [
          'Festividad local de Fresneda de la Sierra',
          'Festividad local de Huelves',
          'Festividad local de Tondos',
          'Festividad local de Valparaiso de Arriba',
          'Festividad local de Anchuela del Campo',
        ],
        pc_ocupacion: 68.0,
        room: {
          double: {
            newPrice: 195.06,
            priceBefore: 180.25,
            priceNow: 176.56,
          },
          single: {
            newPrice: 163.46,
            priceBefore: 164.38,
            priceNow: 159.81,
          },
          suite: {
            newPrice: 316.58,
            priceBefore: 304.22,
            priceNow: 300.37,
          },
        },
      },
      {
        date: '2025-09-30',
        festivos: [
          'Festividad local de Chinchilla',
          'Festividad local de Corralrubio',
          'Festividad local de El Ballestero',
          'Festividad local de Ossa de Montiel',
          'Festividad local de Paterna del Madera',
          'Festividad local de Peñascosa',
          'Festividad local de Anchuras',
          'Festividad local de Montalbo',
          'Festividad local de Narboneta',
          'Festividad local de Tragacete',
          'Festividad local de Castejón de Henares',
          'Festividad local de Driebes',
          'Festividad local de Huertahernando',
          'Festividad local de Yebes',
          'Festividad local de Robledo del Mazo',
          'Festividad local de Torrecilla de La Jara',
          'Festividad local de Urda',
        ],
        pc_ocupacion: 72.0,
        room: {
          double: {
            newPrice: 168.12,
            priceBefore: 169.2,
            priceNow: 181.58,
          },
          single: {
            newPrice: 200.8,
            priceBefore: 202.94,
            priceNow: 213.02,
          },
          suite: {
            newPrice: 285.95,
            priceBefore: 279.35,
            priceNow: 302.6,
          },
        },
      },
      {
        date: '2025-10-01',
        festivos: ['Festividad local de Montalbo', 'Festividad local de Embid'],
        pc_ocupacion: 55.0,
        room: {
          double: {
            newPrice: 143.28,
            priceBefore: 148.96,
            priceNow: 151.74,
          },
          single: {
            newPrice: 144.9,
            priceBefore: 131.76,
            priceNow: 144.49,
          },
          suite: {
            newPrice: 228.93,
            priceBefore: 231.2,
            priceNow: 234.34,
          },
        },
      },
      {
        date: '2025-10-02',
        festivos: [
          'Festividad local de Arguisuelas',
          'Festividad local de La Cierva',
          'Festividad local de Semillas',
          'Festividad local de Yélamos de Abajo',
        ],
        pc_ocupacion: 55.0,
        room: {
          double: {
            newPrice: 177.39,
            priceBefore: 184.21,
            priceNow: 193.34,
          },
          single: {
            newPrice: 160.33,
            priceBefore: 163.41,
            priceNow: 166.21,
          },
          suite: {
            newPrice: 185.66,
            priceBefore: 190.96,
            priceNow: 193.35,
          },
        },
      },
      {
        date: '2025-10-03',
        festivos: ['Festividad local de Yélamos de Abajo'],
        pc_ocupacion: 61.0,
        room: {
          double: {
            newPrice: 183.01,
            priceBefore: 177.19,
            priceNow: 189.42,
          },
          single: {
            newPrice: 140.0,
            priceBefore: 129.01,
            priceNow: 137.07,
          },
          suite: {
            newPrice: 290.89,
            priceBefore: 284.33,
            priceNow: 276.28,
          },
        },
      },
      {
        date: '2025-10-04',
        festivos: [
          'Festividad local de El Salobral',
          'Festividad local de Vianos',
          'Festividad local de Masegosa',
          'Festividad local de Robledo de Corpes',
          'Festividad local de Sotodosos',
        ],
        pc_ocupacion: 57.0,
        room: {
          double: {
            newPrice: 176.04,
            priceBefore: 178.84,
            priceNow: 191.36,
          },
          single: {
            newPrice: 166.2,
            priceBefore: 165.34,
            priceNow: 181.6,
          },
          suite: {
            newPrice: 234.31,
            priceBefore: 241.86,
            priceNow: 234.81,
          },
        },
      },
      {
        date: '2025-10-05',
        festivos: [
          'Festividad local de Salinas del Manzano',
          'Festividad local de El Pedregal',
        ],
        pc_ocupacion: 57.0,
        room: {
          double: {
            newPrice: 148.31,
            priceBefore: 140.33,
            priceNow: 137.75,
          },
          single: {
            newPrice: 128.64,
            priceBefore: 134.77,
            priceNow: 130.56,
          },
          suite: {
            newPrice: 293.38,
            priceBefore: 270.45,
            priceNow: 261.38,
          },
        },
      },
      {
        date: '2025-10-06',
        festivos: [],
        pc_ocupacion: 52.0,
        room: {
          double: {
            newPrice: 175.51,
            priceBefore: 171.36,
            priceNow: 175.76,
          },
          single: {
            newPrice: 111.42,
            priceBefore: 104.78,
            priceNow: 107.51,
          },
          suite: {
            newPrice: 262.22,
            priceBefore: 266.89,
            priceNow: 290.14,
          },
        },
      },
      {
        date: '2025-10-07',
        festivos: [
          'Festividad local de Bonete',
          'Festividad local de Férez',
          'Festividad local de Masegoso',
          'Festividad local de Povedilla',
          'Festividad local de Tiriez',
          'Festividad local de Valdeganga',
          'Festividad local de Almadenejos',
          'Festividad local de Almedina',
          'Festividad local de Santa Cruz de Cáñamos',
          'Festividad local de Villanueva de San Carlos',
          'Festividad local de Buenache de la Sierra',
          'Festividad local de El Picazo',
          'Festividad local de Fuentes',
          'Festividad local de Fuertescusa',
          'Festividad local de Las Valeras',
          'Festividad local de Masegosa',
          'Festividad local de Pineda de Gigüela',
          'Festividad local de Valdetortola',
          'Festividad local de Valparaiso de Abajo',
          'Festividad local de Zafra de Zancara',
          'Festividad local de Zarzuela',
          'Festividad local de Anchuela del Pedregal',
          'Festividad local de Anguita',
          'Festividad local de Driebes',
          'Festividad local de El Sotillo',
          'Festividad local de Málaga del Fresno',
          'Festividad local de Saúca',
          'Festividad local de Setiles',
          'Festividad local de Cabañas de la Sagra',
          'Festividad local de Cardiel de los Montes',
          'Festividad local de Lagartera',
          'Festividad local de Las Vegas y San Antonio',
          'Festividad local de Montearagón',
          'Festividad local de Olias del Rey',
          'Festividad local de Ontígola',
        ],
        pc_ocupacion: 57.0,
        room: {
          double: {
            newPrice: 141.37,
            priceBefore: 146.88,
            priceNow: 159.45,
          },
          single: {
            newPrice: 146.69,
            priceBefore: 145.22,
            priceNow: 154.56,
          },
          suite: {
            newPrice: 285.04,
            priceBefore: 265.11,
            priceNow: 289.94,
          },
        },
      },
      {
        date: '2025-10-08',
        festivos: [
          'Festividad local de Férez',
          'Festividad local de Valdeganga',
          'Festividad local de Valenzuela de Calatrava',
          'Festividad local de Sotos',
          'Festividad local de Setiles',
          'Festividad local de Olias del Rey',
        ],
        pc_ocupacion: 70.0,
        room: {
          double: {
            newPrice: 224.16,
            priceBefore: 205.63,
            priceNow: 209.07,
          },
          single: {
            newPrice: 158.63,
            priceBefore: 152.79,
            priceNow: 155.27,
          },
          suite: {
            newPrice: 267.47,
            priceBefore: 259.33,
            priceNow: 278.73,
          },
        },
      },
      {
        date: '2025-10-09',
        festivos: [
          'Festividad local de Fuenteálamo',
          'Festividad local de Villar del Humo',
          'Festividad local de Ujados',
        ],
        pc_ocupacion: 55.0,
        room: {
          double: {
            newPrice: 197.04,
            priceBefore: 199.83,
            priceNow: 211.32,
          },
          single: {
            newPrice: 120.82,
            priceBefore: 112.13,
            priceNow: 115.42,
          },
          suite: {
            newPrice: 217.87,
            priceBefore: 199.9,
            priceNow: 195.56,
          },
        },
      },
      {
        date: '2025-10-10',
        festivos: [
          'Festividad local de Cantalojas',
          'Festividad local de Torremocha del Pinar',
          'Festividad local de Portillo de Toledo',
        ],
        pc_ocupacion: 57.0,
        room: {
          double: {
            newPrice: 191.26,
            priceBefore: 196.44,
            priceNow: 202.86,
          },
          single: {
            newPrice: 153.3,
            priceBefore: 148.57,
            priceNow: 157.01,
          },
          suite: {
            newPrice: 230.55,
            priceBefore: 233.09,
            priceNow: 247.87,
          },
        },
      },
      {
        date: '2025-10-11',
        festivos: [
          'Festividad local de Bormate',
          'Festividad local de La Herrera',
          'Festividad local de Cardenete',
          'Festividad local de Carrascosa de la Sierra',
          'Festividad local de Henarejos',
          'Festividad local de Cantalojas',
          'Festividad local de Los Alares',
          'Festividad local de Pulgar',
          'Festividad local de Robledo del Buey',
        ],
        pc_ocupacion: 59.0,
        room: {
          double: {
            newPrice: 198.11,
            priceBefore: 190.15,
            priceNow: 182.75,
          },
          single: {
            newPrice: 166.56,
            priceBefore: 155.65,
            priceNow: 153.81,
          },
          suite: {
            newPrice: 254.95,
            priceBefore: 257.72,
            priceNow: 279.41,
          },
        },
      },
      {
        date: '2025-10-12',
        festivos: [],
        pc_ocupacion: 57.0,
        room: {
          double: {
            newPrice: 217.89,
            priceBefore: 202.58,
            priceNow: 200.27,
          },
          single: {
            newPrice: 151.28,
            priceBefore: 143.7,
            priceNow: 146.82,
          },
          suite: {
            newPrice: 251.31,
            priceBefore: 262.27,
            priceNow: 272.17,
          },
        },
      },
      {
        date: '2025-10-13',
        festivos: ['Festividad local de Huelamo'],
        pc_ocupacion: 78.0,
        room: {
          double: {
            newPrice: 278.2,
            priceBefore: 265.78,
            priceNow: 257.86,
          },
          single: {
            newPrice: 97.61,
            priceBefore: 97.24,
            priceNow: 103.58,
          },
          suite: {
            newPrice: 321.36,
            priceBefore: 309.08,
            priceNow: 306.29,
          },
        },
      },
      {
        date: '2025-10-14',
        festivos: [
          'Festividad local de Molinicos',
          'Festividad local de Belinchon',
        ],
        pc_ocupacion: 56.0,
        room: {
          double: {
            newPrice: 173.87,
            priceBefore: 180.11,
            priceNow: 174.47,
          },
          single: {
            newPrice: 144.74,
            priceBefore: 146.76,
            priceNow: 158.68,
          },
          suite: {
            newPrice: 253.73,
            priceBefore: 249.93,
            priceNow: 240.17,
          },
        },
      },
      {
        date: '2025-10-15',
        festivos: [
          'Festividad local de Malagón',
          'Festividad local de Jabaga',
          'Festividad local de Villanueva de la Jara',
          'Festividad local de Pastrana',
        ],
        pc_ocupacion: 52.0,
        room: {
          double: {
            newPrice: 194.43,
            priceBefore: 203.42,
            priceNow: 209.08,
          },
          single: {
            newPrice: 160.07,
            priceBefore: 150.0,
            priceNow: 146.99,
          },
          suite: {
            newPrice: 159.7,
            priceBefore: 150.45,
            priceNow: 145.52,
          },
        },
      },
      {
        date: '2025-10-16',
        festivos: [],
        pc_ocupacion: 38.0,
        room: {
          double: {
            newPrice: 121.5,
            priceBefore: 119.98,
            priceNow: 115.97,
          },
          single: {
            newPrice: 120.23,
            priceBefore: 123.9,
            priceNow: 121.88,
          },
          suite: {
            newPrice: 197.2,
            priceBefore: 183.88,
            priceNow: 174.93,
          },
        },
      },
      {
        date: '2025-10-17',
        festivos: ['Festividad local de Cívica'],
        pc_ocupacion: 65.0,
        room: {
          double: {
            newPrice: 185.91,
            priceBefore: 169.05,
            priceNow: 177.84,
          },
          single: {
            newPrice: 166.05,
            priceBefore: 172.74,
            priceNow: 167.49,
          },
          suite: {
            newPrice: 248.39,
            priceBefore: 250.62,
            priceNow: 265.09,
          },
        },
      },
      {
        date: '2025-10-18',
        festivos: [],
        pc_ocupacion: 52.0,
        room: {
          double: {
            newPrice: 134.34,
            priceBefore: 125.4,
            priceNow: 124.14,
          },
          single: {
            newPrice: 143.6,
            priceBefore: 141.48,
            priceNow: 144.88,
          },
          suite: {
            newPrice: 223.63,
            priceBefore: 206.33,
            priceNow: 225.86,
          },
        },
      },
      {
        date: '2025-10-19',
        festivos: [],
        pc_ocupacion: 53.0,
        room: {
          double: {
            newPrice: 168.42,
            priceBefore: 155.05,
            priceNow: 159.05,
          },
          single: {
            newPrice: 152.96,
            priceBefore: 155.31,
            priceNow: 154.68,
          },
          suite: {
            newPrice: 210.46,
            priceBefore: 215.98,
            priceNow: 221.12,
          },
        },
      },
      {
        date: '2025-10-20',
        festivos: [],
        pc_ocupacion: 63.0,
        room: {
          double: {
            newPrice: 176.17,
            priceBefore: 183.33,
            priceNow: 176.0,
          },
          single: {
            newPrice: 138.3,
            priceBefore: 139.22,
            priceNow: 146.3,
          },
          suite: {
            newPrice: 273.16,
            priceBefore: 248.96,
            priceNow: 270.94,
          },
        },
      },
      {
        date: '2025-10-21',
        festivos: ['Festividad local de Brihuega'],
        pc_ocupacion: 65.0,
        room: {
          double: {
            newPrice: 169.12,
            priceBefore: 163.66,
            priceNow: 173.61,
          },
          single: {
            newPrice: 94.17,
            priceBefore: 95.26,
            priceNow: 96.74,
          },
          suite: {
            newPrice: 297.35,
            priceBefore: 309.44,
            priceNow: 308.79,
          },
        },
      },
      {
        date: '2025-10-22',
        festivos: [],
        pc_ocupacion: 47.0,
        room: {
          double: {
            newPrice: 150.98,
            priceBefore: 148.5,
            priceNow: 157.5,
          },
          single: {
            newPrice: 119.02,
            priceBefore: 119.31,
            priceNow: 124.58,
          },
          suite: {
            newPrice: 236.33,
            priceBefore: 217.28,
            priceNow: 219.29,
          },
        },
      },
      {
        date: '2025-10-23',
        festivos: [],
        pc_ocupacion: 60.0,
        room: {
          double: {
            newPrice: 156.04,
            priceBefore: 144.93,
            priceNow: 147.88,
          },
          single: {
            newPrice: 163.82,
            priceBefore: 154.89,
            priceNow: 155.49,
          },
          suite: {
            newPrice: 288.07,
            priceBefore: 272.96,
            priceNow: 278.76,
          },
        },
      },
      {
        date: '2025-10-24',
        festivos: ['Festividad local de Hellín'],
        pc_ocupacion: 65.0,
        room: {
          double: {
            newPrice: 246.96,
            priceBefore: 234.05,
            priceNow: 255.87,
          },
          single: {
            newPrice: 115.63,
            priceBefore: 109.05,
            priceNow: 119.84,
          },
          suite: {
            newPrice: 298.56,
            priceBefore: 287.02,
            priceNow: 283.51,
          },
        },
      },
      {
        date: '2025-10-25',
        festivos: [],
        pc_ocupacion: 46.0,
        room: {
          double: {
            newPrice: 152.83,
            priceBefore: 139.46,
            priceNow: 144.86,
          },
          single: {
            newPrice: 135.22,
            priceBefore: 123.11,
            priceNow: 133.05,
          },
          suite: {
            newPrice: 206.03,
            priceBefore: 210.91,
            priceNow: 207.47,
          },
        },
      },
      {
        date: '2025-10-26',
        festivos: [],
        pc_ocupacion: 51.0,
        room: {
          double: {
            newPrice: 144.01,
            priceBefore: 139.14,
            priceNow: 139.66,
          },
          single: {
            newPrice: 118.06,
            priceBefore: 107.59,
            priceNow: 108.15,
          },
          suite: {
            newPrice: 267.41,
            priceBefore: 248.13,
            priceNow: 255.05,
          },
        },
      },
      {
        date: '2025-10-27',
        festivos: [],
        pc_ocupacion: 39.0,
        room: {
          double: {
            newPrice: 136.86,
            priceBefore: 140.69,
            priceNow: 148.93,
          },
          single: {
            newPrice: 124.04,
            priceBefore: 119.79,
            priceNow: 125.35,
          },
          suite: {
            newPrice: 143.62,
            priceBefore: 149.26,
            priceNow: 148.16,
          },
        },
      },
      {
        date: '2025-10-28',
        festivos: [],
        pc_ocupacion: 52.0,
        room: {
          double: {
            newPrice: 151.78,
            priceBefore: 144.01,
            priceNow: 151.92,
          },
          single: {
            newPrice: 132.03,
            priceBefore: 122.33,
            priceNow: 121.33,
          },
          suite: {
            newPrice: 273.55,
            priceBefore: 252.55,
            priceNow: 249.02,
          },
        },
      },
      {
        date: '2025-10-29',
        festivos: [],
        pc_ocupacion: 51.0,
        room: {
          double: {
            newPrice: 138.24,
            priceBefore: 129.19,
            priceNow: 130.39,
          },
          single: {
            newPrice: 150.49,
            priceBefore: 145.8,
            priceNow: 151.07,
          },
          suite: {
            newPrice: 265.2,
            priceBefore: 263.35,
            priceNow: 253.2,
          },
        },
      },
      {
        date: '2025-10-30',
        festivos: ['Festividad local de Barriopedro'],
        pc_ocupacion: 46.0,
        room: {
          double: {
            newPrice: 126.06,
            priceBefore: 121.42,
            priceNow: 124.97,
          },
          single: {
            newPrice: 111.86,
            priceBefore: 111.44,
            priceNow: 113.1,
          },
          suite: {
            newPrice: 195.88,
            priceBefore: 202.36,
            priceNow: 198.61,
          },
        },
      },
      {
        date: '2025-10-31',
        festivos: ['Festividad local de Menasalbas'],
        pc_ocupacion: 47.0,
        room: {
          double: {
            newPrice: 163.22,
            priceBefore: 153.93,
            priceNow: 153.64,
          },
          single: {
            newPrice: 131.67,
            priceBefore: 124.64,
            priceNow: 120.29,
          },
          suite: {
            newPrice: 186.14,
            priceBefore: 193.9,
            priceNow: 213.07,
          },
        },
      },
      {
        date: '2025-11-01',
        festivos: ['Día de Todos los Santos'],
        pc_ocupacion: 51.0,
        room: {
          double: {
            newPrice: 152.54,
            priceBefore: 153.07,
            priceNow: 159.78,
          },
          single: {
            newPrice: 144.96,
            priceBefore: 144.81,
            priceNow: 140.26,
          },
          suite: {
            newPrice: 225.18,
            priceBefore: 207.67,
            priceNow: 223.89,
          },
        },
      },
      {
        date: '2025-11-02',
        festivos: [],
        pc_ocupacion: 50.0,
        room: {
          double: {
            newPrice: 170.35,
            priceBefore: 175.66,
            priceNow: 180.89,
          },
          single: {
            newPrice: 125.66,
            priceBefore: 119.05,
            priceNow: 128.23,
          },
          suite: {
            newPrice: 224.09,
            priceBefore: 233.84,
            priceNow: 233.14,
          },
        },
      },
      {
        date: '2025-11-03',
        festivos: [],
        pc_ocupacion: 47.0,
        room: {
          double: {
            newPrice: 118.68,
            priceBefore: 117.59,
            priceNow: 123.18,
          },
          single: {
            newPrice: 108.02,
            priceBefore: 110.53,
            priceNow: 117.31,
          },
          suite: {
            newPrice: 229.17,
            priceBefore: 235.99,
            priceNow: 250.81,
          },
        },
      },
      {
        date: '2025-11-04',
        festivos: [
          'Festividad local de Las Labores',
          'Festividad local de Barchin del Hoyo',
        ],
        pc_ocupacion: 50.0,
        room: {
          double: {
            newPrice: 139.08,
            priceBefore: 143.53,
            priceNow: 144.91,
          },
          single: {
            newPrice: 108.73,
            priceBefore: 114.26,
            priceNow: 125.13,
          },
          suite: {
            newPrice: 254.56,
            priceBefore: 240.98,
            priceNow: 263.01,
          },
        },
      },
      {
        date: '2025-11-05',
        festivos: [],
        pc_ocupacion: 63.0,
        room: {
          double: {
            newPrice: 192.48,
            priceBefore: 192.33,
            priceNow: 206.27,
          },
          single: {
            newPrice: 181.38,
            priceBefore: 189.17,
            priceNow: 191.78,
          },
          suite: {
            newPrice: 242.17,
            priceBefore: 236.59,
            priceNow: 259.37,
          },
        },
      },
      {
        date: '2025-11-06',
        festivos: [],
        pc_ocupacion: 52.0,
        room: {
          double: {
            newPrice: 96.96,
            priceBefore: 99.15,
            priceNow: 100.99,
          },
          single: {
            newPrice: 208.81,
            priceBefore: 195.12,
            priceNow: 192.07,
          },
          suite: {
            newPrice: 238.3,
            priceBefore: 236.04,
            priceNow: 228.94,
          },
        },
      },
      {
        date: '2025-11-07',
        festivos: [],
        pc_ocupacion: 81.0,
        room: {
          double: {
            newPrice: 300.9,
            priceBefore: 274.35,
            priceNow: 261.89,
          },
          single: {
            newPrice: 219.29,
            priceBefore: 213.27,
            priceNow: 234.44,
          },
          suite: {
            newPrice: 252.98,
            priceBefore: 253.69,
            priceNow: 272.82,
          },
        },
      },
      {
        date: '2025-11-08',
        festivos: ['Festividad local de Pozo de Almoguera'],
        pc_ocupacion: 77.0,
        room: {
          double: {
            newPrice: 304.96,
            priceBefore: 277.87,
            priceNow: 287.51,
          },
          single: {
            newPrice: 175.87,
            priceBefore: 177.29,
            priceNow: 177.02,
          },
          suite: {
            newPrice: 200.82,
            priceBefore: 203.77,
            priceNow: 206.82,
          },
        },
      },
      {
        date: '2025-11-09',
        festivos: [],
        pc_ocupacion: 63.0,
        room: {
          double: {
            newPrice: 165.1,
            priceBefore: 167.48,
            priceNow: 164.35,
          },
          single: {
            newPrice: 113.36,
            priceBefore: 111.26,
            priceNow: 112.21,
          },
          suite: {
            newPrice: 328.45,
            priceBefore: 313.74,
            priceNow: 320.57,
          },
        },
      },
      {
        date: '2025-11-10',
        festivos: [],
        pc_ocupacion: 71.0,
        room: {
          double: {
            newPrice: 153.03,
            priceBefore: 159.08,
            priceNow: 166.35,
          },
          single: {
            newPrice: 171.35,
            priceBefore: 178.91,
            priceNow: 189.24,
          },
          suite: {
            newPrice: 285.66,
            priceBefore: 296.45,
            priceNow: 303.82,
          },
        },
      },
      {
        date: '2025-11-11',
        festivos: [
          'Festividad local de Cañaveras',
          'Festividad local de Fuertescusa',
          'Festividad local de San Martin de Boniches',
          'Festividad local de Anquela del Ducado',
          'Festividad local de Masegoso de Tajuña',
          'Festividad local de Pozo de Almoguera',
          'Festividad local de San Martín de Pusa',
          'Festividad local de Villanueva de Alcardete',
        ],
        pc_ocupacion: 57.0,
        room: {
          double: {
            newPrice: 220.7,
            priceBefore: 216.46,
            priceNow: 209.74,
          },
          single: {
            newPrice: 107.82,
            priceBefore: 105.82,
            priceNow: 109.55,
          },
          suite: {
            newPrice: 269.46,
            priceBefore: 251.76,
            priceNow: 273.92,
          },
        },
      },
      {
        date: '2025-11-12',
        festivos: [
          'Festividad local de Cañamares',
          'Festividad local de Cogolludo',
          'Festividad local de Muduex',
        ],
        pc_ocupacion: 57.0,
        room: {
          double: {
            newPrice: 154.23,
            priceBefore: 154.93,
            priceNow: 155.62,
          },
          single: {
            newPrice: 114.67,
            priceBefore: 116.23,
            priceNow: 114.71,
          },
          suite: {
            newPrice: 239.97,
            priceBefore: 230.74,
            priceNow: 243.65,
          },
        },
      },
      {
        date: '2025-11-13',
        festivos: [],
        pc_ocupacion: 26.0,
        room: {
          double: {
            newPrice: 93.24,
            priceBefore: 97.2,
            priceNow: 100.56,
          },
          single: {
            newPrice: 87.37,
            priceBefore: 89.33,
            priceNow: 93.26,
          },
          suite: {
            newPrice: 132.12,
            priceBefore: 122.05,
            priceNow: 124.19,
          },
        },
      },
      {
        date: '2025-11-14',
        festivos: ['Festividad local de Tartanedo'],
        pc_ocupacion: 48.0,
        room: {
          double: {
            newPrice: 126.87,
            priceBefore: 125.3,
            priceNow: 130.23,
          },
          single: {
            newPrice: 117.06,
            priceBefore: 109.19,
            priceNow: 106.85,
          },
          suite: {
            newPrice: 213.05,
            priceBefore: 213.38,
            priceNow: 225.03,
          },
        },
      },
      {
        date: '2025-11-15',
        festivos: [],
        pc_ocupacion: 41.0,
        room: {
          double: {
            newPrice: 133.04,
            priceBefore: 128.26,
            priceNow: 139.01,
          },
          single: {
            newPrice: 101.11,
            priceBefore: 102.86,
            priceNow: 102.59,
          },
          suite: {
            newPrice: 241.91,
            priceBefore: 224.64,
            priceNow: 228.69,
          },
        },
      },
      {
        date: '2025-11-16',
        festivos: [],
        pc_ocupacion: 46.0,
        room: {
          double: {
            newPrice: 153.75,
            priceBefore: 156.12,
            priceNow: 162.07,
          },
          single: {
            newPrice: 84.75,
            priceBefore: 87.69,
            priceNow: 94.08,
          },
          suite: {
            newPrice: 220.61,
            priceBefore: 216.97,
            priceNow: 223.72,
          },
        },
      },
      {
        date: '2025-11-17',
        festivos: [],
        pc_ocupacion: 61.0,
        room: {
          double: {
            newPrice: 169.73,
            priceBefore: 155.09,
            priceNow: 166.41,
          },
          single: {
            newPrice: 121.41,
            priceBefore: 110.55,
            priceNow: 119.39,
          },
          suite: {
            newPrice: 304.92,
            priceBefore: 297.23,
            priceNow: 324.81,
          },
        },
      },
      {
        date: '2025-11-18',
        festivos: [
          'Festividad local de Arcos de la Cantera',
          'Festividad local de San Román de los Montes',
        ],
        pc_ocupacion: 40.0,
        room: {
          double: {
            newPrice: 166.7,
            priceBefore: 163.8,
            priceNow: 172.47,
          },
          single: {
            newPrice: 68.24,
            priceBefore: 63.12,
            priceNow: 62.08,
          },
          suite: {
            newPrice: 192.58,
            priceBefore: 195.1,
            priceNow: 186.11,
          },
        },
      },
      {
        date: '2025-11-19',
        festivos: [],
        pc_ocupacion: 36.0,
        room: {
          double: {
            newPrice: 124.22,
            priceBefore: 117.14,
            priceNow: 123.96,
          },
          single: {
            newPrice: 84.72,
            priceBefore: 80.32,
            priceNow: 79.94,
          },
          suite: {
            newPrice: 210.73,
            priceBefore: 202.41,
            priceNow: 205.52,
          },
        },
      },
      {
        date: '2025-11-20',
        festivos: [],
        pc_ocupacion: 38.0,
        room: {
          double: {
            newPrice: 107.63,
            priceBefore: 110.81,
            priceNow: 105.85,
          },
          single: {
            newPrice: 112.28,
            priceBefore: 105.06,
            priceNow: 108.9,
          },
          suite: {
            newPrice: 184.9,
            priceBefore: 170.53,
            priceNow: 166.64,
          },
        },
      },
      {
        date: '2025-11-21',
        festivos: [],
        pc_ocupacion: 49.0,
        room: {
          double: {
            newPrice: 143.26,
            priceBefore: 147.84,
            priceNow: 141.18,
          },
          single: {
            newPrice: 103.75,
            priceBefore: 95.55,
            priceNow: 95.71,
          },
          suite: {
            newPrice: 218.1,
            priceBefore: 223.88,
            priceNow: 244.16,
          },
        },
      },
      {
        date: '2025-11-22',
        festivos: [],
        pc_ocupacion: 48.0,
        room: {
          double: {
            newPrice: 140.29,
            priceBefore: 129.25,
            priceNow: 123.8,
          },
          single: {
            newPrice: 147.38,
            priceBefore: 142.78,
            priceNow: 136.69,
          },
          suite: {
            newPrice: 183.28,
            priceBefore: 187.15,
            priceNow: 196.45,
          },
        },
      },
      {
        date: '2025-11-23',
        festivos: ['Festividad local de Zarzuela de Jadraque'],
        pc_ocupacion: 48.0,
        room: {
          double: {
            newPrice: 153.8,
            priceBefore: 139.99,
            priceNow: 139.17,
          },
          single: {
            newPrice: 99.13,
            priceBefore: 99.25,
            priceNow: 101.73,
          },
          suite: {
            newPrice: 235.48,
            priceBefore: 234.62,
            priceNow: 232.43,
          },
        },
      },
      {
        date: '2025-11-24',
        festivos: [],
        pc_ocupacion: 47.0,
        room: {
          double: {
            newPrice: 137.5,
            priceBefore: 126.77,
            priceNow: 136.48,
          },
          single: {
            newPrice: 102.1,
            priceBefore: 97.81,
            priceNow: 93.25,
          },
          suite: {
            newPrice: 217.14,
            priceBefore: 220.22,
            priceNow: 231.7,
          },
        },
      },
      {
        date: '2025-11-25',
        festivos: [
          'Festividad local de Angón',
          'Festividad local de Tórtola de Henares',
        ],
        pc_ocupacion: 59.0,
        room: {
          double: {
            newPrice: 214.14,
            priceBefore: 206.57,
            priceNow: 198.46,
          },
          single: {
            newPrice: 140.07,
            priceBefore: 134.54,
            priceNow: 133.43,
          },
          suite: {
            newPrice: 182.12,
            priceBefore: 187.26,
            priceNow: 185.49,
          },
        },
      },
      {
        date: '2025-11-26',
        festivos: [],
        pc_ocupacion: 49.0,
        room: {
          double: {
            newPrice: 162.73,
            priceBefore: 166.75,
            priceNow: 177.88,
          },
          single: {
            newPrice: 135.32,
            priceBefore: 125.21,
            priceNow: 123.91,
          },
          suite: {
            newPrice: 189.25,
            priceBefore: 190.59,
            priceNow: 199.26,
          },
        },
      },
      {
        date: '2025-11-27',
        festivos: [],
        pc_ocupacion: 48.0,
        room: {
          double: {
            newPrice: 197.3,
            priceBefore: 206.61,
            priceNow: 218.21,
          },
          single: {
            newPrice: 101.64,
            priceBefore: 96.15,
            priceNow: 94.25,
          },
          suite: {
            newPrice: 212.35,
            priceBefore: 198.15,
            priceNow: 211.34,
          },
        },
      },
      {
        date: '2025-11-28',
        festivos: [],
        pc_ocupacion: 51.0,
        room: {
          double: {
            newPrice: 148.95,
            priceBefore: 138.24,
            priceNow: 140.55,
          },
          single: {
            newPrice: 132.17,
            priceBefore: 122.95,
            priceNow: 119.74,
          },
          suite: {
            newPrice: 244.96,
            priceBefore: 228.5,
            priceNow: 219.4,
          },
        },
      },
      {
        date: '2025-11-29',
        festivos: [
          'Festividad local de Viso del Marques',
          'Festividad local de Castillo de Bayuela',
          'Festividad local de San Martín de Montalbán',
        ],
        pc_ocupacion: 41.0,
        room: {
          double: {
            newPrice: 144.55,
            priceBefore: 140.52,
            priceNow: 150.61,
          },
          single: {
            newPrice: 93.91,
            priceBefore: 93.59,
            priceNow: 102.78,
          },
          suite: {
            newPrice: 179.04,
            priceBefore: 171.77,
            priceNow: 185.73,
          },
        },
      },
      {
        date: '2025-11-30',
        festivos: [
          'Festividad local de Paredes',
          'Festividad local de Fuentelahiguera de Albatages',
          'Festividad local de Romanillos de Atienza',
          'Festividad local de San Andrés del Rey',
          'Festividad local de Carmena',
        ],
        pc_ocupacion: 42.0,
        room: {
          double: {
            newPrice: 134.96,
            priceBefore: 138.07,
            priceNow: 146.66,
          },
          single: {
            newPrice: 112.89,
            priceBefore: 110.62,
            priceNow: 107.65,
          },
          suite: {
            newPrice: 198.69,
            priceBefore: 202.41,
            priceNow: 192.6,
          },
        },
      },
      {
        date: '2025-12-01',
        festivos: [],
        pc_ocupacion: 39.0,
        room: {
          double: {
            newPrice: 122.8,
            priceBefore: 117.63,
            priceNow: 128.93,
          },
          single: {
            newPrice: 100.18,
            priceBefore: 95.28,
            priceNow: 100.7,
          },
          suite: {
            newPrice: 191.35,
            priceBefore: 196.32,
            priceNow: 192.81,
          },
        },
      },
      {
        date: '2025-12-02',
        festivos: [],
        pc_ocupacion: 42.0,
        room: {
          double: {
            newPrice: 150.32,
            priceBefore: 150.83,
            priceNow: 152.8,
          },
          single: {
            newPrice: 98.93,
            priceBefore: 94.89,
            priceNow: 90.47,
          },
          suite: {
            newPrice: 213.82,
            priceBefore: 203.42,
            priceNow: 211.57,
          },
        },
      },
      {
        date: '2025-12-03',
        festivos: [],
        pc_ocupacion: 51.0,
        room: {
          double: {
            newPrice: 112.07,
            priceBefore: 117.71,
            priceNow: 112.32,
          },
          single: {
            newPrice: 158.93,
            priceBefore: 156.14,
            priceNow: 157.17,
          },
          suite: {
            newPrice: 243.07,
            priceBefore: 242.97,
            priceNow: 237.83,
          },
        },
      },
      {
        date: '2025-12-04',
        festivos: [
          'Festividad local de Congostrina',
          'Festividad local de Iniestola',
          'Festividad local de Las Hunfrías',
        ],
        pc_ocupacion: 54.0,
        room: {
          double: {
            newPrice: 172.21,
            priceBefore: 156.95,
            priceNow: 149.65,
          },
          single: {
            newPrice: 132.41,
            priceBefore: 134.78,
            priceNow: 129.19,
          },
          suite: {
            newPrice: 224.53,
            priceBefore: 209.42,
            priceNow: 203.42,
          },
        },
      },
      {
        date: '2025-12-05',
        festivos: [
          'Festividad local de Casas de Lazaro',
          'Festividad local de Matillas',
          'Festividad local de Romanones',
        ],
        pc_ocupacion: 43.0,
        room: {
          double: {
            newPrice: 139.85,
            priceBefore: 129.2,
            priceNow: 141.13,
          },
          single: {
            newPrice: 124.48,
            priceBefore: 119.42,
            priceNow: 122.85,
          },
          suite: {
            newPrice: 215.6,
            priceBefore: 220.05,
            priceNow: 216.85,
          },
        },
      },
      {
        date: '2025-12-06',
        festivos: ['Día de la Constitución Española'],
        pc_ocupacion: 54.0,
        room: {
          double: {
            newPrice: 196.86,
            priceBefore: 200.5,
            priceNow: 216.32,
          },
          single: {
            newPrice: 142.87,
            priceBefore: 145.49,
            priceNow: 159.75,
          },
          suite: {
            newPrice: 219.92,
            priceBefore: 225.23,
            priceNow: 238.76,
          },
        },
      },
      {
        date: '2025-12-07',
        festivos: [],
        pc_ocupacion: 53.0,
        room: {
          double: {
            newPrice: 177.58,
            priceBefore: 169.03,
            priceNow: 164.35,
          },
          single: {
            newPrice: 146.74,
            priceBefore: 141.66,
            priceNow: 150.9,
          },
          suite: {
            newPrice: 205.04,
            priceBefore: 190.46,
            priceNow: 203.85,
          },
        },
      },
      {
        date: '2025-12-08',
        festivos: ['Inmaculada Concepción'],
        pc_ocupacion: 53.0,
        room: {
          double: {
            newPrice: 179.13,
            priceBefore: 180.05,
            priceNow: 196.59,
          },
          single: {
            newPrice: 112.1,
            priceBefore: 107.02,
            priceNow: 106.52,
          },
          suite: {
            newPrice: 232.42,
            priceBefore: 217.07,
            priceNow: 238.25,
          },
        },
      },
      {
        date: '2025-12-09',
        festivos: [
          'Festividad local de Almuradiel',
          'Festividad local de Cañada de Calatrava',
          'Festividad local de Arrancacepas',
          'Festividad local de Barchin del Hoyo',
          'Festividad local de Horcajo De Santiago',
          'Festividad local de Viana de Jadraque',
          'Festividad local de Alcaudete de la Jara',
          'Festividad local de Camarenilla',
          'Festividad local de Casasbuenas',
          'Festividad local de Escalona',
          'Festividad local de Navaltoril',
          'Festividad local de Villaseca de la Sagra',
        ],
        pc_ocupacion: 48.0,
        room: {
          double: {
            newPrice: 172.48,
            priceBefore: 159.95,
            priceNow: 158.77,
          },
          single: {
            newPrice: 130.7,
            priceBefore: 119.77,
            priceNow: 125.14,
          },
          suite: {
            newPrice: 186.82,
            priceBefore: 189.69,
            priceNow: 200.21,
          },
        },
      },
      {
        date: '2025-12-10',
        festivos: [
          'Festividad local de Durón',
          'Festividad local de Sienes',
          'Festividad local de Navaltoril',
        ],
        pc_ocupacion: 38.0,
        room: {
          double: {
            newPrice: 172.39,
            priceBefore: 171.48,
            priceNow: 177.86,
          },
          single: {
            newPrice: 91.23,
            priceBefore: 94.81,
            priceNow: 99.21,
          },
          suite: {
            newPrice: 131.6,
            priceBefore: 125.87,
            priceNow: 134.76,
          },
        },
      },
      {
        date: '2025-12-11',
        festivos: [],
        pc_ocupacion: 49.0,
        room: {
          double: {
            newPrice: 152.7,
            priceBefore: 155.29,
            priceNow: 158.19,
          },
          single: {
            newPrice: 107.85,
            priceBefore: 106.38,
            priceNow: 102.93,
          },
          suite: {
            newPrice: 236.43,
            priceBefore: 219.41,
            priceNow: 222.37,
          },
        },
      },
      {
        date: '2025-12-12',
        festivos: [],
        pc_ocupacion: 36.0,
        room: {
          double: {
            newPrice: 124.97,
            priceBefore: 122.61,
            priceNow: 118.14,
          },
          single: {
            newPrice: 112.26,
            priceBefore: 103.61,
            priceNow: 111.69,
          },
          suite: {
            newPrice: 218.39,
            priceBefore: 199.17,
            priceNow: 194.53,
          },
        },
      },
      {
        date: '2025-12-13',
        festivos: [
          'Festividad local de Fuente el Fresno',
          'Festividad local de Retuerta del Bullaque',
          'Festividad local de Almonacid del Marquesado',
          'Festividad local de Bolliga',
          'Festividad local de Castillo-Albarañez',
          'Festividad local de Olmedilla del Campo',
          'Festividad local de Aldeanueva de Guadalajara',
          'Festividad local de Valdeavellano',
        ],
        pc_ocupacion: 49.0,
        room: {
          double: {
            newPrice: 170.68,
            priceBefore: 166.87,
            priceNow: 170.09,
          },
          single: {
            newPrice: 106.61,
            priceBefore: 104.54,
            priceNow: 108.49,
          },
          suite: {
            newPrice: 251.56,
            priceBefore: 255.35,
            priceNow: 260.76,
          },
        },
      },
      {
        date: '2025-12-14',
        festivos: [],
        pc_ocupacion: 52.0,
        room: {
          double: {
            newPrice: 142.9,
            priceBefore: 145.03,
            priceNow: 142.25,
          },
          single: {
            newPrice: 167.96,
            priceBefore: 159.11,
            priceNow: 159.28,
          },
          suite: {
            newPrice: 201.65,
            priceBefore: 199.35,
            priceNow: 212.73,
          },
        },
      },
      {
        date: '2025-12-15',
        festivos: [],
        pc_ocupacion: 32.0,
        room: {
          double: {
            newPrice: 112.72,
            priceBefore: 110.21,
            priceNow: 109.64,
          },
          single: {
            newPrice: 103.96,
            priceBefore: 100.81,
            priceNow: 96.95,
          },
          suite: {
            newPrice: 116.96,
            priceBefore: 117.21,
            priceNow: 116.23,
          },
        },
      },
      {
        date: '2025-12-16',
        festivos: [],
        pc_ocupacion: 37.0,
        room: {
          double: {
            newPrice: 142.53,
            priceBefore: 132.9,
            priceNow: 132.77,
          },
          single: {
            newPrice: 108.63,
            priceBefore: 98.79,
            priceNow: 98.45,
          },
          suite: {
            newPrice: 199.13,
            priceBefore: 199.33,
            priceNow: 189.78,
          },
        },
      },
      {
        date: '2025-12-17',
        festivos: ['Festividad local de Narboneta'],
        pc_ocupacion: 28.0,
        room: {
          double: {
            newPrice: 135.28,
            priceBefore: 140.54,
            priceNow: 145.03,
          },
          single: {
            newPrice: 67.74,
            priceBefore: 70.23,
            priceNow: 72.46,
          },
          suite: {
            newPrice: 115.92,
            priceBefore: 121.59,
            priceNow: 122.56,
          },
        },
      },
      {
        date: '2025-12-18',
        festivos: ['Festividad local de Valverdejo'],
        pc_ocupacion: 41.0,
        room: {
          double: {
            newPrice: 125.18,
            priceBefore: 128.03,
            priceNow: 130.79,
          },
          single: {
            newPrice: 134.92,
            priceBefore: 122.99,
            priceNow: 130.4,
          },
          suite: {
            newPrice: 209.34,
            priceBefore: 199.46,
            priceNow: 201.3,
          },
        },
      },
      {
        date: '2025-12-19',
        festivos: [],
        pc_ocupacion: 38.0,
        room: {
          double: {
            newPrice: 102.96,
            priceBefore: 95.63,
            priceNow: 94.19,
          },
          single: {
            newPrice: 119.64,
            priceBefore: 112.17,
            priceNow: 113.76,
          },
          suite: {
            newPrice: 196.45,
            priceBefore: 178.7,
            priceNow: 183.68,
          },
        },
      },
      {
        date: '2025-12-20',
        festivos: [
          'Festividad local de Torralba',
          'Festividad local de Villaminaya',
        ],
        pc_ocupacion: 46.0,
        room: {
          double: {
            newPrice: 151.92,
            priceBefore: 148.26,
            priceNow: 148.41,
          },
          single: {
            newPrice: 108.12,
            priceBefore: 112.99,
            priceNow: 112.97,
          },
          suite: {
            newPrice: 233.76,
            priceBefore: 216.35,
            priceNow: 223.0,
          },
        },
      },
      {
        date: '2025-12-21',
        festivos: [],
        pc_ocupacion: 37.0,
        room: {
          double: {
            newPrice: 131.79,
            priceBefore: 133.23,
            priceNow: 143.08,
          },
          single: {
            newPrice: 93.91,
            priceBefore: 90.77,
            priceNow: 99.43,
          },
          suite: {
            newPrice: 201.65,
            priceBefore: 191.11,
            priceNow: 198.78,
          },
        },
      },
      {
        date: '2025-12-22',
        festivos: [],
        pc_ocupacion: 41.0,
        room: {
          double: {
            newPrice: 90.7,
            priceBefore: 91.13,
            priceNow: 90.66,
          },
          single: {
            newPrice: 121.4,
            priceBefore: 115.5,
            priceNow: 109.85,
          },
          suite: {
            newPrice: 192.86,
            priceBefore: 188.16,
            priceNow: 198.85,
          },
        },
      },
      {
        date: '2025-12-23',
        festivos: ['Festividad local de Huecas'],
        pc_ocupacion: 53.0,
        room: {
          double: {
            newPrice: 139.28,
            priceBefore: 144.46,
            priceNow: 156.44,
          },
          single: {
            newPrice: 139.14,
            priceBefore: 127.05,
            priceNow: 138.6,
          },
          suite: {
            newPrice: 274.66,
            priceBefore: 250.9,
            priceNow: 272.06,
          },
        },
      },
      {
        date: '2025-12-24',
        festivos: [],
        pc_ocupacion: 60.0,
        room: {
          double: {
            newPrice: 225.53,
            priceBefore: 223.96,
            priceNow: 222.59,
          },
          single: {
            newPrice: 179.5,
            priceBefore: 185.69,
            priceNow: 191.05,
          },
          suite: {
            newPrice: 185.8,
            priceBefore: 190.42,
            priceNow: 187.32,
          },
        },
      },
      {
        date: '2025-12-25',
        festivos: ['Natividad del Señor'],
        pc_ocupacion: 32.0,
        room: {
          double: {
            newPrice: 95.24,
            priceBefore: 93.5,
            priceNow: 89.23,
          },
          single: {
            newPrice: 127.84,
            priceBefore: 127.51,
            priceNow: 124.52,
          },
          suite: {
            newPrice: 158.08,
            priceBefore: 154.85,
            priceNow: 163.43,
          },
        },
      },
      {
        date: '2025-12-26',
        festivos: [
          'Festividad local de Liétor',
          'Festividad local de Barbalimpia',
          'Festividad local de La Huerguina',
          'Festividad local de Humanes',
        ],
        pc_ocupacion: 44.0,
        room: {
          double: {
            newPrice: 113.65,
            priceBefore: 108.05,
            priceNow: 105.14,
          },
          single: {
            newPrice: 156.3,
            priceBefore: 145.73,
            priceNow: 157.01,
          },
          suite: {
            newPrice: 209.49,
            priceBefore: 209.89,
            priceNow: 214.53,
          },
        },
      },
      {
        date: '2025-12-27',
        festivos: ['Festividad local de Casasimarro'],
        pc_ocupacion: 41.0,
        room: {
          double: {
            newPrice: 150.88,
            priceBefore: 151.37,
            priceNow: 151.02,
          },
          single: {
            newPrice: 76.92,
            priceBefore: 72.03,
            priceNow: 68.68,
          },
          suite: {
            newPrice: 210.09,
            priceBefore: 215.42,
            priceNow: 211.81,
          },
        },
      },
      {
        date: '2025-12-28',
        festivos: ['Festividad local de Alcázar de San Juan'],
        pc_ocupacion: 68.0,
        room: {
          double: {
            newPrice: 175.27,
            priceBefore: 176.5,
            priceNow: 183.81,
          },
          single: {
            newPrice: 267.47,
            priceBefore: 248.5,
            priceNow: 247.17,
          },
          suite: {
            newPrice: 226.39,
            priceBefore: 228.55,
            priceNow: 235.64,
          },
        },
      },
      {
        date: '2025-12-29',
        festivos: [],
        pc_ocupacion: 100.0,
        room: {
          double: {
            newPrice: 275.52,
            priceBefore: 259.96,
            priceNow: 280.15,
          },
          single: {
            newPrice: 190.05,
            priceBefore: 188.33,
            priceNow: 190.44,
          },
          suite: {
            newPrice: 467.15,
            priceBefore: 450.01,
            priceNow: 489.24,
          },
        },
      },
      {
        date: '2025-12-30',
        festivos: ['Festividad local de Alcabón'],
        pc_ocupacion: 75.0,
        room: {
          double: {
            newPrice: 234.69,
            priceBefore: 244.45,
            priceNow: 262.19,
          },
          single: {
            newPrice: 154.13,
            priceBefore: 153.79,
            priceNow: 151.39,
          },
          suite: {
            newPrice: 227.38,
            priceBefore: 236.2,
            priceNow: 253.42,
          },
        },
      },
      {
        date: '2025-12-31',
        festivos: ['Festividad local de El Robledo'],
        pc_ocupacion: 61.0,
        room: {
          double: {
            newPrice: 143.86,
            priceBefore: 151.07,
            priceNow: 162.99,
          },
          single: {
            newPrice: 107.67,
            priceBefore: 104.54,
            priceNow: 114.83,
          },
          suite: {
            newPrice: 257.64,
            priceBefore: 262.56,
            priceNow: 264.11,
          },
        },
      },
    ],
  },
  {
    stablishment_name: 'Hotel Madrid',
    stablishment_id: '3',
    details: [
      {
        date: '2024-07-19',
        festivos: [
          'Festividad local de Villarejo Sobrehuerta',
          'Festividad local de Cabanillas del Campo',
          'Festividad local de Chozas de Canales',
          'Festividad local de Noblejas',
        ],
        pc_ocupacion: 17.0,
        room: {
          double: {
            newPrice: 40.76,
            priceBefore: 39.54,
            priceNow: 42.3,
          },
          single: {
            newPrice: 59.9,
            priceBefore: 61.61,
            priceNow: 64.58,
          },
          suite: {
            newPrice: 56.87,
            priceBefore: 54.52,
            priceNow: 58.75,
          },
        },
      },
      {
        date: '2024-07-20',
        festivos: ['Festividad local de Cendejas de Enmedio'],
        pc_ocupacion: 20.0,
        room: {
          double: {
            newPrice: 45.06,
            priceBefore: 45.46,
            priceNow: 45.0,
          },
          single: {
            newPrice: 76.63,
            priceBefore: 76.08,
            priceNow: 82.47,
          },
          suite: {
            newPrice: 58.72,
            priceBefore: 61.16,
            priceNow: 58.76,
          },
        },
      },
      {
        date: '2024-07-21',
        festivos: ['Festividad local de Cendejas de Padrastro'],
        pc_ocupacion: 17.0,
        room: {
          double: {
            newPrice: 33.05,
            priceBefore: 33.98,
            priceNow: 34.09,
          },
          single: {
            newPrice: 43.31,
            priceBefore: 40.76,
            priceNow: 44.15,
          },
          suite: {
            newPrice: 66.33,
            priceBefore: 63.73,
            priceNow: 63.67,
          },
        },
      },
      {
        date: '2024-07-22',
        festivos: [
          'Festividad local de Villalgordo del Júcar',
          'Festividad local de Poblete',
          'Festividad local de Huerta del Marquesado',
          'Festividad local de Poyatos',
          'Festividad local de Cendejas de Padrastro',
          'Festividad local de Mondéjar',
          'Festividad local de Valdeavellano',
          'Festividad local de Carranque',
          'Festividad local de El Viso de San Juan',
          'Festividad local de Escalonilla',
          'Festividad local de Garciotum',
          'Festividad local de Guadamur',
          'Festividad local de Layos',
          'Festividad local de Mascaraque',
          'Festividad local de Villamuelas',
          'Festividad local de Villasequilla',
        ],
        pc_ocupacion: 16.0,
        room: {
          double: {
            newPrice: 38.04,
            priceBefore: 37.85,
            priceNow: 39.1,
          },
          single: {
            newPrice: 59.71,
            priceBefore: 61.36,
            priceNow: 62.27,
          },
          suite: {
            newPrice: 61.18,
            priceBefore: 58.98,
            priceNow: 61.54,
          },
        },
      },
      {
        date: '2024-07-23',
        festivos: ['Festividad local de Castillejo de Iniesta'],
        pc_ocupacion: 23.0,
        room: {
          double: {
            newPrice: 29.88,
            priceBefore: 29.71,
            priceNow: 31.17,
          },
          single: {
            newPrice: 67.09,
            priceBefore: 66.49,
            priceNow: 72.57,
          },
          suite: {
            newPrice: 86.09,
            priceBefore: 85.94,
            priceNow: 94.43,
          },
        },
      },
      {
        date: '2024-07-24',
        festivos: [],
        pc_ocupacion: 23.0,
        room: {
          double: {
            newPrice: 30.84,
            priceBefore: 32.23,
            priceNow: 32.09,
          },
          single: {
            newPrice: 62.26,
            priceBefore: 58.24,
            priceNow: 62.41,
          },
          suite: {
            newPrice: 104.65,
            priceBefore: 105.16,
            priceNow: 103.56,
          },
        },
      },
      {
        date: '2024-07-25',
        festivos: [
          'Festividad local de Santa Ana',
          'Festividad local de Carrión de Calatrava',
          'Festividad local de Granátula de Calatrava',
          'Festividad local de La Solana',
          'Festividad local de Membrilla',
          'Festividad local de Viso del Marques',
          'Festividad local de Valsalobre',
          'Festividad local de Campisabalos',
          'Festividad local de Mohernando',
          'Festividad local de Valdelcubo',
          'Festividad local de Alcañizo',
          'Festividad local de Burujón',
          'Festividad local de Carpio de Tajo',
          'Festividad local de Espinoso del Rey',
          'Festividad local de Nombela',
          'Festividad local de Quero',
          'Festividad local de Santo Domingo Caudilla',
          'Festividad local de Sartajada',
          'Festividad local de Sotillo de las Palomas',
        ],
        pc_ocupacion: 40.0,
        room: {
          double: {
            newPrice: 108.03,
            priceBefore: 103.4,
            priceNow: 101.89,
          },
          single: {
            newPrice: 75.45,
            priceBefore: 73.26,
            priceNow: 72.41,
          },
          suite: {
            newPrice: 110.59,
            priceBefore: 114.96,
            priceNow: 121.31,
          },
        },
      },
      {
        date: '2024-07-26',
        festivos: [
          'Festividad local de Motilleja',
          'Festividad local de Pozo Lorente',
          'Festividad local de Santa Ana',
          'Festividad local de Almadén',
          'Festividad local de Cabezarados',
          'Festividad local de Fontanarejo',
          'Festividad local de Carrascosa del Campo',
          'Festividad local de El Pedernoso',
          'Festividad local de Gabaldon',
          'Festividad local de Pineda de Gigüela',
          'Festividad local de Reillo',
          'Festividad local de Valsalobre',
          'Festividad local de Vindel',
          'Festividad local de Alarilla',
          'Festividad local de Campisabalos',
          'Festividad local de Muduex',
          'Festividad local de Torremocha del Campo',
          'Festividad local de Añover de Tajo',
          'Festividad local de Burujón',
          'Festividad local de Camarenilla',
          'Festividad local de Mora',
          'Festividad local de Nombela',
          'Festividad local de Santa Ana de Pusa',
          'Festividad local de Santo Domingo Caudilla',
          'Festividad local de Villanueva de Bogas',
        ],
        pc_ocupacion: 23.0,
        room: {
          double: {
            newPrice: 29.42,
            priceBefore: 29.19,
            priceNow: 29.13,
          },
          single: {
            newPrice: 69.68,
            priceBefore: 65.16,
            priceNow: 71.42,
          },
          suite: {
            newPrice: 110.2,
            priceBefore: 111.65,
            priceNow: 112.15,
          },
        },
      },
      {
        date: '2024-07-27',
        festivos: [
          'Festividad local de Ribatajadilla',
          'Festividad local de Ciruelos del Pinar',
        ],
        pc_ocupacion: 22.0,
        room: {
          double: {
            newPrice: 27.54,
            priceBefore: 25.72,
            priceNow: 27.0,
          },
          single: {
            newPrice: 46.37,
            priceBefore: 48.28,
            priceNow: 51.51,
          },
          suite: {
            newPrice: 118.94,
            priceBefore: 112.07,
            priceNow: 109.81,
          },
        },
      },
      {
        date: '2024-07-28',
        festivos: [],
        pc_ocupacion: 30.0,
        room: {
          double: {
            newPrice: 18.75,
            priceBefore: 19.58,
            priceNow: 20.19,
          },
          single: {
            newPrice: 78.26,
            priceBefore: 71.32,
            priceNow: 67.81,
          },
          suite: {
            newPrice: 107.86,
            priceBefore: 109.33,
            priceNow: 116.71,
          },
        },
      },
      {
        date: '2024-07-29',
        festivos: [
          'Festividad local de Cabezarrubias del Puerto',
          'Festividad local de Mestanza',
          'Festividad local de Solana del Pino',
          'Festividad local de Cobisa',
          'Festividad local de Ontígola',
        ],
        pc_ocupacion: 36.0,
        room: {
          double: {
            newPrice: 74.91,
            priceBefore: 69.8,
            priceNow: 68.49,
          },
          single: {
            newPrice: 80.91,
            priceBefore: 80.16,
            priceNow: 84.81,
          },
          suite: {
            newPrice: 125.25,
            priceBefore: 124.58,
            priceNow: 124.87,
          },
        },
      },
      {
        date: '2024-07-30',
        festivos: [],
        pc_ocupacion: 23.0,
        room: {
          double: {
            newPrice: 30.0,
            priceBefore: 30.94,
            priceNow: 30.34,
          },
          single: {
            newPrice: 56.79,
            priceBefore: 58.1,
            priceNow: 55.56,
          },
          suite: {
            newPrice: 122.17,
            priceBefore: 112.72,
            priceNow: 114.01,
          },
        },
      },
      {
        date: '2024-07-31',
        festivos: ['Festividad local de Tribaldos'],
        pc_ocupacion: 22.0,
        room: {
          double: {
            newPrice: 17.17,
            priceBefore: 15.98,
            priceNow: 16.25,
          },
          single: {
            newPrice: 44.67,
            priceBefore: 46.83,
            priceNow: 47.91,
          },
          suite: {
            newPrice: 115.25,
            priceBefore: 117.98,
            priceNow: 118.41,
          },
        },
      },
      {
        date: '2024-08-01',
        festivos: [
          'Festividad local de San Pedro Palmiches',
          'Festividad local de Villalpardo',
          'Festividad local de Las Ventas de Retamosa',
        ],
        pc_ocupacion: 23.0,
        room: {
          double: {
            newPrice: 29.05,
            priceBefore: 27.09,
            priceNow: 27.07,
          },
          single: {
            newPrice: 66.67,
            priceBefore: 62.41,
            priceNow: 68.42,
          },
          suite: {
            newPrice: 112.3,
            priceBefore: 109.43,
            priceNow: 119.58,
          },
        },
      },
      {
        date: '2024-08-02',
        festivos: [
          'Festividad local de Calzada de Calatrava',
          'Festividad local de Pedro Muñoz',
          'Festividad local de Buciegas',
          'Festividad local de La Huerguina',
          'Festividad local de Villar del Águila',
          'Festividad local de Yemeda',
          'Festividad local de Amayas',
          'Festividad local de El Olivar',
          'Festividad local de El Ordial',
          'Festividad local de Escopete',
          'Festividad local de La Bodera',
          'Festividad local de Renera',
          'Festividad local de Robledo de Corpes',
          'Festividad local de Torre del Burgo',
          'Festividad local de Valdesotos',
          'Festividad local de Argés',
          'Festividad local de Los Cerralbos',
          'Festividad local de Montearagón',
        ],
        pc_ocupacion: 33.0,
        room: {
          double: {
            newPrice: 68.76,
            priceBefore: 68.65,
            priceNow: 75.43,
          },
          single: {
            newPrice: 60.15,
            priceBefore: 58.36,
            priceNow: 61.64,
          },
          suite: {
            newPrice: 112.53,
            priceBefore: 111.54,
            priceNow: 111.21,
          },
        },
      },
      {
        date: '2024-08-03',
        festivos: [
          'Festividad local de Amayas',
          'Festividad local de El Olivar',
          'Festividad local de Gualda',
          'Festividad local de La Bodera',
          'Festividad local de Bargas',
        ],
        pc_ocupacion: 23.0,
        room: {
          double: {
            newPrice: 72.73,
            priceBefore: 71.53,
            priceNow: 76.75,
          },
          single: {
            newPrice: 49.66,
            priceBefore: 48.49,
            priceNow: 49.49,
          },
          suite: {
            newPrice: 78.89,
            priceBefore: 74.39,
            priceNow: 74.38,
          },
        },
      },
      {
        date: '2024-08-04',
        festivos: [
          'Festividad local de Algar de Mesa',
          'Festividad local de Bañuelos',
          'Festividad local de Loranca de Tajuña',
          'Festividad local de Olmeda de Cobeta',
          'Festividad local de Somolinos',
          'Festividad local de Valdepinillos',
        ],
        pc_ocupacion: 30.0,
        room: {
          double: {
            newPrice: 43.86,
            priceBefore: 41.96,
            priceNow: 44.42,
          },
          single: {
            newPrice: 47.94,
            priceBefore: 48.04,
            priceNow: 51.63,
          },
          suite: {
            newPrice: 124.87,
            priceBefore: 121.44,
            priceNow: 120.36,
          },
        },
      },
      {
        date: '2024-08-05',
        festivos: [
          'Festividad local de Cenizate',
          'Festividad local de Chinchilla',
          'Festividad local de Almagro',
          'Festividad local de Valdepeñas',
          'Festividad local de Almodóvar del Pinar',
          'Festividad local de Tejadillos',
          'Festividad local de Torralba',
          'Festividad local de Villanueva de la Jara',
          'Festividad local de Anguita',
          'Festividad local de Membrillera',
          'Festividad local de Ocentejo',
          'Festividad local de Renera',
          'Festividad local de Valdesotos',
          'Festividad local de Valtablado de Rio',
          'Festividad local de Lagartera',
          'Festividad local de Malpica de Tajo',
          'Festividad local de Pulgar',
          'Festividad local de Quero',
          'Festividad local de Torralba de Oropesa',
        ],
        pc_ocupacion: 30.0,
        room: {
          double: {
            newPrice: 66.47,
            priceBefore: 69.35,
            priceNow: 71.39,
          },
          single: {
            newPrice: 48.4,
            priceBefore: 50.43,
            priceNow: 48.0,
          },
          suite: {
            newPrice: 120.61,
            priceBefore: 119.87,
            priceNow: 121.06,
          },
        },
      },
      {
        date: '2024-08-06',
        festivos: [
          'Festividad local de La Roda',
          'Festividad local de Socovos',
          'Festividad local de Pozuelo de Calatrava',
          'Festividad local de Olmedilla de Eliz',
          'Festividad local de Huérmeces del Cerro',
          'Festividad local de Málaga del Fresno',
          'Festividad local de Montarrón',
          'Festividad local de Puebla de Valles',
          'Festividad local de Santiuste',
          'Festividad local de Torralba de Oropesa',
        ],
        pc_ocupacion: 25.0,
        room: {
          double: {
            newPrice: 40.31,
            priceBefore: 40.93,
            priceNow: 39.93,
          },
          single: {
            newPrice: 70.78,
            priceBefore: 64.36,
            priceNow: 67.36,
          },
          suite: {
            newPrice: 106.51,
            priceBefore: 111.98,
            priceNow: 120.01,
          },
        },
      },
      {
        date: '2024-08-07',
        festivos: [
          'Festividad local de Tresjuncos',
          'Festividad local de Pradena de Atienza',
          'Festividad local de Somolinos',
          'Festividad local de Tordellego',
        ],
        pc_ocupacion: 33.0,
        room: {
          double: {
            newPrice: 48.17,
            priceBefore: 43.86,
            priceNow: 46.6,
          },
          single: {
            newPrice: 58.59,
            priceBefore: 61.06,
            priceNow: 60.93,
          },
          suite: {
            newPrice: 122.78,
            priceBefore: 122.66,
            priceNow: 122.94,
          },
        },
      },
      {
        date: '2024-08-08',
        festivos: [
          'Festividad local de Terrinches',
          'Festividad local de Moya',
          'Festividad local de Ocentejo',
          'Festividad local de Torremocha del Pinar',
          'Festividad local de Valtablado de Rio',
          'Festividad local de Valmojado',
        ],
        pc_ocupacion: 33.0,
        room: {
          double: {
            newPrice: 52.11,
            priceBefore: 53.45,
            priceNow: 52.36,
          },
          single: {
            newPrice: 78.68,
            priceBefore: 75.42,
            priceNow: 79.17,
          },
          suite: {
            newPrice: 117.78,
            priceBefore: 120.05,
            priceNow: 115.26,
          },
        },
      },
      {
        date: '2024-08-09',
        festivos: [
          'Festividad local de El Ballestero',
          'Festividad local de San Lorenzo de Calatrava',
          'Festividad local de Santa Cruz de Mudela',
          'Festividad local de Casas de Garcimolina',
          'Festividad local de Mota de Altarejos',
          'Festividad local de Naharros',
          'Festividad local de Adobes',
          'Festividad local de Albendiego',
          'Festividad local de Castellar de la Muela',
          'Festividad local de Peñalén',
          'Festividad local de Riofrio del Llano',
          'Festividad local de Tortuero',
          'Festividad local de Viñuelas',
          'Festividad local de Campillo de La Jara',
          'Festividad local de Parrillas',
        ],
        pc_ocupacion: 30.0,
        room: {
          double: {
            newPrice: 49.03,
            priceBefore: 49.82,
            priceNow: 49.2,
          },
          single: {
            newPrice: 59.27,
            priceBefore: 59.91,
            priceNow: 61.44,
          },
          suite: {
            newPrice: 123.57,
            priceBefore: 121.44,
            priceNow: 120.13,
          },
        },
      },
      {
        date: '2024-08-10',
        festivos: [
          'Festividad local de Socuéllamos',
          'Festividad local de Arguisuelas',
          'Festividad local de Bustares',
          'Festividad local de Carrascosa de Henares',
          'Festividad local de Negredo',
          'Festividad local de Riofrio del Llano',
          'Festividad local de Romanillos de Atienza',
          'Festividad local de Torremochuela',
        ],
        pc_ocupacion: 28.0,
        room: {
          double: {
            newPrice: 45.54,
            priceBefore: 44.2,
            priceNow: 43.05,
          },
          single: {
            newPrice: 68.37,
            priceBefore: 62.5,
            priceNow: 62.65,
          },
          suite: {
            newPrice: 141.59,
            priceBefore: 132.69,
            priceNow: 129.37,
          },
        },
      },
      {
        date: '2024-08-11',
        festivos: [
          'Festividad local de Anchuela del Campo',
          'Festividad local de Negredo',
        ],
        pc_ocupacion: 34.0,
        room: {
          double: {
            newPrice: 82.22,
            priceBefore: 76.81,
            priceNow: 80.38,
          },
          single: {
            newPrice: 68.78,
            priceBefore: 69.49,
            priceNow: 68.14,
          },
          suite: {
            newPrice: 117.2,
            priceBefore: 121.98,
            priceNow: 128.21,
          },
        },
      },
      {
        date: '2024-08-12',
        festivos: [
          'Festividad local de Chillón',
          'Festividad local de Beamud',
          'Festividad local de Cervera del Llano',
          'Festividad local de Mota de Altarejos',
          'Festividad local de Mota del Cuervo',
          'Festividad local de San Lorenzo de la Parrilla',
          'Festividad local de Villarrubio',
          'Festividad local de Arbeteta',
          'Festividad local de Campillo de Ranas',
          'Festividad local de Checa',
          'Festividad local de Concha',
          'Festividad local de Gárgoles de Abajo',
          'Festividad local de Hinojosa',
          'Festividad local de Mantiel',
          'Festividad local de Peralveche',
        ],
        pc_ocupacion: 31.0,
        room: {
          double: {
            newPrice: 34.75,
            priceBefore: 32.78,
            priceNow: 35.69,
          },
          single: {
            newPrice: 78.43,
            priceBefore: 75.57,
            priceNow: 81.27,
          },
          suite: {
            newPrice: 130.71,
            priceBefore: 120.18,
            priceNow: 120.1,
          },
        },
      },
      {
        date: '2024-08-13',
        festivos: [
          'Festividad local de Beamud',
          'Festividad local de Castillo de Garcimuñoz',
          'Festividad local de Villarrubio',
          'Festividad local de Estables',
          'Festividad local de Terzaga',
        ],
        pc_ocupacion: 27.0,
        room: {
          double: {
            newPrice: 37.42,
            priceBefore: 37.05,
            priceNow: 40.65,
          },
          single: {
            newPrice: 67.41,
            priceBefore: 64.06,
            priceNow: 64.21,
          },
          suite: {
            newPrice: 132.51,
            priceBefore: 121.53,
            priceNow: 131.91,
          },
        },
      },
      {
        date: '2024-08-14',
        festivos: [
          'Festividad local de Jorquera',
          'Festividad local de Castillo de Garcimuñoz',
          'Festividad local de San Martin de Boniches',
          'Festividad local de Uña',
          'Festividad local de Campillo de Ranas',
          'Festividad local de Congostrina',
          'Festividad local de La Mierla',
          'Festividad local de Las Navas de Jadraque',
          'Festividad local de Prados Redondos',
          'Festividad local de Rebollosa de Jadraque',
          'Festividad local de Retiendas',
          'Festividad local de Sayatón',
          'Festividad local de Terzaga',
          'Festividad local de Uceda',
          'Festividad local de Alameda de la Sagra',
          'Festividad local de Bernuy',
          'Festividad local de Fuentes',
          'Festividad local de La Estrella',
          'Festividad local de Romeral',
        ],
        pc_ocupacion: 31.0,
        room: {
          double: {
            newPrice: 43.75,
            priceBefore: 40.83,
            priceNow: 40.76,
          },
          single: {
            newPrice: 91.15,
            priceBefore: 94.42,
            priceNow: 103.62,
          },
          suite: {
            newPrice: 137.5,
            priceBefore: 130.43,
            priceNow: 127.65,
          },
        },
      },
      {
        date: '2024-08-15',
        festivos: [
          'Asunción de la Virgen',
          'Festividad local de Hontecillas',
          'Festividad local de Anchuela del Pedregal',
          'Festividad local de Arbeteta',
          'Festividad local de Gascueña de Bornova',
          'Festividad local de Peralveche',
          'Festividad local de Tordelpalo',
        ],
        pc_ocupacion: 34.0,
        room: {
          double: {
            newPrice: 43.38,
            priceBefore: 41.99,
            priceNow: 43.76,
          },
          single: {
            newPrice: 85.65,
            priceBefore: 82.4,
            priceNow: 80.63,
          },
          suite: {
            newPrice: 141.95,
            priceBefore: 132.12,
            priceNow: 132.65,
          },
        },
      },
      {
        date: '2024-08-16',
        festivos: [
          'Festividad local de Barrax',
          'Festividad local de Bormate',
          'Festividad local de Fuentealbilla',
          'Festividad local de Letur',
          'Festividad local de Salobre',
          'Festividad local de Tobarra',
          'Festividad local de Villalgordo del Júcar',
          'Festividad local de Villarrobledo',
          'Festividad local de Villatoya',
          'Festividad local de Alamillo',
          'Festividad local de Alcolea de Calatrava',
          'Festividad local de Moral de Calatrava',
          'Festividad local de Torre de Juan Abad',
          'Festividad local de Aliaguilla',
          'Festividad local de Arcos de la Cantera',
          'Festividad local de Barbalimpia',
          'Festividad local de Bascuñana de San Pedro',
          'Festividad local de Bolliga',
          'Festividad local de Boniches',
          'Festividad local de Buenache de la Sierra',
          'Festividad local de Campillos Paravientos',
          'Festividad local de Cañamares',
          'Festividad local de Carboneras de Guadazaon',
          'Festividad local de Castillejo-Sierra',
          'Festividad local de El Provencio',
          'Festividad local de Enguidanos',
          'Festividad local de Fuentesbuenas',
          'Festividad local de Garcinarro',
          'Festividad local de Henarejos',
          'Festividad local de La Pesquera',
          'Festividad local de Lagunaseca',
          'Festividad local de Landete',
          'Festividad local de Ledaña',
          'Festividad local de Monteagudo de las Salinas',
          'Festividad local de Navalon',
          'Festividad local de Olivares del Jucar',
          'Festividad local de Olmedilla de Alarcon',
          'Festividad local de Olmedilla del Campo',
          'Festividad local de Pajaroncillo',
          'Festividad local de Portalrubio de Guadamejud',
          'Festividad local de Portilla',
          'Festividad local de Poyatos',
          'Festividad local de Rada de Haro',
          'Festividad local de Reillo',
          'Festividad local de Salinas del Manzano',
          'Festividad local de Sotoca',
          'Festividad local de Tondos',
          'Festividad local de Torrecilla',
          'Festividad local de Uña',
          'Festividad local de Valdecabras',
          'Festividad local de Valdemorillo de la Sierra',
          'Festividad local de Valeria',
          'Festividad local de Villaescusa de Haro',
          'Festividad local de Villalba de la Sierra',
          'Festividad local de Villanueva de los Escuderos',
          'Festividad local de Villar de la Encina',
          'Festividad local de Villar del Infantado',
          'Festividad local de Villarejo del Espartal',
          'Festividad local de Villarejo Seco',
          'Festividad local de Villarta',
          'Festividad local de Villora',
          'Festividad local de Vindel',
          'Festividad local de Alcocer',
          'Festividad local de Alcolea de las Peñas',
          'Festividad local de Alcolea del Pinar',
          'Festividad local de Aldeanueva de Atienza',
          'Festividad local de Aldeanueva de Guadalajara',
          'Festividad local de Alique',
          'Festividad local de Alocén',
          'Festividad local de Anquela del Pedregal',
          'Festividad local de Baides',
          'Festividad local de Brihuega',
          'Festividad local de Bujarrabal',
          'Festividad local de Bustares',
          'Festividad local de Canales del Ducado',
          'Festividad local de Cincovillas',
          'Festividad local de Cívica',
          'Festividad local de Condemios de Arriba',
          'Festividad local de Copernal',
          'Festividad local de Durón',
          'Festividad local de El Sotillo',
          'Festividad local de Fontanar',
          'Festividad local de Fuentelsaz',
          'Festividad local de Fuentelviejo',
          'Festividad local de Gárgoles de Abajo',
          'Festividad local de Gascueña de Bornova',
          'Festividad local de Illana',
          'Festividad local de Las Navas de Jadraque',
          'Festividad local de Mazarete',
          'Festividad local de Megina',
          'Festividad local de Olmeda de Cobeta',
          'Festividad local de Piqueras',
          'Festividad local de Poveda de la Sierra',
          'Festividad local de Rebollosa de Jadraque',
          'Festividad local de San Andrés del Congosto',
          'Festividad local de San Andrés del Rey',
          'Festividad local de Sayatón',
          'Festividad local de Selas',
          'Festividad local de Semillas',
          'Festividad local de Sienes',
          'Festividad local de Sigüenza',
          'Festividad local de Tierzo',
          'Festividad local de Tordelpalo',
          'Festividad local de Tordelrábano',
          'Festividad local de Torrejón del Rey',
          'Festividad local de Uceda',
          'Festividad local de Valdearenas',
          'Festividad local de Yélamos de Arriba',
          'Festividad local de Zarzuela de Jadraque',
          'Festividad local de Alameda de la Sagra',
          'Festividad local de Camarena',
          'Festividad local de Camuñas',
          'Festividad local de Casar de Escalona',
          'Festividad local de Cervera de los Montes',
          'Festividad local de Erustes',
          'Festividad local de Escalona',
          'Festividad local de Las Navillas',
          'Festividad local de Los Alares',
          'Festividad local de Magán',
          'Festividad local de Mazarambroz',
          'Festividad local de Mohedas de La Jara',
          'Festividad local de Navalcán',
          'Festividad local de Nuño Gómez',
          'Festividad local de Palomeque',
          'Festividad local de Quintanar de la Orden',
          'Festividad local de Quismondo',
          'Festividad local de San Román de los Montes',
          'Festividad local de Seseña',
          'Festividad local de Valdeverdeja',
        ],
        pc_ocupacion: 26.0,
        room: {
          double: {
            newPrice: 56.04,
            priceBefore: 54.64,
            priceNow: 56.81,
          },
          single: {
            newPrice: 50.55,
            priceBefore: 47.36,
            priceNow: 50.65,
          },
          suite: {
            newPrice: 134.31,
            priceBefore: 134.65,
            priceNow: 129.88,
          },
        },
      },
      {
        date: '2024-08-17',
        festivos: [
          'Festividad local de Hijes',
          'Festividad local de Mazarete',
          'Festividad local de Piqueras',
          'Festividad local de Poveda de la Sierra',
          'Festividad local de Taravilla',
        ],
        pc_ocupacion: 45.0,
        room: {
          double: {
            newPrice: 99.47,
            priceBefore: 104.31,
            priceNow: 99.82,
          },
          single: {
            newPrice: 105.71,
            priceBefore: 97.57,
            priceNow: 106.36,
          },
          suite: {
            newPrice: 128.86,
            priceBefore: 123.64,
            priceNow: 119.03,
          },
        },
      },
      {
        date: '2024-08-18',
        festivos: [
          'Festividad local de Castilnuevo',
          'Festividad local de El Ordial',
          'Festividad local de Estriegana',
          'Festividad local de La Toba',
          'Festividad local de Taravilla',
          'Festividad local de Torrecuadrada de Molina',
        ],
        pc_ocupacion: 40.0,
        room: {
          double: {
            newPrice: 104.59,
            priceBefore: 101.72,
            priceNow: 110.97,
          },
          single: {
            newPrice: 85.6,
            priceBefore: 82.18,
            priceNow: 90.17,
          },
          suite: {
            newPrice: 134.7,
            priceBefore: 132.2,
            priceNow: 135.73,
          },
        },
      },
      {
        date: '2024-08-19',
        festivos: [
          'Festividad local de Nerpio',
          'Festividad local de Membrilla',
          'Festividad local de El Provencio',
          'Festividad local de Moya',
          'Festividad local de Tinajas',
          'Festividad local de Torrubia del Castillo',
          'Festividad local de Villar de Domingo Garcia',
          'Festividad local de Alocén',
          'Festividad local de Estriegana',
          'Festividad local de Labros',
          'Festividad local de Tamajón',
          'Festividad local de Esquivias',
          'Festividad local de Retamoso de la Jara',
          'Festividad local de Valdeverdeja',
        ],
        pc_ocupacion: 29.0,
        room: {
          double: {
            newPrice: 58.29,
            priceBefore: 55.97,
            priceNow: 56.53,
          },
          single: {
            newPrice: 78.46,
            priceBefore: 77.16,
            priceNow: 76.01,
          },
          suite: {
            newPrice: 90.88,
            priceBefore: 86.97,
            priceNow: 93.02,
          },
        },
      },
      {
        date: '2024-08-20',
        festivos: [
          'Festividad local de Bogarra',
          'Festividad local de Letur',
          'Festividad local de Nerpio',
          'Festividad local de Hinojosas de Calatrava',
          'Festividad local de Campillos-Sierra',
          'Festividad local de La Hinojosa',
          'Festividad local de Torrubia del Castillo',
          'Festividad local de El Pobo de Dueñas',
          'Festividad local de Fuembellida',
          'Festividad local de Tamajón',
          'Festividad local de Esquivias',
        ],
        pc_ocupacion: 33.0,
        room: {
          double: {
            newPrice: 63.53,
            priceBefore: 64.66,
            priceNow: 64.53,
          },
          single: {
            newPrice: 73.04,
            priceBefore: 75.44,
            priceNow: 82.9,
          },
          suite: {
            newPrice: 136.91,
            priceBefore: 128.83,
            priceNow: 139.25,
          },
        },
      },
      {
        date: '2024-08-21',
        festivos: [
          'Festividad local de Iniesta',
          'Festividad local de Cubillejo de la Sierra',
          'Festividad local de Fuembellida',
        ],
        pc_ocupacion: 34.0,
        room: {
          double: {
            newPrice: 69.45,
            priceBefore: 71.6,
            priceNow: 73.01,
          },
          single: {
            newPrice: 60.9,
            priceBefore: 55.77,
            priceNow: 53.82,
          },
          suite: {
            newPrice: 142.24,
            priceBefore: 140.38,
            priceNow: 134.59,
          },
        },
      },
      {
        date: '2024-08-22',
        festivos: [
          'Festividad local de Ciudad Real',
          'Festividad local de San Clemente',
          'Festividad local de Alcoroches',
          'Festividad local de La Olmeda de Jadraque',
          'Festividad local de Morenilla',
          'Festividad local de Torremocha de Jadraque',
        ],
        pc_ocupacion: 38.0,
        room: {
          double: {
            newPrice: 63.69,
            priceBefore: 60.3,
            priceNow: 59.9,
          },
          single: {
            newPrice: 80.42,
            priceBefore: 83.79,
            priceNow: 79.62,
          },
          suite: {
            newPrice: 149.95,
            priceBefore: 140.34,
            priceNow: 153.8,
          },
        },
      },
      {
        date: '2024-08-23',
        festivos: [
          'Festividad local de Madrigueras',
          'Festividad local de Pozuelo',
          'Festividad local de Reolid',
          'Festividad local de Yeste',
          'Festividad local de Los Pozuelos de Calatrava',
          'Festividad local de Atalaya del Cañavate',
          'Festividad local de Fresneda de Altarejos',
          'Festividad local de La Almarcha',
          'Festividad local de Laguna del Marquesado',
          'Festividad local de Torrejoncillo del Rey',
          'Festividad local de Alcoroches',
          'Festividad local de Arbancón',
          'Festividad local de Armuña de Tajuña',
          'Festividad local de Casa de Uceda',
          'Festividad local de Galápagos',
          'Festividad local de Galve de Sorbe',
          'Festividad local de Henche',
          'Festividad local de Hita',
          'Festividad local de Morenilla',
          'Festividad local de Tartanedo',
          'Festividad local de Torremocha de Jadraque',
          'Festividad local de Valdepeñas de la Sierra',
          'Festividad local de Yebes',
          'Festividad local de Yélamos de Arriba',
          'Festividad local de Aldeanueva de San Bartolomé',
          'Festividad local de Cabañas de Yepes',
          'Festividad local de Gargantilla',
          'Festividad local de Marrupe',
          'Festividad local de Puerto de San Vicente',
          'Festividad local de San Bartolomé de las Abiertas',
        ],
        pc_ocupacion: 35.0,
        room: {
          double: {
            newPrice: 75.63,
            priceBefore: 70.56,
            priceNow: 75.47,
          },
          single: {
            newPrice: 67.34,
            priceBefore: 64.94,
            priceNow: 61.75,
          },
          suite: {
            newPrice: 122.69,
            priceBefore: 126.48,
            priceNow: 128.0,
          },
        },
      },
      {
        date: '2024-08-24',
        festivos: [
          'Festividad local de Tarazona de la Mancha',
          'Festividad local de Almagro',
          'Festividad local de Campo de Criptana',
          'Festividad local de Arcos de la Sierra',
          'Festividad local de Casasimarro',
          'Festividad local de Castillejo del Romeral',
          'Festividad local de Colliga',
          'Festividad local de Villarejo de la Peñuela',
          'Festividad local de Campillo de Dueñas',
          'Festividad local de El Cubillo de Uceda',
          'Festividad local de Jirueque',
          'Festividad local de Lupiana',
          'Festividad local de Matarrubia',
          'Festividad local de Rillo de Gallo',
          'Festividad local de El Viso de San Juan',
          'Festividad local de Navahermosa',
        ],
        pc_ocupacion: 35.0,
        room: {
          double: {
            newPrice: 100.66,
            priceBefore: 105.09,
            priceNow: 106.23,
          },
          single: {
            newPrice: 71.84,
            priceBefore: 67.09,
            priceNow: 68.62,
          },
          suite: {
            newPrice: 128.22,
            priceBefore: 123.71,
            priceNow: 130.87,
          },
        },
      },
      {
        date: '2024-08-25',
        festivos: ['Festividad local de Rillo de Gallo'],
        pc_ocupacion: 31.0,
        room: {
          double: {
            newPrice: 82.92,
            priceBefore: 76.64,
            priceNow: 77.33,
          },
          single: {
            newPrice: 83.09,
            priceBefore: 78.73,
            priceNow: 84.42,
          },
          suite: {
            newPrice: 127.13,
            priceBefore: 124.43,
            priceNow: 127.65,
          },
        },
      },
      {
        date: '2024-08-26',
        festivos: [
          'Festividad local de Carcelén',
          'Festividad local de Ballesteros de Calatrava',
          'Festividad local de Navalpino',
          'Festividad local de Alcohujate',
          'Festividad local de Belmonte',
          'Festividad local de Casas de Fernando Alonso',
          'Festividad local de Casas de Haro',
          'Festividad local de Castillejo del Romeral',
          'Festividad local de Fresneda de Altarejos',
          'Festividad local de Gascueña',
          'Festividad local de Piqueras del Castillo',
          'Festividad local de Pozorrubio de Santiago',
          'Festividad local de Ucles',
          'Festividad local de Vellisca',
          'Festividad local de Checa',
          'Festividad local de Cubillejo del Sitio',
          'Festividad local de Hita',
          'Festividad local de Sacecorbo',
          'Festividad local de Valfermoso de Tajuña',
          'Festividad local de Alcañizo',
          'Festividad local de Aldeanueva de Barbarroya',
          'Festividad local de Aldeanueva de San Bartolomé',
          'Festividad local de Añover de Tajo',
          'Festividad local de Arisgotas',
          'Festividad local de Cabañas de Yepes',
          'Festividad local de Corral de Almaguer',
          'Festividad local de Hormigos',
          'Festividad local de Huerta de Valdecarábanos',
          'Festividad local de Mesegar de Tajo',
          'Festividad local de Orgaz',
          'Festividad local de San Bartolomé de las Abiertas',
          'Festividad local de Santa Cruz de la Zarza',
          'Festividad local de Torrecilla de La Jara',
        ],
        pc_ocupacion: 34.0,
        room: {
          double: {
            newPrice: 65.11,
            priceBefore: 67.29,
            priceNow: 73.72,
          },
          single: {
            newPrice: 39.91,
            priceBefore: 38.36,
            priceNow: 41.95,
          },
          suite: {
            newPrice: 138.4,
            priceBefore: 127.7,
            priceNow: 133.84,
          },
        },
      },
      {
        date: '2024-08-27',
        festivos: [
          'Festividad local de Paredes de Sigüenza',
          'Festividad local de Valfermoso de Tajuña',
          'Festividad local de Albarreal de Tajo',
          'Festividad local de Huerta de Valdecarábanos',
        ],
        pc_ocupacion: 31.0,
        room: {
          double: {
            newPrice: 76.88,
            priceBefore: 75.66,
            priceNow: 76.4,
          },
          single: {
            newPrice: 81.46,
            priceBefore: 78.78,
            priceNow: 82.93,
          },
          suite: {
            newPrice: 136.9,
            priceBefore: 130.01,
            priceNow: 125.42,
          },
        },
      },
      {
        date: '2024-08-28',
        festivos: [
          'Festividad local de Casas Ibáñez',
          'Festividad local de Peñas de San Pedro',
          'Festividad local de Fernán Caballero',
          'Festividad local de Villahermosa',
          'Festividad local de Castillejo de Iniesta',
          'Festividad local de Collados',
          'Festividad local de Mota del Cuervo',
          'Festividad local de Zafrilla',
          'Festividad local de Atanzón',
          'Festividad local de Fuentelencina',
          'Festividad local de Hombrados',
          'Festividad local de Membrillera',
          'Festividad local de Albarreal de Tajo',
          'Festividad local de El Toboso',
        ],
        pc_ocupacion: 35.0,
        room: {
          double: {
            newPrice: 66.93,
            priceBefore: 69.7,
            priceNow: 69.94,
          },
          single: {
            newPrice: 63.55,
            priceBefore: 62.88,
            priceNow: 65.82,
          },
          suite: {
            newPrice: 127.29,
            priceBefore: 118.74,
            priceNow: 124.56,
          },
        },
      },
      {
        date: '2024-08-29',
        festivos: [
          'Festividad local de Puebla de Don Rodrigo',
          'Festividad local de Aranzueque',
          'Festividad local de Espinosa de Henares',
          'Festividad local de La Yunta',
          'Festividad local de Millana',
          'Festividad local de Borox',
        ],
        pc_ocupacion: 30.0,
        room: {
          double: {
            newPrice: 74.58,
            priceBefore: 71.19,
            priceNow: 77.64,
          },
          single: {
            newPrice: 60.37,
            priceBefore: 54.99,
            priceNow: 55.64,
          },
          suite: {
            newPrice: 130.21,
            priceBefore: 126.12,
            priceNow: 127.27,
          },
        },
      },
      {
        date: '2024-08-30',
        festivos: [
          'Festividad local de Huelamo',
          'Festividad local de Pajaron',
          'Festividad local de Espinosa de Henares',
          'Festividad local de Pinilla de Jadraque 5 febrero y',
          'Festividad local de Quer',
          'Festividad local de Saelices de la Sal',
          'Festividad local de Solanillos del Extremo',
          'Festividad local de Torrecuadradilla',
          'Festividad local de Valdeaveruelo',
          'Festividad local de Villares de Jadraque',
          'Festividad local de Viñuelas',
          'Festividad local de Aldea en Cabo',
          'Festividad local de Borox',
          'Festividad local de Las Ventas de San Julian',
          'Festividad local de Torrico',
          'Festividad local de Villaluenga de la Sagra',
        ],
        pc_ocupacion: 35.0,
        room: {
          double: {
            newPrice: 73.89,
            priceBefore: 68.39,
            priceNow: 65.71,
          },
          single: {
            newPrice: 62.93,
            priceBefore: 59.91,
            priceNow: 61.83,
          },
          suite: {
            newPrice: 122.78,
            priceBefore: 125.1,
            priceNow: 123.52,
          },
        },
      },
      {
        date: '2024-08-31',
        festivos: [
          'Festividad local de Tomelloso',
          'Festividad local de Ciruelos del Pinar',
          'Festividad local de Illescas',
          'Festividad local de Villarejo de Montalbán',
        ],
        pc_ocupacion: 39.0,
        room: {
          double: {
            newPrice: 77.48,
            priceBefore: 76.11,
            priceNow: 74.0,
          },
          single: {
            newPrice: 75.68,
            priceBefore: 71.5,
            priceNow: 75.03,
          },
          suite: {
            newPrice: 118.2,
            priceBefore: 120.74,
            priceNow: 129.81,
          },
        },
      },
      {
        date: '2024-09-01',
        festivos: [
          'Festividad local de Cuevas de Velasco',
          'Festividad local de Villar del Maestre',
        ],
        pc_ocupacion: 36.0,
        room: {
          double: {
            newPrice: 74.27,
            priceBefore: 69.83,
            priceNow: 66.97,
          },
          single: {
            newPrice: 83.04,
            priceBefore: 80.5,
            priceNow: 84.9,
          },
          suite: {
            newPrice: 127.71,
            priceBefore: 130.31,
            priceNow: 134.38,
          },
        },
      },
      {
        date: '2024-09-02',
        festivos: [
          'Festividad local de Almansa',
          'Festividad local de Argamasilla de Alba',
          'Festividad local de Daimiel',
          'Festividad local de Arrancacepas',
          'Festividad local de Cueva del Hierro',
          'Festividad local de Las Pedroñeras',
          'Festividad local de Motilla del Palancar',
          'Festividad local de Pajaroncillo',
          'Festividad local de Valhermoso de la Fuente',
          'Festividad local de Villalba del Rey',
          'Festividad local de Caspueñas',
          'Festividad local de El Casar',
          'Festividad local de Molina de Aragón',
          'Festividad local de Solanillos del Extremo',
          'Festividad local de Burguillos de Toledo',
          'Festividad local de Hontanar',
          'Festividad local de La Fresneda de la Jara',
          'Festividad local de La Puebla de Almoradiel',
          'Festividad local de Las Ventas de San Julian',
          'Festividad local de Torrico',
        ],
        pc_ocupacion: 27.0,
        room: {
          double: {
            newPrice: 56.68,
            priceBefore: 56.36,
            priceNow: 59.59,
          },
          single: {
            newPrice: 61.79,
            priceBefore: 62.0,
            priceNow: 65.68,
          },
          suite: {
            newPrice: 124.35,
            priceBefore: 123.41,
            priceNow: 118.45,
          },
        },
      },
      {
        date: '2024-09-03',
        festivos: [
          'Festividad local de El Robledo',
          'Festividad local de Cueva del Hierro',
        ],
        pc_ocupacion: 39.0,
        room: {
          double: {
            newPrice: 81.6,
            priceBefore: 75.87,
            priceNow: 75.43,
          },
          single: {
            newPrice: 69.38,
            priceBefore: 65.53,
            priceNow: 62.26,
          },
          suite: {
            newPrice: 122.02,
            priceBefore: 125.28,
            priceNow: 125.1,
          },
        },
      },
      {
        date: '2024-09-04',
        festivos: ['Festividad local de Ayna'],
        pc_ocupacion: 42.0,
        room: {
          double: {
            newPrice: 66.33,
            priceBefore: 65.32,
            priceNow: 69.04,
          },
          single: {
            newPrice: 120.53,
            priceBefore: 122.27,
            priceNow: 122.49,
          },
          suite: {
            newPrice: 151.52,
            priceBefore: 145.83,
            priceNow: 149.01,
          },
        },
      },
      {
        date: '2024-09-05',
        festivos: [
          'Festividad local de Alcaraz',
          'Festividad local de Daimiel',
          'Festividad local de Villamayor de Santiago',
          'Festividad local de Corduente',
        ],
        pc_ocupacion: 39.0,
        room: {
          double: {
            newPrice: 87.69,
            priceBefore: 90.29,
            priceNow: 89.9,
          },
          single: {
            newPrice: 74.64,
            priceBefore: 76.72,
            priceNow: 83.64,
          },
          suite: {
            newPrice: 137.57,
            priceBefore: 131.14,
            priceNow: 127.8,
          },
        },
      },
      {
        date: '2024-09-06',
        festivos: [
          'Festividad local de Ayna',
          'Festividad local de Bienservida',
          'Festividad local de Puertollano',
          'Festividad local de Almendros',
          'Festividad local de El Cañavate',
          'Festividad local de Garaballa',
          'Festividad local de Salvacañete',
          'Festividad local de Villalba de la Sierra',
          'Festividad local de Villarejo Sobrehuerta',
          'Festividad local de Alovera',
          'Festividad local de Aragosa',
          'Festividad local de Casa de Uceda',
          'Festividad local de Corduente',
          'Festividad local de Malaguilla',
          'Festividad local de Mandayona',
          'Festividad local de Miralrío',
          'Festividad local de Orea',
          'Festividad local de Pareja',
          'Festividad local de Peñalver',
          'Festividad local de Riba de Saelices',
          'Festividad local de Romanones',
          'Festividad local de Trillo',
          'Festividad local de Carranque',
          'Festividad local de El Bercial',
          'Festividad local de Talavera de la Reina',
          'Festividad local de Talavera la Nueva',
          'Festividad local de Velada',
          'Festividad local de Villarrubia de Santiago',
        ],
        pc_ocupacion: 28.0,
        room: {
          double: {
            newPrice: 28.82,
            priceBefore: 30.12,
            priceNow: 28.64,
          },
          single: {
            newPrice: 90.36,
            priceBefore: 85.37,
            priceNow: 83.59,
          },
          suite: {
            newPrice: 140.24,
            priceBefore: 130.55,
            priceNow: 132.15,
          },
        },
      },
      {
        date: '2024-09-07',
        festivos: [
          'Festividad local de Minaya',
          'Festividad local de Alhambra',
          'Festividad local de Cereceda',
          'Festividad local de Majaelrayo',
          'Festividad local de Puebla de Beleña',
          'Festividad local de El Bercial',
        ],
        pc_ocupacion: 33.0,
        room: {
          double: {
            newPrice: 58.97,
            priceBefore: 58.2,
            priceNow: 56.43,
          },
          single: {
            newPrice: 71.4,
            priceBefore: 69.12,
            priceNow: 68.03,
          },
          suite: {
            newPrice: 124.8,
            priceBefore: 123.35,
            priceNow: 123.78,
          },
        },
      },
      {
        date: '2024-09-08',
        festivos: [
          'Festividad local de Las Majadas',
          'Festividad local de Ribatajada',
          'Festividad local de Algar de Mesa',
          'Festividad local de Alhóndiga',
          'Festividad local de Campillo de Dueñas',
          'Festividad local de Casasana',
          'Festividad local de Cereceda',
          'Festividad local de El Recuenco',
          'Festividad local de Miedes de Atienza',
          'Festividad local de Monasterio',
          'Festividad local de Ventosa',
          'Festividad local de Zaorejas',
        ],
        pc_ocupacion: 38.0,
        room: {
          double: {
            newPrice: 90.95,
            priceBefore: 93.94,
            priceNow: 89.98,
          },
          single: {
            newPrice: 54.8,
            priceBefore: 52.41,
            priceNow: 56.55,
          },
          suite: {
            newPrice: 170.45,
            priceBefore: 158.86,
            priceNow: 169.51,
          },
        },
      },
      {
        date: '2024-09-09',
        festivos: [
          'Festividad local de Albacete',
          'Festividad local de Alborea',
          'Festividad local de Caudete',
          'Festividad local de Fuensanta',
          'Festividad local de Aldea del Rey',
          'Festividad local de Argamasilla de Calatrava',
          'Festividad local de Calzada de Calatrava',
          'Festividad local de Fuencaliente',
          'Festividad local de Llanos del Caudillo',
          'Festividad local de Luciana',
          'Festividad local de Miguelturra',
          'Festividad local de Puebla del Príncipe',
          'Festividad local de Valdemanco del Esteras',
          'Festividad local de Villahermosa',
          'Festividad local de Albendea',
          'Festividad local de Alcohujate',
          'Festividad local de Almendros',
          'Festividad local de Altarejos',
          'Festividad local de Barajas de Melo',
          'Festividad local de Belmonte',
          'Festividad local de Buendía',
          'Festividad local de Campillo de Altobuey',
          'Festividad local de Canalejas del Arroyo',
          'Festividad local de Cañada del Hoyo',
          'Festividad local de Cañete',
          'Festividad local de Cañizares',
          'Festividad local de Culebras',
          'Festividad local de El Cañavate',
          'Festividad local de El Herrumblar',
          'Festividad local de Horcajada de la Torre',
          'Festividad local de Huerta de la Obispalía',
          'Festividad local de La Peraleja',
          'Festividad local de Lagunaseca',
          'Festividad local de Loranca del Campo',
          'Festividad local de Puebla de Almenara',
          'Festividad local de Ribagorda',
          'Festividad local de Santa Maria del Campo Rus',
          'Festividad local de Tarancon',
          'Festividad local de Torrubia del Campo',
          'Festividad local de Vega del Codorno',
          'Festividad local de Villanueva de Guadamejud',
          'Festividad local de Villar de Cañas',
          'Festividad local de Villar de Olalla',
          'Festividad local de Villar del Horno',
          'Festividad local de Villarejo de Fuentes',
          'Festividad local de Balbacil',
          'Festividad local de Casasana',
          'Festividad local de Clares',
          'Festividad local de Cobeta',
          'Festividad local de Codes',
          'Festividad local de Condemios de Abajo',
          'Festividad local de Hontoba',
          'Festividad local de Horche',
          'Festividad local de Illana',
          'Festividad local de Maranchón',
          'Festividad local de Monasterio',
          'Festividad local de Pálmaces de Jadraque',
          'Festividad local de Pareja',
          'Festividad local de Peñalver',
          'Festividad local de Tendilla',
          'Festividad local de Torija',
          'Festividad local de Tortuera',
          'Festividad local de Trillo',
          'Festividad local de Turmiel',
          'Festividad local de Ventosa',
          'Festividad local de Yebra',
          'Festividad local de Alcabón',
          'Festividad local de Arisgotas',
          'Festividad local de Carriches',
          'Festividad local de Castillo de Bayuela',
          'Festividad local de Cebolla',
          'Festividad local de Cedillo del Condado',
          'Festividad local de Consuegra',
          'Festividad local de Domingo Pérez',
          'Festividad local de Espinoso del Rey',
          'Festividad local de Gamonal',
          'Festividad local de Las Hunfrías',
          'Festividad local de Los Navalucillos',
          'Festividad local de Lillo',
          'Festividad local de Méntrida',
          'Festividad local de Miguel Esteban',
          'Festividad local de Mocejón',
          'Festividad local de Navamorcuende',
          'Festividad local de Ocaña',
          'Festividad local de Orgaz',
          'Festividad local de Oropesa y Corchuela',
          'Festividad local de Paredes de Escalona',
          'Festividad local de Pepino',
          'Festividad local de Recas',
          'Festividad local de Sonseca',
          'Festividad local de Turleque',
          'Festividad local de Villamiel',
          'Festividad local de Villanueva de Bogas',
          'Festividad local de Villarrubia de Santiago',
          'Festividad local de Villaseca de la Sagra',
        ],
        pc_ocupacion: 38.0,
        room: {
          double: {
            newPrice: 89.41,
            priceBefore: 85.4,
            priceNow: 81.86,
          },
          single: {
            newPrice: 107.92,
            priceBefore: 104.27,
            priceNow: 111.33,
          },
          suite: {
            newPrice: 160.81,
            priceBefore: 152.04,
            priceNow: 157.44,
          },
        },
      },
      {
        date: '2024-09-10',
        festivos: [
          'Festividad local de Barajas de Melo',
          'Festividad local de Cañizares',
          'Festividad local de Vega del Codorno',
          'Festividad local de Almonacid de Zorita',
          'Festividad local de Horche',
          'Festividad local de Pálmaces de Jadraque',
          'Festividad local de Tendilla',
          'Festividad local de Torija',
          'Festividad local de Tortuera',
          'Festividad local de Valdelcubo',
          'Festividad local de Los Navalucillos',
          'Festividad local de Ocaña',
        ],
        pc_ocupacion: 36.0,
        room: {
          double: {
            newPrice: 49.73,
            priceBefore: 46.48,
            priceNow: 49.07,
          },
          single: {
            newPrice: 95.83,
            priceBefore: 92.06,
            priceNow: 99.57,
          },
          suite: {
            newPrice: 145.13,
            priceBefore: 147.82,
            priceNow: 149.01,
          },
        },
      },
      {
        date: '2024-09-11',
        festivos: [
          'Festividad local de Chumillas',
          'Festividad local de Hontanaya',
          'Festividad local de Pinarejo',
          'Festividad local de Almonacid de Zorita',
          'Festividad local de Almendral de La Cañada',
          'Festividad local de Retamoso de la Jara',
          'Festividad local de Villa de Don Fadrique',
        ],
        pc_ocupacion: 37.0,
        room: {
          double: {
            newPrice: 90.94,
            priceBefore: 86.07,
            priceNow: 83.04,
          },
          single: {
            newPrice: 75.46,
            priceBefore: 71.94,
            priceNow: 77.91,
          },
          suite: {
            newPrice: 149.87,
            priceBefore: 145.43,
            priceNow: 154.05,
          },
        },
      },
      {
        date: '2024-09-12',
        festivos: [
          'Festividad local de El Pozuelo',
          'Festividad local de Priego',
          'Festividad local de Guadalajara',
          'Festividad local de Calera y Chozas',
          'Festividad local de Fuensalida',
          'Festividad local de Villatobas',
          'Festividad local de Yuncos',
        ],
        pc_ocupacion: 41.0,
        room: {
          double: {
            newPrice: 80.32,
            priceBefore: 77.39,
            priceNow: 79.37,
          },
          single: {
            newPrice: 87.2,
            priceBefore: 85.06,
            priceNow: 82.18,
          },
          suite: {
            newPrice: 138.69,
            priceBefore: 140.66,
            priceNow: 150.47,
          },
        },
      },
      {
        date: '2024-09-13',
        festivos: [
          'Festividad local de Cañada de Calatrava',
          'Festividad local de Castellar de Santiago',
          'Festividad local de Fuenllana',
          'Festividad local de Montiel',
          'Festividad local de Saceruela',
          'Festividad local de Arcas',
          'Festividad local de Belmontejo',
          'Festividad local de Chillaron de Cuenca',
          'Festividad local de Horcajo De Santiago',
          'Festividad local de Leganiel',
          'Festividad local de Los Valdecolmenas',
          'Festividad local de Mariana',
          'Festividad local de Montalbanejo',
          'Festividad local de Portilla',
          'Festividad local de Priego',
          'Festividad local de Saceda-Trasierra',
          'Festividad local de Santa Maria del Val',
          'Festividad local de Valdeolivas',
          'Festividad local de Chillaron del Rey',
          'Festividad local de Chiloeches',
          'Festividad local de Cifuentes',
          'Festividad local de Guadalajara',
          'Festividad local de Hueva',
          'Festividad local de Luzón',
          'Festividad local de Moratilla de los Meleros',
          'Festividad local de Trijueque',
          'Festividad local de Villanueva de Alcorón',
          'Festividad local de Calera y Chozas',
          'Festividad local de Calzada de Oropesa',
          'Festividad local de Casarrubios del Monte',
          'Festividad local de Dosbarrios',
          'Festividad local de Escalonilla',
          'Festividad local de Fuensalida',
          'Festividad local de Hinojosa de San Vicente',
          'Festividad local de Los Cerralbos',
          'Festividad local de Los Navalmorales',
          'Festividad local de Los Yébenes',
          'Festividad local de Otero',
          'Festividad local de Real de San Vicente',
          'Festividad local de Rielves',
          'Festividad local de Sevilleja de la Jara',
          'Festividad local de Villatobas',
          'Festividad local de Yepes',
          'Festividad local de Yuncos',
        ],
        pc_ocupacion: 47.0,
        room: {
          double: {
            newPrice: 117.9,
            priceBefore: 122.37,
            priceNow: 120.05,
          },
          single: {
            newPrice: 91.42,
            priceBefore: 87.77,
            priceNow: 87.96,
          },
          suite: {
            newPrice: 142.12,
            priceBefore: 141.89,
            priceNow: 155.88,
          },
        },
      },
      {
        date: '2024-09-14',
        festivos: [
          'Festividad local de Alpera',
          'Festividad local de Villa de Vés',
          'Festividad local de Almadén',
          'Festividad local de Bolaños de Calatrava',
          'Festividad local de Cózar',
          'Festividad local de Malagón',
          'Festividad local de Manzanares',
          'Festividad local de Alarcón',
          'Festividad local de Cuevas de Velasco',
          'Festividad local de Fuentelespino de Moya',
          'Festividad local de La Alberca de Zancara',
          'Festividad local de La Parra de las Vegas',
          'Festividad local de La Ventosa',
          'Festividad local de Minglanilla',
          'Festividad local de Nohales',
          'Festividad local de Villar del Maestre',
          'Festividad local de Alaminos',
          'Festividad local de Argecilla',
          'Festividad local de Atienza',
          'Festividad local de Baños de Tajo',
          'Festividad local de Centenera',
          'Festividad local de Ciruelas',
          'Festividad local de Fuentelahiguera de Albatages',
          'Festividad local de Hueva',
          'Festividad local de Irueste',
          'Festividad local de Jadraque',
          'Festividad local de Loranca de Tajuña',
          'Festividad local de Mondéjar',
          'Festividad local de Saelices de la Sal',
          'Festividad local de Salmerón',
          'Festividad local de Val de San Garcia',
          'Festividad local de Valdeconcha',
          'Festividad local de Valdenuño Fernandez',
          'Festividad local de Valhermoso',
          'Festividad local de Carmena',
          'Festividad local de Garciotum',
          'Festividad local de Guadamur',
          'Festividad local de La Pueblanueva',
          'Festividad local de Las Herencias',
          'Festividad local de Lucillos',
          'Festividad local de Madridejos',
          'Festividad local de Pelahustán',
          'Festividad local de Segurilla',
        ],
        pc_ocupacion: 36.0,
        room: {
          double: {
            newPrice: 60.62,
            priceBefore: 61.41,
            priceNow: 60.06,
          },
          single: {
            newPrice: 69.03,
            priceBefore: 63.99,
            priceNow: 61.51,
          },
          suite: {
            newPrice: 135.29,
            priceBefore: 141.98,
            priceNow: 140.97,
          },
        },
      },
      {
        date: '2024-09-15',
        festivos: [
          'Festividad local de Villar de Cañas',
          'Festividad local de Anquela del Ducado',
          'Festividad local de Baños de Tajo',
          'Festividad local de Valhermoso',
          'Festividad local de Zarzuela de Galve',
        ],
        pc_ocupacion: 46.0,
        room: {
          double: {
            newPrice: 131.67,
            priceBefore: 132.36,
            priceNow: 142.46,
          },
          single: {
            newPrice: 57.62,
            priceBefore: 54.25,
            priceNow: 52.54,
          },
          suite: {
            newPrice: 176.62,
            priceBefore: 169.31,
            priceNow: 184.45,
          },
        },
      },
      {
        date: '2024-09-16',
        festivos: [
          'Festividad local de Elche De La Sierra',
          'Festividad local de Villapalacios',
          'Festividad local de Alcoba de los Montes',
          'Festividad local de Brazatortas',
          'Festividad local de Guadalmez',
          'Festividad local de Arandilla del Arroyo',
          'Festividad local de Beteta',
          'Festividad local de Buenache de Alarcon',
          'Festividad local de Castillejo-Sierra',
          'Festividad local de Fuente de Pedro Naharro',
          'Festividad local de Leganiel',
          'Festividad local de Osa de la Vega',
          'Festividad local de Paracuellos de la Vega',
          'Festividad local de Saelices',
          'Festividad local de Santa Maria del Val',
          'Festividad local de Sisante',
          'Festividad local de Villaescusa de Haro',
          'Festividad local de Villares del Saz',
          'Festividad local de Alcocer',
          'Festividad local de Almoguera',
          'Festividad local de Auñón',
          'Festividad local de Azuqueca de Henares',
          'Festividad local de Budia',
          'Festividad local de Chequilla',
          'Festividad local de Cifuentes',
          'Festividad local de Esplegares',
          'Festividad local de Humanes',
          'Festividad local de Jadraque',
          'Festividad local de Milmarcos',
          'Festividad local de Peñalén',
          'Festividad local de Santiuste',
          'Festividad local de Val de San Garcia',
          'Festividad local de Yunquera de Henares',
          'Festividad local de Zarzuela de Galve',
          'Festividad local de Bargas',
          'Festividad local de Calzada de Oropesa',
          'Festividad local de Chueca',
          'Festividad local de Cobeja',
          'Festividad local de Los Navalmorales',
          'Festividad local de Noez',
          'Festividad local de Numancia de la Sagra',
          'Festividad local de Otero',
          'Festividad local de Real de San Vicente',
          'Festividad local de Santa Ana de Pusa',
          'Festividad local de Villafranca de los Caballeros',
          'Festividad local de Yepes',
          'Festividad local de Yunclillos',
        ],
        pc_ocupacion: 37.0,
        room: {
          double: {
            newPrice: 89.25,
            priceBefore: 89.65,
            priceNow: 95.25,
          },
          single: {
            newPrice: 55.35,
            priceBefore: 51.95,
            priceNow: 54.04,
          },
          suite: {
            newPrice: 148.47,
            priceBefore: 137.66,
            priceNow: 148.45,
          },
        },
      },
      {
        date: '2024-09-17',
        festivos: [
          'Festividad local de Beteta',
          'Festividad local de Budia',
          'Festividad local de Yunquera de Henares',
          'Festividad local de Cobeja',
          'Festividad local de Numancia de la Sagra',
        ],
        pc_ocupacion: 42.0,
        room: {
          double: {
            newPrice: 90.66,
            priceBefore: 91.0,
            priceNow: 88.22,
          },
          single: {
            newPrice: 65.17,
            priceBefore: 64.32,
            priceNow: 65.26,
          },
          suite: {
            newPrice: 164.78,
            priceBefore: 155.58,
            priceNow: 168.65,
          },
        },
      },
      {
        date: '2024-09-18',
        festivos: [
          'Festividad local de Villanueva de los Infantes',
          'Festividad local de Honrubia',
          'Festividad local de Escamilla',
          'Festividad local de Luzaga',
        ],
        pc_ocupacion: 42.0,
        room: {
          double: {
            newPrice: 91.91,
            priceBefore: 87.81,
            priceNow: 92.99,
          },
          single: {
            newPrice: 75.73,
            priceBefore: 77.64,
            priceNow: 76.9,
          },
          suite: {
            newPrice: 165.78,
            priceBefore: 152.34,
            priceNow: 161.18,
          },
        },
      },
      {
        date: '2024-09-19',
        festivos: ['Festividad local de Oropesa y Corchuela'],
        pc_ocupacion: 45.0,
        room: {
          double: {
            newPrice: 85.57,
            priceBefore: 84.79,
            priceNow: 87.98,
          },
          single: {
            newPrice: 85.45,
            priceBefore: 86.11,
            priceNow: 83.9,
          },
          suite: {
            newPrice: 164.22,
            priceBefore: 162.01,
            priceNow: 173.31,
          },
        },
      },
      {
        date: '2024-09-20',
        festivos: [
          'Festividad local de Villaverde del Guadalimar',
          'Festividad local de Valverde de Jucar',
          'Festividad local de Ablanque',
          'Festividad local de Peralejos de las Truchas',
          'Festividad local de Pozo de Guadalajara',
          'Festividad local de Gerindote',
        ],
        pc_ocupacion: 39.0,
        room: {
          double: {
            newPrice: 100.76,
            priceBefore: 95.99,
            priceNow: 103.8,
          },
          single: {
            newPrice: 67.07,
            priceBefore: 65.16,
            priceNow: 66.64,
          },
          suite: {
            newPrice: 141.08,
            priceBefore: 133.37,
            priceNow: 144.88,
          },
        },
      },
      {
        date: '2024-09-21',
        festivos: [
          'Festividad local de La Recueja',
          'Festividad local de Cuenca',
          'Festividad local de La Melgosa',
          'Festividad local de Mohorte',
          'Festividad local de Consuegra',
        ],
        pc_ocupacion: 41.0,
        room: {
          double: {
            newPrice: 120.77,
            priceBefore: 121.48,
            priceNow: 131.51,
          },
          single: {
            newPrice: 39.99,
            priceBefore: 39.63,
            priceNow: 42.13,
          },
          suite: {
            newPrice: 139.78,
            priceBefore: 140.39,
            priceNow: 138.95,
          },
        },
      },
      {
        date: '2024-09-22',
        festivos: [],
        pc_ocupacion: 39.0,
        room: {
          double: {
            newPrice: 55.01,
            priceBefore: 54.68,
            priceNow: 52.17,
          },
          single: {
            newPrice: 78.59,
            priceBefore: 82.54,
            priceNow: 82.28,
          },
          suite: {
            newPrice: 153.52,
            priceBefore: 149.42,
            priceNow: 160.86,
          },
        },
      },
      {
        date: '2024-09-23',
        festivos: [
          'Festividad local de Munera',
          'Festividad local de Navas de Estena',
          'Festividad local de Peralejos de las Truchas',
          'Festividad local de Totanés',
          'Festividad local de Villaminaya',
        ],
        pc_ocupacion: 52.0,
        room: {
          double: {
            newPrice: 96.24,
            priceBefore: 93.08,
            priceNow: 99.95,
          },
          single: {
            newPrice: 117.33,
            priceBefore: 119.65,
            priceNow: 119.71,
          },
          suite: {
            newPrice: 153.76,
            priceBefore: 158.44,
            priceNow: 161.89,
          },
        },
      },
      {
        date: '2024-09-24',
        festivos: [
          'Festividad local de Hoya Gonzalo',
          'Festividad local de Herencia',
          'Festividad local de Los Cortijos',
          'Festividad local de Abánades',
          'Festividad local de Mochales',
          'Festividad local de Torremochuela',
          'Festividad local de Villel de Mesa',
          'Festividad local de Las Ventas con Peña Aguilera',
          'Festividad local de Pantoja',
          'Festividad local de Villaluenga de la Sagra',
        ],
        pc_ocupacion: 47.0,
        room: {
          double: {
            newPrice: 83.77,
            priceBefore: 84.5,
            priceNow: 92.94,
          },
          single: {
            newPrice: 70.06,
            priceBefore: 68.29,
            priceNow: 70.7,
          },
          suite: {
            newPrice: 169.29,
            priceBefore: 169.69,
            priceNow: 170.36,
          },
        },
      },
      {
        date: '2024-09-25',
        festivos: [
          'Festividad local de Los Cortijos',
          'Festividad local de La Guardia',
          'Festividad local de Pantoja',
        ],
        pc_ocupacion: 67.0,
        room: {
          double: {
            newPrice: 133.31,
            priceBefore: 135.78,
            priceNow: 136.7,
          },
          single: {
            newPrice: 157.92,
            priceBefore: 154.15,
            priceNow: 157.26,
          },
          suite: {
            newPrice: 156.82,
            priceBefore: 159.46,
            priceNow: 163.43,
          },
        },
      },
      {
        date: '2024-09-26',
        festivos: [
          'Festividad local de La Guardia',
          'Festividad local de Noves',
        ],
        pc_ocupacion: 65.0,
        room: {
          double: {
            newPrice: 127.42,
            priceBefore: 131.07,
            priceNow: 141.69,
          },
          single: {
            newPrice: 140.29,
            priceBefore: 137.81,
            priceNow: 145.61,
          },
          suite: {
            newPrice: 209.17,
            priceBefore: 190.59,
            priceNow: 206.07,
          },
        },
      },
      {
        date: '2024-09-27',
        festivos: [
          'Festividad local de Abengibre',
          'Festividad local de El Peral',
          'Festividad local de Palomares del Campo',
          'Festividad local de Albalate de Zorita',
          'Festividad local de Heras de Ayuso',
          'Festividad local de Villaseca de Uceda',
          'Festividad local de Alberche del Caudillo',
          'Festividad local de Buenaventura',
          'Festividad local de Noves',
          'Festividad local de Torrijos',
        ],
        pc_ocupacion: 35.0,
        room: {
          double: {
            newPrice: 85.0,
            priceBefore: 86.56,
            priceNow: 88.34,
          },
          single: {
            newPrice: 55.39,
            priceBefore: 51.74,
            priceNow: 51.08,
          },
          suite: {
            newPrice: 144.97,
            priceBefore: 149.44,
            priceNow: 149.76,
          },
        },
      },
      {
        date: '2024-09-28',
        festivos: [],
        pc_ocupacion: 39.0,
        room: {
          double: {
            newPrice: 115.31,
            priceBefore: 113.11,
            priceNow: 122.73,
          },
          single: {
            newPrice: 54.86,
            priceBefore: 53.85,
            priceNow: 58.21,
          },
          suite: {
            newPrice: 160.66,
            priceBefore: 149.83,
            priceNow: 157.83,
          },
        },
      },
      {
        date: '2024-09-29',
        festivos: [
          'Festividad local de Fresneda de la Sierra',
          'Festividad local de Huelves',
          'Festividad local de Tondos',
          'Festividad local de Valparaiso de Arriba',
          'Festividad local de Anchuela del Campo',
        ],
        pc_ocupacion: 36.0,
        room: {
          double: {
            newPrice: 78.62,
            priceBefore: 74.97,
            priceNow: 79.25,
          },
          single: {
            newPrice: 70.61,
            priceBefore: 65.16,
            priceNow: 66.24,
          },
          suite: {
            newPrice: 143.68,
            priceBefore: 146.31,
            priceNow: 156.67,
          },
        },
      },
      {
        date: '2024-09-30',
        festivos: [
          'Festividad local de Chinchilla',
          'Festividad local de Corralrubio',
          'Festividad local de El Ballestero',
          'Festividad local de Ossa de Montiel',
          'Festividad local de Paterna del Madera',
          'Festividad local de Peñascosa',
          'Festividad local de Anchuras',
          'Festividad local de Montalbo',
          'Festividad local de Narboneta',
          'Festividad local de Tragacete',
          'Festividad local de Castejón de Henares',
          'Festividad local de Driebes',
          'Festividad local de Huertahernando',
          'Festividad local de Yebes',
          'Festividad local de Robledo del Mazo',
          'Festividad local de Torrecilla de La Jara',
          'Festividad local de Urda',
        ],
        pc_ocupacion: 42.0,
        room: {
          double: {
            newPrice: 94.46,
            priceBefore: 93.07,
            priceNow: 101.6,
          },
          single: {
            newPrice: 76.32,
            priceBefore: 70.83,
            priceNow: 68.9,
          },
          suite: {
            newPrice: 167.76,
            priceBefore: 155.61,
            priceNow: 158.81,
          },
        },
      },
      {
        date: '2024-10-01',
        festivos: ['Festividad local de Montalbo', 'Festividad local de Embid'],
        pc_ocupacion: 49.0,
        room: {
          double: {
            newPrice: 96.43,
            priceBefore: 88.77,
            priceNow: 92.61,
          },
          single: {
            newPrice: 99.73,
            priceBefore: 104.07,
            priceNow: 105.51,
          },
          suite: {
            newPrice: 152.02,
            priceBefore: 153.55,
            priceNow: 160.47,
          },
        },
      },
      {
        date: '2024-10-02',
        festivos: [
          'Festividad local de Arguisuelas',
          'Festividad local de La Cierva',
          'Festividad local de Semillas',
          'Festividad local de Yélamos de Abajo',
        ],
        pc_ocupacion: 47.0,
        room: {
          double: {
            newPrice: 112.18,
            priceBefore: 103.25,
            priceNow: 104.03,
          },
          single: {
            newPrice: 82.82,
            priceBefore: 81.68,
            priceNow: 88.99,
          },
          suite: {
            newPrice: 183.5,
            priceBefore: 183.59,
            priceNow: 183.34,
          },
        },
      },
      {
        date: '2024-10-03',
        festivos: ['Festividad local de Yélamos de Abajo'],
        pc_ocupacion: 42.0,
        room: {
          double: {
            newPrice: 99.39,
            priceBefore: 99.4,
            priceNow: 106.44,
          },
          single: {
            newPrice: 86.56,
            priceBefore: 81.19,
            priceNow: 81.19,
          },
          suite: {
            newPrice: 157.74,
            priceBefore: 152.05,
            priceNow: 154.75,
          },
        },
      },
      {
        date: '2024-10-04',
        festivos: [
          'Festividad local de El Salobral',
          'Festividad local de Vianos',
          'Festividad local de Masegosa',
          'Festividad local de Robledo de Corpes',
          'Festividad local de Sotodosos',
        ],
        pc_ocupacion: 45.0,
        room: {
          double: {
            newPrice: 95.08,
            priceBefore: 98.28,
            priceNow: 95.48,
          },
          single: {
            newPrice: 75.56,
            priceBefore: 74.24,
            priceNow: 73.74,
          },
          suite: {
            newPrice: 152.57,
            priceBefore: 142.75,
            priceNow: 149.86,
          },
        },
      },
      {
        date: '2024-10-05',
        festivos: [
          'Festividad local de Salinas del Manzano',
          'Festividad local de El Pedregal',
        ],
        pc_ocupacion: 34.0,
        room: {
          double: {
            newPrice: 63.91,
            priceBefore: 62.44,
            priceNow: 63.6,
          },
          single: {
            newPrice: 52.12,
            priceBefore: 48.14,
            priceNow: 46.18,
          },
          suite: {
            newPrice: 140.73,
            priceBefore: 147.96,
            priceNow: 146.0,
          },
        },
      },
      {
        date: '2024-10-06',
        festivos: [],
        pc_ocupacion: 41.0,
        room: {
          double: {
            newPrice: 95.92,
            priceBefore: 96.85,
            priceNow: 103.06,
          },
          single: {
            newPrice: 74.51,
            priceBefore: 73.23,
            priceNow: 76.6,
          },
          suite: {
            newPrice: 183.77,
            priceBefore: 169.54,
            priceNow: 183.09,
          },
        },
      },
      {
        date: '2024-10-07',
        festivos: [
          'Festividad local de Bonete',
          'Festividad local de Férez',
          'Festividad local de Masegoso',
          'Festividad local de Povedilla',
          'Festividad local de Tiriez',
          'Festividad local de Valdeganga',
          'Festividad local de Almadenejos',
          'Festividad local de Almedina',
          'Festividad local de Santa Cruz de Cáñamos',
          'Festividad local de Villanueva de San Carlos',
          'Festividad local de Buenache de la Sierra',
          'Festividad local de El Picazo',
          'Festividad local de Fuentes',
          'Festividad local de Fuertescusa',
          'Festividad local de Las Valeras',
          'Festividad local de Masegosa',
          'Festividad local de Pineda de Gigüela',
          'Festividad local de Valdetortola',
          'Festividad local de Valparaiso de Abajo',
          'Festividad local de Zafra de Zancara',
          'Festividad local de Zarzuela',
          'Festividad local de Anchuela del Pedregal',
          'Festividad local de Anguita',
          'Festividad local de Driebes',
          'Festividad local de El Sotillo',
          'Festividad local de Málaga del Fresno',
          'Festividad local de Saúca',
          'Festividad local de Setiles',
          'Festividad local de Cabañas de la Sagra',
          'Festividad local de Cardiel de los Montes',
          'Festividad local de Lagartera',
          'Festividad local de Las Vegas y San Antonio',
          'Festividad local de Montearagón',
          'Festividad local de Olias del Rey',
          'Festividad local de Ontígola',
        ],
        pc_ocupacion: 43.0,
        room: {
          double: {
            newPrice: 86.33,
            priceBefore: 83.44,
            priceNow: 84.01,
          },
          single: {
            newPrice: 80.49,
            priceBefore: 74.35,
            priceNow: 73.79,
          },
          suite: {
            newPrice: 151.8,
            priceBefore: 156.7,
            priceNow: 152.4,
          },
        },
      },
      {
        date: '2024-10-08',
        festivos: [
          'Festividad local de Férez',
          'Festividad local de Valdeganga',
          'Festividad local de Valenzuela de Calatrava',
          'Festividad local de Sotos',
          'Festividad local de Setiles',
          'Festividad local de Olias del Rey',
        ],
        pc_ocupacion: 37.0,
        room: {
          double: {
            newPrice: 96.38,
            priceBefore: 91.12,
            priceNow: 97.14,
          },
          single: {
            newPrice: 74.34,
            priceBefore: 68.05,
            priceNow: 71.23,
          },
          suite: {
            newPrice: 158.65,
            priceBefore: 144.46,
            priceNow: 146.11,
          },
        },
      },
      {
        date: '2024-10-09',
        festivos: [
          'Festividad local de Fuenteálamo',
          'Festividad local de Villar del Humo',
          'Festividad local de Ujados',
        ],
        pc_ocupacion: 35.0,
        room: {
          double: {
            newPrice: 98.78,
            priceBefore: 90.64,
            priceNow: 90.3,
          },
          single: {
            newPrice: 57.9,
            priceBefore: 55.6,
            priceNow: 59.61,
          },
          suite: {
            newPrice: 151.91,
            priceBefore: 142.3,
            priceNow: 145.43,
          },
        },
      },
      {
        date: '2024-10-10',
        festivos: [
          'Festividad local de Cantalojas',
          'Festividad local de Torremocha del Pinar',
          'Festividad local de Portillo de Toledo',
        ],
        pc_ocupacion: 44.0,
        room: {
          double: {
            newPrice: 101.66,
            priceBefore: 102.4,
            priceNow: 105.17,
          },
          single: {
            newPrice: 54.6,
            priceBefore: 55.19,
            priceNow: 57.0,
          },
          suite: {
            newPrice: 157.02,
            priceBefore: 161.15,
            priceNow: 159.74,
          },
        },
      },
      {
        date: '2024-10-11',
        festivos: [
          'Festividad local de Bormate',
          'Festividad local de La Herrera',
          'Festividad local de Cardenete',
          'Festividad local de Carrascosa de la Sierra',
          'Festividad local de Henarejos',
          'Festividad local de Cantalojas',
          'Festividad local de Los Alares',
          'Festividad local de Pulgar',
          'Festividad local de Robledo del Buey',
        ],
        pc_ocupacion: 41.0,
        room: {
          double: {
            newPrice: 73.6,
            priceBefore: 70.57,
            priceNow: 75.81,
          },
          single: {
            newPrice: 84.85,
            priceBefore: 79.44,
            priceNow: 75.59,
          },
          suite: {
            newPrice: 136.67,
            priceBefore: 141.08,
            priceNow: 135.55,
          },
        },
      },
      {
        date: '2024-10-12',
        festivos: ['Fiesta Nacional de España'],
        pc_ocupacion: 28.0,
        room: {
          double: {
            newPrice: 70.19,
            priceBefore: 70.69,
            priceNow: 68.65,
          },
          single: {
            newPrice: 86.29,
            priceBefore: 78.99,
            priceNow: 75.61,
          },
          suite: {
            newPrice: 73.44,
            priceBefore: 70.1,
            priceNow: 76.96,
          },
        },
      },
      {
        date: '2024-10-13',
        festivos: ['Festividad local de Huelamo'],
        pc_ocupacion: 31.0,
        room: {
          double: {
            newPrice: 52.96,
            priceBefore: 54.38,
            priceNow: 54.14,
          },
          single: {
            newPrice: 67.85,
            priceBefore: 66.99,
            priceNow: 70.73,
          },
          suite: {
            newPrice: 138.32,
            priceBefore: 143.37,
            priceNow: 137.87,
          },
        },
      },
      {
        date: '2024-10-14',
        festivos: [
          'Festividad local de Molinicos',
          'Festividad local de Belinchon',
        ],
        pc_ocupacion: 36.0,
        room: {
          double: {
            newPrice: 62.38,
            priceBefore: 63.83,
            priceNow: 67.15,
          },
          single: {
            newPrice: 92.21,
            priceBefore: 96.39,
            priceNow: 96.67,
          },
          suite: {
            newPrice: 135.89,
            priceBefore: 132.34,
            priceNow: 140.97,
          },
        },
      },
      {
        date: '2024-10-15',
        festivos: [
          'Festividad local de Malagón',
          'Festividad local de Jabaga',
          'Festividad local de Villanueva de la Jara',
          'Festividad local de Pastrana',
        ],
        pc_ocupacion: 40.0,
        room: {
          double: {
            newPrice: 71.54,
            priceBefore: 73.09,
            priceNow: 79.5,
          },
          single: {
            newPrice: 116.64,
            priceBefore: 109.8,
            priceNow: 114.55,
          },
          suite: {
            newPrice: 150.57,
            priceBefore: 152.0,
            priceNow: 164.26,
          },
        },
      },
      {
        date: '2024-10-16',
        festivos: [],
        pc_ocupacion: 39.0,
        room: {
          double: {
            newPrice: 67.1,
            priceBefore: 69.1,
            priceNow: 67.61,
          },
          single: {
            newPrice: 86.04,
            priceBefore: 84.09,
            priceNow: 88.26,
          },
          suite: {
            newPrice: 130.34,
            priceBefore: 124.62,
            priceNow: 119.34,
          },
        },
      },
      {
        date: '2024-10-17',
        festivos: ['Festividad local de Cívica'],
        pc_ocupacion: 31.0,
        room: {
          double: {
            newPrice: 84.29,
            priceBefore: 87.99,
            priceNow: 85.5,
          },
          single: {
            newPrice: 54.54,
            priceBefore: 54.24,
            priceNow: 57.82,
          },
          suite: {
            newPrice: 146.29,
            priceBefore: 133.16,
            priceNow: 131.77,
          },
        },
      },
      {
        date: '2024-10-18',
        festivos: [],
        pc_ocupacion: 46.0,
        room: {
          double: {
            newPrice: 108.89,
            priceBefore: 113.32,
            priceNow: 115.54,
          },
          single: {
            newPrice: 82.65,
            priceBefore: 79.79,
            priceNow: 76.57,
          },
          suite: {
            newPrice: 127.66,
            priceBefore: 119.96,
            priceNow: 131.07,
          },
        },
      },
      {
        date: '2024-10-19',
        festivos: [],
        pc_ocupacion: 26.0,
        room: {
          double: {
            newPrice: 79.35,
            priceBefore: 74.56,
            priceNow: 80.75,
          },
          single: {
            newPrice: 53.27,
            priceBefore: 53.63,
            priceNow: 57.91,
          },
          suite: {
            newPrice: 76.25,
            priceBefore: 72.25,
            priceNow: 74.26,
          },
        },
      },
      {
        date: '2024-10-20',
        festivos: [],
        pc_ocupacion: 31.0,
        room: {
          double: {
            newPrice: 56.94,
            priceBefore: 53.68,
            priceNow: 52.44,
          },
          single: {
            newPrice: 44.0,
            priceBefore: 44.62,
            priceNow: 44.23,
          },
          suite: {
            newPrice: 150.24,
            priceBefore: 149.78,
            priceNow: 154.28,
          },
        },
      },
      {
        date: '2024-10-21',
        festivos: ['Festividad local de Brihuega'],
        pc_ocupacion: 37.0,
        room: {
          double: {
            newPrice: 113.13,
            priceBefore: 112.39,
            priceNow: 115.34,
          },
          single: {
            newPrice: 83.82,
            priceBefore: 76.89,
            priceNow: 77.59,
          },
          suite: {
            newPrice: 65.99,
            priceBefore: 66.14,
            priceNow: 67.46,
          },
        },
      },
      {
        date: '2024-10-22',
        festivos: [],
        pc_ocupacion: 43.0,
        room: {
          double: {
            newPrice: 89.41,
            priceBefore: 84.51,
            priceNow: 84.2,
          },
          single: {
            newPrice: 88.06,
            priceBefore: 86.76,
            priceNow: 93.95,
          },
          suite: {
            newPrice: 176.68,
            priceBefore: 164.09,
            priceNow: 174.27,
          },
        },
      },
      {
        date: '2024-10-23',
        festivos: [],
        pc_ocupacion: 35.0,
        room: {
          double: {
            newPrice: 90.24,
            priceBefore: 90.13,
            priceNow: 91.85,
          },
          single: {
            newPrice: 80.17,
            priceBefore: 82.38,
            priceNow: 80.85,
          },
          suite: {
            newPrice: 109.23,
            priceBefore: 111.11,
            priceNow: 119.41,
          },
        },
      },
      {
        date: '2024-10-24',
        festivos: ['Festividad local de Hellín'],
        pc_ocupacion: 44.0,
        room: {
          double: {
            newPrice: 115.75,
            priceBefore: 107.49,
            priceNow: 111.01,
          },
          single: {
            newPrice: 76.21,
            priceBefore: 74.52,
            priceNow: 74.07,
          },
          suite: {
            newPrice: 183.29,
            priceBefore: 186.42,
            priceNow: 190.36,
          },
        },
      },
      {
        date: '2024-10-25',
        festivos: [],
        pc_ocupacion: 44.0,
        room: {
          double: {
            newPrice: 93.99,
            priceBefore: 88.41,
            priceNow: 90.44,
          },
          single: {
            newPrice: 79.78,
            priceBefore: 76.41,
            priceNow: 78.67,
          },
          suite: {
            newPrice: 175.16,
            priceBefore: 166.39,
            priceNow: 163.84,
          },
        },
      },
      {
        date: '2024-10-26',
        festivos: [],
        pc_ocupacion: 38.0,
        room: {
          double: {
            newPrice: 60.52,
            priceBefore: 56.44,
            priceNow: 60.03,
          },
          single: {
            newPrice: 55.35,
            priceBefore: 50.61,
            priceNow: 52.15,
          },
          suite: {
            newPrice: 170.39,
            priceBefore: 161.01,
            priceNow: 174.74,
          },
        },
      },
      {
        date: '2024-10-27',
        festivos: [],
        pc_ocupacion: 34.0,
        room: {
          double: {
            newPrice: 66.15,
            priceBefore: 69.34,
            priceNow: 72.8,
          },
          single: {
            newPrice: 83.22,
            priceBefore: 80.73,
            priceNow: 80.48,
          },
          suite: {
            newPrice: 173.79,
            priceBefore: 160.43,
            priceNow: 158.51,
          },
        },
      },
      {
        date: '2024-10-28',
        festivos: [],
        pc_ocupacion: 29.0,
        room: {
          double: {
            newPrice: 63.37,
            priceBefore: 59.18,
            priceNow: 57.8,
          },
          single: {
            newPrice: 65.12,
            priceBefore: 65.6,
            priceNow: 64.85,
          },
          suite: {
            newPrice: 129.99,
            priceBefore: 119.02,
            priceNow: 128.69,
          },
        },
      },
      {
        date: '2024-10-29',
        festivos: [],
        pc_ocupacion: 30.0,
        room: {
          double: {
            newPrice: 57.98,
            priceBefore: 53.15,
            priceNow: 50.62,
          },
          single: {
            newPrice: 63.47,
            priceBefore: 64.73,
            priceNow: 61.93,
          },
          suite: {
            newPrice: 106.47,
            priceBefore: 107.67,
            priceNow: 112.83,
          },
        },
      },
      {
        date: '2024-10-30',
        festivos: ['Festividad local de Barriopedro'],
        pc_ocupacion: 31.0,
        room: {
          double: {
            newPrice: 51.63,
            priceBefore: 47.54,
            priceNow: 51.58,
          },
          single: {
            newPrice: 58.61,
            priceBefore: 54.58,
            priceNow: 53.56,
          },
          suite: {
            newPrice: 120.56,
            priceBefore: 113.91,
            priceNow: 110.08,
          },
        },
      },
      {
        date: '2024-10-31',
        festivos: ['Festividad local de Menasalbas'],
        pc_ocupacion: 36.0,
        room: {
          double: {
            newPrice: 45.83,
            priceBefore: 46.78,
            priceNow: 50.08,
          },
          single: {
            newPrice: 60.68,
            priceBefore: 63.12,
            priceNow: 60.76,
          },
          suite: {
            newPrice: 158.59,
            priceBefore: 146.47,
            priceNow: 140.72,
          },
        },
      },
      {
        date: '2024-11-01',
        festivos: ['Día de Todos los Santos'],
        pc_ocupacion: 28.0,
        room: {
          double: {
            newPrice: 45.69,
            priceBefore: 46.25,
            priceNow: 49.53,
          },
          single: {
            newPrice: 81.14,
            priceBefore: 80.01,
            priceNow: 82.84,
          },
          suite: {
            newPrice: 111.59,
            priceBefore: 111.33,
            priceNow: 111.94,
          },
        },
      },
      {
        date: '2024-11-02',
        festivos: [],
        pc_ocupacion: 25.0,
        room: {
          double: {
            newPrice: 47.78,
            priceBefore: 49.04,
            priceNow: 50.43,
          },
          single: {
            newPrice: 64.11,
            priceBefore: 60.83,
            priceNow: 63.84,
          },
          suite: {
            newPrice: 110.49,
            priceBefore: 114.79,
            priceNow: 120.14,
          },
        },
      },
      {
        date: '2024-11-03',
        festivos: [],
        pc_ocupacion: 27.0,
        room: {
          double: {
            newPrice: 56.05,
            priceBefore: 55.97,
            priceNow: 56.27,
          },
          single: {
            newPrice: 48.55,
            priceBefore: 46.92,
            priceNow: 47.89,
          },
          suite: {
            newPrice: 123.71,
            priceBefore: 115.04,
            priceNow: 122.0,
          },
        },
      },
      {
        date: '2024-11-04',
        festivos: [
          'Festividad local de Las Labores',
          'Festividad local de Barchin del Hoyo',
        ],
        pc_ocupacion: 33.0,
        room: {
          double: {
            newPrice: 101.05,
            priceBefore: 94.43,
            priceNow: 91.62,
          },
          single: {
            newPrice: 58.97,
            priceBefore: 57.26,
            priceNow: 60.69,
          },
          suite: {
            newPrice: 120.58,
            priceBefore: 114.69,
            priceNow: 124.42,
          },
        },
      },
      {
        date: '2024-11-05',
        festivos: [],
        pc_ocupacion: 25.0,
        room: {
          double: {
            newPrice: 58.2,
            priceBefore: 54.43,
            priceNow: 57.44,
          },
          single: {
            newPrice: 77.54,
            priceBefore: 78.92,
            priceNow: 78.58,
          },
          suite: {
            newPrice: 74.08,
            priceBefore: 69.48,
            priceNow: 74.81,
          },
        },
      },
      {
        date: '2024-11-06',
        festivos: [],
        pc_ocupacion: 30.0,
        room: {
          double: {
            newPrice: 40.07,
            priceBefore: 40.44,
            priceNow: 43.1,
          },
          single: {
            newPrice: 67.5,
            priceBefore: 69.38,
            priceNow: 75.96,
          },
          suite: {
            newPrice: 108.06,
            priceBefore: 109.02,
            priceNow: 115.29,
          },
        },
      },
      {
        date: '2024-11-07',
        festivos: [],
        pc_ocupacion: 24.0,
        room: {
          double: {
            newPrice: 45.01,
            priceBefore: 44.55,
            priceNow: 44.64,
          },
          single: {
            newPrice: 58.42,
            priceBefore: 57.64,
            priceNow: 58.39,
          },
          suite: {
            newPrice: 101.27,
            priceBefore: 105.43,
            priceNow: 107.16,
          },
        },
      },
      {
        date: '2024-11-08',
        festivos: ['Festividad local de Pozo de Almoguera'],
        pc_ocupacion: 30.0,
        room: {
          double: {
            newPrice: 48.68,
            priceBefore: 50.45,
            priceNow: 53.73,
          },
          single: {
            newPrice: 67.35,
            priceBefore: 70.88,
            priceNow: 72.83,
          },
          suite: {
            newPrice: 114.8,
            priceBefore: 105.44,
            priceNow: 110.26,
          },
        },
      },
      {
        date: '2024-11-09',
        festivos: [],
        pc_ocupacion: 26.0,
        room: {
          double: {
            newPrice: 47.95,
            priceBefore: 49.7,
            priceNow: 51.96,
          },
          single: {
            newPrice: 69.7,
            priceBefore: 72.73,
            priceNow: 74.55,
          },
          suite: {
            newPrice: 106.78,
            priceBefore: 99.01,
            priceNow: 99.55,
          },
        },
      },
      {
        date: '2024-11-10',
        festivos: [],
        pc_ocupacion: 26.0,
        room: {
          double: {
            newPrice: 54.61,
            priceBefore: 51.29,
            priceNow: 54.52,
          },
          single: {
            newPrice: 58.21,
            priceBefore: 56.3,
            priceNow: 59.65,
          },
          suite: {
            newPrice: 100.32,
            priceBefore: 97.36,
            priceNow: 96.58,
          },
        },
      },
      {
        date: '2024-11-11',
        festivos: [
          'Festividad local de Cañaveras',
          'Festividad local de Fuertescusa',
          'Festividad local de San Martin de Boniches',
          'Festividad local de Anquela del Ducado',
          'Festividad local de Masegoso de Tajuña',
          'Festividad local de Pozo de Almoguera',
          'Festividad local de San Martín de Pusa',
          'Festividad local de Villanueva de Alcardete',
        ],
        pc_ocupacion: 29.0,
        room: {
          double: {
            newPrice: 95.73,
            priceBefore: 89.05,
            priceNow: 88.69,
          },
          single: {
            newPrice: 57.82,
            priceBefore: 52.62,
            priceNow: 57.36,
          },
          suite: {
            newPrice: 82.09,
            priceBefore: 86.02,
            priceNow: 89.87,
          },
        },
      },
      {
        date: '2024-11-12',
        festivos: [
          'Festividad local de Cañamares',
          'Festividad local de Cogolludo',
          'Festividad local de Muduex',
        ],
        pc_ocupacion: 23.0,
        room: {
          double: {
            newPrice: 47.72,
            priceBefore: 47.56,
            priceNow: 47.11,
          },
          single: {
            newPrice: 73.93,
            priceBefore: 70.45,
            priceNow: 76.21,
          },
          suite: {
            newPrice: 91.16,
            priceBefore: 86.77,
            priceNow: 88.65,
          },
        },
      },
      {
        date: '2024-11-13',
        festivos: [],
        pc_ocupacion: 19.0,
        room: {
          double: {
            newPrice: 46.73,
            priceBefore: 48.62,
            priceNow: 53.18,
          },
          single: {
            newPrice: 68.55,
            priceBefore: 65.69,
            priceNow: 62.54,
          },
          suite: {
            newPrice: 84.04,
            priceBefore: 84.95,
            priceNow: 84.21,
          },
        },
      },
      {
        date: '2024-11-14',
        festivos: ['Festividad local de Tartanedo'],
        pc_ocupacion: 25.0,
        room: {
          double: {
            newPrice: 80.04,
            priceBefore: 73.53,
            priceNow: 77.21,
          },
          single: {
            newPrice: 67.29,
            priceBefore: 65.3,
            priceNow: 63.63,
          },
          suite: {
            newPrice: 70.79,
            priceBefore: 68.96,
            priceNow: 68.16,
          },
        },
      },
      {
        date: '2024-11-15',
        festivos: [],
        pc_ocupacion: 23.0,
        room: {
          double: {
            newPrice: 72.07,
            priceBefore: 66.22,
            priceNow: 63.67,
          },
          single: {
            newPrice: 52.76,
            priceBefore: 49.83,
            priceNow: 52.36,
          },
          suite: {
            newPrice: 83.94,
            priceBefore: 79.56,
            priceNow: 77.21,
          },
        },
      },
      {
        date: '2024-11-16',
        festivos: [],
        pc_ocupacion: 23.0,
        room: {
          double: {
            newPrice: 55.14,
            priceBefore: 55.45,
            priceNow: 52.75,
          },
          single: {
            newPrice: 78.06,
            priceBefore: 74.25,
            priceNow: 74.67,
          },
          suite: {
            newPrice: 82.17,
            priceBefore: 75.51,
            priceNow: 73.68,
          },
        },
      },
      {
        date: '2024-11-17',
        festivos: [],
        pc_ocupacion: 17.0,
        room: {
          double: {
            newPrice: 46.99,
            priceBefore: 46.31,
            priceNow: 48.07,
          },
          single: {
            newPrice: 51.7,
            priceBefore: 47.18,
            priceNow: 47.23,
          },
          suite: {
            newPrice: 69.59,
            priceBefore: 66.77,
            priceNow: 67.24,
          },
        },
      },
      {
        date: '2024-11-18',
        festivos: [
          'Festividad local de Arcos de la Cantera',
          'Festividad local de San Román de los Montes',
        ],
        pc_ocupacion: 25.0,
        room: {
          double: {
            newPrice: 45.78,
            priceBefore: 48.03,
            priceNow: 49.7,
          },
          single: {
            newPrice: 101.03,
            priceBefore: 93.28,
            priceNow: 93.58,
          },
          suite: {
            newPrice: 58.52,
            priceBefore: 60.02,
            priceNow: 64.43,
          },
        },
      },
      {
        date: '2024-11-19',
        festivos: [],
        pc_ocupacion: 23.0,
        room: {
          double: {
            newPrice: 42.92,
            priceBefore: 40.17,
            priceNow: 43.69,
          },
          single: {
            newPrice: 54.79,
            priceBefore: 51.22,
            priceNow: 51.16,
          },
          suite: {
            newPrice: 108.68,
            priceBefore: 100.58,
            priceNow: 96.3,
          },
        },
      },
      {
        date: '2024-11-20',
        festivos: [],
        pc_ocupacion: 23.0,
        room: {
          double: {
            newPrice: 51.48,
            priceBefore: 48.59,
            priceNow: 53.27,
          },
          single: {
            newPrice: 94.27,
            priceBefore: 96.48,
            priceNow: 98.13,
          },
          suite: {
            newPrice: 58.26,
            priceBefore: 56.09,
            priceNow: 58.68,
          },
        },
      },
      {
        date: '2024-11-21',
        festivos: [],
        pc_ocupacion: 27.0,
        room: {
          double: {
            newPrice: 56.2,
            priceBefore: 52.11,
            priceNow: 52.09,
          },
          single: {
            newPrice: 62.3,
            priceBefore: 61.33,
            priceNow: 65.02,
          },
          suite: {
            newPrice: 116.98,
            priceBefore: 116.53,
            priceNow: 121.37,
          },
        },
      },
      {
        date: '2024-11-22',
        festivos: [],
        pc_ocupacion: 35.0,
        room: {
          double: {
            newPrice: 84.65,
            priceBefore: 82.42,
            priceNow: 82.65,
          },
          single: {
            newPrice: 71.79,
            priceBefore: 66.47,
            priceNow: 63.46,
          },
          suite: {
            newPrice: 108.26,
            priceBefore: 110.3,
            priceNow: 109.36,
          },
        },
      },
      {
        date: '2024-11-23',
        festivos: ['Festividad local de Zarzuela de Jadraque'],
        pc_ocupacion: 23.0,
        room: {
          double: {
            newPrice: 54.63,
            priceBefore: 50.94,
            priceNow: 51.04,
          },
          single: {
            newPrice: 57.22,
            priceBefore: 56.77,
            priceNow: 60.49,
          },
          suite: {
            newPrice: 127.2,
            priceBefore: 115.98,
            priceNow: 127.41,
          },
        },
      },
      {
        date: '2024-11-24',
        festivos: [],
        pc_ocupacion: 24.0,
        room: {
          double: {
            newPrice: 67.57,
            priceBefore: 63.86,
            priceNow: 69.48,
          },
          single: {
            newPrice: 52.35,
            priceBefore: 52.87,
            priceNow: 50.35,
          },
          suite: {
            newPrice: 112.72,
            priceBefore: 103.72,
            priceNow: 101.17,
          },
        },
      },
      {
        date: '2024-11-25',
        festivos: [
          'Festividad local de Angón',
          'Festividad local de Tórtola de Henares',
        ],
        pc_ocupacion: 23.0,
        room: {
          double: {
            newPrice: 40.32,
            priceBefore: 41.3,
            priceNow: 39.48,
          },
          single: {
            newPrice: 55.74,
            priceBefore: 53.07,
            priceNow: 50.49,
          },
          suite: {
            newPrice: 101.7,
            priceBefore: 97.54,
            priceNow: 100.25,
          },
        },
      },
      {
        date: '2024-11-26',
        festivos: [],
        pc_ocupacion: 20.0,
        room: {
          double: {
            newPrice: 59.73,
            priceBefore: 55.8,
            priceNow: 57.25,
          },
          single: {
            newPrice: 36.05,
            priceBefore: 37.36,
            priceNow: 39.12,
          },
          suite: {
            newPrice: 78.79,
            priceBefore: 76.94,
            priceNow: 74.43,
          },
        },
      },
      {
        date: '2024-11-27',
        festivos: [],
        pc_ocupacion: 23.0,
        room: {
          double: {
            newPrice: 43.36,
            priceBefore: 43.32,
            priceNow: 43.83,
          },
          single: {
            newPrice: 55.78,
            priceBefore: 58.47,
            priceNow: 55.91,
          },
          suite: {
            newPrice: 71.88,
            priceBefore: 71.37,
            priceNow: 70.07,
          },
        },
      },
      {
        date: '2024-11-28',
        festivos: [],
        pc_ocupacion: 18.0,
        room: {
          double: {
            newPrice: 52.51,
            priceBefore: 54.24,
            priceNow: 56.0,
          },
          single: {
            newPrice: 45.82,
            priceBefore: 43.67,
            priceNow: 44.65,
          },
          suite: {
            newPrice: 80.56,
            priceBefore: 75.1,
            priceNow: 76.54,
          },
        },
      },
      {
        date: '2024-11-29',
        festivos: [
          'Festividad local de Viso del Marques',
          'Festividad local de Castillo de Bayuela',
          'Festividad local de San Martín de Montalbán',
        ],
        pc_ocupacion: 22.0,
        room: {
          double: {
            newPrice: 46.64,
            priceBefore: 48.64,
            priceNow: 47.42,
          },
          single: {
            newPrice: 74.01,
            priceBefore: 74.11,
            priceNow: 72.97,
          },
          suite: {
            newPrice: 80.14,
            priceBefore: 80.0,
            priceNow: 85.56,
          },
        },
      },
      {
        date: '2024-11-30',
        festivos: [
          'Festividad local de Paredes',
          'Festividad local de Fuentelahiguera de Albatages',
          'Festividad local de Romanillos de Atienza',
          'Festividad local de San Andrés del Rey',
          'Festividad local de Carmena',
        ],
        pc_ocupacion: 22.0,
        room: {
          double: {
            newPrice: 51.97,
            priceBefore: 54.53,
            priceNow: 52.32,
          },
          single: {
            newPrice: 33.74,
            priceBefore: 32.39,
            priceNow: 30.78,
          },
          suite: {
            newPrice: 95.23,
            priceBefore: 87.76,
            priceNow: 93.7,
          },
        },
      },
      {
        date: '2024-12-01',
        festivos: [],
        pc_ocupacion: 25.0,
        room: {
          double: {
            newPrice: 54.73,
            priceBefore: 50.19,
            priceNow: 54.07,
          },
          single: {
            newPrice: 63.82,
            priceBefore: 61.14,
            priceNow: 61.79,
          },
          suite: {
            newPrice: 72.96,
            priceBefore: 75.47,
            priceNow: 74.54,
          },
        },
      },
      {
        date: '2024-12-02',
        festivos: [],
        pc_ocupacion: 22.0,
        room: {
          double: {
            newPrice: 57.37,
            priceBefore: 56.67,
            priceNow: 55.93,
          },
          single: {
            newPrice: 72.69,
            priceBefore: 67.62,
            priceNow: 72.35,
          },
          suite: {
            newPrice: 70.76,
            priceBefore: 67.91,
            priceNow: 73.87,
          },
        },
      },
      {
        date: '2024-12-03',
        festivos: [],
        pc_ocupacion: 21.0,
        room: {
          double: {
            newPrice: 52.11,
            priceBefore: 53.18,
            priceNow: 52.2,
          },
          single: {
            newPrice: 58.45,
            priceBefore: 58.46,
            priceNow: 55.56,
          },
          suite: {
            newPrice: 65.5,
            priceBefore: 66.26,
            priceNow: 63.96,
          },
        },
      },
      {
        date: '2024-12-04',
        festivos: [
          'Festividad local de Congostrina',
          'Festividad local de Iniestola',
          'Festividad local de Las Hunfrías',
        ],
        pc_ocupacion: 43.0,
        room: {
          double: {
            newPrice: 104.29,
            priceBefore: 101.21,
            priceNow: 101.59,
          },
          single: {
            newPrice: 55.66,
            priceBefore: 51.55,
            priceNow: 49.85,
          },
          suite: {
            newPrice: 151.52,
            priceBefore: 147.28,
            priceNow: 155.21,
          },
        },
      },
      {
        date: '2024-12-05',
        festivos: [
          'Festividad local de Casas de Lazaro',
          'Festividad local de Matillas',
          'Festividad local de Romanones',
        ],
        pc_ocupacion: 21.0,
        room: {
          double: {
            newPrice: 56.97,
            priceBefore: 56.09,
            priceNow: 58.13,
          },
          single: {
            newPrice: 56.01,
            priceBefore: 51.55,
            priceNow: 50.9,
          },
          suite: {
            newPrice: 59.64,
            priceBefore: 57.09,
            priceNow: 62.49,
          },
        },
      },
      {
        date: '2024-12-06',
        festivos: ['Día de la Constitución Española'],
        pc_ocupacion: 27.0,
        room: {
          double: {
            newPrice: 42.28,
            priceBefore: 38.8,
            priceNow: 42.24,
          },
          single: {
            newPrice: 88.19,
            priceBefore: 88.14,
            priceNow: 89.14,
          },
          suite: {
            newPrice: 74.94,
            priceBefore: 68.23,
            priceNow: 73.42,
          },
        },
      },
      {
        date: '2024-12-07',
        festivos: [],
        pc_ocupacion: 23.0,
        room: {
          double: {
            newPrice: 39.25,
            priceBefore: 35.69,
            priceNow: 35.43,
          },
          single: {
            newPrice: 81.25,
            priceBefore: 75.63,
            priceNow: 78.88,
          },
          suite: {
            newPrice: 67.49,
            priceBefore: 69.06,
            priceNow: 70.63,
          },
        },
      },
      {
        date: '2024-12-08',
        festivos: [],
        pc_ocupacion: 22.0,
        room: {
          double: {
            newPrice: 72.74,
            priceBefore: 69.36,
            priceNow: 69.5,
          },
          single: {
            newPrice: 51.55,
            priceBefore: 50.0,
            priceNow: 48.44,
          },
          suite: {
            newPrice: 88.72,
            priceBefore: 82.21,
            priceNow: 78.23,
          },
        },
      },
      {
        date: '2024-12-09',
        festivos: [
          'Festividad local de Almuradiel',
          'Festividad local de Cañada de Calatrava',
          'Festividad local de Arrancacepas',
          'Festividad local de Barchin del Hoyo',
          'Festividad local de Horcajo De Santiago',
          'Festividad local de Viana de Jadraque',
          'Festividad local de Alcaudete de la Jara',
          'Festividad local de Camarenilla',
          'Festividad local de Casasbuenas',
          'Festividad local de Escalona',
          'Festividad local de Navaltoril',
          'Festividad local de Villaseca de la Sagra',
        ],
        pc_ocupacion: 21.0,
        room: {
          double: {
            newPrice: 52.57,
            priceBefore: 50.71,
            priceNow: 48.91,
          },
          single: {
            newPrice: 56.46,
            priceBefore: 56.64,
            priceNow: 57.36,
          },
          suite: {
            newPrice: 84.04,
            priceBefore: 88.39,
            priceNow: 88.57,
          },
        },
      },
      {
        date: '2024-12-10',
        festivos: [
          'Festividad local de Durón',
          'Festividad local de Sienes',
          'Festividad local de Navaltoril',
        ],
        pc_ocupacion: 17.0,
        room: {
          double: {
            newPrice: 47.09,
            priceBefore: 46.52,
            priceNow: 48.1,
          },
          single: {
            newPrice: 49.73,
            priceBefore: 51.26,
            priceNow: 53.21,
          },
          suite: {
            newPrice: 53.19,
            priceBefore: 55.93,
            priceNow: 61.33,
          },
        },
      },
      {
        date: '2024-12-11',
        festivos: [],
        pc_ocupacion: 32.0,
        room: {
          double: {
            newPrice: 88.58,
            priceBefore: 88.56,
            priceNow: 84.35,
          },
          single: {
            newPrice: 82.54,
            priceBefore: 85.21,
            priceNow: 84.38,
          },
          suite: {
            newPrice: 64.97,
            priceBefore: 61.52,
            priceNow: 61.47,
          },
        },
      },
      {
        date: '2024-12-12',
        festivos: [],
        pc_ocupacion: 24.0,
        room: {
          double: {
            newPrice: 55.31,
            priceBefore: 58.04,
            priceNow: 58.87,
          },
          single: {
            newPrice: 58.52,
            priceBefore: 57.41,
            priceNow: 55.92,
          },
          suite: {
            newPrice: 83.53,
            priceBefore: 82.07,
            priceNow: 88.05,
          },
        },
      },
      {
        date: '2024-12-13',
        festivos: [
          'Festividad local de Fuente el Fresno',
          'Festividad local de Retuerta del Bullaque',
          'Festividad local de Almonacid del Marquesado',
          'Festividad local de Bolliga',
          'Festividad local de Castillo-Albarañez',
          'Festividad local de Olmedilla del Campo',
          'Festividad local de Aldeanueva de Guadalajara',
          'Festividad local de Valdeavellano',
        ],
        pc_ocupacion: 20.0,
        room: {
          double: {
            newPrice: 53.04,
            priceBefore: 52.82,
            priceNow: 52.64,
          },
          single: {
            newPrice: 34.53,
            priceBefore: 34.03,
            priceNow: 35.68,
          },
          suite: {
            newPrice: 82.47,
            priceBefore: 82.0,
            priceNow: 87.21,
          },
        },
      },
      {
        date: '2024-12-14',
        festivos: [],
        pc_ocupacion: 24.0,
        room: {
          double: {
            newPrice: 70.26,
            priceBefore: 64.33,
            priceNow: 66.34,
          },
          single: {
            newPrice: 56.91,
            priceBefore: 56.63,
            priceNow: 59.12,
          },
          suite: {
            newPrice: 93.2,
            priceBefore: 90.49,
            priceNow: 88.48,
          },
        },
      },
      {
        date: '2024-12-15',
        festivos: [],
        pc_ocupacion: 25.0,
        room: {
          double: {
            newPrice: 70.47,
            priceBefore: 70.46,
            priceNow: 77.3,
          },
          single: {
            newPrice: 55.94,
            priceBefore: 51.41,
            priceNow: 49.56,
          },
          suite: {
            newPrice: 101.84,
            priceBefore: 92.92,
            priceNow: 98.62,
          },
        },
      },
      {
        date: '2024-12-16',
        festivos: [],
        pc_ocupacion: 19.0,
        room: {
          double: {
            newPrice: 66.77,
            priceBefore: 66.15,
            priceNow: 67.95,
          },
          single: {
            newPrice: 47.3,
            priceBefore: 48.41,
            priceNow: 47.37,
          },
          suite: {
            newPrice: 70.34,
            priceBefore: 64.19,
            priceNow: 67.04,
          },
        },
      },
      {
        date: '2024-12-17',
        festivos: ['Festividad local de Narboneta'],
        pc_ocupacion: 19.0,
        room: {
          double: {
            newPrice: 58.83,
            priceBefore: 60.39,
            priceNow: 61.93,
          },
          single: {
            newPrice: 49.23,
            priceBefore: 46.14,
            priceNow: 48.95,
          },
          suite: {
            newPrice: 59.33,
            priceBefore: 59.9,
            priceNow: 57.78,
          },
        },
      },
      {
        date: '2024-12-18',
        festivos: ['Festividad local de Valverdejo'],
        pc_ocupacion: 33.0,
        room: {
          double: {
            newPrice: 79.1,
            priceBefore: 74.45,
            priceNow: 79.48,
          },
          single: {
            newPrice: 44.92,
            priceBefore: 44.23,
            priceNow: 42.4,
          },
          suite: {
            newPrice: 128.66,
            priceBefore: 117.86,
            priceNow: 129.1,
          },
        },
      },
      {
        date: '2024-12-19',
        festivos: [],
        pc_ocupacion: 29.0,
        room: {
          double: {
            newPrice: 83.43,
            priceBefore: 76.62,
            priceNow: 76.82,
          },
          single: {
            newPrice: 77.13,
            priceBefore: 71.33,
            priceNow: 67.98,
          },
          suite: {
            newPrice: 61.59,
            priceBefore: 60.78,
            priceNow: 59.43,
          },
        },
      },
      {
        date: '2024-12-20',
        festivos: [
          'Festividad local de Torralba',
          'Festividad local de Villaminaya',
        ],
        pc_ocupacion: 33.0,
        room: {
          double: {
            newPrice: 120.65,
            priceBefore: 111.29,
            priceNow: 108.43,
          },
          single: {
            newPrice: 56.49,
            priceBefore: 55.51,
            priceNow: 59.02,
          },
          suite: {
            newPrice: 103.5,
            priceBefore: 107.84,
            priceNow: 115.66,
          },
        },
      },
      {
        date: '2024-12-21',
        festivos: [],
        pc_ocupacion: 26.0,
        room: {
          double: {
            newPrice: 56.76,
            priceBefore: 55.69,
            priceNow: 54.18,
          },
          single: {
            newPrice: 49.25,
            priceBefore: 50.12,
            priceNow: 50.68,
          },
          suite: {
            newPrice: 101.69,
            priceBefore: 96.57,
            priceNow: 101.73,
          },
        },
      },
      {
        date: '2024-12-22',
        festivos: [],
        pc_ocupacion: 19.0,
        room: {
          double: {
            newPrice: 52.02,
            priceBefore: 53.0,
            priceNow: 51.35,
          },
          single: {
            newPrice: 40.12,
            priceBefore: 40.6,
            priceNow: 39.85,
          },
          suite: {
            newPrice: 93.46,
            priceBefore: 96.26,
            priceNow: 102.12,
          },
        },
      },
      {
        date: '2024-12-23',
        festivos: ['Festividad local de Huecas'],
        pc_ocupacion: 29.0,
        room: {
          double: {
            newPrice: 74.62,
            priceBefore: 74.38,
            priceNow: 75.79,
          },
          single: {
            newPrice: 41.24,
            priceBefore: 40.55,
            priceNow: 42.36,
          },
          suite: {
            newPrice: 113.34,
            priceBefore: 107.7,
            priceNow: 111.7,
          },
        },
      },
      {
        date: '2024-12-24',
        festivos: [],
        pc_ocupacion: 25.0,
        room: {
          double: {
            newPrice: 45.38,
            priceBefore: 45.9,
            priceNow: 44.0,
          },
          single: {
            newPrice: 63.58,
            priceBefore: 62.94,
            priceNow: 62.8,
          },
          suite: {
            newPrice: 88.82,
            priceBefore: 87.21,
            priceNow: 93.74,
          },
        },
      },
      {
        date: '2024-12-25',
        festivos: ['Natividad del Señor'],
        pc_ocupacion: 27.0,
        room: {
          double: {
            newPrice: 79.07,
            priceBefore: 78.14,
            priceNow: 77.53,
          },
          single: {
            newPrice: 57.55,
            priceBefore: 52.99,
            priceNow: 50.48,
          },
          suite: {
            newPrice: 103.51,
            priceBefore: 102.45,
            priceNow: 98.85,
          },
        },
      },
      {
        date: '2024-12-26',
        festivos: [
          'Festividad local de Liétor',
          'Festividad local de Barbalimpia',
          'Festividad local de La Huerguina',
          'Festividad local de Humanes',
        ],
        pc_ocupacion: 26.0,
        room: {
          double: {
            newPrice: 86.25,
            priceBefore: 87.39,
            priceNow: 87.73,
          },
          single: {
            newPrice: 33.02,
            priceBefore: 30.36,
            priceNow: 31.7,
          },
          suite: {
            newPrice: 112.89,
            priceBefore: 109.35,
            priceNow: 115.75,
          },
        },
      },
      {
        date: '2024-12-27',
        festivos: ['Festividad local de Casasimarro'],
        pc_ocupacion: 35.0,
        room: {
          double: {
            newPrice: 30.46,
            priceBefore: 32.06,
            priceNow: 33.22,
          },
          single: {
            newPrice: 75.73,
            priceBefore: 72.92,
            priceNow: 70.8,
          },
          suite: {
            newPrice: 163.02,
            priceBefore: 149.29,
            priceNow: 143.28,
          },
        },
      },
      {
        date: '2024-12-28',
        festivos: ['Festividad local de Alcázar de San Juan'],
        pc_ocupacion: 41.0,
        room: {
          double: {
            newPrice: 106.47,
            priceBefore: 107.99,
            priceNow: 108.39,
          },
          single: {
            newPrice: 56.77,
            priceBefore: 51.72,
            priceNow: 56.84,
          },
          suite: {
            newPrice: 146.75,
            priceBefore: 153.65,
            priceNow: 156.81,
          },
        },
      },
      {
        date: '2024-12-29',
        festivos: [],
        pc_ocupacion: 37.0,
        room: {
          double: {
            newPrice: 90.29,
            priceBefore: 82.18,
            priceNow: 88.81,
          },
          single: {
            newPrice: 59.05,
            priceBefore: 53.88,
            priceNow: 54.31,
          },
          suite: {
            newPrice: 132.09,
            priceBefore: 131.16,
            priceNow: 128.32,
          },
        },
      },
      {
        date: '2024-12-30',
        festivos: ['Festividad local de Alcabón'],
        pc_ocupacion: 37.0,
        room: {
          double: {
            newPrice: 99.44,
            priceBefore: 96.78,
            priceNow: 101.09,
          },
          single: {
            newPrice: 56.02,
            priceBefore: 54.88,
            priceNow: 56.36,
          },
          suite: {
            newPrice: 156.02,
            priceBefore: 160.53,
            priceNow: 164.29,
          },
        },
      },
      {
        date: '2024-12-31',
        festivos: ['Festividad local de El Robledo'],
        pc_ocupacion: 51.0,
        room: {
          double: {
            newPrice: 96.84,
            priceBefore: 100.99,
            priceNow: 108.11,
          },
          single: {
            newPrice: 112.52,
            priceBefore: 113.19,
            priceNow: 117.84,
          },
          suite: {
            newPrice: 174.66,
            priceBefore: 170.44,
            priceNow: 165.97,
          },
        },
      },
      {
        date: '2025-01-01',
        festivos: ['Año Nuevo'],
        pc_ocupacion: 15.0,
        room: {
          double: {
            newPrice: 21.17,
            priceBefore: 20.39,
            priceNow: 22.12,
          },
          single: {
            newPrice: 41.11,
            priceBefore: 39.0,
            priceNow: 40.93,
          },
          suite: {
            newPrice: 71.55,
            priceBefore: 72.58,
            priceNow: 77.23,
          },
        },
      },
      {
        date: '2025-01-02',
        festivos: ['Festividad local de Rozalen Del Monte'],
        pc_ocupacion: 13.0,
        room: {
          double: {
            newPrice: 48.88,
            priceBefore: 46.19,
            priceNow: 45.13,
          },
          single: {
            newPrice: 48.91,
            priceBefore: 47.86,
            priceNow: 47.42,
          },
          suite: {
            newPrice: 36.91,
            priceBefore: 38.38,
            priceNow: 40.08,
          },
        },
      },
      {
        date: '2025-01-03',
        festivos: [],
        pc_ocupacion: 11.0,
        room: {
          double: {
            newPrice: 25.16,
            priceBefore: 25.61,
            priceNow: 24.38,
          },
          single: {
            newPrice: 33.98,
            priceBefore: 31.2,
            priceNow: 32.5,
          },
          suite: {
            newPrice: 61.19,
            priceBefore: 60.1,
            priceNow: 65.05,
          },
        },
      },
      {
        date: '2025-01-04',
        festivos: [],
        pc_ocupacion: 13.0,
        room: {
          double: {
            newPrice: 43.76,
            priceBefore: 45.28,
            priceNow: 46.59,
          },
          single: {
            newPrice: 27.76,
            priceBefore: 27.69,
            priceNow: 29.68,
          },
          suite: {
            newPrice: 64.66,
            priceBefore: 64.26,
            priceNow: 63.09,
          },
        },
      },
      {
        date: '2025-01-05',
        festivos: [
          'Festividad local de Fuentes',
          'Festividad local de La Estrella',
        ],
        pc_ocupacion: 12.0,
        room: {
          double: {
            newPrice: 33.54,
            priceBefore: 33.81,
            priceNow: 36.54,
          },
          single: {
            newPrice: 31.89,
            priceBefore: 32.13,
            priceNow: 33.72,
          },
          suite: {
            newPrice: 52.68,
            priceBefore: 53.03,
            priceNow: 51.93,
          },
        },
      },
      {
        date: '2025-01-06',
        festivos: ['Epifanía del Señor'],
        pc_ocupacion: 13.0,
        room: {
          double: {
            newPrice: 34.55,
            priceBefore: 32.67,
            priceNow: 31.34,
          },
          single: {
            newPrice: 32.17,
            priceBefore: 31.96,
            priceNow: 33.81,
          },
          suite: {
            newPrice: 63.18,
            priceBefore: 59.03,
            priceNow: 62.15,
          },
        },
      },
      {
        date: '2025-01-07',
        festivos: [],
        pc_ocupacion: 13.0,
        room: {
          double: {
            newPrice: 39.11,
            priceBefore: 38.08,
            priceNow: 41.09,
          },
          single: {
            newPrice: 30.08,
            priceBefore: 29.2,
            priceNow: 29.54,
          },
          suite: {
            newPrice: 64.57,
            priceBefore: 64.44,
            priceNow: 66.74,
          },
        },
      },
      {
        date: '2025-01-08',
        festivos: ['Festividad local de Valverde de Jucar'],
        pc_ocupacion: 14.0,
        room: {
          double: {
            newPrice: 36.91,
            priceBefore: 35.02,
            priceNow: 36.82,
          },
          single: {
            newPrice: 48.55,
            priceBefore: 45.64,
            priceNow: 43.93,
          },
          suite: {
            newPrice: 62.31,
            priceBefore: 59.18,
            priceNow: 60.55,
          },
        },
      },
      {
        date: '2025-01-09',
        festivos: [],
        pc_ocupacion: 11.0,
        room: {
          double: {
            newPrice: 48.91,
            priceBefore: 47.34,
            priceNow: 49.71,
          },
          single: {
            newPrice: 16.56,
            priceBefore: 17.24,
            priceNow: 17.5,
          },
          suite: {
            newPrice: 60.58,
            priceBefore: 60.0,
            priceNow: 57.39,
          },
        },
      },
      {
        date: '2025-01-10',
        festivos: [],
        pc_ocupacion: 7.0,
        room: {
          double: {
            newPrice: 28.37,
            priceBefore: 29.83,
            priceNow: 30.72,
          },
          single: {
            newPrice: 23.91,
            priceBefore: 21.89,
            priceNow: 21.25,
          },
          suite: {
            newPrice: 36.66,
            priceBefore: 38.28,
            priceNow: 38.69,
          },
        },
      },
      {
        date: '2025-01-11',
        festivos: [],
        pc_ocupacion: 11.0,
        room: {
          double: {
            newPrice: 41.78,
            priceBefore: 41.82,
            priceNow: 45.94,
          },
          single: {
            newPrice: 26.78,
            priceBefore: 28.14,
            priceNow: 30.57,
          },
          suite: {
            newPrice: 58.67,
            priceBefore: 57.77,
            priceNow: 61.02,
          },
        },
      },
      {
        date: '2025-01-12',
        festivos: [],
        pc_ocupacion: 11.0,
        room: {
          double: {
            newPrice: 36.26,
            priceBefore: 37.37,
            priceNow: 40.95,
          },
          single: {
            newPrice: 35.07,
            priceBefore: 33.78,
            priceNow: 34.7,
          },
          suite: {
            newPrice: 55.42,
            priceBefore: 57.14,
            priceNow: 54.81,
          },
        },
      },
      {
        date: '2025-01-13',
        festivos: [],
        pc_ocupacion: 15.0,
        room: {
          double: {
            newPrice: 41.06,
            priceBefore: 41.82,
            priceNow: 44.99,
          },
          single: {
            newPrice: 28.84,
            priceBefore: 29.82,
            priceNow: 29.35,
          },
          suite: {
            newPrice: 68.0,
            priceBefore: 67.45,
            priceNow: 73.43,
          },
        },
      },
      {
        date: '2025-01-14',
        festivos: [],
        pc_ocupacion: 6.0,
        room: {
          double: {
            newPrice: 44.6,
            priceBefore: 45.55,
            priceNow: 49.82,
          },
          single: {
            newPrice: 2.91,
            priceBefore: 2.88,
            priceNow: 2.96,
          },
          suite: {
            newPrice: 48.98,
            priceBefore: 48.33,
            priceNow: 45.97,
          },
        },
      },
      {
        date: '2025-01-15',
        festivos: [],
        pc_ocupacion: 11.0,
        room: {
          double: {
            newPrice: 39.03,
            priceBefore: 36.47,
            priceNow: 39.76,
          },
          single: {
            newPrice: 29.61,
            priceBefore: 31.09,
            priceNow: 33.27,
          },
          suite: {
            newPrice: 60.35,
            priceBefore: 59.78,
            priceNow: 62.23,
          },
        },
      },
      {
        date: '2025-01-16',
        festivos: [],
        pc_ocupacion: 9.0,
        room: {
          double: {
            newPrice: 42.83,
            priceBefore: 42.98,
            priceNow: 43.5,
          },
          single: {
            newPrice: 35.31,
            priceBefore: 36.74,
            priceNow: 36.39,
          },
          suite: {
            newPrice: 38.99,
            priceBefore: 37.17,
            priceNow: 38.56,
          },
        },
      },
      {
        date: '2025-01-17',
        festivos: [
          'Festividad local de Aguas Nuevas',
          'Festividad local de Balsa de Vés',
          'Festividad local de Casas de Juan Núñez',
          'Festividad local de Mahora',
          'Festividad local de Minaya',
          'Festividad local de Robledo',
          'Festividad local de Tobarra',
          'Festividad local de Villamalea',
          'Festividad local de Alcubillas',
          'Festividad local de Puerto Lápice',
          'Festividad local de Ruidera',
          'Festividad local de Hontecillas',
          'Festividad local de Santa Maria de los Llanos',
          'Festividad local de Santa Maria del Campo Rus',
          'Festividad local de Tebar',
          'Festividad local de Alique',
          'Festividad local de Berninches',
          'Festividad local de Cendejas de Enmedio',
          'Festividad local de Escariche',
          'Festividad local de Almonacid de Toledo',
          'Festividad local de Cuerva',
          'Festividad local de Menasalbas',
          'Festividad local de Navalmoralejo',
        ],
        pc_ocupacion: 11.0,
        room: {
          double: {
            newPrice: 29.37,
            priceBefore: 29.41,
            priceNow: 29.88,
          },
          single: {
            newPrice: 41.02,
            priceBefore: 37.59,
            priceNow: 36.44,
          },
          suite: {
            newPrice: 48.5,
            priceBefore: 45.5,
            priceNow: 45.44,
          },
        },
      },
      {
        date: '2025-01-18',
        festivos: [
          'Festividad local de Cendejas de la Torre',
          'Festividad local de Medranda',
        ],
        pc_ocupacion: 7.0,
        room: {
          double: {
            newPrice: 40.27,
            priceBefore: 37.46,
            priceNow: 40.07,
          },
          single: {
            newPrice: 26.51,
            priceBefore: 26.44,
            priceNow: 26.06,
          },
          suite: {
            newPrice: 51.25,
            priceBefore: 46.6,
            priceNow: 46.89,
          },
        },
      },
      {
        date: '2025-01-19',
        festivos: [
          'Festividad local de Bogarra',
          'Festividad local de Alarcón',
          'Festividad local de Culebras',
          'Festividad local de El Cubillo',
          'Festividad local de Garaballa',
          'Festividad local de Olmeda de la Cuesta',
          'Festividad local de Villalba del Rey',
          'Festividad local de Villar del Águila',
          'Festividad local de Villar del Horno',
          'Festividad local de Cendejas de la Torre',
          'Festividad local de Hortezuela de Océn',
          'Festividad local de El Membrillo',
          'Festividad local de El Toboso',
          'Festividad local de La Mata',
          'Festividad local de Marrupe',
          'Festividad local de Mohedas de La Jara',
          'Festividad local de Sevilleja de la Jara',
          'Festividad local de Sotillo de las Palomas',
          'Festividad local de Villamiel',
        ],
        pc_ocupacion: 9.0,
        room: {
          double: {
            newPrice: 36.15,
            priceBefore: 36.27,
            priceNow: 38.13,
          },
          single: {
            newPrice: 27.66,
            priceBefore: 25.38,
            priceNow: 24.71,
          },
          suite: {
            newPrice: 48.08,
            priceBefore: 46.25,
            priceNow: 44.78,
          },
        },
      },
      {
        date: '2025-01-20',
        festivos: [
          'Festividad local de Fernán Caballero',
          'Festividad local de Valdemanco del Esteras',
          'Festividad local de Campillos Paravientos',
          'Festividad local de Casas de Roldan',
          'Festividad local de El Cubillo',
          'Festividad local de Graja de Campalbo',
          'Festividad local de Torrecilla',
          'Festividad local de Villar del Saz de Navalon',
          'Festividad local de Alustante',
          'Festividad local de La Huerce',
          'Festividad local de Millana',
          'Festividad local de Montarrón',
          'Festividad local de Valdarachas',
          'Festividad local de Belvís de la Jara',
          'Festividad local de El Membrillo',
          'Festividad local de Madridejos',
          'Festividad local de Nuño Gómez',
          'Festividad local de Parrillas',
        ],
        pc_ocupacion: 9.0,
        room: {
          double: {
            newPrice: 54.64,
            priceBefore: 51.51,
            priceNow: 50.83,
          },
          single: {
            newPrice: 43.16,
            priceBefore: 40.62,
            priceNow: 39.97,
          },
          suite: {
            newPrice: 34.86,
            priceBefore: 33.1,
            priceNow: 33.44,
          },
        },
      },
      {
        date: '2025-01-21',
        festivos: [
          'Festividad local de Aldeanueva de Atienza',
          'Festividad local de La Mierla',
        ],
        pc_ocupacion: 7.0,
        room: {
          double: {
            newPrice: 16.89,
            priceBefore: 17.29,
            priceNow: 17.14,
          },
          single: {
            newPrice: 37.14,
            priceBefore: 33.81,
            priceNow: 34.51,
          },
          suite: {
            newPrice: 52.6,
            priceBefore: 51.15,
            priceNow: 54.8,
          },
        },
      },
      {
        date: '2025-01-22',
        festivos: [
          'Festividad local de La Solana',
          'Festividad local de Casas de Guijarro',
          'Festividad local de Las Valeras',
          'Festividad local de Olmedilla de Alarcon',
          'Festividad local de Bujarrabal',
          'Festividad local de Cincovillas',
          'Festividad local de Condemios de Arriba',
          'Festividad local de Pastrana',
          'Festividad local de Quer',
          'Festividad local de Sigüenza',
          'Festividad local de Cazalegas',
          'Festividad local de Gálvez',
          'Festividad local de Malpica de Tajo',
          'Festividad local de Montesclaros',
          'Festividad local de Paredes de Escalona',
          'Festividad local de Puerto de San Vicente',
          'Festividad local de Robledo del Mazo',
        ],
        pc_ocupacion: 8.0,
        room: {
          double: {
            newPrice: 33.6,
            priceBefore: 32.52,
            priceNow: 35.45,
          },
          single: {
            newPrice: 43.4,
            priceBefore: 42.16,
            priceNow: 43.91,
          },
          suite: {
            newPrice: 46.55,
            priceBefore: 47.87,
            priceNow: 49.72,
          },
        },
      },
      {
        date: '2025-01-23',
        festivos: [
          'Festividad local de Albatana',
          'Festividad local de Villavaliente',
          'Festividad local de Villaseca',
          'Festividad local de Valverde de los Arroyos',
          'Festividad local de Herreruela de Oropesa',
          'Festividad local de Toledo',
        ],
        pc_ocupacion: 7.0,
        room: {
          double: {
            newPrice: 45.98,
            priceBefore: 41.98,
            priceNow: 42.4,
          },
          single: {
            newPrice: 24.07,
            priceBefore: 22.23,
            priceNow: 23.02,
          },
          suite: {
            newPrice: 44.18,
            priceBefore: 45.6,
            priceNow: 49.74,
          },
        },
      },
      {
        date: '2025-01-24',
        festivos: [
          'Festividad local de Cabezarrubias del Puerto',
          'Festividad local de Corral de Calatrava',
          'Festividad local de Villarta San Juan',
          'Festividad local de Alovera',
          'Festividad local de Aragosa',
          'Festividad local de Baides',
          'Festividad local de Mandayona',
          'Festividad local de Mazuecos',
          'Festividad local de La Puebla de Montalbán',
          'Festividad local de La Pueblanueva',
          'Festividad local de La Rinconada',
          'Festividad local de Portillo de Toledo',
          'Festividad local de Santa Cruz del Retamar',
          'Festividad local de Villarejo de Montalbán',
        ],
        pc_ocupacion: 8.0,
        room: {
          double: {
            newPrice: 29.16,
            priceBefore: 27.59,
            priceNow: 26.59,
          },
          single: {
            newPrice: 29.73,
            priceBefore: 27.29,
            priceNow: 27.02,
          },
          suite: {
            newPrice: 41.22,
            priceBefore: 38.43,
            priceNow: 38.62,
          },
        },
      },
      {
        date: '2025-01-25',
        festivos: [
          'Festividad local de Fuencemillan',
          'Festividad local de Mazuecos',
          'Festividad local de Navalcán',
          'Festividad local de San Pablo de los Montes',
        ],
        pc_ocupacion: 7.0,
        room: {
          double: {
            newPrice: 39.36,
            priceBefore: 35.88,
            priceNow: 39.21,
          },
          single: {
            newPrice: 35.75,
            priceBefore: 36.68,
            priceNow: 37.57,
          },
          suite: {
            newPrice: 32.98,
            priceBefore: 34.04,
            priceNow: 36.32,
          },
        },
      },
      {
        date: '2025-01-26',
        festivos: ['Festividad local de El Herrumblar'],
        pc_ocupacion: 10.0,
        room: {
          double: {
            newPrice: 43.0,
            priceBefore: 40.22,
            priceNow: 39.9,
          },
          single: {
            newPrice: 24.97,
            priceBefore: 24.77,
            priceNow: 25.46,
          },
          suite: {
            newPrice: 49.12,
            priceBefore: 48.82,
            priceNow: 46.94,
          },
        },
      },
      {
        date: '2025-01-27',
        festivos: [],
        pc_ocupacion: 11.0,
        room: {
          double: {
            newPrice: 33.41,
            priceBefore: 34.0,
            priceNow: 35.3,
          },
          single: {
            newPrice: 35.37,
            priceBefore: 32.39,
            priceNow: 33.21,
          },
          suite: {
            newPrice: 56.21,
            priceBefore: 56.74,
            priceNow: 61.75,
          },
        },
      },
      {
        date: '2025-01-28',
        festivos: [],
        pc_ocupacion: 15.0,
        room: {
          double: {
            newPrice: 28.36,
            priceBefore: 27.55,
            priceNow: 26.26,
          },
          single: {
            newPrice: 25.08,
            priceBefore: 22.93,
            priceNow: 23.15,
          },
          suite: {
            newPrice: 73.17,
            priceBefore: 67.92,
            priceNow: 65.96,
          },
        },
      },
      {
        date: '2025-01-29',
        festivos: [
          'Festividad local de Casas de Haro',
          'Festividad local de Tarancon',
          'Festividad local de Aldeanueva de Barbarroya',
        ],
        pc_ocupacion: 8.0,
        room: {
          double: {
            newPrice: 40.17,
            priceBefore: 37.8,
            priceNow: 41.26,
          },
          single: {
            newPrice: 31.5,
            priceBefore: 32.72,
            priceNow: 33.67,
          },
          suite: {
            newPrice: 37.67,
            priceBefore: 35.86,
            priceNow: 34.55,
          },
        },
      },
      {
        date: '2025-01-30',
        festivos: [],
        pc_ocupacion: 9.0,
        room: {
          double: {
            newPrice: 19.91,
            priceBefore: 19.31,
            priceNow: 18.83,
          },
          single: {
            newPrice: 44.86,
            priceBefore: 42.04,
            priceNow: 44.15,
          },
          suite: {
            newPrice: 47.96,
            priceBefore: 44.03,
            priceNow: 43.58,
          },
        },
      },
      {
        date: '2025-01-31',
        festivos: ['Festividad local de Albendiego'],
        pc_ocupacion: 7.0,
        room: {
          double: {
            newPrice: 17.67,
            priceBefore: 16.9,
            priceNow: 17.65,
          },
          single: {
            newPrice: 23.59,
            priceBefore: 21.75,
            priceNow: 23.15,
          },
          suite: {
            newPrice: 43.7,
            priceBefore: 41.47,
            priceNow: 42.73,
          },
        },
      },
      {
        date: '2025-02-01',
        festivos: [
          'Festividad local de Alcaraz',
          'Festividad local de Pozo de Guadalajara',
          'Festividad local de Villanueva de Argecilla',
          'Festividad local de Cardiel de los Montes',
        ],
        pc_ocupacion: 15.0,
        room: {
          double: {
            newPrice: 41.38,
            priceBefore: 38.08,
            priceNow: 36.99,
          },
          single: {
            newPrice: 26.94,
            priceBefore: 26.99,
            priceNow: 26.74,
          },
          suite: {
            newPrice: 65.54,
            priceBefore: 63.99,
            priceNow: 63.59,
          },
        },
      },
      {
        date: '2025-02-02',
        festivos: [
          'Festividad local de Balazote',
          'Festividad local de Villaverde del Guadalimar',
          'Festividad local de Luciana',
          'Festividad local de Albalate de las Nogueras',
          'Festividad local de Casas de los Pinos',
          'Festividad local de Castejon',
          'Festividad local de Cervera del Llano',
          'Festividad local de Fresneda de la Sierra',
          'Festividad local de Jabalera',
          'Festividad local de La Pesquera',
          'Festividad local de Los Estesos',
          'Festividad local de Pajares',
          'Festividad local de Solera de Gabaldón',
          'Festividad local de Sotoca',
          'Festividad local de Torrejoncillo del Rey',
          'Festividad local de Torrubia del Campo',
          'Festividad local de Villaverde y Pasaconsol',
          'Festividad local de Albalate de Zorita',
          'Festividad local de Arbancón',
          'Festividad local de Malaguilla',
          'Festividad local de Torrejón del Rey',
          'Festividad local de Valdeconcha',
          'Festividad local de Villanueva de Argecilla',
          'Festividad local de Villaseca de Henares',
          'Festividad local de Arcicollar',
          'Festividad local de Buenasbodas',
          'Festividad local de Campillo de La Jara',
          'Festividad local de Cazalegas',
          'Festividad local de Cebolla',
          'Festividad local de Gamonal',
          'Festividad local de Las Navillas',
        ],
        pc_ocupacion: 9.0,
        room: {
          double: {
            newPrice: 38.36,
            priceBefore: 38.67,
            priceNow: 39.5,
          },
          single: {
            newPrice: 21.85,
            priceBefore: 20.78,
            priceNow: 21.07,
          },
          suite: {
            newPrice: 47.57,
            priceBefore: 49.44,
            priceNow: 51.2,
          },
        },
      },
      {
        date: '2025-02-03',
        festivos: [
          'Festividad local de Elche De La Sierra',
          'Festividad local de Tarazona de la Mancha',
          'Festividad local de Hinojosas de Calatrava',
          'Festividad local de Villar del Pozo',
          'Festividad local de Cañaveruelas',
          'Festividad local de Enguidanos',
          'Festividad local de La Peraleja',
          'Festividad local de Pajaron',
          'Festividad local de Tribaldos',
          'Festividad local de Villanueva de Guadamejud',
          'Festividad local de Villar del Saz de Arcas',
          'Festividad local de Villarejo de la Peñuela',
          'Festividad local de Villarejo Seco',
          'Festividad local de Angón',
          'Festividad local de Atanzón',
          'Festividad local de Barriopedro',
          'Festividad local de Castilforte',
          'Festividad local de Copernal',
          'Festividad local de Huérmeces del Cerro',
          'Festividad local de Irueste',
          'Festividad local de La Toba',
          'Festividad local de Saúca',
          'Festividad local de Tobillos',
          'Festividad local de Villaseca de Henares',
          'Festividad local de Cervera de los Montes',
          'Festividad local de Recas',
        ],
        pc_ocupacion: 8.0,
        room: {
          double: {
            newPrice: 36.9,
            priceBefore: 38.25,
            priceNow: 40.39,
          },
          single: {
            newPrice: 31.1,
            priceBefore: 28.46,
            priceNow: 27.87,
          },
          suite: {
            newPrice: 45.93,
            priceBefore: 44.8,
            priceNow: 47.63,
          },
        },
      },
      {
        date: '2025-02-04',
        festivos: [],
        pc_ocupacion: 9.0,
        room: {
          double: {
            newPrice: 33.99,
            priceBefore: 33.92,
            priceNow: 37.09,
          },
          single: {
            newPrice: 23.88,
            priceBefore: 23.16,
            priceNow: 23.65,
          },
          suite: {
            newPrice: 38.34,
            priceBefore: 38.25,
            priceNow: 36.61,
          },
        },
      },
      {
        date: '2025-02-05',
        festivos: [
          'Festividad local de Caracuel de Calatrava',
          'Festividad local de Pinarejo',
          'Festividad local de Pozoamargo',
          'Festividad local de Santa Cruz de Moya',
          'Festividad local de Villalgordo del Marquesado',
          'Festividad local de Zarza de Tajo',
          'Festividad local de Castilforte',
          'Festividad local de Centenera',
          'Festividad local de El Casar',
          'Festividad local de Galápagos',
          'Festividad local de Hontoba',
          'Festividad local de Pioz',
          'Festividad local de Retiendas',
          'Festividad local de Villanueva de la Torre',
          'Festividad local de Buenasbodas',
          'Festividad local de Montesclaros',
          'Festividad local de Pepino',
        ],
        pc_ocupacion: 12.0,
        room: {
          double: {
            newPrice: 47.2,
            priceBefore: 43.14,
            priceNow: 45.95,
          },
          single: {
            newPrice: 30.22,
            priceBefore: 28.6,
            priceNow: 29.59,
          },
          suite: {
            newPrice: 50.26,
            priceBefore: 49.61,
            priceNow: 54.07,
          },
        },
      },
      {
        date: '2025-02-06',
        festivos: [],
        pc_ocupacion: 6.0,
        room: {
          double: {
            newPrice: 38.36,
            priceBefore: 34.91,
            priceNow: 33.99,
          },
          single: {
            newPrice: 29.05,
            priceBefore: 27.94,
            priceNow: 28.95,
          },
          suite: {
            newPrice: 47.65,
            priceBefore: 45.27,
            priceNow: 47.22,
          },
        },
      },
      {
        date: '2025-02-07',
        festivos: [],
        pc_ocupacion: 12.0,
        room: {
          double: {
            newPrice: 65.01,
            priceBefore: 60.5,
            priceNow: 66.11,
          },
          single: {
            newPrice: 23.86,
            priceBefore: 24.87,
            priceNow: 24.1,
          },
          suite: {
            newPrice: 43.03,
            priceBefore: 44.15,
            priceNow: 45.5,
          },
        },
      },
      {
        date: '2025-02-08',
        festivos: [
          'Festividad local de Atalaya del Cañavate',
          'Festividad local de Casas de Benitez',
          'Festividad local de Paracuellos de la Vega',
          'Festividad local de Pozorrubio de Santiago',
          'Festividad local de Sisante',
          'Festividad local de Vara de Rey',
          'Festividad local de Alcaudete de la Jara',
          'Festividad local de San Martín de Montalbán',
        ],
        pc_ocupacion: 12.0,
        room: {
          double: {
            newPrice: 19.61,
            priceBefore: 20.35,
            priceNow: 22.09,
          },
          single: {
            newPrice: 32.54,
            priceBefore: 32.71,
            priceNow: 35.58,
          },
          suite: {
            newPrice: 65.82,
            priceBefore: 65.42,
            priceNow: 71.0,
          },
        },
      },
      {
        date: '2025-02-09',
        festivos: [
          'Festividad local de Albaladejo del Cuende',
          'Festividad local de Tortuero',
        ],
        pc_ocupacion: 11.0,
        room: {
          double: {
            newPrice: 19.94,
            priceBefore: 20.2,
            priceNow: 19.42,
          },
          single: {
            newPrice: 22.9,
            priceBefore: 23.3,
            priceNow: 22.43,
          },
          suite: {
            newPrice: 60.21,
            priceBefore: 60.86,
            priceNow: 63.05,
          },
        },
      },
      {
        date: '2025-02-10',
        festivos: [],
        pc_ocupacion: 8.0,
        room: {
          double: {
            newPrice: 40.7,
            priceBefore: 38.58,
            priceNow: 39.61,
          },
          single: {
            newPrice: 43.57,
            priceBefore: 41.95,
            priceNow: 40.59,
          },
          suite: {
            newPrice: 32.61,
            priceBefore: 33.84,
            priceNow: 34.26,
          },
        },
      },
      {
        date: '2025-02-11',
        festivos: [],
        pc_ocupacion: 13.0,
        room: {
          double: {
            newPrice: 23.9,
            priceBefore: 23.13,
            priceNow: 24.27,
          },
          single: {
            newPrice: 45.02,
            priceBefore: 42.21,
            priceNow: 43.18,
          },
          suite: {
            newPrice: 68.65,
            priceBefore: 71.37,
            priceNow: 76.35,
          },
        },
      },
      {
        date: '2025-02-12',
        festivos: [
          'Festividad local de Moral de Calatrava',
          'Festividad local de Pedro Muñoz',
          'Festividad local de Santa Cruz de Mudela',
          'Festividad local de Las Mesas',
          'Festividad local de Los Yébenes',
        ],
        pc_ocupacion: 9.0,
        room: {
          double: {
            newPrice: 26.67,
            priceBefore: 26.53,
            priceNow: 27.6,
          },
          single: {
            newPrice: 28.5,
            priceBefore: 26.25,
            priceNow: 25.7,
          },
          suite: {
            newPrice: 51.25,
            priceBefore: 53.23,
            priceNow: 51.1,
          },
        },
      },
      {
        date: '2025-02-13',
        festivos: [
          'Festividad local de Herencia',
          'Festividad local de Torrenueva',
          'Festividad local de Almorox',
        ],
        pc_ocupacion: 13.0,
        room: {
          double: {
            newPrice: 33.98,
            priceBefore: 35.02,
            priceNow: 33.7,
          },
          single: {
            newPrice: 41.53,
            priceBefore: 40.99,
            priceNow: 41.7,
          },
          suite: {
            newPrice: 49.52,
            priceBefore: 50.77,
            priceNow: 53.27,
          },
        },
      },
      {
        date: '2025-02-14',
        festivos: [
          'Festividad local de La Roda',
          'Festividad local de Miguelturra',
          'Festividad local de Mejorada',
        ],
        pc_ocupacion: 22.0,
        room: {
          double: {
            newPrice: 63.72,
            priceBefore: 59.18,
            priceNow: 56.99,
          },
          single: {
            newPrice: 28.3,
            priceBefore: 29.29,
            priceNow: 31.73,
          },
          suite: {
            newPrice: 97.78,
            priceBefore: 93.81,
            priceNow: 102.77,
          },
        },
      },
      {
        date: '2025-02-15',
        festivos: [],
        pc_ocupacion: 5.0,
        room: {
          double: {
            newPrice: 23.62,
            priceBefore: 23.36,
            priceNow: 23.7,
          },
          single: {
            newPrice: 36.05,
            priceBefore: 34.69,
            priceNow: 34.54,
          },
          suite: {
            newPrice: 32.28,
            priceBefore: 32.44,
            priceNow: 35.51,
          },
        },
      },
      {
        date: '2025-02-16',
        festivos: ['Festividad local de Villarrobledo'],
        pc_ocupacion: 13.0,
        room: {
          double: {
            newPrice: 51.81,
            priceBefore: 48.09,
            priceNow: 48.43,
          },
          single: {
            newPrice: 30.59,
            priceBefore: 31.36,
            priceNow: 31.24,
          },
          suite: {
            newPrice: 59.25,
            priceBefore: 56.07,
            priceNow: 58.93,
          },
        },
      },
      {
        date: '2025-02-17',
        festivos: [],
        pc_ocupacion: 8.0,
        room: {
          double: {
            newPrice: 39.07,
            priceBefore: 40.74,
            priceNow: 40.2,
          },
          single: {
            newPrice: 29.17,
            priceBefore: 30.67,
            priceNow: 31.06,
          },
          suite: {
            newPrice: 44.41,
            priceBefore: 40.51,
            priceNow: 39.87,
          },
        },
      },
      {
        date: '2025-02-18',
        festivos: [],
        pc_ocupacion: 14.0,
        room: {
          double: {
            newPrice: 51.4,
            priceBefore: 49.84,
            priceNow: 54.38,
          },
          single: {
            newPrice: 31.64,
            priceBefore: 29.61,
            priceNow: 31.38,
          },
          suite: {
            newPrice: 59.27,
            priceBefore: 57.07,
            priceNow: 55.15,
          },
        },
      },
      {
        date: '2025-02-19',
        festivos: [],
        pc_ocupacion: 17.0,
        room: {
          double: {
            newPrice: 44.89,
            priceBefore: 42.61,
            priceNow: 42.59,
          },
          single: {
            newPrice: 34.76,
            priceBefore: 32.75,
            priceNow: 33.66,
          },
          suite: {
            newPrice: 66.72,
            priceBefore: 67.75,
            priceNow: 69.54,
          },
        },
      },
      {
        date: '2025-02-20',
        festivos: ['Festividad local de Valderrebollo'],
        pc_ocupacion: 13.0,
        room: {
          double: {
            newPrice: 35.74,
            priceBefore: 34.95,
            priceNow: 38.42,
          },
          single: {
            newPrice: 38.51,
            priceBefore: 35.86,
            priceNow: 34.14,
          },
          suite: {
            newPrice: 51.9,
            priceBefore: 49.61,
            priceNow: 52.18,
          },
        },
      },
      {
        date: '2025-02-21',
        festivos: ['Festividad local de Chillaron de Cuenca'],
        pc_ocupacion: 13.0,
        room: {
          double: {
            newPrice: 39.02,
            priceBefore: 39.31,
            priceNow: 40.84,
          },
          single: {
            newPrice: 29.35,
            priceBefore: 27.22,
            priceNow: 29.79,
          },
          suite: {
            newPrice: 54.4,
            priceBefore: 54.08,
            priceNow: 54.7,
          },
        },
      },
      {
        date: '2025-02-22',
        festivos: ['Festividad local de Carriches'],
        pc_ocupacion: 14.0,
        room: {
          double: {
            newPrice: 50.63,
            priceBefore: 49.86,
            priceNow: 49.18,
          },
          single: {
            newPrice: 27.7,
            priceBefore: 27.95,
            priceNow: 27.78,
          },
          suite: {
            newPrice: 79.25,
            priceBefore: 73.59,
            priceNow: 75.51,
          },
        },
      },
      {
        date: '2025-02-23',
        festivos: [],
        pc_ocupacion: 23.0,
        room: {
          double: {
            newPrice: 61.53,
            priceBefore: 63.86,
            priceNow: 61.22,
          },
          single: {
            newPrice: 68.6,
            priceBefore: 66.63,
            priceNow: 68.73,
          },
          suite: {
            newPrice: 71.96,
            priceBefore: 65.49,
            priceNow: 65.49,
          },
        },
      },
      {
        date: '2025-02-24',
        festivos: ['Festividad local de Herreruela de Oropesa'],
        pc_ocupacion: 13.0,
        room: {
          double: {
            newPrice: 51.33,
            priceBefore: 51.21,
            priceNow: 51.06,
          },
          single: {
            newPrice: 19.22,
            priceBefore: 19.72,
            priceNow: 19.53,
          },
          suite: {
            newPrice: 74.94,
            priceBefore: 68.53,
            priceNow: 71.57,
          },
        },
      },
      {
        date: '2025-02-25',
        festivos: [],
        pc_ocupacion: 12.0,
        room: {
          double: {
            newPrice: 45.25,
            priceBefore: 44.3,
            priceNow: 44.57,
          },
          single: {
            newPrice: 35.4,
            priceBefore: 32.54,
            priceNow: 33.19,
          },
          suite: {
            newPrice: 34.41,
            priceBefore: 35.72,
            priceNow: 34.75,
          },
        },
      },
      {
        date: '2025-02-26',
        festivos: [],
        pc_ocupacion: 12.0,
        room: {
          double: {
            newPrice: 25.74,
            priceBefore: 25.89,
            priceNow: 28.17,
          },
          single: {
            newPrice: 38.16,
            priceBefore: 35.37,
            priceNow: 38.44,
          },
          suite: {
            newPrice: 64.9,
            priceBefore: 59.33,
            priceNow: 63.97,
          },
        },
      },
      {
        date: '2025-02-27',
        festivos: [],
        pc_ocupacion: 16.0,
        room: {
          double: {
            newPrice: 58.3,
            priceBefore: 57.53,
            priceNow: 56.51,
          },
          single: {
            newPrice: 28.95,
            priceBefore: 27.41,
            priceNow: 29.24,
          },
          suite: {
            newPrice: 60.81,
            priceBefore: 61.91,
            priceNow: 62.48,
          },
        },
      },
      {
        date: '2025-02-28',
        festivos: [],
        pc_ocupacion: 13.0,
        room: {
          double: {
            newPrice: 54.32,
            priceBefore: 52.18,
            priceNow: 49.71,
          },
          single: {
            newPrice: 18.01,
            priceBefore: 18.57,
            priceNow: 18.85,
          },
          suite: {
            newPrice: 53.32,
            priceBefore: 53.67,
            priceNow: 51.11,
          },
        },
      },
      {
        date: '2025-03-01',
        festivos: [],
        pc_ocupacion: 11.0,
        room: {
          double: {
            newPrice: 49.41,
            priceBefore: 47.72,
            priceNow: 46.67,
          },
          single: {
            newPrice: 29.48,
            priceBefore: 30.52,
            priceNow: 31.19,
          },
          suite: {
            newPrice: 68.08,
            priceBefore: 62.13,
            priceNow: 68.25,
          },
        },
      },
      {
        date: '2025-03-02',
        festivos: [],
        pc_ocupacion: 14.0,
        room: {
          double: {
            newPrice: 47.45,
            priceBefore: 49.09,
            priceNow: 53.25,
          },
          single: {
            newPrice: 25.65,
            priceBefore: 25.8,
            priceNow: 28.21,
          },
          suite: {
            newPrice: 82.06,
            priceBefore: 81.29,
            priceNow: 81.96,
          },
        },
      },
      {
        date: '2025-03-03',
        festivos: [],
        pc_ocupacion: 19.0,
        room: {
          double: {
            newPrice: 45.17,
            priceBefore: 46.58,
            priceNow: 47.02,
          },
          single: {
            newPrice: 41.85,
            priceBefore: 42.2,
            priceNow: 44.02,
          },
          suite: {
            newPrice: 93.65,
            priceBefore: 94.53,
            priceNow: 91.52,
          },
        },
      },
      {
        date: '2025-03-04',
        festivos: ['Festividad local de El Bonillo'],
        pc_ocupacion: 14.0,
        room: {
          double: {
            newPrice: 54.01,
            priceBefore: 50.18,
            priceNow: 51.49,
          },
          single: {
            newPrice: 27.88,
            priceBefore: 28.03,
            priceNow: 30.52,
          },
          suite: {
            newPrice: 49.46,
            priceBefore: 47.3,
            priceNow: 46.11,
          },
        },
      },
      {
        date: '2025-03-05',
        festivos: [],
        pc_ocupacion: 13.0,
        room: {
          double: {
            newPrice: 42.96,
            priceBefore: 41.6,
            priceNow: 45.4,
          },
          single: {
            newPrice: 27.18,
            priceBefore: 25.43,
            priceNow: 25.97,
          },
          suite: {
            newPrice: 69.28,
            priceBefore: 66.0,
            priceNow: 69.03,
          },
        },
      },
      {
        date: '2025-03-06',
        festivos: [],
        pc_ocupacion: 13.0,
        room: {
          double: {
            newPrice: 44.62,
            priceBefore: 41.02,
            priceNow: 41.26,
          },
          single: {
            newPrice: 19.36,
            priceBefore: 17.97,
            priceNow: 18.63,
          },
          suite: {
            newPrice: 60.77,
            priceBefore: 63.25,
            priceNow: 63.45,
          },
        },
      },
      {
        date: '2025-03-07',
        festivos: ['Festividad local de Villaseca de Uceda'],
        pc_ocupacion: 16.0,
        room: {
          double: {
            newPrice: 44.49,
            priceBefore: 43.93,
            priceNow: 42.14,
          },
          single: {
            newPrice: 34.48,
            priceBefore: 35.5,
            priceNow: 37.81,
          },
          suite: {
            newPrice: 57.22,
            priceBefore: 59.64,
            priceNow: 57.77,
          },
        },
      },
      {
        date: '2025-03-08',
        festivos: ['Festividad local de Navas de Jorquera'],
        pc_ocupacion: 13.0,
        room: {
          double: {
            newPrice: 47.54,
            priceBefore: 44.7,
            priceNow: 42.95,
          },
          single: {
            newPrice: 25.5,
            priceBefore: 25.96,
            priceNow: 28.13,
          },
          suite: {
            newPrice: 70.55,
            priceBefore: 66.15,
            priceNow: 70.77,
          },
        },
      },
      {
        date: '2025-03-09',
        festivos: [],
        pc_ocupacion: 13.0,
        room: {
          double: {
            newPrice: 54.96,
            priceBefore: 55.39,
            priceNow: 54.32,
          },
          single: {
            newPrice: 34.64,
            priceBefore: 35.09,
            priceNow: 36.13,
          },
          suite: {
            newPrice: 46.32,
            priceBefore: 43.42,
            priceNow: 46.28,
          },
        },
      },
      {
        date: '2025-03-10',
        festivos: [],
        pc_ocupacion: 5.0,
        room: {
          double: {
            newPrice: 26.49,
            priceBefore: 25.27,
            priceNow: 26.79,
          },
          single: {
            newPrice: 29.94,
            priceBefore: 28.87,
            priceNow: 29.09,
          },
          suite: {
            newPrice: 41.4,
            priceBefore: 38.87,
            priceNow: 40.02,
          },
        },
      },
      {
        date: '2025-03-11',
        festivos: ['Festividad local de Illescas'],
        pc_ocupacion: 15.0,
        room: {
          double: {
            newPrice: 44.64,
            priceBefore: 45.74,
            priceNow: 43.91,
          },
          single: {
            newPrice: 23.76,
            priceBefore: 24.75,
            priceNow: 26.73,
          },
          suite: {
            newPrice: 61.08,
            priceBefore: 62.32,
            priceNow: 68.25,
          },
        },
      },
      {
        date: '2025-03-12',
        festivos: [],
        pc_ocupacion: 13.0,
        room: {
          double: {
            newPrice: 50.03,
            priceBefore: 49.66,
            priceNow: 51.42,
          },
          single: {
            newPrice: 25.81,
            priceBefore: 25.27,
            priceNow: 26.54,
          },
          suite: {
            newPrice: 62.31,
            priceBefore: 57.27,
            priceNow: 61.93,
          },
        },
      },
      {
        date: '2025-03-13',
        festivos: [],
        pc_ocupacion: 11.0,
        room: {
          double: {
            newPrice: 34.4,
            priceBefore: 35.75,
            priceNow: 34.28,
          },
          single: {
            newPrice: 32.77,
            priceBefore: 30.85,
            priceNow: 29.54,
          },
          suite: {
            newPrice: 53.3,
            priceBefore: 55.38,
            priceNow: 57.1,
          },
        },
      },
      {
        date: '2025-03-14',
        festivos: [],
        pc_ocupacion: 15.0,
        room: {
          double: {
            newPrice: 54.58,
            priceBefore: 55.48,
            priceNow: 57.64,
          },
          single: {
            newPrice: 29.64,
            priceBefore: 31.09,
            priceNow: 32.87,
          },
          suite: {
            newPrice: 54.82,
            priceBefore: 56.58,
            priceNow: 56.07,
          },
        },
      },
      {
        date: '2025-03-15',
        festivos: [],
        pc_ocupacion: 14.0,
        room: {
          double: {
            newPrice: 30.23,
            priceBefore: 31.13,
            priceNow: 32.75,
          },
          single: {
            newPrice: 34.67,
            priceBefore: 33.55,
            priceNow: 33.94,
          },
          suite: {
            newPrice: 83.27,
            priceBefore: 87.1,
            priceNow: 86.09,
          },
        },
      },
      {
        date: '2025-03-16',
        festivos: [],
        pc_ocupacion: 10.0,
        room: {
          double: {
            newPrice: 47.7,
            priceBefore: 46.62,
            priceNow: 47.0,
          },
          single: {
            newPrice: 39.71,
            priceBefore: 36.71,
            priceNow: 39.27,
          },
          suite: {
            newPrice: 35.45,
            priceBefore: 32.39,
            priceNow: 34.64,
          },
        },
      },
      {
        date: '2025-03-17',
        festivos: [],
        pc_ocupacion: 13.0,
        room: {
          double: {
            newPrice: 41.6,
            priceBefore: 39.79,
            priceNow: 40.5,
          },
          single: {
            newPrice: 21.91,
            priceBefore: 21.51,
            priceNow: 20.53,
          },
          suite: {
            newPrice: 81.97,
            priceBefore: 85.98,
            priceNow: 88.23,
          },
        },
      },
      {
        date: '2025-03-18',
        festivos: [
          'Festividad local de Casas de Lazaro',
          'Festividad local de Ontur',
          'Festividad local de Abenójar',
          'Festividad local de Zorita de los Canes',
        ],
        pc_ocupacion: 21.0,
        room: {
          double: {
            newPrice: 57.03,
            priceBefore: 56.31,
            priceNow: 60.04,
          },
          single: {
            newPrice: 57.35,
            priceBefore: 57.58,
            priceNow: 57.42,
          },
          suite: {
            newPrice: 88.16,
            priceBefore: 87.85,
            priceNow: 88.99,
          },
        },
      },
      {
        date: '2025-03-19',
        festivos: [
          'Festividad local de Molinicos',
          'Festividad local de Ontur',
          'Festividad local de Las Mesas',
          'Festividad local de Puebla de Valles',
          'Festividad local de Zorita de los Canes',
          'Festividad local de Gerindote',
          'Festividad local de Sartajada',
          'Festividad local de Villacañas',
        ],
        pc_ocupacion: 16.0,
        room: {
          double: {
            newPrice: 52.56,
            priceBefore: 49.38,
            priceNow: 48.64,
          },
          single: {
            newPrice: 25.54,
            priceBefore: 23.9,
            priceNow: 23.44,
          },
          suite: {
            newPrice: 55.93,
            priceBefore: 53.45,
            priceNow: 52.69,
          },
        },
      },
      {
        date: '2025-03-20',
        festivos: ['Festividad local de Monreal del Llano'],
        pc_ocupacion: 14.0,
        room: {
          double: {
            newPrice: 16.38,
            priceBefore: 15.99,
            priceNow: 16.45,
          },
          single: {
            newPrice: 37.72,
            priceBefore: 37.09,
            priceNow: 39.71,
          },
          suite: {
            newPrice: 64.52,
            priceBefore: 67.86,
            priceNow: 73.48,
          },
        },
      },
      {
        date: '2025-03-21',
        festivos: [],
        pc_ocupacion: 18.0,
        room: {
          double: {
            newPrice: 38.74,
            priceBefore: 36.32,
            priceNow: 39.85,
          },
          single: {
            newPrice: 44.22,
            priceBefore: 43.17,
            priceNow: 43.82,
          },
          suite: {
            newPrice: 71.52,
            priceBefore: 68.77,
            priceNow: 72.22,
          },
        },
      },
      {
        date: '2025-03-22',
        festivos: ['Festividad local de Atienza'],
        pc_ocupacion: 25.0,
        room: {
          double: {
            newPrice: 59.42,
            priceBefore: 57.56,
            priceNow: 60.19,
          },
          single: {
            newPrice: 56.41,
            priceBefore: 54.86,
            priceNow: 54.3,
          },
          suite: {
            newPrice: 84.7,
            priceBefore: 87.55,
            priceNow: 87.97,
          },
        },
      },
      {
        date: '2025-03-23',
        festivos: [],
        pc_ocupacion: 19.0,
        room: {
          double: {
            newPrice: 38.35,
            priceBefore: 40.04,
            priceNow: 43.74,
          },
          single: {
            newPrice: 50.45,
            priceBefore: 48.35,
            priceNow: 50.3,
          },
          suite: {
            newPrice: 76.6,
            priceBefore: 75.99,
            priceNow: 79.21,
          },
        },
      },
      {
        date: '2025-03-24',
        festivos: [],
        pc_ocupacion: 13.0,
        room: {
          double: {
            newPrice: 47.88,
            priceBefore: 45.12,
            priceNow: 44.89,
          },
          single: {
            newPrice: 26.56,
            priceBefore: 25.31,
            priceNow: 24.47,
          },
          suite: {
            newPrice: 74.84,
            priceBefore: 75.41,
            priceNow: 80.0,
          },
        },
      },
      {
        date: '2025-03-25',
        festivos: [],
        pc_ocupacion: 19.0,
        room: {
          double: {
            newPrice: 38.76,
            priceBefore: 37.92,
            priceNow: 37.83,
          },
          single: {
            newPrice: 59.35,
            priceBefore: 56.63,
            priceNow: 60.2,
          },
          suite: {
            newPrice: 88.3,
            priceBefore: 85.9,
            priceNow: 89.17,
          },
        },
      },
      {
        date: '2025-03-26',
        festivos: [],
        pc_ocupacion: 25.0,
        room: {
          double: {
            newPrice: 61.63,
            priceBefore: 64.18,
            priceNow: 67.68,
          },
          single: {
            newPrice: 33.54,
            priceBefore: 31.79,
            priceNow: 30.3,
          },
          suite: {
            newPrice: 95.68,
            priceBefore: 91.13,
            priceNow: 86.94,
          },
        },
      },
      {
        date: '2025-03-27',
        festivos: [],
        pc_ocupacion: 27.0,
        room: {
          double: {
            newPrice: 69.69,
            priceBefore: 63.6,
            priceNow: 67.92,
          },
          single: {
            newPrice: 37.65,
            priceBefore: 36.78,
            priceNow: 39.13,
          },
          suite: {
            newPrice: 101.39,
            priceBefore: 106.07,
            priceNow: 111.43,
          },
        },
      },
      {
        date: '2025-03-28',
        festivos: [],
        pc_ocupacion: 13.0,
        room: {
          double: {
            newPrice: 35.3,
            priceBefore: 35.83,
            priceNow: 35.54,
          },
          single: {
            newPrice: 42.99,
            priceBefore: 43.02,
            priceNow: 42.56,
          },
          suite: {
            newPrice: 74.71,
            priceBefore: 68.07,
            priceNow: 66.55,
          },
        },
      },
      {
        date: '2025-03-29',
        festivos: [],
        pc_ocupacion: 17.0,
        room: {
          double: {
            newPrice: 47.32,
            priceBefore: 44.66,
            priceNow: 48.54,
          },
          single: {
            newPrice: 29.82,
            priceBefore: 29.44,
            priceNow: 30.22,
          },
          suite: {
            newPrice: 98.67,
            priceBefore: 92.58,
            priceNow: 99.42,
          },
        },
      },
      {
        date: '2025-03-30',
        festivos: [],
        pc_ocupacion: 19.0,
        room: {
          double: {
            newPrice: 52.25,
            priceBefore: 49.87,
            priceNow: 51.01,
          },
          single: {
            newPrice: 34.73,
            priceBefore: 34.94,
            priceNow: 33.81,
          },
          suite: {
            newPrice: 92.04,
            priceBefore: 91.75,
            priceNow: 90.45,
          },
        },
      },
      {
        date: '2025-03-31',
        festivos: [],
        pc_ocupacion: 19.0,
        room: {
          double: {
            newPrice: 56.59,
            priceBefore: 53.38,
            priceNow: 50.77,
          },
          single: {
            newPrice: 31.79,
            priceBefore: 30.57,
            priceNow: 30.25,
          },
          suite: {
            newPrice: 70.53,
            priceBefore: 73.68,
            priceNow: 75.26,
          },
        },
      },
      {
        date: '2025-04-01',
        festivos: [
          'Festividad local de Bonete',
          'Festividad local de Almadenejos',
          'Festividad local de Campo de Criptana',
          'Festividad local de Carrión de Calatrava',
          'Festividad local de Carrizosa',
          'Festividad local de Chillón',
          'Festividad local de Socuéllamos',
          'Festividad local de Villarrubia de los Ojos',
          'Festividad local de Los Hinojosos',
          'Festividad local de Mira',
          'Festividad local de Villora',
          'Festividad local de Argés',
          'Festividad local de Cobisa',
          'Festividad local de Las Ventas con Peña Aguilera',
          'Festividad local de Navamorcuende',
          'Festividad local de Puente del Arzobispo',
          'Festividad local de Tembleque',
          'Festividad local de Toledo',
        ],
        pc_ocupacion: 17.0,
        room: {
          double: {
            newPrice: 62.33,
            priceBefore: 57.3,
            priceNow: 54.67,
          },
          single: {
            newPrice: 40.56,
            priceBefore: 40.88,
            priceNow: 43.43,
          },
          suite: {
            newPrice: 65.93,
            priceBefore: 69.25,
            priceNow: 70.7,
          },
        },
      },
      {
        date: '2025-04-02',
        festivos: ['Festividad local de Ajofrín'],
        pc_ocupacion: 18.0,
        room: {
          double: {
            newPrice: 39.86,
            priceBefore: 39.74,
            priceNow: 42.22,
          },
          single: {
            newPrice: 34.33,
            priceBefore: 33.82,
            priceNow: 36.42,
          },
          suite: {
            newPrice: 88.88,
            priceBefore: 91.14,
            priceNow: 99.2,
          },
        },
      },
      {
        date: '2025-04-03',
        festivos: ['Festividad local de La Nava de Ricomalillo'],
        pc_ocupacion: 11.0,
        room: {
          double: {
            newPrice: 14.79,
            priceBefore: 14.52,
            priceNow: 14.81,
          },
          single: {
            newPrice: 37.44,
            priceBefore: 38.89,
            priceNow: 37.7,
          },
          suite: {
            newPrice: 72.31,
            priceBefore: 65.98,
            priceNow: 70.7,
          },
        },
      },
      {
        date: '2025-04-04',
        festivos: ['Festividad local de La Nava de Ricomalillo'],
        pc_ocupacion: 15.0,
        room: {
          double: {
            newPrice: 45.31,
            priceBefore: 47.37,
            priceNow: 45.66,
          },
          single: {
            newPrice: 39.19,
            priceBefore: 36.14,
            priceNow: 38.9,
          },
          suite: {
            newPrice: 62.86,
            priceBefore: 63.36,
            priceNow: 60.23,
          },
        },
      },
      {
        date: '2025-04-05',
        festivos: [],
        pc_ocupacion: 15.0,
        room: {
          double: {
            newPrice: 47.44,
            priceBefore: 45.99,
            priceNow: 46.22,
          },
          single: {
            newPrice: 41.09,
            priceBefore: 39.2,
            priceNow: 42.09,
          },
          suite: {
            newPrice: 67.62,
            priceBefore: 66.77,
            priceNow: 65.71,
          },
        },
      },
      {
        date: '2025-04-06',
        festivos: [],
        pc_ocupacion: 19.0,
        room: {
          double: {
            newPrice: 55.72,
            priceBefore: 58.49,
            priceNow: 60.93,
          },
          single: {
            newPrice: 37.29,
            priceBefore: 38.97,
            priceNow: 37.43,
          },
          suite: {
            newPrice: 72.09,
            priceBefore: 74.06,
            priceNow: 73.74,
          },
        },
      },
      {
        date: '2025-04-07',
        festivos: [],
        pc_ocupacion: 15.0,
        room: {
          double: {
            newPrice: 65.62,
            priceBefore: 60.48,
            priceNow: 65.58,
          },
          single: {
            newPrice: 25.99,
            priceBefore: 25.86,
            priceNow: 26.83,
          },
          suite: {
            newPrice: 73.26,
            priceBefore: 74.43,
            priceNow: 78.09,
          },
        },
      },
      {
        date: '2025-04-08',
        festivos: [
          'Festividad local de Caudete',
          'Festividad local de El Hito',
          'Festividad local de Carpio de Tajo',
        ],
        pc_ocupacion: 16.0,
        room: {
          double: {
            newPrice: 30.98,
            priceBefore: 31.55,
            priceNow: 34.62,
          },
          single: {
            newPrice: 41.74,
            priceBefore: 42.43,
            priceNow: 42.86,
          },
          suite: {
            newPrice: 77.05,
            priceBefore: 77.33,
            priceNow: 84.32,
          },
        },
      },
      {
        date: '2025-04-09',
        festivos: [],
        pc_ocupacion: 18.0,
        room: {
          double: {
            newPrice: 61.01,
            priceBefore: 55.57,
            priceNow: 54.89,
          },
          single: {
            newPrice: 32.79,
            priceBefore: 30.78,
            priceNow: 33.23,
          },
          suite: {
            newPrice: 82.22,
            priceBefore: 80.2,
            priceNow: 88.04,
          },
        },
      },
      {
        date: '2025-04-10',
        festivos: [
          'Festividad local de San Lorenzo de Calatrava',
          'Festividad local de Hinojosa de San Vicente',
        ],
        pc_ocupacion: 14.0,
        room: {
          double: {
            newPrice: 34.7,
            priceBefore: 33.84,
            priceNow: 33.07,
          },
          single: {
            newPrice: 36.88,
            priceBefore: 34.45,
            priceNow: 34.31,
          },
          suite: {
            newPrice: 73.08,
            priceBefore: 67.67,
            priceNow: 68.61,
          },
        },
      },
      {
        date: '2025-04-11',
        festivos: [],
        pc_ocupacion: 15.0,
        room: {
          double: {
            newPrice: 37.12,
            priceBefore: 37.24,
            priceNow: 40.05,
          },
          single: {
            newPrice: 33.29,
            priceBefore: 34.24,
            priceNow: 35.06,
          },
          suite: {
            newPrice: 73.44,
            priceBefore: 72.87,
            priceNow: 73.33,
          },
        },
      },
      {
        date: '2025-04-12',
        festivos: [],
        pc_ocupacion: 16.0,
        room: {
          double: {
            newPrice: 61.0,
            priceBefore: 55.52,
            priceNow: 58.75,
          },
          single: {
            newPrice: 25.0,
            priceBefore: 24.56,
            priceNow: 26.1,
          },
          suite: {
            newPrice: 75.16,
            priceBefore: 70.73,
            priceNow: 74.8,
          },
        },
      },
      {
        date: '2025-04-13',
        festivos: [],
        pc_ocupacion: 25.0,
        room: {
          double: {
            newPrice: 70.0,
            priceBefore: 68.18,
            priceNow: 67.92,
          },
          single: {
            newPrice: 38.56,
            priceBefore: 40.42,
            priceNow: 42.34,
          },
          suite: {
            newPrice: 77.23,
            priceBefore: 75.25,
            priceNow: 79.73,
          },
        },
      },
      {
        date: '2025-04-14',
        festivos: [],
        pc_ocupacion: 21.0,
        room: {
          double: {
            newPrice: 35.96,
            priceBefore: 35.37,
            priceNow: 36.66,
          },
          single: {
            newPrice: 59.08,
            priceBefore: 55.71,
            priceNow: 57.04,
          },
          suite: {
            newPrice: 75.19,
            priceBefore: 78.11,
            priceNow: 81.02,
          },
        },
      },
      {
        date: '2025-04-15',
        festivos: [],
        pc_ocupacion: 19.0,
        room: {
          double: {
            newPrice: 44.22,
            priceBefore: 45.96,
            priceNow: 47.78,
          },
          single: {
            newPrice: 46.93,
            priceBefore: 42.8,
            priceNow: 43.68,
          },
          suite: {
            newPrice: 74.02,
            priceBefore: 71.69,
            priceNow: 73.99,
          },
        },
      },
      {
        date: '2025-04-16',
        festivos: [],
        pc_ocupacion: 15.0,
        room: {
          double: {
            newPrice: 38.1,
            priceBefore: 37.9,
            priceNow: 40.29,
          },
          single: {
            newPrice: 33.02,
            priceBefore: 34.17,
            priceNow: 33.21,
          },
          suite: {
            newPrice: 78.48,
            priceBefore: 73.92,
            priceNow: 76.42,
          },
        },
      },
      {
        date: '2025-04-17',
        festivos: ['Jueves Santo', 'Festividad local de Nohales'],
        pc_ocupacion: 17.0,
        room: {
          double: {
            newPrice: 54.38,
            priceBefore: 52.0,
            priceNow: 51.57,
          },
          single: {
            newPrice: 27.9,
            priceBefore: 27.03,
            priceNow: 28.76,
          },
          suite: {
            newPrice: 89.31,
            priceBefore: 87.09,
            priceNow: 84.62,
          },
        },
      },
      {
        date: '2025-04-18',
        festivos: ['Viernes Santo'],
        pc_ocupacion: 15.0,
        room: {
          double: {
            newPrice: 41.64,
            priceBefore: 39.48,
            priceNow: 41.03,
          },
          single: {
            newPrice: 36.51,
            priceBefore: 35.18,
            priceNow: 36.9,
          },
          suite: {
            newPrice: 83.91,
            priceBefore: 77.89,
            priceNow: 80.11,
          },
        },
      },
      {
        date: '2025-04-19',
        festivos: [],
        pc_ocupacion: 20.0,
        room: {
          double: {
            newPrice: 59.61,
            priceBefore: 58.3,
            priceNow: 60.89,
          },
          single: {
            newPrice: 45.62,
            priceBefore: 44.47,
            priceNow: 44.19,
          },
          suite: {
            newPrice: 71.42,
            priceBefore: 68.86,
            priceNow: 73.12,
          },
        },
      },
      {
        date: '2025-04-20',
        festivos: [],
        pc_ocupacion: 11.0,
        room: {
          double: {
            newPrice: 38.72,
            priceBefore: 37.22,
            priceNow: 38.92,
          },
          single: {
            newPrice: 34.33,
            priceBefore: 33.91,
            priceNow: 34.17,
          },
          suite: {
            newPrice: 53.35,
            priceBefore: 52.21,
            priceNow: 57.16,
          },
        },
      },
      {
        date: '2025-04-21',
        festivos: ['Festividad local de Castilnuevo'],
        pc_ocupacion: 18.0,
        room: {
          double: {
            newPrice: 60.26,
            priceBefore: 56.04,
            priceNow: 53.24,
          },
          single: {
            newPrice: 34.1,
            priceBefore: 35.64,
            priceNow: 38.97,
          },
          suite: {
            newPrice: 65.65,
            priceBefore: 68.65,
            priceNow: 74.26,
          },
        },
      },
      {
        date: '2025-04-22',
        festivos: ['Festividad local de Escariche'],
        pc_ocupacion: 21.0,
        room: {
          double: {
            newPrice: 54.87,
            priceBefore: 51.68,
            priceNow: 54.78,
          },
          single: {
            newPrice: 38.09,
            priceBefore: 35.81,
            priceNow: 37.03,
          },
          suite: {
            newPrice: 84.32,
            priceBefore: 86.9,
            priceNow: 89.25,
          },
        },
      },
      {
        date: '2025-04-23',
        festivos: [
          'Festividad local de Golosalvo',
          'Festividad local de Madrigueras',
          'Festividad local de Graja de Iniesta',
          'Festividad local de Pozoseco',
          'Festividad local de Rubielos Altos',
          'Festividad local de Rubielos Bajos',
          'Festividad local de Miralrío',
          'Festividad local de Erustes',
          'Festividad local de Villanueva de Alcardete',
        ],
        pc_ocupacion: 18.0,
        room: {
          double: {
            newPrice: 53.41,
            priceBefore: 52.32,
            priceNow: 54.57,
          },
          single: {
            newPrice: 50.31,
            priceBefore: 48.45,
            priceNow: 48.01,
          },
          suite: {
            newPrice: 77.82,
            priceBefore: 70.94,
            priceNow: 76.56,
          },
        },
      },
      {
        date: '2025-04-24',
        festivos: ['Festividad local de Segurilla'],
        pc_ocupacion: 19.0,
        room: {
          double: {
            newPrice: 45.75,
            priceBefore: 45.58,
            priceNow: 47.12,
          },
          single: {
            newPrice: 56.29,
            priceBefore: 52.12,
            priceNow: 53.82,
          },
          suite: {
            newPrice: 67.46,
            priceBefore: 67.24,
            priceNow: 69.43,
          },
        },
      },
      {
        date: '2025-04-25',
        festivos: [
          'Festividad local de Bienservida',
          'Festividad local de El Salobral',
          'Festividad local de Fuenteálamo',
          'Festividad local de Montalvos',
          'Festividad local de Reolid',
          'Festividad local de Salobre',
          'Festividad local de Viveros',
          'Festividad local de Albaladejo',
          'Festividad local de Arroba de los Montes',
          'Festividad local de Castellar de Santiago',
          'Festividad local de Ruidera',
          'Festividad local de San Carlos del Valle',
          'Festividad local de Santa Cruz de Cáñamos',
          'Festividad local de Villamanrique',
          'Festividad local de Villanueva de la Fuente',
          'Festividad local de Algarra',
          'Festividad local de Carrascosa del Campo',
          'Festividad local de Castillo-Albarañez',
          'Festividad local de Osa de la Vega',
          'Festividad local de Quintanar del Rey',
          'Festividad local de Villalpardo',
          'Festividad local de Villaverde y Pasaconsol',
          'Festividad local de Yemeda',
          'Festividad local de Chiloeches',
          'Festividad local de Tordesilos',
          'Festividad local de Villares de Jadraque',
          'Festividad local de Méntrida',
        ],
        pc_ocupacion: 24.0,
        room: {
          double: {
            newPrice: 54.49,
            priceBefore: 54.3,
            priceNow: 56.51,
          },
          single: {
            newPrice: 42.81,
            priceBefore: 44.31,
            priceNow: 45.19,
          },
          suite: {
            newPrice: 114.52,
            priceBefore: 108.99,
            priceNow: 113.04,
          },
        },
      },
      {
        date: '2025-04-26',
        festivos: [
          'Festividad local de Montealegre del Castillo',
          'Festividad local de Povedilla',
          'Festividad local de Aldea del Rey',
          'Festividad local de Maqueda',
        ],
        pc_ocupacion: 17.0,
        room: {
          double: {
            newPrice: 50.66,
            priceBefore: 46.34,
            priceNow: 47.15,
          },
          single: {
            newPrice: 33.26,
            priceBefore: 31.2,
            priceNow: 33.38,
          },
          suite: {
            newPrice: 67.53,
            priceBefore: 69.43,
            priceNow: 70.04,
          },
        },
      },
      {
        date: '2025-04-27',
        festivos: ['Festividad local de Adobes', 'Festividad local de Azután'],
        pc_ocupacion: 17.0,
        room: {
          double: {
            newPrice: 54.23,
            priceBefore: 51.72,
            priceNow: 51.97,
          },
          single: {
            newPrice: 33.74,
            priceBefore: 35.21,
            priceNow: 35.61,
          },
          suite: {
            newPrice: 83.45,
            priceBefore: 80.12,
            priceNow: 82.27,
          },
        },
      },
      {
        date: '2025-04-28',
        festivos: [],
        pc_ocupacion: 20.0,
        room: {
          double: {
            newPrice: 60.71,
            priceBefore: 59.18,
            priceNow: 63.88,
          },
          single: {
            newPrice: 46.78,
            priceBefore: 44.1,
            priceNow: 44.29,
          },
          suite: {
            newPrice: 66.79,
            priceBefore: 69.62,
            priceNow: 68.72,
          },
        },
      },
      {
        date: '2025-04-29',
        festivos: [
          'Festividad local de Casas Ibáñez',
          'Festividad local de Hoya Gonzalo',
          'Festividad local de Ossa de Montiel',
          'Festividad local de Bolaños de Calatrava',
          'Festividad local de Tomelloso',
          'Festividad local de Casas de Santa Cruz',
          'Festividad local de Graja de Campalbo',
          'Festividad local de Pozoamargo',
          'Festividad local de Tórtola de Henares',
          'Festividad local de Maqueda',
          'Festividad local de Mora',
          'Festividad local de Pelahustán',
          'Festividad local de Villamuelas',
        ],
        pc_ocupacion: 20.0,
        room: {
          double: {
            newPrice: 64.59,
            priceBefore: 62.34,
            priceNow: 60.65,
          },
          single: {
            newPrice: 52.38,
            priceBefore: 48.92,
            priceNow: 47.39,
          },
          suite: {
            newPrice: 80.75,
            priceBefore: 78.02,
            priceNow: 74.65,
          },
        },
      },
      {
        date: '2025-04-30',
        festivos: [
          'Festividad local de Fontanarejo',
          'Festividad local de Palomares del Campo',
          'Festividad local de Henche',
        ],
        pc_ocupacion: 20.0,
        room: {
          double: {
            newPrice: 57.96,
            priceBefore: 53.64,
            priceNow: 58.02,
          },
          single: {
            newPrice: 63.04,
            priceBefore: 58.61,
            priceNow: 57.86,
          },
          suite: {
            newPrice: 77.18,
            priceBefore: 74.35,
            priceNow: 80.44,
          },
        },
      },
      {
        date: '2025-05-01',
        festivos: ['Fiesta del Trabajo'],
        pc_ocupacion: 17.0,
        room: {
          double: {
            newPrice: 62.63,
            priceBefore: 58.47,
            priceNow: 63.71,
          },
          single: {
            newPrice: 45.16,
            priceBefore: 47.25,
            priceNow: 50.32,
          },
          suite: {
            newPrice: 69.86,
            priceBefore: 66.24,
            priceNow: 71.91,
          },
        },
      },
      {
        date: '2025-05-02',
        festivos: [
          'Festividad local de La Gineta',
          'Festividad local de Lezuza',
          'Festividad local de Tinajeros',
          'Festividad local de Villamayor de Calatrava',
          'Festividad local de Alcázar del Rey',
          'Festividad local de Escopete',
          'Festividad local de Aldea en Cabo',
          'Festividad local de Belvís de la Jara',
          'Festividad local de La Iglesuela del Tietar',
          'Festividad local de Lominchar',
          'Festividad local de Piedraescrita',
        ],
        pc_ocupacion: 16.0,
        room: {
          double: {
            newPrice: 32.07,
            priceBefore: 29.23,
            priceNow: 30.86,
          },
          single: {
            newPrice: 30.53,
            priceBefore: 29.63,
            priceNow: 29.47,
          },
          suite: {
            newPrice: 75.73,
            priceBefore: 76.49,
            priceNow: 73.14,
          },
        },
      },
      {
        date: '2025-05-03',
        festivos: [
          'Festividad local de Alpera',
          'Festividad local de Balazote',
          'Festividad local de Balsa de Vés',
          'Festividad local de Cotillas',
          'Festividad local de Hellín',
          'Festividad local de La Gineta',
          'Festividad local de Lezuza',
          'Festividad local de Pozo Lorente',
          'Festividad local de Riópar',
          'Festividad local de Socovos',
          'Festividad local de Tinajeros',
          'Festividad local de Yeste',
          'Festividad local de Arroba de los Montes',
          'Festividad local de Carrizosa',
          'Festividad local de Granátula de Calatrava',
          'Festividad local de Picón',
          'Festividad local de Piedrabuena',
          'Festividad local de Porzuna',
          'Festividad local de Saceruela',
          'Festividad local de Villanueva de los Infantes',
          'Festividad local de Graja de Iniesta',
          'Festividad local de Huerta de la Obispalía',
          'Festividad local de La Cierva',
          'Festividad local de Las Majadas',
          'Festividad local de San Lorenzo de la Parrilla',
          'Festividad local de Valdetortola',
          'Festividad local de Vellisca',
          'Festividad local de Villalgordo del Marquesado',
          'Festividad local de Villar de Olalla',
          'Festividad local de Villares del Saz',
          'Festividad local de Almoguera',
          'Festividad local de Cabanillas del Campo',
          'Festividad local de Fuencemillan',
          'Festividad local de Fuentelviejo',
          'Festividad local de La Yunta',
          'Festividad local de Marchamalo',
          'Festividad local de Milmarcos',
          'Festividad local de Mohernando',
          'Festividad local de Paredes de Sigüenza',
          'Festividad local de Almorox',
          'Festividad local de Buenaventura',
          'Festividad local de Chozas de Canales',
          'Festividad local de Ciruelos',
          'Festividad local de La Iglesuela del Tietar',
          'Festividad local de Lillo',
          'Festividad local de Lominchar',
          'Festividad local de Navalmoralejo',
          'Festividad local de Noblejas',
          'Festividad local de Noez',
          'Festividad local de Piedraescrita',
          'Festividad local de San Martín de Pusa',
          'Festividad local de Santa Cruz del Retamar',
          'Festividad local de Torre de Esteban Hambrán',
          'Festividad local de Valmojado',
        ],
        pc_ocupacion: 20.0,
        room: {
          double: {
            newPrice: 52.64,
            priceBefore: 49.67,
            priceNow: 49.79,
          },
          single: {
            newPrice: 49.1,
            priceBefore: 47.27,
            priceNow: 49.42,
          },
          suite: {
            newPrice: 91.91,
            priceBefore: 85.84,
            priceNow: 92.12,
          },
        },
      },
      {
        date: '2025-05-04',
        festivos: ['Festividad local de Prados Redondos'],
        pc_ocupacion: 13.0,
        room: {
          double: {
            newPrice: 46.02,
            priceBefore: 46.9,
            priceNow: 46.51,
          },
          single: {
            newPrice: 32.27,
            priceBefore: 32.48,
            priceNow: 33.36,
          },
          suite: {
            newPrice: 42.92,
            priceBefore: 42.81,
            priceNow: 43.62,
          },
        },
      },
      {
        date: '2025-05-05',
        festivos: [],
        pc_ocupacion: 15.0,
        room: {
          double: {
            newPrice: 36.48,
            priceBefore: 33.24,
            priceNow: 35.9,
          },
          single: {
            newPrice: 45.23,
            priceBefore: 41.62,
            priceNow: 41.24,
          },
          suite: {
            newPrice: 82.34,
            priceBefore: 77.05,
            priceNow: 76.65,
          },
        },
      },
      {
        date: '2025-05-06',
        festivos: [
          'Festividad local de Almansa',
          'Festividad local de Casas de Guijarro',
          'Festividad local de Mazarulleque',
          'Festividad local de Rada de Haro',
          'Festividad local de Solera de Gabaldón',
          'Festividad local de Tinajas',
          'Festividad local de Ucles',
          'Festividad local de Villarejo Periesteban',
          'Festividad local de Lupiana',
          'Festividad local de Caleruela',
          'Festividad local de Ciruelos',
          'Festividad local de Mazarambroz',
          'Festividad local de Santa Olalla',
          'Festividad local de Sonseca',
        ],
        pc_ocupacion: 17.0,
        room: {
          double: {
            newPrice: 44.46,
            priceBefore: 43.69,
            priceNow: 42.36,
          },
          single: {
            newPrice: 50.15,
            priceBefore: 52.66,
            priceNow: 54.55,
          },
          suite: {
            newPrice: 76.97,
            priceBefore: 72.3,
            priceNow: 69.39,
          },
        },
      },
      {
        date: '2025-05-07',
        festivos: [
          'Festividad local de Villel de Mesa',
          'Festividad local de Ajofrín',
          'Festividad local de Caleruela',
        ],
        pc_ocupacion: 17.0,
        room: {
          double: {
            newPrice: 58.44,
            priceBefore: 57.91,
            priceNow: 59.7,
          },
          single: {
            newPrice: 45.16,
            priceBefore: 42.48,
            priceNow: 45.64,
          },
          suite: {
            newPrice: 70.58,
            priceBefore: 65.79,
            priceNow: 67.35,
          },
        },
      },
      {
        date: '2025-05-08',
        festivos: [
          'Festividad local de Abengibre',
          'Festividad local de Villa de Vés',
          'Festividad local de Arenales de San Gregorio',
          'Festividad local de Alconchel de la Estrella',
          'Festividad local de Huelves',
          'Festividad local de Olmeda del Rey',
          'Festividad local de Palomera',
          'Festividad local de Villagarcía del Llano',
          'Festividad local de Argecilla',
          'Festividad local de Matarrubia',
          'Festividad local de Taragudo',
        ],
        pc_ocupacion: 24.0,
        room: {
          double: {
            newPrice: 71.4,
            priceBefore: 67.66,
            priceNow: 74.18,
          },
          single: {
            newPrice: 50.79,
            priceBefore: 49.07,
            priceNow: 51.53,
          },
          suite: {
            newPrice: 78.1,
            priceBefore: 79.11,
            priceNow: 76.77,
          },
        },
      },
      {
        date: '2025-05-09',
        festivos: [
          'Festividad local de Almedina',
          'Festividad local de Arenales de San Gregorio',
          'Festividad local de Caracuel de Calatrava',
          'Festividad local de Alconchel de la Estrella',
          'Festividad local de Cubillejo del Sitio',
          'Festividad local de Valdegrudas',
        ],
        pc_ocupacion: 19.0,
        room: {
          double: {
            newPrice: 62.36,
            priceBefore: 57.25,
            priceNow: 56.86,
          },
          single: {
            newPrice: 33.43,
            priceBefore: 30.97,
            priceNow: 33.33,
          },
          suite: {
            newPrice: 89.46,
            priceBefore: 85.15,
            priceNow: 91.0,
          },
        },
      },
      {
        date: '2025-05-10',
        festivos: [
          'Festividad local de Almodóvar del Campo',
          'Festividad local de Montiel',
          'Festividad local de Alcantud',
          'Festividad local de Huete',
          'Festividad local de Alustante',
          'Festividad local de Mirabueno',
          'Festividad local de Riba de Saelices',
        ],
        pc_ocupacion: 17.0,
        room: {
          double: {
            newPrice: 50.94,
            priceBefore: 52.42,
            priceNow: 55.46,
          },
          single: {
            newPrice: 41.17,
            priceBefore: 42.19,
            priceNow: 40.1,
          },
          suite: {
            newPrice: 61.3,
            priceBefore: 61.25,
            priceNow: 60.22,
          },
        },
      },
      {
        date: '2025-05-11',
        festivos: [
          'Festividad local de Piqueras del Castillo',
          'Festividad local de Tordellego',
        ],
        pc_ocupacion: 21.0,
        room: {
          double: {
            newPrice: 34.3,
            priceBefore: 34.71,
            priceNow: 37.5,
          },
          single: {
            newPrice: 58.11,
            priceBefore: 55.06,
            priceNow: 55.69,
          },
          suite: {
            newPrice: 91.41,
            priceBefore: 87.55,
            priceNow: 88.04,
          },
        },
      },
      {
        date: '2025-05-12',
        festivos: [],
        pc_ocupacion: 24.0,
        room: {
          double: {
            newPrice: 78.96,
            priceBefore: 72.58,
            priceNow: 70.91,
          },
          single: {
            newPrice: 53.9,
            priceBefore: 51.46,
            priceNow: 53.56,
          },
          suite: {
            newPrice: 85.9,
            priceBefore: 81.87,
            priceNow: 86.0,
          },
        },
      },
      {
        date: '2025-05-13',
        festivos: [
          'Festividad local de Fuensanta',
          'Festividad local de Masegoso',
          'Festividad local de Peñascosa',
          'Festividad local de Solana del Pino',
          'Festividad local de Torrenueva',
          'Festividad local de Villanueva de la Fuente',
          'Festividad local de Villanueva de San Carlos',
          'Festividad local de Alcantud',
          'Festividad local de Buendía',
          'Festividad local de Carboneras de Guadazaon',
          'Festividad local de San Pedro Palmiches',
          'Festividad local de Fontanar',
          'Festividad local de Labros',
          'Festividad local de Mirabueno',
          'Festividad local de Alcolea de Tajo',
          'Festividad local de Casar de Escalona',
          'Festividad local de Mesegar de Tajo',
        ],
        pc_ocupacion: 23.0,
        room: {
          double: {
            newPrice: 59.21,
            priceBefore: 59.86,
            priceNow: 57.1,
          },
          single: {
            newPrice: 46.15,
            priceBefore: 43.22,
            priceNow: 45.02,
          },
          suite: {
            newPrice: 77.7,
            priceBefore: 79.36,
            priceNow: 86.71,
          },
        },
      },
      {
        date: '2025-05-14',
        festivos: [
          'Festividad local de Pozuelo',
          'Festividad local de Fuentelencina',
          'Festividad local de Salmerón',
          'Festividad local de Valdepeñas de la Sierra',
          'Festividad local de Valdeazores',
        ],
        pc_ocupacion: 20.0,
        room: {
          double: {
            newPrice: 64.75,
            priceBefore: 67.77,
            priceNow: 68.54,
          },
          single: {
            newPrice: 53.82,
            priceBefore: 53.53,
            priceNow: 54.79,
          },
          suite: {
            newPrice: 86.3,
            priceBefore: 82.54,
            priceNow: 86.7,
          },
        },
      },
      {
        date: '2025-05-15',
        festivos: [
          'Festividad local de Aguas Nuevas',
          'Festividad local de Alatoz',
          'Festividad local de Albatana',
          'Festividad local de Alborea',
          'Festividad local de Alcadozo',
          'Festividad local de Alcalá del Júcar',
          'Festividad local de Carcelén',
          'Festividad local de Casas de Juan Núñez',
          'Festividad local de Corralrubio',
          'Festividad local de Fuentealbilla',
          'Festividad local de Golosalvo',
          'Festividad local de La Herrera',
          'Festividad local de La Recueja',
          'Festividad local de Mahora',
          'Festividad local de Montalvos',
          'Festividad local de Montealegre del Castillo',
          'Festividad local de Motilleja',
          'Festividad local de Munera',
          'Festividad local de Navas de Jorquera',
          'Festividad local de Pozo Cañada',
          'Festividad local de Vianos',
          'Festividad local de Villamalea',
          'Festividad local de Villapalacios',
          'Festividad local de Villatoya',
          'Festividad local de Villavaliente',
          'Festividad local de Viveros',
          'Festividad local de Agudo',
          'Festividad local de Albaladejo',
          'Festividad local de Alcázar de San Juan',
          'Festividad local de Alcolea de Calatrava',
          'Festividad local de Alcubillas',
          'Festividad local de Alhambra',
          'Festividad local de Almuradiel',
          'Festividad local de Argamasilla de Alba',
          'Festividad local de Argamasilla de Calatrava',
          'Festividad local de Brazatortas',
          'Festividad local de Cabezarados',
          'Festividad local de Corral de Calatrava',
          'Festividad local de Cózar',
          'Festividad local de Fuencaliente',
          'Festividad local de Horcajo de los Montes',
          'Festividad local de Las Labores',
          'Festividad local de Llanos del Caudillo',
          'Festividad local de Navalpino',
          'Festividad local de Picón',
          'Festividad local de Piedrabuena',
          'Festividad local de Poblete',
          'Festividad local de Porzuna',
          'Festividad local de Puerto Lápice',
          'Festividad local de Retuerta del Bullaque',
          'Festividad local de San Carlos del Valle',
          'Festividad local de Terrinches',
          'Festividad local de Torralba de Calatrava',
          'Festividad local de Torre de Juan Abad',
          'Festividad local de Valenzuela de Calatrava',
          'Festividad local de Villamanrique',
          'Festividad local de Villamayor de Calatrava',
          'Festividad local de Villarrubia de los Ojos',
          'Festividad local de Albaladejo del Cuende',
          'Festividad local de Albalate de las Nogueras',
          'Festividad local de Alcázar del Rey',
          'Festividad local de Almonacid del Marquesado',
          'Festividad local de Altarejos',
          'Festividad local de Arandilla del Arroyo',
          'Festividad local de Arcas',
          'Festividad local de Belmontejo',
          'Festividad local de Buenache de Alarcon',
          'Festividad local de Campillo de Altobuey',
          'Festividad local de Canalejas del Arroyo',
          'Festividad local de Cañada Juncosa',
          'Festividad local de Cañaveras',
          'Festividad local de Cañaveruelas',
          'Festividad local de Carrascosa de Haro',
          'Festividad local de Casas de Benitez',
          'Festividad local de Casas de Fernando Alonso',
          'Festividad local de Casas de los Pinos',
          'Festividad local de Casas de Roldan',
          'Festividad local de Casas de Santa Cruz',
          'Festividad local de Chumillas',
          'Festividad local de El Acebron',
          'Festividad local de El Pedernoso',
          'Festividad local de El Peral',
          'Festividad local de El Picazo',
          'Festividad local de Fuente de Pedro Naharro',
          'Festividad local de Fuentesclaras Chillaron',
          'Festividad local de Fuentesbuenas',
          'Festividad local de Garcinarro',
          'Festividad local de Honrubia',
          'Festividad local de Hontanaya',
          'Festividad local de Horcajada de la Torre',
          'Festividad local de Iniesta',
          'Festividad local de Jabaga',
          'Festividad local de Jabalera',
          'Festividad local de La Alberca de Zancara',
          'Festividad local de La Almarcha',
          'Festividad local de La Frontera',
          'Festividad local de La Parra de las Vegas',
          'Festividad local de La Ventosa',
          'Festividad local de Las Pedroñeras',
          'Festividad local de Ledaña',
          'Festividad local de Los Estesos',
          'Festividad local de Los Hinojosos',
          'Festividad local de Mazarulleque',
          'Festividad local de Minglanilla',
          'Festividad local de Mira',
          'Festividad local de Montalbanejo',
          'Festividad local de Motilla del Palancar',
          'Festividad local de Navalon',
          'Festividad local de Noheda',
          'Festividad local de Olivares del Jucar',
          'Festividad local de Olmeda de la Cuesta',
          'Festividad local de Olmeda del Rey',
          'Festividad local de Olmedilla de Eliz',
          'Festividad local de Paredes',
          'Festividad local de Poveda de la Obispalía',
          'Festividad local de Pozoseco',
          'Festividad local de Puebla de Almenara',
          'Festividad local de Puebla del Salvador',
          'Festividad local de Quintanar del Rey',
          'Festividad local de Ribagorda',
          'Festividad local de Rubielos Altos',
          'Festividad local de Rubielos Bajos',
          'Festividad local de Salmeroncillos',
          'Festividad local de Santa Maria de los Llanos',
          'Festividad local de Tresjuncos',
          'Festividad local de Valdecabras',
          'Festividad local de Valdecañas',
          'Festividad local de Valdemorillo de la Sierra',
          'Festividad local de Valdemoro Sierra',
          'Festividad local de Valeria',
          'Festividad local de Valparaiso de Arriba',
          'Festividad local de Valverdejo',
          'Festividad local de Villaconejos de Trabaque',
          'Festividad local de Villagarcía del Llano',
          'Festividad local de Villamayor de Santiago',
          'Festividad local de Villar de Domingo Garcia',
          'Festividad local de Villar de la Encina',
          'Festividad local de Villar del Humo',
          'Festividad local de Villar del Infantado',
          'Festividad local de Villar del Saz de Navalon',
          'Festividad local de Villarejo del Espartal',
          'Festividad local de Villarejo Periesteban',
          'Festividad local de Villarta',
          'Festividad local de Zafra de Zancara',
          'Festividad local de Abánades',
          'Festividad local de Alaminos',
          'Festividad local de Alarilla',
          'Festividad local de Alcolea de las Peñas',
          'Festividad local de Armuña de Tajuña',
          'Festividad local de Azuqueca de Henares',
          'Festividad local de Bujalaro',
          'Festividad local de Canredondo',
          'Festividad local de Cañizar',
          'Festividad local de Castejón de Henares',
          'Festividad local de Ciruelas',
          'Festividad local de Cogolludo',
          'Festividad local de El Cubillo de Uceda',
          'Festividad local de El Pobo de Dueñas',
          'Festividad local de Esplegares',
          'Festividad local de Fuentenovilla',
          'Festividad local de Gajanejos',
          'Festividad local de Las Inviernas',
          'Festividad local de Ledanca',
          'Festividad local de Marchamalo',
          'Festividad local de Pioz',
          'Festividad local de Sacecorbo',
          'Festividad local de Torre del Burgo',
          'Festividad local de Utande',
          'Festividad local de Valdearenas',
          'Festividad local de Valderrebollo',
          'Festividad local de Alberche del Caudillo',
          'Festividad local de Alcolea de Tajo',
          'Festividad local de Almendral de La Cañada',
          'Festividad local de Almonacid de Toledo',
          'Festividad local de Arcicollar',
          'Festividad local de Bernuy',
          'Festividad local de Burguillos de Toledo',
          'Festividad local de Cabañas de la Sagra',
          'Festividad local de Camuñas',
          'Festividad local de Casarrubios del Monte',
          'Festividad local de Casasbuenas',
          'Festividad local de Chueca',
          'Festividad local de Cuerva',
          'Festividad local de Domingo Pérez',
          'Festividad local de Dosbarrios',
          'Festividad local de Gálvez',
          'Festividad local de Gargantilla',
          'Festividad local de La Puebla de Almoradiel',
          'Festividad local de La Rinconada',
          'Festividad local de Las Herencias',
          'Festividad local de Las Vegas y San Antonio',
          'Festividad local de Layos',
          'Festividad local de Manzaneque',
          'Festividad local de Mascaraque',
          'Festividad local de Mejorada',
          'Festividad local de Miguel Esteban',
          'Festividad local de Quintanar de la Orden',
          'Festividad local de Quismondo',
          'Festividad local de Romeral',
          'Festividad local de San Pablo de los Montes',
          'Festividad local de Santa Cruz de la Zarza',
          'Festividad local de Seseña',
          'Festividad local de Talavera de la Reina',
          'Festividad local de Talavera la Nueva',
          'Festividad local de Tembleque',
          'Festividad local de Torre de Esteban Hambrán',
          'Festividad local de Totanés',
          'Festividad local de Turleque',
          'Festividad local de Ugena',
          'Festividad local de Urda',
          'Festividad local de Valdeazores',
          'Festividad local de Villa de Don Fadrique',
          'Festividad local de Villacañas',
          'Festividad local de Villafranca de los Caballeros',
          'Festividad local de Villasequilla',
        ],
        pc_ocupacion: 20.0,
        room: {
          double: {
            newPrice: 53.23,
            priceBefore: 49.84,
            priceNow: 54.28,
          },
          single: {
            newPrice: 40.97,
            priceBefore: 42.64,
            priceNow: 40.75,
          },
          suite: {
            newPrice: 82.46,
            priceBefore: 83.15,
            priceNow: 80.19,
          },
        },
      },
      {
        date: '2025-05-16',
        festivos: [
          'Festividad local de Alcadozo',
          'Festividad local de Cenizate',
          'Festividad local de Puertollano',
          'Festividad local de Cañada Juncosa',
          'Festividad local de Monreal del Llano',
          'Festividad local de Valhermoso de la Fuente',
          'Festividad local de La Olmeda de Jadraque',
          'Festividad local de Valdarachas',
        ],
        pc_ocupacion: 17.0,
        room: {
          double: {
            newPrice: 52.09,
            priceBefore: 54.04,
            priceNow: 55.79,
          },
          single: {
            newPrice: 35.49,
            priceBefore: 36.05,
            priceNow: 39.25,
          },
          suite: {
            newPrice: 75.91,
            priceBefore: 70.16,
            priceNow: 74.89,
          },
        },
      },
      {
        date: '2025-05-17',
        festivos: [
          'Festividad local de Paterna del Madera',
          'Festividad local de Fuenllana',
          'Festividad local de Balbacil',
          'Festividad local de Castellar de la Muela',
          'Festividad local de Clares',
          'Festividad local de Codes',
          'Festividad local de Fuentelsaz',
          'Festividad local de Maranchón',
          'Festividad local de Mochales',
          'Festividad local de Moratilla de los Meleros',
          'Festividad local de Tierzo',
          'Festividad local de Torremocha del Campo',
          'Festividad local de Turmiel',
        ],
        pc_ocupacion: 21.0,
        room: {
          double: {
            newPrice: 62.28,
            priceBefore: 57.22,
            priceNow: 55.58,
          },
          single: {
            newPrice: 59.32,
            priceBefore: 55.31,
            priceNow: 52.61,
          },
          suite: {
            newPrice: 94.67,
            priceBefore: 86.54,
            priceNow: 82.82,
          },
        },
      },
      {
        date: '2025-05-18',
        festivos: [],
        pc_ocupacion: 17.0,
        room: {
          double: {
            newPrice: 52.29,
            priceBefore: 47.64,
            priceNow: 45.89,
          },
          single: {
            newPrice: 29.66,
            priceBefore: 29.05,
            priceNow: 31.76,
          },
          suite: {
            newPrice: 77.65,
            priceBefore: 80.58,
            priceNow: 84.52,
          },
        },
      },
      {
        date: '2025-05-19',
        festivos: [],
        pc_ocupacion: 22.0,
        room: {
          double: {
            newPrice: 62.6,
            priceBefore: 63.21,
            priceNow: 69.08,
          },
          single: {
            newPrice: 44.86,
            priceBefore: 44.2,
            priceNow: 42.37,
          },
          suite: {
            newPrice: 76.9,
            priceBefore: 73.75,
            priceNow: 79.68,
          },
        },
      },
      {
        date: '2025-05-20',
        festivos: [
          'Festividad local de Peñas de San Pedro',
          'Festividad local de Tiriez',
          'Festividad local de Ciudad Real',
          'Festividad local de Guadalmez',
          'Festividad local de Algarra',
          'Festividad local de Boniches',
          'Festividad local de Cañada del Hoyo',
          'Festividad local de Castejon',
          'Festividad local de El Acebron',
          'Festividad local de La Hinojosa',
          'Festividad local de Landete',
          'Festividad local de Poveda de la Obispalía',
          'Festividad local de San Clemente',
          'Festividad local de Santa Cruz de Moya',
          'Festividad local de Vara de Rey',
          'Festividad local de Villarejo de Fuentes',
          'Festividad local de Auñón',
          'Festividad local de Corral de Almaguer',
          'Festividad local de Hontanar',
          'Festividad local de Nambroca',
          'Festividad local de Navahermosa',
          'Festividad local de Torrijos',
          'Festividad local de Velada',
        ],
        pc_ocupacion: 20.0,
        room: {
          double: {
            newPrice: 48.73,
            priceBefore: 50.32,
            priceNow: 55.0,
          },
          single: {
            newPrice: 26.51,
            priceBefore: 27.65,
            priceNow: 28.95,
          },
          suite: {
            newPrice: 106.03,
            priceBefore: 103.69,
            priceNow: 107.62,
          },
        },
      },
      {
        date: '2025-05-21',
        festivos: ['Festividad local de Hombrados'],
        pc_ocupacion: 14.0,
        room: {
          double: {
            newPrice: 62.29,
            priceBefore: 61.08,
            priceNow: 64.17,
          },
          single: {
            newPrice: 34.54,
            priceBefore: 31.81,
            priceNow: 33.1,
          },
          suite: {
            newPrice: 57.8,
            priceBefore: 56.5,
            priceNow: 58.64,
          },
        },
      },
      {
        date: '2025-05-22',
        festivos: [
          'Festividad local de Casas de Vés',
          'Festividad local de Higueruela',
          'Festividad local de Jorquera',
          'Festividad local de Pétrola',
          'Festividad local de Alcoba de los Montes',
          'Festividad local de Fuente el Fresno',
          'Festividad local de Los Pozuelos de Calatrava',
          'Festividad local de Puebla de Don Rodrigo',
          'Festividad local de Belinchon',
          'Festividad local de Fuentelespino de Moya',
          'Festividad local de Rozalen Del Monte',
          'Festividad local de Tebar',
          'Festividad local de Ablanque',
          'Festividad local de Carrascosa de Henares',
          'Festividad local de Megina',
          'Festividad local de Torrecuadrada de Molina',
          'Festividad local de Marjaliza',
          'Festividad local de Minas de Santa Quiteria',
        ],
        pc_ocupacion: 19.0,
        room: {
          double: {
            newPrice: 49.43,
            priceBefore: 50.87,
            priceNow: 50.5,
          },
          single: {
            newPrice: 41.16,
            priceBefore: 39.94,
            priceNow: 39.87,
          },
          suite: {
            newPrice: 68.52,
            priceBefore: 69.89,
            priceNow: 72.54,
          },
        },
      },
      {
        date: '2025-05-23',
        festivos: [
          'Festividad local de Higueruela',
          'Festividad local de Puebla del Príncipe',
          'Festividad local de Minas de Santa Quiteria',
        ],
        pc_ocupacion: 19.0,
        room: {
          double: {
            newPrice: 43.15,
            priceBefore: 40.85,
            priceNow: 42.87,
          },
          single: {
            newPrice: 38.4,
            priceBefore: 34.93,
            priceNow: 36.24,
          },
          suite: {
            newPrice: 65.79,
            priceBefore: 64.45,
            priceNow: 68.72,
          },
        },
      },
      {
        date: '2025-05-24',
        festivos: [
          'Festividad local de Barrax',
          'Festividad local de Abia de la Obispalia',
          'Festividad local de Almodóvar del Pinar',
          'Festividad local de Huete',
          'Festividad local de Naharros',
          'Festividad local de Saceda-Trasierra',
          'Festividad local de Robledillo',
        ],
        pc_ocupacion: 19.0,
        room: {
          double: {
            newPrice: 48.6,
            priceBefore: 49.65,
            priceNow: 53.59,
          },
          single: {
            newPrice: 37.91,
            priceBefore: 35.27,
            priceNow: 33.85,
          },
          suite: {
            newPrice: 74.28,
            priceBefore: 69.03,
            priceNow: 71.78,
          },
        },
      },
      {
        date: '2025-05-25',
        festivos: [],
        pc_ocupacion: 16.0,
        room: {
          double: {
            newPrice: 39.85,
            priceBefore: 38.97,
            priceNow: 37.59,
          },
          single: {
            newPrice: 49.36,
            priceBefore: 46.45,
            priceNow: 44.13,
          },
          suite: {
            newPrice: 80.99,
            priceBefore: 82.48,
            priceNow: 80.55,
          },
        },
      },
      {
        date: '2025-05-26',
        festivos: [],
        pc_ocupacion: 24.0,
        room: {
          double: {
            newPrice: 63.73,
            priceBefore: 63.75,
            priceNow: 66.37,
          },
          single: {
            newPrice: 55.11,
            priceBefore: 55.22,
            priceNow: 54.18,
          },
          suite: {
            newPrice: 69.63,
            priceBefore: 72.94,
            priceNow: 78.53,
          },
        },
      },
      {
        date: '2025-05-27',
        festivos: [
          'Festividad local de Mestanza',
          'Festividad local de Navas de Estena',
          'Festividad local de Saelices',
          'Festividad local de Cañizar',
          'Festividad local de Córcoles',
          'Festividad local de Hortezuela de Océn',
          'Festividad local de Hormigos',
          'Festividad local de Robledillo',
        ],
        pc_ocupacion: 15.0,
        room: {
          double: {
            newPrice: 42.49,
            priceBefore: 38.79,
            priceNow: 40.69,
          },
          single: {
            newPrice: 45.4,
            priceBefore: 45.78,
            priceNow: 48.14,
          },
          suite: {
            newPrice: 71.41,
            priceBefore: 72.32,
            priceNow: 75.22,
          },
        },
      },
      {
        date: '2025-05-28',
        festivos: [
          'Festividad local de El Hito',
          'Festividad local de Puebla del Salvador',
          'Festividad local de Córcoles',
          'Festividad local de Tordelrábano',
        ],
        pc_ocupacion: 21.0,
        room: {
          double: {
            newPrice: 62.81,
            priceBefore: 58.29,
            priceNow: 62.33,
          },
          single: {
            newPrice: 33.05,
            priceBefore: 32.88,
            priceNow: 34.48,
          },
          suite: {
            newPrice: 89.54,
            priceBefore: 91.67,
            priceNow: 88.37,
          },
        },
      },
      {
        date: '2025-05-29',
        festivos: [
          'Festividad local de Gabaldon',
          'Festividad local de Sacedón',
        ],
        pc_ocupacion: 16.0,
        room: {
          double: {
            newPrice: 45.65,
            priceBefore: 42.78,
            priceNow: 43.74,
          },
          single: {
            newPrice: 38.89,
            priceBefore: 38.85,
            priceNow: 41.47,
          },
          suite: {
            newPrice: 69.37,
            priceBefore: 70.68,
            priceNow: 70.27,
          },
        },
      },
      {
        date: '2025-05-30',
        festivos: ['Festividad local de Sacedón', 'Festividad local de Selas'],
        pc_ocupacion: 15.0,
        room: {
          double: {
            newPrice: 44.51,
            priceBefore: 45.94,
            priceNow: 46.6,
          },
          single: {
            newPrice: 32.98,
            priceBefore: 34.57,
            priceNow: 36.56,
          },
          suite: {
            newPrice: 78.19,
            priceBefore: 78.0,
            priceNow: 84.52,
          },
        },
      },
      {
        date: '2025-05-31',
        festivos: ['Día de Castilla-La Mancha'],
        pc_ocupacion: 19.0,
        room: {
          double: {
            newPrice: 53.04,
            priceBefore: 49.91,
            priceNow: 51.71,
          },
          single: {
            newPrice: 42.38,
            priceBefore: 38.53,
            priceNow: 42.12,
          },
          suite: {
            newPrice: 73.2,
            priceBefore: 73.2,
            priceNow: 70.38,
          },
        },
      },
      {
        date: '2025-06-01',
        festivos: [
          'Festividad local de Cuenca',
          'Festividad local de La Melgosa',
          'Festividad local de Mohorte',
          'Festividad local de Gualda',
        ],
        pc_ocupacion: 46.0,
        room: {
          double: {
            newPrice: 91.08,
            priceBefore: 92.05,
            priceNow: 91.71,
          },
          single: {
            newPrice: 71.65,
            priceBefore: 69.28,
            priceNow: 67.3,
          },
          suite: {
            newPrice: 146.22,
            priceBefore: 144.52,
            priceNow: 143.98,
          },
        },
      },
      {
        date: '2025-06-02',
        festivos: [],
        pc_ocupacion: 47.0,
        room: {
          double: {
            newPrice: 125.68,
            priceBefore: 125.24,
            priceNow: 133.14,
          },
          single: {
            newPrice: 73.62,
            priceBefore: 67.04,
            priceNow: 73.7,
          },
          suite: {
            newPrice: 168.52,
            priceBefore: 155.77,
            priceNow: 156.06,
          },
        },
      },
      {
        date: '2025-06-03',
        festivos: [
          'Festividad local de Zafrilla',
          'Festividad local de Casas de San Galindo',
        ],
        pc_ocupacion: 41.0,
        room: {
          double: {
            newPrice: 111.18,
            priceBefore: 110.44,
            priceNow: 113.66,
          },
          single: {
            newPrice: 75.09,
            priceBefore: 71.91,
            priceNow: 68.74,
          },
          suite: {
            newPrice: 156.33,
            priceBefore: 144.75,
            priceNow: 158.03,
          },
        },
      },
      {
        date: '2025-06-04',
        festivos: ['Festividad local de Casas de San Galindo'],
        pc_ocupacion: 48.0,
        room: {
          double: {
            newPrice: 118.32,
            priceBefore: 118.15,
            priceNow: 125.8,
          },
          single: {
            newPrice: 57.3,
            priceBefore: 56.98,
            priceNow: 54.2,
          },
          suite: {
            newPrice: 166.55,
            priceBefore: 154.43,
            priceNow: 161.43,
          },
        },
      },
      {
        date: '2025-06-05',
        festivos: [],
        pc_ocupacion: 46.0,
        room: {
          double: {
            newPrice: 81.78,
            priceBefore: 84.43,
            priceNow: 88.49,
          },
          single: {
            newPrice: 83.04,
            priceBefore: 82.39,
            priceNow: 79.91,
          },
          suite: {
            newPrice: 152.5,
            priceBefore: 157.3,
            priceNow: 165.87,
          },
        },
      },
      {
        date: '2025-06-06',
        festivos: ['Festividad local de Valdepeñas'],
        pc_ocupacion: 33.0,
        room: {
          double: {
            newPrice: 91.98,
            priceBefore: 89.49,
            priceNow: 89.49,
          },
          single: {
            newPrice: 89.75,
            priceBefore: 82.57,
            priceNow: 86.69,
          },
          suite: {
            newPrice: 99.99,
            priceBefore: 91.77,
            priceNow: 94.49,
          },
        },
      },
      {
        date: '2025-06-07',
        festivos: ['Festividad local de Hinojosa'],
        pc_ocupacion: 58.0,
        room: {
          double: {
            newPrice: 118.36,
            priceBefore: 110.11,
            priceNow: 116.98,
          },
          single: {
            newPrice: 97.03,
            priceBefore: 99.38,
            priceNow: 99.31,
          },
          suite: {
            newPrice: 186.55,
            priceBefore: 174.9,
            priceNow: 188.72,
          },
        },
      },
      {
        date: '2025-06-08',
        festivos: [],
        pc_ocupacion: 49.0,
        room: {
          double: {
            newPrice: 123.95,
            priceBefore: 126.56,
            priceNow: 129.85,
          },
          single: {
            newPrice: 106.41,
            priceBefore: 98.89,
            priceNow: 98.32,
          },
          suite: {
            newPrice: 157.14,
            priceBefore: 150.75,
            priceNow: 143.49,
          },
        },
      },
      {
        date: '2025-06-09',
        festivos: ['Festividad local de La Huerce'],
        pc_ocupacion: 55.0,
        room: {
          double: {
            newPrice: 132.18,
            priceBefore: 130.66,
            priceNow: 137.33,
          },
          single: {
            newPrice: 98.81,
            priceBefore: 93.04,
            priceNow: 93.56,
          },
          suite: {
            newPrice: 163.17,
            priceBefore: 169.92,
            priceNow: 186.87,
          },
        },
      },
      {
        date: '2025-06-10',
        festivos: [
          'Festividad local de Fuentes',
          'Festividad local de Cobeta',
          'Festividad local de Mantiel',
        ],
        pc_ocupacion: 48.0,
        room: {
          double: {
            newPrice: 128.13,
            priceBefore: 117.65,
            priceNow: 123.98,
          },
          single: {
            newPrice: 86.97,
            priceBefore: 80.25,
            priceNow: 81.28,
          },
          suite: {
            newPrice: 173.66,
            priceBefore: 164.26,
            priceNow: 166.88,
          },
        },
      },
      {
        date: '2025-06-11',
        festivos: [
          'Festividad local de Pétrola',
          'Festividad local de Arenas de San Juan',
          'Festividad local de Cogollor',
          'Festividad local de Hijes',
          'Festividad local de Masegoso de Tajuña',
          'Festividad local de Trijueque',
          'Festividad local de Valdenuño Fernandez',
        ],
        pc_ocupacion: 37.0,
        room: {
          double: {
            newPrice: 104.61,
            priceBefore: 101.89,
            priceNow: 98.87,
          },
          single: {
            newPrice: 62.96,
            priceBefore: 63.57,
            priceNow: 62.39,
          },
          suite: {
            newPrice: 139.86,
            priceBefore: 134.32,
            priceNow: 133.6,
          },
        },
      },
      {
        date: '2025-06-12',
        festivos: [],
        pc_ocupacion: 51.0,
        room: {
          double: {
            newPrice: 98.11,
            priceBefore: 101.56,
            priceNow: 109.58,
          },
          single: {
            newPrice: 88.34,
            priceBefore: 91.88,
            priceNow: 90.12,
          },
          suite: {
            newPrice: 153.36,
            priceBefore: 151.9,
            priceNow: 148.8,
          },
        },
      },
      {
        date: '2025-06-13',
        festivos: [
          'Festividad local de Casas de Vés',
          'Festividad local de Robledo',
          'Festividad local de Alamillo',
          'Festividad local de Anchuras',
          'Festividad local de Torralba de Calatrava',
          'Festividad local de Abia de la Obispalia',
          'Festividad local de Albendea',
          'Festividad local de Arcos de la Sierra',
          'Festividad local de Buciegas',
          'Festividad local de Campillos-Sierra',
          'Festividad local de Cañete',
          'Festividad local de Cardenete',
          'Festividad local de Carrascosa de Haro',
          'Festividad local de Collados',
          'Festividad local de Colliga',
          'Festividad local de El Pozuelo',
          'Festividad local de Fuentelespino de Haro',
          'Festividad local de Huerta del Marquesado',
          'Festividad local de La Frontera',
          'Festividad local de Laguna del Marquesado',
          'Festividad local de Los Valdecolmenas',
          'Festividad local de Mariana',
          'Festividad local de Noheda',
          'Festividad local de Palomera',
          'Festividad local de Portalrubio de Guadamejud',
          'Festividad local de Ribatajadilla',
          'Festividad local de Salmeroncillos',
          'Festividad local de Salvacañete',
          'Festividad local de Sotos',
          'Festividad local de Talayuelas',
          'Festividad local de Valdecañas',
          'Festividad local de Valdemoro Sierra',
          'Festividad local de Valparaiso de Abajo',
          'Festividad local de Villanueva de los Escuderos',
          'Festividad local de Villar del Saz de Arcas',
          'Festividad local de Villaseca',
          'Festividad local de Zarzuela',
          'Festividad local de Albares',
          'Festividad local de Alcolea del Pinar',
          'Festividad local de Anquela del Pedregal',
          'Festividad local de Aranzueque',
          'Festividad local de Armallones',
          'Festividad local de Bañuelos',
          'Festividad local de Caspueñas',
          'Festividad local de Chillaron del Rey',
          'Festividad local de Condemios de Abajo',
          'Festividad local de Cubillejo de la Sierra',
          'Festividad local de El Recuenco',
          'Festividad local de Estables',
          'Festividad local de Gajanejos',
          'Festividad local de Huertahernando',
          'Festividad local de Iniestola',
          'Festividad local de Jirueque',
          'Festividad local de La Miñosa',
          'Festividad local de Miedes de Atienza',
          'Festividad local de Pradena de Atienza',
          'Festividad local de Tordesilos',
          'Festividad local de Torrecuadradilla',
          'Festividad local de Ujados',
          'Festividad local de Viana de Jadraque',
          'Festividad local de Villanueva de Alcorón',
          'Festividad local de Zaorejas',
          'Festividad local de Barcience',
          'Festividad local de Cabezamesada',
          'Festividad local de Cedillo del Condado',
          'Festividad local de Lucillos',
          'Festividad local de Yeles',
        ],
        pc_ocupacion: 49.0,
        room: {
          double: {
            newPrice: 110.18,
            priceBefore: 113.56,
            priceNow: 112.57,
          },
          single: {
            newPrice: 66.21,
            priceBefore: 66.42,
            priceNow: 66.82,
          },
          suite: {
            newPrice: 175.29,
            priceBefore: 164.54,
            priceNow: 175.49,
          },
        },
      },
      {
        date: '2025-06-14',
        festivos: [
          'Festividad local de Agudo',
          'Festividad local de Alcalá de la Vega',
          'Festividad local de Aliaguilla',
          'Festividad local de Fuentelespino de Haro',
          'Festividad local de Monteagudo de las Salinas',
          'Festividad local de Talayuelas',
          'Festividad local de Valdeolivas',
          'Festividad local de Albares',
          'Festividad local de Almadrones',
          'Festividad local de Canales del Ducado',
          'Festividad local de Cogollor',
          'Festividad local de La Miñosa',
          'Festividad local de Luzón',
          'Festividad local de Sotodosos',
          'Festividad local de Barcience',
          'Festividad local de Cabezamesada',
          'Festividad local de Yeles',
        ],
        pc_ocupacion: 41.0,
        room: {
          double: {
            newPrice: 90.63,
            priceBefore: 88.01,
            priceNow: 86.47,
          },
          single: {
            newPrice: 108.88,
            priceBefore: 99.43,
            priceNow: 98.48,
          },
          suite: {
            newPrice: 157.17,
            priceBefore: 150.62,
            priceNow: 163.45,
          },
        },
      },
      {
        date: '2025-06-15',
        festivos: [
          'Festividad local de Alcalá de la Vega',
          'Festividad local de Algora',
          'Festividad local de San Andrés del Congosto',
        ],
        pc_ocupacion: 43.0,
        room: {
          double: {
            newPrice: 131.91,
            priceBefore: 125.83,
            priceNow: 132.59,
          },
          single: {
            newPrice: 90.74,
            priceBefore: 92.84,
            priceNow: 93.42,
          },
          suite: {
            newPrice: 145.64,
            priceBefore: 146.84,
            priceNow: 149.53,
          },
        },
      },
      {
        date: '2025-06-16',
        festivos: [
          'Festividad local de Algora',
          'Festividad local de Valdepinillos',
        ],
        pc_ocupacion: 51.0,
        room: {
          double: {
            newPrice: 108.54,
            priceBefore: 107.57,
            priceNow: 104.13,
          },
          single: {
            newPrice: 74.67,
            priceBefore: 73.63,
            priceNow: 80.6,
          },
          suite: {
            newPrice: 187.29,
            priceBefore: 184.6,
            priceNow: 176.9,
          },
        },
      },
      {
        date: '2025-06-17',
        festivos: [
          'Festividad local de Zarza de Tajo',
          'Festividad local de Almadrones',
          'Festividad local de Valdegrudas',
        ],
        pc_ocupacion: 38.0,
        room: {
          double: {
            newPrice: 112.96,
            priceBefore: 104.69,
            priceNow: 114.43,
          },
          single: {
            newPrice: 56.62,
            priceBefore: 53.37,
            priceNow: 56.84,
          },
          suite: {
            newPrice: 139.98,
            priceBefore: 145.5,
            priceNow: 152.25,
          },
        },
      },
      {
        date: '2025-06-18',
        festivos: [],
        pc_ocupacion: 33.0,
        room: {
          double: {
            newPrice: 81.1,
            priceBefore: 77.75,
            priceNow: 79.63,
          },
          single: {
            newPrice: 65.25,
            priceBefore: 67.79,
            priceNow: 69.21,
          },
          suite: {
            newPrice: 144.86,
            priceBefore: 146.07,
            priceNow: 149.21,
          },
        },
      },
      {
        date: '2025-06-19',
        festivos: ['Corpus Christi'],
        pc_ocupacion: 61.0,
        room: {
          double: {
            newPrice: 88.98,
            priceBefore: 91.03,
            priceNow: 89.53,
          },
          single: {
            newPrice: 83.84,
            priceBefore: 76.61,
            priceNow: 73.63,
          },
          suite: {
            newPrice: 228.0,
            priceBefore: 233.54,
            priceNow: 227.74,
          },
        },
      },
      {
        date: '2025-06-20',
        festivos: ['Festividad local de Concha'],
        pc_ocupacion: 44.0,
        room: {
          double: {
            newPrice: 158.17,
            priceBefore: 147.87,
            priceNow: 145.01,
          },
          single: {
            newPrice: 71.67,
            priceBefore: 67.14,
            priceNow: 67.65,
          },
          suite: {
            newPrice: 143.86,
            priceBefore: 132.57,
            priceNow: 135.44,
          },
        },
      },
      {
        date: '2025-06-21',
        festivos: [
          'Festividad local de Galve de Sorbe',
          'Festividad local de Heras de Ayuso',
          'Festividad local de Villanueva de la Torre',
          'Festividad local de Rielves',
        ],
        pc_ocupacion: 32.0,
        room: {
          double: {
            newPrice: 92.12,
            priceBefore: 96.52,
            priceNow: 100.66,
          },
          single: {
            newPrice: 95.94,
            priceBefore: 92.17,
            priceNow: 91.7,
          },
          suite: {
            newPrice: 79.6,
            priceBefore: 81.01,
            priceNow: 86.24,
          },
        },
      },
      {
        date: '2025-06-22',
        festivos: [
          'Festividad local de Pajares',
          'Festividad local de Las Inviernas',
          'Festividad local de Utande',
        ],
        pc_ocupacion: 33.0,
        room: {
          double: {
            newPrice: 105.94,
            priceBefore: 97.49,
            priceNow: 95.58,
          },
          single: {
            newPrice: 90.24,
            priceBefore: 87.04,
            priceNow: 94.82,
          },
          suite: {
            newPrice: 100.5,
            priceBefore: 92.31,
            priceNow: 97.87,
          },
        },
      },
      {
        date: '2025-06-23',
        festivos: [],
        pc_ocupacion: 46.0,
        room: {
          double: {
            newPrice: 101.8,
            priceBefore: 97.23,
            priceNow: 98.47,
          },
          single: {
            newPrice: 76.97,
            priceBefore: 77.31,
            priceNow: 73.63,
          },
          suite: {
            newPrice: 145.11,
            priceBefore: 145.7,
            priceNow: 150.39,
          },
        },
      },
      {
        date: '2025-06-24',
        festivos: [
          'Festividad local de Alatoz',
          'Festividad local de Albacete',
          'Festividad local de Alcalá del Júcar',
          'Festividad local de Cotillas',
          'Festividad local de El Bonillo',
          'Festividad local de Pozo Cañada',
          'Festividad local de Pozohondo',
          'Festividad local de Riópar',
          'Festividad local de Abenójar',
          'Festividad local de Horcajo de los Montes',
          'Festividad local de Villar del Pozo',
          'Festividad local de Villarta San Juan',
          'Festividad local de Casas de Garcimolina',
          'Festividad local de Colliguilla',
          'Festividad local de Fuentesclaras Chillaron',
          'Festividad local de Ribatajada',
          'Festividad local de Tragacete',
          'Festividad local de Villaconejos de Trabaque',
          'Festividad local de Alhóndiga',
          'Festividad local de Ledanca',
          'Festividad local de Majaelrayo',
          'Festividad local de Matillas',
          'Festividad local de Medranda',
          'Festividad local de Pinilla de Molina',
          'Festividad local de Puebla de Beleña',
          'Festividad local de Taragudo',
          'Festividad local de Tobillos',
          'Festividad local de Valdeaveruelo',
          'Festividad local de Yebra',
          'Festividad local de Camarena',
          'Festividad local de Huecas',
          'Festividad local de La Mata',
          'Festividad local de Marjaliza',
          'Festividad local de Palomeque',
          'Festividad local de Puente del Arzobispo',
          'Festividad local de Ugena',
        ],
        pc_ocupacion: 42.0,
        room: {
          double: {
            newPrice: 81.75,
            priceBefore: 81.29,
            priceNow: 85.41,
          },
          single: {
            newPrice: 75.03,
            priceBefore: 76.96,
            priceNow: 82.58,
          },
          suite: {
            newPrice: 133.22,
            priceBefore: 138.67,
            priceNow: 152.17,
          },
        },
      },
      {
        date: '2025-06-25',
        festivos: ['Festividad local de Pozohondo'],
        pc_ocupacion: 42.0,
        room: {
          double: {
            newPrice: 102.45,
            priceBefore: 107.42,
            priceNow: 115.74,
          },
          single: {
            newPrice: 66.03,
            priceBefore: 61.26,
            priceNow: 61.53,
          },
          suite: {
            newPrice: 176.73,
            priceBefore: 168.01,
            priceNow: 180.52,
          },
        },
      },
      {
        date: '2025-06-26',
        festivos: [],
        pc_ocupacion: 43.0,
        room: {
          double: {
            newPrice: 112.49,
            priceBefore: 111.19,
            priceNow: 114.62,
          },
          single: {
            newPrice: 81.85,
            priceBefore: 81.02,
            priceNow: 80.41,
          },
          suite: {
            newPrice: 154.3,
            priceBefore: 157.39,
            priceNow: 168.77,
          },
        },
      },
      {
        date: '2025-06-27',
        festivos: ['Festividad local de Fuentenovilla'],
        pc_ocupacion: 39.0,
        room: {
          double: {
            newPrice: 92.98,
            priceBefore: 93.34,
            priceNow: 94.52,
          },
          single: {
            newPrice: 56.6,
            priceBefore: 53.01,
            priceNow: 52.21,
          },
          suite: {
            newPrice: 137.04,
            priceBefore: 141.68,
            priceNow: 134.89,
          },
        },
      },
      {
        date: '2025-06-28',
        festivos: [
          'Festividad local de San Pedro',
          'Festividad local de Bujalaro',
          'Festividad local de Polán',
        ],
        pc_ocupacion: 36.0,
        room: {
          double: {
            newPrice: 87.85,
            priceBefore: 83.93,
            priceNow: 81.89,
          },
          single: {
            newPrice: 63.81,
            priceBefore: 65.11,
            priceNow: 70.75,
          },
          suite: {
            newPrice: 144.66,
            priceBefore: 142.7,
            priceNow: 156.0,
          },
        },
      },
      {
        date: '2025-06-29',
        festivos: [
          'Festividad local de Bascuñana de San Pedro',
          'Festividad local de Loranca del Campo',
          'Festividad local de El Pedregal',
          'Festividad local de Polán',
        ],
        pc_ocupacion: 54.0,
        room: {
          double: {
            newPrice: 97.72,
            priceBefore: 89.0,
            priceNow: 96.77,
          },
          single: {
            newPrice: 103.59,
            priceBefore: 106.08,
            priceNow: 111.54,
          },
          suite: {
            newPrice: 163.73,
            priceBefore: 169.06,
            priceNow: 167.36,
          },
        },
      },
      {
        date: '2025-06-30',
        festivos: [],
        pc_ocupacion: 39.0,
        room: {
          double: {
            newPrice: 109.14,
            priceBefore: 101.71,
            priceNow: 102.72,
          },
          single: {
            newPrice: 71.74,
            priceBefore: 70.73,
            priceNow: 75.67,
          },
          suite: {
            newPrice: 151.82,
            priceBefore: 145.57,
            priceNow: 140.17,
          },
        },
      },
      {
        date: '2025-07-01',
        festivos: [
          'Festividad local de San Pedro',
          'Festividad local de Chequilla',
        ],
        pc_ocupacion: 47.0,
        room: {
          double: {
            newPrice: 132.05,
            priceBefore: 129.25,
            priceNow: 133.85,
          },
          single: {
            newPrice: 92.66,
            priceBefore: 90.44,
            priceNow: 92.67,
          },
          suite: {
            newPrice: 136.28,
            priceBefore: 134.97,
            priceNow: 142.39,
          },
        },
      },
      {
        date: '2025-07-02',
        festivos: ['Festividad local de Valdemeca'],
        pc_ocupacion: 51.0,
        room: {
          double: {
            newPrice: 109.38,
            priceBefore: 104.8,
            priceNow: 110.62,
          },
          single: {
            newPrice: 97.63,
            priceBefore: 89.03,
            priceNow: 86.95,
          },
          suite: {
            newPrice: 148.86,
            priceBefore: 140.11,
            priceNow: 141.36,
          },
        },
      },
      {
        date: '2025-07-03',
        festivos: [
          'Festividad local de Valdemeca',
          'Festividad local de Escamilla',
        ],
        pc_ocupacion: 51.0,
        room: {
          double: {
            newPrice: 134.41,
            priceBefore: 122.69,
            priceNow: 125.64,
          },
          single: {
            newPrice: 97.66,
            priceBefore: 91.05,
            priceNow: 99.1,
          },
          suite: {
            newPrice: 141.31,
            priceBefore: 145.32,
            priceNow: 140.62,
          },
        },
      },
      {
        date: '2025-07-04',
        festivos: [],
        pc_ocupacion: 42.0,
        room: {
          double: {
            newPrice: 116.9,
            priceBefore: 116.94,
            priceNow: 116.95,
          },
          single: {
            newPrice: 106.66,
            priceBefore: 97.09,
            priceNow: 92.55,
          },
          suite: {
            newPrice: 127.27,
            priceBefore: 133.88,
            priceNow: 144.79,
          },
        },
      },
      {
        date: '2025-07-05',
        festivos: ['Festividad local de Puerto Rey'],
        pc_ocupacion: 46.0,
        room: {
          double: {
            newPrice: 139.4,
            priceBefore: 136.8,
            priceNow: 147.82,
          },
          single: {
            newPrice: 84.63,
            priceBefore: 85.14,
            priceNow: 89.81,
          },
          suite: {
            newPrice: 140.48,
            priceBefore: 134.51,
            priceNow: 134.46,
          },
        },
      },
      {
        date: '2025-07-06',
        festivos: [],
        pc_ocupacion: 48.0,
        room: {
          double: {
            newPrice: 97.65,
            priceBefore: 91.92,
            priceNow: 97.08,
          },
          single: {
            newPrice: 81.68,
            priceBefore: 82.86,
            priceNow: 84.38,
          },
          suite: {
            newPrice: 186.64,
            priceBefore: 186.06,
            priceNow: 185.43,
          },
        },
      },
      {
        date: '2025-07-07',
        festivos: [],
        pc_ocupacion: 43.0,
        room: {
          double: {
            newPrice: 132.71,
            priceBefore: 125.08,
            priceNow: 124.19,
          },
          single: {
            newPrice: 64.4,
            priceBefore: 67.02,
            priceNow: 69.27,
          },
          suite: {
            newPrice: 149.23,
            priceBefore: 139.43,
            priceNow: 147.48,
          },
        },
      },
      {
        date: '2025-07-08',
        festivos: [
          'Festividad local de Ballesteros de Calatrava',
          'Festividad local de Canredondo',
          'Festividad local de Puerto Rey',
        ],
        pc_ocupacion: 64.0,
        room: {
          double: {
            newPrice: 101.8,
            priceBefore: 100.74,
            priceNow: 99.43,
          },
          single: {
            newPrice: 118.51,
            priceBefore: 111.43,
            priceNow: 106.67,
          },
          suite: {
            newPrice: 219.81,
            priceBefore: 211.22,
            priceNow: 214.54,
          },
        },
      },
      {
        date: '2025-07-09',
        festivos: ['Festividad local de Luzaga'],
        pc_ocupacion: 54.0,
        room: {
          double: {
            newPrice: 116.45,
            priceBefore: 119.71,
            priceNow: 116.0,
          },
          single: {
            newPrice: 81.02,
            priceBefore: 84.67,
            priceNow: 80.73,
          },
          suite: {
            newPrice: 143.6,
            priceBefore: 147.39,
            priceNow: 149.59,
          },
        },
      },
      {
        date: '2025-07-10',
        festivos: ['Festividad local de Gascueña', 'Festividad local de Orea'],
        pc_ocupacion: 58.0,
        room: {
          double: {
            newPrice: 110.23,
            priceBefore: 107.96,
            priceNow: 107.69,
          },
          single: {
            newPrice: 115.07,
            priceBefore: 116.09,
            priceNow: 111.46,
          },
          suite: {
            newPrice: 195.57,
            priceBefore: 183.48,
            priceNow: 175.45,
          },
        },
      },
      {
        date: '2025-07-11',
        festivos: [],
        pc_ocupacion: 62.0,
        room: {
          double: {
            newPrice: 111.89,
            priceBefore: 103.24,
            priceNow: 103.56,
          },
          single: {
            newPrice: 140.25,
            priceBefore: 139.69,
            priceNow: 134.35,
          },
          suite: {
            newPrice: 160.06,
            priceBefore: 167.7,
            priceNow: 174.72,
          },
        },
      },
      {
        date: '2025-07-12',
        festivos: [
          'Festividad local de Manzanares',
          'Festividad local de Robledo del Buey',
        ],
        pc_ocupacion: 45.0,
        room: {
          double: {
            newPrice: 107.97,
            priceBefore: 111.81,
            priceNow: 118.95,
          },
          single: {
            newPrice: 97.03,
            priceBefore: 100.91,
            priceNow: 107.47,
          },
          suite: {
            newPrice: 141.82,
            priceBefore: 138.44,
            priceNow: 139.84,
          },
        },
      },
      {
        date: '2025-07-13',
        festivos: [],
        pc_ocupacion: 52.0,
        room: {
          double: {
            newPrice: 70.23,
            priceBefore: 68.67,
            priceNow: 73.39,
          },
          single: {
            newPrice: 65.45,
            priceBefore: 61.77,
            priceNow: 61.79,
          },
          suite: {
            newPrice: 217.63,
            priceBefore: 213.11,
            priceNow: 214.21,
          },
        },
      },
      {
        date: '2025-07-14',
        festivos: [],
        pc_ocupacion: 51.0,
        room: {
          double: {
            newPrice: 126.85,
            priceBefore: 125.44,
            priceNow: 130.73,
          },
          single: {
            newPrice: 108.93,
            priceBefore: 109.64,
            priceNow: 105.37,
          },
          suite: {
            newPrice: 140.54,
            priceBefore: 140.02,
            priceNow: 137.93,
          },
        },
      },
      {
        date: '2025-07-15',
        festivos: [
          'Festividad local de Pinilla de Molina',
          'Festividad local de Azután',
          'Festividad local de Yuncler de la Sagra',
        ],
        pc_ocupacion: 49.0,
        room: {
          double: {
            newPrice: 109.88,
            priceBefore: 111.08,
            priceNow: 117.59,
          },
          single: {
            newPrice: 90.7,
            priceBefore: 86.36,
            priceNow: 89.67,
          },
          suite: {
            newPrice: 187.07,
            priceBefore: 185.32,
            priceNow: 193.2,
          },
        },
      },
      {
        date: '2025-07-16',
        festivos: [
          'Festividad local de Liétor',
          'Festividad local de Almodóvar del Campo',
          'Festividad local de Arenas de San Juan',
          'Festividad local de Pozuelo de Calatrava',
          'Festividad local de Carrascosa de la Sierra',
          'Festividad local de Colliguilla',
          'Festividad local de Tejadillos',
          'Festividad local de Berninches',
          'Festividad local de Molina de Aragón',
          'Festividad local de La Fresneda de la Jara',
          'Festividad local de La Puebla de Montalbán',
          'Festividad local de Las Ventas de Retamosa',
          'Festividad local de Manzaneque',
          'Festividad local de Mocejón',
          'Festividad local de Nambroca',
          'Festividad local de Santa Olalla',
          'Festividad local de Yuncler de la Sagra',
          'Festividad local de Yunclillos',
        ],
        pc_ocupacion: 39.0,
        room: {
          double: {
            newPrice: 103.05,
            priceBefore: 102.22,
            priceNow: 106.84,
          },
          single: {
            newPrice: 67.58,
            priceBefore: 70.52,
            priceNow: 68.95,
          },
          suite: {
            newPrice: 144.12,
            priceBefore: 149.23,
            priceNow: 143.62,
          },
        },
      },
      {
        date: '2025-07-17',
        festivos: [],
        pc_ocupacion: 67.0,
        room: {
          double: {
            newPrice: 118.35,
            priceBefore: 110.76,
            priceNow: 113.7,
          },
          single: {
            newPrice: 83.51,
            priceBefore: 82.96,
            priceNow: 87.59,
          },
          suite: {
            newPrice: 265.62,
            priceBefore: 243.4,
            priceNow: 249.9,
          },
        },
      },
      {
        date: '2025-07-18',
        festivos: ['Festividad local de Magán'],
        pc_ocupacion: 38.0,
        room: {
          double: {
            newPrice: 66.23,
            priceBefore: 69.54,
            priceNow: 69.51,
          },
          single: {
            newPrice: 79.27,
            priceBefore: 73.85,
            priceNow: 79.33,
          },
          suite: {
            newPrice: 131.13,
            priceBefore: 123.65,
            priceNow: 131.65,
          },
        },
      },
      {
        date: '2025-07-19',
        festivos: [
          'Festividad local de Villarejo Sobrehuerta',
          'Festividad local de Cabanillas del Campo',
          'Festividad local de Chozas de Canales',
          'Festividad local de Noblejas',
        ],
        pc_ocupacion: 46.0,
        room: {
          double: {
            newPrice: 114.16,
            priceBefore: 106.36,
            priceNow: 110.16,
          },
          single: {
            newPrice: 63.33,
            priceBefore: 59.93,
            priceNow: 63.33,
          },
          suite: {
            newPrice: 155.71,
            priceBefore: 148.08,
            priceNow: 157.43,
          },
        },
      },
      {
        date: '2025-07-20',
        festivos: ['Festividad local de Cendejas de Enmedio'],
        pc_ocupacion: 55.0,
        room: {
          double: {
            newPrice: 144.76,
            priceBefore: 133.21,
            priceNow: 132.85,
          },
          single: {
            newPrice: 82.49,
            priceBefore: 84.37,
            priceNow: 83.26,
          },
          suite: {
            newPrice: 181.14,
            priceBefore: 172.8,
            priceNow: 174.4,
          },
        },
      },
      {
        date: '2025-07-21',
        festivos: ['Festividad local de Cendejas de Padrastro'],
        pc_ocupacion: 49.0,
        room: {
          double: {
            newPrice: 103.13,
            priceBefore: 95.0,
            priceNow: 92.04,
          },
          single: {
            newPrice: 105.77,
            priceBefore: 105.6,
            priceNow: 100.46,
          },
          suite: {
            newPrice: 146.58,
            priceBefore: 136.54,
            priceNow: 141.65,
          },
        },
      },
      {
        date: '2025-07-22',
        festivos: [
          'Festividad local de Villalgordo del Júcar',
          'Festividad local de Poblete',
          'Festividad local de Huerta del Marquesado',
          'Festividad local de Poyatos',
          'Festividad local de Cendejas de Padrastro',
          'Festividad local de Mondéjar',
          'Festividad local de Valdeavellano',
          'Festividad local de Carranque',
          'Festividad local de El Viso de San Juan',
          'Festividad local de Escalonilla',
          'Festividad local de Garciotum',
          'Festividad local de Guadamur',
          'Festividad local de Layos',
          'Festividad local de Mascaraque',
          'Festividad local de Villamuelas',
          'Festividad local de Villasequilla',
        ],
        pc_ocupacion: 49.0,
        room: {
          double: {
            newPrice: 96.96,
            priceBefore: 96.4,
            priceNow: 103.13,
          },
          single: {
            newPrice: 78.43,
            priceBefore: 77.74,
            priceNow: 84.91,
          },
          suite: {
            newPrice: 181.72,
            priceBefore: 172.87,
            priceNow: 188.8,
          },
        },
      },
      {
        date: '2025-07-23',
        festivos: ['Festividad local de Castillejo de Iniesta'],
        pc_ocupacion: 37.0,
        room: {
          double: {
            newPrice: 76.15,
            priceBefore: 74.33,
            priceNow: 79.0,
          },
          single: {
            newPrice: 100.01,
            priceBefore: 95.66,
            priceNow: 99.15,
          },
          suite: {
            newPrice: 126.28,
            priceBefore: 123.11,
            priceNow: 127.52,
          },
        },
      },
      {
        date: '2025-07-24',
        festivos: [],
        pc_ocupacion: 41.0,
        room: {
          double: {
            newPrice: 105.43,
            priceBefore: 97.53,
            priceNow: 93.79,
          },
          single: {
            newPrice: 74.94,
            priceBefore: 70.66,
            priceNow: 75.14,
          },
          suite: {
            newPrice: 124.85,
            priceBefore: 124.31,
            priceNow: 118.21,
          },
        },
      },
      {
        date: '2025-07-25',
        festivos: [
          'Festividad local de Santa Ana',
          'Festividad local de Carrión de Calatrava',
          'Festividad local de Granátula de Calatrava',
          'Festividad local de La Solana',
          'Festividad local de Membrilla',
          'Festividad local de Viso del Marques',
          'Festividad local de Valsalobre',
          'Festividad local de Campisabalos',
          'Festividad local de Mohernando',
          'Festividad local de Valdelcubo',
          'Festividad local de Alcañizo',
          'Festividad local de Burujón',
          'Festividad local de Carpio de Tajo',
          'Festividad local de Espinoso del Rey',
          'Festividad local de Nombela',
          'Festividad local de Quero',
          'Festividad local de Santo Domingo Caudilla',
          'Festividad local de Sartajada',
          'Festividad local de Sotillo de las Palomas',
        ],
        pc_ocupacion: 37.0,
        room: {
          double: {
            newPrice: 74.95,
            priceBefore: 75.66,
            priceNow: 80.29,
          },
          single: {
            newPrice: 65.94,
            priceBefore: 65.29,
            priceNow: 70.7,
          },
          suite: {
            newPrice: 148.72,
            priceBefore: 144.9,
            priceNow: 138.79,
          },
        },
      },
      {
        date: '2025-07-26',
        festivos: [
          'Festividad local de Motilleja',
          'Festividad local de Pozo Lorente',
          'Festividad local de Santa Ana',
          'Festividad local de Almadén',
          'Festividad local de Cabezarados',
          'Festividad local de Fontanarejo',
          'Festividad local de Carrascosa del Campo',
          'Festividad local de El Pedernoso',
          'Festividad local de Gabaldon',
          'Festividad local de Pineda de Gigüela',
          'Festividad local de Reillo',
          'Festividad local de Valsalobre',
          'Festividad local de Vindel',
          'Festividad local de Alarilla',
          'Festividad local de Campisabalos',
          'Festividad local de Muduex',
          'Festividad local de Torremocha del Campo',
          'Festividad local de Añover de Tajo',
          'Festividad local de Burujón',
          'Festividad local de Camarenilla',
          'Festividad local de Mora',
          'Festividad local de Nombela',
          'Festividad local de Santa Ana de Pusa',
          'Festividad local de Santo Domingo Caudilla',
          'Festividad local de Villanueva de Bogas',
        ],
        pc_ocupacion: 53.0,
        room: {
          double: {
            newPrice: 77.93,
            priceBefore: 81.38,
            priceNow: 86.2,
          },
          single: {
            newPrice: 154.36,
            priceBefore: 147.72,
            priceNow: 161.54,
          },
          suite: {
            newPrice: 198.37,
            priceBefore: 180.98,
            priceNow: 173.51,
          },
        },
      },
      {
        date: '2025-07-27',
        festivos: [
          'Festividad local de Ribatajadilla',
          'Festividad local de Ciruelos del Pinar',
        ],
        pc_ocupacion: 56.0,
        room: {
          double: {
            newPrice: 125.14,
            priceBefore: 117.96,
            priceNow: 121.29,
          },
          single: {
            newPrice: 152.27,
            priceBefore: 147.26,
            priceNow: 140.36,
          },
          suite: {
            newPrice: 153.37,
            priceBefore: 139.85,
            priceNow: 138.28,
          },
        },
      },
      {
        date: '2025-07-28',
        festivos: [],
        pc_ocupacion: 48.0,
        room: {
          double: {
            newPrice: 85.97,
            priceBefore: 90.3,
            priceNow: 95.86,
          },
          single: {
            newPrice: 101.81,
            priceBefore: 93.03,
            priceNow: 91.14,
          },
          suite: {
            newPrice: 189.65,
            priceBefore: 193.3,
            priceNow: 193.65,
          },
        },
      },
      {
        date: '2025-07-29',
        festivos: [
          'Festividad local de Cabezarrubias del Puerto',
          'Festividad local de Mestanza',
          'Festividad local de Solana del Pino',
          'Festividad local de Cobisa',
          'Festividad local de Ontígola',
        ],
        pc_ocupacion: 55.0,
        room: {
          double: {
            newPrice: 103.69,
            priceBefore: 104.74,
            priceNow: 112.93,
          },
          single: {
            newPrice: 98.58,
            priceBefore: 92.34,
            priceNow: 95.78,
          },
          suite: {
            newPrice: 169.45,
            priceBefore: 173.46,
            priceNow: 173.01,
          },
        },
      },
      {
        date: '2025-07-30',
        festivos: [],
        pc_ocupacion: 37.0,
        room: {
          double: {
            newPrice: 89.98,
            priceBefore: 89.39,
            priceNow: 97.46,
          },
          single: {
            newPrice: 74.68,
            priceBefore: 70.42,
            priceNow: 71.1,
          },
          suite: {
            newPrice: 156.21,
            priceBefore: 142.26,
            priceNow: 149.72,
          },
        },
      },
      {
        date: '2025-07-31',
        festivos: ['Festividad local de Tribaldos'],
        pc_ocupacion: 48.0,
        room: {
          double: {
            newPrice: 125.61,
            priceBefore: 114.49,
            priceNow: 111.22,
          },
          single: {
            newPrice: 82.83,
            priceBefore: 76.95,
            priceNow: 81.06,
          },
          suite: {
            newPrice: 150.27,
            priceBefore: 157.66,
            priceNow: 151.33,
          },
        },
      },
      {
        date: '2025-08-01',
        festivos: [
          'Festividad local de San Pedro Palmiches',
          'Festividad local de Villalpardo',
          'Festividad local de Las Ventas de Retamosa',
        ],
        pc_ocupacion: 44.0,
        room: {
          double: {
            newPrice: 100.11,
            priceBefore: 95.23,
            priceNow: 99.45,
          },
          single: {
            newPrice: 100.93,
            priceBefore: 106.11,
            priceNow: 116.18,
          },
          suite: {
            newPrice: 136.51,
            priceBefore: 139.51,
            priceNow: 147.24,
          },
        },
      },
      {
        date: '2025-08-02',
        festivos: [
          'Festividad local de Calzada de Calatrava',
          'Festividad local de Pedro Muñoz',
          'Festividad local de Buciegas',
          'Festividad local de La Huerguina',
          'Festividad local de Villar del Águila',
          'Festividad local de Yemeda',
          'Festividad local de Amayas',
          'Festividad local de El Olivar',
          'Festividad local de El Ordial',
          'Festividad local de Escopete',
          'Festividad local de La Bodera',
          'Festividad local de Renera',
          'Festividad local de Robledo de Corpes',
          'Festividad local de Torre del Burgo',
          'Festividad local de Valdesotos',
          'Festividad local de Argés',
          'Festividad local de Los Cerralbos',
          'Festividad local de Montearagón',
        ],
        pc_ocupacion: 52.0,
        room: {
          double: {
            newPrice: 141.64,
            priceBefore: 133.97,
            priceNow: 144.26,
          },
          single: {
            newPrice: 104.66,
            priceBefore: 99.68,
            priceNow: 98.56,
          },
          suite: {
            newPrice: 158.39,
            priceBefore: 158.04,
            priceNow: 167.72,
          },
        },
      },
      {
        date: '2025-08-03',
        festivos: [
          'Festividad local de Amayas',
          'Festividad local de El Olivar',
          'Festividad local de Gualda',
          'Festividad local de La Bodera',
          'Festividad local de Bargas',
        ],
        pc_ocupacion: 58.0,
        room: {
          double: {
            newPrice: 109.43,
            priceBefore: 112.3,
            priceNow: 117.8,
          },
          single: {
            newPrice: 119.19,
            priceBefore: 118.05,
            priceNow: 119.72,
          },
          suite: {
            newPrice: 157.36,
            priceBefore: 151.0,
            priceNow: 159.13,
          },
        },
      },
      {
        date: '2025-08-04',
        festivos: [
          'Festividad local de Algar de Mesa',
          'Festividad local de Bañuelos',
          'Festividad local de Loranca de Tajuña',
          'Festividad local de Olmeda de Cobeta',
          'Festividad local de Somolinos',
          'Festividad local de Valdepinillos',
        ],
        pc_ocupacion: 34.0,
        room: {
          double: {
            newPrice: 100.32,
            priceBefore: 97.01,
            priceNow: 92.28,
          },
          single: {
            newPrice: 65.86,
            priceBefore: 67.94,
            priceNow: 68.39,
          },
          suite: {
            newPrice: 134.92,
            priceBefore: 127.05,
            priceNow: 123.44,
          },
        },
      },
      {
        date: '2025-08-05',
        festivos: [
          'Festividad local de Cenizate',
          'Festividad local de Chinchilla',
          'Festividad local de Almagro',
          'Festividad local de Valdepeñas',
          'Festividad local de Almodóvar del Pinar',
          'Festividad local de Tejadillos',
          'Festividad local de Torralba',
          'Festividad local de Villanueva de la Jara',
          'Festividad local de Anguita',
          'Festividad local de Membrillera',
          'Festividad local de Ocentejo',
          'Festividad local de Renera',
          'Festividad local de Valdesotos',
          'Festividad local de Valtablado de Rio',
          'Festividad local de Lagartera',
          'Festividad local de Malpica de Tajo',
          'Festividad local de Pulgar',
          'Festividad local de Quero',
          'Festividad local de Torralba de Oropesa',
        ],
        pc_ocupacion: 51.0,
        room: {
          double: {
            newPrice: 170.51,
            priceBefore: 158.39,
            priceNow: 150.62,
          },
          single: {
            newPrice: 77.19,
            priceBefore: 73.57,
            priceNow: 75.25,
          },
          suite: {
            newPrice: 136.28,
            priceBefore: 133.88,
            priceNow: 134.24,
          },
        },
      },
      {
        date: '2025-08-06',
        festivos: [
          'Festividad local de La Roda',
          'Festividad local de Socovos',
          'Festividad local de Pozuelo de Calatrava',
          'Festividad local de Olmedilla de Eliz',
          'Festividad local de Huérmeces del Cerro',
          'Festividad local de Málaga del Fresno',
          'Festividad local de Montarrón',
          'Festividad local de Puebla de Valles',
          'Festividad local de Santiuste',
          'Festividad local de Torralba de Oropesa',
        ],
        pc_ocupacion: 48.0,
        room: {
          double: {
            newPrice: 98.45,
            priceBefore: 96.77,
            priceNow: 92.52,
          },
          single: {
            newPrice: 136.11,
            priceBefore: 138.53,
            priceNow: 144.24,
          },
          suite: {
            newPrice: 143.37,
            priceBefore: 140.89,
            priceNow: 135.32,
          },
        },
      },
      {
        date: '2025-08-07',
        festivos: [
          'Festividad local de Tresjuncos',
          'Festividad local de Pradena de Atienza',
          'Festividad local de Somolinos',
          'Festividad local de Tordellego',
        ],
        pc_ocupacion: 41.0,
        room: {
          double: {
            newPrice: 72.3,
            priceBefore: 74.36,
            priceNow: 76.17,
          },
          single: {
            newPrice: 74.18,
            priceBefore: 74.05,
            priceNow: 74.14,
          },
          suite: {
            newPrice: 148.92,
            priceBefore: 140.86,
            priceNow: 136.77,
          },
        },
      },
      {
        date: '2025-08-08',
        festivos: [
          'Festividad local de Terrinches',
          'Festividad local de Moya',
          'Festividad local de Ocentejo',
          'Festividad local de Torremocha del Pinar',
          'Festividad local de Valtablado de Rio',
          'Festividad local de Valmojado',
        ],
        pc_ocupacion: 58.0,
        room: {
          double: {
            newPrice: 119.14,
            priceBefore: 119.0,
            priceNow: 130.61,
          },
          single: {
            newPrice: 131.09,
            priceBefore: 127.61,
            priceNow: 135.54,
          },
          suite: {
            newPrice: 160.71,
            priceBefore: 153.99,
            priceNow: 167.58,
          },
        },
      },
      {
        date: '2025-08-09',
        festivos: [
          'Festividad local de El Ballestero',
          'Festividad local de San Lorenzo de Calatrava',
          'Festividad local de Santa Cruz de Mudela',
          'Festividad local de Casas de Garcimolina',
          'Festividad local de Mota de Altarejos',
          'Festividad local de Naharros',
          'Festividad local de Adobes',
          'Festividad local de Albendiego',
          'Festividad local de Castellar de la Muela',
          'Festividad local de Peñalén',
          'Festividad local de Riofrio del Llano',
          'Festividad local de Tortuero',
          'Festividad local de Viñuelas',
          'Festividad local de Campillo de La Jara',
          'Festividad local de Parrillas',
        ],
        pc_ocupacion: 51.0,
        room: {
          double: {
            newPrice: 62.41,
            priceBefore: 60.83,
            priceNow: 61.28,
          },
          single: {
            newPrice: 135.25,
            priceBefore: 123.57,
            priceNow: 121.92,
          },
          suite: {
            newPrice: 155.28,
            priceBefore: 158.54,
            priceNow: 159.89,
          },
        },
      },
      {
        date: '2025-08-10',
        festivos: [
          'Festividad local de Socuéllamos',
          'Festividad local de Arguisuelas',
          'Festividad local de Bustares',
          'Festividad local de Carrascosa de Henares',
          'Festividad local de Negredo',
          'Festividad local de Riofrio del Llano',
          'Festividad local de Romanillos de Atienza',
          'Festividad local de Torremochuela',
        ],
        pc_ocupacion: 49.0,
        room: {
          double: {
            newPrice: 81.14,
            priceBefore: 81.55,
            priceNow: 84.9,
          },
          single: {
            newPrice: 77.3,
            priceBefore: 77.66,
            priceNow: 77.21,
          },
          suite: {
            newPrice: 205.44,
            priceBefore: 203.43,
            priceNow: 214.57,
          },
        },
      },
      {
        date: '2025-08-11',
        festivos: [
          'Festividad local de Anchuela del Campo',
          'Festividad local de Negredo',
        ],
        pc_ocupacion: 51.0,
        room: {
          double: {
            newPrice: 145.35,
            priceBefore: 149.54,
            priceNow: 150.32,
          },
          single: {
            newPrice: 67.63,
            priceBefore: 63.96,
            priceNow: 62.12,
          },
          suite: {
            newPrice: 151.34,
            priceBefore: 146.34,
            priceNow: 160.5,
          },
        },
      },
      {
        date: '2025-08-12',
        festivos: [
          'Festividad local de Chillón',
          'Festividad local de Beamud',
          'Festividad local de Cervera del Llano',
          'Festividad local de Mota de Altarejos',
          'Festividad local de Mota del Cuervo',
          'Festividad local de San Lorenzo de la Parrilla',
          'Festividad local de Villarrubio',
          'Festividad local de Arbeteta',
          'Festividad local de Campillo de Ranas',
          'Festividad local de Checa',
          'Festividad local de Concha',
          'Festividad local de Gárgoles de Abajo',
          'Festividad local de Hinojosa',
          'Festividad local de Mantiel',
          'Festividad local de Peralveche',
        ],
        pc_ocupacion: 54.0,
        room: {
          double: {
            newPrice: 55.84,
            priceBefore: 56.39,
            priceNow: 56.43,
          },
          single: {
            newPrice: 128.25,
            priceBefore: 130.58,
            priceNow: 135.44,
          },
          suite: {
            newPrice: 178.01,
            priceBefore: 162.74,
            priceNow: 158.27,
          },
        },
      },
      {
        date: '2025-08-13',
        festivos: [
          'Festividad local de Beamud',
          'Festividad local de Castillo de Garcimuñoz',
          'Festividad local de Villarrubio',
          'Festividad local de Estables',
          'Festividad local de Terzaga',
        ],
        pc_ocupacion: 47.0,
        room: {
          double: {
            newPrice: 121.77,
            priceBefore: 112.38,
            priceNow: 115.17,
          },
          single: {
            newPrice: 76.17,
            priceBefore: 80.04,
            priceNow: 80.31,
          },
          suite: {
            newPrice: 163.25,
            priceBefore: 162.99,
            priceNow: 175.6,
          },
        },
      },
      {
        date: '2025-08-14',
        festivos: [
          'Festividad local de Jorquera',
          'Festividad local de Castillo de Garcimuñoz',
          'Festividad local de San Martin de Boniches',
          'Festividad local de Uña',
          'Festividad local de Campillo de Ranas',
          'Festividad local de Congostrina',
          'Festividad local de La Mierla',
          'Festividad local de Las Navas de Jadraque',
          'Festividad local de Prados Redondos',
          'Festividad local de Rebollosa de Jadraque',
          'Festividad local de Retiendas',
          'Festividad local de Sayatón',
          'Festividad local de Terzaga',
          'Festividad local de Uceda',
          'Festividad local de Alameda de la Sagra',
          'Festividad local de Bernuy',
          'Festividad local de Fuentes',
          'Festividad local de La Estrella',
          'Festividad local de Romeral',
        ],
        pc_ocupacion: 52.0,
        room: {
          double: {
            newPrice: 147.34,
            priceBefore: 136.3,
            priceNow: 149.05,
          },
          single: {
            newPrice: 102.63,
            priceBefore: 99.13,
            priceNow: 96.26,
          },
          suite: {
            newPrice: 173.48,
            priceBefore: 160.23,
            priceNow: 171.75,
          },
        },
      },
      {
        date: '2025-08-15',
        festivos: [
          'Asunción de la Virgen',
          'Festividad local de Hontecillas',
          'Festividad local de Anchuela del Pedregal',
          'Festividad local de Arbeteta',
          'Festividad local de Gascueña de Bornova',
          'Festividad local de Peralveche',
          'Festividad local de Tordelpalo',
        ],
        pc_ocupacion: 55.0,
        room: {
          double: {
            newPrice: 137.27,
            priceBefore: 133.12,
            priceNow: 139.03,
          },
          single: {
            newPrice: 88.76,
            priceBefore: 88.18,
            priceNow: 95.56,
          },
          suite: {
            newPrice: 165.59,
            priceBefore: 161.43,
            priceNow: 164.65,
          },
        },
      },
      {
        date: '2025-08-16',
        festivos: [
          'Festividad local de Barrax',
          'Festividad local de Bormate',
          'Festividad local de Fuentealbilla',
          'Festividad local de Letur',
          'Festividad local de Salobre',
          'Festividad local de Tobarra',
          'Festividad local de Villalgordo del Júcar',
          'Festividad local de Villarrobledo',
          'Festividad local de Villatoya',
          'Festividad local de Alamillo',
          'Festividad local de Alcolea de Calatrava',
          'Festividad local de Moral de Calatrava',
          'Festividad local de Torre de Juan Abad',
          'Festividad local de Aliaguilla',
          'Festividad local de Arcos de la Cantera',
          'Festividad local de Barbalimpia',
          'Festividad local de Bascuñana de San Pedro',
          'Festividad local de Bolliga',
          'Festividad local de Boniches',
          'Festividad local de Buenache de la Sierra',
          'Festividad local de Campillos Paravientos',
          'Festividad local de Cañamares',
          'Festividad local de Carboneras de Guadazaon',
          'Festividad local de Castillejo-Sierra',
          'Festividad local de El Provencio',
          'Festividad local de Enguidanos',
          'Festividad local de Fuentesbuenas',
          'Festividad local de Garcinarro',
          'Festividad local de Henarejos',
          'Festividad local de La Pesquera',
          'Festividad local de Lagunaseca',
          'Festividad local de Landete',
          'Festividad local de Ledaña',
          'Festividad local de Monteagudo de las Salinas',
          'Festividad local de Navalon',
          'Festividad local de Olivares del Jucar',
          'Festividad local de Olmedilla de Alarcon',
          'Festividad local de Olmedilla del Campo',
          'Festividad local de Pajaroncillo',
          'Festividad local de Portalrubio de Guadamejud',
          'Festividad local de Portilla',
          'Festividad local de Poyatos',
          'Festividad local de Rada de Haro',
          'Festividad local de Reillo',
          'Festividad local de Salinas del Manzano',
          'Festividad local de Sotoca',
          'Festividad local de Tondos',
          'Festividad local de Torrecilla',
          'Festividad local de Uña',
          'Festividad local de Valdecabras',
          'Festividad local de Valdemorillo de la Sierra',
          'Festividad local de Valeria',
          'Festividad local de Villaescusa de Haro',
          'Festividad local de Villalba de la Sierra',
          'Festividad local de Villanueva de los Escuderos',
          'Festividad local de Villar de la Encina',
          'Festividad local de Villar del Infantado',
          'Festividad local de Villarejo del Espartal',
          'Festividad local de Villarejo Seco',
          'Festividad local de Villarta',
          'Festividad local de Villora',
          'Festividad local de Vindel',
          'Festividad local de Alcocer',
          'Festividad local de Alcolea de las Peñas',
          'Festividad local de Alcolea del Pinar',
          'Festividad local de Aldeanueva de Atienza',
          'Festividad local de Aldeanueva de Guadalajara',
          'Festividad local de Alique',
          'Festividad local de Alocén',
          'Festividad local de Anquela del Pedregal',
          'Festividad local de Baides',
          'Festividad local de Brihuega',
          'Festividad local de Bujarrabal',
          'Festividad local de Bustares',
          'Festividad local de Canales del Ducado',
          'Festividad local de Cincovillas',
          'Festividad local de Cívica',
          'Festividad local de Condemios de Arriba',
          'Festividad local de Copernal',
          'Festividad local de Durón',
          'Festividad local de El Sotillo',
          'Festividad local de Fontanar',
          'Festividad local de Fuentelsaz',
          'Festividad local de Fuentelviejo',
          'Festividad local de Gárgoles de Abajo',
          'Festividad local de Gascueña de Bornova',
          'Festividad local de Illana',
          'Festividad local de Las Navas de Jadraque',
          'Festividad local de Mazarete',
          'Festividad local de Megina',
          'Festividad local de Olmeda de Cobeta',
          'Festividad local de Piqueras',
          'Festividad local de Poveda de la Sierra',
          'Festividad local de Rebollosa de Jadraque',
          'Festividad local de San Andrés del Congosto',
          'Festividad local de San Andrés del Rey',
          'Festividad local de Sayatón',
          'Festividad local de Selas',
          'Festividad local de Semillas',
          'Festividad local de Sienes',
          'Festividad local de Sigüenza',
          'Festividad local de Tierzo',
          'Festividad local de Tordelpalo',
          'Festividad local de Tordelrábano',
          'Festividad local de Torrejón del Rey',
          'Festividad local de Uceda',
          'Festividad local de Valdearenas',
          'Festividad local de Yélamos de Arriba',
          'Festividad local de Zarzuela de Jadraque',
          'Festividad local de Alameda de la Sagra',
          'Festividad local de Camarena',
          'Festividad local de Camuñas',
          'Festividad local de Casar de Escalona',
          'Festividad local de Cervera de los Montes',
          'Festividad local de Erustes',
          'Festividad local de Escalona',
          'Festividad local de Las Navillas',
          'Festividad local de Los Alares',
          'Festividad local de Magán',
          'Festividad local de Mazarambroz',
          'Festividad local de Mohedas de La Jara',
          'Festividad local de Navalcán',
          'Festividad local de Nuño Gómez',
          'Festividad local de Palomeque',
          'Festividad local de Quintanar de la Orden',
          'Festividad local de Quismondo',
          'Festividad local de San Román de los Montes',
          'Festividad local de Seseña',
          'Festividad local de Valdeverdeja',
        ],
        pc_ocupacion: 53.0,
        room: {
          double: {
            newPrice: 106.25,
            priceBefore: 103.57,
            priceNow: 111.81,
          },
          single: {
            newPrice: 115.01,
            priceBefore: 120.23,
            priceNow: 127.78,
          },
          suite: {
            newPrice: 165.23,
            priceBefore: 157.19,
            priceNow: 171.58,
          },
        },
      },
      {
        date: '2025-08-17',
        festivos: [
          'Festividad local de Hijes',
          'Festividad local de Mazarete',
          'Festividad local de Piqueras',
          'Festividad local de Poveda de la Sierra',
          'Festividad local de Taravilla',
        ],
        pc_ocupacion: 48.0,
        room: {
          double: {
            newPrice: 128.63,
            priceBefore: 120.91,
            priceNow: 120.05,
          },
          single: {
            newPrice: 73.15,
            priceBefore: 66.52,
            priceNow: 63.78,
          },
          suite: {
            newPrice: 136.76,
            priceBefore: 135.84,
            priceNow: 145.22,
          },
        },
      },
      {
        date: '2025-08-18',
        festivos: [
          'Festividad local de Castilnuevo',
          'Festividad local de El Ordial',
          'Festividad local de Estriegana',
          'Festividad local de La Toba',
          'Festividad local de Taravilla',
          'Festividad local de Torrecuadrada de Molina',
        ],
        pc_ocupacion: 39.0,
        room: {
          double: {
            newPrice: 111.53,
            priceBefore: 108.82,
            priceNow: 113.56,
          },
          single: {
            newPrice: 82.43,
            priceBefore: 78.99,
            priceNow: 77.13,
          },
          suite: {
            newPrice: 158.5,
            priceBefore: 145.46,
            priceNow: 151.56,
          },
        },
      },
      {
        date: '2025-08-19',
        festivos: [
          'Festividad local de Nerpio',
          'Festividad local de Membrilla',
          'Festividad local de El Provencio',
          'Festividad local de Moya',
          'Festividad local de Tinajas',
          'Festividad local de Torrubia del Castillo',
          'Festividad local de Villar de Domingo Garcia',
          'Festividad local de Alocén',
          'Festividad local de Estriegana',
          'Festividad local de Labros',
          'Festividad local de Tamajón',
          'Festividad local de Esquivias',
          'Festividad local de Retamoso de la Jara',
          'Festividad local de Valdeverdeja',
        ],
        pc_ocupacion: 58.0,
        room: {
          double: {
            newPrice: 142.02,
            priceBefore: 141.55,
            priceNow: 138.72,
          },
          single: {
            newPrice: 147.06,
            priceBefore: 147.36,
            priceNow: 140.5,
          },
          suite: {
            newPrice: 173.49,
            priceBefore: 172.68,
            priceNow: 186.58,
          },
        },
      },
      {
        date: '2025-08-20',
        festivos: [
          'Festividad local de Bogarra',
          'Festividad local de Letur',
          'Festividad local de Nerpio',
          'Festividad local de Hinojosas de Calatrava',
          'Festividad local de Campillos-Sierra',
          'Festividad local de La Hinojosa',
          'Festividad local de Torrubia del Castillo',
          'Festividad local de El Pobo de Dueñas',
          'Festividad local de Fuembellida',
          'Festividad local de Tamajón',
          'Festividad local de Esquivias',
        ],
        pc_ocupacion: 53.0,
        room: {
          double: {
            newPrice: 87.39,
            priceBefore: 88.16,
            priceNow: 92.31,
          },
          single: {
            newPrice: 119.61,
            priceBefore: 124.84,
            priceNow: 122.5,
          },
          suite: {
            newPrice: 155.24,
            priceBefore: 163.04,
            priceNow: 165.32,
          },
        },
      },
      {
        date: '2025-08-21',
        festivos: [
          'Festividad local de Iniesta',
          'Festividad local de Cubillejo de la Sierra',
          'Festividad local de Fuembellida',
        ],
        pc_ocupacion: 45.0,
        room: {
          double: {
            newPrice: 76.84,
            priceBefore: 71.39,
            priceNow: 70.07,
          },
          single: {
            newPrice: 93.9,
            priceBefore: 94.23,
            priceNow: 102.83,
          },
          suite: {
            newPrice: 183.0,
            priceBefore: 168.83,
            priceNow: 177.32,
          },
        },
      },
      {
        date: '2025-08-22',
        festivos: [
          'Festividad local de Ciudad Real',
          'Festividad local de San Clemente',
          'Festividad local de Alcoroches',
          'Festividad local de La Olmeda de Jadraque',
          'Festividad local de Morenilla',
          'Festividad local de Torremocha de Jadraque',
        ],
        pc_ocupacion: 46.0,
        room: {
          double: {
            newPrice: 108.04,
            priceBefore: 107.26,
            priceNow: 108.98,
          },
          single: {
            newPrice: 85.33,
            priceBefore: 82.79,
            priceNow: 84.1,
          },
          suite: {
            newPrice: 199.85,
            priceBefore: 187.74,
            priceNow: 193.51,
          },
        },
      },
      {
        date: '2025-08-23',
        festivos: [
          'Festividad local de Madrigueras',
          'Festividad local de Pozuelo',
          'Festividad local de Reolid',
          'Festividad local de Yeste',
          'Festividad local de Los Pozuelos de Calatrava',
          'Festividad local de Atalaya del Cañavate',
          'Festividad local de Fresneda de Altarejos',
          'Festividad local de La Almarcha',
          'Festividad local de Laguna del Marquesado',
          'Festividad local de Torrejoncillo del Rey',
          'Festividad local de Alcoroches',
          'Festividad local de Arbancón',
          'Festividad local de Armuña de Tajuña',
          'Festividad local de Casa de Uceda',
          'Festividad local de Galápagos',
          'Festividad local de Galve de Sorbe',
          'Festividad local de Henche',
          'Festividad local de Hita',
          'Festividad local de Morenilla',
          'Festividad local de Tartanedo',
          'Festividad local de Torremocha de Jadraque',
          'Festividad local de Valdepeñas de la Sierra',
          'Festividad local de Yebes',
          'Festividad local de Yélamos de Arriba',
          'Festividad local de Aldeanueva de San Bartolomé',
          'Festividad local de Cabañas de Yepes',
          'Festividad local de Gargantilla',
          'Festividad local de Marrupe',
          'Festividad local de Puerto de San Vicente',
          'Festividad local de San Bartolomé de las Abiertas',
        ],
        pc_ocupacion: 63.0,
        room: {
          double: {
            newPrice: 201.89,
            priceBefore: 189.57,
            priceNow: 205.84,
          },
          single: {
            newPrice: 78.78,
            priceBefore: 73.18,
            priceNow: 77.59,
          },
          suite: {
            newPrice: 167.03,
            priceBefore: 164.63,
            priceNow: 174.54,
          },
        },
      },
      {
        date: '2025-08-24',
        festivos: [
          'Festividad local de Tarazona de la Mancha',
          'Festividad local de Almagro',
          'Festividad local de Campo de Criptana',
          'Festividad local de Arcos de la Sierra',
          'Festividad local de Casasimarro',
          'Festividad local de Castillejo del Romeral',
          'Festividad local de Colliga',
          'Festividad local de Villarejo de la Peñuela',
          'Festividad local de Campillo de Dueñas',
          'Festividad local de El Cubillo de Uceda',
          'Festividad local de Jirueque',
          'Festividad local de Lupiana',
          'Festividad local de Matarrubia',
          'Festividad local de Rillo de Gallo',
          'Festividad local de El Viso de San Juan',
          'Festividad local de Navahermosa',
        ],
        pc_ocupacion: 45.0,
        room: {
          double: {
            newPrice: 120.04,
            priceBefore: 109.87,
            priceNow: 115.83,
          },
          single: {
            newPrice: 91.27,
            priceBefore: 88.74,
            priceNow: 86.1,
          },
          suite: {
            newPrice: 123.96,
            priceBefore: 113.54,
            priceNow: 111.07,
          },
        },
      },
      {
        date: '2025-08-25',
        festivos: ['Festividad local de Rillo de Gallo'],
        pc_ocupacion: 43.0,
        room: {
          double: {
            newPrice: 118.39,
            priceBefore: 112.82,
            priceNow: 121.61,
          },
          single: {
            newPrice: 89.63,
            priceBefore: 82.01,
            priceNow: 88.12,
          },
          suite: {
            newPrice: 135.49,
            priceBefore: 132.56,
            priceNow: 137.79,
          },
        },
      },
      {
        date: '2025-08-26',
        festivos: [
          'Festividad local de Carcelén',
          'Festividad local de Ballesteros de Calatrava',
          'Festividad local de Navalpino',
          'Festividad local de Alcohujate',
          'Festividad local de Belmonte',
          'Festividad local de Casas de Fernando Alonso',
          'Festividad local de Casas de Haro',
          'Festividad local de Castillejo del Romeral',
          'Festividad local de Fresneda de Altarejos',
          'Festividad local de Gascueña',
          'Festividad local de Piqueras del Castillo',
          'Festividad local de Pozorrubio de Santiago',
          'Festividad local de Ucles',
          'Festividad local de Vellisca',
          'Festividad local de Checa',
          'Festividad local de Cubillejo del Sitio',
          'Festividad local de Hita',
          'Festividad local de Sacecorbo',
          'Festividad local de Valfermoso de Tajuña',
          'Festividad local de Alcañizo',
          'Festividad local de Aldeanueva de Barbarroya',
          'Festividad local de Aldeanueva de San Bartolomé',
          'Festividad local de Añover de Tajo',
          'Festividad local de Arisgotas',
          'Festividad local de Cabañas de Yepes',
          'Festividad local de Corral de Almaguer',
          'Festividad local de Hormigos',
          'Festividad local de Huerta de Valdecarábanos',
          'Festividad local de Mesegar de Tajo',
          'Festividad local de Orgaz',
          'Festividad local de San Bartolomé de las Abiertas',
          'Festividad local de Santa Cruz de la Zarza',
          'Festividad local de Torrecilla de La Jara',
        ],
        pc_ocupacion: 59.0,
        room: {
          double: {
            newPrice: 149.3,
            priceBefore: 143.45,
            priceNow: 151.24,
          },
          single: {
            newPrice: 112.82,
            priceBefore: 105.62,
            priceNow: 100.81,
          },
          suite: {
            newPrice: 141.33,
            priceBefore: 133.2,
            priceNow: 135.72,
          },
        },
      },
      {
        date: '2025-08-27',
        festivos: [
          'Festividad local de Paredes de Sigüenza',
          'Festividad local de Valfermoso de Tajuña',
          'Festividad local de Albarreal de Tajo',
          'Festividad local de Huerta de Valdecarábanos',
        ],
        pc_ocupacion: 33.0,
        room: {
          double: {
            newPrice: 77.26,
            priceBefore: 72.51,
            priceNow: 79.47,
          },
          single: {
            newPrice: 60.18,
            priceBefore: 59.9,
            priceNow: 61.73,
          },
          suite: {
            newPrice: 133.27,
            priceBefore: 131.62,
            priceNow: 134.21,
          },
        },
      },
      {
        date: '2025-08-28',
        festivos: [
          'Festividad local de Casas Ibáñez',
          'Festividad local de Peñas de San Pedro',
          'Festividad local de Fernán Caballero',
          'Festividad local de Villahermosa',
          'Festividad local de Castillejo de Iniesta',
          'Festividad local de Collados',
          'Festividad local de Mota del Cuervo',
          'Festividad local de Zafrilla',
          'Festividad local de Atanzón',
          'Festividad local de Fuentelencina',
          'Festividad local de Hombrados',
          'Festividad local de Membrillera',
          'Festividad local de Albarreal de Tajo',
          'Festividad local de El Toboso',
        ],
        pc_ocupacion: 54.0,
        room: {
          double: {
            newPrice: 79.76,
            priceBefore: 80.36,
            priceNow: 84.47,
          },
          single: {
            newPrice: 87.38,
            priceBefore: 90.11,
            priceNow: 92.68,
          },
          suite: {
            newPrice: 178.92,
            priceBefore: 180.11,
            priceNow: 189.21,
          },
        },
      },
      {
        date: '2025-08-29',
        festivos: [
          'Festividad local de Puebla de Don Rodrigo',
          'Festividad local de Aranzueque',
          'Festividad local de Espinosa de Henares',
          'Festividad local de La Yunta',
          'Festividad local de Millana',
          'Festividad local de Borox',
        ],
        pc_ocupacion: 49.0,
        room: {
          double: {
            newPrice: 110.45,
            priceBefore: 101.43,
            priceNow: 111.0,
          },
          single: {
            newPrice: 131.25,
            priceBefore: 125.49,
            priceNow: 119.89,
          },
          suite: {
            newPrice: 179.73,
            priceBefore: 165.32,
            priceNow: 173.38,
          },
        },
      },
      {
        date: '2025-08-30',
        festivos: [
          'Festividad local de Huelamo',
          'Festividad local de Pajaron',
          'Festividad local de Espinosa de Henares',
          'Festividad local de Pinilla de Jadraque 5 febrero y',
          'Festividad local de Quer',
          'Festividad local de Saelices de la Sal',
          'Festividad local de Solanillos del Extremo',
          'Festividad local de Torrecuadradilla',
          'Festividad local de Valdeaveruelo',
          'Festividad local de Villares de Jadraque',
          'Festividad local de Viñuelas',
          'Festividad local de Aldea en Cabo',
          'Festividad local de Borox',
          'Festividad local de Las Ventas de San Julian',
          'Festividad local de Torrico',
          'Festividad local de Villaluenga de la Sagra',
        ],
        pc_ocupacion: 47.0,
        room: {
          double: {
            newPrice: 108.52,
            priceBefore: 111.5,
            priceNow: 115.87,
          },
          single: {
            newPrice: 75.41,
            priceBefore: 73.44,
            priceNow: 79.59,
          },
          suite: {
            newPrice: 115.74,
            priceBefore: 109.87,
            priceNow: 114.38,
          },
        },
      },
      {
        date: '2025-08-31',
        festivos: [
          'Festividad local de Tomelloso',
          'Festividad local de Ciruelos del Pinar',
          'Festividad local de Illescas',
          'Festividad local de Villarejo de Montalbán',
        ],
        pc_ocupacion: 35.0,
        room: {
          double: {
            newPrice: 81.22,
            priceBefore: 78.97,
            priceNow: 82.5,
          },
          single: {
            newPrice: 101.54,
            priceBefore: 104.52,
            priceNow: 106.23,
          },
          suite: {
            newPrice: 104.15,
            priceBefore: 96.51,
            priceNow: 95.8,
          },
        },
      },
      {
        date: '2025-09-01',
        festivos: [
          'Festividad local de Cuevas de Velasco',
          'Festividad local de Villar del Maestre',
        ],
        pc_ocupacion: 47.0,
        room: {
          double: {
            newPrice: 122.03,
            priceBefore: 114.82,
            priceNow: 120.21,
          },
          single: {
            newPrice: 80.39,
            priceBefore: 79.47,
            priceNow: 87.22,
          },
          suite: {
            newPrice: 140.74,
            priceBefore: 141.75,
            priceNow: 138.34,
          },
        },
      },
      {
        date: '2025-09-02',
        festivos: [
          'Festividad local de Almansa',
          'Festividad local de Argamasilla de Alba',
          'Festividad local de Daimiel',
          'Festividad local de Arrancacepas',
          'Festividad local de Cueva del Hierro',
          'Festividad local de Las Pedroñeras',
          'Festividad local de Motilla del Palancar',
          'Festividad local de Pajaroncillo',
          'Festividad local de Valhermoso de la Fuente',
          'Festividad local de Villalba del Rey',
          'Festividad local de Caspueñas',
          'Festividad local de El Casar',
          'Festividad local de Molina de Aragón',
          'Festividad local de Solanillos del Extremo',
          'Festividad local de Burguillos de Toledo',
          'Festividad local de Hontanar',
          'Festividad local de La Fresneda de la Jara',
          'Festividad local de La Puebla de Almoradiel',
          'Festividad local de Las Ventas de San Julian',
          'Festividad local de Torrico',
        ],
        pc_ocupacion: 51.0,
        room: {
          double: {
            newPrice: 111.73,
            priceBefore: 110.54,
            priceNow: 107.53,
          },
          single: {
            newPrice: 102.47,
            priceBefore: 94.74,
            priceNow: 99.29,
          },
          suite: {
            newPrice: 183.02,
            priceBefore: 191.14,
            priceNow: 202.14,
          },
        },
      },
      {
        date: '2025-09-03',
        festivos: [
          'Festividad local de El Robledo',
          'Festividad local de Cueva del Hierro',
        ],
        pc_ocupacion: 39.0,
        room: {
          double: {
            newPrice: 98.61,
            priceBefore: 95.23,
            priceNow: 93.34,
          },
          single: {
            newPrice: 97.95,
            priceBefore: 100.56,
            priceNow: 97.44,
          },
          suite: {
            newPrice: 111.2,
            priceBefore: 102.44,
            priceNow: 108.76,
          },
        },
      },
      {
        date: '2025-09-04',
        festivos: ['Festividad local de Ayna'],
        pc_ocupacion: 51.0,
        room: {
          double: {
            newPrice: 98.85,
            priceBefore: 92.6,
            priceNow: 89.74,
          },
          single: {
            newPrice: 115.93,
            priceBefore: 107.68,
            priceNow: 117.96,
          },
          suite: {
            newPrice: 142.65,
            priceBefore: 144.91,
            priceNow: 146.32,
          },
        },
      },
      {
        date: '2025-09-05',
        festivos: [
          'Festividad local de Alcaraz',
          'Festividad local de Daimiel',
          'Festividad local de Villamayor de Santiago',
          'Festividad local de Corduente',
        ],
        pc_ocupacion: 51.0,
        room: {
          double: {
            newPrice: 100.96,
            priceBefore: 104.46,
            priceNow: 111.53,
          },
          single: {
            newPrice: 111.76,
            priceBefore: 106.92,
            priceNow: 109.2,
          },
          suite: {
            newPrice: 146.31,
            priceBefore: 147.9,
            priceNow: 153.79,
          },
        },
      },
      {
        date: '2025-09-06',
        festivos: [
          'Festividad local de Ayna',
          'Festividad local de Bienservida',
          'Festividad local de Puertollano',
          'Festividad local de Almendros',
          'Festividad local de El Cañavate',
          'Festividad local de Garaballa',
          'Festividad local de Salvacañete',
          'Festividad local de Villalba de la Sierra',
          'Festividad local de Villarejo Sobrehuerta',
          'Festividad local de Alovera',
          'Festividad local de Aragosa',
          'Festividad local de Casa de Uceda',
          'Festividad local de Corduente',
          'Festividad local de Malaguilla',
          'Festividad local de Mandayona',
          'Festividad local de Miralrío',
          'Festividad local de Orea',
          'Festividad local de Pareja',
          'Festividad local de Peñalver',
          'Festividad local de Riba de Saelices',
          'Festividad local de Romanones',
          'Festividad local de Trillo',
          'Festividad local de Carranque',
          'Festividad local de El Bercial',
          'Festividad local de Talavera de la Reina',
          'Festividad local de Talavera la Nueva',
          'Festividad local de Velada',
          'Festividad local de Villarrubia de Santiago',
        ],
        pc_ocupacion: 52.0,
        room: {
          double: {
            newPrice: 108.82,
            priceBefore: 109.53,
            priceNow: 117.69,
          },
          single: {
            newPrice: 120.45,
            priceBefore: 115.19,
            priceNow: 112.8,
          },
          suite: {
            newPrice: 154.26,
            priceBefore: 155.91,
            priceNow: 150.12,
          },
        },
      },
      {
        date: '2025-09-07',
        festivos: [
          'Festividad local de Minaya',
          'Festividad local de Alhambra',
          'Festividad local de Cereceda',
          'Festividad local de Majaelrayo',
          'Festividad local de Puebla de Beleña',
          'Festividad local de El Bercial',
        ],
        pc_ocupacion: 55.0,
        room: {
          double: {
            newPrice: 104.0,
            priceBefore: 109.26,
            priceNow: 112.43,
          },
          single: {
            newPrice: 115.36,
            priceBefore: 112.92,
            priceNow: 123.4,
          },
          suite: {
            newPrice: 184.47,
            priceBefore: 173.67,
            priceNow: 177.23,
          },
        },
      },
      {
        date: '2025-09-08',
        festivos: [
          'Festividad local de Las Majadas',
          'Festividad local de Ribatajada',
          'Festividad local de Algar de Mesa',
          'Festividad local de Alhóndiga',
          'Festividad local de Campillo de Dueñas',
          'Festividad local de Casasana',
          'Festividad local de Cereceda',
          'Festividad local de El Recuenco',
          'Festividad local de Miedes de Atienza',
          'Festividad local de Monasterio',
          'Festividad local de Ventosa',
          'Festividad local de Zaorejas',
        ],
        pc_ocupacion: 51.0,
        room: {
          double: {
            newPrice: 136.22,
            priceBefore: 126.76,
            priceNow: 134.31,
          },
          single: {
            newPrice: 98.74,
            priceBefore: 91.07,
            priceNow: 96.03,
          },
          suite: {
            newPrice: 164.46,
            priceBefore: 164.43,
            priceNow: 175.35,
          },
        },
      },
      {
        date: '2025-09-09',
        festivos: [
          'Festividad local de Albacete',
          'Festividad local de Alborea',
          'Festividad local de Caudete',
          'Festividad local de Fuensanta',
          'Festividad local de Aldea del Rey',
          'Festividad local de Argamasilla de Calatrava',
          'Festividad local de Calzada de Calatrava',
          'Festividad local de Fuencaliente',
          'Festividad local de Llanos del Caudillo',
          'Festividad local de Luciana',
          'Festividad local de Miguelturra',
          'Festividad local de Puebla del Príncipe',
          'Festividad local de Valdemanco del Esteras',
          'Festividad local de Villahermosa',
          'Festividad local de Albendea',
          'Festividad local de Alcohujate',
          'Festividad local de Almendros',
          'Festividad local de Altarejos',
          'Festividad local de Barajas de Melo',
          'Festividad local de Belmonte',
          'Festividad local de Buendía',
          'Festividad local de Campillo de Altobuey',
          'Festividad local de Canalejas del Arroyo',
          'Festividad local de Cañada del Hoyo',
          'Festividad local de Cañete',
          'Festividad local de Cañizares',
          'Festividad local de Culebras',
          'Festividad local de El Cañavate',
          'Festividad local de El Herrumblar',
          'Festividad local de Horcajada de la Torre',
          'Festividad local de Huerta de la Obispalía',
          'Festividad local de La Peraleja',
          'Festividad local de Lagunaseca',
          'Festividad local de Loranca del Campo',
          'Festividad local de Puebla de Almenara',
          'Festividad local de Ribagorda',
          'Festividad local de Santa Maria del Campo Rus',
          'Festividad local de Tarancon',
          'Festividad local de Torrubia del Campo',
          'Festividad local de Vega del Codorno',
          'Festividad local de Villanueva de Guadamejud',
          'Festividad local de Villar de Cañas',
          'Festividad local de Villar de Olalla',
          'Festividad local de Villar del Horno',
          'Festividad local de Villarejo de Fuentes',
          'Festividad local de Balbacil',
          'Festividad local de Casasana',
          'Festividad local de Clares',
          'Festividad local de Cobeta',
          'Festividad local de Codes',
          'Festividad local de Condemios de Abajo',
          'Festividad local de Hontoba',
          'Festividad local de Horche',
          'Festividad local de Illana',
          'Festividad local de Maranchón',
          'Festividad local de Monasterio',
          'Festividad local de Pálmaces de Jadraque',
          'Festividad local de Pareja',
          'Festividad local de Peñalver',
          'Festividad local de Tendilla',
          'Festividad local de Torija',
          'Festividad local de Tortuera',
          'Festividad local de Trillo',
          'Festividad local de Turmiel',
          'Festividad local de Ventosa',
          'Festividad local de Yebra',
          'Festividad local de Alcabón',
          'Festividad local de Arisgotas',
          'Festividad local de Carriches',
          'Festividad local de Castillo de Bayuela',
          'Festividad local de Cebolla',
          'Festividad local de Cedillo del Condado',
          'Festividad local de Consuegra',
          'Festividad local de Domingo Pérez',
          'Festividad local de Espinoso del Rey',
          'Festividad local de Gamonal',
          'Festividad local de Las Hunfrías',
          'Festividad local de Los Navalucillos',
          'Festividad local de Lillo',
          'Festividad local de Méntrida',
          'Festividad local de Miguel Esteban',
          'Festividad local de Mocejón',
          'Festividad local de Navamorcuende',
          'Festividad local de Ocaña',
          'Festividad local de Orgaz',
          'Festividad local de Oropesa y Corchuela',
          'Festividad local de Paredes de Escalona',
          'Festividad local de Pepino',
          'Festividad local de Recas',
          'Festividad local de Sonseca',
          'Festividad local de Turleque',
          'Festividad local de Villamiel',
          'Festividad local de Villanueva de Bogas',
          'Festividad local de Villarrubia de Santiago',
          'Festividad local de Villaseca de la Sagra',
        ],
        pc_ocupacion: 38.0,
        room: {
          double: {
            newPrice: 133.04,
            priceBefore: 138.92,
            priceNow: 143.51,
          },
          single: {
            newPrice: 72.9,
            priceBefore: 74.25,
            priceNow: 72.6,
          },
          suite: {
            newPrice: 102.63,
            priceBefore: 96.18,
            priceNow: 96.93,
          },
        },
      },
      {
        date: '2025-09-10',
        festivos: [
          'Festividad local de Barajas de Melo',
          'Festividad local de Cañizares',
          'Festividad local de Vega del Codorno',
          'Festividad local de Almonacid de Zorita',
          'Festividad local de Horche',
          'Festividad local de Pálmaces de Jadraque',
          'Festividad local de Tendilla',
          'Festividad local de Torija',
          'Festividad local de Tortuera',
          'Festividad local de Valdelcubo',
          'Festividad local de Los Navalucillos',
          'Festividad local de Ocaña',
        ],
        pc_ocupacion: 50.0,
        room: {
          double: {
            newPrice: 101.47,
            priceBefore: 103.83,
            priceNow: 103.57,
          },
          single: {
            newPrice: 105.16,
            priceBefore: 107.99,
            priceNow: 111.04,
          },
          suite: {
            newPrice: 174.45,
            priceBefore: 166.38,
            priceNow: 179.98,
          },
        },
      },
      {
        date: '2025-09-11',
        festivos: [
          'Festividad local de Chumillas',
          'Festividad local de Hontanaya',
          'Festividad local de Pinarejo',
          'Festividad local de Almonacid de Zorita',
          'Festividad local de Almendral de La Cañada',
          'Festividad local de Retamoso de la Jara',
          'Festividad local de Villa de Don Fadrique',
        ],
        pc_ocupacion: 50.0,
        room: {
          double: {
            newPrice: 121.62,
            priceBefore: 122.17,
            priceNow: 130.57,
          },
          single: {
            newPrice: 76.27,
            priceBefore: 72.09,
            priceNow: 77.97,
          },
          suite: {
            newPrice: 173.17,
            priceBefore: 172.44,
            priceNow: 186.41,
          },
        },
      },
      {
        date: '2025-09-12',
        festivos: [
          'Festividad local de El Pozuelo',
          'Festividad local de Priego',
          'Festividad local de Guadalajara',
          'Festividad local de Calera y Chozas',
          'Festividad local de Fuensalida',
          'Festividad local de Villatobas',
          'Festividad local de Yuncos',
        ],
        pc_ocupacion: 55.0,
        room: {
          double: {
            newPrice: 149.04,
            priceBefore: 142.62,
            priceNow: 137.22,
          },
          single: {
            newPrice: 78.37,
            priceBefore: 71.56,
            priceNow: 68.31,
          },
          suite: {
            newPrice: 160.01,
            priceBefore: 163.59,
            priceNow: 161.73,
          },
        },
      },
      {
        date: '2025-09-13',
        festivos: [
          'Festividad local de Cañada de Calatrava',
          'Festividad local de Castellar de Santiago',
          'Festividad local de Fuenllana',
          'Festividad local de Montiel',
          'Festividad local de Saceruela',
          'Festividad local de Arcas',
          'Festividad local de Belmontejo',
          'Festividad local de Chillaron de Cuenca',
          'Festividad local de Horcajo De Santiago',
          'Festividad local de Leganiel',
          'Festividad local de Los Valdecolmenas',
          'Festividad local de Mariana',
          'Festividad local de Montalbanejo',
          'Festividad local de Portilla',
          'Festividad local de Priego',
          'Festividad local de Saceda-Trasierra',
          'Festividad local de Santa Maria del Val',
          'Festividad local de Valdeolivas',
          'Festividad local de Chillaron del Rey',
          'Festividad local de Chiloeches',
          'Festividad local de Cifuentes',
          'Festividad local de Guadalajara',
          'Festividad local de Hueva',
          'Festividad local de Luzón',
          'Festividad local de Moratilla de los Meleros',
          'Festividad local de Trijueque',
          'Festividad local de Villanueva de Alcorón',
          'Festividad local de Calera y Chozas',
          'Festividad local de Calzada de Oropesa',
          'Festividad local de Casarrubios del Monte',
          'Festividad local de Dosbarrios',
          'Festividad local de Escalonilla',
          'Festividad local de Fuensalida',
          'Festividad local de Hinojosa de San Vicente',
          'Festividad local de Los Cerralbos',
          'Festividad local de Los Navalmorales',
          'Festividad local de Los Yébenes',
          'Festividad local de Otero',
          'Festividad local de Real de San Vicente',
          'Festividad local de Rielves',
          'Festividad local de Sevilleja de la Jara',
          'Festividad local de Villatobas',
          'Festividad local de Yepes',
          'Festividad local de Yuncos',
        ],
        pc_ocupacion: 49.0,
        room: {
          double: {
            newPrice: 94.84,
            priceBefore: 93.59,
            priceNow: 99.21,
          },
          single: {
            newPrice: 72.07,
            priceBefore: 71.62,
            priceNow: 68.46,
          },
          suite: {
            newPrice: 190.12,
            priceBefore: 199.22,
            priceNow: 213.2,
          },
        },
      },
      {
        date: '2025-09-14',
        festivos: [
          'Festividad local de Alpera',
          'Festividad local de Villa de Vés',
          'Festividad local de Almadén',
          'Festividad local de Bolaños de Calatrava',
          'Festividad local de Cózar',
          'Festividad local de Malagón',
          'Festividad local de Manzanares',
          'Festividad local de Alarcón',
          'Festividad local de Cuevas de Velasco',
          'Festividad local de Fuentelespino de Moya',
          'Festividad local de La Alberca de Zancara',
          'Festividad local de La Parra de las Vegas',
          'Festividad local de La Ventosa',
          'Festividad local de Minglanilla',
          'Festividad local de Nohales',
          'Festividad local de Villar del Maestre',
          'Festividad local de Alaminos',
          'Festividad local de Argecilla',
          'Festividad local de Atienza',
          'Festividad local de Baños de Tajo',
          'Festividad local de Centenera',
          'Festividad local de Ciruelas',
          'Festividad local de Fuentelahiguera de Albatages',
          'Festividad local de Hueva',
          'Festividad local de Irueste',
          'Festividad local de Jadraque',
          'Festividad local de Loranca de Tajuña',
          'Festividad local de Mondéjar',
          'Festividad local de Saelices de la Sal',
          'Festividad local de Salmerón',
          'Festividad local de Val de San Garcia',
          'Festividad local de Valdeconcha',
          'Festividad local de Valdenuño Fernandez',
          'Festividad local de Valhermoso',
          'Festividad local de Carmena',
          'Festividad local de Garciotum',
          'Festividad local de Guadamur',
          'Festividad local de La Pueblanueva',
          'Festividad local de Las Herencias',
          'Festividad local de Lucillos',
          'Festividad local de Madridejos',
          'Festividad local de Pelahustán',
          'Festividad local de Segurilla',
        ],
        pc_ocupacion: 49.0,
        room: {
          double: {
            newPrice: 109.37,
            priceBefore: 99.99,
            priceNow: 109.19,
          },
          single: {
            newPrice: 110.23,
            priceBefore: 101.79,
            priceNow: 110.33,
          },
          suite: {
            newPrice: 162.05,
            priceBefore: 164.29,
            priceNow: 165.08,
          },
        },
      },
      {
        date: '2025-09-15',
        festivos: [
          'Festividad local de Villar de Cañas',
          'Festividad local de Anquela del Ducado',
          'Festividad local de Baños de Tajo',
          'Festividad local de Valhermoso',
          'Festividad local de Zarzuela de Galve',
        ],
        pc_ocupacion: 58.0,
        room: {
          double: {
            newPrice: 142.21,
            priceBefore: 130.29,
            priceNow: 140.63,
          },
          single: {
            newPrice: 97.61,
            priceBefore: 94.3,
            priceNow: 89.82,
          },
          suite: {
            newPrice: 175.6,
            priceBefore: 169.34,
            priceNow: 170.02,
          },
        },
      },
      {
        date: '2025-09-16',
        festivos: [
          'Festividad local de Elche De La Sierra',
          'Festividad local de Villapalacios',
          'Festividad local de Alcoba de los Montes',
          'Festividad local de Brazatortas',
          'Festividad local de Guadalmez',
          'Festividad local de Arandilla del Arroyo',
          'Festividad local de Beteta',
          'Festividad local de Buenache de Alarcon',
          'Festividad local de Castillejo-Sierra',
          'Festividad local de Fuente de Pedro Naharro',
          'Festividad local de Leganiel',
          'Festividad local de Osa de la Vega',
          'Festividad local de Paracuellos de la Vega',
          'Festividad local de Saelices',
          'Festividad local de Santa Maria del Val',
          'Festividad local de Sisante',
          'Festividad local de Villaescusa de Haro',
          'Festividad local de Villares del Saz',
          'Festividad local de Alcocer',
          'Festividad local de Almoguera',
          'Festividad local de Auñón',
          'Festividad local de Azuqueca de Henares',
          'Festividad local de Budia',
          'Festividad local de Chequilla',
          'Festividad local de Cifuentes',
          'Festividad local de Esplegares',
          'Festividad local de Humanes',
          'Festividad local de Jadraque',
          'Festividad local de Milmarcos',
          'Festividad local de Peñalén',
          'Festividad local de Santiuste',
          'Festividad local de Val de San Garcia',
          'Festividad local de Yunquera de Henares',
          'Festividad local de Zarzuela de Galve',
          'Festividad local de Bargas',
          'Festividad local de Calzada de Oropesa',
          'Festividad local de Chueca',
          'Festividad local de Cobeja',
          'Festividad local de Los Navalmorales',
          'Festividad local de Noez',
          'Festividad local de Numancia de la Sagra',
          'Festividad local de Otero',
          'Festividad local de Real de San Vicente',
          'Festividad local de Santa Ana de Pusa',
          'Festividad local de Villafranca de los Caballeros',
          'Festividad local de Yepes',
          'Festividad local de Yunclillos',
        ],
        pc_ocupacion: 46.0,
        room: {
          double: {
            newPrice: 112.19,
            priceBefore: 116.4,
            priceNow: 118.01,
          },
          single: {
            newPrice: 60.11,
            priceBefore: 54.79,
            priceNow: 52.57,
          },
          suite: {
            newPrice: 155.39,
            priceBefore: 151.73,
            priceNow: 159.74,
          },
        },
      },
      {
        date: '2025-09-17',
        festivos: [
          'Festividad local de Beteta',
          'Festividad local de Budia',
          'Festividad local de Yunquera de Henares',
          'Festividad local de Cobeja',
          'Festividad local de Numancia de la Sagra',
        ],
        pc_ocupacion: 44.0,
        room: {
          double: {
            newPrice: 78.77,
            priceBefore: 76.37,
            priceNow: 79.8,
          },
          single: {
            newPrice: 75.88,
            priceBefore: 74.84,
            priceNow: 75.81,
          },
          suite: {
            newPrice: 170.29,
            priceBefore: 164.99,
            priceNow: 172.7,
          },
        },
      },
      {
        date: '2025-09-18',
        festivos: [
          'Festividad local de Villanueva de los Infantes',
          'Festividad local de Honrubia',
          'Festividad local de Escamilla',
          'Festividad local de Luzaga',
        ],
        pc_ocupacion: 51.0,
        room: {
          double: {
            newPrice: 97.45,
            priceBefore: 96.25,
            priceNow: 103.27,
          },
          single: {
            newPrice: 143.12,
            priceBefore: 134.29,
            priceNow: 129.09,
          },
          suite: {
            newPrice: 188.05,
            priceBefore: 186.3,
            priceNow: 189.12,
          },
        },
      },
      {
        date: '2025-09-19',
        festivos: ['Festividad local de Oropesa y Corchuela'],
        pc_ocupacion: 45.0,
        room: {
          double: {
            newPrice: 89.22,
            priceBefore: 90.12,
            priceNow: 96.65,
          },
          single: {
            newPrice: 91.26,
            priceBefore: 92.62,
            priceNow: 89.55,
          },
          suite: {
            newPrice: 189.19,
            priceBefore: 188.22,
            priceNow: 198.83,
          },
        },
      },
      {
        date: '2025-09-20',
        festivos: [
          'Festividad local de Villaverde del Guadalimar',
          'Festividad local de Valverde de Jucar',
          'Festividad local de Ablanque',
          'Festividad local de Peralejos de las Truchas',
          'Festividad local de Pozo de Guadalajara',
          'Festividad local de Gerindote',
        ],
        pc_ocupacion: 61.0,
        room: {
          double: {
            newPrice: 136.95,
            priceBefore: 138.21,
            priceNow: 133.08,
          },
          single: {
            newPrice: 104.13,
            priceBefore: 104.69,
            priceNow: 111.75,
          },
          suite: {
            newPrice: 188.88,
            priceBefore: 190.66,
            priceNow: 189.65,
          },
        },
      },
      {
        date: '2025-09-21',
        festivos: [
          'Festividad local de La Recueja',
          'Festividad local de Cuenca',
          'Festividad local de La Melgosa',
          'Festividad local de Mohorte',
          'Festividad local de Consuegra',
        ],
        pc_ocupacion: 38.0,
        room: {
          double: {
            newPrice: 102.6,
            priceBefore: 99.94,
            priceNow: 97.35,
          },
          single: {
            newPrice: 85.3,
            priceBefore: 85.36,
            priceNow: 86.08,
          },
          suite: {
            newPrice: 107.85,
            priceBefore: 98.87,
            priceNow: 105.52,
          },
        },
      },
      {
        date: '2025-09-22',
        festivos: [],
        pc_ocupacion: 49.0,
        room: {
          double: {
            newPrice: 126.52,
            priceBefore: 117.94,
            priceNow: 117.9,
          },
          single: {
            newPrice: 91.07,
            priceBefore: 86.75,
            priceNow: 83.04,
          },
          suite: {
            newPrice: 177.01,
            priceBefore: 174.47,
            priceNow: 170.22,
          },
        },
      },
      {
        date: '2025-09-23',
        festivos: [
          'Festividad local de Munera',
          'Festividad local de Navas de Estena',
          'Festividad local de Peralejos de las Truchas',
          'Festividad local de Totanés',
          'Festividad local de Villaminaya',
        ],
        pc_ocupacion: 70.0,
        room: {
          double: {
            newPrice: 132.64,
            priceBefore: 126.25,
            priceNow: 134.68,
          },
          single: {
            newPrice: 134.84,
            priceBefore: 129.25,
            priceNow: 129.08,
          },
          suite: {
            newPrice: 186.21,
            priceBefore: 172.28,
            priceNow: 177.69,
          },
        },
      },
      {
        date: '2025-09-24',
        festivos: [
          'Festividad local de Hoya Gonzalo',
          'Festividad local de Herencia',
          'Festividad local de Los Cortijos',
          'Festividad local de Abánades',
          'Festividad local de Mochales',
          'Festividad local de Torremochuela',
          'Festividad local de Villel de Mesa',
          'Festividad local de Las Ventas con Peña Aguilera',
          'Festividad local de Pantoja',
          'Festividad local de Villaluenga de la Sagra',
        ],
        pc_ocupacion: 53.0,
        room: {
          double: {
            newPrice: 129.2,
            priceBefore: 135.3,
            priceNow: 139.02,
          },
          single: {
            newPrice: 120.74,
            priceBefore: 116.21,
            priceNow: 127.45,
          },
          suite: {
            newPrice: 163.38,
            priceBefore: 158.04,
            priceNow: 158.99,
          },
        },
      },
      {
        date: '2025-09-25',
        festivos: [
          'Festividad local de Los Cortijos',
          'Festividad local de La Guardia',
          'Festividad local de Pantoja',
        ],
        pc_ocupacion: 41.0,
        room: {
          double: {
            newPrice: 75.03,
            priceBefore: 71.27,
            priceNow: 76.12,
          },
          single: {
            newPrice: 79.86,
            priceBefore: 80.72,
            priceNow: 86.32,
          },
          suite: {
            newPrice: 159.49,
            priceBefore: 158.02,
            priceNow: 173.04,
          },
        },
      },
      {
        date: '2025-09-26',
        festivos: [
          'Festividad local de La Guardia',
          'Festividad local de Noves',
        ],
        pc_ocupacion: 49.0,
        room: {
          double: {
            newPrice: 144.8,
            priceBefore: 138.27,
            priceNow: 145.83,
          },
          single: {
            newPrice: 85.87,
            priceBefore: 87.24,
            priceNow: 86.78,
          },
          suite: {
            newPrice: 124.09,
            priceBefore: 114.22,
            priceNow: 121.81,
          },
        },
      },
      {
        date: '2025-09-27',
        festivos: [
          'Festividad local de Abengibre',
          'Festividad local de El Peral',
          'Festividad local de Palomares del Campo',
          'Festividad local de Albalate de Zorita',
          'Festividad local de Heras de Ayuso',
          'Festividad local de Villaseca de Uceda',
          'Festividad local de Alberche del Caudillo',
          'Festividad local de Buenaventura',
          'Festividad local de Noves',
          'Festividad local de Torrijos',
        ],
        pc_ocupacion: 54.0,
        room: {
          double: {
            newPrice: 93.56,
            priceBefore: 90.37,
            priceNow: 87.01,
          },
          single: {
            newPrice: 102.26,
            priceBefore: 94.44,
            priceNow: 90.27,
          },
          suite: {
            newPrice: 206.25,
            priceBefore: 187.64,
            priceNow: 197.66,
          },
        },
      },
      {
        date: '2025-09-28',
        festivos: [],
        pc_ocupacion: 45.0,
        room: {
          double: {
            newPrice: 94.53,
            priceBefore: 88.92,
            priceNow: 96.83,
          },
          single: {
            newPrice: 98.15,
            priceBefore: 94.09,
            priceNow: 97.1,
          },
          suite: {
            newPrice: 148.64,
            priceBefore: 138.36,
            priceNow: 140.56,
          },
        },
      },
      {
        date: '2025-09-29',
        festivos: [
          'Festividad local de Fresneda de la Sierra',
          'Festividad local de Huelves',
          'Festividad local de Tondos',
          'Festividad local de Valparaiso de Arriba',
          'Festividad local de Anchuela del Campo',
        ],
        pc_ocupacion: 53.0,
        room: {
          double: {
            newPrice: 111.74,
            priceBefore: 115.85,
            priceNow: 120.52,
          },
          single: {
            newPrice: 100.61,
            priceBefore: 99.5,
            priceNow: 100.8,
          },
          suite: {
            newPrice: 181.01,
            priceBefore: 186.63,
            priceNow: 180.98,
          },
        },
      },
      {
        date: '2025-09-30',
        festivos: [
          'Festividad local de Chinchilla',
          'Festividad local de Corralrubio',
          'Festividad local de El Ballestero',
          'Festividad local de Ossa de Montiel',
          'Festividad local de Paterna del Madera',
          'Festividad local de Peñascosa',
          'Festividad local de Anchuras',
          'Festividad local de Montalbo',
          'Festividad local de Narboneta',
          'Festividad local de Tragacete',
          'Festividad local de Castejón de Henares',
          'Festividad local de Driebes',
          'Festividad local de Huertahernando',
          'Festividad local de Yebes',
          'Festividad local de Robledo del Mazo',
          'Festividad local de Torrecilla de La Jara',
          'Festividad local de Urda',
        ],
        pc_ocupacion: 54.0,
        room: {
          double: {
            newPrice: 97.38,
            priceBefore: 98.13,
            priceNow: 103.75,
          },
          single: {
            newPrice: 137.98,
            priceBefore: 131.79,
            priceNow: 131.94,
          },
          suite: {
            newPrice: 190.2,
            priceBefore: 186.08,
            priceNow: 186.54,
          },
        },
      },
      {
        date: '2025-10-01',
        festivos: ['Festividad local de Montalbo', 'Festividad local de Embid'],
        pc_ocupacion: 41.0,
        room: {
          double: {
            newPrice: 91.78,
            priceBefore: 89.83,
            priceNow: 91.05,
          },
          single: {
            newPrice: 75.6,
            priceBefore: 78.65,
            priceNow: 81.28,
          },
          suite: {
            newPrice: 145.12,
            priceBefore: 140.36,
            priceNow: 135.9,
          },
        },
      },
      {
        date: '2025-10-02',
        festivos: [
          'Festividad local de Arguisuelas',
          'Festividad local de La Cierva',
          'Festividad local de Semillas',
          'Festividad local de Yélamos de Abajo',
        ],
        pc_ocupacion: 41.0,
        room: {
          double: {
            newPrice: 108.45,
            priceBefore: 106.1,
            priceNow: 110.18,
          },
          single: {
            newPrice: 109.85,
            priceBefore: 101.44,
            priceNow: 100.48,
          },
          suite: {
            newPrice: 127.61,
            priceBefore: 125.54,
            priceNow: 124.73,
          },
        },
      },
      {
        date: '2025-10-03',
        festivos: ['Festividad local de Yélamos de Abajo'],
        pc_ocupacion: 48.0,
        room: {
          double: {
            newPrice: 108.07,
            priceBefore: 111.46,
            priceNow: 109.53,
          },
          single: {
            newPrice: 75.32,
            priceBefore: 77.8,
            priceNow: 78.06,
          },
          suite: {
            newPrice: 168.32,
            priceBefore: 172.25,
            priceNow: 166.78,
          },
        },
      },
      {
        date: '2025-10-04',
        festivos: [
          'Festividad local de El Salobral',
          'Festividad local de Vianos',
          'Festividad local de Masegosa',
          'Festividad local de Robledo de Corpes',
          'Festividad local de Sotodosos',
        ],
        pc_ocupacion: 45.0,
        room: {
          double: {
            newPrice: 111.11,
            priceBefore: 105.28,
            priceNow: 107.0,
          },
          single: {
            newPrice: 100.95,
            priceBefore: 102.28,
            priceNow: 105.24,
          },
          suite: {
            newPrice: 158.29,
            priceBefore: 152.2,
            priceNow: 164.3,
          },
        },
      },
      {
        date: '2025-10-05',
        festivos: [
          'Festividad local de Salinas del Manzano',
          'Festividad local de El Pedregal',
        ],
        pc_ocupacion: 44.0,
        room: {
          double: {
            newPrice: 83.95,
            priceBefore: 83.22,
            priceNow: 84.3,
          },
          single: {
            newPrice: 102.39,
            priceBefore: 95.33,
            priceNow: 91.9,
          },
          suite: {
            newPrice: 176.09,
            priceBefore: 164.78,
            priceNow: 180.69,
          },
        },
      },
      {
        date: '2025-10-06',
        festivos: [],
        pc_ocupacion: 41.0,
        room: {
          double: {
            newPrice: 107.56,
            priceBefore: 103.07,
            priceNow: 101.25,
          },
          single: {
            newPrice: 66.05,
            priceBefore: 60.32,
            priceNow: 57.35,
          },
          suite: {
            newPrice: 158.02,
            priceBefore: 164.02,
            priceNow: 165.06,
          },
        },
      },
      {
        date: '2025-10-07',
        festivos: [
          'Festividad local de Bonete',
          'Festividad local de Férez',
          'Festividad local de Masegoso',
          'Festividad local de Povedilla',
          'Festividad local de Tiriez',
          'Festividad local de Valdeganga',
          'Festividad local de Almadenejos',
          'Festividad local de Almedina',
          'Festividad local de Santa Cruz de Cáñamos',
          'Festividad local de Villanueva de San Carlos',
          'Festividad local de Buenache de la Sierra',
          'Festividad local de El Picazo',
          'Festividad local de Fuentes',
          'Festividad local de Fuertescusa',
          'Festividad local de Las Valeras',
          'Festividad local de Masegosa',
          'Festividad local de Pineda de Gigüela',
          'Festividad local de Valdetortola',
          'Festividad local de Valparaiso de Abajo',
          'Festividad local de Zafra de Zancara',
          'Festividad local de Zarzuela',
          'Festividad local de Anchuela del Pedregal',
          'Festividad local de Anguita',
          'Festividad local de Driebes',
          'Festividad local de El Sotillo',
          'Festividad local de Málaga del Fresno',
          'Festividad local de Saúca',
          'Festividad local de Setiles',
          'Festividad local de Cabañas de la Sagra',
          'Festividad local de Cardiel de los Montes',
          'Festividad local de Lagartera',
          'Festividad local de Las Vegas y San Antonio',
          'Festividad local de Montearagón',
          'Festividad local de Olias del Rey',
          'Festividad local de Ontígola',
        ],
        pc_ocupacion: 41.0,
        room: {
          double: {
            newPrice: 94.22,
            priceBefore: 86.2,
            priceNow: 85.34,
          },
          single: {
            newPrice: 78.22,
            priceBefore: 82.24,
            priceNow: 87.29,
          },
          suite: {
            newPrice: 174.37,
            priceBefore: 167.06,
            priceNow: 183.54,
          },
        },
      },
      {
        date: '2025-10-08',
        festivos: [
          'Festividad local de Férez',
          'Festividad local de Valdeganga',
          'Festividad local de Valenzuela de Calatrava',
          'Festividad local de Sotos',
          'Festividad local de Setiles',
          'Festividad local de Olias del Rey',
        ],
        pc_ocupacion: 48.0,
        room: {
          double: {
            newPrice: 126.14,
            priceBefore: 115.99,
            priceNow: 119.09,
          },
          single: {
            newPrice: 91.81,
            priceBefore: 91.49,
            priceNow: 99.09,
          },
          suite: {
            newPrice: 176.42,
            priceBefore: 160.9,
            priceNow: 172.19,
          },
        },
      },
      {
        date: '2025-10-09',
        festivos: [
          'Festividad local de Fuenteálamo',
          'Festividad local de Villar del Humo',
          'Festividad local de Ujados',
        ],
        pc_ocupacion: 41.0,
        room: {
          double: {
            newPrice: 131.19,
            priceBefore: 119.88,
            priceNow: 115.73,
          },
          single: {
            newPrice: 78.52,
            priceBefore: 73.97,
            priceNow: 73.8,
          },
          suite: {
            newPrice: 129.06,
            priceBefore: 119.74,
            priceNow: 118.57,
          },
        },
      },
      {
        date: '2025-10-10',
        festivos: [
          'Festividad local de Cantalojas',
          'Festividad local de Torremocha del Pinar',
          'Festividad local de Portillo de Toledo',
        ],
        pc_ocupacion: 47.0,
        room: {
          double: {
            newPrice: 133.21,
            priceBefore: 123.73,
            priceNow: 123.81,
          },
          single: {
            newPrice: 85.98,
            priceBefore: 88.29,
            priceNow: 88.38,
          },
          suite: {
            newPrice: 132.36,
            priceBefore: 138.2,
            priceNow: 131.62,
          },
        },
      },
      {
        date: '2025-10-11',
        festivos: [
          'Festividad local de Bormate',
          'Festividad local de La Herrera',
          'Festividad local de Cardenete',
          'Festividad local de Carrascosa de la Sierra',
          'Festividad local de Henarejos',
          'Festividad local de Cantalojas',
          'Festividad local de Los Alares',
          'Festividad local de Pulgar',
          'Festividad local de Robledo del Buey',
        ],
        pc_ocupacion: 50.0,
        room: {
          double: {
            newPrice: 127.79,
            priceBefore: 122.56,
            priceNow: 131.19,
          },
          single: {
            newPrice: 94.5,
            priceBefore: 94.51,
            priceNow: 95.16,
          },
          suite: {
            newPrice: 162.39,
            priceBefore: 155.08,
            priceNow: 170.37,
          },
        },
      },
      {
        date: '2025-10-12',
        festivos: [],
        pc_ocupacion: 49.0,
        room: {
          double: {
            newPrice: 119.54,
            priceBefore: 123.2,
            priceNow: 127.23,
          },
          single: {
            newPrice: 89.58,
            priceBefore: 86.62,
            priceNow: 86.62,
          },
          suite: {
            newPrice: 146.85,
            priceBefore: 152.79,
            priceNow: 148.16,
          },
        },
      },
      {
        date: '2025-10-13',
        festivos: ['Festividad local de Huelamo'],
        pc_ocupacion: 51.0,
        room: {
          double: {
            newPrice: 161.94,
            priceBefore: 163.77,
            priceNow: 166.98,
          },
          single: {
            newPrice: 60.77,
            priceBefore: 60.07,
            priceNow: 63.14,
          },
          suite: {
            newPrice: 180.8,
            priceBefore: 188.05,
            priceNow: 181.09,
          },
        },
      },
      {
        date: '2025-10-14',
        festivos: [
          'Festividad local de Molinicos',
          'Festividad local de Belinchon',
        ],
        pc_ocupacion: 50.0,
        room: {
          double: {
            newPrice: 121.66,
            priceBefore: 112.98,
            priceNow: 123.87,
          },
          single: {
            newPrice: 83.76,
            priceBefore: 86.24,
            priceNow: 86.42,
          },
          suite: {
            newPrice: 157.21,
            priceBefore: 157.15,
            priceNow: 168.98,
          },
        },
      },
      {
        date: '2025-10-15',
        festivos: [
          'Festividad local de Malagón',
          'Festividad local de Jabaga',
          'Festividad local de Villanueva de la Jara',
          'Festividad local de Pastrana',
        ],
        pc_ocupacion: 41.0,
        room: {
          double: {
            newPrice: 128.33,
            priceBefore: 131.22,
            priceNow: 138.56,
          },
          single: {
            newPrice: 87.16,
            priceBefore: 88.95,
            priceNow: 90.6,
          },
          suite: {
            newPrice: 88.31,
            priceBefore: 89.97,
            priceNow: 93.72,
          },
        },
      },
      {
        date: '2025-10-16',
        festivos: [],
        pc_ocupacion: 30.0,
        room: {
          double: {
            newPrice: 69.32,
            priceBefore: 69.07,
            priceNow: 69.27,
          },
          single: {
            newPrice: 73.88,
            priceBefore: 77.61,
            priceNow: 76.97,
          },
          suite: {
            newPrice: 112.46,
            priceBefore: 110.19,
            priceNow: 113.69,
          },
        },
      },
      {
        date: '2025-10-17',
        festivos: ['Festividad local de Cívica'],
        pc_ocupacion: 46.0,
        room: {
          double: {
            newPrice: 117.5,
            priceBefore: 108.67,
            priceNow: 106.55,
          },
          single: {
            newPrice: 115.67,
            priceBefore: 108.12,
            priceNow: 114.3,
          },
          suite: {
            newPrice: 162.85,
            priceBefore: 154.24,
            priceNow: 149.32,
          },
        },
      },
      {
        date: '2025-10-18',
        festivos: [],
        pc_ocupacion: 37.0,
        room: {
          double: {
            newPrice: 73.66,
            priceBefore: 75.85,
            priceNow: 72.25,
          },
          single: {
            newPrice: 91.21,
            priceBefore: 85.33,
            priceNow: 84.84,
          },
          suite: {
            newPrice: 135.51,
            priceBefore: 128.17,
            priceNow: 122.25,
          },
        },
      },
      {
        date: '2025-10-19',
        festivos: [],
        pc_ocupacion: 42.0,
        room: {
          double: {
            newPrice: 93.49,
            priceBefore: 86.82,
            priceNow: 87.38,
          },
          single: {
            newPrice: 101.01,
            priceBefore: 97.52,
            priceNow: 92.98,
          },
          suite: {
            newPrice: 124.71,
            priceBefore: 127.97,
            priceNow: 132.57,
          },
        },
      },
      {
        date: '2025-10-20',
        festivos: [],
        pc_ocupacion: 49.0,
        room: {
          double: {
            newPrice: 114.16,
            priceBefore: 108.36,
            priceNow: 106.31,
          },
          single: {
            newPrice: 87.0,
            priceBefore: 87.45,
            priceNow: 93.9,
          },
          suite: {
            newPrice: 153.71,
            priceBefore: 151.58,
            priceNow: 156.43,
          },
        },
      },
      {
        date: '2025-10-21',
        festivos: ['Festividad local de Brihuega'],
        pc_ocupacion: 43.0,
        room: {
          double: {
            newPrice: 107.84,
            priceBefore: 103.79,
            priceNow: 112.98,
          },
          single: {
            newPrice: 65.27,
            priceBefore: 59.53,
            priceNow: 61.91,
          },
          suite: {
            newPrice: 198.78,
            priceBefore: 192.11,
            priceNow: 203.12,
          },
        },
      },
      {
        date: '2025-10-22',
        festivos: [],
        pc_ocupacion: 38.0,
        room: {
          double: {
            newPrice: 96.24,
            priceBefore: 92.52,
            priceNow: 97.9,
          },
          single: {
            newPrice: 95.88,
            priceBefore: 87.43,
            priceNow: 84.41,
          },
          suite: {
            newPrice: 126.24,
            priceBefore: 132.48,
            priceNow: 145.16,
          },
        },
      },
      {
        date: '2025-10-23',
        festivos: [],
        pc_ocupacion: 50.0,
        room: {
          double: {
            newPrice: 89.63,
            priceBefore: 92.39,
            priceNow: 100.25,
          },
          single: {
            newPrice: 85.13,
            priceBefore: 89.45,
            priceNow: 86.44,
          },
          suite: {
            newPrice: 161.64,
            priceBefore: 167.5,
            priceNow: 183.39,
          },
        },
      },
      {
        date: '2025-10-24',
        festivos: ['Festividad local de Hellín'],
        pc_ocupacion: 57.0,
        room: {
          double: {
            newPrice: 148.6,
            priceBefore: 143.4,
            priceNow: 143.03,
          },
          single: {
            newPrice: 59.91,
            priceBefore: 59.58,
            priceNow: 62.56,
          },
          suite: {
            newPrice: 178.58,
            priceBefore: 176.49,
            priceNow: 191.45,
          },
        },
      },
      {
        date: '2025-10-25',
        festivos: [],
        pc_ocupacion: 37.0,
        room: {
          double: {
            newPrice: 84.08,
            priceBefore: 87.75,
            priceNow: 89.89,
          },
          single: {
            newPrice: 73.71,
            priceBefore: 73.86,
            priceNow: 80.19,
          },
          suite: {
            newPrice: 142.13,
            priceBefore: 135.33,
            priceNow: 130.91,
          },
        },
      },
      {
        date: '2025-10-26',
        festivos: [],
        pc_ocupacion: 36.0,
        room: {
          double: {
            newPrice: 91.15,
            priceBefore: 84.03,
            priceNow: 81.31,
          },
          single: {
            newPrice: 64.07,
            priceBefore: 62.59,
            priceNow: 62.87,
          },
          suite: {
            newPrice: 154.82,
            priceBefore: 148.1,
            priceNow: 150.88,
          },
        },
      },
      {
        date: '2025-10-27',
        festivos: [],
        pc_ocupacion: 29.0,
        room: {
          double: {
            newPrice: 89.82,
            priceBefore: 86.02,
            priceNow: 91.75,
          },
          single: {
            newPrice: 68.91,
            priceBefore: 71.23,
            priceNow: 69.73,
          },
          suite: {
            newPrice: 84.28,
            priceBefore: 80.52,
            priceNow: 85.09,
          },
        },
      },
      {
        date: '2025-10-28',
        festivos: [],
        pc_ocupacion: 42.0,
        room: {
          double: {
            newPrice: 101.02,
            priceBefore: 94.28,
            priceNow: 99.71,
          },
          single: {
            newPrice: 95.49,
            priceBefore: 86.98,
            priceNow: 89.27,
          },
          suite: {
            newPrice: 153.3,
            priceBefore: 150.61,
            priceNow: 163.64,
          },
        },
      },
      {
        date: '2025-10-29',
        festivos: [],
        pc_ocupacion: 47.0,
        room: {
          double: {
            newPrice: 83.42,
            priceBefore: 77.17,
            priceNow: 79.72,
          },
          single: {
            newPrice: 98.2,
            priceBefore: 97.9,
            priceNow: 102.48,
          },
          suite: {
            newPrice: 153.34,
            priceBefore: 151.99,
            priceNow: 161.04,
          },
        },
      },
      {
        date: '2025-10-30',
        festivos: ['Festividad local de Barriopedro'],
        pc_ocupacion: 36.0,
        room: {
          double: {
            newPrice: 81.19,
            priceBefore: 75.37,
            priceNow: 74.2,
          },
          single: {
            newPrice: 60.99,
            priceBefore: 56.78,
            priceNow: 61.09,
          },
          suite: {
            newPrice: 141.83,
            priceBefore: 130.57,
            priceNow: 143.59,
          },
        },
      },
      {
        date: '2025-10-31',
        festivos: ['Festividad local de Menasalbas'],
        pc_ocupacion: 35.0,
        room: {
          double: {
            newPrice: 105.45,
            priceBefore: 99.81,
            priceNow: 106.38,
          },
          single: {
            newPrice: 84.35,
            priceBefore: 83.04,
            priceNow: 89.31,
          },
          suite: {
            newPrice: 124.83,
            priceBefore: 120.76,
            priceNow: 129.05,
          },
        },
      },
      {
        date: '2025-11-01',
        festivos: ['Día de Todos los Santos'],
        pc_ocupacion: 37.0,
        room: {
          double: {
            newPrice: 104.7,
            priceBefore: 100.83,
            priceNow: 100.54,
          },
          single: {
            newPrice: 82.94,
            priceBefore: 82.98,
            priceNow: 86.65,
          },
          suite: {
            newPrice: 145.15,
            priceBefore: 133.52,
            priceNow: 127.49,
          },
        },
      },
      {
        date: '2025-11-02',
        festivos: [],
        pc_ocupacion: 46.0,
        room: {
          double: {
            newPrice: 114.68,
            priceBefore: 115.65,
            priceNow: 115.09,
          },
          single: {
            newPrice: 77.66,
            priceBefore: 73.09,
            priceNow: 73.2,
          },
          suite: {
            newPrice: 149.81,
            priceBefore: 151.0,
            priceNow: 153.11,
          },
        },
      },
      {
        date: '2025-11-03',
        festivos: [],
        pc_ocupacion: 33.0,
        room: {
          double: {
            newPrice: 78.47,
            priceBefore: 75.39,
            priceNow: 73.24,
          },
          single: {
            newPrice: 64.65,
            priceBefore: 63.41,
            priceNow: 63.43,
          },
          suite: {
            newPrice: 136.69,
            priceBefore: 133.88,
            priceNow: 138.56,
          },
        },
      },
      {
        date: '2025-11-04',
        festivos: [
          'Festividad local de Las Labores',
          'Festividad local de Barchin del Hoyo',
        ],
        pc_ocupacion: 36.0,
        room: {
          double: {
            newPrice: 90.4,
            priceBefore: 87.74,
            priceNow: 90.17,
          },
          single: {
            newPrice: 75.88,
            priceBefore: 69.01,
            priceNow: 70.85,
          },
          suite: {
            newPrice: 149.26,
            priceBefore: 156.54,
            priceNow: 156.79,
          },
        },
      },
      {
        date: '2025-11-05',
        festivos: [],
        pc_ocupacion: 51.0,
        room: {
          double: {
            newPrice: 119.27,
            priceBefore: 119.36,
            priceNow: 129.3,
          },
          single: {
            newPrice: 121.29,
            priceBefore: 111.15,
            priceNow: 121.32,
          },
          suite: {
            newPrice: 151.95,
            priceBefore: 153.85,
            priceNow: 146.57,
          },
        },
      },
      {
        date: '2025-11-06',
        festivos: [],
        pc_ocupacion: 41.0,
        room: {
          double: {
            newPrice: 62.05,
            priceBefore: 57.5,
            priceNow: 55.66,
          },
          single: {
            newPrice: 128.31,
            priceBefore: 121.57,
            priceNow: 117.96,
          },
          suite: {
            newPrice: 144.54,
            priceBefore: 149.97,
            priceNow: 162.93,
          },
        },
      },
      {
        date: '2025-11-07',
        festivos: [],
        pc_ocupacion: 71.0,
        room: {
          double: {
            newPrice: 191.21,
            priceBefore: 178.89,
            priceNow: 184.33,
          },
          single: {
            newPrice: 134.86,
            priceBefore: 139.16,
            priceNow: 152.94,
          },
          suite: {
            newPrice: 146.09,
            priceBefore: 152.67,
            priceNow: 163.94,
          },
        },
      },
      {
        date: '2025-11-08',
        festivos: ['Festividad local de Pozo de Almoguera'],
        pc_ocupacion: 51.0,
        room: {
          double: {
            newPrice: 172.83,
            priceBefore: 179.31,
            priceNow: 195.99,
          },
          single: {
            newPrice: 118.7,
            priceBefore: 116.76,
            priceNow: 121.98,
          },
          suite: {
            newPrice: 121.49,
            priceBefore: 120.52,
            priceNow: 131.96,
          },
        },
      },
      {
        date: '2025-11-09',
        festivos: [],
        pc_ocupacion: 53.0,
        room: {
          double: {
            newPrice: 118.09,
            priceBefore: 109.36,
            priceNow: 116.85,
          },
          single: {
            newPrice: 75.18,
            priceBefore: 69.15,
            priceNow: 71.58,
          },
          suite: {
            newPrice: 203.55,
            priceBefore: 194.39,
            priceNow: 199.06,
          },
        },
      },
      {
        date: '2025-11-10',
        festivos: [],
        pc_ocupacion: 56.0,
        room: {
          double: {
            newPrice: 89.96,
            priceBefore: 94.35,
            priceNow: 93.14,
          },
          single: {
            newPrice: 110.35,
            priceBefore: 116.02,
            priceNow: 126.53,
          },
          suite: {
            newPrice: 183.3,
            priceBefore: 184.24,
            priceNow: 182.41,
          },
        },
      },
      {
        date: '2025-11-11',
        festivos: [
          'Festividad local de Cañaveras',
          'Festividad local de Fuertescusa',
          'Festividad local de San Martin de Boniches',
          'Festividad local de Anquela del Ducado',
          'Festividad local de Masegoso de Tajuña',
          'Festividad local de Pozo de Almoguera',
          'Festividad local de San Martín de Pusa',
          'Festividad local de Villanueva de Alcardete',
        ],
        pc_ocupacion: 45.0,
        room: {
          double: {
            newPrice: 137.26,
            priceBefore: 127.47,
            priceNow: 125.14,
          },
          single: {
            newPrice: 54.07,
            priceBefore: 55.54,
            priceNow: 54.55,
          },
          suite: {
            newPrice: 162.38,
            priceBefore: 165.15,
            priceNow: 167.71,
          },
        },
      },
      {
        date: '2025-11-12',
        festivos: [
          'Festividad local de Cañamares',
          'Festividad local de Cogolludo',
          'Festividad local de Muduex',
        ],
        pc_ocupacion: 44.0,
        room: {
          double: {
            newPrice: 97.33,
            priceBefore: 100.9,
            priceNow: 108.8,
          },
          single: {
            newPrice: 70.52,
            priceBefore: 71.32,
            priceNow: 75.82,
          },
          suite: {
            newPrice: 137.14,
            priceBefore: 143.41,
            priceNow: 149.95,
          },
        },
      },
      {
        date: '2025-11-13',
        festivos: [],
        pc_ocupacion: 20.0,
        room: {
          double: {
            newPrice: 56.92,
            priceBefore: 54.96,
            priceNow: 52.62,
          },
          single: {
            newPrice: 54.83,
            priceBefore: 51.48,
            priceNow: 50.64,
          },
          suite: {
            newPrice: 82.46,
            priceBefore: 76.68,
            priceNow: 83.02,
          },
        },
      },
      {
        date: '2025-11-14',
        festivos: ['Festividad local de Tartanedo'],
        pc_ocupacion: 38.0,
        room: {
          double: {
            newPrice: 79.11,
            priceBefore: 80.5,
            priceNow: 78.02,
          },
          single: {
            newPrice: 65.9,
            priceBefore: 65.26,
            priceNow: 69.04,
          },
          suite: {
            newPrice: 140.78,
            priceBefore: 128.67,
            priceNow: 122.87,
          },
        },
      },
      {
        date: '2025-11-15',
        festivos: [],
        pc_ocupacion: 35.0,
        room: {
          double: {
            newPrice: 76.75,
            priceBefore: 78.65,
            priceNow: 81.64,
          },
          single: {
            newPrice: 53.94,
            priceBefore: 55.11,
            priceNow: 60.32,
          },
          suite: {
            newPrice: 148.88,
            priceBefore: 135.55,
            priceNow: 135.57,
          },
        },
      },
      {
        date: '2025-11-16',
        festivos: [],
        pc_ocupacion: 38.0,
        room: {
          double: {
            newPrice: 88.66,
            priceBefore: 89.74,
            priceNow: 86.29,
          },
          single: {
            newPrice: 47.12,
            priceBefore: 46.29,
            priceNow: 47.26,
          },
          suite: {
            newPrice: 132.13,
            priceBefore: 128.86,
            priceNow: 134.1,
          },
        },
      },
      {
        date: '2025-11-17',
        festivos: [],
        pc_ocupacion: 49.0,
        room: {
          double: {
            newPrice: 89.88,
            priceBefore: 92.12,
            priceNow: 95.41,
          },
          single: {
            newPrice: 71.87,
            priceBefore: 68.9,
            priceNow: 70.39,
          },
          suite: {
            newPrice: 177.65,
            priceBefore: 184.09,
            priceNow: 193.99,
          },
        },
      },
      {
        date: '2025-11-18',
        festivos: [
          'Festividad local de Arcos de la Cantera',
          'Festividad local de San Román de los Montes',
        ],
        pc_ocupacion: 30.0,
        room: {
          double: {
            newPrice: 98.13,
            priceBefore: 103.0,
            priceNow: 112.18,
          },
          single: {
            newPrice: 38.15,
            priceBefore: 37.77,
            priceNow: 35.93,
          },
          suite: {
            newPrice: 119.44,
            priceBefore: 124.58,
            priceNow: 122.76,
          },
        },
      },
      {
        date: '2025-11-19',
        festivos: [],
        pc_ocupacion: 29.0,
        room: {
          double: {
            newPrice: 70.82,
            priceBefore: 71.25,
            priceNow: 70.56,
          },
          single: {
            newPrice: 63.52,
            priceBefore: 60.27,
            priceNow: 60.82,
          },
          suite: {
            newPrice: 114.18,
            priceBefore: 114.97,
            priceNow: 121.58,
          },
        },
      },
      {
        date: '2025-11-20',
        festivos: [],
        pc_ocupacion: 30.0,
        room: {
          double: {
            newPrice: 81.99,
            priceBefore: 77.89,
            priceNow: 77.16,
          },
          single: {
            newPrice: 55.18,
            priceBefore: 57.14,
            priceNow: 62.43,
          },
          suite: {
            newPrice: 110.23,
            priceBefore: 105.63,
            priceNow: 110.85,
          },
        },
      },
      {
        date: '2025-11-21',
        festivos: [],
        pc_ocupacion: 33.0,
        room: {
          double: {
            newPrice: 93.86,
            priceBefore: 91.07,
            priceNow: 95.44,
          },
          single: {
            newPrice: 56.12,
            priceBefore: 54.28,
            priceNow: 53.85,
          },
          suite: {
            newPrice: 148.34,
            priceBefore: 138.16,
            priceNow: 139.29,
          },
        },
      },
      {
        date: '2025-11-22',
        festivos: [],
        pc_ocupacion: 41.0,
        room: {
          double: {
            newPrice: 89.62,
            priceBefore: 85.54,
            priceNow: 81.42,
          },
          single: {
            newPrice: 85.4,
            priceBefore: 87.03,
            priceNow: 95.66,
          },
          suite: {
            newPrice: 133.6,
            priceBefore: 124.11,
            priceNow: 123.89,
          },
        },
      },
      {
        date: '2025-11-23',
        festivos: ['Festividad local de Zarzuela de Jadraque'],
        pc_ocupacion: 37.0,
        room: {
          double: {
            newPrice: 89.48,
            priceBefore: 86.09,
            priceNow: 86.07,
          },
          single: {
            newPrice: 64.69,
            priceBefore: 64.98,
            priceNow: 69.98,
          },
          suite: {
            newPrice: 144.48,
            priceBefore: 150.83,
            priceNow: 161.74,
          },
        },
      },
      {
        date: '2025-11-24',
        festivos: [],
        pc_ocupacion: 37.0,
        room: {
          double: {
            newPrice: 72.0,
            priceBefore: 75.73,
            priceNow: 79.98,
          },
          single: {
            newPrice: 69.31,
            priceBefore: 66.14,
            priceNow: 64.66,
          },
          suite: {
            newPrice: 132.61,
            priceBefore: 133.46,
            priceNow: 127.44,
          },
        },
      },
      {
        date: '2025-11-25',
        festivos: [
          'Festividad local de Angón',
          'Festividad local de Tórtola de Henares',
        ],
        pc_ocupacion: 39.0,
        room: {
          double: {
            newPrice: 133.33,
            priceBefore: 123.13,
            priceNow: 133.58,
          },
          single: {
            newPrice: 87.05,
            priceBefore: 88.62,
            priceNow: 94.35,
          },
          suite: {
            newPrice: 118.94,
            priceBefore: 111.71,
            priceNow: 109.81,
          },
        },
      },
      {
        date: '2025-11-26',
        festivos: [],
        pc_ocupacion: 37.0,
        room: {
          double: {
            newPrice: 108.63,
            priceBefore: 101.81,
            priceNow: 102.2,
          },
          single: {
            newPrice: 81.79,
            priceBefore: 77.79,
            priceNow: 74.61,
          },
          suite: {
            newPrice: 121.92,
            priceBefore: 125.01,
            priceNow: 137.1,
          },
        },
      },
      {
        date: '2025-11-27',
        festivos: [],
        pc_ocupacion: 39.0,
        room: {
          double: {
            newPrice: 121.98,
            priceBefore: 118.6,
            priceNow: 115.18,
          },
          single: {
            newPrice: 55.58,
            priceBefore: 56.73,
            priceNow: 55.34,
          },
          suite: {
            newPrice: 124.04,
            priceBefore: 123.89,
            priceNow: 126.51,
          },
        },
      },
      {
        date: '2025-11-28',
        festivos: [],
        pc_ocupacion: 42.0,
        room: {
          double: {
            newPrice: 95.13,
            priceBefore: 89.91,
            priceNow: 89.55,
          },
          single: {
            newPrice: 89.3,
            priceBefore: 81.36,
            priceNow: 77.52,
          },
          suite: {
            newPrice: 148.32,
            priceBefore: 134.94,
            priceNow: 144.34,
          },
        },
      },
      {
        date: '2025-11-29',
        festivos: [
          'Festividad local de Viso del Marques',
          'Festividad local de Castillo de Bayuela',
          'Festividad local de San Martín de Montalbán',
        ],
        pc_ocupacion: 30.0,
        room: {
          double: {
            newPrice: 100.94,
            priceBefore: 92.82,
            priceNow: 98.02,
          },
          single: {
            newPrice: 60.83,
            priceBefore: 63.56,
            priceNow: 67.39,
          },
          suite: {
            newPrice: 114.8,
            priceBefore: 115.67,
            priceNow: 123.27,
          },
        },
      },
      {
        date: '2025-11-30',
        festivos: [
          'Festividad local de Paredes',
          'Festividad local de Fuentelahiguera de Albatages',
          'Festividad local de Romanillos de Atienza',
          'Festividad local de San Andrés del Rey',
          'Festividad local de Carmena',
        ],
        pc_ocupacion: 38.0,
        room: {
          double: {
            newPrice: 92.75,
            priceBefore: 92.71,
            priceNow: 90.57,
          },
          single: {
            newPrice: 70.35,
            priceBefore: 64.67,
            priceNow: 65.67,
          },
          suite: {
            newPrice: 120.6,
            priceBefore: 124.13,
            priceNow: 133.17,
          },
        },
      },
      {
        date: '2025-12-01',
        festivos: [],
        pc_ocupacion: 33.0,
        room: {
          double: {
            newPrice: 71.75,
            priceBefore: 74.35,
            priceNow: 81.34,
          },
          single: {
            newPrice: 53.22,
            priceBefore: 53.46,
            priceNow: 52.67,
          },
          suite: {
            newPrice: 109.83,
            priceBefore: 115.07,
            priceNow: 120.13,
          },
        },
      },
      {
        date: '2025-12-02',
        festivos: [],
        pc_ocupacion: 39.0,
        room: {
          double: {
            newPrice: 109.19,
            priceBefore: 104.4,
            priceNow: 114.33,
          },
          single: {
            newPrice: 60.11,
            priceBefore: 56.23,
            priceNow: 59.13,
          },
          suite: {
            newPrice: 139.7,
            priceBefore: 129.1,
            priceNow: 124.53,
          },
        },
      },
      {
        date: '2025-12-03',
        festivos: [],
        pc_ocupacion: 43.0,
        room: {
          double: {
            newPrice: 71.05,
            priceBefore: 73.39,
            priceNow: 70.31,
          },
          single: {
            newPrice: 97.98,
            priceBefore: 90.29,
            priceNow: 93.61,
          },
          suite: {
            newPrice: 160.44,
            priceBefore: 150.45,
            priceNow: 147.81,
          },
        },
      },
      {
        date: '2025-12-04',
        festivos: [
          'Festividad local de Congostrina',
          'Festividad local de Iniestola',
          'Festividad local de Las Hunfrías',
        ],
        pc_ocupacion: 39.0,
        room: {
          double: {
            newPrice: 95.42,
            priceBefore: 92.44,
            priceNow: 89.76,
          },
          single: {
            newPrice: 80.38,
            priceBefore: 77.78,
            priceNow: 77.85,
          },
          suite: {
            newPrice: 138.19,
            priceBefore: 131.17,
            priceNow: 127.69,
          },
        },
      },
      {
        date: '2025-12-05',
        festivos: [
          'Festividad local de Casas de Lazaro',
          'Festividad local de Matillas',
          'Festividad local de Romanones',
        ],
        pc_ocupacion: 39.0,
        room: {
          double: {
            newPrice: 67.32,
            priceBefore: 67.49,
            priceNow: 68.07,
          },
          single: {
            newPrice: 72.3,
            priceBefore: 69.81,
            priceNow: 71.1,
          },
          suite: {
            newPrice: 140.45,
            priceBefore: 133.86,
            priceNow: 132.89,
          },
        },
      },
      {
        date: '2025-12-06',
        festivos: ['Día de la Constitución Española'],
        pc_ocupacion: 46.0,
        room: {
          double: {
            newPrice: 130.33,
            priceBefore: 122.2,
            priceNow: 120.5,
          },
          single: {
            newPrice: 98.23,
            priceBefore: 90.19,
            priceNow: 98.47,
          },
          suite: {
            newPrice: 130.65,
            priceBefore: 134.43,
            priceNow: 134.25,
          },
        },
      },
      {
        date: '2025-12-07',
        festivos: [],
        pc_ocupacion: 39.0,
        room: {
          double: {
            newPrice: 113.44,
            priceBefore: 104.58,
            priceNow: 104.41,
          },
          single: {
            newPrice: 87.43,
            priceBefore: 87.7,
            priceNow: 88.49,
          },
          suite: {
            newPrice: 127.7,
            priceBefore: 123.19,
            priceNow: 125.54,
          },
        },
      },
      {
        date: '2025-12-08',
        festivos: ['Inmaculada Concepción'],
        pc_ocupacion: 40.0,
        room: {
          double: {
            newPrice: 112.47,
            priceBefore: 112.36,
            priceNow: 114.48,
          },
          single: {
            newPrice: 63.25,
            priceBefore: 61.63,
            priceNow: 59.42,
          },
          suite: {
            newPrice: 130.59,
            priceBefore: 132.98,
            priceNow: 134.32,
          },
        },
      },
      {
        date: '2025-12-09',
        festivos: [
          'Festividad local de Almuradiel',
          'Festividad local de Cañada de Calatrava',
          'Festividad local de Arrancacepas',
          'Festividad local de Barchin del Hoyo',
          'Festividad local de Horcajo De Santiago',
          'Festividad local de Viana de Jadraque',
          'Festividad local de Alcaudete de la Jara',
          'Festividad local de Camarenilla',
          'Festividad local de Casasbuenas',
          'Festividad local de Escalona',
          'Festividad local de Navaltoril',
          'Festividad local de Villaseca de la Sagra',
        ],
        pc_ocupacion: 39.0,
        room: {
          double: {
            newPrice: 94.67,
            priceBefore: 94.32,
            priceNow: 102.03,
          },
          single: {
            newPrice: 81.77,
            priceBefore: 84.5,
            priceNow: 91.92,
          },
          suite: {
            newPrice: 134.22,
            priceBefore: 125.12,
            priceNow: 128.87,
          },
        },
      },
      {
        date: '2025-12-10',
        festivos: [
          'Festividad local de Durón',
          'Festividad local de Sienes',
          'Festividad local de Navaltoril',
        ],
        pc_ocupacion: 30.0,
        room: {
          double: {
            newPrice: 104.84,
            priceBefore: 102.35,
            priceNow: 107.7,
          },
          single: {
            newPrice: 56.4,
            priceBefore: 58.9,
            priceNow: 62.49,
          },
          suite: {
            newPrice: 76.69,
            priceBefore: 73.56,
            priceNow: 71.45,
          },
        },
      },
      {
        date: '2025-12-11',
        festivos: [],
        pc_ocupacion: 37.0,
        room: {
          double: {
            newPrice: 103.53,
            priceBefore: 99.34,
            priceNow: 98.37,
          },
          single: {
            newPrice: 68.12,
            priceBefore: 68.26,
            priceNow: 68.28,
          },
          suite: {
            newPrice: 151.22,
            priceBefore: 141.99,
            priceNow: 146.78,
          },
        },
      },
      {
        date: '2025-12-12',
        festivos: [],
        pc_ocupacion: 29.0,
        room: {
          double: {
            newPrice: 74.15,
            priceBefore: 76.28,
            priceNow: 83.01,
          },
          single: {
            newPrice: 70.79,
            priceBefore: 66.36,
            priceNow: 69.96,
          },
          suite: {
            newPrice: 116.71,
            priceBefore: 114.06,
            priceNow: 111.59,
          },
        },
      },
      {
        date: '2025-12-13',
        festivos: [
          'Festividad local de Fuente el Fresno',
          'Festividad local de Retuerta del Bullaque',
          'Festividad local de Almonacid del Marquesado',
          'Festividad local de Bolliga',
          'Festividad local de Castillo-Albarañez',
          'Festividad local de Olmedilla del Campo',
          'Festividad local de Aldeanueva de Guadalajara',
          'Festividad local de Valdeavellano',
        ],
        pc_ocupacion: 47.0,
        room: {
          double: {
            newPrice: 108.31,
            priceBefore: 99.66,
            priceNow: 109.09,
          },
          single: {
            newPrice: 70.8,
            priceBefore: 68.48,
            priceNow: 72.03,
          },
          suite: {
            newPrice: 156.76,
            priceBefore: 153.0,
            priceNow: 146.48,
          },
        },
      },
      {
        date: '2025-12-14',
        festivos: [],
        pc_ocupacion: 38.0,
        room: {
          double: {
            newPrice: 84.87,
            priceBefore: 82.73,
            priceNow: 86.99,
          },
          single: {
            newPrice: 106.1,
            priceBefore: 106.37,
            priceNow: 105.33,
          },
          suite: {
            newPrice: 108.31,
            priceBefore: 109.19,
            priceNow: 119.27,
          },
        },
      },
      {
        date: '2025-12-15',
        festivos: [],
        pc_ocupacion: 27.0,
        room: {
          double: {
            newPrice: 73.39,
            priceBefore: 68.98,
            priceNow: 75.09,
          },
          single: {
            newPrice: 60.29,
            priceBefore: 57.97,
            priceNow: 58.66,
          },
          suite: {
            newPrice: 64.17,
            priceBefore: 65.72,
            priceNow: 65.81,
          },
        },
      },
      {
        date: '2025-12-16',
        festivos: [],
        pc_ocupacion: 30.0,
        room: {
          double: {
            newPrice: 76.63,
            priceBefore: 77.98,
            priceNow: 78.64,
          },
          single: {
            newPrice: 48.23,
            priceBefore: 46.97,
            priceNow: 50.01,
          },
          suite: {
            newPrice: 114.95,
            priceBefore: 109.34,
            priceNow: 108.07,
          },
        },
      },
      {
        date: '2025-12-17',
        festivos: ['Festividad local de Narboneta'],
        pc_ocupacion: 25.0,
        room: {
          double: {
            newPrice: 90.98,
            priceBefore: 91.41,
            priceNow: 86.95,
          },
          single: {
            newPrice: 47.81,
            priceBefore: 47.4,
            priceNow: 45.44,
          },
          suite: {
            newPrice: 64.69,
            priceBefore: 61.27,
            priceNow: 60.65,
          },
        },
      },
      {
        date: '2025-12-18',
        festivos: ['Festividad local de Valverdejo'],
        pc_ocupacion: 35.0,
        room: {
          double: {
            newPrice: 72.87,
            priceBefore: 71.31,
            priceNow: 69.05,
          },
          single: {
            newPrice: 71.56,
            priceBefore: 67.04,
            priceNow: 70.19,
          },
          suite: {
            newPrice: 111.26,
            priceBefore: 113.97,
            priceNow: 108.36,
          },
        },
      },
      {
        date: '2025-12-19',
        festivos: [],
        pc_ocupacion: 32.0,
        room: {
          double: {
            newPrice: 72.72,
            priceBefore: 68.57,
            priceNow: 73.0,
          },
          single: {
            newPrice: 72.57,
            priceBefore: 69.44,
            priceNow: 67.03,
          },
          suite: {
            newPrice: 100.91,
            priceBefore: 104.05,
            priceNow: 110.01,
          },
        },
      },
      {
        date: '2025-12-20',
        festivos: [
          'Festividad local de Torralba',
          'Festividad local de Villaminaya',
        ],
        pc_ocupacion: 41.0,
        room: {
          double: {
            newPrice: 89.43,
            priceBefore: 86.94,
            priceNow: 93.42,
          },
          single: {
            newPrice: 78.37,
            priceBefore: 74.5,
            priceNow: 78.78,
          },
          suite: {
            newPrice: 135.14,
            priceBefore: 123.3,
            priceNow: 124.44,
          },
        },
      },
      {
        date: '2025-12-21',
        festivos: [],
        pc_ocupacion: 35.0,
        room: {
          double: {
            newPrice: 74.75,
            priceBefore: 77.34,
            priceNow: 75.55,
          },
          single: {
            newPrice: 63.17,
            priceBefore: 60.89,
            priceNow: 64.91,
          },
          suite: {
            newPrice: 117.58,
            priceBefore: 122.31,
            priceNow: 120.6,
          },
        },
      },
      {
        date: '2025-12-22',
        festivos: [],
        pc_ocupacion: 28.0,
        room: {
          double: {
            newPrice: 63.63,
            priceBefore: 63.95,
            priceNow: 69.19,
          },
          single: {
            newPrice: 79.51,
            priceBefore: 75.98,
            priceNow: 82.42,
          },
          suite: {
            newPrice: 124.12,
            priceBefore: 119.52,
            priceNow: 131.4,
          },
        },
      },
      {
        date: '2025-12-23',
        festivos: ['Festividad local de Huecas'],
        pc_ocupacion: 40.0,
        room: {
          double: {
            newPrice: 83.66,
            priceBefore: 84.66,
            priceNow: 85.21,
          },
          single: {
            newPrice: 75.57,
            priceBefore: 75.14,
            priceNow: 81.58,
          },
          suite: {
            newPrice: 159.97,
            priceBefore: 152.43,
            priceNow: 152.84,
          },
        },
      },
      {
        date: '2025-12-24',
        festivos: [],
        pc_ocupacion: 54.0,
        room: {
          double: {
            newPrice: 132.53,
            priceBefore: 136.39,
            priceNow: 149.13,
          },
          single: {
            newPrice: 114.28,
            priceBefore: 110.78,
            priceNow: 108.23,
          },
          suite: {
            newPrice: 122.51,
            priceBefore: 116.71,
            priceNow: 112.84,
          },
        },
      },
      {
        date: '2025-12-25',
        festivos: ['Natividad del Señor'],
        pc_ocupacion: 26.0,
        room: {
          double: {
            newPrice: 67.07,
            priceBefore: 63.48,
            priceNow: 67.45,
          },
          single: {
            newPrice: 79.32,
            priceBefore: 75.42,
            priceNow: 80.79,
          },
          suite: {
            newPrice: 99.3,
            priceBefore: 101.41,
            priceNow: 100.12,
          },
        },
      },
      {
        date: '2025-12-26',
        festivos: [
          'Festividad local de Liétor',
          'Festividad local de Barbalimpia',
          'Festividad local de La Huerguina',
          'Festividad local de Humanes',
        ],
        pc_ocupacion: 39.0,
        room: {
          double: {
            newPrice: 64.62,
            priceBefore: 67.48,
            priceNow: 72.59,
          },
          single: {
            newPrice: 104.46,
            priceBefore: 97.58,
            priceNow: 103.48,
          },
          suite: {
            newPrice: 146.87,
            priceBefore: 133.72,
            priceNow: 135.28,
          },
        },
      },
      {
        date: '2025-12-27',
        festivos: ['Festividad local de Casasimarro'],
        pc_ocupacion: 32.0,
        room: {
          double: {
            newPrice: 94.94,
            priceBefore: 90.8,
            priceNow: 88.86,
          },
          single: {
            newPrice: 40.62,
            priceBefore: 41.63,
            priceNow: 40.92,
          },
          suite: {
            newPrice: 131.81,
            priceBefore: 137.72,
            priceNow: 139.06,
          },
        },
      },
      {
        date: '2025-12-28',
        festivos: ['Festividad local de Alcázar de San Juan'],
        pc_ocupacion: 59.0,
        room: {
          double: {
            newPrice: 102.56,
            priceBefore: 104.4,
            priceNow: 108.98,
          },
          single: {
            newPrice: 172.34,
            priceBefore: 158.33,
            priceNow: 158.74,
          },
          suite: {
            newPrice: 153.22,
            priceBefore: 153.44,
            priceNow: 147.48,
          },
        },
      },
      {
        date: '2025-12-29',
        festivos: [],
        pc_ocupacion: 72.0,
        room: {
          double: {
            newPrice: 154.98,
            priceBefore: 160.68,
            priceNow: 154.28,
          },
          single: {
            newPrice: 134.55,
            priceBefore: 126.21,
            priceNow: 128.6,
          },
          suite: {
            newPrice: 279.42,
            priceBefore: 282.54,
            priceNow: 273.74,
          },
        },
      },
      {
        date: '2025-12-30',
        festivos: ['Festividad local de Alcabón'],
        pc_ocupacion: 52.0,
        room: {
          double: {
            newPrice: 155.38,
            priceBefore: 152.98,
            priceNow: 166.52,
          },
          single: {
            newPrice: 100.3,
            priceBefore: 99.54,
            priceNow: 108.39,
          },
          suite: {
            newPrice: 165.15,
            priceBefore: 156.97,
            priceNow: 164.49,
          },
        },
      },
      {
        date: '2025-12-31',
        festivos: ['Festividad local de El Robledo'],
        pc_ocupacion: 41.0,
        room: {
          double: {
            newPrice: 111.7,
            priceBefore: 105.52,
            priceNow: 107.84,
          },
          single: {
            newPrice: 73.13,
            priceBefore: 67.51,
            priceNow: 73.73,
          },
          suite: {
            newPrice: 170.26,
            priceBefore: 163.24,
            priceNow: 167.2,
          },
        },
      },
    ],
  },
]
