import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import * as Plotly from 'plotly.js-dist-min';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-grafico-pdf',
  templateUrl: './grafico-pdf.component.html',
  styleUrls: ['./grafico-pdf.component.scss']
})
export class GraficoPdfComponent implements OnInit {
  @ViewChild('chart', { static: true }) chart!: ElementRef;
  
   jsonData ={
    data: [
      {
         x: [
                "2023-08-13T00:00:00",
                "2023-08-14T00:00:00",
                "2023-08-15T00:00:00",
                "2023-08-16T00:00:00"],
        y: [10, 11, 12, 13],
        type: 'scatter',
      } as Plotly.ScatterData
    ], 
    layout: {
      title: 'Gráfico generado con Plotly',
    }, 
  };

  ngOnInit() {
       // Plotly.newPlot(this.chart.nativeElement, jsonData.data, jsonData.layout);
       this.renderPlotAndConvertToPNG();
  }
  renderPlotAndConvertToPNG() {
    const nativeElement = this.chart.nativeElement;

    Plotly.newPlot(nativeElement, this.jsonData.data, this.jsonData.layout)
      .then(() => {
        // Convert the plot to a PNG
        return Plotly.toImage(nativeElement, { format: 'png', width: 800, height: 600 });
      })
      .then((imageDataUrl: string) => {
        // Trigger the download of the PNG image
        const link = document.createElement('a');
        link.href = imageDataUrl;
        link.download = 'plot.png';
        link.click(); // Trigger the download
      })
      .catch((error: any) => {
        console.error('Error converting plot to PNG:', error);
      });
  }
  generatePDF() {
    const chartElement = this.chart.nativeElement;

    // Convertir el gráfico en una imagen usando html2canvas
    html2canvas(chartElement,{
      scale: 1,width: chartElement.offsetWidth * 10,height: chartElement.offsetHeight * 10
 },).then(canvas => {
      const imgData = canvas.toDataURL('image/png'); // Convertir el canvas a imagen (base64)
      // Crear el PDF
      const pdf = new jsPDF();
      pdf.addImage(imgData, 'PNG', 10, 10, 300, 300); // Añadir la imagen al PDF
      pdf.save('grafico.pdf'); // Guardar el PDF
    });
  }
}