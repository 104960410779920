<section class="page-header">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <ul
          class="list-inline breadcrumbs text-capitalize"
          style="font-weight: 500"
        >
          <li class="list-inline-item">
            <a routerLink="/home"><i class="fa-solid fa-house"></i></a>
          </li>
          <li class="list-inline-item">
            | &nbsp; <a routerLink="/app">Establecimientos</a>
          </li>
          <li class="list-inline-item">
            | &nbsp; <a routerLink="/app">Mis alojamientos</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>
<section class="divider-section extra">
  <div class="container">
    <h2 class="divider fs-3">
      <span class="position-absolute">Información del establecimiento</span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        width="553px"
        height="61px"
        viewBox="0 0 553 61"
        version="1.1"
      >
        <title>Group Copy 2</title>
        <g
          id="Page-1"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
        >
          <g id="separadores" transform="translate(-12, -495)" fill="#FFFFFF">
            <g id="Group-Copy-2" transform="translate(12, 495)">
              <path
                d="M552.045,0 L0,0 L0,60.4823 L491.563,60.4823 C515.552,60.4823 535,41.0348 535,17.045 C535,7.63132 542.631,0 552.045,0 Z"
                id="Path"
              />
            </g>
          </g>
        </g>
      </svg>
    </h2>
    <div class="my-5">
      <nav>
        <div class="nav nav-tabs register-tabs" id="nav-tab" role="tablist">
          <button
            class="nav-link active"
            id="nav-newCustomer-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-newCustomer"
            type="button"
            role="tab"
            aria-controls="nav-newCustomer"
            aria-selected="true"
          >
            <i class="fa-solid fa-user me-2"></i>Alta alojamiento
          </button>
        </div>
      </nav>
      <div
        class="tab-content register-content pt-4 border bg-white shadow"
        id="nav-tabContent"
      >
        <!-- alta establecimeinto -->
        <div
          class="tab-pane fade show active"
          id="nav-newCustomer"
          role="tabpanel"
          aria-labelledby="nav-newCustomer-tab"
          tabindex="0"
        >
          <div class="basic-form p-4">
            <h4 class="pb-4">Información Básica - Alta de alojamiento</h4>
            <form [formGroup]="establecimientoForm">
              <div class="row justify-content-center mt-5">
                <h6 class="text-uppercase fw-light">
                  Seleccionar el tipo de alojamiento
                  <small class="text-danger">*</small>
                </h6>
                <a
                  [ngClass]="{
                    active: selectedButton === 'Albergue',
                    disabled: false,
                  }"
                  (click)="onButtonClick('Albergue')"
                  class="filter-box col-6 col-lg-3 mb-4"
                >
                  <div class="filter-body rounded py-4 px-4 border">
                    <div class="text-center">
                      <img
                        src="assets/images/icons/albergue.svg"
                        alt="Resort"
                        height="40px"
                      />
                      <h5 class="mt-3">Albergue</h5>
                    </div>
                  </div>
                </a>
                <a
                  [ngClass]="{
                    active: selectedButton === 'Apartamento',
                    disabled: false,
                  }"
                  (click)="onButtonClick('Apartamento')"
                  class="filter-box col-6 col-lg-3 mb-4"
                >
                  <div class="filter-body rounded py-4 px-4 border">
                    <div class="text-center">
                      <img
                        src="assets/images/icons/apartamento.svg"
                        alt="Apartamento"
                        height="40px"
                      />
                      <h5 class="mt-3">Apartamento</h5>
                    </div>
                  </div>
                </a>

                <a
                  [ngClass]="{
                    active: selectedButton === 'Camping',
                    disabled: false,
                  }"
                  (click)="onButtonClick('Camping')"
                  class="filter-box col-6 col-lg-3 mb-4"
                >
                  <div class="filter-body rounded py-4 px-4 border">
                    <div class="text-center">
                      <img
                        src="assets/images/icons/camping.svg"
                        alt="camping"
                        height="40px"
                      />
                      <h5 class="mt-3">Camping</h5>
                    </div>
                  </div>
                </a>
                <a
                  [ngClass]="{ active: selectedButton === 'Casa Rural' }"
                  (click)="onButtonClick('Casa Rural')"
                  class="filter-box col-6 col-lg-3 mb-4"
                >
                  <div class="filter-body rounded py-4 px-4 border">
                    <div class="text-center">
                      <img
                        src="assets/images/icons/casaRural.svg"
                        alt="Casa Rural"
                        height="40px"
                      />
                      <h5 class="mt-3">Casa Rural</h5>
                    </div>
                  </div>
                </a>
                <a
                  [ngClass]="{ active: selectedButton === 'Hostal' }"
                  (click)="onButtonClick('Hostal')"
                  class="filter-box col-6 col-lg-3 mb-4"
                >
                  <div class="filter-body rounded py-4 px-4 border">
                    <div class="text-center">
                      <img
                        src="assets/images/icons/hostal.svg"
                        alt="Hostal"
                        height="40px"
                      />
                      <h5 class="mt-3">Hostal</h5>
                    </div>
                  </div>
                </a>

                <a
                  [ngClass]="{ active: selectedButton === 'Hotel' }"
                  (click)="onButtonClick('Hotel')"
                  class="filter-box col-6 col-lg-3 mb-4"
                >
                  <div class="filter-body rounded py-4 px-4 border">
                    <div class="text-center">
                      <img
                        src="assets/images/icons/hotel.svg"
                        alt="Hotel"
                        height="40px"
                      />
                      <h5 class="mt-3">Hotel</h5>
                    </div>
                  </div>
                </a>
                <a
                  [ngClass]="{ active: selectedButton === 'Motel' }"
                  (click)="onButtonClick('Motel')"
                  class="filter-box col-6 col-lg-3 mb-4"
                >
                  <div class="filter-body rounded py-4 px-4 border">
                    <div class="text-center">
                      <img
                        src="assets/images/icons/motel.svg"
                        alt="Motel"
                        height="40px"
                      />
                      <h5 class="mt-3">Motel</h5>
                    </div>
                  </div>
                </a>

                <a
                  [ngClass]="{ active: selectedButton === 'Pension' }"
                  (click)="onButtonClick('Pension')"
                  class="filter-box col-6 col-lg-3 mb-4"
                >
                  <div class="filter-body rounded py-4 px-4 border">
                    <div class="text-center">
                      <img
                        src="assets/images/icons/pension.svg"
                        alt="Pension"
                        height="40px"
                      />
                      <h5 class="mt-3">Pension</h5>
                    </div>
                  </div>
                </a>
              </div>
              <div *ngIf="selectedButton === ''">
                <small class="text-danger"
                  >Escoja el tipo de alojamiento.</small
                >
              </div>
              <input type="hidden" name="typeobject" />

              <h6 class="text-uppercase fw-light mt-4 mb-3">
                Descripción y datos del Alojamiento
              </h6>

              <div class="row form-row">
                <div class="form-group col-md-4">
                  <label
                    >Nombre del alojamiento
                    <small class="text-danger">*</small></label
                  >
                  <input
                    type="text"
                    class="form-control"
                    name="name"
                    placeholder="Nombre del alojamiento"
                    formControlName="name"
                  />
                  <div
                    *ngIf="
                      establecimientoForm.get('name')?.hasError('required') &&
                      establecimientoForm.get('name')?.touched
                    "
                  >
                    <small class="text-danger">
                      El nombre es obligatorio.
                    </small>
                  </div>
                  <!-- Lista de sugerencias de autocompletado -->
                  <ul
                    *ngIf="resultadosAutocompletar$ | async as resultados"
                    class="mt-3 destacar-option"
                  >
                    <li
                      *ngFor="let item of resultados"
                      (click)="seleccionarAlojamiento(item)"
                    >
                      {{ item }}
                    </li>
                  </ul>
                </div>

                <!-- <div class="form-group col-md-8">
                                    <label>Campo 2</label>
                                    <input type="text" class="form-control" placeholder="placeholder">
                                </div> -->
              </div>
              @if (establishmentNameType) {
                <div *ngIf="establishmentNameType">
                  <div class="form-group col-9">
                    <label>Dirección completa</label>

                    <!-- Mostrar input cuando solo hay una dirección -->
                    <ng-container
                      *ngIf="!addressMoreOptions; else addressOptionsTemplate"
                    >
                      <input
                        type="text"
                        class="form-control"
                        id="address"
                        name="address"
                        placeholder="Dirección completa, añadiendo número y datos necesarios"
                        formControlName="address"
                        readonly
                      />
                    </ng-container>

                    <!-- Mostrar select cuando hay más de una dirección -->
                    <ng-template #addressOptionsTemplate>
                      <select
                        id="selectAddressOptions"
                        class="form-select py-2"
                        name="address"
                        formControlName="address"
                        (change)="onSelectAddress($event)"
                      >
                        <option
                          *ngFor="let ad of addressOptions"
                          [value]="ad.address"
                        >
                          {{ ad.address }}
                        </option>
                      </select>
                    </ng-template>
                  </div>

                  <div class="row form-row">
                    <div class="form-group col-md-3">
                      <label for="dropdown">
                        Provincia <small class="text-danger">*</small>
                      </label>

                      <input
                        type="text"
                        class="form-control"
                        id="province"
                        name="province"
                        formControlName="province"
                        readonly
                      />
                    </div>

                    <div class="form-group col-md-3">
                      <label for="dropdown">
                        Municipio <span class="text-danger">*</span>
                      </label>

                      <input
                        type="text"
                        class="form-control"
                        id="city"
                        name="city"
                        formControlName="city"
                        readonly
                      />
                    </div>

                    <div class="form-group col-md-3">
                      <label>
                        Código Postal <small class="text-danger">*</small>
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        name="postal_code"
                        maxlength="5"
                        formControlName="postal_code"
                        readonly
                      />
                    </div>
                  </div>

                  <div class="row form-row">
                    <div class="form-group col-md-3">
                      <label for="dropdown"> Email </label>

                      <input
                        type="text"
                        class="form-control"
                        id="email"
                        name="email"
                        formControlName="email"
                        readonly
                      />
                    </div>

                    <div class="form-group col-md-3">
                      <label for="dropdown"> Teléfono </label>

                      <input
                        type="text"
                        class="form-control"
                        id="phone"
                        name="phone"
                        formControlName="phone"
                        readonly
                      />
                    </div>

                    <div
                      class="form-group col-md-3"
                      *ngIf="selectedButton != 'Albergue'"
                    >
                      <label> Categoria </label>
                      <input
                        type="text"
                        class="form-control"
                        name="category"
                        formControlName="category"
                        readonly
                      />
                    </div>
                  </div>

                  <div class="row form-row">
                    <div class="form-group col-md-3">
                      <label for="dropdown"> Total Plazas </label>

                      <input
                        type="text"
                        class="form-control"
                        id="capacity"
                        name="capacity"
                        formControlName="capacity"
                        readonly
                      />
                    </div>

                    <div
                      class="form-group col-md-3"
                      *ngIf="
                        selectedButton == 'Hotel' ||
                        selectedButton == 'Hostal' ||
                        selectedButton == 'Pension' ||
                        selectedButton == 'Motel'
                      "
                    >
                      <label for="dropdown"> Total Habitaciones </label>

                      <input
                        type="text"
                        class="form-control"
                        id="rooms"
                        name="rooms"
                        formControlName="rooms"
                        readonly
                      />
                    </div>
                  </div>
                </div>
              }

              <div class="row px-5">
                <!-- <div class="col-12 col-lg-6 mb-3">
                                    <div class="form-check form-check-inline mb-3">
                                        <label class="form-check-label text-secondary">
                                        <input type="checkbox" class="form-check-input" value="">He leído y acepto la Política de Privacidad y Protección de datos.</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <label class="form-check-label text-secondary">
                                        <input type="checkbox" class="form-check-input" value="">Quiero recibir información del Portal de gestión hotelera</label>
                                    </div>
                                </div> -->
                <div class="row my-5">
                  <div
                    class="col-12 col-md-col-8 mx-auto d-flex justify-content-center"
                  >
                    <button
                      *ngIf="establishmentNameType && !establishmentDataExists"
                      class="btn btn-sm btn-primary rounded-pill py-2 px-4 btn-rotate complete align-items-center"
                      type="submit"
                      (click)="submitForm(establecimientoForm)"
                    >
                      <i class="fa-regular fa-paper-plane me-2"></i>
                      Guardar
                    </button>

                    <button
                      *ngIf="buttonbuscarAloj"
                      class="btn btn-sm btn-primary rounded-pill py-2 px-4 btn-rotate complete align-items-center"
                      type="submit"
                      (click)="onSubmitEstablishmentName()"
                    >
                      <i class="fa-regular fa-paper-plane me-2"></i>
                      Buscar Alojamiento
                    </button>
                  </div>
                </div>
              </div>
            </form>
            @if (establishmentNameType && establishmentDataExists) {
              <div class="row justify-content-center mt-5">
                <h6 class="text-uppercase fw-light">
                  Seleccionar tipo de integración
                  <small class="text-danger">*</small>
                </h6>

                <div class="form-check mt-3 ms-5">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="integracion"
                    id="pms"
                    value="pms"
                    [(ngModel)]="selectedIntegracion"
                    (change)="onIntegracionChange()"
                  />
                  <label class="form-check-label" for="pms">
                    Integración PMS
                  </label>
                </div>
                <div class="form-check mat-headline-3 ms-5 mt-3">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="integracion"
                    id="excel"
                    value="excel"
                    [(ngModel)]="selectedIntegracion"
                    (change)="onIntegracionChange()"
                  />
                  <label class="form-check-label" for="excel">
                    Integracion Manual
                  </label>
                </div>
                <!-- SUBIR FICHERO -->
                <div *ngIf="selectedIntegracion === 'excel'" class="mt-5">
                  <div
                    class="file-uploader d-flex flex-column align-items-center text-center p-3 rounded"
                    style="
                      background-color: rgba(0, 0, 0, 0.02);
                      border: 1px dashed grey;
                    "
                  >
                    <!-- <app-file-upload (fileSelected)="onFileSelected($event)"></app-file-upload> 
                                    <span class="mt-2 text-secondary">Tamaño máximo de archivo 10Mb</span> -->

                    <input type="file" (change)="onFileSelected($event)" />
                    <span class="mt-2 text-secondary"
                      >Tamaño máximo de archivo 10Mb</span
                    >
                    <button
                      class="btn btn-sm btn-primary rounded-pill py-2 px-4 btn-rotate complete align-items-center mt-3"
                      (click)="onUpload()"
                    >
                      Subir fichero
                    </button>
                    <button
                      class="btn btn-sm btn-primary rounded-pill py-2 px-4 btn-rotate complete align-items-center mt-3"
                      (click)="noFile()"
                    >
                      No subir fichero
                    </button>
                  </div>
                </div>

                <div
                  *ngIf="selectedIntegracion === 'pms'"
                  class="mt-5 ms-5 me-5"
                >
                  <p>Por favor, ingresa el nombre del PMS:</p>
                  <input
                    type="text"
                    class="form-control"
                    [(ngModel)]="pmsNombre"
                    placeholder="Nombre del PMS"
                  />

                  <div class="mt-3 d-flex justify-content-center mt-3">
                    <button
                      class="btn btn-sm btn-primary rounded-pill py-2 px-4 btn-rotate complete align-items-center mt-3"
                      (click)="noPms()"
                    >
                      Guardar
                    </button>
                  </div>

                  <div class="mt-3 d-flex justify-content-center mt-3">
                    <button
                      class="btn btn-sm btn-primary rounded-pill py-2 px-4 btn-rotate complete align-items-center mt-3"
                      (click)="noPms()"
                    >
                      Insertar PMS más adelante
                    </button>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
        <div
          class="tab-pane fade"
          id="nav-occupancy"
          role="tabpanel"
          aria-labelledby="nav-occupancy-tab"
          tabindex="0"
        >
          <p>
            This is some placeholder content the <strong>Profile tab's</strong
            > associated content. Clicking another tab will toggle the
            visibility of this one for the next. The tab JavaScript swaps
            classes to control the content visibility and styling. You can use
            it with tabs, pills, and any other
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
