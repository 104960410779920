import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  async canActivate(router: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean>{
    const isAuth = await this.authService.isAuth();
    
    if (!isAuth) {
      this.router.navigate(['/login'])
      return false;
    } 
  
  return true;
  }
}
