import { Component, Output, EventEmitter, Input } from '@angular/core'
import { FormGroup, FormBuilder, ReactiveFormsModule } from '@angular/forms'

@Component({
  selector: 'app-vinculate-mfa-form',
  standalone: true,
  imports: [ReactiveFormsModule],
  templateUrl: './vinculate-mfa-form.component.html',
  styleUrl: './vinculate-mfa-form.component.scss',
})
export class VinculateMfaFormComponent {
  QRcodeForm: FormGroup
  @Input() waiting: boolean = false
  @Input() QRUrl: string
  @Output() otpCode: EventEmitter<any> = new EventEmitter()

  constructor(private fb: FormBuilder) {
    this.QRcodeForm = this.fb.group({
      code: [''],
    })
  }

  submitSignupTOTPcode() {
    this.otpCode.emit(this.QRcodeForm.value.code)
  }
}
