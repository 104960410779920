import { Component, ElementRef, ViewChild } from '@angular/core'
import { FileUploadService } from '../../services/files/fileUpload.service'
import { Chart, registerables } from 'chart.js'
import { HttpClient } from '@angular/common/http'
import * as noUiSlider from 'nouislider'
import { GraphService } from '../../services/graficos/graph.service'
@Component({
  selector: 'app-grafico-volumen-punto',
  templateUrl: './grafico-volumen-punto.component.html',
  styleUrl: './grafico-volumen-punto.component.scss',
})
export class GraficoVolumenPuntoComponent {
  chart: Chart
  provinces: string[] = [
    'Albacete',
    'Ciudad Real',
    'Cuenca',
    'Sigüenza',
    'Toledo',
    // 'Total provincias',
  ]
  months: string[] = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ]
  years: any[] = []
  yearsWithoutUndefined: any[] = []
  yearsWithoutUndefinedEnd: any[] = []
  currentYear: number = new Date().getFullYear()
  data: any[] = []
  selectedProvince = ''
  selectedEndYear = 0
  selectedStartYear = 0
  selectedEndMonth = ''
  selectedStartMonth = ''
  filteredData: any[] = []
  endMonthsAvailable: string[] = []
  dynamicMonthsByYear: Map<string, string[]>
  @ViewChild('volumenPunto', { static: true })
  canvasElRef!: ElementRef<HTMLCanvasElement>
  @ViewChild('rangeSlider', { static: true }) rangeSlider!: ElementRef
  constructor(
    private fileService: FileUploadService,
    private http: HttpClient,
    private graphService: GraphService
  ) {
    Chart.register(...registerables)
  }
  ngOnInit() {
    this.loadCSVData()
  }
  ngAfterViewInit() {
    if (this.selectedProvince) {
      this.loadCSVData()
    }
  }

  updateChart() {
    this.updateChartByDateRange()
  }
  loadCSVData() {
    this.http
      .get('../../../assets/data/puntos_vol_turista.csv', {
        responseType: 'text',
      })
      .subscribe((data) => {
        this.data = this.fileService.parseCSVData(data)
        const yearMonthMap = this.graphService.extractYearsAndMonths(
          this.data,
          this.months
        )
        this.years = Array.from(yearMonthMap.keys()).sort()
        this.dynamicMonthsByYear = yearMonthMap

        if (this.selectedProvince == '') {
          this.selectedProvince = this.provinces[0]
        }
        this.initializeSlider()
      })
  }

  initializeSlider() {
    const slider = this.rangeSlider.nativeElement
    if (!this.rangeSlider) return

    const sliderValues: string[] = []
    this.years.forEach((year) => {
      const months = this.dynamicMonthsByYear.get(year)!
      months.forEach((month) => {
        sliderValues.push(`${month} ${year}`)
      })
    })

    noUiSlider.create(slider, {
      start: [sliderValues[0], sliderValues[50]],
      step: 1,
      range: {
        min: 0,
        max: sliderValues.length - 1,
      },
      tooltips: [true, true],
      format: {
        to: (value) => {
          return sliderValues[Math.round(value)]
        },
        from: (value) => {
          return sliderValues.indexOf(value)
        },
      },
    })

    slider.noUiSlider!.on('update', (values: any, handle: any) => {
      const start = values[0]
      const end = values[1]

      const [startMonth, startYear] = start.split(' ')
      const [endMonth, endYear] = end.split(' ')

      this.selectedStartYear = parseInt(startYear)
      this.selectedStartMonth = startMonth
      this.selectedEndYear = parseInt(endYear)
      this.selectedEndMonth = endMonth
      this.updateChartByDateRange()
    })
  }
  getProvinceInfo(province: string) {
    switch (province.toLowerCase()) {
      case 'albacete':
        return { codigo: '02003', nombre: 'albacete' }
      case 'ciudad real':
        return { codigo: '13034', nombre: 'ciudad_real' }
      case 'cuenca':
        return { codigo: '16078', nombre: 'cuenca' }
      case 'sigüenza':
        return { codigo: '19257', nombre: 'siguenza' }
      case 'toledo':
        return { codigo: '45168', nombre: 'toledo' }
      default:
        return { codigo: '00', nombre: '' }
    }
  }
  updateChartByDateRange() {
    this.filteredData = []
    this.filteredData = this.graphService.filterDataByDateRange(
      this.data,
      this.selectedStartYear,
      this.selectedStartMonth,
      this.selectedEndYear,
      this.selectedEndMonth,
      this.months
    )
    this.createChart()
  }
  createChart() {
    const { codigo, nombre } = this.getProvinceInfo(this.selectedProvince)
    const canvasElement = this.canvasElRef.nativeElement

    this.filteredData = this.filteredData.map((d) => {
      const cleanedData: any = {}

      Object.keys(d).forEach((key) => {
        // Limpia tanto las claves como los valores, usando trim()
        const cleanedKey = key.trim()
        cleanedData[cleanedKey] = d[key]?.trim() // Limpia espacios extra de los valores también
      })

      return cleanedData
    })

    const viajeroData = this.filteredData.map((d) => {
      if (d) {
        // if (this.selectedProvince === 'Total provincias') {
        //   return d['total_viajero']
        // } else {
        return d[`total_${codigo}_${nombre}_viajero`]
      }
    })
    const pernocData = this.filteredData.map((d) => {
      if (d) {
        // if (this.selectedProvince === 'Total provincias') {
        //   return d['total_pernoctaciones']
        // } else {
        return d[`total_${codigo}_${nombre}_pernoctaciones`]
      }
    })

    const colorViajero = this.filteredData.map((d) =>
      d['type'] && d['type'] === 'historico'
        ? 'rgba(135, 206, 250, 1.0)'
        : 'rgba(135, 206, 250, 0.5)'
    )
    const colorPernoctaciones = this.filteredData.map((d) =>
      d['type'] && d['type'] === 'historico'
        ? 'rgba(255, 182, 193, 1.0)'
        : 'rgba(255, 182, 193, 0.5)'
    )

    if (this.chart) {
      this.chart.destroy()
    }
    const chartConfig = {
      type: 'scatter',
      data: {
        labels: this.filteredData.map((d) => {
          const year = d['periodo'].slice(0, 4)
          const monthIndex = parseInt(d['periodo'].slice(5)) - 1 // convierte a índice (0-11)
          return `${this.months[monthIndex]} ${year}`
        }),
        datasets: [
          {
            type: 'bar',
            label: 'Viajeros',
            data: viajeroData,
            backgroundColor: colorViajero,
          },
          {
            type: 'line',
            label: 'Pernoctaciones',
            data: pernocData,
            fill: false,
            borderColor: colorPernoctaciones,
          },
        ],
      },
      options: {
        responsive: true,
        scales: {
          y: {
            beginAtZero: true,
          },
        },
        plugins: {
          datalabels: false,
          title: {
            display: true,
            text: 'Volumen de turismo por punto turístico',
            font: {
              size: 24,
            },
            padding: {
              top: 30,
              bottom: 30,
            },
          },
        },
      },
    }

    this.chart = new Chart(canvasElement, chartConfig as any)
  }

  onProvinceChange(event: any): void {
    this.selectedProvince = event.target.value
    this.updateChartByDateRange()
  }
}
