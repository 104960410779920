<div class="container">
  <div class="row">
    <div class="col-12 mb-3">
      <ul
        class="list-inline breadcrumbs text-capitalize"
        style="font-weight: 500"
      >
        <li class="list-inline-item">
          <a routerLink="/home"><i class="fa-solid fa-house"></i></a>
        </li>
        <li class="list-inline-item">
          | &nbsp; <a>Datos abiertos de Castilla-La Mancha</a>
        </li>
      </ul>
    </div>
  </div>
</div>
<section class="divider-section pb-4">
  <div class="container">
    <h2 class="divider fs-3">
      <span class="position-absolute">Directorio Turismo</span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        width="413px"
        height="61px"
        viewBox="0 0 413 61"
        version="1.1"
      >
        <title>Group</title>
        <g
          id="Page-1"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
        >
          <g id="separadores" transform="translate(-12, -22)" fill="#FFFFFF">
            <g id="Group" transform="translate(12, 22)">
              <path
                d="M412.045,0 L0,0 L0,60.4823 L351.563,60.4823 C375.552,60.4823 395,41.0348 395,17.045 C395,7.63132 402.631,0 412.045,0 Z"
                id="Path"
              ></path>
            </g>
          </g>
        </g>
      </svg>
    </h2>
  </div>
</section>

<section class="section bg-tertiary pb-5">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <!-- Pestañas -->
        <ul class="nav nav-tabs">
          <li class="nav-item" *ngFor="let tab of tabs">
            <a
              class="nav-link py-3"
              (click)="selectTab(tab)"
              [class.active]="tab === selectedTab"
            >
              {{ tab.title }}
            </a>
          </li>
        </ul>

        <!-- Contenido de la tabla -->
        <div class="container_tab">
          <ng-container *ngIf="selectedTab">
            <div class="table-responsive bg-white border-start">
              <!-- Verifica si hay datos -->
              <table
                *ngIf="data$ | async as data"
                class="table table-hover table-striped"
              >
                <thead>
                  <tr>
                    <!-- Cabeceras dinámicas usando la propiedad 'headers' -->
                    <th
                      class="bg-body-secondary"
                      *ngFor="let header of headers"
                    >
                      {{ header }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <!-- Filas dinámicas -->
                  <tr *ngFor="let row of data">
                    <td class="fw-light" *ngFor="let header of headers">
                      {{ row[header] }}
                    </td>
                  </tr>
                </tbody>
              </table>
              <!-- Mensaje en caso de que no haya datos
              <ng-template #noData>
                <p>No hay datos disponibles.</p>
              </ng-template> -->
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</section>
