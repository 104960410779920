<form [formGroup]="loginMFACode" (ngSubmit)="submitSigninTOTPCode()">
  <div id="login-wrapper" class="login-wrapper">
    <p>Introduce el código temporal de tu aplicación de MFA</p>
    <div class="mb-3">
      <input
        type="text"
        class="form-control"
        placeholder="Código de verificación"
        name="code"
        formControlName="mfacode"
      />
      <button
        class="btn login_btn btn-primary text-center w-100 rounded-pill mt-3"
      >
        @if (waiting) {
          <img
            width="20"
            src="assets/images/icons/loading-white.svg"
            alt="Loading"
          />
        } @else {
          Verificar código
        }
      </button>
      <button
        class="btn login_btn text-center w-100 rounded-pill mt-3"
        (click)="close()"
      >
        Volver
      </button>
    </div>
  </div>
</form>
