import { Component, OnInit, ViewChild } from '@angular/core'
import { CalendarOptions, EventClickArg, EventApi } from '@fullcalendar/core'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'
import esLocale from '@fullcalendar/core/locales/es'
import { FullCalendarComponent } from '@fullcalendar/angular'
import { CalendarService } from '../../services/calendar.service'
import { ModalCalendarComponent } from './modal-calendar/modal-calendar.component'
import { MatDialog } from '@angular/material/dialog'
import { ModalConfirmarComponent } from './modal-confirmar/modal-confirmar.component'
import { OcupacionColor } from './enums/ocupacion-colors.enum'
import { mock } from './mocks/hoteles-mock'
import tippy, { Instance } from 'tippy.js'
import { TipoHabitaciones } from './models/tipo-habitaciones'
import { MatSnackBar } from '@angular/material/snack-bar'
import { ModalGuardarComponent } from './modal-guardar/modal-guardar.component'
import { ConfirmarCambioHotelModalComponent } from './modal-cambio-hotel/modal-cambio-hotel.component'

@Component({
  selector: 'app-calendario',
  templateUrl: './calendario.component.html',
  styleUrls: ['./calendario.component.scss'],
})
export class CalendarioComponent implements OnInit {
  @ViewChild('calendario') calendarComponent?: FullCalendarComponent
  calendarVisible = true
  currentEvents: EventApi[] = []
  private tooltipInstance: Instance | null = null
  filtroTipoHabitacion: keyof TipoHabitaciones | 'all' = 'all'
  tieneCambios: boolean = false
  listaHoteles: { stablishment_name: string; stablishment_id: string }[] = []
  hotelSeleccionado: string = ''
  // Por defecto, el primer hotel
  filtroHotelId: string = mock[0].stablishment_id
  // Lista de hoteles cargada desde el mock
  hoteles = mock
  filtroHotelOriginal: string = this.filtroHotelId

  opcionesCalendario: CalendarOptions = {
    plugins: [dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin],
    headerToolbar: {
      left: 'today',
      center: 'title',
      right: 'dayGridMonth,dayGridWeek, prev,next',
    },
    titleFormat: {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    },
    locale: esLocale,
    initialView: 'dayGridMonth',
    weekends: true,
    editable: false,
    eventStartEditable: false,
    eventDurationEditable: false,
    selectable: true,
    selectMirror: true,
    dayMaxEvents: this.filtroTipoHabitacion !== 'all',
    /*     eventClick: this.manejarClickEvento.bind(this),*/
    eventsSet: this.manejarEventos.bind(this),
    events: [],
    eventChange: (info) => {
      // Marcar cambios cuando se edita un evento
      const id = info.event.id
      this.eventosEditados[id] = info.event
      this.tieneCambios = true
    },
    eventContent: this.cargarContenidoEvento.bind(this),
    eventMouseEnter: this.manejarEventoMouseEnter.bind(this),
    eventMouseLeave: this.manejarEventoMouseLeave.bind(this),
  }

  constructor(
    private calendarService: CalendarService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.filtroTipoHabitacion = 'single'
    this.listaHoteles = mock.map((hotel) => ({
      stablishment_name: hotel.stablishment_name,
      stablishment_id: hotel.stablishment_id,
    }))
    // Selección inicial
    this.hotelSeleccionado = this.listaHoteles[0]?.stablishment_name || ''
    this.cargarDatos()
    this.calendarService.tabSwitched$.subscribe(() => {
      const calendarApi = this.calendarComponent?.getApi()
      calendarApi?.updateSize()
    })
  }

  private mostrarSnackBar(mensaje: string, duracion: number = 3000): void {
    this.snackBar.open(mensaje, 'Cerrar', {
      duration: duracion,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
    })
  }

  private cargarDatos() {
    const selectedHotel = mock.find(
      (hotel) => hotel.stablishment_id === this.filtroHotelId
    )
    if (!selectedHotel) return
    const events = selectedHotel.details.flatMap((detail) => {
      const tiposHabitaciones: (keyof TipoHabitaciones)[] = [
        'single',
        'double',
        'suite',
      ]
      return tiposHabitaciones
        .map((tipoHabitacion) => {
          const room = detail.room[tipoHabitacion]
          if (
            room &&
            (this.filtroTipoHabitacion === 'all' ||
              this.filtroTipoHabitacion === tipoHabitacion)
          ) {
            return {
              id: `${detail.date}-${tipoHabitacion}`,
              title: `${selectedHotel.stablishment_name} - Habitación ${tipoHabitacion}`,
              start: detail.date,
              allDay: true,
              extendedProps: {
                newPrice: room.newPrice,
                originalPrice: room.newPrice,
                priceBefore: room.priceBefore,
                priceNow: room.priceNow,
                ocupacion: detail.pc_ocupacion,
                tipoHabitacion: tipoHabitacion,
                festivos: detail.festivos,
                esEditado: false,
              },
            }
          }
          return null
        })
        .filter(Boolean)
    })

    this.opcionesCalendario.events = events
    // Reiniciar modificaciones al cambiar de hotel
    this.eventosEditados = {}
    this.tieneCambios = false
  }

  public async cambioHotel(event: Event): Promise<void> {
    // Guardar el valor original del filtro antes de abrir el modal
    const hotelSeleccionadoTemporal = (event.target as HTMLSelectElement).value
    if (this.tieneCambios) {
      const dialogRef = this.dialog.open(ConfirmarCambioHotelModalComponent)
      dialogRef.afterClosed().subscribe((resultado) => {
        if (!resultado) {
          // Si el usuario cancela restauramos el valor original del filtro
          ;(event.target as HTMLSelectElement).value = this.filtroHotelId
          // No cambiar de hotel
          return
        }
        // Si el usuario ha confirmado el cambio, actualizamos el filtro
        this.filtroHotelId = hotelSeleccionadoTemporal
        // Recargar datos del nuevo hotel
        this.cargarDatos()
      })
    } else {
      // Si no hay cambios, simplemente actualizamos el filtro
      this.filtroHotelId = hotelSeleccionadoTemporal
      // Recargar datos del nuevo hotel
      this.cargarDatos()
    }
  }

  public cambioTipoHabitacion(event: Event): void {
    this.filtroTipoHabitacion = (event.target as HTMLSelectElement).value as
      | keyof TipoHabitaciones
      | 'all'
    this.cargarDatos()
  }

  private cargarContenidoEvento(eventInfo: any) {
    const {
      newPrice,
      priceNow,
      ocupacion,
      esEditado,
      tipoHabitacion,
      festivos,
    } = eventInfo.event.extendedProps

    const ocupacionColor = this.obtenerColorOcupacion(ocupacion)

    let iconoHabitacion = ''
    if (tipoHabitacion === 'single') {
      iconoHabitacion = `<span class="material-icons icono-tipo-habitacion">bedroom_child</span>`
    } else if (tipoHabitacion === 'double') {
      iconoHabitacion = `<span class="material-icons icono-tipo-habitacion">king_bed</span>`
    } else if (tipoHabitacion === 'suite') {
      iconoHabitacion = `<span class="material-icons icono-tipo-habitacion">star</span>`
    }

    const ocupacionIcon = ` 
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="black" class="bi bi-person-fill" viewBox="0 0 16 16">
        <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
      </svg>`

    const prediccionIcon = `<span class="material-icons" style="font-size: 16px; color: #066ac9; margin-left: 5px;">moving</span>`

    // Solo para la vista "all"
    if (this.filtroTipoHabitacion === 'all') {
      const contenidoFestivos =
        festivos && festivos.length > 0
          ? `<span class="material-icons" style="font-size: 16px; color: purple;">celebration</span>`
          : ''

      const contenidoOcupacion =
        ocupacion !== undefined || priceNow !== undefined
          ? `<div style="display: flex; align-items: center; color: black; gap: 1px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
            ${ocupacionIcon}
            <span style="font-size: 9px;">${ocupacion || 0}%</span>
            ${priceNow !== undefined ? `<span style="color: #066ac9; font-size: 9px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">${prediccionIcon} ${priceNow}€</span>` : ''}
          </div>`
          : ''

      return {
        html: `
          <div class="all" style="background-color: ${ocupacionColor};">
            ${esEditado ? `<div class="all-edicion"><span class="material-icons all-edicion-icono">edit</span></div>` : ''}
            <div class="all-habitacion-icono">
              ${iconoHabitacion}
              <div style="display: flex; align-items: center; justify-content: space-between;">
                <span class="all-habitacion-icono-span" style="color: ${newPrice !== null && newPrice !== undefined ? 'black' : '#b7523c'};">
                  ${newPrice !== null && newPrice !== undefined ? `${newPrice}€` : 'Sin Precio'}
                </span>
                ${contenidoFestivos}
              </div>
            </div>
            ${contenidoOcupacion}
          </div>
        `,
      }
    }

    // Para las demás vistas (no "all")
    return {
      html: `
        <div style="display: flex; flex-direction: column; align-items: center; justify-content: center; text-align: center; height: 100%; background-color: ${ocupacionColor}; position: relative; padding: 5px;">
          ${esEditado ? `<div style="position: absolute; top: 5px; right: 5px;"><span class="material-icons" style="color: gray;">edit</span></div>` : ''}
          ${iconoHabitacion}
          <span style="font-size: 1.8em; font-weight: bold; color: ${newPrice !== null && newPrice !== undefined ? 'black' : '#066ac9'};">
            ${newPrice !== null && newPrice !== undefined ? `${newPrice}€` : 'Sin Precio'}
          </span>
          <div style="display: flex; align-items: center; justify-content: space-between; width: 100%;">
            ${festivos && festivos.length > 0 ? `<span class="material-icons" style="font-size: 16px; color: purple;">celebration</span>` : ''}
            ${
              ocupacion !== undefined || priceNow !== undefined
                ? `<div style="margin-top: 5px; display: flex; align-items: center; font-weight: bold; font-size: 1.2em; justify-content: center; color: black;">
              ${ocupacionIcon} ${ocupacion || 0}%
              ${priceNow !== undefined ? `<span style="color: #066ac9; margin-left: 10px; font-weight: bold;">${prediccionIcon} ${priceNow}€</span>` : ''}
            </div>`
                : ''
            }
          </div>
        </div>
      `,
    }
  }

  public handleCalenmanegdarToggle() {
    this.calendarVisible = !this.calendarVisible
  }

  public handleWeekendsToggle() {
    const { opcionesCalendario } = this
    opcionesCalendario.weekends = !opcionesCalendario.weekends
  }

  public obtenerColorOcupacion(ocupacion: number): string {
    if (ocupacion >= 70) return OcupacionColor.ALTA
    if (ocupacion >= 33) return OcupacionColor.MEDIA
    return OcupacionColor.BAJA
  }

  /*  private manejarClickEvento(clickInfo: EventClickArg) {
    const event = clickInfo.event
    const currentPrice = event.extendedProps['newPrice']
    const initialPrice = event.extendedProps['originalPrice']
    // Verificar si el evento ya ha sido eliminado
    const estaBorrado = currentPrice !== null

    const dialogRef = this.dialog.open(ModalCalendarComponent, {
      width: '300px',
      data: {
        newPrice: currentPrice,
        originalPrice: initialPrice,
        estaBorrado: estaBorrado,
      },
    })

    dialogRef.afterClosed().subscribe((result) => {
      if (result !== undefined && typeof result === 'number') {
        event.setExtendedProp('newPrice', result)
        event.setExtendedProp('esEditado', true)
        this.tieneCambios = true
        // Guarda el evento modificado
        this.eventosEditados[event.id] = event.toPlainObject()
      } else if (result?.delete) {
        const confirmDialogRef = this.dialog.open(ModalConfirmarComponent, {
          width: '300px',
        })

        confirmDialogRef.afterClosed().subscribe((confirmed) => {
          if (confirmed) {
            event.setExtendedProp('newPrice', null)
            event.setExtendedProp('esEditado', true)
            this.tieneCambios = true
            // Guarda el evento modificado
            this.eventosEditados[event.id] = event.toPlainObject()
          }
        })
      }
    })
  } */

  /*  private actualizarMock(
    eventos: {
      fecha: string
      tipoHabitacion: keyof TipoHabitaciones
      precioNuevo: number | null
    }[]
  ): void {
    eventos.forEach((eventoModificado) => {
      const { fecha, tipoHabitacion, precioNuevo } = eventoModificado
      const establecimiento = mock.find((stablishment) =>
        stablishment.details.some((detail) => detail.date === fecha)
      )

      if (establecimiento) {
        const detalle = establecimiento.details.find(
          (detail) => detail.date === fecha
        )
        if (detalle && detalle.room[tipoHabitacion]) {
          // Actualiza el precio en el mock
          detalle.room[tipoHabitacion].newPrice = precioNuevo
        }
      }
    })
    console.log('Mock Actualizado:', mock)
  } */

  /*   public guardarCambios(): void {
    const dialogRef = this.dialog.open(ModalGuardarComponent, {
      width: '300px',
    })

    dialogRef.afterClosed().subscribe((confirmed) => {
      if (confirmed) {
        const eventosModificados = this.currentEvents
          .filter((event) => event.extendedProps['esEditado'])
          .map((event) => {
            const tipoHabitacion = event.extendedProps[
              'tipoHabitacion'
            ] as keyof TipoHabitaciones
            return {
              fecha: event.startStr,
              tipoHabitacion,
              precioNuevo: event.extendedProps['newPrice'],
              precioOriginal: event.extendedProps['originalPrice'],
            }
          })
        console.log('Eventos Modificados:', eventosModificados)
        this.actualizarMock(eventosModificados)
        this.mostrarSnackBar('Los cambios se han guardado con éxito')
        this.tieneCambios = false
      }
    })
  } */

  private manejarEventos(events: EventApi[]) {
    this.currentEvents = events
  }

  private manejarEventoMouseEnter(mouseEnterInfo: any) {
    const {
      newPrice,
      priceBefore,
      priceNow,
      ocupacion,
      tipoHabitacion,
      festivos,
    } = mouseEnterInfo.event.extendedProps
    // Traducción de tipos de habitación
    const traduccionTipoHabitacion = {
      single: 'Individual',
      double: 'Doble',
      suite: 'Suite',
    }

    const tipoHabitacionTraducido =
      traduccionTipoHabitacion[
        tipoHabitacion as keyof typeof traduccionTipoHabitacion
      ] || tipoHabitacion

    let contenidoFestivos = ''
    if (festivos && festivos.length > 0) {
      contenidoFestivos = `
          <div><strong>Festivos:</strong></div>
          <ul style="margin: 0; padding-left: 20px; font-size: 0.9em;">
            ${festivos.map((festivo: string) => `<li style="color:#066ac9">${festivo}</li>`).join('')}
          </ul>
        `
    }

    const tooltipContent = `
      <div style="
        text-align: left; 
        padding: 10px; 
        border: 1px solid #ccc; 
        border-radius: 8px; 
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2); 
        background-color: #ffffff;
        color: #333;">
        <div><strong>Tipo de Habitación:</strong> ${tipoHabitacionTraducido}</div>
        ${
          priceBefore !== undefined
            ? `<div><strong>Precio Anterior:</strong> ${priceBefore}€</div>`
            : ''
        }
        ${
          priceNow !== undefined
            ? `<div><strong>Predicción:</strong> ${priceNow}€</div>`
            : ''
        }
        ${
          newPrice !== null && newPrice !== undefined
            ? `<div><strong>Precio Nuevo:</strong> <span>${newPrice}€</span></div>`
            : ''
        }
        ${
          ocupacion !== undefined
            ? `<div><strong>Ocupación:</strong> <span>${ocupacion}%</span></div>`
            : ''
        }
        ${contenidoFestivos} 
      </div>
    `

    this.tooltipInstance = tippy(mouseEnterInfo.el, {
      content: tooltipContent,
      allowHTML: true,
      theme: 'custom',
      animation: 'scale',
      duration: [200, 150],
      onShow(instance) {
        instance.popper.classList.add('custom-tooltip')
      },
    })[0]
  }

  private manejarEventoMouseLeave() {
    if (this.tooltipInstance) {
      this.tooltipInstance.destroy()
      this.tooltipInstance = null
    }
  }

  private eventosEditados: { [key: string]: any } = {}
}
