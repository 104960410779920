import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { from, Observable, switchMap } from 'rxjs'
import { ApiResponse, ResultData } from './iDataCard' // Ajusta la ruta según tu estructura
import { AuthService } from '../auth/auth.service'
import { environment } from '../../../environments/environment'

@Injectable({
  providedIn: 'root',
})
export class DataCardService {
  constructor(
    private httpClient: HttpClient,
    private authService: AuthService
  ) {}

  getDataCard(uid: string): Observable<ResultData> {
    const url = environment.getDataCardUrl.replace('{uid}', uid)
    // let urlCompleta = this.apiUrl+nameEstablecimiento+'/diferencia';
    console.log('entra en descargar....', url)
    return this.httpClient.get<ResultData>(url)
  }

  getDatosAdr(uid: string): Observable<any> {
    let url = environment.downloadJsonADRUrl.replace('{uid}', uid)
    console.log('entra en descargar....', url)
    return this.httpClient.get<ApiResponse>(url)
  }
}
