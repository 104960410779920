import { Component, Output, EventEmitter, Input } from '@angular/core'
import {
  FormGroup,
  FormBuilder,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms'
import { passwordMatchValidator } from '../../../../../utils/validators'

@Component({
  selector: 'app-reset-password-form',
  standalone: true,
  imports: [ReactiveFormsModule],
  templateUrl: './reset-password-form.component.html',
  styleUrl: './reset-password-form.component.scss',
})
export class ResetPasswordFormComponent {
  sendCode: FormGroup
  resetPassword: FormGroup
  @Input() waiting: boolean = false
  @Input() resetCodeSentToUser: boolean = false
  @Output() goBack: EventEmitter<any> = new EventEmitter()
  @Output() askForCode: EventEmitter<any> = new EventEmitter()
  @Output() askForNewPassword: EventEmitter<any> = new EventEmitter()

  constructor(private fb: FormBuilder) {
    this.sendCode = this.fb.group({
      email: ['', [Validators.required]],
    })
    this.resetPassword = this.fb.group(
      {
        code: ['', [Validators.required]],
        password: ['', [Validators.required]],
        confirmPassword: ['', [Validators.required]],
      },
      { validators: [passwordMatchValidator] }
    )
  }

  sendCodeForm() {
    this.askForCode.emit(this.sendCode.value.email)
  }

  sendNewPasswordForm() {
    this.askForNewPassword.emit({
      email: this.sendCode.value.email,
      code: this.resetPassword.value.code,
      newPassword: this.resetPassword.value.password,
    })
  }

  close() {
    this.goBack.emit()
  }
}
