  <div *ngIf="weatherData" class="row row-cols-2 row-cols-md-3 row-cols-sm-6 row-cols-lg-5 g-4 mt-2 ">
    <div class="col" *ngFor="let item of itemTiempo">
      <div class="card card-weather">
        <div class="card-body p-0">
          <a class="list-group list-group-horizontal align-items-center text-decoration-none text-body justify-content-between">
            <div>
              <img class="icon-weather " [src]="'http://openweathermap.org/img/wn/' + item.weather[0].icon + '@2x.png'" alt="Icono del clima">
              <span class="card-title m-0 fs-6 ">{{ item.dt_txt | formatFecha }}</span>
            </div>
            <div class="d-flex">
              <div class="d-flex align-items-center me-2">
                <i class="fa-solid fa-arrow-up text-secondary subida-temp"></i>
                <p class="m-0 ms-1">{{ item.main.temp_max | formatTemp }}°C</p>
              </div>
              <div class="d-flex align-items-center pe-2">
                <i class="fa-solid fa-arrow-down text-secondary bajada-temp"></i>
               <p class="m-0 ms-1">{{ temp_min | formatTemp }}°C</p>

              </div>
            </div>
            
          </a>
        </div>
      </div>
    </div>
  </div>





