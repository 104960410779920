import { Component, HostListener, OnInit } from '@angular/core'
import { Router, NavigationEnd } from '@angular/router'
import { AuthService } from './services/auth/auth.service'
import { filter, tap } from 'rxjs'
import { UserDataService } from './services/users/userData.service'
import { Iuser } from './services/users/iuser'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  isloged: boolean = false
  isActive = true
  isDisabled = false
  isScrolled = false
  correo: string = ''
  nombre: string = ''
  logolName: string = ''
  initialName: string = ''

  // Escuchar el evento de scroll en la ventana
  @HostListener('window:scroll', [])
  onWindowScroll() {
    const scrollPosition =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0

    // Si el scroll supera los 200px, activar la variable isScrolled
    if (scrollPosition >= 200) {
      this.isScrolled = true
    } else {
      this.isScrolled = false
    }
  }
  constructor(
    private router: Router,
    public authService: AuthService,
    private userDataService: UserDataService
  ) {
    router.events
      .pipe(filter((event: any) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        if (event.url == '/login') {
          this.isDisabled = true
        } else {
          this.isActive = true
          this.isDisabled = false
        }
      })
  }
  ngOnInit() {
    this.isActive = true
    console.log('ntra en noginit......')
    this.userDataService.getUserInfo().subscribe((userData: Iuser) => {
      console.log('entra aqui.....', userData)
      this.logolName = userData.name
      this.initialName = this.logolName.charAt(0).toUpperCase()
      console.log('this.initialName......', this.initialName)
    })
  }
  goToFaqs() {
    this.router.navigate(['/home'], { fragment: 'faqs' })
  }
  deslog() {
    this.authService.logout()
    // this.isloged=this.authService.isAuthenticated();
    // if (localStorage.getItem('token')==''){
    //   this.isloged=false
    // }
  }

  title = 'senda-ui'

  nothing(event: any) {
    event.preventDefault()
  }

  get scrollPosition() {
    console.log(window.scrollY, document.documentElement.scrollTop)
    return window.scrollY
  }
}
