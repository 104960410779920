//user-service.service.ts

import { Injectable, Sanitizer } from '@angular/core'
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http'
import { BehaviorSubject, catchError, firstValueFrom, Observable } from 'rxjs'
import { Iuser } from './iuser' // Import the User interface

import { AuthService } from '../auth/auth.service'

import { environment } from '../../../environments/environment'

@Injectable({
  providedIn: 'root',
})
export class UserDataService {
  private userSubject = new BehaviorSubject<any>(null)
  user$ = this.userSubject.asObservable()

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) {}

  setUser(user: any) {
    this.userSubject.next(user)
  }
  getUser() {
    return this.userSubject.getValue()
  }
  // // Guardar un nuevo usuario
  async guardarUsuario(usuario: Iuser): Promise<any> {
    const url = environment.register
    try {
      const response = await firstValueFrom(this.http.post(url, usuario))
      return response
    } catch (error: any) {
      throw error
    }
  }

  getUserInfo(): Observable<Iuser> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    })
    return this.http
      .get<Iuser>(environment.getUserInfoUrl, { headers })
      .pipe(catchError(this.handleError))
  }

  updateUserInfo(user: Iuser): Observable<Iuser> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    })
    return this.http
      .put<Iuser>(environment.updateUserInfoUrl, user, { headers })
      .pipe(catchError(this.handleError))
  }

  updatePassword(
    current_password: string,
    new_password: string
  ): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    })
    return this.http
      .put(
        environment.updatePassword,
        { current_password, new_password },
        { headers }
      )
      .pipe(catchError(this.handleError))
  }

  // Manejo de errores
  private handleError(error: any): Observable<never> {
    console.error('Ocurrió un error:', error)
    throw new Error('Error en la solicitud')
  }
}
