<!-- Dashboard section -->

<div class="container">
  <div class="row">
    <div class="col-12 mb-3">
      <ul
        class="list-inline breadcrumbs text-capitalize"
        style="font-weight: 500"
      >
        <li class="list-inline-item">
          <a routerLink="/app">Área privada</a>
        </li>
        <li class="list-inline-item">
          | &nbsp;
          <a routerLink="/app"
            ><span class="hotel">{{ hotelData.name }}</span></a
          >
        </li>
        <!-- <li class="list-inline-item">» &nbsp; <a href="">Hotel Real Luxor</a>
                </li> -->
        <li class="list-inline-item">
          » &nbsp; <a routerLink="/app">Analítica avanzada</a>
        </li>
      </ul>
    </div>
  </div>
</div>
<section class="divider-section pb-3">
  <div class="container">
    <h2 class="divider fs-3">
      <span class="position-absolute">Analítica avanzada</span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        width="413px"
        height="61px"
        viewBox="0 0 413 61"
        version="1.1"
      >
        <title>Group</title>
        <g
          id="Page-1"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
        >
          <g id="separadores" transform="translate(-12, -22)" fill="#FFFFFF">
            <g id="Group" transform="translate(12, 22)">
              <path
                d="M412.045,0 L0,0 L0,60.4823 L351.563,60.4823 C375.552,60.4823 395,41.0348 395,17.045 C395,7.63132 402.631,0 412.045,0 Z"
                id="Path"
              ></path>
            </g>
          </g>
        </g>
      </svg>
    </h2>
  </div>
</section>
<section class="dashboard-section pb-3">
  <div class="container">
    <div class="row">
      <div
        class="col-12 sticky-lg-top custom-nav p-3"
        [ngClass]="{ 'nav-sticky-on': isScrolled }"
      >
        <div class="mb-5 mb-lg-0">
          <div
            class="nav nav-pills menupills me-3 pb-3"
            id="v-pills-tab"
            role="tablist"
            aria-orientation="vertical"
          >
            <button
              class="nav-link active"
              (click)="refrescarDatosPublicos()"
              id="v-pills-hotel-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-hotel"
              type="button"
              role="tab"
              aria-controls="v-pills-hotel"
              aria-selected="true"
            >
              Indicadores {{ hotelData.name }}
            </button>
            <button
              class="nav-link"
              id="v-pills-publico-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-publico"
              type="button"
              role="tab"
              aria-controls="v-pills-publico"
              aria-selected="false"
              (click)="onTabIndicadoresSelect()"
            >
              Indicadores Públicos
            </button>
            <button
              class="nav-link"
              id="v-pills-turismo-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-turismo"
              type="button"
              role="tab"
              aria-controls="v-pills-turismo"
              aria-selected="false"
            >
              Perfil Turista 360
            </button>
            <button
              class="nav-link"
              id="v-pills-volumen-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-volumen"
              type="button"
              role="tab"
              aria-controls="v-pills-volumen"
              aria-selected="false"
              (click)="onTabSelect()"
            >
              Volumen Turista
            </button>
            <button
              class="nav-link"
              id="v-pills-oferta-ocupacion-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-oferta-ocupacion"
              type="button"
              role="tab"
              aria-controls="v-pills-oferta-ocupacion"
              aria-selected="false"
              (click)="onTabSelect()"
            >
              Oferta y Ocupación de Establecimientos
            </button>
            <button
              class="nav-link"
              id="v-pills-calendario-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-calendario"
              type="button"
              role="tab"
              aria-controls="v-pills-calendario"
              (click)="onTabSelect()"
              aria-selected="false"
            >
              Calendario
            </button>
            <button
              class="nav-link"
              id="v-pills-excel-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-excel"
              type="button"
              role="tab"
              aria-controls="v-pills-excel"
              aria-selected="false"
            >
              Carga de Datos
            </button>
          </div>
          <ul
            class="nav nav-underline subnav pt-3 border-top border-secondary d-none"
          >
            <li class="nav-item">
              <a class="nav-link hotel active" aria-current="page" href="#">
                <span>Hotel Real Luxor</span>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link pension text-muted" href="#">
                <span>Pensión Jardines de Castilla</span></a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link hostal text-muted" href="#">
                <span>Hostal El Rincón del Viento</span>
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link apartamento text-muted"
                aria-current="page"
                href="#"
              >
                <span>Apartamentos Casas colgadas</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="tab-content col-12 mt-lg-4 mb-5" id="v-pills-tabContent">
        <!-- tab1 Hotel-->
        <div
          class="tab-pane fade show active"
          id="v-pills-hotel"
          role="tabpanel"
          aria-labelledby="v-pills-hotel-tab"
        >
          <!-- bloque ADR, RevPar, Ocupación, Ingresos -->
          <p *ngIf="this.ficheroHotelEmpty == true" class="alert-warning">
            No hay datos subidos para mostrar. Por favor, vaya a la pestaña
            'Carga de Datos' para subir archivo y mostrar la gráfica.
          </p>
          <div
            *ngIf="this.ficheroHotelEmpty == false"
            class="row row-cols-2 row-cols-md-3 row-cols-lg-4 g-4 mb-4"
          >
            <!-- ADR -->
            @if (dataCardsView) {
              <div class="col">
                <div class="card border-0">
                  <div class="card-body p-6">
                    <div
                      class="d-flex justify-content-between align-items-center mb-6"
                    >
                      <div>
                        <h4 class="mb-0 fs-6">ADR</h4>
                      </div>
                      <div
                        *ngIf="dataCardsView.adr_vr >= 0"
                        class="text-success"
                      >
                        <i class="fa-solid fa-arrow-up me-2"></i>
                        <span class=""> {{ dataCardsView.adr_vr }}</span>
                      </div>
                      <div *ngIf="dataCardsView.adr_vr < 0" class="text-danger">
                        <i class="fa-solid fa-arrow-down me-2"></i>
                        <span class=""> {{ dataCardsView.adr_vr }}</span>
                      </div>
                    </div>
                    <div class="lh-1 mt-4">
                      <h1 class="mb-2 fs-2">{{ dataCardsView.adr }}</h1>
                    </div>
                  </div>
                </div>
              </div>
              <!-- RevPar -->
              <div class="col">
                <div class="card border-0">
                  <div class="card-body p-6">
                    <div
                      class="d-flex justify-content-between align-items-center mb-6"
                    >
                      <div>
                        <h4 class="mb-0 fs-6">RevPar</h4>
                      </div>
                      <div
                        *ngIf="dataCardsView.revpar_vr >= 0"
                        class="text-success"
                      >
                        <i class="fa-solid fa-arrow-up me-2"></i>
                        <span class=""> {{ dataCardsView.revpar_vr }}</span>
                      </div>
                      <div
                        *ngIf="dataCardsView.revpar_vr < 0"
                        class="text-danger"
                      >
                        <i class="fa-solid fa-arrow-down me-2"></i>
                        <span class=""> {{ dataCardsView.revpar_vr }}</span>
                      </div>
                    </div>
                    <div class="lh-1 mt-4">
                      <h1 class="mb-2 fs-2">{{ dataCardsView.revpar }}</h1>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Ocupación -->
              <div class="col">
                <div class="card border-0">
                  <div class="card-body p-6">
                    <div
                      class="d-flex justify-content-between align-items-center mb-6"
                    >
                      <div>
                        <h4 class="mb-0 fs-6">Ocupación</h4>
                      </div>
                      <div
                        *ngIf="dataCardsView.ocupacion_vr >= 0"
                        class="text-success"
                      >
                        <i class="fa-solid fa-arrow-up me-2"></i>
                        <span class=""> {{ dataCardsView.ocupacion_vr }} </span>
                      </div>
                      <div
                        *ngIf="dataCardsView.ocupacion_vr < 0"
                        class="text-danger"
                      >
                        <i class="fa-solid fa-arrow-down me-2"></i>
                        <span class=""> {{ dataCardsView.ocupacion_vr }} </span>
                      </div>
                    </div>
                    <div class="lh-1 mt-4">
                      <h1 class="mb-2 fs-2">{{ dataCardsView.ocupacion }}</h1>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Ingresos -->
              <div class="col">
                <div class="card border-0">
                  <div class="card-body p-6">
                    <div
                      class="d-flex justify-content-between align-items-center mb-6"
                    >
                      <div>
                        <h4 class="mb-0 fs-6">Ingresos</h4>
                      </div>
                      <div
                        *ngIf="dataCardsView.ingresos_vr >= 0"
                        class="text-success"
                      >
                        <i class="fa-solid fa-arrow-up me-2"></i>
                        <span class=""> {{ dataCardsView.ingresos_vr }} </span>
                      </div>
                      <div
                        *ngIf="dataCardsView.ingresos_vr < 0"
                        class="text-danger"
                      >
                        <i class="fa-solid fa-arrow-down me-2"></i>
                        <span class=""> {{ dataCardsView.ingresos_vr }} </span>
                      </div>
                    </div>
                    <div class="lh-1 mt-4">
                      <h1 class="mb-2 fs-2">{{ dataCardsView.ingresos }}</h1>
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>

          <div class="row mb-4">
            <div class="col-12">
              <div class="card border-0">
                <div class="card-body">
                  <!-- <h5 class="text-center mt-5">Previsión meteorológica para {{ciudad}}</h5> -->
                  <div class="d-flex align-items-center">
                    <!-- <select class="form-select w-auto" [(ngModel)]="ciudad" (change)="onProvinciaChange($event)"> -->
                    <!-- <option *ngFor="let option of options">{{ option.label }}</option> -->
                    <!-- <option *ngFor="let provincia of provincias">{{provincia.value}}</option> -->
                    <!-- </select> -->
                    <!-- <select class="form-select w-auto ms-2" [(ngModel)]="localidadSelect"> -->
                    <!-- <option *ngFor="let localidad of localidades">{{localidad}}</option> -->
                    <!-- </select> -->
                    <p class="m-0 ms-4">
                      <span class="fw-bold"> </span> La temperatura actual en
                      <b> {{ municipioTiempo }}</b> ({{ provinciaTiempo }}) es
                      de <b> {{ temperatura | formatTemp }}°C </b>
                    </p>
                  </div>

                  <app-tiempo
                    *ngIf="municipioTiempo"
                    [municipio]="municipioTiempo"
                  ></app-tiempo>
                  <!-- *ngIf="municipioTiempo !== undefined"  -->
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-4" *ngIf="this.ficheroHotelEmpty == false">
            <div class="col-12">
              <div class="card border-0">
                <div class="card-body">
                  <app-grafico-reusable
                    [hotelData]="hotelData"
                  ></app-grafico-reusable>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <!-- <div class="card shadow">
              <div class="card-body">
                <app-grafico-reusable [hotelData]="hotelData"></app-grafico-reusable>
              </div>
            </div> -->
          </div>
        </div>

        <!-- tab2 Publico-->
        <div
          class="tab-pane fade"
          id="v-pills-publico"
          role="tabpanel"
          aria-labelledby="v-pills-publico-tab"
        >
          <div class="row">
            <div class="col-12">
              <div class="card border-0">
                <p class="mt-5 ms-5 me-5">
                  En las siguientes gráficas se puede observar lo siguiente:
                </p>
                <li class="mt-2 ms-5 me-5">
                  El grado de ocupación por provincia y punto turístico. El
                  grado de ocupación se mide como el número de habitaciones
                  ocupadas dividido entre el número total de habitaciones de un
                  establecimiento.
                </li>
                <li class="mt-2 ms-5 me-5">
                  Los indicadores ADR y RevPar. ADR (average daily rate) son los
                  ingresos del hotel por concepto de reservas de habitaciones
                  dividido entre el número de habitaciones ocupadas. RevPar
                  (revenue per available room) son los ingresos del hotel por
                  concepto de reservas de habitaciones dividido entre el número
                  total de habitaciones.
                </li>
                <div class="card-body p-6">
                  <app-grafico-grado-ocupacion></app-grafico-grado-ocupacion>
                  <app-grafico-punto-provincia></app-grafico-punto-provincia>

                  <!-- <app-grafico-mapa></app-grafico-mapa> -->
                  <!-- <app-grafico-mapa></app-grafico-mapa> -->
                  <!-- <app-grafico-puntos-turisticos></app-grafico-puntos-turisticos> -->
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- tab3 Turista 360-->
        <div
          class="tab-pane fade"
          id="v-pills-turismo"
          role="tabpanel"
          aria-labelledby="v-pills-turismo-tab"
        >
          <div class="row">
            <div class="col-12">
              <div class="card border-0">
                <div class="card-body">
                  <div>
                    <ul class="nav nav-tabs">
                      <li class="nav-item">
                        <a
                          class="nav-link active"
                          id="nav-link-tipo-turista-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#nav-link-tipo-turista"
                          role="tab"
                          type="button"
                          aria-controls="nav-link-tipo-turista"
                          aria-selected="true"
                          >Turista 360</a
                        >
                      </li>
                      <!-- <li class="nav-item">
                        <a
                          class="nav-link"
                          id="nav-link-perfil-turista-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#nav-link-perfil-turista"
                          role="tab"
                          type="button"
                          aria-controls="nav-link-perfil-turista"
                          aria-selected="false"
                          >Perfil turista</a
                        >
                      </li> -->
                      <li class="nav-item">
                        <a
                          class="nav-link"
                          id="nav-link-consumo-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#nav-link-consumo"
                          role="tab"
                          type="button"
                          aria-controls="nav-link-consumo"
                          aria-selected="false"
                          >Patrones de consumo</a
                        >
                      </li>
                      <li class="nav-item">
                        <a
                          class="nav-link"
                          id="nav-link-estancia-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#nav-link-estancia"
                          role="tab"
                          type="button"
                          aria-controls="nav-link-estancia"
                          aria-selected="false"
                          >Estancia media</a
                        >
                      </li>
                      <li class="nav-item">
                        <a
                          class="nav-link"
                          id="nav-link-porcentaje-turistas-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#nav-link-porcentaje-turistas"
                          role="tab"
                          type="button"
                          aria-controls="nav-link-porcentaje-turistas"
                          aria-selected="false"
                          >Procedencia turistas nacionales</a
                        >
                      </li>
                    </ul>
                    <div class="tab-content">
                      <div
                        class="tab-pane fade show active"
                        id="nav-link-tipo-turista"
                        role="tabpanel"
                        aria-labelledby="nav-link-tipo-turista-tab"
                      >
                        <p class="mt-5">
                          Abajo, exploramos por qué motivos los turistas viajan
                          a Castilla la Mancha y tipos de viajes organizan.
                        </p>

                        <div class="row mt-5">
                          <div class="col-12">
                            <div class="card border-0">
                              <div class="card-body">
                                <app-grafico-tipo-turista></app-grafico-tipo-turista>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row mt-5">
                          <div class="col-6">
                            <div class="card border-0">
                              <div class="card-body">
                                <!-- grafica barras -->
                                <app-grafico-por-edad></app-grafico-por-edad>
                              </div>
                            </div>
                          </div>
                          <div class="col-6">
                            <!-- <div class="card border-0">
                              <div class="card-body"> -->
                            <!-- grafica pie -->
                            <!--    <app-grafico-paquete></app-grafico-paquete>
                              </div>
                            </div> -->
                          </div>
                          <p class="mt-2">
                            *Datos extraídos de la Encuesta de Turismo de
                            Residentes (ETR/FAMILITUR) del INE.
                          </p>
                        </div>
                      </div>
                      <div
                        class="tab-pane fade"
                        id="nav-link-perfil-turista"
                        role="tabpanel"
                        aria-labelledby="nav-link-perfil-turista-tab"
                      >
                        <div class="row mt-5">
                          <div class="col-12">
                            <div class="card border-0">
                              <div class="card-body">
                                <!-- <app-grafica3-d></app-grafica3-d> -->
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="tab-pane fade"
                        id="nav-link-consumo"
                        role="tabpanel"
                        aria-labelledby="nav-link-consumo-tab"
                      >
                        <p class="mt-5">
                          En este apartado visualizamos los patrones de consumo
                          de los viajeros de Castilla la Mancha.
                        </p>
                        <div class="row mt-5">
                          <div class="col-12">
                            <div class="card border-0">
                              <div class="card-body">
                                <app-grafico-gastos-motivos></app-grafico-gastos-motivos>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row mt-5">
                          <div class="col-12">
                            <div class="card border-0">
                              <div class="card-body">
                                <app-grafico-gastos-motiv></app-grafico-gastos-motiv>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="tab-pane fade"
                        id="nav-link-estancia"
                        role="tabpanel"
                        aria-labelledby="nav-link-estancia-tab"
                      >
                        <p class="mt-5">
                          En este apartado visualizamos el número de noches
                          promedio que pernoctan los viajeros de Castilla la
                          Mancha.
                        </p>

                        <div class="row mt-5">
                          <div class="col-12">
                            <div class="card border-0">
                              <div class="card-body">
                                <app-grafico-duracion-motiv></app-grafico-duracion-motiv>
                              </div>
                            </div>
                          </div>
                          <p class="mt-2">
                            *Datos extraídos de la Encuesta de Turismo de
                            Residentes (ETR/FAMILITUR) del INE.
                          </p>
                        </div>
                      </div>

                      <div
                        class="tab-pane fade"
                        id="nav-link-porcentaje-turistas"
                        role="tabpanel"
                        aria-labelledby="nav-link-porcentaje-turistas-tab"
                      >
                        <p class="mt-5">
                          En el siguiente apartado observamos la comunidad
                          autónoma de procedencia de los viajeros de cada
                          provincia de Castilla la Mancha.
                        </p>
                        <div class="row mt-5">
                          <div class="col-12">
                            <div class="card border-0">
                              <div class="card-body">
                                <app-grafico-turistas-por-ccaa></app-grafico-turistas-por-ccaa>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- tab3 Volumen Turista-->
        <div
          class="tab-pane fade"
          id="v-pills-volumen"
          role="tabpanel"
          aria-labelledby="v-pills-volumen-tab"
        >
          <div class="row">
            <div class="col-12">
              <div class="card border-0">
                <div class="card-body">
                  <div>
                    <ul class="nav nav-tabs">
                      <li class="nav-item">
                        <a
                          class="nav-link active"
                          id="nav-link-viajeros-provincia-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#nav-link-viajeros-provincia"
                          role="tab"
                          type="button"
                          aria-controls="nav-link-viajeros-provincia"
                          aria-selected="true"
                          >Viajeros y pernoctaciones por provincia</a
                        >
                      </li>
                      <li class="nav-item">
                        <a
                          class="nav-link"
                          id="nav-link-viajeros-establecimiento-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#nav-link-viajeros-establecimiento"
                          role="tab"
                          type="button"
                          aria-controls="nav-link-viajeros-establecimiento"
                          aria-selected="false"
                          >Viajeros y pernoctaciones por punto turístico</a
                        >
                      </li>
                      <li class="nav-item">
                        <a
                          class="nav-link"
                          id="nav-link-origen-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#nav-link-origen"
                          role="tab"
                          type="button"
                          aria-controls="nav-link-origen"
                          aria-selected="false"
                          >Viajeros y pernoctaciones por residencia</a
                        >
                      </li>
                      <li class="nav-item">
                        <a
                          class="nav-link"
                          id="nav-link-alojamiento-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#nav-link-alojamiento"
                          role="tab"
                          type="button"
                          aria-controls="nav-link-alojamiento"
                          aria-selected="false"
                          >Evolución por tipo de alojamiento</a
                        >
                      </li>
                    </ul>
                    <div class="tab-content">
                      <div
                        class="tab-pane fade show active"
                        id="nav-link-viajeros-provincia"
                        role="tabpanel"
                        aria-labelledby="nav-link-viajeros-provincia-tab"
                      >
                        <p class="mt-5">
                          A través de la siguiente gráfica se puede observar el
                          número de viajeros y pernoctaciones* para una
                          determinada provincia a lo largo del tiempo.
                        </p>

                        <p class="mt-2">
                          *Pernoctar: Pasar la noche en determinado lugar,
                          especialmente fuera del propio domicilio.
                        </p>
                        <app-grafico-volumen-turista></app-grafico-volumen-turista>
                      </div>
                      <div
                        class="tab-pane fade"
                        id="nav-link-viajeros-establecimiento"
                        role="tabpanel"
                        aria-labelledby="nav-link-viajeros-establecimiento-tab"
                      >
                        <p class="mt-5">
                          A través de la siguiente gráfica se puede observar el
                          número de viajeros y pernoctaciones* para un
                          determinado punto turístico a lo largo del tiempo.
                        </p>

                        <p class="mt-2">
                          *Pernoctar: Pasar la noche en determinado lugar,
                          especialmente fuera del propio domicilio.
                        </p>
                        <app-grafico-volumen-punto></app-grafico-volumen-punto>
                      </div>
                      <div
                        class="tab-pane fade"
                        id="nav-link-origen"
                        role="tabpanel"
                        aria-labelledby="nav-link-origen-tab"
                      >
                        <p class="mt-5">
                          A través de las siguientes gráficas se puede observar
                          la evolución del número Viajeros o Pernoctaciones*
                          dividida entre turistas extranjeros y turistas
                          nacionales a lo largo del tiempo.
                        </p>

                        <p class="mt-2">
                          *Pernoctar: Pasar la noche en determinado lugar,
                          especialmente fuera del propio domicilio.
                        </p>
                        <app-grafico-origen></app-grafico-origen>
                      </div>
                      <div
                        class="tab-pane fade"
                        id="nav-link-alojamiento"
                        role="tabpanel"
                        aria-labelledby="nav-link-alojamiento-tab"
                      >
                        <p class="mt-5">
                          A través de las siguientes gráficas se puede observar
                          la evolución del número Viajeros o Pernoctaciones*
                          comparada entre Hoteles y Apartamentos a lo largo del
                          tiempo.
                        </p>

                        <p class="mt-2">
                          *Pernoctar: Pasar la noche en determinado lugar,
                          especialmente fuera del propio domicilio.
                        </p>
                        <app-grafico-tipo-alojamiento></app-grafico-tipo-alojamiento>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- tab4 oferta y ocupación establecimientos -->
        <div
          class="tab-pane fade"
          id="v-pills-oferta-ocupacion"
          role="tabpanel"
          aria-labelledby="v-pills-oferta-ocupacion-tab"
        >
          <div class="row">
            <div class="col-12">
              <div class="card border-0">
                <div class="card-body">
                  <div>
                    <ul class="nav nav-tabs">
                      <li class="nav-item">
                        <a
                          class="nav-link active"
                          id="nav-link-tipos-ocupacion-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#nav-link-tipos-ocupacion"
                          role="tab"
                          type="button"
                          aria-controls="nav-link-tipos-ocupacion"
                          aria-selected="true"
                          >Evolución Tipos de Ocupación</a
                        >
                      </li>
                      <li class="nav-item">
                        <a
                          class="nav-link"
                          id="nav-link-estimacion-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#nav-link-estimacion"
                          role="tab"
                          type="button"
                          aria-controls="nav-link-estimacion"
                          aria-selected="false"
                          >Establecimientos, Habitaciones y Plazas
                          Disponibles</a
                        >
                      </li>
                      <!-- <li class="nav-item">
                        <a
                          class="nav-link"
                          id="nav-link-alojamiento-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#nav-link-alojamiento"
                          role="tab"
                          type="button"
                          aria-controls="nav-link-alojamiento"
                          aria-selected="false"
                          >Evolución por tipo de alojamiento</a
                        >
                      </li> -->
                    </ul>
                    <div class="tab-content">
                      <div
                        class="tab-pane fade show active"
                        id="nav-link-tipos-ocupacion"
                        role="tabpanel"
                        aria-labelledby="nav-link-tipos-ocupacion-tab"
                      >
                        <div class="mt-5">
                          <p>
                            A través de la siguiente gráfica se puede observar
                            una comparativa entre tres métricas de ocupación
                            para una provincia a lo largo del tiempo.
                          </p>
                          <ul>
                            <li>
                              Ocupación por plazas hace referencia al nivel de
                              ocupación por plazas para cada mes.
                            </li>
                            <li>
                              Ocupación por plazas en fin de semana hace
                              referencia al nivel de ocupación por plazas los
                              fines de semana de cada mes.
                            </li>
                            <li>
                              Ocupación por habitaciones hace referencia al
                              nivel ocupación por habitación para cada mes.
                            </li>
                          </ul>
                        </div>

                        <app-grafico-indicadores-ocupacion></app-grafico-indicadores-ocupacion>
                      </div>
                      <div
                        class="tab-pane fade"
                        id="nav-link-estimacion"
                        role="tabpanel"
                        aria-labelledby="nav-link-estimacion-tab"
                      >
                        <div class="mt-5">
                          <p>
                            En la siguiente sección se ofrece una comparativa
                            entre el número de establecimientos, habitaciones y
                            plazas estimadas para una provincia a lo largo del
                            tiempo.
                          </p>
                          <p>
                            *Plazas: número estimado de turistas por habitación
                            habilitadas para su alquiler en todos los tipos de
                            alojamiento de la provincia.
                          </p>
                        </div>
                        <div class="row mt-5">
                          <!-- <div class="col-12"> -->
                          <div class="card border-0">
                            <div class="card-body">
                              <app-grafico-estimacion-habitaciones-plazas></app-grafico-estimacion-habitaciones-plazas>
                            </div>
                          </div>
                          <!-- </div> -->
                        </div>
                        <div class="row mt-5">
                          <!-- <div class="col-12"> -->
                          <div class="card border-0">
                            <div class="card-body">
                              <app-grafico-establecimientos-abiertos></app-grafico-establecimientos-abiertos>
                            </div>
                          </div>
                          <!-- </div> -->
                        </div>
                      </div>
                      <div
                        class="tab-pane fade"
                        id="nav-link-abiertos"
                        role="tabpanel"
                        aria-labelledby="nav-link-abiertos-tab"
                      >
                        <p class="mt-5">
                          A través de la siguiente gráfica se puede observar la
                          evolución del número estimado de establecimientos
                          turísticos para una provincia determinada a lo largo
                          del tiempo.
                        </p>
                        <app-grafico-establecimientos-abiertos></app-grafico-establecimientos-abiertos>
                      </div>
                      <!-- <div
                        class="tab-pane fade"
                        id="nav-link-alojamiento"
                        role="tabpanel"
                        aria-labelledby="nav-link-alojamiento-tab"
                      >
                        <app-grafico-tipo-alojamiento></app-grafico-tipo-alojamiento>
                      </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- calendario tab -->
        <div
          class="tab-pane fade"
          id="v-pills-calendario"
          role="tabpanel"
          aria-labelledby="v-pills-calendario-tab"
        >
          <div class="row">
            <app-calendario
              *ngIf="selectedTab === 'v-pills-calendario'"
            ></app-calendario>
          </div>
        </div>
        <!-- Ver excel-->
        <div
          class="tab-pane fade"
          id="v-pills-excel"
          role="tabpanel"
          aria-labelledby="v-pills-excel-tab"
        >
          <div class="row">
            <span class="mt-2 mb-4">
              Si quieres modificar los datos, sube un nuevo excel</span
            >
            <div
              class="col-12 file-uploader d-flex flex-column align-items-center text-center p-3 rounded"
              style="
                background-color: rgba(0, 0, 0, 0.02);
                border: 1px dashed grey;
              "
            >
              <input
                type="file"
                (change)="onFileSelected($event)"
                accept=".csv"
              />

              <span class="mt-2 text-secondary"
                >Tamaño máximo de archivo 10Mb</span
              >
            </div>
          </div>
          <div>
            <div class="col-12 mt-4">
              <span class="mt-4 mb-4"
                >Si quieres ver el contenido del Excel de los datos de tu hotel,
                click en el siguiente botón:</span
              >
              <button
                class="mb-4 mt-3 ms-3 btn btn-primary"
                style="align-self: start"
                (click)="fetchAndDisplayExcel()"
              >
                Visualizar
              </button>
              <table *ngIf="data.length" class="table table-striped">
                <thead>
                  <tr>
                    <th *ngFor="let column of columns">{{ column }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of data">
                    <td *ngFor="let column of columns">{{ row[column] }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
