//función para pasar de milisegundos a dd/mm/aaaa
export function formatearFecha(fechacheckin: number) {
  console.log(fechacheckin)
  const fecha = new Date(fechacheckin);

  const dia = String(fecha.getDate()).padStart(2, '0');
  const mes = String(fecha.getMonth() + 1).padStart(2, '0'); // Los meses empiezan en 0
  const anio = fecha.getFullYear();

  return `${dia}/${mes}/${anio}`;
}


export function transformarTexto(texto: string): string {
  // Encuentra la posición del paréntesis de apertura
  const inicio = texto.indexOf('(');
  const fin = texto.indexOf(')');

  if (inicio !== -1 && fin !== -1) {
    // Extrae el contenido entre paréntesis
    const contenido = texto.slice(inicio + 1, fin).trim();
    // Elimina el texto entre paréntesis y los paréntesis mismos
    const textoSinParentesis = texto.slice(0, inicio).trim();
    // Retorna el resultado en el formato deseado
    texto = contenido + ' ' + textoSinParentesis;
    return texto;
  } else {
    console.log("No se encuentra el patrón");
    return texto
  }
  // Devuelve el texto original si no se encuentra el patrón
}

export function capitalizarPalabra(palabra: string): string {
  return palabra
    .split(' ')
    .map(p => p.charAt(0).toUpperCase() + p.slice(1).toLowerCase())
    .join(' ');
}

export function formatFechaTiempo(fecha: string): string {
  const date = new Date(fecha);
  const options: Intl.DateTimeFormatOptions = {weekday: 'short'};

  const diaSemana = date.toLocaleDateString('es-ES', options);
  const diaSemanaUp = diaSemana.toUpperCase();
  return diaSemanaUp;
}

export function formatTemp(temperatura:number): number {
  const temp = Math.round(temperatura);
  return temp;
}