@if (resetCodeSentToUser) {
  <form [formGroup]="resetPassword" (ngSubmit)="sendNewPasswordForm()">
    <div id="login-wrapper" class="login-wrapper">
      <p>Introduce el código de verificación y tu nueva contraseña</p>
      <div class="mb-3">
        <label for="code">Código de verificación</label>
        <input
          type="text"
          class="form-control"
          name="code"
          formControlName="code"
        />
        <label class="mt-2" for="password">Nueva contraseña</label>
        <input
          type="password"
          class="form-control"
          name="password"
          formControlName="password"
        />
        <label class="mt-2" for="newPassword">Confirmar nueva contraseña</label>
        <input
          type="password"
          class="form-control"
          name="newPassword"
          formControlName="confirmPassword"
        />
        <button
          [disabled]="resetPassword.invalid"
          class="btn login_btn btn-primary text-center w-100 rounded-pill mt-3"
        >
          Cambiar contraseña
        </button>
        <button
          class="btn login_btn text-center w-100 rounded-pill mt-3"
          (click)="close()"
        >
          Volver
        </button>
      </div>
    </div>
  </form>
} @else {
  <form [formGroup]="sendCode" (ngSubmit)="sendCodeForm()">
    <div id="login-wrapper" class="login-wrapper">
      <p>Introduce tu correo para recibir un código de verificación</p>
      <div class="mb-3">
        <input
          type="text"
          class="form-control"
          name="email"
          formControlName="email"
        />
        <button
          [disabled]="sendCode.invalid"
          class="btn login_btn btn-primary text-center w-100 rounded-pill mt-3"
        >
          @if (waiting) {
            <img
              width="20"
              src="assets/images/icons/loading-white.svg"
              alt="Loading"
            />
          } @else {
            Enviar código
          }
        </button>
        <button
          class="btn login_btn text-center w-100 rounded-pill mt-3"
          (click)="close()"
        >
          Volver
        </button>
      </div>
    </div>
  </form>
}
