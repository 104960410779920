<div class="container">
    <div class="row">
        <div class="col-12 mb-3">
            <ul class="list-inline breadcrumbs text-capitalize" style="font-weight:500">
                <li class="list-inline-item"><a routerLink="/home"><i class="fa-solid fa-house"></i></a>
                </li>
                <li class="list-inline-item">| &nbsp; <a>Últimas noticias</a>
                </li>
            </ul>
        </div>
    </div>
</div>
<section class="divider-section">
	<div class="container">
        <h2 class="divider fs-3">
            <span class=" position-absolute">Noticias del sector</span>
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="413px" height="61px" viewBox="0 0 413 61" version="1.1">
                <title>Group</title>
                <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="separadores" transform="translate(-12, -22)" fill="#FFFFFF">
                        <g id="Group" transform="translate(12, 22)">
                            <path d="M412.045,0 L0,0 L0,60.4823 L351.563,60.4823 C375.552,60.4823 395,41.0348 395,17.045 C395,7.63132 402.631,0 412.045,0 Z" id="Path"></path>
                        </g>
                    </g>
                </g>
            </svg>
         </h2>
    </div>
</section>

<section class="bg-tertiary blog-section">
    <div class="container">
        <div id="posts" class="row gutter-40">
            <div class="entry col-12">
                <div class="row g-0 p-3 bg-white rounded-3 mb-5">
                    <div class="col-md-4">
                        <div class="entry-image">
                            <a href="article" data-lightbox="image"><img src="assets/images/blog-1.jpeg" alt="post-image"></a>
                        </div>
                    </div>
                    <div class="col-md-8 ps-md-4 pt-4 pt-md-0">
                        <div class="entry-title title-sm">
                            <h3><a href="article" class="link-underline link-underline-opacity-0 link-underline-opacity-75-hover link-body-emphasis">C-LM se posiciona como una alternativa para el sector de los centros de datos a nivel europeo</a></h3>
                        </div>
                        <div class="entry-meta">
                            <ul>
                                <li><i class="fa fa-clock me-1"></i> 14 Julio 2024</li>
                                <li><i class="fa fa-user"></i><span class="mx-2">Por</span><a href="#">Administrador</a></li>
                            </ul>
                        </div>
                        <div class="entry-content">
                            <p>Castilla-La Mancha se está consolidando como uno de los destinos más atractivos para la instalación de centros de datos en Europa. Gracias a su ubicación estratégica, la estabilidad de su red eléctrica, y un entorno ideal para la innovación, la región se convierte en un foco de interés para grandes empresas tecnológicas.</p>
                            <a class="btn btn-sm btn-primary rounded-pill py-2 px-4 btn-rotate  align-items-center mt-3" href="article">
                                <i class="fa-solid fa-plus me-2"></i>
                                Leer más 
                                
                            </a>
                        </div>
                    </div>
                </div>
                <div class="row g-0 p-3 bg-white rounded-3 mb-5">
                    <div class="col-md-4">
                        <div class="entry-image">
                            <a href="article" data-lightbox="image"><img src="assets/images/post2.avif" alt="post-image"></a>
                        </div>
                    </div>
                    <div class="col-md-8 ps-md-4 pt-4 pt-md-0">
                        <div class="entry-title title-sm">
                            <h3><a href="article" class="link-underline link-underline-opacity-0 link-underline-opacity-75-hover link-body-emphasis">
                                La gestión de datos y su importancia como herramienta de toma de decisiones
                            </a></h3>
                        </div>
                        <div class="entry-meta">
                            <ul>
                                <li><i class="fa fa-clock me-1"></i> 1 Agosto 2024</li>
                                <li><i class="fa fa-user"></i><span class="mx-2">Por</span><a href="#">Administrador</a></li>
                            </ul>
                        </div>
                        <div class="entry-content">
                            <p>
                                En el mundo empresarial actual, la información es poder. La gestión de datos se ha convertido en un pilar fundamental para las empresas que buscan tomar decisiones más acertadas, ágiles y estratégicas.
Desde la gestión de inventarios hasta el desarrollo de estrategias de marketing, los datos ofrecen el soporte necesario para optimizar cada aspecto del negocio.
                            </p>
                            <a class="btn btn-sm btn-primary rounded-pill py-2 px-4 btn-rotate  align-items-center mt-3" href="article">
                                <i class="fa-solid fa-plus me-2"></i>
                                Leer más 
                                
                            </a>
                        </div>
                    </div>
                </div>
                <div class="row g-0 p-3 bg-white rounded-3 mb-5">
                    <div class="col-md-4">
                        <div class="entry-image">
                            <a href="article" data-lightbox="image"><img src="assets/images/post3.avif" alt="post-image"></a>
                        </div>
                    </div>
                    <div class="col-md-8 ps-md-4 pt-4 pt-md-0">
                        <div class="entry-title title-sm">
                            <h3><a href="article" class="link-underline link-underline-opacity-0 link-underline-opacity-75-hover link-body-emphasis">
                                Transformación del turismo con tecnologías digitales: del big data a la realidad virtual
                            </a></h3>
                        </div>
                        <div class="entry-meta">
                            <ul>
                                <li><i class="fa fa-clock me-1"></i> 8 Agosto 2024</li>
                                <li><i class="fa fa-user"></i><span class="mx-2">Por</span><a href="#">Administrador</a></li>
                            </ul>
                        </div>
                        <div class="entry-content">
                            <p>
                                La era digital está revolucionando la industria del turismo. Big Data, Realidad Virtual (RV) y otras tecnologías emergentes están redefiniendo cómo los viajeros planean sus escapadas y cómo las empresas turísticas mejoran sus servicios.     
                            </p>
                            <a class="btn btn-sm btn-primary rounded-pill py-2 px-4 btn-rotate  align-items-center mt-3" href="article">
                                <i class="fa-solid fa-plus me-2"></i>
                                Leer más 
                                
                            </a>
                        </div>
                    </div>
                </div>
                <div class="row g-0 p-3 bg-white rounded-3 mb-5">
                    <div class="col-md-4">
                        <div class="entry-image">
                            <a href="article" data-lightbox="image"><img src="assets/images/hub.avif" alt="post-image"></a>
                        </div>
                    </div>
                    <div class="col-md-8 ps-md-4 pt-4 pt-md-0">
                        <div class="entry-title title-sm">
                            <h3><a href="article" class="link-underline link-underline-opacity-0 link-underline-opacity-75-hover link-body-emphasis">
                                La gestión de datos y su importancia como herramienta de toma de decisiones
                            </a></h3>
                        </div>
                        <div class="entry-meta">
                            <ul>
                                <li><i class="fa fa-clock me-1"></i> 24 Agosto 2024</li>
                                <li><i class="fa fa-user"></i><span class="mx-2">Por</span><a href="#">Administrador</a></li>
                            </ul>
                        </div>
                        <div class="entry-content">
                            <p>
                                En el mundo empresarial actual, la información es poder. La gestión de datos se ha convertido en un pilar fundamental para las empresas que buscan tomar decisiones más acertadas, ágiles y estratégicas.
Desde la gestión de inventarios hasta el desarrollo de estrategias de marketing, los datos ofrecen el soporte necesario para optimizar cada aspecto del negocio.
                            </p>
                            <a class="btn btn-sm btn-primary rounded-pill py-2 px-4 btn-rotate  align-items-center mt-3" href="article">
                                <i class="fa-solid fa-plus me-2"></i>
                                Leer más 
                                
                            </a>
                        </div>
                    </div>
                </div>





                <div class="mx-auto d-flex justify-content-between py-4 mb-5">
                    <a href="" class="btn btn-sm btn-primary btn-animate previo rounded-pill py-2 px-3 mt-3 border-0"> <i class="fa-solid fa-chevron-left me-2"></i> Últimas noticias </a>
                    <a href="" class="btn btn-sm btn-primary btn-animate rounded-pill py-2 px-3 mt-3 border-0"> Siguiente artículo <i class="fa-solid fa-chevron-right"></i></a>
                </div>

            </div>
        </div>
            <!-- <div class="row g-4  pt-3">
                    <a href="#" class="col-12 rounded-2 bg-white blog-item d-flex link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover link-body-emphasis">
                        <div class="row">
                            <div class="blog-img col-4">
                                <img src="assets/images/blog-1.jpeg" class="img-fluid rounded-4" alt="" style="max-height:350px;">
                            </div>
                            <div class="blog-content col-8">
                                <p class="mb-4 text-uppercase">C-LM se posiciona como una alternativa para el sector de los centros de datos a nivel europeo.</p>
                                <p>Castilla-La Mancha se está consolidando como uno de los destinos más atractivos para la instalación de centros de datos en Europa. Gracias a su ubicación estratégica, la estabilidad de su red eléctrica, y un entorno ideal para la innovación, la región se convierte en un foco de interés para grandes empresas tecnológicas.</p>
                            </div>
                        </div>
                        
                    </a>
            </div> -->
    </div>