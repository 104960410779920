<div class="mx-4 row">
  <div class="col-6">
    <canvas #origenViajero id="origenViajero"></canvas>
  </div>
  <div class="col-6">
    <canvas #origenPernoc id="origenPernoc"></canvas>
  </div>

  <div #rangeSlider id="rangeSlider" class="slider mt-5"></div>
</div>
<div class="mt-5">
  <small
    >Estos datos han sido extraidos de la encuesta de ocupación hotelera del
    INE.</small
  >
</div>
