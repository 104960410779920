<!-- <img src="../../../assets/images/403-error-forbidden-with-police-concept-illustration_114360-1904.jpg" alt="No autorizado" /> -->
<div class="container mb-5">
    <div class="text-center flex-wrapper">
            <div class="d-flex flex-column col-10 col-md-6 col-lg-3 mx-auto pt-5">
                <h1 class="mt-5 pt-5"><img class="img-fluid" width="140" src="assets/images/logo-senda.svg" alt=""></h1>
                <span class="error">404</span>
                <p class="lead-error">Página no encontrada</p>
                <p class="px-3 p-md-2">El enlace que ha pulsado puede estar roto o la página puede haber sido eliminada o renombrada.</p>
            </div>
            <a href="home" class="btn btn-sm btn-primary btn-animate rounded-pill py-2 px-3 mt-3  ">Ir a la home <i class="fa-solid fa-chevron-right"></i></a>

    </div>
  </div>