<h2 mat-dialog-title>Tienes Cambios pendientes</h2>
<div mat-dialog-content>
  <p>
    Tienes cambios sin guardar. Si cambias de hotel, perderás los cambios
    realizados. ¿Deseas continuar?
  </p>
</div>
<div mat-dialog-actions text-align="end">
  <button mat-button (click)="cancelar()">Cancelar</button>
  <button mat-button color="warn" (click)="confirmar()">Confirmar</button>
</div>
