import { Component, OnDestroy } from '@angular/core';
import { Form, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { log } from 'console';
import { Hotel } from '../../services/hotel/hotelModel';

@Component({
  selector: 'app-management-area',
  templateUrl: './management-area.component.html',
  styleUrl: './management-area.component.scss'
})

export class ManagementAreaComponent  {

  hotelData: Hotel = {} as Hotel; 
  
  saltar:boolean=true;
  formGroup: FormGroup

  newData:any = {}
  constructor(private router:Router, private fb: FormBuilder) {
    this.formGroup = this.createFormGroup();


      //cargar datos del hotel referente
      const navigation = this.router.getCurrentNavigation();
          if (navigation?.extras.state) {
            this.hotelData =navigation.extras.state['hotelData']; // Accede a los datos pasados
            console.log("datos asados.. del botnn...", this.hotelData)}
  }

  forzar() {
    this.router.navigate(['/management-area'])
  }

  createFormGroup() {
    // return this.fb.group<any>({
    //   answers: this.fb.array<any>([]),
    // })
    return this.fb.group<any[]>([{
      ocupation: this.fb.control<number>(0,{
        validators: Validators.required,
        nonNullable: true,
      }),
      incomingTotal: this.fb.control<number>(0,{
        validators: Validators.required,
        nonNullable: true,
      }),
      spendingTotal: this.fb.control<number>(0,{
        validators: Validators.required,
        nonNullable: true,
      }),
      confirmedReserves: this.fb.control<number>(0,{
        validators: Validators.required,
        nonNullable: true,
      }),
      cancelations: this.fb.control<number>(0,{
        validators: Validators.required,
        nonNullable: true,
      }),
      newCustomers: this.fb.control<number>(0,{
        validators: Validators.required,
        nonNullable: true,
      }),
      recurrentCustomers: this.fb.control<number>(0,{
        validators: Validators.required,
        nonNullable: true,
      }),
      discountsApplied: this.fb.control<number>(0,{
        validators: Validators.required,
        nonNullable: true,
      }),
      checkinoutSchedule: this.fb.control<number>(0,{
        validators: Validators.required,
        nonNullable: true,
      }),
    }])
  }

  addData(){
    // console.log("data", this.newData);
    let prueba = this.newData
    // this.fakeService.dataAlojamiento.push(prueba)
    // console.log("data", this.fakeService.dataAlojamiento);
    // this.fakeService.hotelData.push()
  }
  
 
  // on destroy enrutar a management

  //manejar rutas a privatehome


}
