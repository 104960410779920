import { Component, HostListener } from '@angular/core'
import * as XLSX from 'xlsx'
import { FileUploadService } from '../../services/files/fileUpload.service'
import { HttpClient } from '@angular/common/http'
import { CSVData } from '../../services/files/iCSV'
import { catchError, Observable, of } from 'rxjs'

interface Tab {
  title: string
  filePath: string
}

@Component({
  selector: 'app-datos-clm',
  templateUrl: './datos-clm.component.html',
  styleUrl: './datos-clm.component.scss',
})
export class DatosCLMComponent {
  tabs: Tab[] = [
    { title: 'Albergues Turisticos', filePath: 'Albergues_turisticos' },
    {
      title: 'Alojamientos Turismo Rural',
      filePath: 'Alojamientos_turismo_rural',
    },
    {
      title: 'Alojamientos Turisticos Hoteleros',
      filePath: 'Alojamientos_turisticos_hoteleros',
    },
    {
      title: 'Apartamentos Turisticos',
      filePath: 'Apartamentos_turisticos',
    },
    {
      title: 'Campings y Áreas de Autocaravanas',
      filePath: 'Campings_y_areas_autocaravanas',
    },
    {
      title: 'Fiestas Interes Turistico',
      filePath: 'Fiestas_interes_turistico',
    },
  ]

  selectedTab: Tab = this.tabs[0]
  data$: Observable<any>
  headers: string[] = []

  constructor(private fileService: FileUploadService) {}

  ngOnInit() {
    console.log('n54tra en onitin...', this.selectedTab.filePath)
    this.loadJSON(this.selectedTab.filePath)
  }

  selectTab(tab: Tab) {
    this.selectedTab = tab
    this.loadJSON(tab.filePath)
  }

  loadJSON(filePath: string) {
    console.log('Cargando JSON...', filePath)
    this.data$ = this.fileService.obtenerJson(filePath)

    this.data$.subscribe((data: any) => {
      // Reemplazar NaN por vacío en cada objeto
      data.forEach((item: any) => {
        Object.keys(item).forEach((key) => {
          if (isNaN(item[key])) {
            item[key] = '' // Asigna un valor vacío si el valor es NaN
          }
        })
      })

      // Después de limpiar los datos, asigna las cabeceras
      this.headers = data.length > 0 ? Object.keys(data[0]) : []
    })
  }
}
