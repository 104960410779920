import { Component, ElementRef, ViewChild } from '@angular/core'
import { FileUploadService } from '../../services/files/fileUpload.service'
import { HttpClient } from '@angular/common/http'
import { Chart, registerables } from 'chart.js'
import * as noUiSlider from 'nouislider'

@Component({
  selector: 'app-grafico-punto-provincia',
  templateUrl: './grafico-punto-provincia.component.html',
  styleUrl: './grafico-punto-provincia.component.scss',
})
export class GraficoPuntoProvinciaComponent {
  chartOcuPunto: Chart | undefined

  chartPunto: Chart | undefined
  provinces: string[] = [
    'Albacete',
    'Ciudad Real',
    'Cuenca',
    'Guadalajara',
    'Toledo',
  ]
  months: string[] = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ]
  years: any[] = []
  yearsWithoutUndefined: any[] = []
  yearsWithoutUndefinedEnd: any[] = []
  currentYear: number = new Date().getFullYear()
  selectedProvince = ''
  selectedStartMonth = ''
  selectedStartYear = 0
  selectedEndMonth = ''
  selectedEndYear = 0
  endMonthsAvailable: string[] = []
  availableMonthsByYear: { [year: number]: string[] } = {}
  data: any[] = []
  filteredProvinciaData: any[] = []
  filteredPuntoData: any[] = []
  @ViewChild('puntoCanvas', { static: true })
  puntoCanvas!: ElementRef<HTMLCanvasElement>
  @ViewChild('puntoOcupacionCanvas', { static: true })
  puntoOcupacionCanvas!: ElementRef<HTMLCanvasElement>
  dynamicMonthsByYear: Map<string, string[]>
  @ViewChild('rangeSlider', { static: true }) rangeSlider!: ElementRef
  constructor(
    private fileService: FileUploadService,
    private http: HttpClient
  ) {
    Chart.register(...registerables)
  }
  ngOnInit() {
    this.loadCSVData()
  }
  ngAfterViewInit() {
    if (
      this.selectedProvince &&
      this.selectedStartMonth &&
      this.selectedStartYear
    ) {
      this.updateChartByDateRange()
    }
  }

  loadCSVData() {
    this.http
      .get('../../../assets/data/indicadores.csv', {
        responseType: 'text',
      })
      .subscribe((data) => {
        this.data = this.fileService.parseCSVData(data)
        const yearMonthMap = new Map<string, string[]>()

        this.data.forEach((dato) => {
          if (dato.date) {
            const year = dato.date.slice(0, 4)
            const monthIndex = parseInt(dato.date.slice(5)) - 1
            const month = this.months[monthIndex]

            if (!yearMonthMap.has(year)) {
              yearMonthMap.set(year, [])
            }
            const months = yearMonthMap.get(year)!
            if (!months.includes(month)) {
              months.push(month)
            }
          }
        })
        yearMonthMap.forEach((months, year) => {
          months.sort((a, b) => this.months.indexOf(a) - this.months.indexOf(b))
        })
        this.years = Array.from(yearMonthMap.keys()).sort()
        this.dynamicMonthsByYear = yearMonthMap

        if (this.selectedProvince == '') {
          this.selectedProvince = this.provinces[0]
        }
        this.initializeSlider()
      })
  }
  initializeSlider() {
    const slider = this.rangeSlider.nativeElement
    if (!this.rangeSlider) return

    const sliderValues: string[] = []
    this.years.forEach((year) => {
      const months = this.dynamicMonthsByYear.get(year)!
      months.forEach((month) => {
        sliderValues.push(`${month} ${year}`)
      })
    })

    noUiSlider.create(slider, {
      start: [sliderValues[0], sliderValues[10]],
      step: 1,
      range: {
        min: 0,
        max: sliderValues.length - 1,
      },
      tooltips: [true, true],
      format: {
        to: (value) => {
          return sliderValues[Math.round(value)]
        },
        from: (value) => {
          return sliderValues.indexOf(value)
        },
      },
    })

    slider.noUiSlider!.on('update', (values: any, handle: any) => {
      const start = values[0]
      const end = values[1]

      const [startMonth, startYear] = start.split(' ')
      const [endMonth, endYear] = end.split(' ')

      this.selectedStartYear = parseInt(startYear)
      this.selectedStartMonth = startMonth
      this.selectedEndYear = parseInt(endYear)
      this.selectedEndMonth = endMonth
      this.updateChartByDateRange()
    })
  }
  updateChart() {
    this.updateChartByDateRange()
  }
  updateChartByDateRange() {
    const startDate = parseInt(
      `${this.selectedStartYear}${String(this.months.indexOf(this.selectedStartMonth) + 1).padStart(2, '0')}`,
      10
    )
    const endDate = parseInt(
      `${this.selectedEndYear}${String(this.months.indexOf(this.selectedEndMonth) + 1).padStart(2, '0')}`,
      10
    )
    const { provinciaName, puntoName } = this.getLocationNames(
      this.selectedProvince
    )

    const dataInTimeRangeProv = this.data.filter((dato) => {
      if (dato.date && dato.location === provinciaName) {
        const date = dato.date.slice(0, 4) + dato.date.slice(5)
        return date >= startDate && date <= endDate
      }
      return false
    })

    const dataInTimeRangePunto = this.data.filter((dato) => {
      if (dato.date && dato.location === puntoName) {
        const date = dato.date.slice(0, 4) + dato.date.slice(5)
        return date >= startDate && date <= endDate
      }
      return false
    })
    this.filteredPuntoData = []
    this.filteredPuntoData = dataInTimeRangePunto
    this.filteredProvinciaData = []
    this.filteredProvinciaData = dataInTimeRangeProv
    this.createCharts()
  }
  getLocationNames(provincia: string) {
    switch (provincia.toLowerCase()) {
      case 'albacete':
        return {
          provinciaName: 'albacete_provincia',
          puntoName: 'albacete_punto',
        }
      case 'ciudad real':
        return { provinciaName: 'ciudad_provincia', puntoName: 'ciudad_punto' }
      case 'guadalajara':
        return {
          provinciaName: 'guadalajara_provincia',
          puntoName: 'siguenza_punto',
        }
      case 'toledo':
        return { provinciaName: 'toledo_provincia', puntoName: 'toledo_punto' }
      case 'cuenca':
        return { provinciaName: 'cuenca_provincia', puntoName: 'cuenca_punto' }
      default:
        return { provinciaName: '', puntoName: '' }
    }
  }
  createCharts() {
    if (this.chartPunto) this.chartPunto.destroy()

    if (this.chartOcuPunto) this.chartOcuPunto.destroy()

    const puntoElement = this.puntoCanvas.nativeElement
    const puntoOcuElement = this.puntoOcupacionCanvas.nativeElement

    const colorOcuPunto = this.filteredPuntoData.map((d) =>
      d['type'] && d['type'] === 'historico' ? '#4BC0C0' : '#bbf8f8'
    )
    const colorADRPunto = this.filteredPuntoData.map((d) =>
      d['type'] && d['type'] === 'historico' ? '#00897B' : '#4DB6AC'
    )
    const colorRevParPunto = this.filteredPuntoData.map((d) =>
      d['type'] && d['type'] === 'historico' ? '#FBC02D' : '#FFF176'
    )
    const chartOcuPuntoConfig = {
      type: 'bar',
      data: {
        labels: this.filteredProvinciaData.map((d) => {
          const year = d['date'].slice(0, 4)
          const monthIndex = parseInt(d['date'].slice(5)) - 1
          return `${this.months[monthIndex]} ${year}`
        }),
        datasets: [
          {
            label: 'Grado de Ocupación',
            data: this.filteredPuntoData.map((d) => {
              return parseFloat(d.grado_ocupacion).toFixed(2) // Limitar a 2 decimales
            }),
            backgroundColor: colorOcuPunto,
          },
        ],
      },
      options: {
        responsive: true,
        scales: {
          y: {
            title: { display: true, text: 'Porcentaje' },
            beginAtZero: true,
            ticks: {
              callback: (value: any) => `${value}%`,
            },
          },
        },
        plugins: {
          datalabels: {
            display: false,
          },
          title: {
            display: true,
            text: 'Grado de Ocupación por punto turístico',
            font: {
              size: 24,
            },
            padding: {
              top: 30,
              bottom: 30,
            },
          },
          tooltip: {
            callbacks: {
              label: function (context: any) {
                const value = context.raw
                return `${parseFloat(value).toFixed(2)}%`
              },
            },
          },
        },
      },
    }
    this.chartOcuPunto = new Chart(puntoOcuElement, chartOcuPuntoConfig as any)

    const chartPuntoConfig = {
      type: 'bar',
      data: {
        labels: this.filteredProvinciaData.map((d) => {
          const year = d['date'].slice(0, 4)
          const monthIndex = parseInt(d['date'].slice(5)) - 1 // convierte a índice (0-11)
          return `${this.months[monthIndex]} ${year}`
        }),
        datasets: [
          {
            label: 'ADR',
            data: this.filteredPuntoData.map((d) => d.adr),
            backgroundColor: colorADRPunto,
          },
          {
            label: 'RevPar',
            data: this.filteredPuntoData.map((d) => d.revpar),
            backgroundColor: colorRevParPunto,
          },
        ],
      },
      options: {
        responsive: true,
        scales: { y: { beginAtZero: true } },
        plugins: {
          datalabels: {
            display: false,
          },
          title: {
            display: true,
            text: 'ADR y RevPar por punto turístico',
            font: {
              size: 24,
            },
            padding: {
              top: 30,
              bottom: 30,
            },
          },
          tooltip: {
            callbacks: {
              label: function (context: any) {
                const value = context.raw
                return `${value}€`
              },
            },
          },
        },
      },
    }
    this.chartPunto = new Chart(puntoElement, chartPuntoConfig as any)
  }
  onProvinceChange(event: any): void {
    this.selectedProvince = event.target.value
    this.updateChartByDateRange()
  }
}
