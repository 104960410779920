import { Component, Output, EventEmitter, Input } from '@angular/core'
import {
  FormGroup,
  FormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms'

@Component({
  selector: 'app-signin-form',
  standalone: true,
  imports: [ReactiveFormsModule],
  templateUrl: './signin-form.component.html',
  styleUrl: './signin-form.component.scss',
})
export class SigninFormComponent {
  signinForm: FormGroup
  @Input() waiting: boolean = false
  @Output() clickLogin: EventEmitter<any> = new EventEmitter()
  @Output() resetPassword: EventEmitter<any> = new EventEmitter()

  loginError: boolean = false

  constructor(private fb: FormBuilder) {
    this.signinForm = this.fb.group({
      email: ['', [Validators.required]],
      password: ['', Validators.required],
    })
  }

  startResetPasswordFlow() {
    this.resetPassword.emit()
  }

  signIn() {
    this.clickLogin.emit(this.signinForm.value)
  }
}
