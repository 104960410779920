<section class="section-sm">
  <div class="container">
    <div class="row">
      <div class="col-12 mb-3">
        <ul
          class="list-inline breadcrumbs text-capitalize"
          style="font-weight: 500"
        >
          <li class="list-inline-item">
            <a routerLink="/app">Área privada</a>
          </li>
          <li class="list-inline-item">
            | &nbsp;
            <a routerLink="/app"
              ><span class="hotel">{{ hotelData.name }}</span></a
            >
          </li>
          <!-- <li class="list-inline-item">» &nbsp; <a href="">Hotel Real Luxor</a>
                    </li> -->
          <li class="list-inline-item">
            » &nbsp; <a href="#">Gestión de datos</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="d-lg-flex align-items-start">
      <div
        class="col-12 col-lg-3 p-4 sticky-lg-top custom-nav"
        style="top: 30px"
      >
        <div class="mb-5 mb-lg-0">
          <div
            class="nav flex-column nav-pills me-3"
            id="v-pills-tab"
            role="tablist"
            aria-orientation="vertical"
          >
            <button
              class="nav-link rounded-pill text-start active"
              id="v-pills-analyse-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-analyse"
              type="button"
              role="tab"
              aria-controls="v-pills-analyse"
              aria-selected="true"
            >
              <i class="fa-solid fa-table-list"></i
              ><span class="ms-3">Ver datos</span>
            </button>
            <!-- <button class="nav-link rounded-pill text-start" id="v-pills-register-tab" data-bs-toggle="pill" data-bs-target="#v-pills-register" type="button" role="tab" aria-controls="v-pills-register" aria-selected="false" tabindex="-1">
                        <i class="fa-solid fa-file-circle-plus "></i><span class="ms-3">Registrar datos</span></button> -->
            <!-- <button class="nav-link rounded-pill text-start " id="v-pills-update-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home" aria-selected="true">
                        <i class="fa-solid fa-file-arrow-up "></i><span class="ms-3">Subir datos</span></button> -->
          </div>
        </div>
      </div>

      <!-- CONTENT  -->
      <div
        class="tab-content col-12 col-lg-9 mt-lg-4 mb-5"
        id="v-pills-tabContent"
      >
        <!-- tab#1 -->
        <div
          class="tab-pane fade active show"
          id="v-pills-analyse"
          role="tabpanel"
          aria-labelledby="v-pills-analyse-tab"
          tabindex="0"
        >
          <h2 class="divider fs-3 mb-4">
            Visualización de datos
            <div class="float-end mt-2">
              <!-- <div class="d-flex align-items-center">
                                <img src="assets/images/icons/hotel.svg" alt="hotel" width="20px">
                                <h4 class="ms-3 mb-0">Mi Hotel</h4>
                            </div>  -->
            </div>
          </h2>
          <div class="filter-table d-flex align-items-center mt-4">
            <div class="me-3">
              <span>Mostrar datos desde:</span>
              <select name="select" class="ms-2 py-2 px-2 text-primary rounded">
                <option value="value1" selected>últimos 30 días</option>
                <option value="value2">Value 2</option>
                <option value="value3">Value 3</option>
              </select>
            </div>
            <div class="">
              <span>Mostrar datos desde:</span>
              <select name="select" class="ms-2 border-0 text-primary">
                <option value="value1">Value 1</option>
                <option value="value2" selected>Todos</option>
                <option value="value3">Value 3</option>
              </select>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table table-hover table-borderless mt-4">
              <thead
                class="table-light rounded"
                style="height: 50px; vertical-align: middle"
              >
                <tr>
                  <th scope="col"></th>
                  <th scope="col">Fecha</th>
                  <th scope="col">Ingresos (%)</th>
                  <th scope="col">Gastos (%)</th>
                  <th scope="col">Cancelaciones</th>
                  <th scope="col">Nuevos clientes</th>
                  <th scope="col">Valoración Promedio</th>
                  <th scope="col">Ocupación (%)</th>
                </tr>
              </thead>
              <tbody>
                <!-- HACER NGFOR CON EL SERVICIO PARA MOSTRAR LOS DATOS EN LA TABLA -->
                <!-- <tr>
                                <th class="py-4" scope="row"> <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"></th>
                                <td class="py-4">2024-08-22</td>
                                <td class="py-4">15,500 €</td>
                                <td class="py-4">5,200 €</td>
                                <td class="py-4">12</td>
                                <td class="py-4">22</td>
                                <td class="py-4">67</td>
                                <td class="py-4">100</td>
                            </tr>
                            <tr>
                                <th class="py-4" scope="row"> <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"></th>
                                <td class="py-4">2024-08-22</td>
                                <td class="py-4">15,500 €</td>
                                <td class="py-4">5,200 €</td>
                                <td class="py-4">12</td>
                                <td class="py-4">22</td>
                                <td class="py-4">67</td>
                                <td class="py-4">100</td>
                            </tr> -->
                <!-- <tr>
                                <th class="py-4" scope="row"> <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"></th>
                                <td class="py-4">2024-08-22</td>
                                <td class="py-4">15,500 €</td>
                                <td class="py-4">5,200 €</td>
                                <td class="py-4">12</td>
                                <td class="py-4">22</td>
                                <td class="py-4">67</td>
                                <td class="py-4">100</td>
                            </tr>
                            <tr>
                                <th class="py-4" scope="row"> <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"></th>
                                <td class="py-4">2024-08-22</td>
                                <td class="py-4">15,500 €</td>
                                <td class="py-4">5,200 €</td>
                                <td class="py-4">12</td>
                                <td class="py-4">22</td>
                                <td class="py-4">67</td>
                                <td class="py-4">100</td>
                            </tr>
                            <tr>
                                <th class="py-4" scope="row"> <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"></th>
                                <td class="py-4">2024-08-22</td>
                                <td class="py-4">15,500 €</td>
                                <td class="py-4">5,200 €</td>
                                <td class="py-4">12</td>
                                <td class="py-4">22</td>
                                <td class="py-4">67</td>
                                <td class="py-4">100</td>
                            </tr>
                            <tr>
                                <th class="py-4" scope="row"> <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"></th>
                                <td class="py-4">2024-08-22</td>
                                <td class="py-4">15,500 €</td>
                                <td class="py-4">5,200 €</td>
                                <td class="py-4">12</td>
                                <td class="py-4">22</td>
                                <td class="py-4">67</td>
                                <td class="py-4">100</td>
                            </tr> -->
              </tbody>
            </table>
          </div>

          <div
            class="d-flex align-items-center justify-content-between mt-5"
            (click)="forzar()"
          >
            @if (saltar) {
              <app-generador-pdf></app-generador-pdf>
            }
            <!--  -->
            <!-- <ul class="d-flex download-bar">
                            <li class="me-2">Exportar a:</li>
                            <li><a href="" class="text-decoration-none text-body"><i class="fa-solid fa-file-csv me-2 text-info"></i>CSV</a></li>
                            <li><a href="" class="text-decoration-none text-body"><i class="fa-solid fa-file-excel me-2 text-success"></i>EXCEL</a></li>
                            <li><a href="" class="text-decoration-none text-body"><i class="fa-solid fa-file me-2 text-warning"></i>XML</a></li>
                            <li><a href="" class="text-decoration-none text-body"><i class="fa-solid fa-file-pdf me-2 text-danger"></i>PDF</a></li>
                        </ul> -->
          </div>
        </div>
        <!-- tab#2  -->
        <div
          class="tab-pane fade"
          id="v-pills-register"
          role="tabpanel"
          aria-labelledby="v-pills-register-tab"
          tabindex="0"
        >
          <h2 class="divider fs-3 mb-4">
            Registro de datos
            <div class="float-end mt-2">
              <div class="d-flex align-items-center">
                <img
                  src="assets/images/icons/hotel.svg"
                  alt="hotel"
                  width="20px"
                />
                <h4 class="ms-3 mb-0">Mi Hotel</h4>
              </div>
            </div>
          </h2>

          <form>
            <div class="center mb-4">
              <input
                type="date"
                value="2024-09-12"
                class="form-control-lg fs-6 border-1"
              />
            </div>
            <h6 class="text-uppercase fw-light mt-4 mb-3">
              Datos de gestión del alojamiento
            </h6>
            <!-- *ngFor="let alojamiento in fakeService.dataAlojamiento"  -->
            <div class="row form-row">
              <div class="form-group col-md-6 col-lg-4">
                <label>Ocupación (%)</label>
                <input
                  [(ngModel)]="newData.ocupation"
                  [ngModelOptions]="{ standalone: true }"
                  type="text"
                  class="form-control"
                />
              </div>
              <div class="form-group col-md-6 col-lg-4">
                <label>Ingresos Totales (€)</label>
                <input
                  [(ngModel)]="newData.incomingTotal"
                  [ngModelOptions]="{ standalone: true }"
                  type="text"
                  class="form-control"
                />
              </div>
              <div class="form-group col-md-6 col-lg-4">
                <label>Gastos Totales (€)</label>
                <input
                  [(ngModel)]="newData.spendingTotal"
                  [ngModelOptions]="{ standalone: true }"
                  type="text"
                  class="form-control"
                />
              </div>
              <div class="form-group col-md-6 col-lg-4">
                <label>Reservas Confirmadas</label>
                <input
                  [(ngModel)]="newData.confirmedReserves"
                  [ngModelOptions]="{ standalone: true }"
                  type="text"
                  class="form-control"
                />
              </div>
              <div class="form-group col-md-6 col-lg-4">
                <label>Cancelaciones</label>
                <input
                  [(ngModel)]="newData.cancelations"
                  [ngModelOptions]="{ standalone: true }"
                  type="text"
                  class="form-control"
                />
              </div>
              <div class="form-group col-md-6 col-lg-4">
                <label>Nuevos clientes</label>
                <input
                  [(ngModel)]="newData.newCustomers"
                  [ngModelOptions]="{ standalone: true }"
                  type="text"
                  class="form-control"
                />
              </div>
              <div class="form-group col-md-6 col-lg-4">
                <label>Clientes recurrentes</label>
                <input
                  [(ngModel)]="newData.recurrentCustomers"
                  [ngModelOptions]="{ standalone: true }"
                  type="text"
                  class="form-control"
                />
              </div>
              <div class="form-group col-md-6 col-lg-4">
                <label>Valoración Promedio</label>
                <input
                  [(ngModel)]="newData.averageValorations"
                  [ngModelOptions]="{ standalone: true }"
                  type="text"
                  class="form-control"
                />
              </div>
              <div class="form-group col-md-6 col-lg-4">
                <label>Ofertas Aplicadas</label>
                <input
                  [(ngModel)]="newData.discountsApplied"
                  [ngModelOptions]="{ standalone: true }"
                  type="text"
                  class="form-control"
                />
              </div>
            </div>
            <div class="form-group">
              <label>Horarios de check-in y check-out</label>
              <textarea
                [(ngModel)]="newData.checkinoutSchedule"
                [ngModelOptions]="{ standalone: true }"
                class="form-control h-120px"
                rows="6"
                placeholder="Wi-Fi, desayuno incluido, piscina, gimnasio, estacionamiento"
              ></textarea>
            </div>
            <div class="row my-5">
              <div
                (click)="addData()"
                class="col-12 col-md-col-8 mx-auto d-flex justify-content-center"
              >
                <a
                  class="btn btn-sm btn-primary rounded-pill py-2 px-4 btn-rotate complete align-items-center"
                  href="management-area"
                >
                  <i class="fa-regular fa-paper-plane me-2"></i>
                  Enviar formulario
                </a>
              </div>
            </div>
          </form>
        </div>
        <!-- tab#3 -->
        <div
          class="tab-pane fade"
          id="v-pills-home"
          role="tabpanel"
          aria-labelledby="v-pills-update-tab"
          tabindex="0"
        >
          <h2 class="divider fs-3 mb-4">
            Subida de datos
            <div class="float-end mt-2">
              <div class="d-flex align-items-center">
                <img
                  src="assets/images/icons/hotel.svg"
                  alt="hotel"
                  width="20px"
                />
                <h4 class="ms-3 mb-0">Mi Hotel</h4>
              </div>
            </div>
          </h2>
          <form>
            <div class="center mb-4">
              <!-- <label for="dateSelect" class="">Please select a date</label> -->
              <input
                type="date"
                value="2024-09-12"
                class="form-control-lg fs-6 border-1"
              />
            </div>

            <!-- <div class="file-uploader d-flex flex-column align-items-center text-center p-3 rounded"  style="  background-color: rgba(0, 0, 0, .02);border: 1px dashed grey;">
                                <app-file-upload></app-file-upload>
                                <span class="mt-2 text-secondary">Tamaño máximo de archivo 10Mb</span>
                        </div> -->
          </form>
        </div>
      </div>
    </div>
  </div>
</section>
