import { Component, ElementRef, ViewChild } from '@angular/core'
import * as Plotly from 'plotly.js-dist-min'
import { HttpClient } from '@angular/common/http'
import { FileUploadService } from '../../services/files/fileUpload.service'
import {
  Chart,
  ChartConfiguration,
  ChartType,
  plugins,
  registerables,
} from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'

@Component({
  selector: 'app-grafico-por-edad',
  templateUrl: './grafico-por-edad.component.html',
  styleUrl: './grafico-por-edad.component.scss',
})
export class GraficoPorEdadComponent {
  // graph: any = {};
  // resultado :any = {};
  chartEdad: Chart | undefined
  @ViewChild('rangoEdad', { static: true })
  canvasRef!: ElementRef<HTMLCanvasElement>
  constructor(
    private http: HttpClient,
    private fileService: FileUploadService
  ) {
    Chart.register(...registerables, ChartDataLabels)
  }

  ngAfterViewInit() {
    console.log('afterinit')
    setTimeout(() => {
      console.log(this.canvasRef.nativeElement)
    }, 1000)
  }

  ngOnInit(): void {
    // this.http.get('../../../assets/data/graficoPorEdad.json').subscribe((data)=> {
    //     this.resultado = data;
    //     this.graph.data = this.resultado.data;
    //     this.graph.layout = this.resultado.layout;
    // });

    this.loadCSVData()
  }

  loadCSVData(): void {
    this.http
      .get('../../../assets/data/output_turista360.csv', {
        responseType: 'text',
      })
      .subscribe((data) => {
        const parsedData = this.fileService.parseCSVData(data)
        this.percentageAge(parsedData)
      })
  }

  percentageAge(data: any[]): void {
    const ageGroups = {
      '20-30': 0,
      '30-40': 0,
      '40-50': 0,
      '50-60': 0,
      '60+': 0,
    }
    const total = data.length

    data.forEach((row) => {
      const age = parseInt(row.edad)
      if (age >= 20 && age <= 30) ageGroups['20-30']++
      if (age >= 30 && age <= 40) ageGroups['30-40']++
      if (age >= 40 && age <= 50) ageGroups['40-50']++
      if (age >= 50 && age <= 60) ageGroups['50-60']++
      if (age >= 60) ageGroups['60+']++
    })

    const agePercentages = (
      Object.keys(ageGroups) as Array<keyof typeof ageGroups>
    ).map((range) => parseFloat(((ageGroups[range] / total) * 100).toFixed(2)))

    this.barChart(Object.keys(ageGroups), agePercentages)
  }

  barChart(labels: string[], data: number[]): void {
    const canvasElement = this.canvasRef.nativeElement
    if (this.chartEdad) {
      this.chartEdad.destroy()
    }
    const chartConfig = {
      type: 'bar',
      data: {
        labels: labels,
        datasets: [
          {
            data: data,
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(255, 205, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(153, 102, 255, 0.2)',
            ],
            borderColor: [
              'rgb(255, 99, 132)',
              'rgb(255, 205, 86)',
              'rgb(75, 192, 192)',
              'rgb(54, 162, 235)',
              'rgb(153, 102, 255)',
            ],
            borderWidth: 1,
          },
        ],
      },
      options: {
        responsive: true,
        scales: {
          x: { title: { display: true, text: 'Rango de Edad' } },
          y: {
            title: { display: true, text: 'Porcentaje' },
            beginAtZero: true,
            ticks: {
              callback: (value: any) => `${value}%`,
            },
          },
        },
        plugins: {
          title: {
            display: true,
            text: 'Distribución de visitantes por rango de edad',
            font: {
              size: 24,
            },
            padding: {
              top: 10,
              bottom: 30,
            },
          },
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              label: function (context: any) {
                const value = context.raw || 0
                return `${value}%`
              },
            },
          },
          datalabels: {
            formatter: (value: any) => `${value}%`, // Agrega el símbolo % al valor
            // color: '#000',  // Opcional: elige un color para la etiqueta
            anchor: 'end', // Posiciona la etiqueta al final de la barra
            align: 'top', // Alinea la etiqueta en la parte superior de la barra
          },
        },
      },
      plugins: [ChartDataLabels],
    }
    this.chartEdad = new Chart(canvasElement, chartConfig as any)
  }
}
