<div>
  <div>
    <label for="provinceSelect">Selecciona una provincia:</label>
    <select
      id="provinceSelect"
      [(ngModel)]="selectedProvince"
      (change)="onProvinceChange($event)"
    >
      <option *ngFor="let province of provinces" [value]="province">
        {{ province }}
      </option>
    </select>
  </div>

  <canvas #gastosPorActividad id="gastosPorActividad"></canvas>
</div>
