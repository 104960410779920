import { HttpClient } from '@angular/common/http'
import { Component, ElementRef, ViewChild, viewChild } from '@angular/core'
import { FileUploadService } from '../../services/files/fileUpload.service'
import { Chart, registerables } from 'chart.js'
import { GraphService } from '../../services/graficos/graph.service'
import { MatrixController, MatrixElement } from 'chartjs-chart-matrix'

@Component({
  selector: 'app-grafico-gastos-motiv',
  templateUrl: './grafico-gastos-motiv.component.html',
  styleUrl: './grafico-gastos-motiv.component.scss',
})
export class GraficoGastosMotivComponent {
  chart: Chart | undefined
  data: any[] = []
  filteredData: { [province: string]: number[] } = {}
  provinces: string[] = []
  motives: string[] = []
  @ViewChild('gastosMotiv', { static: true }) gastosMotiv!: ElementRef

  constructor(
    private http: HttpClient,
    private fileService: FileUploadService,
    private graphService: GraphService
  ) {
    Chart.register(MatrixController, MatrixElement)
    Chart.register(...registerables)
  }

  ngOnInit() {
    this.loadCSVData()
  }

  loadCSVData() {
    this.http
      .get('../../../assets/data/gasto_promedio_provdest_dataset.csv', {
        responseType: 'text',
      })
      .subscribe((data) => {
        const processedData = this.fileService.parseCSVData(data)
        this.data = this.prepareMatrixData(processedData)
        this.updateChart()
      })
  }
  prepareMatrixData(data: any[]): any[] {
    const result: any[] = []
    this.provinces = [
      ...new Set(
        data.map((row) => this.graphService.getProvince(row.provdest))
      ),
    ]
    this.motives = [
      ...new Set(data.map((row) => this.graphService.getMotive(row.motiv))),
    ]
    data.forEach((row) => {
      const motive = this.graphService.getMotive(row.motiv)
      const province = this.graphService.getProvince(row.provdest)
      const gasto = row.gasto_prom_motiv_prov
      if (province && motive && !isNaN(gasto)) {
        result.push({
          x: province,
          y: motive,
          v: gasto,
        })
      }
    })
    this.provinces.forEach((province) => {
      this.motives.forEach((motive) => {
        const exists = result.some(
          (item) => item.x === province && item.y === motive
        )
        if (!exists) {
          result.push({
            x: province,
            y: motive,
            v: 0,
          })
        }
      })
    })

    const resultWithoutUndefined = result.filter((result) => {
      return result.x !== 'undefined' && !result.undefined
    })

    return resultWithoutUndefined
  }
  updateChart() {
    if (this.chart) {
      this.chart.destroy()
    }

    const chartConfig = {
      type: 'matrix',
      data: {
        datasets: [
          {
            label: 'Gasto Promedio por Provincia y Motivo',
            data: this.data,
            backgroundColor: (context: any) => {
              const value = context.dataset.data[context.dataIndex].v
              if (!value) {
                return '#eaf4fc'
              } else if (value >= 200) {
                return 'rgba(0, 51, 102, 0.8)'
              } else if (value > 100) {
                return 'rgba(51, 102, 204, 0.8)'
              } else {
                return 'rgba(153, 204, 255, 0.8)'
              }
            },
            width: (context: any) => {
              const chartArea = context.chart.chartArea || {}
              const xAxis = context.chart.scales.x
              return chartArea.width / xAxis.ticks.length
            },
            height: (context: any) => {
              const chartArea = context.chart.chartArea || {}
              const yAxis = context.chart.scales.y

              return chartArea.height / yAxis.ticks.length
            },
            borderColor: 'rgba(255,255,255)',
            borderWidth: 1,
          },
        ],
      },
      options: {
        responsive: true,

        plugins: {
          title: {
            display: true,
            text: 'Promedio de gastos por motivo de viaje de cada provincia',
            font: {
              size: 24,
            },
            padding: {
              top: 10,
              bottom: 30,
            },
          },
          datalabels: false,
          legend: {
            position: 'top',
            labels: {
              generateLabels: () => {
                const labels = []
                labels.push({
                  text: 'Sin valor',
                  fillStyle: '#eaf4fc',
                })
                labels.push({
                  text: 'Gasto menor a 100€',
                  fillStyle: 'rgba(153, 204, 255, 0.8)',
                })
                labels.push({
                  text: 'Gasto entre 100€ y 200€',
                  fillStyle: 'rgba(51, 102, 204, 0.8)',
                })
                labels.push({
                  text: 'Gasto mayor a 200€',
                  fillStyle: 'rgba(0, 51, 102, 0.8)',
                })
                return labels
              },
            },
            padding: 50, // Agregar espacio entre la leyenda y el gráfico
          },
          tooltip: {
            callbacks: {
              label: (context: any) => {
                const value = context.raw.v
                return `Gasto: ${value}€`
              },
            },
          },
        },
        layout: {
          padding: {
            top: 30,
            bottom: 40, // Agregar espacio entre la leyenda y el gráfico
          },
        },
        scales: {
          x: {
            type: 'category',
            offset: true,
            labbels: Array.from(new Set(this.data.map((item) => item.x))),
            title: {
              display: true,
              text: 'Provincia',
            },
            grid: {
              display: false,
              drawBorder: false,
            },
          },
          y: {
            type: 'category',
            reverse: false,
            offset: true,
            labels: Array.from(new Set(this.data.map((item) => item.y))),
            title: {
              display: true,
              text: 'Motivos de Viaje',
            },
            grid: {
              display: false,
              drawBorder: false,
            },
          },
        },
      },
    }
    this.chart = new Chart(this.gastosMotiv.nativeElement, chartConfig as any)
  }
}
