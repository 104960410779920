import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-notification-modal',
  templateUrl: './notification-modal.component.html',
  styleUrl: './notification-modal.component.scss'
})
export class NotificationModalComponent {
  showModal : boolean = false;
  message: string = '';
  openModal(message:string) : void{
    this.showModal = true;
    this.message = message;
    console.log('SE ABRE MODAL...');
  }
  closeModal(){
    this.showModal = false;
    console.log('SE CIERRA MODAL...')
  }

}
