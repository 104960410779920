import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Hotel } from '../../services/hotel/hotelModel';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrl: './confirmation-modal.component.scss'
})
export class ConfirmationModalComponent {
  @Input() title?: string;
  @Input() message?: string;
  @Output() confirmed = new EventEmitter<Hotel>();
  @Input() hotel?: Hotel;
  deleteHotel: boolean = false;
  openModal(hotel: Hotel) {
    this.deleteHotel = true;
    this.hotel = hotel;
  }

  closeModal() {
    if (this.hotel) {
      this.deleteHotel = false;
      this.confirmed.emit(this.hotel)
    }
    
  }

  dismiss(){
    this.deleteHotel= false;

  }
}
