<div class="mt-5">
  Punto Turístico:
  <select
    class="me-3"
    [(ngModel)]="selectedProvince"
    (change)="onProvinceChange($event)"
  >
    <option *ngFor="let province of provinces" [value]="province">
      {{ province }}
    </option>
  </select>
</div>
<div class="row">
  <div class="col-6">
    <canvas #puntoOcupacionCanvas id="puntoOcupacionCanvas"></canvas>
  </div>
  <div class="col-6">
    <canvas #puntoCanvas id="puntoCanvas"></canvas>
  </div>

  <div #rangeSlider id="rangeSlider" class="slider mt-5"></div>
</div>
<div class="mt-5">
  <small
    >*Estos datos han sido extraidos de la encuesta de ocupación hotelera del
    INE.</small
  >
</div>
