import { Component } from '@angular/core'
import { Router } from '@angular/router'
import pdfMake from 'pdfmake/build/pdfmake'
// import pdfFonts from "pdfmake/build/vfs_fonts";

// (pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-generador-pdf',
  templateUrl: './generador-pdf.component.html',
  styleUrls: ['./generador-pdf.component.css'],
})
export class GeneradorPdfComponent {
  constructor(private router: Router) {
    //this.router.navigate(['/management-area'])
  }

  // Método para generar el documento PDF
  generarPDF() {
    // Definición del contenido del PDF
    const documentDefinition: any = {
      content: [
        { text: 'Listado de hoteles  versión 1.0.', margin: [0, 10, 0, 10] },
        {
          table: {
            headerRows: 1,
            widths: ['*', 'auto', 100, '*'],
            body: [
              [
                { text: 'Nombre Hotel ', style: 'tableHeader' },
                { text: 'Tipo Hotel', style: 'tableHeader' },
                { text: 'Habitaciones totales', style: 'tableHeader' },
                { text: 'Porcentaje de ocupación', style: 'tableHeader' },
              ],
              // ['Mi Hotel', 'Hotel', '145', '96%'],
              // ['Hotel Gran Meliá', 'Hotel', '180', '92%']
            ],
          },
        },
        //{ text: 'Gracias por usar el generador de PDF.', margin: [0, 20, 0, 0] }
      ],
      styles: {
        header: {
          fontSize: 18,
          bold: true,
        },
        tableHeader: {
          bold: true,
          fontSize: 13,
          color: 'black',
        },
      },
    }

    // Generar y abrir el PDF
    pdfMake.createPdf(documentDefinition).open()
  }
}
