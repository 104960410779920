<div class="filter">
  <label for="hotel">Selecciona Hotel:</label>
  <select id="hotel" (change)="cambioHotel($event)">
    <option *ngFor="let hotel of hoteles" [value]="hotel.stablishment_id">
      {{ hotel.stablishment_name }}
    </option>
  </select>
</div>

<div class="filter mt-3">
  <label for="tipoHabitacion">Selecciona tipo de habitación:</label>
  <select id="tipoHabitacion" (change)="cambioTipoHabitacion($event)">
    <option value="single">Individual</option>
    <option value="double">Doble</option>
    <option value="suite">Suite</option>
    <option value="all">Todas</option>
  </select>
</div>

<div class="demo-app mt-3">
  <div class="demo-app-main">
    <full-calendar
      #calendario
      *ngIf="calendarVisible"
      [options]="opcionesCalendario"
    ></full-calendar>
    <!--  <button
      mat-raised-button
      color="primary"
      (click)="guardarCambios()"
      [disabled]="!tieneCambios"
    >
      Guardar Cambios
    </button> -->
    <div class="calendar-legend">
      <!-- Leyenda para ocupación -->
      <div class="legend-ocupacion">
        <ul>
          <li>
            <span
              class="legend-color"
              [style.background-color]="obtenerColorOcupacion(70)"
            ></span>
            <span>Alta ocupación (70% o más)</span>
          </li>
          <li>
            <span
              class="legend-color"
              [style.background-color]="obtenerColorOcupacion(33)"
            ></span>
            <span>Media ocupación (33% - 69%)</span>
          </li>
          <li>
            <span
              class="legend-color"
              [style.background-color]="obtenerColorOcupacion(0)"
            ></span>
            <span>Baja ocupación (menos de 33%)</span>
          </li>
        </ul>
      </div>
      <!-- Leyenda para tipos de habitación -->
      <div class="legend-habitaciones">
        <ul>
          <li>
            <span class="material-icons" style="font-size: 16px; color: gray"
              >bedroom_child</span
            >
            <span>Habitación Individual</span>
          </li>
          <li>
            <span class="material-icons" style="font-size: 16px; color: gray"
              >king_bed</span
            >
            <span>Habitación Doble</span>
          </li>
          <li>
            <span class="material-icons" style="font-size: 16px; color: gray"
              >star</span
            >
            <span>Suite</span>
          </li>
        </ul>
      </div>
      <div class="legend-festivos">
        <ul>
          <li>
            <span class="material-icons" style="font-size: 16px; color: purple"
              >celebration</span
            >
            <span>Festivos</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
