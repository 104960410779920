<div class="content-wrapper">
  <div class="container">
    <div class="d-flex justify-content-center">
      <div class="user_card">
        <div class="justify-content-start">
          <div class="mask-wrapper d-none d-lg-block float-end rounded-end">
            <img src="assets/images/login-image.jpeg" alt="Login Image" />
          </div>
          <div class="content-form float-start">
            <div class="form_container d-flex flex-column">
              <div
                class="brand_logo_container mb-3 d-flex justify-content-center"
              >
                <img
                  src="assets/images/logo-senda.svg"
                  alt="Brand Logo Image"
                  width="140px"
                />
              </div>

              @if (errorMessage) {
                <div class="alert alert-danger" role="alert">
                  <small>{{ errorMessage }}</small>
                </div>
              }
              @if (successMessage) {
                <div class="alert alert-success" role="alert">
                  <small>{{ successMessage }}</small>
                </div>
              }
              @if (showLoginMFA) {
                <app-verification-code-form
                  [waiting]="waiting"
                  (mfacode)="submitSigninTOTPCode($event)"
                  (goBack)="showLoginMFA = false"
                ></app-verification-code-form>
              }
              @if (showConfirmationCodeInput) {
                <app-confirmation-code-form
                  [waiting]="this.waiting"
                  (confirmationCode)="confirmSignup($event)"
                ></app-confirmation-code-form>
              }
              @if (showVinculateMFA) {
                <app-vinculate-mfa-form
                  [waiting]="waiting"
                  [QRUrl]="QRUrl"
                  (otpCode)="submitSignupTOTPcode($event)"
                ></app-vinculate-mfa-form>
              }
              @if (showResetPassword) {
                <app-reset-password-form
                  [waiting]="waiting"
                  [resetCodeSentToUser]="resetCodeSentToUser"
                  (askForNewPassword)="confirmPasswordReset($event)"
                  (askForCode)="initiatePasswordReset($event)"
                  (goBack)="closeResetPassword()"
                ></app-reset-password-form>
              }
              @if (
                !showRegister &&
                !showLoginMFA &&
                !showVinculateMFA &&
                !showResetPassword &&
                !showConfirmationCodeInput
              ) {
                <app-signin-form
                  [waiting]="waiting"
                  (resetPassword)="startResetPassword()"
                  (clickLogin)="signIn($event)"
                ></app-signin-form>
                <p class="text-center p-4">
                  ¿No tienes cuenta?
                  <a
                    href="#"
                    class="float-end text-primary"
                    (click)="showSignup(); $event.preventDefault()"
                    >Registrate</a
                  >
                </p>
              }
              @if (
                showRegister &&
                !showConfirmationCodeInput &&
                !showVinculateMFA &&
                !showLoginMFA &&
                !showResetPassword
              ) {
                <app-signup-form
                  [waiting]="waiting"
                  [userAlreadyExists]="userAlreadyExists"
                  (clickRegister)="signUp($event)"
                ></app-signup-form>
                <p class="text-center p-4">
                  ¿Ya eres miembro?
                  <a
                    href="#"
                    class="float-end text-primary"
                    (click)="showSignin(); $event.preventDefault()"
                    >Iniciar sesión</a
                  >
                </p>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
