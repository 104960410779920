import { Component, Output, EventEmitter, Input } from '@angular/core'
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms'

@Component({
  selector: 'app-confirmation-code-form',
  standalone: true,
  imports: [ReactiveFormsModule],
  templateUrl: './confirmation-code-form.component.html',
  styleUrl: './confirmation-code-form.component.scss',
})
export class ConfirmationCodeFormComponent {
  userConfirmationForm: FormGroup
  @Input() waiting: boolean = false
  @Output() confirmationCode: EventEmitter<any> = new EventEmitter()

  constructor(private fb: FormBuilder) {
    this.userConfirmationForm = this.fb.group({
      confirmationCode: [''],
    })
  }

  confirmSignup() {
    this.confirmationCode.emit(this.userConfirmationForm.value.confirmationCode)
  }
}
