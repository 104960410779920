import { Component } from '@angular/core';

@Component({
  selector: 'app-page-not-found-componentmponent',
  templateUrl: './page-not-found-componentmponent.component.html',
  styleUrl: './page-not-found-componentmponent.component.scss'
})
export class PageNotFoundComponent {

}
