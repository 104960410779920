import { Component, Input, OnInit } from '@angular/core'
import { Hotel } from '../../services/hotel/hotelModel'
import { HotelDataService } from '../../services/hotel/hotelData.service'
import { Router } from '@angular/router'
import * as hotelesJSON from '../../../assets/data/hoteles.json'

@Component({
  selector: 'app-privatehome',
  templateUrl: './privatehome.component.html',
  styleUrl: './privatehome.component.scss',
})
export class PrivatehomeComponent implements OnInit {
  isChanged: any
  hotel: Hotel = {} as Hotel
  h: Hotel = {} as Hotel
  listaHoteles: Hotel[] = []
  buttonClicked = localStorage.getItem('buttonClicked')

  constructor(
    private router: Router,
    private hotelDataService: HotelDataService
  ) {}

  ngOnInit() {
    this.hotelDataService.hotelstate$.subscribe((hoteles) => {
      this.listaHoteles = hoteles
      // console.log('lista de hoteles',hoteles);
    })
    this.hotelDataService.getHoteles()
    console.log('HOTELEEESS....', this.hotel, this.h)
  }

  getData(): Hotel[] {
    return this.listaHoteles
  }
}
