import { Component, Output, EventEmitter, Input } from '@angular/core'
import {
  FormGroup,
  FormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms'
import {
  passwordMatchValidator,
  postalCodeValidator,
  municipalityValidator,
  passwordRequirements,
} from '../../../../../utils/validators'
import { MunicipiosService } from '../../../../services/municipios/municipios.service'

@Component({
  selector: 'app-signup-form',
  standalone: true,
  imports: [ReactiveFormsModule],
  templateUrl: './signup-form.component.html',
  styleUrl: './signup-form.component.scss',
})
export class SignupFormComponent {
  registerForm: FormGroup
  municipalitiesInProvince: string[]

  @Input() userAlreadyExists: boolean = false
  @Input() waiting: boolean = false
  @Output() clickRegister: EventEmitter<any> = new EventEmitter()

  constructor(
    private fb: FormBuilder,
    private municipalities: MunicipiosService
  ) {
    this.registerForm = this.fb.group(
      {
        email: ['', [Validators.required, Validators.email]],
        username: ['', Validators.required],
        province: ['', Validators.required],
        municipality: ['', Validators.required],
        postalCode: [
          '',
          [
            Validators.required,
            Validators.minLength(5),
            Validators.maxLength(5),
            Validators.pattern(/^[0-9]{5}$/),
          ],
        ],
        password: ['', Validators.required],
        confirmPassword: ['', Validators.required],
      },
      {
        validators: [
          postalCodeValidator(),
          passwordMatchValidator(),
          passwordRequirements('password'),
        ],
      }
    )
  }

  ngOnInit() {
    this.updateFormValidators()
    this.registerForm.get('province').valueChanges.subscribe((province) => {
      this.municipalities
        .getMunicipios(province)
        .subscribe((municipalities) => {
          this.registerForm.get('municipality').setValue('')
          this.municipalitiesInProvince = municipalities

          this.updateFormValidators()
        })
    })
  }

  private updateFormValidators() {
    this.registerForm.setValidators([
      postalCodeValidator(),
      passwordMatchValidator(),
      municipalityValidator(this.municipalitiesInProvince),
      passwordRequirements('password'),
    ])
    this.registerForm.updateValueAndValidity()
  }

  register() {
    this.clickRegister.emit(this.registerForm.value)
  }
}
