import { Pipe, PipeTransform } from '@angular/core';
import { formatFechaTiempo } from '../../utils/utils';
@Pipe({
  name: 'formatFecha'
})
export class FormatFechaPipe implements PipeTransform {

  transform(value: string): string {
    return formatFechaTiempo(value);
  }

}
