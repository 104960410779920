import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TiempoService } from '../../services/tiempo/tiempo.service';

@Component({
  selector: 'app-tiempo',
  templateUrl: './tiempo.component.html',
  styleUrls: ['./tiempo.component.scss']
})

export class TiempoComponent implements OnInit {
  weatherData: any;
  datosapintar:any;
  itemTiempo: any[]= [];
  lat:number=0;
  lon:number=0;
  fecha: string = '';
  temp_min = 0;
  @Input()municipio: string = '';
  constructor(private tiemposervice:TiempoService) { }

  ngOnInit() {
    console.log('Municipio recibido....', this.municipio);
    this.getWeather();
 
  }
  // ngOnChanges(changes: SimpleChanges): void {
  //   if(changes['localidad']){
  //     this.itemTiempo =[];
  //     this.getWeather();
  //   }
  // }
 

  getWeather() {
    this.tiemposervice.getLatLon(this.municipio).subscribe(data => {
      this.lat= data[0].lat;
      this.lon= data[0].lon;
      this.tiemposervice.getWeather(this.municipio).subscribe(data => {
        this.weatherData = data;
        // this.fecha = this.formatFecha(this.weatherData.list[0].dt_txt);
        this.guardarinteresan(this.weatherData);
        console.log('datos clima', this.weatherData);
      });
      console.log('latitud y longitud', this.lat, this.lon);
    });
  }

   guardarinteresan(weatherData: any) {
    this.weatherData.list.forEach((item: any) => {
      if(item.dt_txt.includes('06:00:00')){
        this.temp_min = item.main.temp_min;
      }
      if(item.dt_txt.includes('15:00:00')){
        this.itemTiempo = [...this.itemTiempo, item];
      }
    });
  }

  


}