import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CalendarService {
    private tabSwitchSource = new Subject<void>();
    tabSwitched$ = this.tabSwitchSource.asObservable();
  
    switchTab() {
      this.tabSwitchSource.next(); // Emitir evento cuando la pestaña cambia
    }
}
