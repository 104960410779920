import { Component, Input } from '@angular/core'
import * as Plotly from 'plotly.js-dist-min'
import { HttpClient } from '@angular/common/http'
import { FileUploadService } from '../../services/files/fileUpload.service'
import { Hotel } from '../../services/hotel/hotelModel'

@Component({
  selector: 'app-grafico-reusable',
  templateUrl: './grafico-reusable.component.html',
  styleUrl: './grafico-reusable.component.scss',
})
export class GraficoReusableComponent {
  graph: any = {}
  resultado: any = {}
  // hotelData: Hotel = {} as Hotel;
  @Input() hotelData: Hotel = {
    name: '',
    type: '',
    address: '',
    province: '',
    postal_code: '',
    rooms: 0,
    capacity: 0,
  }

  constructor(
    private http: HttpClient,
    private fileUploadService: FileUploadService
  ) {}
  ngOnInit(): void {
    console.log('hoteldata..fadsdasdfas..', this.hotelData)
    this.fileUploadService
      .loadCsvsAdrRevPar(this.hotelData.uid)
      .subscribe((data) => {
        const { data1, data2 } = data // Desestructura el objeto recibido
        console.log('data...', data1)
        this.plotGraph(data1, data2)
      })
    // this.fileUploadService.downloadJsonADR(this.hotelData.nombre).subscribe({
    //     next: (resp) => {
    //         this.plotGraph(resp);
    //       },
    //     error: (error) => {
    //         console.error('Error al descargar el JSON', error);
    //       }
    // })
    // this.fileUploadService.downloadJsonRevPar(this.hotelData.nombre).subscribe({
    //     next: (resp) => {
    //         console.log("......res.....", resp)
    //         this.plotGraph(resp);
    //       },
    //     error: (error) => {
    //         console.error('Error al descargar el JSON', error);
    //       }
    // })
  }

  private plotGraph(data1: any[], data2: any[]): void {
    const x1 = data1.map((row) => row[0])
    const y1 = data1.map((row) => row[1])
    const x2 = data2.map((row) => row[0])
    const y2 = data2.map((row) => row[1])
    console.log('LLama al plotly....')
    const trace1: Partial<Plotly.PlotData> = {
      x: x1,
      y: y1,
      mode: 'lines+markers',
      type: 'scatter',
      name: 'ADR', // Nombre de la primera serie
    }

    const trace2: Partial<Plotly.PlotData> = {
      x: x2,
      y: y2,
      mode: 'lines+markers', // Puedes cambiar el modo si lo deseas
      type: 'scatter',
      name: 'RevPar', // Nombre de la segunda serie
    }

    const layout: Partial<Plotly.Layout> = {
      title: 'Histórico y previsión ADR/RevPar para tu hotel',
      xaxis: {
        title: '',
      },
      yaxis: {
        title: '',
      },
    }
    console.log('Trace y Layout', trace1, layout)
    Plotly.newPlot('plot', [trace1, trace2], layout)
  }
}
