import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { HomeComponent } from './pages/home/home.component'
import { LoginComponent } from './pages/login/login.component'
import { PrivatehomeComponent } from './pages/privatehome/privatehome.component'
import { DashboardComponent } from './pages/dashboard/dashboard.component'
import { DataStorageComponent } from './pages/data-storage/data-storage.component'
import { PageNotFoundComponent } from './pages/page-not-found-componentmponent/page-not-found-componentmponent.component'
import { ManagementAreaComponent } from './pages/management-area/management-area.component'
import { PageUnderConstructionComponent } from './pages/page-under-construction/page-under-construction.component'
import { ArticleComponent } from './pages/article/article.component'
import { BlogComponent } from './pages/blog/blog.component'
import { AuthGuard } from './guards/auth.guard'
import { AccountComponent } from './pages/account/account.component'
import { KnowledgeAreaComponent } from './pages/knowledge-area/knowledge-area.component'
import { DatosCLMComponent } from './pages/datos-clm/datos-clm.component'

const routes: Routes = [
  { path: 'home', component: HomeComponent }, // ruta vacía

  { path: 'account', component: AccountComponent, canActivate: [AuthGuard] }, // ruta vacía

  {
    path: 'knowledge',
    component: KnowledgeAreaComponent,
    canActivate: [AuthGuard],
  }, // ruta vacía

  {
    path: 'data-storage',
    component: DataStorageComponent,
    canActivate: [AuthGuard],
  }, // ruta vacía

  {
    path: 'management-area',
    component: ManagementAreaComponent,
    canActivate: [AuthGuard],
  }, // ruta vacía

  { path: 'article', component: ArticleComponent }, // ruta vacía

  { path: 'blog', canActivate: [AuthGuard], component: BlogComponent }, // ruta vacía

  {
    path: 'datosAbiertosCLM',
    component: DatosCLMComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
  }, // ruta vacía

  { path: 'login', component: LoginComponent, pathMatch: 'full' }, // ruta vacía

  {
    path: 'app',
    component: PrivatehomeComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
  }, // ruta vacía   ,

  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard],
  }, // ruta vacía ,

  {
    path: 'page-under-construction',
    component: PageUnderConstructionComponent,
  },

  { path: '', component: HomeComponent }, // ruta vacía

  { path: '**', component: PageNotFoundComponent }, // ruta vacía
]

@NgModule({
  imports: [RouterModule.forRoot(routes, { anchorScrolling: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
