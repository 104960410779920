import { HttpClient } from '@angular/common/http'
import { Component, ElementRef, ViewChild, viewChild } from '@angular/core'
import { FileUploadService } from '../../services/files/fileUpload.service'
import { Chart, registerables } from 'chart.js'
import { GraphService } from '../../services/graficos/graph.service'

@Component({
  selector: 'app-grafico-turistas-por-ccaa',
  templateUrl: './grafico-turistas-por-ccaa.component.html',
  styleUrl: './grafico-turistas-por-ccaa.component.scss',
})
export class GraficoTuristasPorCcaaComponent {
  chart: Chart
  data: any[] = []
  @ViewChild('turistasComunidad', { static: true })
  turistasComunidad!: ElementRef
  constructor(
    private http: HttpClient,
    private fileService: FileUploadService,
    private graphService: GraphService
  ) {
    Chart.register(...registerables)
  }
  ngOnInit() {
    this.loadCSVData()
  }
  loadCSVData() {
    this.http
      .get('../../../assets/data/porcentaje_turistas_por_ccaa.csv', {
        responseType: 'text',
      })
      .subscribe((data) => {
        const processedData = this.fileService.parseCSVData(data)
        this.data = this.groupByCommunityAndProvince(processedData)
        this.updateChart()
      })
  }
  groupByCommunityAndProvince(data: any[]): any[] {
    const groupedData: { [key: string]: { [key: string]: number } } = {}
    data.forEach((row) => {
      const community = this.graphService.getCommunity(row.ccaa_residencia)
      const province = this.graphService.getProvince(row.provdest)
      const turistas = parseFloat(row.porcentaje_turistas)

      if (!groupedData[province]) {
        groupedData[province] = {}
      }
      if (!groupedData[province][community]) {
        groupedData[province][community] = 0
      }
      groupedData[province][community] += turistas
    })
    const result: any[] = Object.keys(groupedData).map((province) => {
      const communities = groupedData[province]
      const totalPorProvincia = Object.values(communities).reduce(
        (acc, porcentaje) => acc + porcentaje,
        0
      )
      console.log(
        'Total Porcentaje,...',
        province,
        totalPorProvincia.toFixed(2)
      )

      return {
        province,
        ...communities, // Expandimos las comunidades como propiedades
      }
    })
    const resultWithoutUndefined = result.filter((result) => {
      return result.province !== 'undefined' && !result.undefined
    })

    return resultWithoutUndefined
  }
  updateChart() {
    console.log('DATA...', this.data)
    const labels = this.data.map((item) => item.province)
    const communities: any[] = []

    this.data.forEach((item) => {
      Object.keys(item).forEach((key) => {
        if (key !== 'province' && !communities.includes(key)) {
          communities.push(key)
        }
      })
    })
    console.log('communities...', communities)
    const colorMap: { [key: string]: string } = {
      // Extranjero: 'rgba(255, 159, 64, 1.0)',
      Andalucía: 'rgba(255, 99, 132, 1.0)',

      Aragón: 'rgba(54, 162, 235, 1.0)',
      'Principado de Asturias': 'rgba(255, 206, 86, 1.0)',
      'Islas Baleares': 'rgba(75, 192, 192, 1.0)',
      'Islas Canarias': 'rgba(153, 102, 255, 1.0)',
      Cantabria: 'rgba(255, 159, 64, 1.0)',
      'Castilla y León': 'rgba(183, 77, 51 , 1.0)',
      'Castilla-La Mancha': 'rgba(128, 205, 160, 1.0)',
      Cataluña: 'rgba(230, 128, 255, 1.0)',
      'Comunidad Valenciana': 'rgba(108, 128, 255, 1.0)',
      Extremadura: 'rgba(255, 128, 171, 1.0)',
      Galicia: 'rgba(64, 200, 240, 1.0)',
      'Comunidad de Madrid': 'rgba(200, 160, 255, 1.0)',
      'Región de Murcia': 'rgba(255, 192, 112, 1.0)',
      'Comunidad Foral de Navarra': 'rgba(144, 238, 144, 1.0)',
      'País Vasco': 'rgba(255, 182, 193, 1.0)',
      'La Rioja': 'rgb(189, 128, 103)',
      Ceuta: 'rgba(255, 140, 140, 1.0)',
      Melilla: 'rgba(255, 99, 71)',
    }
    const datasets = communities.map((community) => ({
      label: community,
      data: this.data.map((item) => item[community]),
      backgroundColor: colorMap[community],
    }))

    if (this.chart) {
      this.chart.destroy()
    }
    const chartConfig = {
      type: 'bar',
      data: {
        labels,
        datasets,
      },
      options: {
        responsive: true,
        plugins: {
          title: {
            display: true,
            text: 'Porcentaje de turistas por Comunidad Autónoma',
            font: {
              size: 24,
            },
            padding: {
              top: 10,
              bottom: 30,
            },
          },
          tooltip: {
            callbacks: {
              label: function (context: any) {
                const label = context.dataset.label || '' // Obtén la etiqueta de la leyenda
                const value = context.raw // Valor de los datos
                return `${label}: ${value}%` // Muestra "Porcentaje: 30%"
              },
            },
          },
          datalabels: false,
          legend: {
            position: 'right',
          },
        },
        scales: {
          x: {
            stacked: true,
            title: {
              display: true,
              text: 'Provincia',
            },
          },
          y: {
            stacked: true,
            beginAtZero: true,
            max: 100,
            ticks: {
              callback: function (value: any) {
                return value + '%' // Agrega el símbolo de porcentaje
              },
            },
            title: {
              display: true,
              text: 'Porcentaje de turistas',
            },
          },
        },
      },
    }
    this.chart = new Chart(
      this.turistasComunidad.nativeElement,
      chartConfig as any
    )
  }
}
