<form [formGroup]="userConfirmationForm" (ngSubmit)="confirmSignup()">
  <div id="login-wrapper" class="login-wrapper">
    <p>
      <strong>Confirmación del correo electrònico</strong><br />Introduce el
      código de confirmación que hemos enviado a tu correo
    </p>
    <div class="mb-3">
      <input
        type="text"
        formControlName="confirmationCode"
        class="form-control mb-3"
        placeholder="Código de confirmación"
      />
      <button
        type="submit"
        name="button"
        class="btn login_btn btn-primary text-center w-100 rounded-pill"
      >
        @if (waiting) {
          <img
            width="20"
            src="assets/images/icons/loading-white.svg"
            alt="Loading"
          />
        } @else {
          Confirmar registro
        }
      </button>
    </div>
  </div>
</form>
